class DashboardHelper {
  reflowDashboard(hideClass, showClass) {
    $('.' + hideClass).css('display', 'none');
    $('.' + showClass).css('display', 'block');

    this.reflowGraphCharts();
    this.reflowScrollBars();
  }

  reflowGraphCharts() {
    $('.graphChart').each(function() {
      var highchart = $(this).highcharts();
      if(highchart) highchart.reflow();
    });
  }

  reflowScrollBars() {
    setTimeout(function() {
      $('table').trigger('reflowScrollBar');
    }, 0);
  }

  dashboardParams(self) {
    return  {
      date_range: self.selectedDateRange,
      forecast_date_range: self.selectedForecastDateRange,
      dashboard_type: self.selectedType,
      tag_format: self.selectedFormat,
      currency: self.currency
    };
  }

  abortAjaxRequests(ajaxRequests) {
    _.each(ajaxRequests, function(request, label) {
      request.abort();
    });
  }

  intervalOptions() {
    return {
      'minute':{
        'label': 'Minute',
        'shortLabel': 'M'
      },
      'fiveMinute': {
        'label': '5 Minute',
        'shortLabel': '5M'
      },
      'hour': {
        'label': 'Hour',
        'shortLabel': 'H'
      },
      'day': {
        'label': 'Day',
        'shortLabel': 'D'
      }
    };
  }

  forecastDateRangePresent(forecastDateRange) {
    return forecastDateRange && forecastDateRange !== '';
  }

  nonTForecastDateRangePresent(forecastDateRange) {
    return this.forecastDateRangePresent(forecastDateRange) && forecastDateRange !== 'T';
  }

  intervalDisabledByForecastDateRange(interval, forecastDateRange) {
    return _.contains(['minute', 'fiveMinute', 'hour'], interval) &&
      this.forecastDateRangePresent(forecastDateRange);
  }

  intervalDisabledException(interval, dateRanges, dateRange, forecastDateRange) {
    const selectedDateRange = this.getSelectedDateRange(dateRanges, dateRange);

    if (selectedDateRange) {
      return this.nonTForecastDateRangePresent(forecastDateRange) &&
        _.contains(selectedDateRange.forecast_non_t_exceptions, interval);
    }
  }

  intervalDisabled(dateRanges, dateRange, interval, disabledAttr) {
    const selectedDateRange = this.getSelectedDateRange(dateRanges, dateRange);

    if (selectedDateRange) {
      return _.contains(selectedDateRange[disabledAttr], interval);
    }
  }

  getDefaultInterval(dateRanges, dateRange, forecastDateRange) {
    var intervalDefault;
    const selectedDateRange = this.getSelectedDateRange(dateRanges, dateRange);

    if (selectedDateRange) {
      intervalDefault = selectedDateRange.default;

      if (this.nonTForecastDateRangePresent(forecastDateRange) && selectedDateRange.forecast_non_t_default) {
        intervalDefault = selectedDateRange.forecast_non_t_default;
      }
    }

    return intervalDefault;
  }

  getSelectedDateRange(dateRanges, dateRange) {
    return dateRanges[dateRange];
  }
}

export default DashboardHelper;

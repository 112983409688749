import HighchartsBuilder from '../../modules/highcharts_builder.js';

angular.module('vastdesk')
.component('sunburstChart', {
  bindings: {
    chartId: '@',
    chartData: '=',
    hideLegend: '@',
    chartHeight: '@',
    htmlTooltip: '@',
    smallDrilldown: '<'
  },
  template: '<div class="graphChart sunburstChart"><div></div></div>',
  controller: ['$scope', '$element', function($scope, $element) {

    var highchartsBuilder = new HighchartsBuilder();
    var colors = highchartsBuilder.chartColors;

    var chartDiv = $element.find('div');

    var self = this;
    var onInitLoad = $.Deferred();

    var getPointColor = function(label) {
      var point = label.series.points[label.index];
      return (point && point.node && point.node.color) || (point && point.color) || 'transparent';
    };

    var getIconClass = function(label) {
      return 'sunburst-icon-' + self.chartId + '-' + label.index;
    };

    var setChart = function() {
      chartDiv.highcharts({
        credits: {enabled: false},
        chart: {
          backgroundColor: null,
          plotBorderWidth: null,
          height: (self.chartHeight || 400) + 'px'
        },
        title: {
          text: ''
        },
        series: [{
          type: "sunburst",
          name: '',
          data: self.chartData || [],
          allowDrillToNode: true,
          breadcrumbs: {
            format: self.smallDrilldown ? '←' : undefined,
            buttonTheme: {
              style: {
                color: '#484848'
              },
              fill: '#f7f7f7',
              padding: 8,
              stroke: '#cccccc',
              'stroke-width': 1
            },
            floating: self.smallDrilldown ? true : false,
            position: {
              align: 'right'
            },
            showFullPath: false
          },
          cursor: 'pointer',
              levelSize: {
                unit: 'weight',
                value: 1
              },
          levels: [
            {
              level: 1,
              levelIsConstant: false,
              levelSize: {
                unit: 'weight',
                value: 1
              }
            }, {
              level: 2,
              colorByPoint: true,
              levelSize: {
                unit: 'weight',
                value: 1
              }
            }, {
              level: 3,
              colorVariation: {
                key: 'brightness',
                to: -0.5
              },
              levelSize: {
                unit: 'weight',
                value: 1
              }
            }, {
              level: 4,
              colorVariation: {
                key: 'brightness',
                to: 0.5
              },
              levelSize: {
                unit: 'weight',
                value: 1
              }
            }, {
              level: 5,
              colorVariation: {
                key: 'brightness',
                to: -0.5
              },
              levelSize: {
                unit: 'weight',
                value: 1
              }
            }
          ]
        }],
        colors: colors,
        tooltip: {
          useHTML: self.htmlTooltip === 'true' ? true : false,
          formatter: function() {
            var point = this.point;

            if (point.tooltip && point.tooltip !== '') {
              return point.tooltip;
            }
            else {
              return '<div style="font-size: 10px;">' + point.name + '</div><br/><b>' + Highcharts.numberFormat(point.value, 0, '.', ',') + '</b>';
            }
          },
          style: {
            opacity: '0.95'
          }
        },
        legend: {
          enabled: self.hideLegend === 'true' ? false : true,
          useHTML: true,
          padding: 0,
          itemMarginTop: 0,
          itemMarginBottom: 2,
          itemStyle: {
            lineHeight: '10px',
            fontSize: '10px',
            color: '#484848',
            fontWeight: '400'
          },
          labelFormatter: function () {
            var thisLabel = this;
            var iconClass = getIconClass(thisLabel);
            var color = getPointColor(thisLabel);
            if (color === 'transparent') {
              setTimeout(function() {
                $('.' + iconClass).css('color', getPointColor(thisLabel));
              }, 0);
            }
            return '<span><i class="fa fa-circle ' + iconClass + '" style="color:' + color + '"></i> ' + this.name + '</span>';
          },
          squareSymbol: false,
          symbolWidth: 0,
          symbolHeight: 0,
          symbolPadding: 0,
          symbolRadius: 0,
          maxHeight: 32
        },
        plotOptions: {
          sunburst: {
            showInLegend: true,
            legendType: 'point',
            dataLabels: {
              enabled: false
            },
            point: {
              events: {
                legendItemClick: function(event) {
                  if (this.series.rootNode === this.id) {
                    this.series.drillToNode('');
                  }
                  else {
                    this.series.drillToNode(this.id);
                  }
                }
              }
            }
          }
        }
      });
    };

    $scope.$on('setHighchartLoading', function(e, args) {
      highchartsBuilder.loadData(onInitLoad, chartDiv, self, args);
    });

    $scope.$on('refreshHighchart', function(e, args) {
      highchartsBuilder.refreshData(onInitLoad, chartDiv, self, args, function() {
        var chart = chartDiv.highcharts();

        if (chart && chart.series) {
          if (args.data) {
            self.chartData = args.data;
          }
          chart.series[0].setData(self.chartData);
        }
      });
    });

    $scope.$on('reflowHighcharts', function(e, args) {
      highchartsBuilder.deferredReflowChart(onInitLoad, chartDiv);
    });

    this.$onInit = function() {
      setChart();
      onInitLoad.resolve();
    };

  }]
});
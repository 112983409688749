angular.module('vastdesk').
  component('directConnectionForm',  {
    bindings: {
      formName: '@',
      formId: '@',
      showAction: '<',
      establishedConnection: '<',
      requestedStatusChange: '@',
      status: '@',
      connectionsClear: '<',
      netPayableTerm: '<',
      netReceivableTerm: '<',
      preferredSupplyEnvironments: '<',
      preferredDemandEnvironments: '<',
      rejectionMessage: '@',
      otherAccountName: '@',
      reconnectDeadline: '@',
      reconnectStart: '@',
      reconnectEnd: '@',
      showRejectionMessage: '<',
      accountContactName: '@',
      accountContactEmail: '@',
      accountContactOptions: '<',
      accountContactId: '@',
      message: '@',
      availableStatusChangeOptions: '<',
      directConnectionStatusMappings: '<',
      connectionsClearInfo: '@',
      clearingFilterOptions: '<',
      paymentTerms: '@',
      receivableTerms: '@',
      dcPreferredSupplyRateRange: '@',
      dcPreferredSupplyEnvironments: '@',
      dcPreferredSupplyCountryCodes: '@',
      dcPreferredDemandRateRange: '@',
      dcPreferredDemandEnvironments: '@',
      dcPreferredDemandCountryCodes: '@',
      persistedPreferencesPresent: '@',
      preferredSupplyRateMin: '<',
      preferredSupplyRateMax: '<',
      environments: '<',
      environmentMappings: '<',
      supplyCountrySelected: '<',
      preferredDemandRateMin: '<',
      preferredDemandRateMax: '<',
      demandCountrySelected: '<'
    },
    template: require("../templates/directConnectionForm.html"),
    controllerAs: '$directConnectionFormCtrl',
    controller: function() {

      var self = this;

      this.setRequestedStatusChange = function(requestedStatus) {
        self.requestedStatusChange = requestedStatus;
      };

      this.environmentSelected = function(list, value) {
        return _.contains(list, value);
      };

      this.unchangedStatus = function() {
        return self.status === self.requestedStatusChange;
      };

      this.statusChangedFromTo = function(from, to) {
        return self.status === from && self.requestedStatusChange === to;
      };

      this.statusChangedTo = function(to) {
        return self.status !== to && self.requestedStatusChange === to;
      };

      this.showCrudFields = function() {
        return self.statusChangedTo('pending');
      };

      this.showReadOnlyFields = function() {
        return self.requestedStatusChange !== 'unconnected' && !self.statusChangedTo('pending');
      };

      this.actionPending = function() {
        return self.statusChangedTo('pending') || self.statusChangedTo('rejected') || self.statusChangedTo('connected');
      };

      var urlWithoutQueryString = function(href) {
        var split = href.split('?');

        if (split.length > 1) {
          href = split[0] + window.location.hash;
        }

        return href;
      };

      var resetHistory = function() {
        var href = window.location.href;

        if (/requested_status_change=/.test(href)) {
          window.history.replaceState({}, document.title, urlWithoutQueryString(href));
        }
      };

      resetHistory();

    }
});

angular.module('vastdesk').
  component('scannedCreativeOverridePanel',  {
    bindings: {
      overrideFields: '<',
      canOverride: '<',
      panelTitle: '@',
      panelName: '@'
    },
    template: require("../templates/scannedCreativeOverridePanel.html"),
    controllerAs: '$scannedCreativeOverridePanelCtrl',
    controller: function() {

      this.displayOverrideField = (field) => {
        return !field.menuOnly &&
          (!field.hideWhenOriginalEmpty || field.original);
      };

      this.fieldMenuLabel = (field) => {
        return field.displayType === 'override' ? 'Reset' : 'Override';
      };

      const toggleDisplayType = (field) => {
        field.displayType = (field.displayType === 'override') ? 'reset' : 'override';

        if (field.displayType === 'override') {
          field.override = field.original;
          field.overrideArray = field.originalArray;

          if (field.type === 'multi_select') {
            setTimeout(function() {
              $('#' + field.name).trigger('chosen:updated');
            }, 0);
          }
        }
      };

      this.changeDisplayType = (field) => {
        toggleDisplayType(field);

        if (field.menuOnly) {
          _.each(field.overrideFieldNames, (name) => {
            var fieldToToggle = _.find(this.overrideFields, (overrideField) => { return overrideField.name === name });
            if (fieldToToggle) {
              toggleDisplayType(fieldToToggle);
            }
          });
        }
      };

      const displayOverrideOnLoad = (field) => {
        return (field.type === 'boolean' && _.contains(['false', 'true'], field.override)) ||
          (field.type === 'text' && field.override) ||
          (field.type === 'multi_select' && Array.isArray(field.overrideArray));
      };

      this.$onInit = () => {
        if (!this.canOverride) {
          return;
        }

        _.each(this.overrideFields, (field) => {
          if (displayOverrideOnLoad(field)) {
            field.displayType = 'override';
          }

          if (field.menuOnly) {
            _.each(field.overrideFieldNames, (name) => {
              var fieldToToggle = _.find(this.overrideFields, (overrideField) => { return overrideField.name === name });
              if (fieldToToggle && displayOverrideOnLoad(fieldToToggle)) {
                field.displayType = 'override';
              }
            });
          }
        });
      };

    }
});

import platformUtilities from '../modules/platform_utilities.js';
import dataTableStickyTableHeaders from '../modules/data_table_sticky_table_headers.js';
import SwalHelper from '../modules/swal_helper.js';
import DatatableFilterLinkHelper from '../modules/datatable_filter_link_helper.js';

$.fn.dataTable.ext.errMode = 'none';

var dataTableExtSortNodes = function(table, col) {
  return table.api().column( col, {order:'index'} ).nodes() || [];
};

$.fn.dataTable.ext.order['dom-checkbox'] = function  ( settings, col ) {
  return dataTableExtSortNodes(this, col).map( function ( td, i ) {
    return $(td).find("input[type='checkbox']").prop('checked') ? '1' : '0';
  } );
};

$.fn.dataTable.ext.order['dom-html-text-numeric'] = function  ( settings, col ) {
  return dataTableExtSortNodes(this, col).map( function ( td, i ) {
    return $(td).text() * 1;
  } );
};

$.fn.dataTable.ext.order['dom-active'] = function  ( settings, col ) {
  return dataTableExtSortNodes(this, col).map( function ( td, i ) {
    var val = $(td).find("input[type='hidden'][name*='active']").val();
    return String(val) !== 'false' ? '1' : '0';
  } );
};

$.fn.dataTable.ext.order['dom-input'] = function  ( settings, col ) {
    return dataTableExtSortNodes(this, col).map( function ( td, i ) {
        return $(td).find("input:visible").val();
    } );
};

$.fn.dataTable.ext.order['dom-tier'] = function  ( settings, col ) {
  return dataTableExtSortNodes(this, col).map( function ( td, i ) {
    var cell = $(td);
    if ( cell.closest('tr:visible').length < 1 ) {
      return;
    }
    return cell.find("select:visible").val() || cell.find("input").val();
  });
};

$.fn.dataTable.ext.order['dom-placement-time'] = function  ( settings, col ) {
  return dataTableExtSortNodes(this, col).map( function ( td, i ) {
    return $.map($(td).find('select'), function(s) {
      return $(s).val();
    }).join('');
  } );
};

$.fn.dataTable.ext.order['dom-select'] = function  ( settings, col ) {
  return dataTableExtSortNodes(this, col).map( function ( td, i ) {
    return $(td).find('select').val();
  } );
};

$.fn.dataTable.ext.order['dom-placement-position'] = function  ( settings, col ) {
  return dataTableExtSortNodes(this, col).map( function ( td, i ) {
    var val = $(td).find('select').val();
    var sortVal = -1;
    if (val === 'pre-roll') {
      sortVal = 0;
    }
    else if (val === 'post-roll') {
      sortVal = 100;
    }
    else if (/\d$/.test(val)) {
      var split = val.split('-');
      sortVal = split[split.length - 1];
    }
    return sortVal;
  } );
};

$.fn.dataTable.ext.order['dom-radio'] = function  ( settings, col ) {
  return dataTableExtSortNodes(this, col).map( function ( td, i ) {
    return $(td).find("input[type='radio']:checked").val();
  } );
};

$.fn.dataTable.ext.order['pc-waterfall-demand-class'] = function  ( settings, col ) {
  return dataTableExtSortNodes(this, col).map( function ( td, i ) {
    return $(td).text() === '7';
  } );
};

$.fn.dataTable.ext.type.search.radio = function ( data ) {
  var val = $(data).find("input[type='radio']:checked").val();
  return val || '';
};

$.fn.dataTable.ext.order['range-compare-span'] = function  ( settings, col ) {
  return dataTableExtSortNodes(this, col).map( function ( td, i ) {
    var numMatch = /[+|-]*[0-9]*\.*[0-9]+/;
    //get the first span element within the td
    var span = ($(td).find("span")[0]);
    var content = "0.0";
    if(span){
      content = span.innerHTML;
    } else {
      //if there is no span (for example when there is no past report)
      var tdSelector = $(td)[0];
      if(tdSelector){
        content = tdSelector.innerHTML;
      }
    }
    var match = content && content.replace(/[^0-9.-]/g,"").match(numMatch)[0] || "0.0";
    return parseFloat(match);
  });
};

$(document).on("change", ".dataTables_wrapper table input[type='radio']", function(e) {
  var input = $(e.target);
  var table = input.parents(".dataTables_wrapper table");
  var datatable = table.DataTable();
  var td = input.parents(".dataTables_wrapper table td");
  var cell = datatable.cell(td);
  if (table.find("thead th").eq(cell.index().column).data("type") === 'radio') {
    input.prop("checked", true).attr("checked", "checked");
    $("input[name='"+$(input).attr("name")+"']:radio").not(input).prop("checked", false);
    cell.data(td.html()).draw("page");
  }
});

var setSecondaryHeaderRowSpans = function(table, isFull) {
  var secondaryHeaderRow = $(table).find('thead tr.secondary-header-row');
  var attr = isFull ? 'full-span' : 'lite-span';

  if (secondaryHeaderRow.length < 1) {
    return;
  }

  secondaryHeaderRow.find('th[data-' + attr + ']').each(function(i, th) {
    var newSpan = $(th).data(attr);
    if (newSpan) {
      $(th).attr('colspan', newSpan);
    }
  });
};

var hideHiddenColumns = function(table) {
  var hiddenColumns = $(table).find('th.hidden-column');

  hiddenColumns.each(function(index, elem) {
    platformUtilities.toggleHiddenColumn(table, elem, false);
  });
};

var fullViewOnlyTds = function() {
  return 'td .targeting-icons .fa[data-full-view-only="true"], td .actions li[data-full-view-only="true"]';
};

var toggleFullViewOnlyColumnsDisplay = function(table, display) {
  $(table).parents('.dataTables_wrapper').find(fullViewOnlyTds()).toggleClass('hidden', !display);
};

var toggleColumnsDisplay = function(table, columns, display) {
  columns.each(function(index, elem) {
    var th = $(elem);
    var colIndex = th.index();
    th.toggle(display);
    table.find('tr td:nth-child(' + (colIndex + 1) + ')').toggle(display);
  });
};

var toggleColumns = function(table, display, trackInMixpanel) {
  hideHiddenColumns(table);

  var columns = $(table).find('th.full-view-only').not('.hidden-column');

  if (!includeFullLiteToggles(table)) {
    return;
  }

  display = !!display;

  if (trackInMixpanel) {
    mixpanel.track('datatables lite/full change', {
      table_id: $(table).attr('id'),
      value: display ? 'full' : 'lite'
    });
  }

  setSecondaryHeaderRowSpans(table, display);
  toggleColumnsDisplay(table, columns, display);
  toggleFullViewOnlyColumnsDisplay(table, display);

  // we don't keep a reference to wrapper here so I have to traverse in this
  // weird way.  We want to do this so we select the closest one, and not all
  // scroll-fake-contents
  var width = $(table).width();
  var scrollContent = $(table).closest(".wrapper").siblings(".datatable-top-scroll-bar").children(".scroll-fake-content");
  scrollContent.width(width);
};

var addDividerBorders = function(table, headerClass) {
  var borderHeaders = $(table).find('thead tr.primary-header-row th.' + headerClass);
  if (!borderHeaders.length) {
    return;
  }

  borderHeaders.each(function(index, elem) {
    var th = $(elem);
    var colIndex = th.index();
    table.find('tr td:nth-child(' + (colIndex + 1) + ')').addClass(headerClass);
  });
};

var addDividers = function(table) {
  addDividerBorders(table, 'th-br');
  addDividerBorders(table, 'th-bl');
};

var initPopovers = function(table) {
  $(table).find('[data-toggle="popover"]').popover();
  hidePopovers(table);
};

var hidePopovers = function(table) {
  if ($(table).find('[aria-describedby*="popover"]').length > 0) {
    $('.popover').popover('hide');
  }
};

var hideTooltips = function(table) {
  if ($(table).find('[aria-describedby*="tooltip"]').length > 0) {
    $('.tooltip').tooltip('hide');
  }
};

var adjustDataTablesColumns = function(table) {
  var tableSelector = $(table);

  if (tableSelector.data('scrollY') && tableSelector.data('scrollX') && $.fn.dataTable.isDataTable(tableSelector)) {
    setTimeout(function() {
      tableSelector.DataTable().columns.adjust();
    }, 0);
  }
};

var initDataTableProcessing = function(table) {
  $(table).on('processing.dt', function(e, settings, processing) {
    if (processing) {
      $(table).find('tbody,tfoot').addClass('dt-processing');
      $(table).find('td.dataTables_empty').addClass('hidden');
    }
    else {
      $(table).find('tbody,tfoot').removeClass('dt-processing');
      $(table).find('td.dataTables_empty').removeClass('hidden');
    }
  });
};

var includeFullLiteToggles = function(table) {
  return $(table).find('th.full-view-only:not(.hidden-column)').length > 0 ||
    $(table).find(fullViewOnlyTds()).length > 0;
};

var setDataTablesToggles = function(table, filterPlace) {
  if ( includeFullLiteToggles(table) ) {
    var toggles = $('<div class="btn-toolbar toggle lite-full-toggles"><div class="btn-group btn-group-toggle" data-toggle="buttons"><label class="toggle-lite btn btn-outline-secondary active"><input type="radio" name="options" id="optionLite">Lite</label><label class="toggle-full btn btn-outline-secondary"><input type="radio" name="options" id="optionFull">Full</label></div></div>');
    var togglePlace = filterPlace.find('> .date-group');
    toggles.insertBefore(togglePlace);

    toggles.find('.toggle-full').click(function(){ toggleColumns(table, true, true); });
    toggles.find('.toggle-lite').click(function(){ toggleColumns(table, false, true); });
  }
  else {
    $(filterPlace).addClass('no-lite-full-toggles');
  }
};

var extractDataTablesFilterOptions = function(column) {
  return column.data().map(function ( d, j ) {
    var text;
    try {
      text = $(d).text();
    } catch(err) {
      return d;
    }
    if (text.length === 0 && d && d.length !== 0) {
      return d;
    } else {
      return text;
    }
  }).unique();
};

var initDataTablesDateFilter = function(table, column, th, filterPlace) {
  var filterId = th.data('filterId') || 'dfilter_' + th.text().toLowerCase().split(' ').join('_');
  var datePlace = filterPlace.find('> .date-group');
  var dateFilter = $('<div class="date-filter"></div>');
  var filterLabel = th.data('filterLabel') || 'All ' +th.text()+ 's';
  var filter = $('<select class="form-control chosen" id="'+filterId+'"><option value=""><span class="fa fa-calendar-days"></span>'+filterLabel+'</option></select>');
  var filterOptions = th.data('filterOptions');

  if(!filterOptions) {
    filterOptions = extractDataTablesFilterOptions(column);
  }

  var filterDefault = th.data('filterDefault');
  var forecastingDateRanges = th.data('forecastingDateRanges');

  $.each(filterOptions, function(i, t) {
    if(t.length > 0) {
      var optionLabel, optionValue;
      if(Array.isArray(t)){
        optionLabel = t[0];
        optionValue = t[1];
      } else{
        optionLabel = optionValue = t;
      }
      var option = $('<option></option>').attr('value', optionValue).text(optionLabel);
      if(filterDefault && optionValue === filterDefault) option.attr('selected', true);
      filter.append(option);
    }
  });

  filter.on('change', function () {
    var val = $(this).val();
    trackDatatablesFilterInMixpanelFromElement(this);
    columnSearch(column, th, val);
  } );

  dateFilter.append(filter);
  datePlace.append(dateFilter);

  if (Array.isArray(forecastingDateRanges)) {
    const forecastingDateRangesWithoutT = forecastingDateRanges.filter((item) => item !== 'T');
    const forecastFilter = initForecastFilter(forecastingDateRangesWithoutT);

    forecastFilter.on('change', function() {
      table.api().draw();
    });

    table.on('preXhr.dt', function(e, settings, data) {
      const forecast_date_range = $(table).parents('.dataTables_wrapper').find('#forecast_date_range').val();

      if (forecast_date_range) {
        data.forecast_date_range = forecast_date_range;
      }
    });

    forecastFilter.insertAfter(datePlace);
  }
};

var initForecastFilter = (forecastingDateRanges) => {
  var forecastFilterOptions = _.map(forecastingDateRanges, function(forecastingDateRange) {
    return '<option value = ' + forecastingDateRange +  '>' + forecastingDateRange + '</option>';
  });

  return $('<div class="forecast-date-group"><div class="forecast-date-filter"><select class="form-control chosen" id="forecast_date_range"><option value="">0</option>' + forecastFilterOptions + '</select></div></div>');
};

var initDataTablesIconFilter = function(column, th, filterPlace, headerClass) {
  var filterId = th.data('filterId') || 'dfilter_' + th.text().toLowerCase().split(' ').join('_');
  var iconFilter = $('<div class="' + headerClass + '"></div>');
  var filterLabel = th.data('filterLabel') || 'All';
  var filter = $("<div class='btn-group btn-group-toggle pill-filter' data-toggle='buttons'></div>");
  var filterDefault = th.data('filterDefault');
  var filterOptions = th.data('filterOptions');
  filterOptions.unshift([filterLabel, '']);

  $.each(filterOptions, function(i,t){
    var value = t[1] === undefined ? t[0] : t[1];
    var tooltipText = t[2] || 'All';

    var optionId = filterId + '_' + value;
    var active = filterDefault === undefined ? i === 0 : filterDefault === value;
    var checked = active ? "checked='checked'" : '';
    var label = $("<label data-toggle='tooltip' data-placement='top' data-container='body' title='" + tooltipText + "' class='btn btn-outline-secondary' for='"+optionId+"'>"+ t[0] +"</label>").toggleClass('active', active);
    var input = "<input type='radio' name='"+ filterId + "' value='" + value + "' id='" + optionId+ "' " + checked + " />";
    label.append(input);
    filter.append(label);
  });

  filter.on('change', 'input', function () {
    var val = $(this).val();
    trackDatatablesFilterInMixpanelFromElement(this);
    columnSearch(column, th, val);
  } );

  iconFilter.append(filter);
  filterPlace.append(iconFilter);
};

var trackDatatablesFilterInMixpanelFromElement = function(elementSelector) {
  const filterElement = $(elementSelector);
  const tableId = filterElement.parents('.dataTables_wrapper').find('table').attr('id');
  const filterId = filterElement.attr('id');
  const filterValue = filterElement.val();

  const forecast_date_range = filterElement.parents('.dataTables_wrapper').find('#forecast_date_range');
  const forecast_date_range_chosen = filterElement.parents('.dataTables_wrapper').find('#forecast_date_range_chosen');
  if(filterValue === "Last 24 Hours"){
    forecast_date_range.val('').trigger('chosen:updated');
    forecast_date_range_chosen.addClass("chosen-disabled");
  }else{
    forecast_date_range_chosen.removeClass("chosen-disabled");
  }
  trackDatatablesFilterInMixpanel(tableId, filterId, filterValue);
};

var trackDatatablesFilterInMixpanel = function(tableId, filterId, filterValue) {
  mixpanel.track('datatables filter change', {
    table_id: tableId,
    element_id: filterId,
    value: filterValue
  });
};

var initDataTablesColumnFilter = function(column, th, table, columnFilters) {
  var isServerSideTable = $(table).DataTable().page.info().serverSide;
  var filter;
  var filterEvent;
  var filterLabel;
  var filterId = th.data("filterId") || "dfilter_" + th.text().toLowerCase().split(' ').join('_');
  var filterSelector = null;
  var listItem = $('<li class="list-inline-item"></li>');
  var exactMatchSearch = true;
  var filterType = th.data("filterType");
  var filterThrottle = false;
  var filterValue;
  var filterOptions;
  var filterMinChars = 0;
  var tableId = $(table).attr('id');
  if(filterType === 'big_list') {
    var exactMatchFilterId = filterId + '_exact_match';
    filterEvent = 'keyup';
    filterSelector = 'input[type="text"]';
    filterLabel = th.data("filterLabel") || "Search Items";
    filter = $('<div class="input-group">' +
                '<div class="input-group-prepend tooltip-addon">' +
                  '<div class="input-group-text">' +
                    '<div class="inline-block" data-toggle="tooltip" data-placement="top" data-container="body" title="Filters lists used in the last 24 hours containing the searched text"><i class="fa fa-info-circle"></i></div>' +
                  '</div>' +
                '</div>' +
                '<input class="form-control" placeholder="' + filterLabel + '" id="' + filterId + '" type="text" />' +
                '<div class="input-group-append">' +
                  '<div class="input-group-text">' +
                    '<input type="checkbox" id="' + exactMatchFilterId + '" class="checkbox-custom">' +
                    '<label for="' + exactMatchFilterId + '" class="checkbox-custom-label checkbox-custom-label-sm">Exact Match</label>' +
                  '</div>' +
                '</div>' +
              '</div>');
    exactMatchSearch = false;
    listItem.addClass("big-list-filter");
    filterThrottle = true;
    filterMinChars = 2;
  } else if(filterType === 'text'){
    filterEvent = 'keyup';
    filterLabel = th.data("filterLabel") || "Search " +th.text()+ "s";
    filter = $('<input class="form-control form-control-sm" placeholder="'+ filterLabel +'" id="'+filterId+'" type="text"/>');
    exactMatchSearch = false;
    filterThrottle = true;
  } else if(filterType === 'pill'){
    filterEvent = "change";
    filterLabel = th.data("filterLabel") || "All";
    filterOptions = th.data("filterOptions");
    filterOptions.unshift([filterLabel,'']);
    filter = $("<div class='btn-group btn-group-toggle' data-toggle='buttons'></div>");
    filterSelector = "input";

    $.each(filterOptions, function(i,t){
      var value = t[1] === undefined ? t[0] : t[1];
      var optionId = filterId + "_" + value;
      var label = $("<label class='btn btn-outline-secondary' for='"+optionId+"'>"+ t[0] +"</label>").toggleClass("active", i === 0);
      var input = "<input type='radio' name='"+ filterId + "' value='" + value + "' id='" + optionId+ "' />";
      label.append(input);
      filter.append(label);
    });
    listItem.addClass("pill-filter");
  } else{
    filterEvent = 'change';
    filterLabel = th.data("filterLabel") || "All " +th.text()+ "s";
    filter = $('<select class="form-control form-control-sm" id="'+filterId+'"><option value=""><span class="fa fa-bars-filter"></span>'+filterLabel+'</option></select>');
    filterOptions = th.data("filterOptions");
    if(!filterOptions){
      filterOptions = column.data().map( function ( d, j ) {
        var text;
        try {
          text = $(d).text();
        } catch(err) {
          return d;
        }
        if (text.length === 0 && d && d.length !== 0) {
          return d;
        } else {
          return text;
        }
      } ).unique();
    }

    var filterDefault = th.data('filterDefault');
    $.each(filterOptions, function(i, t) {
      if(t.length > 0){
        var optionLabel, optionValue, optionData;
        if(Array.isArray(t)){
          optionLabel = t[0];
          optionValue = t[1];
          optionData = t[2];
        } else{
          optionLabel = optionValue = t;
        }
        var option = $('<option></option>').attr('value', optionValue).text(optionLabel);
        if (optionData) {
          $.each(optionData, function(k, v) {
            option.attr('data-' + k, v);
          });
        }
        if(filterDefault && optionValue === filterDefault) option.attr('selected', true);
        filter.append(option);
      }
    });
  }

  var getAppliedFilterValue = function(filterValue) {
    var appliedValue = ( filterValue && filterValue.length < filterMinChars ) ? '' : filterValue;
    if (th.data('removeNameWithDcSuffix')) {
      appliedValue = appliedValue.replace(/ \(PC\)$| \(DC\)$| \(HB\)$/, '');
    }
    return appliedValue;
  };

  var executeFilter = function() {
    var appliedFilterValue = getAppliedFilterValue(filterValue);

    trackDatatablesFilterInMixpanel(tableId, filterId, filterValue);

    if (isServerSideTable || !appliedFilterValue || !exactMatchSearch) {
       column.search( appliedFilterValue , false , true, false)
             .draw(th.data("filterPaging"));
    }
    else {
      var searchInput;
      var regexEscapedVal;
      if(th.hasClass("label-env-filter") && appliedFilterValue === 'any'){
        searchInput = '^$|^dc$|_\\$unfilterable\\$_';
      } else if ( th.data("csvSearch") ) {
        regexEscapedVal = jQuery.ui.autocomplete.escapeRegex(appliedFilterValue);
        searchInput = '(,|^)' + regexEscapedVal + '($|,)';
      } else {
        regexEscapedVal = jQuery.ui.autocomplete.escapeRegex(appliedFilterValue);
        var startsWith = th.data('ignoreIconPrefix') ? '' : '^';
        searchInput = startsWith + regexEscapedVal + '$' + '|_\\$unfilterable\\$_';
      }
      column.search( searchInput, true, false, false)
             .draw(th.data("filterPaging"));
    }
  };

  var throttledExecuteFilter = _.throttle(function() {
    executeFilter();
  }, 300, {leading: false});

  filter.on( filterEvent, filterSelector, function() {
    filterValue = $(this).val();

    if (filterThrottle) {
      throttledExecuteFilter();
    }
    else {
      executeFilter();
    }
  });

  if (filterType === 'big_list') {
    filter.on( 'change', 'input[type="checkbox"]', function() {
      filterValue = $(this).closest('.big-list-filter').find('input[type="text"]').val();
      executeFilter();
    });
  }

  listItem.append(filter);

  setTimeout(function() {
    $(table).trigger('init.filter.ss.dt', [filterId]);
  }, 0);

  if(th.hasClass("hidden-column") && !th.hasClass("show-hidden-filter")){
    listItem.addClass("hidden");
  }
  columnFilters.find(".list-inline").append(listItem);
};

var dataTableInitFilters = function(that) {
  var filterPlace = that.parents(".dataTables_wrapper").find('> .top');
  var expandFilters = $(that).data('expandFilters');
  var inState = (expandFilters) ? ' show' : '';
  var collapsedState = (expandFilters) ? '' : ' collapsed';
  var filterToggle = $('<div id="filter-row-' + $(that)[0].id + '" class="filter-row"><a class="filter-collapse btn btn-ss-default ' + collapsedState + '" data-toggle="collapse" data-target="#column-filters-' + $(that)[0].id + '">Filters</a></div>');
  var columnFilters = $('<div id="column-filters-' + $(that)[0].id + '" class="column-filters collapse' + inState + '"><ul class="list-inline"></ul></div>');
  var filterColumns = false;

  initDataTableProcessing(that);

  setDataTablesToggles(that, filterPlace);

  that.api().columns().every( function () {
    var column = this;
    var th = $(column.header());

    if (th.hasClass('date-filter')) {
      initDataTablesDateFilter(that, column, th, filterPlace);
    }

    _.each(platformUtilities.pillFilters, function(k) {
      if (th.hasClass(k)) {
        initDataTablesIconFilter(column, th, filterPlace, k);
      }
    });

    if(th.hasClass('column-filter') ){
      initDataTablesColumnFilter(column, th, that, columnFilters);
      filterColumns = true;
    }
  });

  if (filterColumns) {
    filterPlace.append(filterToggle);
    columnFilters.insertAfter(filterPlace);
  }

  $('.dataTables_wrapper .top select, .dataTables_wrapper .column-filters select').defaultChosen();
  that.api().draw();
};

function defaultDataTableInitComplete(){
  var that = this;

  $(this).on('reloadDataTableFilters', function () {
    $("#filter-row-" + $(that)[0].id).remove();
    $("#column-filters-" + $(that)[0].id).remove();
    dataTableInitFilters(that);
  });

  dataTableInitFilters(this);
  dataTableStickyTableHeaders.sticky(this);

  var throttledAdjustDataTablesColumns = _.throttle(function() {
    adjustDataTablesColumns(that);
  }, 150, {leading: false});

  $(window).resize(function() {
    throttledAdjustDataTablesColumns();
  });
}

function defaultFnDrawCallback(){
  var that = this;

  addDividers(that);
  initPopovers(that);
  hideTooltips(that);
  adjustDataTablesColumns(that);

  var toggleFull = $(that).parents(".dataTables_wrapper").find(".toggle-full").is(".active");

  if (toggleFull) {
    toggleColumns(that, true);
  } else {
    toggleColumns(that, false);
  }
}

function dataTableSearchCols(table){
  return _.map($(table).find("thead tr:not(.secondary-header-row) th"), function(th) {
    var defaultSearch = $(th).data("filterDefault");

    if(defaultSearch !== undefined) {
      return {"search": String(defaultSearch), "caseInsensitive": false};
    }
  });
}

function columnSearch(column, th, val){
  column
    .search( val , false , true, false)
    .draw(th.data('filterPaging'));
}

var initFilterPartnersByDc = function(table) {
  DatatableFilterLinkHelper.dataTableInitFilterListByBoolean('filter-partners-by-dc-table', table, '#dfilter_partner', 'input[name="dfilter_name"]', function(option, booleanFilterValue) {
    var bool = ['dc', 'true'].includes(booleanFilterValue);
    return / \(DC\)$| \(PC\)$/.test($(option).text()) === bool || $(option).val() === "";
  });
};

$.fn.defaultDateRangeSelect = function() {
  var dateRangeSelect = $(this).attr('name', 'date_range')
                               .attr('id', 'date_range')
                               .addClass('form-control form-control-sm');

  var dateRanges = ['Today', 'Yesterday',  'Last 15 Minutes', 'Last Hour', 'Last 24 Hours', 'Last 72 Hours', 'Last 7 Days', 'Last 30 Days'];

  $.each(dateRanges, function(i, dateRange) {
    dateRangeSelect.append($('<option />').val(dateRange).text(dateRange));
  });

  dateRangeSelect.on('change', function() {
    trackDatatablesFilterInMixpanelFromElement(this);
  });

  return dateRangeSelect;
};

$.fn.defaultForecastFilter = function() {
  // SpringsightForecasting.forecasting_date_ranges
  return initForecastFilter(['7', 'W', '30', 'M']);
};

$.fn.defaultDataTable = function(options = {}) {
  var deferRender = !$(this).find("th[data-order-data-type]").length;
  var that = this;
  toggleColumns(that, false);
  that.on('xhr.dt', function ( e, settings, json, xhr ) {
    if(xhr.status && !xhr.status.toString().match("^2")){
      if(xhr.status !== 401 && xhr.status !== 403){
        SwalHelper.alertWarning(xhr.statusText);
        var tableAttributes = _.reduce( this.attributes, function ( attrs, attribute ) {
            attrs[attribute.name] = attribute.value;
            return attrs;
        }, {} );

        mixpanel.track('datatables error', _.extend(tableAttributes, {
          status: xhr.status,
          statusText: xhr.statusText
        }));
      }
    }
  });

  that.on('init.dt', function() {
    initFilterPartnersByDc(that);
  });

  that.on('preXhr.dt', function (e, settings, data) {
    var bigListExactMatch = $(this).closest('.dataTables_wrapper').find('.big-list-filter input[type="checkbox"]').prop('checked');
    var tableHeaderColumnIds = $(this).data('tableHeaderColumnIds');

    data.custom_data = data.custom_data || {};

    if ( bigListExactMatch ) {
      data.custom_data.big_list_exact_match = bigListExactMatch;
    }

    if (tableHeaderColumnIds) {
      data.custom_data.table_header_column_ids = tableHeaderColumnIds;
    }
  });

  that.on('preDraw.dt', function(e, settings, data) {
    hidePopovers(that);
    hideTooltips(that);
  });

  var defaultOptions = {
    "dom": '<"top"<"date-group">f>' +
    '<"wrapper"rt>' +
    '<"bottom"lp><"clear">',
    "orderClasses": false,
    'deferRender': deferRender,
    'colResize': {
       include: [1]
    },
    'columnDefs': [{
      'orderable': false,
      'targets': ['no-sort']
    },{
      'sorting': ['desc', 'asc'],
      'targets': ['sort-desc']
    }],
    "iDisplayLength": 25,
    "lengthMenu": [[10, 25, 50, 200], [10, 25, 50, 200]],
    "searchCols": dataTableSearchCols(that),
    "search": {
      "search": that.data("defaultSearch")
    },
    "processing": true,
    "oLanguage": {
      "sProcessing": "<div class='dt-spin'></div>"
    },
    "bAutoWidth": false,
    initComplete: defaultDataTableInitComplete,
    fnDrawCallback: defaultFnDrawCallback
  };

  var dataTable = $(this).DataTable($.extend(defaultOptions, options));

  var appendHeaderTooltip = function(th, opts) {
    var classes = opts.classes || 'fa fa-info-circle';
    $(th).append(' <div class="inline-block" data-placement="top" data-container="body" data-toggle="tooltip" title="' + opts.text + '"><i class="' + classes + '"></i></div>');
  };

  var demandReport = $('#demand_report').val() === '1';
  var theme = window.platformTheme;

  // name overrides
  $("#results .report-table .table-headers").each(function(i, th){
    var text = $(th).text($(th).text().replace("Rate", "%"));
    var lowerText = $(th).text().toLowerCase();

    // append tooltip to IVT headers
    if(/^ivt/.test(lowerText)) {
      appendHeaderTooltip(th, {
        text: 'Note that IVT data is 8 hours behind other reporting data. This can lead to initial zeroes in this column.'
      });
    }

    // append tooltip to IAS post-imp headers
    if(/^ias/.test(lowerText)) {
      appendHeaderTooltip(th, {
        text: 'This is IAS log level data aggregated by Springserve, it is not MRC accredited, and may slightly vary from IAS\'s MRC accredited data.'
      });
    }

    // append tooltip to WhiteOps headers
    if(/whiteops/.test(lowerText)) {
      appendHeaderTooltip(th, {
        text: 'Note that there is a 72 hour delay in HUMAN pre-bid data. This can lead to initial zeroes in this column.',
        classes: 'whiteops-icon'
      });
    }

    // append tooltip to Protected pre-bid headers
    if(/pre-bid protected/.test(lowerText)) {
      appendHeaderTooltip(th, {
        text: 'Data delayed by 2 hours. Blocked data includes all Fraudulent and Suspicious traffic.'
      });
    }

    if(/vast responses/.test(lowerText)) {
      appendHeaderTooltip(th, {
        text: 'Aggregated for broadcast calls only.'
      });
    }

    // append tooltip to Error headers if demand report
    if(/bidder error/.test(lowerText)) {
      appendHeaderTooltip(th, {
        text: 'Bidder Errors are specific to PC.' + theme.wikiInstructions
      });
    }
    else if(/errors/.test(lowerText) && demandReport) {
      appendHeaderTooltip(th, {
        text: 'Errors include VAST, VPAID, and custom errors.' + theme.wikiInstructions
      });
    }
  });
  return dataTable;
};

$(document).on('turbo:load', function() {
  //need to call each one individually
  $.each($(".datatable"), function(index, table){
    $(table).defaultDataTable({});
  });

  // ensure all elements are in dom before submitting
  $("form").submit(function(e){
    $.each($(this).find(".dataTables_wrapper table:not(.readonly-datatable)"), function( index, table ) {
      $(table).hide();

      if (!$(table).data('serverSide')) {
        var datatable = $(table).DataTable();

        if (datatable) {
          datatable.search('');
          datatable.columns().every( function () { this.search(''); });
          datatable.draw();
          datatable.rows().nodes().page.len(-1).draw(false);
        }
      }
    });
  });

});

import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('keySharing',  {
    bindings: {
      formName: '@',
      formId: '@',
      keySharingEnabled: '<',
      selectedKeySharingOtherAccounts: '<'
    },
    template: require("../templates/keySharing.html"),
    controllerAs: '$keySharingCtrl',
    controller: function() {
      this.attributeEnabledOptions = componentHelper.attributeEnabledMappings();
    }
});

angular.module('vastdesk').
  component('accountLogLevelData',  {
    bindings: {
      logLevelImpressions: '@',
      logLevelDataFee: '<',
      logLevelDataFeeCommencementDate: '@',
      logLevelImpressionsOptions: '<'
    },
    template: require("../templates/accountLogLevelData.html"),
    controllerAs: '$accountLogLevelDataCtrl',
    controller: function() {

      var self = this;

      this.logLevelDataFeePlaceholder = function() {
        if (self.logLevelImpressions === 'tier_1') {
          return 500;
        }
        else if (self.logLevelImpressions === 'tier_2') {
          return 750;
        }
        else if (self.logLevelImpressions === 'tier_3') {
          return 1000;
        }
      };

      this.logLevelImpressionsTooltip = function(logLevelImpressions) {
        if (logLevelImpressions === 'tier_1') {
          return 'imps';
        }
        else if (logLevelImpressions === 'tier_2') {
          return 'imps, opps, starts, clicks, completes';
        }
        else if (logLevelImpressions === 'tier_3') {
          return 'imps, supply/demand opps, starts, clicks, completes, supply/demand requests, supply/demand error, quartiles, missed opps, demand response, openrtb wins, ssb demand error/request, response';
        }
      };

    }
});

class HighchartsBuilder {
  constructor(timezone) {
    const theme = window.platformTheme;
    this.timezone = timezone;
    this.percentMetrics = [
      'fill_rate', 'opportunity_rate', 'net_margin', 'opportunity_fill_rate', 'moat_viewability_rate',
      'moat_complete_audible_visible_rate', 'moat_human_rate', 'mismatched_domain_rate',
      'openrtb_bid_fill_rate', 'openrtb_bid_rate', 'openrtb_win_rate', 'openrtb_win_fill_rate',
      'openrtb_sell_through_rate', 'moat_bot_rate', 'ias_ivt_rate', 'ad_pod_fill_rate', 'efficiency_rate',
      'completion_rate', 'click_through_rate', 'ad_rate', 'use_rate', 'timeout_rate'
    ];
    this.moneyMetrics = ['revenue', 'cost', 'net_profit', 'rpm', 'cpm'];
    this.chartColors = [
      theme.primaryColor, theme.secondaryColor, theme.tertiaryColor,
      '#f0b310', '#2acc9c', '#1d66d3', '#f30c43',
      '#06565e', '#e51499', '#2a2e7f', '#dddddd',
      theme.quaternaryColor
    ];
  }

  defaultLegend() {
    const self = this;

    return {
      labelFormatter: function() {
        return self.getName(this.name);
      },
      padding: 0,
      itemMarginTop: 0,
      itemStyle: {
        lineHeight: '10px',
        fontSize: '10px',
        color: '#484848',
        fontWeight: '400'
      },
      symbolWidth: 8,
      symbolHeight: 8,
      symbolPadding: 2,
      itemDistance: 20
    };
  }

  defaultShadow() {
    return {
      color: '#000000',
      offsetX: 1,
      offsetY: 1,
      opacity: 0.075,
      width: 2.5
    };
  }

  forecastDataFromTimeValuesOptions(scope, forecast) {
    return {
      forecast: forecast,
      forecastDateRange: scope.forecastDateRange,
      interval: scope.pointsType
    };
  }

  forecastDateRangePresent(forecastDateRange, interval) {
    return _.contains(['hour', 'day'], interval) &&
      forecastDateRange &&
      forecastDateRange !== '';
  }

  includeForecastingSeries(forecastDateRange, interval, forecastData) {
    return this.forecastDateRangePresent(forecastDateRange, interval) &&
      Array.isArray(forecastData);
  }

  getNextDayOfWeek(currentTime, dow) {
    if ( currentTime.clone().isoWeekday() <= dow ) {
      return currentTime.clone().isoWeekday(dow);
    }
    else {
      return currentTime.clone().add(1, 'weeks').isoWeekday(dow);
    }
  }

  springyAreaSplineFill(color) {
    return {
      linearGradient: {
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 1
      },
      stops: [
        [0, new Highcharts.Color(color).setOpacity(0.6).get('rgba')],
        [1, new Highcharts.Color(color).setOpacity(0).get('rgba')]
      ]
    };
  }

  columnColor(color, forecast) {
    if (forecast) {
      return this.stripeFillColor(color);
    }
    else {
      return color;
    }
  }

  seriesNameFor(metric, forecast) {
    if (forecast) {
      return 'Forecasted ' + metric;
    }
    else {
      return metric;
    }
  }

  getName(name) {
    if(/fa-bolt/.test(name)) {
      name += ' (DC)';
    } else if(/fa-cog/.test(name)) {
      name += ' (PC)';
    }
    return name;
  }

  formatTypeForMetric(metric) {
    let formatType = "number";
    if(this.percentMetrics.indexOf(metric) > -1){
      formatType = "percent";
    } else if(this.moneyMetrics.indexOf(metric) > -1){
      formatType = "money";
    }
    return formatType;
  }

  getMoment(time) {
    var timeWithoutZ = typeof(time) === 'string' ? time.replace(/Z$/, '') : time;

    if(this.timezone) {
      return moment.tz(timeWithoutZ, this.timezone);
    } else {
      return moment(timeWithoutZ);
    }
  }

  chartRgbaColors(opacity) {
    return _.map(this.chartColors, function(hex) {
      hex = hex.replace('#','');
      var r = parseInt(hex.substring(0,2), 16);
      var g = parseInt(hex.substring(2,4), 16);
      var b = parseInt(hex.substring(4,6), 16);

      var result = 'rgba('+r+','+g+','+b+','+opacity/100+')';
      return result;
    });
  }

  reflowChart(div) {
    setTimeout(function() {
      var divHighcharts = div.highcharts();
      if (divHighcharts) {
        divHighcharts.reflow();
      }
    }, 0);
  }

  deferredReflowChart(onInitLoad, div) {
    var that = this;

    $.when( onInitLoad ).done(function() {
      that.reflowChart(div);
    });
  }

  stripeFillColor(color) {
    return {
      pattern: {
        color: color,
        path: {
          d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
          strokeWidth: 3
        },
        width: 10,
        height: 10,
        opacity: 0.5
      }
    };
  }

  setPanelLoading(chartDiv, args, toggle) {
    var selector = args.selector || '.refreshing-panel';
    var parent = args.parent || '.card-body';
    chartDiv.parents(parent).find(selector).toggleClass('hidden', toggle);
  }

  loadData(onInitLoad, chartDiv, self, args) {
    var that = this;

    $.when( onInitLoad ).done(function() {
      if (args.id === self.chartId && self.noRefreshingPanel !== 'true') {
        that.setPanelLoading(chartDiv, args, false);
      }
    });
  }

  refreshData(onInitLoad, chartDiv, self, args, cb) {
    var that = this;

    $.when( onInitLoad ).done(function() {
      if (args.id === self.chartId) {
        setTimeout(function() {
          cb();
          that.setPanelLoading(chartDiv, args, true);
        }, 0);
      }
    });
  }

  getDataFromHourValues(dataPoints, results, attr, options = {}) {
    var self = this;
    var currentHour = this.getMoment().hour();

    _.each(results, function(r){
      if(typeof(r) === 'object' && !r.hourVal) {
        r.hourVal = self.getMoment(r.ymdh).hour();
      }
    });

    return _.map(dataPoints, function(dataPoint) {
      var hourVal = dataPoint.hour();
      var result = _.find(results, function(r) {
        return typeof(r) === 'object' && r.hourVal === hourVal;
      });

      if (result && result[attr]) {
        return result[attr];
      }
      else if (options.todayForecast && hourVal < currentHour) {
        return null;
      }
      else {
        return 0;
      }
    });
  }

  getDataFromTimeValues(dataPoints, results, attr, options = {}) {
    var self = this;
    var forecastDateRangePresent = self.forecastDateRangePresent(options.forecastDateRange, options.interval);
    var currentTime = this.getMoment();
    var currentUnixTimeVal = options.interval == 'hour' ? currentTime.startOf('hour').unix() : currentTime.startOf('day').unix();

    _.each(results, function(r){
      if (typeof(r) === 'object' && !r.unixVal) {
        r.unixVal = self.getMoment(r.ymdh).unix();
      }
    });

    return _.map(dataPoints, function(dataPoint) {
      var unixVal = dataPoint.unix();
      var result = _.find(results, function(r) {
        return typeof(r) === 'object' && r.unixVal === unixVal;
      });

      if (result && result[attr]) {
        return result[attr];
      }
      else if (forecastDateRangePresent) {
        if (options.forecast && unixVal < currentUnixTimeVal) {
          return null;
        }
        else if (!options.forecast && unixVal > currentUnixTimeVal) {
          return null;
        }
        else {
          return 0;
        }
      }
      else {
        return 0;
      }
    });
  }

  hourStartTime(currentTime, dateRange) {
    if (dateRange==='Yesterday') {
      return currentTime.clone().startOf('day').subtract(1, 'day');
    } else if (dateRange==='Last 24 Hours') {
      return currentTime.clone().startOf('hour').subtract(1, 'day');
    } else if (dateRange==='Last 72 Hours') {
      return currentTime.clone().startOf('hour').subtract(3, 'day');
    } else if (dateRange==='Last 7 Days') {
      return currentTime.clone().startOf('hour').subtract(7, 'day');
    } else if (dateRange==='Last 30 Days') {
      return currentTime.clone().startOf('hour').subtract(30, 'day');
    } else if (dateRange==='Last Month') {
      return moment(currentTime).date(0).startOf('month');
    } else if (dateRange==='Month to Date') {
      return currentTime.clone().startOf('month');
    } else {
      return currentTime.clone().startOf('day');
    }
  }

  minuteStartTime(currentTime, dateRange) {
    if (dateRange==='Last 15 Minutes') {
      return currentTime.clone().subtract(15, 'minute');
    } else if (dateRange==='Last Hour') {
      return currentTime.clone().subtract(60, 'minute');
    } else {
      var twoAM = currentTime.clone().startOf('day').add(2, 'hour');

      if (currentTime.isBefore(twoAM)) {
        return currentTime.clone().startOf('day');
      }
      else {
        return currentTime.clone().subtract(120, 'minute');
      }
    }
  }

  minuteEndTime(currentTime) {
    return currentTime.clone().subtract(1, 'minute');
  }

  forecastDateRangeEndTime(currentTime, forecastDateRange) {
    if (forecastDateRange === 'T') {
      return currentTime.clone().endOf('day');
    }
    else if (forecastDateRange == '7') {
      return currentTime.clone().endOf('hour').add(7, 'day');
    }
    else if (forecastDateRange == 'W') {
      return this.getNextDayOfWeek(currentTime, 6).endOf('day');
    }
    else if (forecastDateRange == '30') {
      return currentTime.clone().endOf('hour').add(30, 'day');
    }
    else if (forecastDateRange == 'M') {
      return currentTime.clone().endOf('month');
    }
  }

  dateRangeEndTime(currentTime, dateRange) {
    if (dateRange === 'Yesterday') {
      return currentTime.clone().subtract(1, 'day');
    } else if (dateRange == 'Last Month'){
      return  moment(currentTime).date(0).endOf('month');
    } else {
      return currentTime.clone().subtract(1, 'minute');
    }
  }

  hourEndTime(currentTime, dateRange, forecastDateRange) {
    if (forecastDateRange) {
      return this.forecastDateRangeEndTime(currentTime, forecastDateRange);
    }
    else {
      return this.dateRangeEndTime(currentTime, dateRange);
    }
  }

  numForecastDays(currentTime, forecastDateRange) {
    if (forecastDateRange == '7') {
      return 7;
    }
    else if (forecastDateRange == 'W') {
      var endOfWeek = this.getNextDayOfWeek(currentTime, 6).endOf('day');
      return endOfWeek.diff(currentTime, 'days');
    }
    else if (forecastDateRange == '30') {
      return 30;
    }
    else if (forecastDateRange == 'M') {
      var endOfMonth = currentTime.clone().endOf('month');
      return endOfMonth.diff(currentTime, 'days');
    }
    else {
      return 0;
    }
  }

  getDaysDataPoints(numDays, forecastDateRange) {
    var currentTime = this.getMoment();
    var startOfToday = currentTime.clone().startOf('day');
    var rangeDays = numDays + 1 + this.numForecastDays(currentTime, forecastDateRange);
    return _.map(_.range(rangeDays), function (d) {
      return startOfToday.clone().subtract((numDays - d), 'd');
    });
  }

  getDaysDataPointsByDateRange(startDate, endDate) {
    var numDays = endDate.diff(startDate, 'days');
    var endDay = endDate.clone().startOf('day');
    return _.map(_.range(numDays + 1), function (d) {
      return endDay.clone().subtract((numDays - d), 'd');
    });
  }

  getHourDataPoints(dateRange, lastHour, forecastDateRange) {
    var currentTime = this.getMoment();
    var startTime = this.hourStartTime(currentTime, dateRange);
    var endTime = this.hourEndTime(currentTime, dateRange, forecastDateRange);
    var difference = (!forecastDateRange && lastHour) || endTime.diff(startTime, 'hours');

    return _.map(_.range(difference + 1), function(h) {
      return startTime.clone().add(h, 'h');
    });
  }

  getMinuteDataPoints(dateRange, skipInterval=1) {
    var currentTime = this.getMoment().startOf("minute");
    var startTime = this.minuteStartTime(currentTime, dateRange);

    startTime = startTime.subtract(startTime.minutes() % skipInterval, 'minutes'); // This is how you make it 5 minute buckets

    var endTime = this.minuteEndTime(currentTime);
    endTime = endTime.subtract(endTime.minutes() % skipInterval, 'minutes');

    var difference = endTime.diff(startTime, 'minutes');

    return _.map(_.range(0, difference, skipInterval), function(m) {
      return startTime.clone().add(m, 'm');
    });
  }

  getDateFormatFromInterval(interval) {
    var format = 'h:mma';
    if (interval === 'day') {
      format = 'MM/DD/YY';
    } else if (interval === 'hour') {
      format = 'ha';
    } else if (interval === 'last7Days') {
      format = 'MMM D';
    }
    return format;
  }

  getDataPointsFromDateRange(dateRange, options = {}) {
    var dp = [];
    var currentTime = this.getMoment();

    if (options.interval === 'fiveMinute') {
      dp = this.getMinuteDataPoints(dateRange, 5);
    } else if (_.contains(['Last 15 Minutes', 'Last Hour'], dateRange) || options.interval === 'minute') {
      dp = this.getMinuteDataPoints(dateRange);
    } else if (dateRange === 'Yesterday') {
      dp = this.getHourDataPoints(dateRange, 23, options.forecastDateRange);
    } else if (dateRange === 'Today' && options.interval === 'day') {
      dp = this.getDaysDataPoints(0, options.forecastDateRange);
    } else if (_.contains(['Today', 'Last 24 Hours'], dateRange) || options.interval === 'hour') {
      dp = this.getHourDataPoints(dateRange, null, options.forecastDateRange);
    } else if (dateRange === 'Month to Date') {
      var startOfMonth = moment(currentTime).startOf('month');
      var numDays = currentTime.diff(startOfMonth, 'days');
      dp = this.getDaysDataPoints(numDays, options.forecastDateRange);
    } else if (dateRange === 'Last 72 Hours') {
      dp = this.getDaysDataPoints(3, options.forecastDateRange);
    } else if (dateRange === 'Last 7 Days') {
      dp = this.getDaysDataPoints(7, options.forecastDateRange);
    } else if (dateRange === 'Last 30 Days') {
      dp = this.getDaysDataPoints(30, options.forecastDateRange);
    } else if (dateRange === 'Last Month') {
      var startOfLastMonth = moment(currentTime).date(0).startOf('month');
      var endDate = options.forecastDateRange ? this.forecastDateRangeEndTime(currentTime, options.forecastDateRange) : moment(currentTime).date(0).endOf('month');
      dp = this.getDaysDataPointsByDateRange(startOfLastMonth, endDate);
    }

    return dp;
  }

  getFilteredChangelogs(changelogs, dataPoints, timeAxis) {
    if (!changelogs) {
      return;
    }
    var that = this;
    var minDataPoint = dataPoints[0];
    var maxDataPoint = dataPoints[dataPoints.length - 1];

    return changelogs.filter(function(changelog) {
      var createdAt = that.getMoment(changelog.created_at);
      return createdAt >= minDataPoint && createdAt <= maxDataPoint;
    });
  }

  addChangelogs(chart, changelogs, dataPoints, dateFormat) {
    var that = this;
    var timeAxis = _.map(dataPoints, function(time) { return time.format(dateFormat); });
    changelogs = that.getFilteredChangelogs(changelogs, dataPoints, timeAxis);

    if (!changelogs || changelogs.length < 1) {
      return;
    }

    var logs = _.map(changelogs, function(log) {
      var _time = that.getMoment(log.created_at).format("h:mma");
      var index = _.indexOf(timeAxis, _time);
      var title = log.user.split(' ').map(function(item){ return item[0]; }).join('').toUpperCase();
      var description = '<b>' + log.user + '</b> ' +
            log.description.charAt(0).toLowerCase() + log.description.slice(1);

      if (index < 0 ) { index = timeAxis.length - 1; }

      return {
        x: index,
        text: description,
        title: title,
        shape: 'squarepin',
        events: {
          click: function() {
            $.get("/settings/changelog/" + log.id + "?version_modal_id=version_modal_in_graph", "_self");
          }
        }
      };
    });

    chart.highcharts().addSeries({
      type: 'flags',
      name: 'Change Logs',
      color: '#333333',
      shape: 'squarepin',
      y: -50,
      data: logs,
      showInLegend: true,
      yAxis: 1,
      zIndex: 100,
      style: {
        cursor: 'pointer'
      }
    });
    return chart;
  }

  changelogTooltipFormatter(highchart) {
    if (highchart.series && highchart.series.userOptions && highchart.series.userOptions.type === 'flags') {
      return '<b>' + highchart.x.calendar() + '</b><br/>' + highchart.point.text;
    } else {
      return this.tooltipFormatter(highchart, self.pointsType, self.currencySymbol);
    }
  }

  currencyDisplay(symbol, value) {
    symbol = symbol || '$';

    if (_.contains(['€', 'kr', '₺', '₴', '₫'], symbol)) {
      return value + ' ' + symbol;
    }
    else if (_.contains(['CHF', 'E£', 'Rp', 'R'], symbol)) {
      return symbol + ' ' + value;
    }
    else {
      return symbol + value;
    }
  }

  isNumber(n) {
    return typeof n === 'number' && !isNaN(n) && n < Infinity && n > -Infinity;
  }

  revisedDefaultLabelFormatter(point) {
    // this is a an edit of highchart.axis.defaultLabelFormatter
    // to remove the logic that requires all symbols on an axis to be the same
    // i.e. we will provide 1.5M, 1M, 500k, 0 instead of 1 500k, 1 000k, 500k, 0
    //
    // their function also doesn't allow more than 1 decimal place
    // i.e. we will provide 1.25M instead of 1 250k

    var axis = point.axis, chart = point.chart, numberFormatter = chart.numberFormatter,
      value = this.isNumber(point.value) ? point.value : NaN, time = axis.chart.time,
      categories = axis.categories, dateTimeLabelFormat = point.dateTimeLabelFormat,
      lang = Highcharts.defaultOptions.lang, numericSymbols = lang.numericSymbols,
      numSymMagnitude = lang.numericSymbolMagnitude || 1000, numericSymbolDetector = Math.abs(value);

    var i = numericSymbols && numericSymbols.length, multi, ret;

    if (categories) {
      ret = "".concat(point.value);
    }
    else if (dateTimeLabelFormat) {
      ret = time.dateFormat(dateTimeLabelFormat, value);
    }
    else if (i && numericSymbolDetector >= 1000) {
      while (i-- && typeof ret === 'undefined') {
        multi = Math.pow(numSymMagnitude, i + 1);
        if ( numericSymbolDetector >= multi && numericSymbols[i] !== null && value !== 0) {
          ret = numberFormatter(value / multi, -1) + numericSymbols[i];
        }
      }
    }
    if (typeof ret === 'undefined') {
      if (Math.abs(value) >= 10000) {
        ret = numberFormatter(value, -1);
      }
      else {
        ret = numberFormatter(value, -1, void(0), '');
      }
    }
    return ret;
  }

  yAxisFormatter(format, highchart, currencySymbol) {
    var value = this.revisedDefaultLabelFormatter(highchart);
    if(format === 'money' || format === 'moneyNoDecimal') {
      return this.currencyDisplay(currencySymbol, value);
    } else if(format === 'percent') {
      value = (value)*100;
      return parseFloat(value.toFixed(2)) + '%';
    } else {
      return value;
    }
  }

  tooltipDateTime(highchart, interval, options) {
    if(typeof highchart.x === 'string') { return highchart.x; }
    if (interval === 'day') {
      return highchart.x.format('MM/DD/YY');
    }

    var value = (typeof highchart.x === 'number') ? moment(highchart.x).calendar(): highchart.x.calendar();
    if (options.tooltipHourOnly) {
      return highchart.x.format("h:00 A");
    }
    else if(isNaN(parseInt(value.charAt(0)))) {
      return value;
    }
    else {
      return value + ' at ' + highchart.x.format("ha");
    }
  }

  tooltipFormatter(highchart, interval, currencySymbol, options = {}) {
    var self = this;
    var sText = '<b>' + this.tooltipDateTime(highchart, interval, options) + '</b><br/>';
    var sArray = [];
    sArray.push();
    $.each(highchart.points, function(i, point) {
      var pointData = null;
      if(point.series.options.formatType === 'money') {
        pointData = self.currencyDisplay(currencySymbol, Highcharts.numberFormat(point.y,2,'.',','));
      }
      else if(point.series.options.formatType === 'percent') {
        pointData = Highcharts.numberFormat(point.y * 100,2,'.') + '%';
      }
      else {
        pointData = Highcharts.numberFormat(point.y,0,'.',',');
      }
      var pointColor = (typeof(point.color) === 'object' && point.color.pattern) ? point.color.pattern.color : point.color;
      sArray.push(
        '<span style="color:' + pointColor + ';font-weight:bold;">' + point.series.name + ': </span>' +
        pointData + '<br/>'
      );
    });
    var sArrayText = sArray.join('');
    return sText + sArrayText;
  }

  playerSizeNameFor(width) {
    var playerName = '';

    if (width >= 0 && width < 250) {
      playerName = 'X-Small';
    }
    else if (width >= 250 && width < 350) {
      playerName = 'Small';
    }
    else if (width >= 350 && width < 500) {
      playerName = 'Med';
    }
    else if (width >= 500 && width < 800) {
      playerName = 'Large';
    }
    else if (width >= 800) {
      playerName = 'X-Large';
    }

    return playerName;
  }

  validAxisValue(test) {
    return test.value && typeof(test.value.format) === 'function';
  }

  playerSizeZones(rgba) {
    var colors = rgba ? this.chartRgbaColors(50) : this.chartColors;

    return [
      {value: 0,   color: colors[5]},
      {value: 250, color: colors[0]},
      {value: 350, color: colors[1]},
      {value: 500, color: colors[2]},
      {value: 800, color: colors[3]},
      {color: colors[4]}
    ];
  }

  qualityStacks(report) {
    if (report && report.viewability_data) {
      return [
        report.viewability_data,
        report.groupm_viewability_data,
        report.avoc_data
      ];
    }

    return [];
  }

}

export default HighchartsBuilder;

$(document).on('turbo:load', function() {
  $('body').on('focus', 'input[type=number]', function (e) {
    $(this).on('mousewheel.disableScroll', function (e) {
      e.preventDefault();
    });
  });

  $('body').on('blur', 'input[type=number]', function (e) {
    $(this).off('mousewheel.disableScroll');
  });
});
import HighchartsBuilder from '../../modules/highcharts_builder.js';

angular.module('vastdesk')
.component('playerSizeChart', {
  bindings: {
    chartId: '@',
    chartData: '=',
    chartDimension: '@'
  },
  template: '<div class="graphChart playerSizeChart"><div></div></div>',
  controller: ['$scope', '$element', function($scope, $element) {

    var highchartsBuilder = new HighchartsBuilder();
    var colors = highchartsBuilder.chartColors;
    var chartDiv = $element.find('div');

    var self = this;
    var onInitLoad = $.Deferred();

    var getPlayerSizeName = function(width) {
      if (self.chartDimension !== 'width') {
        return '';
      }

      var playerSizeName = highchartsBuilder.playerSizeNameFor(width);
      return playerSizeName === '' ? '' : ' (' + playerSizeName + ')';
    };

    var playerSizeZones = function() {
      if (self.chartDimension !== 'width') {
        return [];
      }

      return highchartsBuilder.playerSizeZones(false);
    };

    var setChart = function() {
      chartDiv.highcharts({
          chart: {
            zooming: {
              type: 'xy'
            },
            type: 'column',
            backgroundColor: null,
            animation: false
          },
          title: {
            text: ''
          },
          xAxis: {
            title: { text: '' },
            lineColor: '#ccd6eb'
          },
          yAxis: {
            title: { text: '' }
          },
          legend: {
            enabled: false
          },
          tooltip: {
            formatter: function() {
              return '<div style="font-size: 10px;">' + this.x + '-' + (this.x + 49) + getPlayerSizeName(this.x) + '</div><br/>' +
                     '<b>' + Highcharts.numberFormat(this.y,0,'.',',') + '</b>';
            },
            style: {
              opacity: '0.95'
            }
          },
          credits: {enabled: false},
          plotOptions: {
            column: {
                pointPadding: 0,
                borderWidth: 0,
                groupPadding: 0,
                shadow: false,
                zoneAxis: 'x',
                zones: playerSizeZones()
            },
            series: {
              animation: false,
              marker: {
                enabled: false
              },
              states: {
                hover: {
                  enabled: false
                }
              },
              shadow: false
            }
          },
          series: [{
            name: 'Data',
            data: self.chartData,
            color: colors[0]
          }]
      });
    };

    $scope.$on('refreshHighchart', function(e, args) {
      $.when( onInitLoad ).done(function() {
        if (args.id === self.chartId) {
          setTimeout(function() {          
            var chart = chartDiv.highcharts();

            if (chart && chart.series) {
              chart.series[0].setData(self.chartData);
            }
          }, 0);
        }
      });
    });

    $scope.$on('reflowHighcharts', function(e, args) {
      highchartsBuilder.deferredReflowChart(onInitLoad, chartDiv);
    });

    this.$onInit = function() {
      setChart();
      onInitLoad.resolve();
    };

  }]
});
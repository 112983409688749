angular.module('vastdesk').
  component('scannedCreativeOverrideField',  {
    bindings: {
      original: '@',
      override: '@',
      originalArray: '<',
      overrideArray: '=',
      arrayOptions: '<',
      arrayLabels: '<',
      fieldType: '@',
      fieldLabel: '@',
      fieldName: '@',
      showOriginal: '<',
      displayType: '@'
    },
    template: require("../templates/scannedCreativeOverrideField.html"),
    controllerAs: '$scannedCreativeOverrideFieldCtrl',
    controller: function() {

      this.boolButtonGroupOptions = [{
        value: 'true',
        icon: 'fa-check'
      }, {
        value: 'false',
        icon: 'fa-times'
      }];

      this.showOriginalLabel = () => {
        if (this.showOriginal) {
          if (this.fieldType === 'multi_select') {
            return this.originalArrayValues();
          }
          else {
            return this.original;
          }
        }
        else {
          return '';
        }
      };

      this.originalArrayMod = () => {
        return this.arrayLabels || this.originalArray;
      };

      this.originalArrayValues = () => {
        const arr = this.originalArrayMod();

        if (Array.isArray(arr)) {
          return arr.join(', ');
        }
        else {
          return '';
        }
      };

    }
});

angular.module('vastdesk').
  component('accountCurrencyFields',  {
    bindings: {
      multiCurrencyEnabled: '<',
      defaultCurrency: '@',
      currencyOptions: '<',
      readOnly: '<',
      quickstatsCurrencyOptions: '<',
      quickstatsCurrencies: '<'
    },
    template: require("../templates/accountCurrencyFields.html"),
    controllerAs: '$accountCurrencyFieldsCtrl',
    controller: function() {

    }
});

export function highchartsComparisonConfigHelper(config) {
  return config.chartDiv.highcharts(
    {
      chart: {
        zooming: {
          type: 'xy'
        },
        backgroundColor: null,
        animation: false
      },
      title: {
        text: ''
      },
      credits: { enabled: false },
      colors: config.colors,
      xAxis: [{
        categories: config.todayAllHours,
        crosshair: true,
        tickLength: 0,
        lineColor: '#ccd6eb',
        labels: {
          formatter: function () {
            if (config.highchartsBuilder.validAxisValue(this)) {
              return this.value.format('ha');
            }
          },
          reserveSpace: true,
          y: 10,
          style: {
            color: '#acacac',
            fontSize: '9px'
          }
        }
      }],
      yAxis: [
        {
          title: { text: '' },
          lineWidth: 2,
          id: config.metric + '-axis',
          labels: {
            reserveSpace: true,
            y: 10,
            formatter: function () {
              return config.highchartsBuilder.yAxisFormatter(config.formatType, this, config.currencySymbol);
            },
            style: {
              color: '#acacac',
              fontSize: '9px'
            }
          },
          gridLineColor: '#d7d7d7',
          lineColor: '#ccd6eb'
        },
        {
          title: { text: '' },
          labels: { enabled: false }
        },
        {
          title: { text: '' },
          labels: { enabled: false }
        }
      ],
      legend: config.highchartsBuilder.defaultLegend(),
      tooltip: {
        formatter: function () {
          return config.highchartsBuilder.tooltipFormatter(this, null, null, {
            tooltipHourOnly: true
          });
        },
        shared: true,
        backgroundColor: '#FFFFFF',
        borderColor: '#484848',
        style: {
          fontSize: '10px',
          lineHeight: '14px',
          opacity: '0.95'
        }
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false
          },
          states: {
            hover: {
              enabled: false
            }
          },
          shadow: false
        }
      },
      series: config.series
    }
  );
}



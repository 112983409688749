import HighchartsBuilder from '../../modules/highcharts_builder.js';

angular.module('vastdesk')
.component('objectStatsPanel', {
  bindings: {
    objectId: '@',
    objectType: '@',
    qualityPath: '@',
    summaryPath: '@',
    budgetPath: '@',
    responseTimesModalTitle: '@',
    responseTimesPath: '@',
    responseTimesPath2: '@',
    hideResponseTimes: '@',
    vpaidWindowPath: '@',
    hasBudget: '@',
    objectTable: '@',
    panelType: '@',
    showVpaidWindow: '@',
    directConnect: '@',
    trackingPlayerCost: '=',
    reportingView: '@',
    excludeOpps: '@',
    completionReporting: '@',
    externalBidder: '@',
    openrtbSeller: '@',
    dateRangeParent: '@',
    pod: '@',
    thirdPartyOverride: '@',
    costModelType: '@',
    slotOrder: '@',
    slotNumber: '@',
    slotDimension: '@',
    supplyTagId: '@',
    skipInitialLoad: '<',
    tile: '<',
    currency: '@',
    currencySymbol: '@',
    currencyPlaceholder: '@',
    environment: '@',
    supplyClass: '@'
  },
  template: require("../templates/objectStatsPanel.html"),
  controllerAs: '$objectStatsPanelCtrl',
  controller: ['$scope', '$rootScope', function($scope, $rootScope) {

    var self = this;
    var onInitLoad = $.Deferred();
    var ajaxRequests = {};
    var theme = window.platformTheme;
    var qualityReportRun = false;
    var qualityReportIvtMetricsPresent = false;
    var dateRange;
    var highchartsBuilder = new HighchartsBuilder();

    this.panelTypeOptions = ['Graph', 'Table'];
    this.forecastType = 'historical';
    this.forecastTypeOptions = [
      {
        value: 'total',
        label: 'Total'
      },
      {
        value: 'historical',
        tooltip: 'Historical',
        icon: 'fa-calendar-days'
      },
      {
        value: 'forecast',
        tooltip: 'Forecast',
        icon: 'fa-crystal-ball'
      }
    ];
    this.wikiInstructions = theme.wikiInstructions;

    this.setPanelType = function(panelType) {
      self.panelType = panelType;
      self.reflowCharts();
    };

    this.setForecastType = function(forecastType) {
      self.forecastType = forecastType;
      setSummaryReport();
      refreshCharts('.refreshing-panel-summary');
      self.reflowCharts();
    };

    this.panel1HeaderMetric = function() {
      var headerMetric = 'usable_requests';

      if ( self.isRouter() ) {
        headerMetric = 'router_usable_requests';
      }
      else if (self.useBidderMetrics() ) {
        if (magniteStreamingModal() || demandTagMagniteStreamingModal()) {
          headerMetric = 'openrtb_bids';
        }
        else {
           headerMetric = 'openrtb_bid_requests';
        }
      }
      else if (dspSub()) {
        headerMetric = 'has_ads';
      }

      return headerMetric;
    };

    this.responseTimesReportsPathWithSlot = function(path) {
      var base = path;

      if (!!self.slotOrder) {
        base += '&slot_order=' + self.slotOrder;
      }
      else if (!!self.slotNumber) {
        base += '&slot_number=' + self.slotNumber;
      }

      if (dateRange && dateRange !== '') {
        base += '&date_range=' + dateRange;
      }

      return base;
    };

    this.wholeSummaryReport = {};
    this.summaryReport = {};
    this.qualityReport = {};

    var reportOptions = {};

    const magniteStreamingModal = () => {
      return this.objectType === 'MagniteStreaming';
    };

    const demandTagMagniteStreamingModal = () => {
      return _.contains(['MagniteStreamingDemandTag'], self.objectType);
    };

    var dspSub = function() {
      return self.reportingView === 'dsp_sub';
    };

    var uniqObjectIdFor = function(label) {
      return self.objectType + self.objectId + label;
    };

    this.budgetAjaxPartialId = function() {
      return uniqObjectIdFor('Budget');
    };

    this.financialChart = function() {
      var financialView = 'sunburst';

      if (self.useBidderFinancialMetrics()) {
        financialView = 'costOneColumn';
      }
      else if (_.contains(['publisher', 'ctv_publisher', 'dsp_sub', 'dsp_main'], self.reportingView)) {
        financialView = 'revenueOneColumn';
      }

      return financialView;
    };

    this.supplyObject = function() {
      return _.contains(['SupplyTag', 'SupplyPartner', 'SupplyRouter', 'SupplyLabel'], self.objectType);
    };

    this.demandObject = function() {
      return _.contains(['DemandTag', 'DemandPartner', 'Campaign', 'SupplyTagCampaign', 'DemandLabel', 'Creative'], self.objectType);
    };

    this.respTimeObject = function() {
      return _.contains(['DemandTag', 'SupplyTag', 'DemandPartner', 'SupplyPartner', 'Campaign', 'SupplyRouter', 'SupplyLabel', 'DemandLabel'], self.objectType);
    };

    this.performancePricingObject = function() {
      return _.contains(['DemandTag', 'Campaign'], self.objectType);
    };

    this.networkPerformancePricing = function() {
      return self.performancePricingObject() && self.networkReportingViews() && self.hasCpcPerformancePricing();
    };

    this.excludeOppMetrics = function () {
      return self.excludeOpps === 'true';
    };

    this.excludeFillRates = () => {
      if (this.environment === 'dooh') {
        if(this.supplyObject()) {
          const bidLinkSupplyClass = '3';
          return this.supplyClass === bidLinkSupplyClass || this.directConnect;
        } else if(this.demandObject()) {
          return true;
        }
      } else {
        return false;
      }
    };

    var nonBidderPerformanceMetrics = function() {
      var metrics = [];
      if (self.demandObject() && self.ctvReportingViews()) {
        metrics.push({label: 'Bids', metric: 'has_ads', default: '0'});
      }
      if (!self.excludeOppMetrics()) {
        if ( self.supplyObject() ) {
          metrics.push({label: 'Opps', metric: 'opportunities', default: '0'});
        }
        else {
          metrics.push({label: 'Wins', metric: 'opportunities', default: '0'});
        }
        if (self.supplyObject() && !self.directConnect && !self.isRouter()) {
          metrics.push({label: 'Missed Opps', metric: 'missed_opportunities', default: '0', graphHidden: true});
        }
      }
      if (self.isRouter() && self.reportingView !== 'ctv_publisher') {
        metrics.push({label: 'Router Missed Opps', metric: 'router_missed_opportunities', default: '0', graphHidden: true});
      }
      metrics.push({label: 'Imps', metric: 'impressions', default: '0'});
      if (self.tile || self.networkPerformancePricing()) {
        metrics.push({label: 'Clicks', metric: 'clicks', default: '0', graphHidden: !self.tile});
      }
      if (self.performancePricingObject() && self.networkReportingViews() && self.hasCpcvPerformancePricing()) {
        metrics.push({label: 'Completes', metric: 'fourth_quartile', default: '0', graphHidden: true});
      }
      if (self.objectType === 'SupplyTag') {
        metrics.push({label: 'Player Loads', metric: 'player_starts', default: '0', playerCost: true});
      }
      if (!self.excludeOppMetrics() && !(self.demandObject() && self.ctvReportingViews())) {
        metrics.push({label: 'Opp %', metric: 'opportunity_percentage', default: '0.00%', graphHidden: true});
        if (!self.excludeFillRates()) {
          metrics.push({label: 'Opp Fill %', metric: 'opportunity_fill_percentage', default: '0.00%', graphHidden: true});
        }
      }
      if (self.demandObject() && self.ctvReportingViews() && !self.excludeFillRates()) {
        metrics.push({label: 'Efficiency %', metric: 'efficiency_rate', default: '0.00%'});
      }
      if ( self.isRouter() ) {
        metrics.push({label: 'Router Req Fill %', metric: 'router_request_fill_rate', default: '0.00%'});
      }
      else if (!self.excludeFillRates()) {
        metrics.push({label: 'Req Fill %', metric: 'fill_percentage', default: '0.00%'});
      }
      if (self.tile || self.networkPerformancePricing()) {
        metrics.push({label: 'CTR', metric: 'click_through_rate', default: '0.00%', graphHidden: true});
      }
      if (self.objectType === 'SupplyTag') {
        metrics.push({label: 'Player Fill %', metric: 'player_fill_rate', default: '0.00%', playerCost: true});
      }
      if (self.demandObject()) {
        metrics.push({label: 'Score', metric: 'score', default: '0.00', graphHidden: true});
      }
      if (self.isPod()) {
        metrics.push({label: 'Pod Time Req Fill %', metric: 'ad_pod_fill_rate', default: '0.00%'});
      }
      return metrics;
    };

    var dspPerformanceMetrics = function() {
      var metrics = [
        {label: 'Imps', metric: 'impressions', default: '0'},
        {label: 'Clicks', metric: 'clicks', default: '0', graphHidden: true},
        {label: 'Completes', metric: 'fourth_quartile', default: '0', graphHidden: true}
      ];

      if ( dspSub() ) {
        metrics.push({label: 'Efficiency %', metric: 'efficiency_rate', default: '0.00%'});
      }
      else {
        metrics.push({label: 'Req Fill %', metric: 'fill_percentage', default: '0.00%'});
      }

      metrics.push({label: 'CTR', metric: 'click_through_rate', default: '0.00%'});

      return metrics;
    };

    var podSupplyTagPerformanceMetrics = function() {
      return [
        {label: 'Pod Slot Req', metric: 'ap_slots_count', default: '0'},
        {label: 'Pod Slots Ret', metric: 'ap_slots_opportunity', default: '0', graphHidden: true},
        {label: 'Imps', metric: 'impressions', default: '0'},
        {label: 'Pod Slot Ret %', metric: 'slot_opportunity_rate', default: '0.00%', graphHidden: true},
        {label: 'Pod Slot Ret Fill %', metric: 'slot_opportunity_fill_rate', default: '0.00%', graphHidden: true},
        {label: 'Pod Slot Req Fill %', metric: 'slot_fill_rate', default: '0.00%'},
        {label: 'Pod Time Req Fill %', metric: 'ad_pod_fill_rate', default: '0.00%'}
      ];
    };

    var bidderPerformanceMetrics = function() {
      var metrics = [];

      if (!magniteStreamingModal() && !demandTagMagniteStreamingModal()) {
        metrics.push({label: 'Prog Bids', metric: 'openrtb_bids', default: '0'});
      }

      metrics = metrics.concat([
        {label: 'Prog Wins', metric: 'openrtb_wins', default: '0', graphHidden: true},
        {label: 'Imps', metric: 'impressions', default: '0'}
      ]);

      if (!magniteStreamingModal() && !demandTagMagniteStreamingModal()) {
        metrics.push({label: 'Prog Bid %', metric: 'openrtb_bid_rate', default: '0.00%', graphHidden: true});
      }

      metrics = metrics.concat([
        {label: 'Prog Win %', metric: 'openrtb_win_rate', default: '0.00%', graphHidden: true},
        {label: 'Prog Win Fill %', metric: 'openrtb_win_fill_rate', default: '0.00%', graphHidden: true},
        {label: 'Prog Bid Fill %', metric: 'openrtb_bid_fill_rate', default: '0.00%'}
      ]);

      return metrics;
    };

    this.isRouter = function() {
      return self.objectType === 'SupplyRouter';
    };

    this.useBidderMetrics = function() {
      return self.externalBidder === 'true';
    };

    var objectEligibleForShowingCompletionBasedOnQualityData = function() {
      return _.contains(['SupplyLabel', 'DemandLabel', 'SupplyPartner', 'DemandPartner', 'Campaign', 'ExternalBidder'], self.objectType) ||
        (self.objectType === 'SupplyRouter' && self.thirdPartyOverride !== 'true') ||
        (self.objectType === 'ExternalBidder' && self.reportingView === 'ctv_network');
    };

    var showCompletionPanelBasedOnQualityData = function() {
      return qualityReportRun && !qualityReportIvtMetricsPresent && objectEligibleForShowingCompletionBasedOnQualityData();
    };

    this.useCompletionMetrics = function() {
      return self.completionReporting === 'true' || showCompletionPanelBasedOnQualityData();
    };

    this.isOpenrtbSeller = function() {
      return self.openrtbSeller === 'true';
    };

    this.isPod = function() {
      return self.pod === 'true';
    };

    this.ctvReportingViews = function() {
      return _.contains(['ctv_network', 'ctv_publisher'], self.reportingView);
    };

    this.networkReportingViews = function() {
      return _.contains(['network', 'ctv_network'], self.reportingView);
    };

    this.dspReportingViews = function() {
      return _.contains(['dsp_sub', 'dsp_main'], self.reportingView);
    };

    this.hasCpcPerformancePricing = function() {
      return _.contains(['2'], self.costModelType);
    };

    this.hasCpcvPerformancePricing = function() {
      return _.contains(['3'], self.costModelType);
    };

    this.useBidderFinancialMetrics = function() {
      return self.useBidderMetrics() && !self.isOpenrtbSeller();
    };

    var usePodSupplyTagMetrics = function() {
      return self.isPod() && self.objectType === 'SupplyTag' && noSlot();
    };

    var noSlot = function() {
      return (!self.slotNumber || self.slotNumber === '') &&
        (!self.slotOrder || self.slotOrder === '');
    };

    var initPerformanceMetrics = function() {
      var pMetrics;
      if (self.useBidderMetrics()) {
        pMetrics = bidderPerformanceMetrics();
      }
      else if (usePodSupplyTagMetrics()) {
        pMetrics = podSupplyTagPerformanceMetrics();
      }
      else if (self.dspReportingViews()) {
        pMetrics = dspPerformanceMetrics();
      }
      else {
        pMetrics = nonBidderPerformanceMetrics();
      }
      return pMetrics;
    };

    this.showPerformanceRow = function(performanceMetric) {
      return (self.panelType === 'Table' || !performanceMetric.graphHidden) &&
        (!performanceMetric.playerCost || (performanceMetric.playerCost && self.trackingPlayerCost));
    };

    var currencyMetricPlaceholder = function() {
      return self.currencyPlaceholder || '$0.00';
    };

    var initFinancialMetrics = function() {
      if (self.useBidderFinancialMetrics()) {
        return [
          {label: 'CPM', metric: 'cpm', default: currencyMetricPlaceholder()}
        ];
      }
      else if ( _.contains(['publisher'], self.reportingView) ) {
        if (self.demandObject()) {
          return [
            {label: 'RPM', metric: 'rpm', default: currencyMetricPlaceholder()},
            {label: 'Completes', metric: 'fourth_quartile', default: '0'},
            {label: 'Clicks', metric: 'clicks', default: '0', graphHidden: true},
            {label: 'CTR', metric: 'click_through_rate', default: '0.00%'}
          ];
        }
        else {
          return [
            {label: 'RPM', metric: 'rpm', default: currencyMetricPlaceholder()}
          ];
        }
      }
      else if ( _.contains(['ctv_publisher'], self.reportingView) ) {
        if (self.demandObject() || demandTagMagniteStreamingModal()) {
          return [
            {label: 'RPM', metric: 'rpm', default: currencyMetricPlaceholder()},
            {label: 'Completes', metric: 'fourth_quartile', default: '0'},
          ];
        }
        else {
          return [
            {label: 'RPM', metric: 'rpm', default: currencyMetricPlaceholder()}
          ];
        }
      }
      else {
        var metrics = [
          {label: 'Cost',         metric: 'cost',    default: currencyMetricPlaceholder(), graphHidden: !self.dspReportingViews()},
          {label: 'Profit (Net)', metric: 'profit',  default: currencyMetricPlaceholder()},
          {label: 'Margin (Net)', metric: 'margin',  default: '0.00%'},
          {label: 'RPM',          metric: 'rpm',     default: currencyMetricPlaceholder(), graphHidden: self.demandObject() && !self.dspReportingViews()}
        ];

        if (_.contains(['ctv_network', 'dsp_main'], self.reportingView)) {
          metrics.push({label: 'RPMR',          metric: 'rpmr',     default: currencyMetricPlaceholder(), graphHidden: self.demandObject()});
        }

        metrics = metrics.concat([
          {label: 'CPM',          metric: 'cpm',     default: currencyMetricPlaceholder(), graphHidden: self.supplyObject() || !self.dspReportingViews()},
          {label: 'PPM (Net)',    metric: 'ppm',     default: currencyMetricPlaceholder(), graphHidden: true}
        ]);

        return metrics;
      }
    };

    this.completionMetrics = [
      {label: '3rd Quartile', metric: 'third_quartile_rate', default: '0'},
      {label: '2nd Quartile', metric: 'second_quartile_rate', default: '0'},
      {label: '1st Quartile', metric: 'first_quartile_rate', default: '0'},
    ];

    var initQualityMetrics = function() {
      var metrics = [
        {label: 'MOAT Viewability', metric: 'moat_viewability_rate', default: '0.00%', graphHidden: true},
        {label: 'MOAT AVOC', metric: 'moat_complete_audible_visible_rate', default: '0.00%', graphHidden: true},
        {label: 'MOAT Bot', metric: 'moat_bot_rate', default: '0.00%'},
        {label: 'IAS Viewability', metric: 'ias_mrc_viewability_rate', default: '0.00%', graphHidden: true},
        {label: 'IAS AVOC', metric: 'ias_complete_audible_visible_rate', default: '0.00%', graphHidden: true},
        {label: 'IAS IVT', metric: 'ias_ivt_rate', default: '0.00%'}
      ];

      if (self.supplyObject()) {
        metrics.push({label: 'Blocked Rate', metric: 'blocked_rate_total', default: '0.00%', graphHidden: true});
      }

      return metrics;
    };

    $rootScope.$on('updateReportData', function(e, args) {
      $.when( onInitLoad ).done(function() {
        if (args.table === self.objectTable) {
          reportOptions = args.filterParams || {};
          setTimeout(function() {
            updateReportData();
          }, 0);
        }
      });
    });

    $rootScope.$on('reportCurrencyChange', function(e, args) {
      self.currency = args.currency;

      if (self.summaryPath) {
        updateReportData();
      }
    });

    $scope.$on('wfSlotFieldChanged', function(e, args) {
      setTimeout(function() {
        self.performanceMetrics = initPerformanceMetrics();
      }, 0);
    });

    var getSummaryReport = function(resp) {
      if (self.objectType === 'DemandPartner') {
        return resp.demand_partner_report;
      }
      else if (self.objectType === 'Campaign') {
        return resp.campaign_report;
      }
      else if (self.objectType === 'SupplyPartner') {
        return resp.supply_partner_report;
      }
      else if (self.objectType === 'ExternalBidder') {
        return resp.external_bidder_report;
      }
      else if (self.objectType === 'SupplyRouter') {
        return resp.router_report;
      }
      else if (self.objectType === 'Creative') {
        return resp.creative_report;
      }
      else if (_.contains(['SupplyLabel', 'DemandLabel'], self.objectType)) {
        return resp.label_report;
      }
      else {
        return resp.tag_report;
      }
    };

    var getDateRangeValue = (selector) => {
      var parent = self.dateRangeParent || '.dataTables_wrapper';
      return $('#' + self.objectTable).parents(parent).find(selector);
    };

    var dateRangeSelector = () => getDateRangeValue('#date_range');
    var forecastDateRangeSelector = () => getDateRangeValue('#forecast_date_range');

    var abortPendingAjaxRequests = function() {
      _.each(ajaxRequests, function(request, label) {
        request.abort();
      });
    };

    this.forecastingDataPresent = () => self.wholeSummaryReport.total_report && self.wholeSummaryReport.forecast_report;

    const setSummaryReport = () => {
      if (self.forecastingDataPresent() && self.forecastType === 'total') {
        self.summaryReport = self.wholeSummaryReport.total_report;
      }
      else if (self.forecastingDataPresent() && self.forecastType === 'forecast') {
        self.summaryReport = self.wholeSummaryReport.forecast_report;
      }
      else {
        self.summaryReport = self.wholeSummaryReport;
      }
      self.sunbursts.performance.data = self.summaryReport.performance_sunburst;
      self.sunbursts.financial.data = self.summaryReport.financial_sunburst;
      self.sunbursts.completion.data = self.summaryReport.completion_sunburst;
      self.oneColumnCharts.revenue.data = self.summaryReport.revenue_one_column;
      self.oneColumnCharts.cost.data = self.summaryReport.cost_one_column;
      refreshCharts('.refreshing-panel-summary');
      refreshHighchart(self.oneColumnCharts.revenue);
      refreshHighchart(self.oneColumnCharts.cost);
    };

    const setDefaultForecastType = (forecastDateRangeWas, newForecastDateRange) => {
      if (!self.forecastingDataPresent() || (forecastDateRangeWas && !newForecastDateRange)) {
        self.setForecastType('historical');
      }
      else if (!forecastDateRangeWas && newForecastDateRange) {
        self.setForecastType('total');
      }
      else {
        setSummaryReport();
      }
    };

    var updateReportData = function() {
      dateRange = dateRangeSelector().val() || 'Today';
      const newForecastDateRange = forecastDateRangeSelector().val() || null;
      const forecastDateRangeWas = self.forecastDateRange;

      var params = _.extend({}, reportOptions, {
        date_range: dateRange,
        forecast_date_range: newForecastDateRange,
        pod: self.pod,
        slot_order: self.slotOrder,
        slot_number: self.slotNumber,
        slot_dimension: self.slotDimension,
        supply_tag_id: self.supplyTagId,
        currency: self.currency,
        environment: self.environment
      });

      abortPendingAjaxRequests();
      $rootScope.$broadcast('updatingReportData', {table: self.objectTable});
      $rootScope.$broadcast('refreshAjaxPartial', {ajaxId: self.budgetAjaxPartialId()});

      if (self.summaryPath) {
        ajaxRequests.summary = $.get(self.summaryPath, params, function(resp) {
          if (resp.currency_placeholder) {
            self.currencyPlaceholder = resp.currency_placeholder;
          }
          self.wholeSummaryReport = getSummaryReport(resp) || {};
          self.forecastDateRange = newForecastDateRange;
          setDefaultForecastType(forecastDateRangeWas, newForecastDateRange);
          $rootScope.$broadcast('refreshTagCharts', _.extend({}, params, {
            table: self.objectTable,
            filterParams: reportOptions
          }));
          $scope.$apply();
          $rootScope.$broadcast('updatedReportData', {table: self.objectTable, resp: resp});
        });
      }

      if (self.qualityPath) {
        ajaxRequests.quality = $.get(self.qualityPath, params, function(resp) {
          self.qualityReport = resp.quality_report || {};
          self.stackedColumnCharts.quality.stacks = highchartsBuilder.qualityStacks(resp.quality_report);

          qualityReportRun = true;
          qualityReportIvtMetricsPresent = getQualityReportIvtMetricsPresent(self.qualityReport);

          $scope.$apply();
          refreshHighchart(self.stackedColumnCharts.quality);
          refreshCharts('.refreshing-panel-quality');
        });
      }

      $scope.$apply();

      setTimeout(function() {
        toggleResponseTimesVisibility();
      }, 0);
    };

    var toggleResponseTimesVisibility = function() {
      // $('.object-stats-panel .chart-popover[data-data-check]').each(function() {
      //   $(this).toggleResponseTimesVisibility();
      // });
    };

    var getQualityReportIvtMetricsPresent = function(qualityReport) {
      return (parseInt(qualityReport.ias_impressions) || 0) > 0 ||
        (parseInt(qualityReport.in_view_impressions) || 0) > 0;
    };

    var refreshHighchart = function(chart) {
      $scope.$broadcast('refreshHighchart', chart);
    };

    var refreshCharts = function(selector) {
      $(selector).addClass('hidden');

      _.each(self.sunbursts, function(chart, name) {
        refreshHighchart(chart);
      });
    };

    this.reflowCharts = function() {
      $scope.$broadcast('reflowHighcharts');
    };

    this.$onInit = function() {
      this.sunbursts = {
        performance: {
          id: uniqObjectIdFor('PerformanceSunburst'),
          parent: '.ss-card-body'
        },
        financial: {
          id: uniqObjectIdFor('FinancialSunburst'),
          parent: '.ss-card-body'
        },
        completion: {
          id: uniqObjectIdFor('CompletionSunburst'),
          parent: '.ss-card-body'
        }
      };

      this.oneColumnCharts = {
        revenue: {
          id: uniqObjectIdFor('RevenueColumn'),
          parent: '.ss-card-body'
        },
        cost: {
          id: uniqObjectIdFor('CostColumn'),
          parent: '.ss-card-body'
        }
      };

      this.stackedColumnCharts = {
        quality: {
          id: uniqObjectIdFor('stackedQuality'),
          parent: '.ss-card-body'
        }
      };

      this.financialMetrics = initFinancialMetrics();
      this.qualityMetrics = initQualityMetrics();
      this.performanceMetrics = initPerformanceMetrics();

      $('#' + self.objectTable).on('init.dt', function() {
        setTimeout(function() {
          dateRangeSelector().change(updateReportData);
          forecastDateRangeSelector().change(updateReportData);
        }, 0);
      });

      setTimeout(function() {
        if (!self.skipInitialLoad) {
          updateReportData();
        }
        self.reflowCharts();
      }, 0);

      onInitLoad.resolve();
    };

  }]
});

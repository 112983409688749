import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('modifierFields',  {
    bindings: {
      formName: '@',
      formId: '@',
      rate: '<',
      demandClass: '@',
      bidShading: '<',
      selectedBidModifierOptions: '<',
      selectedDeliveryModifierOptions: '<',
      dspSubAccount: '@',
      bidModifierMultiplierInteraction: '@',
      deliveryModifierMultiplierInteraction: '@'
    },
    template: require("../templates/modifierFields.html"),
    controllerAs: '$modifierFieldsCtrl',
    controller: ['$scope', '$rootScope', function($scope, $rootScope) {

      var self = this;
      var bodyElement = $('body');

      this.stackedModifiers = [ 
        { label: 'Stacked', value: 'stacked' },
        { label: 'Min', value: 'min' },
        { label: 'Max', value: 'max' },
        { label: 'Mean', value: 'mean' },
      ];

      this.modifierFieldsChange = () => {
        $rootScope.$emit('modifierFieldsChange', { amountOfBidModifiers: self.amountOfBidModifiers, bidShading: self.bidShading });
      };

      this.attributeEnabledMappings = componentHelper.attributeEnabledMappings();

      bodyElement.on('change', '.modifier-association-fields select[name*="[bid_modifier_ids][]"]', function() {
        self.amountOfBidModifiers = $(this).val().length;
        if (self.amountOfBidModifiers > 1 && self.bidModifierMultiplierInteraction === '') {
          self.bidModifierMultiplierInteraction = 'stacked';
        }
        self.modifierFieldsChange();
        $scope.$apply();
      });

      bodyElement.on('change', '.modifier-association-fields select[name*="[delivery_modifier_ids][]"]', function() {
        self.amountOfDeliveryModifiers = $(this).val().length;
        if (self.amountOfDeliveryModifiers > 1 && self.deliveryModifierMultiplierInteraction === '') {
          self.deliveryModifierMultiplierInteraction = 'stacked';
        }
        $scope.$apply();
      });

      this.$onInit = function() {
        self.amountOfBidModifiers = Array.isArray(self.selectedBidModifierOptions) ? self.selectedBidModifierOptions.length : 0;
        self.amountOfDeliveryModifiers = Array.isArray(self.selectedDeliveryModifierOptions) ? self.selectedDeliveryModifierOptions.length : 0;
        self.modifierFieldsChange();
      };

    }]
});

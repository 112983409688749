import WaterfallHelper from '../../modules/waterfall_helper.js';
import WaterfallModalHelper from '../../modules/waterfall_modal_helper.js';

angular.module('vastdesk')
.component('demandLabelDemand', {
  bindings: {
    objectIds: '=',
    tableSection: '@',
    modalToggle: '@',
    modalElement: '@',
    buttonLabel: '@',
    includeIds: '<',
    tableId: '@',
    batchActions: '<',
    editable: '<'
  },
  template: require("../templates/demandLabelDemand.html"),
  controllerAs: '$demandLabelDemandCtrl',
  controller: ['$scope', '$rootScope', function($scope, $rootScope) {
    var self = this;
    self.selectedBulkActionIds = [];

    var bodySelector = $('body');
    var serverSideTable;
    var serverSideTableId;
    var serverSideDataTable;
    var modal;
    var modalTable;
    var selectedIds = [];
    var waterfallHelper = new WaterfallHelper();
    var waterfallModalHelper = new WaterfallModalHelper();

    this.$onInit = function() {
      serverSideTable = $(self.tableSection + ' table');
      serverSideTableId = $(serverSideTable).attr('id');
      modal = $(self.modalElement);
      modalTable = $(self.modalElement + ' table');

      serverSideTable.on('init.dt', function() {
        serverSideDataTable = $(this).DataTable();
      });

      serverSideTable.on('preXhr.dt', function (e, settings, data) {
        waterfallHelper.abortPendingAjaxRequests(serverSideTable, serverSideDataTable);

        data.demand_tag_ids = self.objectIds;
        data.custom_data = data.custom_data || {};
        data.custom_data.selected_ids = self.selectedBulkActionIds;
      });

      modalTable.on('preXhr.dt', function(e, settings, data) {
        data.custom_data = data.custom_data || {};
        data.custom_data.selected_ids = [].concat(self.objectIds).concat(selectedIds);
      });

      modalTable.on( 'draw.dt', function () {
        _styleModalRows();
        waterfallModalHelper.setSelectedModalCheckboxes(modalTable, selectedIds);
        waterfallHelper.setModalCheckboxes(modalTable, self.objectIds);
      });

      waterfallModalHelper.openEventHandler(bodySelector, '.' + self.modalToggle, modal, modalTable, function() {
        selectedIds = [];
        var searchText = (self.tableSection === '#tag_results') ? 'tag or partner name,' : 'name';
        $('.modal-content .dataTables_filter input').attr("placeholder", "Search by " + searchText + " or enter a comma-separated list of IDs...");
      });

      waterfallModalHelper.saveEventHandler(bodySelector, modal, modalTable, function() {
        waterfallHelper.addIds(self, 'objectIds', selectedIds, serverSideDataTable);
        $scope.$apply();
        broadcastObjectIdsChange();
      });

      bodySelector.on("click", "form.tag-form " + self.tableSection + " a.remove-tag", function() {
        var demandTagId = $(this).data('id');

        waterfallHelper.removeIds(self, 'objectIds', [demandTagId], serverSideDataTable, function() {
          $scope.$apply();
          broadcastObjectIdsChange();
        });

        return false;
      });
    };

    var broadcastObjectIdsChange = function() {
      $rootScope.$broadcast('demandLabelDemandIdsChange', {
        objectIds: self.objectIds,
        includeIds: self.includeIds
      });
    };

    $scope.$on('demandLabelDemandIdsChange', function(e, args) {
      if (args.includeIds === self.includeIds || !Array.isArray(args.objectIds)) {
        return;
      }
      self.objectIds = args.objectIds;
      $scope.$apply();
    });

    var _styleModalRows = function (){
      var statusIndex = modalTable.find("th.status").index();

      modalTable.find("tr").each(function(i, tr) {
        waterfallModalHelper.styleStatus(tr, statusIndex);
      });
    };

    waterfallModalHelper.modalCheckEventHandler(bodySelector, 'demand_tag_ids', function(val) {
      selectedIds.push(val);
    }, function(val) {
      selectedIds = _.without(selectedIds, val);
    });

    $scope.$on('bulkEditBroadcast', function(e, args) {
      if (args.tableId === serverSideTableId) {
        if (!args.selectedIds || !Array.isArray(args.selectedIds)) {
          return;
        }

        var demandTagIds = _.map(args.selectedIds, function(id) {
          return parseInt(id);
        });

        if (args.id === 'remove') {
          waterfallHelper.removeIds(self, 'objectIds', demandTagIds, serverSideDataTable);
          broadcastObjectIdsChange();
        }
      }
    });

  }]
});

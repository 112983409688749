angular.module('vastdesk').
  component('scheduledReportPermissions',  {
    bindings: {
      allowDirectDownload: '<',
      attachCsv: '<',
      directDownloadMsg: '@',
      attachCsvMsg: '@'
    },
    template: require("../templates/scheduledReportPermissions.html"),
    controllerAs: '$scheduledReportPermissionsCtrl',
    controller: function() {

      const toggleExternalRecipientsVisibility = () => {
        $('.scheduled-report-external-recipients').toggleClass('hidden', !this.allowDirectDownload && !this.attachCsv);
      };

      this.setReportPermissions = (allowDirectDownload, attachCsv) => {
        this.allowDirectDownload = allowDirectDownload;
        this.attachCsv = attachCsv;

        toggleExternalRecipientsVisibility();
      };

      this.$onInit = () => {
        toggleExternalRecipientsVisibility();
      };

    }
});

import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('daypartingTargeting',  {
    bindings: {
      formName: '@',
      formId: '@',
      allDayParts: '<',
      dayParting: '<',
      usersTimezone: '<',
      displayDayparting: '@',
      dayPartingPath: '@'
    },
    template: require("../templates/daypartingTargeting.html"),
    controllerAs: '$daypartingTargetingCtrl',
    controller: ['$scope', '$timeout', '$element', function($scope, $timeout, $element) {
      var self = this;
      var bodySelector = $('body');
      this.tooltip = $("select[name*='[budget_timezone]']").val()
      this.modalDayparting = [];
      this.modalUsersTimezone = false;
      this.targetingButtonOptions = componentHelper.allCustomOptions2();

      this.days = [
        {name: 'Sun',   position: 0},
        {name: 'Mon',   position: 1},
        {name: 'Tues',  position: 2},
        {name: 'Wed',   position: 3},
        {name: 'Thurs', position: 4},
        {name: 'Fri',   position: 5},
        {name: 'Sat',   position: 6}
      ];

      this.hours = [
        {name: '12am', position: 0},
        {name: '1am',  position: 1},
        {name: '2am',  position: 2},
        {name: '3am',  position: 3},
        {name: '4am',  position: 4},
        {name: '5am',  position: 5},
        {name: '6am',  position: 6},
        {name: '7am',  position: 7},
        {name: '8am',  position: 8},
        {name: '9am',  position: 9},
        {name: '10am', position: 10},
        {name: '11am', position: 11},
        {name: '12pm', position: 12},
        {name: '1pm',  position: 13},
        {name: '2pm',  position: 14},
        {name: '3pm',  position: 15},
        {name: '4pm',  position: 16},
        {name: '5pm',  position: 17},
        {name: '6pm',  position: 18},
        {name: '7pm',  position: 19},
        {name: '8pm',  position: 20},
        {name: '9pm',  position: 21},
        {name: '10pm', position: 22},
        {name: '11pm', position: 23}
      ];

      this.openDaypartingModalClass = function(allDayParts) {
        return !allDayParts && self.dayParting.length === 0;
      };

      this.dayPartingInputNameFor = function(day, hour) {
        return 'day_parting[' + day + '][' + hour + ']';
      };

      bodySelector.on("click", ".open-dayparting-modal", function() {
        $("#day_parting_modal").modal();
      });

      $('#day_parting_modal #modal_save').click(function() {
        $('#day_parting_modal').modal('hide');

        $timeout(function() {
          $scope.$apply();
        });
      });
    
      this.isSelected = function(day, hour) {
        if (self.modalDayparting.length < 1) {
          return false;
        }
        return self.modalDayparting[day][hour];
      };

      this.initModalDayParting = function() {
        angular.forEach(self.days, function(day) {
          var hourValues = [];
          angular.forEach(self.hours, function(hour) {
            if (angular.isArray(self.dayParting) && self.dayParting[day.position] && self.dayParting[day.position][hour.position]) {
              hourValues[hour.position] = true;
            }
            else {
              hourValues[hour.position] = false;
            }
          });
          self.modalDayparting[day.position] = hourValues;
          self.modalUsersTimezone = self.usersTimezone;
        });

        $scope.$apply();
      };

      var isMouseDown = false,
          startCellSelected,
          startCoords,
          endCoords;

      var getCoords = function(td) {
        var coords = td.attr('data-coordinates').split('-');
        coords = _.map(coords, function(c) {
          return parseInt(c);
        });
        return coords;
      };

      var resetPendingDayParts = function() {
        $element.find('td.daypart-selectable')
          .removeClass('daypart-pending-selected')
          .removeClass('daypart-pending-deselected');
      };

      var setPendingDayPart = function(day, hour, value) {
        $('td[data-coordinates="' + day + '-' + hour + '"]').toggleClass('daypart-pending-selected', value);
        $('td[data-coordinates="' + day + '-' + hour + '"]').toggleClass('daypart-pending-deselected', !value);
      };

      var setDayPart = function(day, hour, value) {
        self.modalDayparting[day][hour] = value;
        $scope.$apply();
      };

      var editCells = function(startCoords, endCoords, cb, startCellSelected) {
        var minDay = _.min([startCoords[0], endCoords[0]]);
        var maxDay = _.max([startCoords[0], endCoords[0]]);
        var minHour = _.min([startCoords[1], endCoords[1]]);
        var maxHour = _.max([startCoords[1], endCoords[1]]);

        for(var i = minDay; i <= maxDay; i++) {
          for(var j = minHour; j <= maxHour; j++) {
            cb(i, j, !startCellSelected);
          }
        }
      };

      var addEventHandlers = function() {
        $element.find('td.daypart-selectable')
          .mousedown(function () {
            isMouseDown = true;
            startCoords = getCoords($(this));
            endCoords = getCoords($(this));
            startCellSelected = $(this).hasClass('daypart-selected');
            editCells(startCoords, endCoords, setPendingDayPart, startCellSelected);
            return false; // prevent text selection
          })
          .mouseover(function () {
            if (isMouseDown) {
              resetPendingDayParts();
              endCoords =  getCoords($(this));
              editCells(startCoords, endCoords, setPendingDayPart, startCellSelected);
            }
          })
          .mouseout(function() {
            resetPendingDayParts();
          })
          .mouseup(function() {
            editCells(startCoords, endCoords, setDayPart, startCellSelected);
            resetPendingDayParts();
          })
          .on('selectstart', function () {
            return false;
          });

        $(document).mouseup(function () {
          isMouseDown = false;
        });
      };

      var setAllDayParts = function() {
        $('label[for$="all_day_parts_true"]').click();
      };

      var save = function() {
        var uniqueValues = _.chain(self.modalDayparting).flatten().uniq().value();
        if (uniqueValues.length > 1) {
          self.dayParting = angular.copy(self.modalDayparting);
          self.usersTimezone = self.modalUsersTimezone;
        }
        else {
          self.dayParting = [];
          self.initModalDayParting();
          setAllDayParts();
        }
        var data = {day_parting: JSON.stringify(self.dayParting)};
        $.post(self.dayPartingPath, data, function(resp) {
          $('.dayparting').html(resp);
        });
      };

      var cancel = function() {
        if (_.isEmpty(self.dayParting)) {
          setAllDayParts();
        }
        self.initModalDayParting();
      };

      $('#day_parting_modal #modal_save').click(save);
      $('#day_parting_modal .modal_cancel').click(cancel);

      $timeout(function() {
        self.initModalDayParting();
        addEventHandlers();
      });

    }]
});

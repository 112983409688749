import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('scannedCreativeListsTargeting', {
    bindings: {
      formName: '@',
      formId: '@',
      allowList: '@',
      selectedOptions: '<',
    },
    template: require("../templates/scannedCreativeListsTargeting.html"),
    controllerAs: '$scannedCreativeListsTargetingCtrl',
    controller: function () {
      const controller = this;

      this.allowlistButtonOptions = componentHelper.allowlistOptions();

      this.setAllowList = function (list) {
        controller.allowList = list;

        if (list === '') {
          $('[name*="scanned_creative_list_ids"]').attr('name', null)
        } else {
          $('[name*="scanned_creative_list_ids"]').attr('name', `${controller.formName}[scanned_creative_list_ids][]`)
        }
      };
    }
});

angular.module('vastdesk')
.component('removeAllListItems', {
  bindings: {
    target: '@',
    disabled: '@'
  },
  template: require("../templates/removeAllListItems.html"),
  controllerAs: '$removeAllListItemsCtrl',
  controller: function() {

    var self = this;

    this.removeAllItems = function() {
      $('#' + self.target).prop('checked', true);
      $('.list-holder-item').remove();
    };

  }
});

angular.module('vastdesk').
  component('userRoleFields',  {
    bindings: {
      accountManagerRoles: '<',
      accountManagerAssociationsCount: '<',
      userAccountRoles: '<',
      userAccountRoleDisabled: '<',
      userAccountRole: '@',
      clientObjectType: '@',
      clientObjectTypes: '<',
      accountContact: '<',
      supplyPartnerOptions: '<',
      supplyLabelOptions: '<',
      demandPartnerOptions: '<',
      demandLabelOptions: '<',
      accountOperativeEnabled: '<',
      operativeUser: '<'
    },
    template: require("../templates/userRoleFields.html"),
    controllerAs: '$userRoleFieldsCtrl',
    controller: function() {

      var self = this;

      var anyInvalidAccountManagerAssociations = function() {
        return self.accountManagerAssociationsCount > 0 && !_.contains(self.accountManagerRoles, self.userAccountRole);
      };

      this.handleUserAccountRoleChange = function() {
        self.displayAmDeletionWarning = anyInvalidAccountManagerAssociations();
      };

      this.supplyOrDemandClientRole = function() {
        return _.contains(['supply_client', 'demand_client'], self.userAccountRole);
      };

      this.clientUserRole = function() {
        return _.contains(['supply_client', 'demand_client', 'dsp_client'], self.userAccountRole);
      };

      this.showClientObjects = function(role, clientObjectType) {
        return self.userAccountRole === role && self.clientObjectType === clientObjectType;
      };

    }
});

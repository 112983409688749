import SwalHelper from '../../modules/swal_helper.js';

angular.module('vastdesk').
  component('routerSupplyPartner',  {
    bindings: {
      supplyPartnerId: '@',
      errorClass: '@',
      editable: '<',
      supplyPartnerOptions: '<',
      supplyPartnerName: '@',
      persisted: '<'
    },
    template: require("../templates/routerSupplyPartner.html"),
    controllerAs: '$routerSupplyPartnerCtrl',
    controller: ['$rootScope', function($rootScope) {

      var self = this;

      var togglePartnerFieldVisibility = function() {
        $('.supply-partner-hidden').toggleClass('hidden', !!self.supplyPartnerId);
        $('.supply-partner-shown').toggleClass('hidden', !self.supplyPartnerId);
      };

      this.broadcastSupplyPartnerChange = function() {
        $rootScope.$broadcast('supplyPartnerIdChange', {
          supplyPartnerId: self.supplyPartnerId
        });

        togglePartnerFieldVisibility();

        if (self.persisted) {
          SwalHelper.alertWarning('Changing the supply partner will update the supply partner for associated tags');
        }
      };

      this.$onInit = function() {
        togglePartnerFieldVisibility();
      };

    }]
});

import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('accountOpenrtbEnabled',  {
    bindings: {
      openrtbEnabled: '<'
    },
    template: require("../templates/accountOpenrtbEnabled.html"),
    controllerAs: '$accountOpenrtbEnabledCtrl',
    controller: ['$rootScope', function($rootScope) {

      var self = this;

      this.attributeEnabledMappings = componentHelper.attributeEnabledMappings();

      var toggleFieldVisibility = function() {
        $('.openrtb-enabled-shown').toggleClass('hidden', !self.openrtbEnabled);
        $('.openrtb-enabled-hidden').toggleClass('hidden', self.openrtbEnabled);
      };

      this.setOpenRtbEnabled = function(val) {
        self.openrtbEnabled = val;

        toggleFieldVisibility();

        $rootScope.$broadcast('openrtbEnabledChange', {
          openrtbEnabled: val
        });
      };

      $rootScope.$on('accountTypeChange', function(e, args) {
        self.setOpenRtbEnabled(args.accountTypeId === '5');
      });

      this.$onInit = function() {
        toggleFieldVisibility();
      };

    }]
});

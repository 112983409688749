class MagniteModalHelper {

  filterIds(json, self, scope, custom_data_name, listModel, selectedListModel, select_element) {
    var resetFilter = false;

    if (json && json.custom_data) {
      var list = json.custom_data[custom_data_name];

      if ( Array.isArray(list) ) {
        self[listModel] = list;

        if (this.selectedNotInList(self, listModel, selectedListModel)) {
          resetFilter = true;
        }

        scope.$apply();

        setTimeout(function() {
          $(select_element).trigger('chosen:updated');
        }, 0);
      }
    }

    return resetFilter;
  }

  selectedNotInList(self, listModel, selectedListModel) {
    if (self[selectedListModel] && self[selectedListModel] !== '' && !_.find(self[listModel], function(b) { return b[0] === self[selectedListModel];})) {
      return true;
    }
  }

}

export default MagniteModalHelper;

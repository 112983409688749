import platformUtilities from '../../modules/platform_utilities.js';

angular.module('vastdesk').
  component('troubleshootingReport',  {
    bindings: {
      noDataMessage: '@',
      tagType: '@',
      tagId: '@',
      tagName: '@',
      tagType2: '@',
      tagId2: '@',
      tagName2: '@',
      dealId: '@',
      publisherId: '@',
      publisherName: '@',
      inventorySource: '@',
      siteId: '@',
      appId: '@',
      privateAuction: '@',
      includeOpps: '<',
      audio: '<',
      pod: '<',
      eventTabOptions: '<',
      performanceTab: '@',
      inventoryType: '@',
      showSupply: '<',
      showProgrammatic: '<',
      showRouterInventory: '<',
      showIncoming: '<',
      showOutgoing: '<',
      showVast: '<',
      showOutgoingSshb: '<',
      dsp: '<',
      timezone: "@"
    },
    template: require("../templates/troubleshootingReport.html"),
    controllerAs: '$troubleshootingReportCtrl',
    controller: ['$scope', function($scope) {

      var self = this;
      var incomingRequestsDatatable;
      var outgoingRequestsDatatable;
      var timeouts = {
        realTime: undefined
      };
      var realTimeHash = '';
      var tagTypeSupply = '1';
      var tagTypeDemand = '2';
      var tagTagTypes = [tagTypeSupply, tagTypeDemand];
      var documentSourceTypes = [];
      var hashLoaded = $.Deferred();
      var documentTypeMap = {
        "broadcast-ssb-response": {
          "label": "VAST Response",
          "tabClass": ".outgoing_ssbidder_requests"
        },
        "broadcast-sshb-response": {
          "label": "Bidder Request",
          "tabClass": ".vast_responses"
        }
      };

      this.stats = {
        metric: {}
      };

      this.historicalTargetingStats = {};

      this.inventoryTypeOptions = ['Domains', 'App Names', 'App Bundles'];

      this.programmaticInventoryTypeOptions = ['Publisher Names', 'Publisher IDs', 'App IDs', 'Site IDs', 'Inventory Source', 'Private Auction'];

      this.programmaticInventoryType = 'Publisher Names';

      this.eventTab = 'Performance';

      this.skipsTab = 'Sunburst';

      this.errorTab = 'Sunburst';

      this.userAgentChart = 'Device Type';

      this.userAgentOptions = ['Device Type', 'Device Make', 'OS', 'Browser'];

      this.contentChart = 'Episode';

      this.contentOptions = ['Episode', 'Title', 'ID', 'Series', 'Season', 'Genre', 'Channel', 'Network', 'Producer Name', 'Language', 'Rating', 'Production Quality', 'Livestream'];

      this.geoType = 'Country';

      this.playerSizeType = 'Size';

      this.playerSizeOptions = ['Size', 'Width', 'Height', 'Aspect Ratio'];

      this.privacyType = 'GDPR';

      this.privacyTypeOptions = ['GDPR', 'COPPA', 'DNT'];

      this.blockingCategoryType = 'Total Blocks';

      this.blockingCategoryOptions = ['Total Blocks', 'Targeting', 'Pricing', 'Ad Quality', 'Budget/Delivery', 'Pre-Bid', 'OpenRTB', 'System', 'Valid'];

      this.content2x = function() {
        return !_.contains(['Rating', 'Livestream', 'Production Quality', 'Language'], self.contentChart);
      };

      this.reflowCharts = function() {
        $scope.$broadcast('reflowHighcharts');
      };

      this.toggleChartType = function(chartType, val) {
        self[chartType] = val;
        self.reflowCharts();
      };

      this.resourcePresent = function() {
        return self.tagType !== '' && self.tagId !== '';
      };

      this.resource2Present = function() {
        return self.tagType2 !== '' && self.tagId2 !== '';
      };

      this.resouce2Addable = function() {
        return !self.resource2Enabled && _.contains(tagTagTypes, self.tagType) && self.resourcePresent();
      };

      this.setResource2Enabled = function(val) {
        self.resource2Enabled = val;
      };

      this.unselectTagCb = function() {
        self.tagType2 = '';
        self.tagId2 = '';
        self.tagName2 = '';
        self.setResource2Enabled(false);
      };

      this.selectTagCb = function(id) {
        $('#troubleshooting_form').submit();
      };

      this.togglePause = function(val) {
        self.pauseStats = val;

        if (self.pauseStats) {
          destroyTimeout(timeouts, 'realTime');

          _.each(documentTypeMap, function(value, key, list) {
            if (value.timeout) {
              destroyTimeout(value, 'timeout');
            }
          });
        }
        else {
          startDataFlow(false);
        }
      };

      this.breadcrumbNgClass = function(multipleColumns, isDemandTag) {
        var width = 12;
        var hidden = false;

        if (multipleColumns) {
          if (self.tabHref === '#tabIncoming') {
            if (isDemandTag) {
              hidden = true;
            }
          }
          else if (self.tabHref === '#tabOutgoing') {
            if (!isDemandTag) {
              hidden = true;
            }
          }
          else {
            width = 4;
          }
        }

        return {
          'col-lg-4' : width === 4,
          'col-lg-12': width === 12,
          'hidden': hidden
        };
      };

      $('a[data-toggle="tab"]').on('show.bs.tab', function (e) {
        self.tabHref = $(e.target).attr('href');
        const documentTabClasses = _.pluck(documentTypeMap, 'tabClass').join(',');

        if ($(this).is(documentTabClasses) && !self.pullDocuments) {
          self.pullDocuments = true;

          if (!self.pauseStats) {
            activateDocuments();
          }
        }

        if (self.historicalTargetingTabSelected()) {
          // Alway hide the filters display on historical targeting tab.
          $('.column-filters').removeClass('show');
          self.filterPreviouslySet = false;

          if (self.resourcePresent()) {
            // Remove second object reference.
            self.unselectTagCb();
            // Pause real time data fetching.
            self.togglePause(true);
            getHistoricalTargeting();
          }
        }

        $scope.$apply();
      });

      this.historicalTargetingTabSelected = () => {
        return this.tabHref === "#tabHistorical_targeting";
      };

      this.shouldShowPlayPauseButton = () => {
        return this.resourcePresent() && !this.historicalTargetingTabSelected();
      };

      this.filterClick = () => {
        // Shows and hides the filters display
        $('.column-filters.collapse').toggleClass('show');
        // Changes the Filter button text icon
        $('.btn.filter-collapse').toggleClass('collapsed');
      };

      this.filterChange = () => {
        this.filterPreviouslySet = true;
        startDataFlow(false);
      };

      this.hasFilter = () => {
        return !!(this.dealId ||
          this.publisherId ||
          this.publisherName ||
          this.inventorySource ||
          this.siteId ||
          this.appId ||
          this.privateAuction ||
          this.filterPreviouslySet
        );
      };

      this.shouldShowFilter = () => {
        return this.dsp && !this.historicalTargetingTabSelected();
      };

      var supplyDemandComboData = function(supply_id, demand_id) {
        return {
          object_type: 5,
          object_id: supply_id + '-' + demand_id,
          deal_id: self.dealId,
          publisher_id: self.publisher_id,
          publisher_name: self.publisher_name,
          inventory_source: self.inventory_source,
          site_id: self.site_id,
          app_id: self.app_id,
          private_auction: self.private_auction
        };
      };

      var objectData = function() {
        return {
          object_type: self.tagType,
          object_id: self.tagId,
          deal_id: self.dealId,
          publisher_id: self.publisherId,
          publisher_name: self.publisherName,
          inventory_source: self.inventorySource,
          site_id: self.siteId,
          app_id: self.appId,
          private_auction: self.privateAuction
        };
      };

      var registerData = function() {
        if ( self.resource2Present() && angular.equals(tagTagTypes, [self.tagType, self.tagType2].sort()) ) {
          if (self.tagType === tagTypeSupply) {
            return supplyDemandComboData(self.tagId, self.tagId2);
          }
          else {
            return supplyDemandComboData(self.tagId2, self.tagId);
          }
        }
        else {
          return objectData();
        }
      };

      var initializeDataTable = function(table) {
        if ( table.length > 0 ) {
          return table.defaultDataTable({"iDisplayLength": 200});
        }
      };

      var updateRealTimeDataTable = function(datatable, data) {
        if (!datatable) {
          return;
        }
        datatable.clear();
        datatable.rows.add(data || []).draw();
      };

      var refreshCharts = function() {
        $('.refreshing-panel').addClass('hidden');

        _.each(self.charts, function(chart, name) {
          $scope.$broadcast('refreshHighchart', chart);
        });
      };

      var setRealTimeData = function(stats) {
        self.charts.realTime.data = stats || {};
      };

      var getMetrics = function() {
        $.post({
          url: '/real_time_data/metrics',
          dataType: "json",
          data: {
            hash: realTimeHash,
            include_opps: self.includeOpps,
            audio: self.audio,
            pod: self.pod
          },
          success: function(resp) {
            self.reportRun = true;
            self.stats = resp || {};
            setRealTimeData(self.stats.real_time);
            updateRealTimeDataTable(incomingRequestsDatatable, resp.incoming_requests);
            updateRealTimeDataTable(outgoingRequestsDatatable, resp.outgoing_requests);
            if (!self.stats.has_data) {
              self.togglePause(true);
            }
            $scope.$apply();
            refreshCharts();
            if (!self.pauseStats) {
              startRealTimeTimeout();
            }
          }
        });
      };

      const getHistoricalTargeting = () => {
        const date_format = 'YYYY-MM-DDTHH:mm:ss';
        const now = moment.tz(this.timezone).format(date_format);
        const past_week = moment.tz(this.timezone).subtract(7, 'days').startOf('day').format(date_format);
        $.post({
          url: '/settings/troubleshooting/historical_targeting',
          dataType: "json",
          data: {
            tag_type: self.tagType,
            tag_id: self.tagId,
            interval: "day",
            min_ymdh: past_week,
            max_ymdh: now
          }, 
          success: (resp) => {
            if (resp) {
              self.historicalTargetingStats = resp || {};  
              $scope.$apply();
              refreshCharts();
            }
          }
        });

        // Get Data for hourly report
        $.post({
          url: '/settings/troubleshooting/historical_targeting',
          dataType: "json",
          data: {
            tag_type: self.tagType,
            tag_id: self.tagId,
            interval: "hour",
            min_ymdh: past_week,
            max_ymdh: now
          }, 
          success: (resp) => {
            if (resp) {
              self.historicalTargetingStatsHourly = resp || {};  
              $scope.$apply();
              refreshCharts();
            }
          }
        });
      };

      this.targetingGroupPanelTitle = (group) => `${group.title} Blocks: ${group.total.toLocaleString()} (${group.percentage})`;

      this.setPanelTab = (panelName, value) => {
        this[panelName + "PanelTab"] = value;
      };

      this.initPanelTab = (panelName, value) => this.setPanelTab(panelName, this.dsp ? 'table' : value);

      this.setGraphTab = (panelName, value) => {
        this[panelName + "GraphTab"] = value;
      };

      this.getHourlyHistorical = (category) => {
        if (this.historicalTargetingStatsHourly) {
          const categoryData = this.historicalTargetingStatsHourly.groups[category];
          return categoryData ? categoryData.graph_data : {};
        }
      };

      var startRealTimeTimeout = function() {
        if ( timeouts.realTime ) {
          return;
        }

        timeouts.realTime = setTimeout(function() {
          destroyTimeout(timeouts, 'realTime');
          getMetrics();
        }, 2500);
      };

      var destroyTimeout = function(object, value) {
        clearTimeout(object[value]);
        object[value] = undefined;
      };

      var setDocumentSourceTypes = function() {
        var st = [];

        if (self.showVast) {
          st.push('broadcast-ssb-response');
        }
        if (self.showOutgoingSshb) {
          st.push('broadcast-sshb-response');
        }

        documentSourceTypes = st;
      };

      var activateDocuments = function() {
        setDocumentSourceTypes();

        $.when( hashLoaded ).done(function() {
          if (documentSourceTypes.length === 0) {
            return;
          }

          $.post({
            url: '/real_time_data/activate_documents',
            dataType: "json",
            data: {
              hash: realTimeHash,
              source_types: documentSourceTypes
            },
            success: function(resp) {
              getDocuments();
            }
          });
        });
      };

      var searchDocuments = function(sourceType, sourceMap) {
        $.post({
          url: '/real_time_data/search_documents',
          dataType: "json",
          data: {
            hash: realTimeHash,
            source_types: [sourceType],
            document_label: sourceMap.label
          },
          success: function(resp) {
            updateRealTimeDataTable(sourceMap.datatable, resp.documents);

            if (!self.pauseStats) {
              setDocumentTimeout(sourceType, sourceMap);
            }
          }
        });
      };

      var setDocumentTimeout = function(sourceType, sourceMap) {
        if (sourceMap.timeout) {
          return;
        }

        sourceMap.timeout = setTimeout(function() {
          destroyTimeout(sourceMap, 'timeout');
          searchDocuments(sourceType, sourceMap);
        }, 10000);
      };

      var getDocuments = function() {
        _.each(documentSourceTypes, function(sourceType) {
          var sourceMap = documentTypeMap[sourceType];
          setDocumentTimeout(sourceType, sourceMap);
        });
      };

      var startDataFlow = function(initialLoad) {
        $.post({
          url: '/real_time_data/register',
          dataType: "json",
          data: registerData(),
          success: function(data) {
            realTimeHash = data.hash;
            startRealTimeTimeout();

            if (initialLoad) {
              hashLoaded.resolve();
            }
            else if (self.pullDocuments) {
              activateDocuments();
            }
          }, error: function(e, resp) {
            self.reportRun = true;
            self.stats = resp || {};
            self.togglePause(true);
            $scope.$apply();
          }
        });
      };

      var getResourceInfo = function() {
        $.get('/settings/troubleshooting/resource_info.json', {
          tag_type: self.tagType,
          tag_id: self.tagId,
          tag_type2: self.tagType2,
          tag_id2: self.tagId2
        }, function(json) {
          if (json) {
            $('#debug_info_partial').replaceWith(json.info_partial);
            $('#debug_breadcrumbs').replaceWith(json.breadcrumbs);
            $('#debug_breadcrumbs2').replaceWith(json.breadcrumbs2);
            $('#debug_info_partial_historical').replaceWith(json.info_partial);


            $('#tabDebugger ajax-partial, #tabHistorical_targeting ajax-partial').each(function(i, partial) {
              $.ajax({
                url: $(partial).attr('ajax-path'),
                cache: false
              }).done(function(html) {
                $(partial).html(html);
                $(partial).find('[data-toggle="popover"]').popover();
              });
            });

            $scope.$apply();

            platformUtilities.initSvgFontAwesomeIcons();
          }
        });
      };

      this.addDynamicChart = (chartName) => {
        this.charts[chartName] =  {id: chartName + 'Dynamic'};
      };

      var initCharts = function() {
        var chartNames = [
          'environmentPie', 'countryPie', 'dmaPie', 'playerSizeDeclaredPie', 'playerSizeDetectedPie', 'playerSizeWidthDeclaredHistogram',
          'playerSizeWidthDetectedHistogram', 'playerSizeHeightDeclaredHistogram', 'playerSizeHeightDetectedHistogram',
          'playerSizeAspectDeclaredScatter', 'playerSizeAspectDetectedScatter', 'browserPie', 'deviceMakePie', 'deviceTypePie',
          'osPie', 'appBundleStacked', 'appNameStacked', 'domainDeclaredStacked', 'domainDetectedStacked', 'dspPublisherNameStacked',
          'dspSiteIdStacked', 'dspAppIdStacked', 'dspInventorySourcePie', 'dspPrivateAuctionPie', 'skipsSunburst',
          'dspDealIdStacked', 'dspPublisherIdStacked', 'appBundlePie', 'appNamePie', 'domainDeclaredPie', 'domainDetectedPie',
          'supplyStacked', 'realTime', 'sunburst', 'errorSunburst', 'contentRatingPie', 'contentProdqPie', 'contentLanguagePie', 'contentLivestreamPie',
          'contentChannelStacked', 'contentEpisodeStacked', 'contentGenreStacked', 'contentIdStacked', 'contentKeywordsStacked',
          'contentNetworknameStacked', 'contentProducernameStacked', 'contentSeasonStacked', 'contentSeriesStacked', 'contentTitleStacked',
          'coppaPie', 'dntPie', 'gdprPie', 'historicalBlockEventsSunburst'
        ];

        self.charts = _.object(_.map(chartNames, function(chartName) {
          return [chartName, {id: chartName + 'Debug'}];
        }));
      };

      this.$onInit = function(e) {
        self.tabHref = $(location).attr('hash');
        getResourceInfo();
        initCharts();
        self.setResource2Enabled(self.resource2Present());

        setTimeout(function() {
          incomingRequestsDatatable = initializeDataTable($('#incoming_requests_table'));
          outgoingRequestsDatatable = initializeDataTable($('#outgoing_requests_table'));
          documentTypeMap['broadcast-ssb-response'].datatable = initializeDataTable($('#vast_responses_table'));
          documentTypeMap['broadcast-sshb-response'].datatable = initializeDataTable($('#outgoing_ssbidder_requests_table'));
        }, 0);

        if (self.resourcePresent()) {
          self.tagTypeOnInit = self.tagType;
          self.resourcePresentOnInit = true;
          if(!self.historicalTargetingTabSelected()) {
            startDataFlow(true);
          }
        }
        if (self.resource2Present()) {
          self.tagType2OnInit = self.tagType2;
          self.resource2PresentOnInit = true;
        }
      };

    }]
});

angular.module('vastdesk', ['ngSanitize', 'angular-bind-html-compile']);


$(document).on('turbo:load', function() {
  var highchartLimit = 20;
  var numHighcharts = Highcharts.charts.length;

  // cleanup highcharts between each load when over limit
  if (numHighcharts > highchartLimit) {
    _.each(Highcharts.charts, function(chart, index) {
      if(chart != null && index < (numHighcharts - highchartLimit)) {
        chart.destroy();
      }
    });
  }
});

// This will cause your app to compile when Turbo loads a new page
// and removes the need for ng-app in the DOM
$(document).on('turbo:load', function() {
  var element = angular.element(document.body);

  var isInitialized = element.injector();
  if (!isInitialized) {
    angular.bootstrap(element, ['vastdesk']);

    Highcharts.error = function(code) {
      console.log("Highcharts error: " + code);
    };
  }

});


import platformUtilities from '../../modules/platform_utilities.js';

angular.module('vastdesk').
  component('demandBidFloor',  {
    bindings: {
      formName: '@',
      formId: '@',
      currencyEditable: '<',
      rate: '=',
      rateCurrency: '=',
      currencyOptions: '<',
      bidFloorType: '@',
      bidMargin: '<',
      bidFloorTypes: '<',
      breakoutBid: '<',
      allowTierBreakout: '<',
      bidPlatform: '@',
      premiumHbPlatforms: '<',
      demandClass: '<',
      paymentSettings: '<',
      paymentSettingsTooltip: '<',
    },
    template: require("../templates/demandBidFloor.html"),
    controllerAs: '$demandBidFloorCtrl',
    controller: ['$rootScope', function($rootScope) {

      const bodySelector = $('body');

      this.setBidFloorType = (bidFloorType) => {
        if (this.paymentSettings) {
          return;
        }

        this.bidFloorType = bidFloorType;

        if (bidFloorType === 'dynamic') {
          initCurrencyChosen();
        }

        $rootScope.$broadcast('bidFloorTypeChanged', {
          bidFloorType: bidFloorType
        });
      };

      this.isBidFloorType = (bidFloorType) => {
        return this.bidFloorType === bidFloorType;
      };

      this.bidFloorTypeTooltip = (bidFloorType) => {
        if (this.paymentSettings) {
          return this.paymentSettingsTooltip;
        }

        if (bidFloorType === 'dynamic') {
          if (this.isLineItem()) {
            return  "Set bid percent over floor to 0 to bid at deal floor";
          } else {
            return "Dynamic floor Bidder tags will have a floor of $0.01 for Rev-Share and Dynamically priced supply tags.";
          }
        }
      };

      this.typeLabel = () => {
        if(this.isLineItem()) {
          return "Bid Type:";
        } else {
          return "Floor Type:";
        }
      };

      this.rateLabel = () => {
        let rl = "Floor Rate:";

        if (this.bidFloorType === 'flat') {
          rl = "Flat Rate:";
        }

        return rl;
      };

      this.bidFloorTypeTitle = (bidFloorType) => {
        return platformUtilities.titleize(bidFloorType);
      };

      this.tierBreakoutClickCb = (val) => {
        if (val) {
          initCurrencyChosen();
        }
      };

      this.premiumHb = () => {
        return Array.isArray(this.premiumHbPlatforms) && _.contains(this.premiumHbPlatforms, this.bidPlatform);
      };

      this.isLineItem = () => {
        return this.demandClass == '11';
      };

      const initCurrencyChosen = () => {
        setTimeout(() => {
          $('select[name*="[rate_currency]"]').defaultChosen();
        }, 0);
      };

      bodySelector.on('change', 'select[name*="[rate_currency]"]', () => {
        setTimeout(() => {
          $('select[name*="[rate_currency]"]').trigger('chosen:updated');
        }, 0);
      });

      $rootScope.$on('campaignHasRateChange', (e, args) => {
        if (args.hasRate) {
          this.setBidFloorType('static')
        }
      });

      $rootScope.$on('bidPlatformChange', (e, args) => {
        this.bidPlatform = args.bidPlatform;
      });

    }]
});

angular.module('vastdesk')
.component('bigListSearch', {
  bindings: {
    searchUrl: '@',
    placeholder: '@'
  },
  template: '<input type="text" placeholder="{{$ctrl.placeholder || &quot;Enter text&quot;}}" class="form-control" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" />',
  controller: ['$element', function($element) {

    var self = this;

    $element.find('input').autocomplete({
      minLength: 2,
      source: function( request, response ) {
        $.post(self.searchUrl, {
          search: request.term
        }).done(function( data ) {
          response($.map( data, function( item ) {
            return {
              label: item.label,
              value: item.label
            };
          }));
        });
      }
    });

  }]
});

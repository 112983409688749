angular.module('vastdesk')
.component('notificationsPanel', {
  bindings: {
    defaultShow: '@'
  },
  controller: function() {
    var self = this;
    var bodyElement = $('body');
    var unreadNotificationClass = "unread-notification-row";

    this.$onInit = function() {
      if (self.defaultShow === 'true') {
        $("#notifications-toggle").click();
      }
    };

    $("#notifications-toggle").popover({
      placement: 'bottom',
      html: true,
      content: function(){
        return $("#unread-notifications-cache").html();
      }
    });
    
    bodyElement.on("mousedown", "." + unreadNotificationClass, function(){
      var path = $(this).data("path");
      $.post(path, function( data ) {
        $("." + unreadNotificationClass + "[data-user-notification-id='" + data.user_notification_id + "']").removeClass(unreadNotificationClass);
        var notificationBatch = $(".notification-badge");
        notificationBatch.text(data.unread_count);
        if(data.unread_count == 0){
          notificationBatch.css("visibility", "hidden");
        }
      });
      return false;
    });

    bodyElement.on('click', '#notification-remove', function() {
      $("#notifications-toggle").click();
      $.get('/user_notifications/hide');
      return false;
    });

    $('#notifications-toggle').on('shown.bs.popover', function(){
      bodyElement.on('click', notificationToggleHandler);
    });

    $('#notifications-toggle').on('hidden.bs.popover', function(){
      bodyElement.off('click', notificationToggleHandler);
    });

    var notificationToggleHandler = function(e) {
      if ($(e.target).data('toggle') !== 'popover'
        && $(e.target).parents('.popover.in').length === 0
        && $(e.target).parents('#notifications-li').length === 0
        && e.target.id !== '#notifications-toggle'
      ) {
        $("#notifications-toggle").click();
        $.get('/user_notifications/hide');
      }
    };

  }
});

angular.module('vastdesk').
  component('accountSupportFields',  {
    bindings: {
      supportLevel: '@',
      supportFee: '<',
      supportCommencementDate: '@',
      supportLevelOptions: '<',
      readOnly: '<'
    },
    template: require("../templates/accountSupportFields.html"),
    controllerAs: '$accountSupportFieldsCtrl',
    controller: ['$sce', function($sce) {

      var self = this;

      this.setSupportLevel = function(level) {
        if (self.readOnly) {
          return;
        }

        self.supportLevel = level;
        $('.support-level-1-hidden').toggleClass('hidden', level === 'level_1');
      };

      this.supportFeePlaceholder = function() {
        if (self.supportLevel === 'level_1') {
          return 500;
        }
        else if (self.supportLevel === 'level_2') {
          return 1000;
        }
        else if (self.supportLevel === 'level_3') {
          return 3000;
        }
      };

      this.supportLevelPopover = function(level) {
        var htmlContent = '';

        if (_.contains(['level_1', 'level_2', 'level_3'], level)) {
          htmlContent += '<i class="fa fa-envelope"></i>';
        }
        if (_.contains(['level_2', 'level_3'], level)) {
          htmlContent += '<i class="fa fa-blind spacer-left-sm"></i>';
        }
        if (_.contains(['level_3'], level)) {
          htmlContent += '<i class="fab fa-slack-hash spacer-left-sm"></i>';
        }

        return $sce.trustAsHtml(htmlContent);
      };

    }]
});

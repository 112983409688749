angular.module('vastdesk').
  component('magniteStreamingDealsTable',  {
    bindings: {},
    controller: function() {

      let serverSideTable = $('#demand_tags_magnite_streaming_deals_table');

      serverSideTable.on('preXhr.dt', function (e, settings, data) {
        data.date_range = $('#demand_tags_magnite_streaming_deals_table_wrapper #date_range').val();
      });

    }
});

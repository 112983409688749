angular.module('vastdesk').
  component('thirdPartyFees',  {
    bindings: {
      formName: '@',
      formId: '@',
      nested: '<',
      thirdPartyFeeMetric: '@',
      thirdPartyFee: '<',
      thirdPartyFeeCurrency: '@',
      thirdPartyMetricMappings: '<',
      removeFeeFromRevenue: '<',
      currencyOptions: '<',
      currencyEditable: '<'
    },
    template: require("../templates/thirdPartyFees.html"),
    controllerAs: '$thirdPartyFeesCtrl',
    controller: function() {

    }
});

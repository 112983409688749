angular.module('vastdesk').
  component('dashboardComparison',  {
    bindings: {
      chartId: '@',
      timezone: '@',
      metrics: '<',
      selectedMetric: '<',
      defaultMetric: '<',
      dashboardTypes: '<',
      chartPath: '@',
      selectedType: '@',
      currencySymbol: '@'
    },
    template: require("../templates/dashboardComparison.html"),
    controllerAs: '$dashboardComparisonCtrl',
    controller: ['$rootScope', function($rootScope) {

      var self = this;

      var handleInvalidFormatMetric = function() {
        var selectedTypeMetric = self.selectedMetric[self.selectedType];
        var selectedTypeMetrics = self.metrics[self.selectedType];
        var metric = _.find(selectedTypeMetrics, function(m){return m[1] === selectedTypeMetric;});

        if (metricExcludedByFormat(metric)) {
          self.selectedMetric[self.selectedType] = self.defaultMetric[self.selectedType];
          self.changeComparisonMetric();
        }
      };

      var metricExcludedByFormat = function(metric) {
        return Array.isArray(metric) && metric[2] && Array.isArray(metric[2].excluded_formats) && _.contains(metric[2].excluded_formats, self.selectedFormat);
      };

      this.changeComparisonMetric = function() {
        $rootScope.$broadcast('changeComparisonMetric');
      };

      this.metricDisabled = function(metric) {
        return metricExcludedByFormat(metric);
      };

      $rootScope.$on('dashboardSelectedTypeChange', function (e, args) {
        self.selectedType = args.selectedType;
        handleInvalidFormatMetric();
      });

      $rootScope.$on('dashboardSelectedFormatChange', function (e, args) {
        self.selectedFormat = args.selectedFormat;
        handleInvalidFormatMetric();
      });

    }]
});

import platformUtilities from '../../modules/platform_utilities.js';
import WaterfallHelper from '../../modules/waterfall_helper.js';
import WaterfallModalHelper from '../../modules/waterfall_modal_helper.js';

angular.module('vastdesk').
  component('supplyRouterRatios',  {
    bindings: {
      setSupplyRouterRatios: '<',
      editable: '<',
      batchActions: '<',
      format: '@',
      modalPath: '@',
      supplyPartnerId: '<',
      environment: '@',
      persisted: '<',
      currencyPlaceholder: '@',
      forecast: '<'
    },
    template: require("../templates/supplyRouterRatios.html"),
    controllerAs: '$supplyRouterRatiosCtrl',
    controller: ['$scope', '$rootScope', '$timeout', function($scope, $rootScope, $timeout) {

      var self = this;
      var selectedIds = [];
      var serverSideTable = $('#tag_results table');
      var serverSideTableId = $(serverSideTable).attr('id');
      var serverSideDataTable;
      var modal = $('#tag_modal');
      var modalTable = $("#tag_modal table");
      var modalTableLoaded = $.Deferred();
      var bodySelector = $('body');
      var filteredRecordIds = [];
      var reportData = [];
      var waterfallHelper = new WaterfallHelper();
      var waterfallModalHelper = new WaterfallModalHelper();

      this.selectedSupplyTagBulkActionIds = [];

      var setSupplyRouterRatiosParams = function() {
        return _.map(self.setSupplyRouterRatios, function(t) {
          return _.pick(t, 'supply_tag_id', 'ratio', 'fallback_supply_tag_id');
        });
      };

      serverSideTable.on('init.dt', function() {
        serverSideDataTable = $(this).DataTable();
        waterfallHelper.addDateRangeSelect({forecast: self.forecast});

        if (Array.isArray(reportData) && reportData.length > 0) {
          setTimeout(function() {
            waterfallHelper.drawServerSideTable(serverSideDataTable, false);
          }, 0);
        }
      });

      serverSideTable.on('preXhr.dt', function (e, settings, data) {
        waterfallHelper.abortPendingAjaxRequests(serverSideTable, serverSideDataTable);

        data.set_supply_router_ratios = setSupplyRouterRatiosParams();
        data.report_data = waterfallHelper.getSupplyTagReportDataForDraw(serverSideTable, serverSideDataTable, reportData);
        data.custom_data = data.custom_data || {};
        data.custom_data.selected_ids = self.selectedSupplyTagBulkActionIds;
        data.date_range = $('#date_range').val();

        $('.ssrr-r').blur();
      });

      serverSideTable.on('xhr.dt', function(e, settings, json, xhr) {
        if (json && json.custom_data) {
          filteredRecordIds = json.custom_data.filtered_record_ids;

          $rootScope.$broadcast('dynamicPodChange', {
            dynamicPod: json.custom_data.dynamic_pod
          });

          $rootScope.$broadcast('hasVastOnlySupplyTagChange', {
            hasVastOnlySupplyTag: json.custom_data.apply_vast_only_vendors
          });

          $rootScope.$broadcast('customAdSlotDurationChange');

          $rootScope.$broadcast('openMarketEnabledChange', {
            openMarketEnabled: json.custom_data.open_market_enabled
          });
        }
        if (json && json.data) {
          attachReportData(json.data);
        }
      });

      var reportRowFor = function(tableRow) {
        return _.findWhere(reportData, {supply_tag_id: tableRow.DT_RowAttr['data-supply-tag-id']}) || {};
      };

      var attachReportData = function(rows) {
        _.each(rows, function(r) {
          var reportRow = reportRowFor(r);
          waterfallHelper.addReportDataToRow(r, reportRow, {
            objectType: 'SupplyRouter',
            currencyPlaceholder: self.currencyPlaceholder
          });
        });
      };

      bodySelector.on("click", "form.tag-form a.remove-tag", function () {
        var row = waterfallHelper.rowForTableElement(this);
        var id = supplyTagIdForRow(row);

        removeSetSupplyRouterRatios([id], true);

        return false;
      });

      bodySelector.on("click", "#associated_tag_section .remove-all", function(e){
        removeAllFilteredSetSupplyRouterRatios();
        return false;
      });

      bodySelector.on('change', 'td .ssrr-r', function () {
        handleRatioChange(this);
      });

      bodySelector.on('keyup mousedown', 'td .ssrr-r', function () {
        throttledDraw.cancel();
        waterfallHelper.abortPendingAjaxRequests(serverSideTable, serverSideDataTable);
        platformUtilities.triggerDataTablesProcessing(serverSideTable, false);
      });

      var removeSetSupplyRouterRatios = function(supplyTagIds, applyScope) {
        var startingLength = self.setSupplyRouterRatios.length;

        self.setSupplyRouterRatios = _.reject(self.setSupplyRouterRatios, function(ssrr) {
          return _.contains(supplyTagIds, parseInt(ssrr.supply_tag_id));
        });

        var endingLength = self.setSupplyRouterRatios.length;
        var tagsRemoved = startingLength - endingLength;

        if (tagsRemoved < 1) {
          return;
        }

        if (applyScope) {
          $scope.$apply();
        }

        waterfallHelper.drawServerSideTableAfterRemoval(serverSideDataTable, tagsRemoved);
      };

      var removeAllFilteredSetSupplyRouterRatios = function() {
        self.setSupplyRouterRatios = _.filter(self.setSupplyRouterRatios, function(ssrr) {
          return !_.contains(filteredRecordIds, parseInt(ssrr.supply_tag_id));
        });
        $scope.$apply();
        waterfallHelper.drawServerSideTable(serverSideDataTable, true);
      };

      var supplyTagIdForRow = function(row) {
        return $(row).data('supplyTagId');
      };

      var ratioWithSupplyTagId = function(ratios, id) {
        return _.find(ratios, function(ssrr) {
          return parseInt(ssrr.supply_tag_id) === parseInt(id);
        });
      };

      var setSupplyTagRatioFor = function(row) {
        var id = supplyTagIdForRow(row);
        return ratioWithSupplyTagId(self.setSupplyRouterRatios, id);
      };

      var throttledDraw = _.throttle(function () {
        waterfallHelper.drawServerSideTable(serverSideDataTable, false);
      }, 750, {leading: false});

      var updateSetSupplyRouterRatio = function(row, attributes, draw) {
        var ssrr = setSupplyTagRatioFor(row);

        if (ssrr) {
          ssrr = _.extend(ssrr, attributes);
          $scope.$apply();

          if (draw) {
            throttledDraw.cancel();
            throttledDraw();
          }
        }
      };

      var updateSetSupplyRouterRatioFromElement = function(element, attribute, draw) {
        var row = waterfallHelper.rowForTableElement(element);
        var attributes = {};
        attributes[attribute] = waterfallHelper.valueForElement(element, attribute);
        updateSetSupplyRouterRatio(row, attributes, draw);
      };

      var handleRatioChange = function(element) {
        updateSetSupplyRouterRatioFromElement(element, 'ratio', true);
      };

      var currentSupplyTagIds = function() {
        return waterfallHelper.extractIdsFromSetList(self.setSupplyRouterRatios, 'supply_tag_id');
      };

      var addSetSupplyRouterRatios = function(resp) {
        if (!resp.rows) {
          return;
        }

        var currentIds = currentSupplyTagIds();

        var validNewSupplyRouterRatios = _.reject(resp.rows, function(dtp) {
          return _.contains(currentIds, parseInt(dtp.supply_tag_id));
        });

        self.setSupplyRouterRatios = self.setSupplyRouterRatios.concat(validNewSupplyRouterRatios);
        $scope.$apply();
        waterfallHelper.drawServerSideTable(serverSideDataTable, false);
      };

      var handleBulkChange = function(supplyTagIds, attribute, value) {
        _.each(self.setSupplyRouterRatios, function(ssrr) {
          if (_.contains(supplyTagIds, parseInt(ssrr.supply_tag_id))) {
            ssrr[attribute] = value;
          }
        });

        $timeout(function() {
          $scope.$apply();
        });

        waterfallHelper.drawServerSideTable(serverSideDataTable, false);
      };

      modalTable.on('preXhr.dt', function (e, settings, data) {
        data.supply_partner_id = self.supplyPartnerId;
        data.custom_data = data.custom_data || {};
        data.custom_data.selected_ids = [].concat(currentSupplyTagIds()).concat(selectedIds);
        data.custom_data.environment = self.environment;
      });

      modalTable.on('init.dt', function () {
        modalTableLoaded.resolve();
      });

      modalTable.on('draw.dt', function () {
        _styleModalRows();
        waterfallHelper.setModalCheckboxes(modalTable, currentSupplyTagIds());
      });

      bodySelector.on('click', '.open-tag-modal', function (e) {
        if ($(this).attr('disabled')) {
          e.preventDefault();
          return false;
        }
        selectedIds = [];
      });

      waterfallModalHelper.openEventHandler(bodySelector, '.open-tag-modal', modal, modalTable, function() {
        $.when( modalTableLoaded ).done(function() {
          waterfallModalHelper.setEnvironmentFilter(modal, self.environment);
          waterfallModalHelper.setFormatFilter(modal, self.format);
          waterfallModalHelper.hideShowFormatFilters(modal, self.format);
          $('.modal-content .dataTables_filter input').attr("placeholder", "Search by tag name, or enter a comma-separated list of IDs...");
        });
      });

      waterfallModalHelper.saveEventHandler(bodySelector, modal, modalTable, function() {
        var path = $('#tag_results').data('ssTableRowsPath');
        var params = {"object_ids": selectedIds};

        $.post(path, params, function(resp) {
          addSetSupplyRouterRatios(resp);
        });
      });

      waterfallModalHelper.modalCheckEventHandler(bodySelector, 'tag_ids', function(val) {
        selectedIds.push(val);
      }, function(val) {
        selectedIds = _.without(selectedIds, val);
      });

      var _styleModalRows = function (){
        var statusIndex = modalTable.find("th.status").index();

        modalTable.find("tr").each(function(i, tr) {
          waterfallModalHelper.styleStatus(tr, statusIndex);
        });
      };

      $scope.$on('updatingReportData', function(e, args) {
        if (args.table === serverSideTableId) {
          platformUtilities.triggerDataTablesProcessing(serverSideTable, true);
        }
      });

      $scope.$on('updatedReportData', function(e, args) {
        if (args.table === serverSideTableId) {
          reportData = args.resp.supply_tag_report;
          self.currencyPlaceholder = args.resp.currency_placeholder;
          waterfallHelper.drawServerSideTable(serverSideDataTable, false);
        }
      });

      $scope.$on('drawServerSideTable', function(e, args) {
        if (args.tableId === serverSideTableId) {
          waterfallHelper.drawServerSideTable(serverSideDataTable, false);
        }
      });

      $rootScope.$on('fallbackSupplyTagIdChange', function(e, args) {
        handleBulkChange([args.fallbackParentSupplyTagId], 'fallback_supply_tag_id', args.fallbackSupplyTagId);
      });

      $scope.$on('bulkEditBroadcast', function(e, args) {
        if (args.tableId === serverSideTableId) {
          if (!args.selectedIds || !Array.isArray(args.selectedIds)) {
            return;
          }

          var supplyTagIds = _.map(args.selectedIds, function(id) {
            return parseInt(id);
          });

          if (args.id === 'remove') {
            removeSetSupplyRouterRatios(supplyTagIds, false);
          }
          else if (args.id === 'ratio') {
            handleBulkChange(supplyTagIds, 'ratio', waterfallHelper.parseIntOrNull(args.value));
          }
        }
      });

      $rootScope.$on('formatChanged', function(e, args) {
        self.format = args.format;
      });

      $rootScope.$on('environmentChange', function(e, args) {
        self.environment = args.environment;
      });

      $rootScope.$on('supplyPartnerIdChange', function(e, args) {
        self.supplyPartnerId = args.supplyPartnerId;

        if (self.setSupplyRouterRatios.length < 1 || self.persisted) {
          return;
        }

        self.setSupplyRouterRatios = [];
        waterfallHelper.drawServerSideTable(serverSideDataTable, true);
      });

      var intervalTime = 0;
      setInterval(function () {
        intervalTime += 1;
        if (intervalTime >= 60 && document.hasFocus() && $("#tabSupply").is(".active")) {
          $scope.$broadcast('updateReportData', {table: serverSideTableId});
          intervalTime = 0;
        }
      }, 1000);

    }]
});

import HighchartsBuilder from '../../modules/highcharts_builder.js';

angular.module('vastdesk')
.component('historicalBlocksStackedColumnChart', {
  bindings: {
    chartId: '@',
    chartHeight: '@',
    categories: '=?',
    chartData: '=',
    timeInterval: '=',
    timezone: '@',
    lookbackDays: '@'
  },
  template: '<div class="graphChart stackedColumnChart"><div></div></div>',
  controller: ['$scope', '$rootScope', '$element', function($scope, $rootScope, $element) {

    const self = this;
    const onInitLoad = $.Deferred();
    const highchartsBuilder = new HighchartsBuilder(self.timezone);
    const colors = highchartsBuilder.chartColors;
    const chartDiv = $element.find('div');
    const formatType = highchartsBuilder.formatTypeForMetric(self.metric);
    let chart;

    this.$onInit = function() {
      onInitLoad.resolve();
    };

    const setChart = () => {
      chart = chartDiv.highcharts({
        chart: {
          type: 'column',
          animation: false,
          plotBorderWidth: null,
          backgroundColor: null,
          height: (this.chartHeight || 400) + 'px',
          zooming: {
            type: 'xy'
          },
        },
        title: {text: ''},
        credits: {enabled: false},
        legend: _.extend({}, highchartsBuilder.defaultLegend(), {maxHeight: 44}),
        xAxis: {
          categories: setDateTimeCategories(),
          labels: {
            enabled: Array.isArray(this.chartData.categories),
            style: {
              color: '#acacac',
              fontSize: '9px'
            }
          },
          tickLength: 0,
          lineColor: '#ccd6eb'
        },
        yAxis: [
          {
            title: {text: ''},
            labels: {
              formatter: function() {
                return highchartsBuilder.revisedDefaultLabelFormatter(this);
              },
              reserveSpace: true,
              style: {
                fontSize: '9px',
                fontWeight: 'bold',
                color: colors[0]
              }
            },
            stackLabels: {
              enabled: false
            },
            gridLineColor: '#d7d7d7'
          },
          {
            gridLineWidth: 0,
            title: {text: ''},
            labels: {
              reserveSpace: true,
              style: {
                fontSize: '9px',
                fontWeight: 'bold',
                color: colors[11], //quaternary color
                opposite: true
              }
            },
            opposite: true,
            gridLineColor: '#d7d7d7'
          }
        ],
        colors: colors,
        tooltip: {
          formatter: function() {
            return `
              <div style="font-size: 10px;">
                ${this.series.name}: ${Highcharts.numberFormat(this.point.y,0,'.',',')}<br/>
                ${(this.point.percentage ? this.point.percentage.toFixed(2)+ "% of Group" : "")}
              </div>
            `;
          },
          style: {
            opacity: '0.95'
          }
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            borderWidth: 0,
            maxPointWidth: 50,
            grouping: false,
            shadow: false,
            dataLabels: {
                enabled: false
            }
          }
        },
        series: [ ...groupSeries(), validSeries() ].filter( Boolean )
      });
    };

    const groupSeries = () => {
      return this.chartData.series.map((series) => {
        return {
          name: series.name,
          type: 'column',
          yAxis: 0,
          data: series.data
        };
      });
    };

    const validSeries = () => {
      if (!this.chartData.validTotal){
        return null;
      }
      return {
        name: 'Valid',
        type: 'spline',
        yAxis: 1,
        data: this.chartData.validTotal.data,
        formatType: formatType,
        color: colors[11], //quaternary color
        shadow: highchartsBuilder.defaultShadow(),
        lineWidth: 2,
        zIndex: 2,
        connectNulls: false,
        dashStyle: 'solid',
        legendSymbol: 'lineMarker',
        marker: {
          enabled: false
        }
      };
    };

    const setDateTimeCategories = () => {
      if(!this.chartData.categories){
        return [];
      }

      return this.chartData.categories.map((category) => {
  
        let format = 'MM/DD/YY';

        if(this.timeInterval === "hour") {
          if (this.lookbackDays === "7") {
            format = 'MM/DD/YY h a';
          } else {
            format = 'h a';
          }
        }

        return highchartsBuilder.getMoment(category).format(format);
      });
    };

    $scope.$on('setHighchartLoading', function(e, args) {
      highchartsBuilder.loadData(onInitLoad, chartDiv, self, args);
    });

    $scope.$on('refreshHighchart', function(e, args) {
      highchartsBuilder.refreshData(onInitLoad, chartDiv, self, args, function() {
        setChart();
      });
    });

    $scope.$on('reflowHighcharts', function(e, args) {
      highchartsBuilder.deferredReflowChart(onInitLoad, chartDiv);
    });

  }]
});

import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('dntTargeting',  {
    bindings: {
      formName: '@',
      formId: '@',
      allValues: '<',
      valueSelections: '<',
      paramRequired: '<',
      readOnly: '<',
      valueMappings: '<'
    },
    template: require("../templates/dntTargeting.html"),
    controllerAs: '$dntTargetingCtrl',
    controller: function() {
      this.allCustomOptions2 = componentHelper.allCustomOptions2();
    }
});

class DecimalEnforcer {
  enforce(value, roundingStrategy, decimalPlaces) {
    var rawNumber = this.getRawValue(value);

    if (this.isEmpty(rawNumber)) {
      return null;
    }

    if ( this.countDecimals(rawNumber) > decimalPlaces ) {
      var pow = this.getPow(decimalPlaces);
      rawNumber = Math[roundingStrategy](rawNumber*pow)/pow;
    }
    return rawNumber;
  }

  isEmpty(value) {
    return !value || !value.length;
  }

  countDecimals(value) {
    var numDecimals = 0;
    var numSplit = value.toString().split('.');

    if (numSplit.length > 1) {
      numDecimals = numSplit[1].length;
    }

    return numDecimals;
  }

  getPow(decimalPlaces) {
    if (decimalPlaces < 1) {
      return 1;
    }
    else {
      return Math.pow(10, decimalPlaces);
    }
  }

  getRawValue(value) {
    return (value || '').toString().replace(/[^0-9.]/g, '');
  }
}

export default DecimalEnforcer;
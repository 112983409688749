import HighchartsBuilder from '../../modules/highcharts_builder.js';

angular.module('vastdesk')
.component('stackedBarChart', {
  bindings: {
    chartId: '@',
    chartData: '=',
    chartData2: '=?',
    seriesName: '@',
    seriesName2: '@',
    tooltipSeriesName: '@'
  },
  template: '<div class="graphChart stackedBarChart"><div></div></div>',
  controller: ['$scope', '$element', function($scope, $element) {

    var self = this;
    var onInitLoad = $.Deferred();
    var highchartsBuilder = new HighchartsBuilder();
    var colors = highchartsBuilder.chartColors;
    var chartDiv = $element.find('div');

    var setChart = function() {
      chartDiv.highcharts({
        chart: {
          type: 'bar',
          plotBorderWidth: null,
          backgroundColor: null
        },
        title: {
          text: ''
        },
        credits:{
          enabled: false
        },
        xAxis: {
          type: 'category',
          tickLength: 0,
          lineColor: '#ccd6eb',
          labels: {
            useHTML:true,
            style:{
              width:'100px',
              whiteSpace:'nowrap',
              textOverflow: 'none'
            },
            step: 1,
            formatter: function () {
                return '<div class="stacked-bar-label" title="' + this.value + '">' + this.value + '</div>';
            }
          }
        },
        yAxis: {
          labels: {enabled:false},
          title: {
            text: ''
          },
          tickInterval: 10
        },
        legend: {
          enabled: false
        },
        tooltip: {
          formatter: function() {
            var name = (self.tooltipSeriesName === 'true') ? this.series.name : this.point.name;
            var format = '<div style="font-size: 10px;">' + name + '</div><br/>';
            format += '<b>' + this.point.y.toFixed(2) + '%</b>';
            return format;
          },
          style: {
            opacity: '0.95'
          }
        },
        plotOptions: {
          series: {
            stacking: 'normal'
          }
        },
        series: [{
          name: self.seriesName,
          data: self.chartData,
          maxPointWidth: 10,
          color: colors[0]
        }, {
          name: self.seriesName2,
          data: self.chartData2,
          maxPointWidth: 10,
          color: colors[1]
        }]
      });
    };

    $scope.$on('refreshHighchart', function(e, args) {
      $.when( onInitLoad ).done(function() {
        if (args.id === self.chartId) {
          setTimeout(function() {
            var chart = chartDiv.highcharts();

            if (chart && chart.series) {
              chart.series[0].setData(self.chartData);
              chart.series[1].setData(self.chartData2);
            }
          }, 0);
        }
      });
    });

    $scope.$on('reflowHighcharts', function(e, args) {
      highchartsBuilder.deferredReflowChart(onInitLoad, chartDiv);
    });

    this.$onInit = function() {
      setChart();
      onInitLoad.resolve();
    };

  }]
});
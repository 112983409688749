import platformUtilities from '../../modules/platform_utilities.js';
import WaterfallHelper from '../../modules/waterfall_helper.js';

angular.module('vastdesk')
.component('openMarketExternalBiddersModal', {
  bindings: {
    model: '=',
    metrics: '=',
    autoAddModel: '=',
    tagOpenrtbFloor: '=',
    readOnly: '=',
    path: '@',
    dummyName: '@',
    objectId: '@',
    summaryPath: '@',
    qualityPath: '@',
    timezone: '@',
    selectedMetric: '@',
    chartPath: '@',
    reportingView: '@',
    openMarketDemandTagId: '@',
    waterfallTable: '@',
    bidDensityReportLink: '@',
    leftAxisSeries: '@',
    rateCurrency: '@',
    currency: '@',
    currencySymbol: '@',
    currencyPlaceholder: '@',
    dateRanges: '<'
  },
  template: require("../templates/openMarketExternalBiddersModal.html"),
  controllerAs: '$openMarketExternalBiddersModalCtrl',
  controller: ['$scope', '$element', '$rootScope', '$filter', function($scope, $element, $rootScope, $filter) {

    var waterfallHelper = new WaterfallHelper();

    this.tableId = 'open_market_external_bidders_table';
    this.autoAddExternalBidders = false;
    this.setOpenMarketBidders = [];
    this.reportData = [];
    this.externalBidders = [];
    this.selectedExternalBidder = '';
    this.activeTab = '';

    waterfallHelper.dateRangeize($element.find('.date-filter select'));

    var self = this;
    var onInitLoad = $.Deferred();
    var modal = $element.find('.modal');
    var modalTable = $element.find('#' + self.tableId);
    var modalDataTable;
    var oTable;

    var waterfallTableDateRange = function() {
      return $('#' + self.waterfallTable).parents('.dataTables_wrapper').find('#date_range');
    };

    var openMarketModalDateRange = function() {
      return $element.find('#date_range');
    };

    var bidFloorFieldFor = function(id) {
      return modalTable.find('#external_bidder_' + id + '_openrtb_floor');
    };

    var styleBidFloorField = function(bidFloorField, val) {
      var isInvalid = !!(val && self.tagOpenrtbFloor && val < self.tagOpenrtbFloor);
      bidFloorField.toggleClass('invalid-field', isInvalid);
      bidFloorField.closest('tr').toggleClass('invalid-row', isInvalid);
    };

    modal.on('show.bs.modal', function(e) {
      waterfallHelper.syncDateRange(waterfallTableDateRange(), openMarketModalDateRange());
      resetBidders();
      modalTable.find("input[type='checkbox']").prop('checked', false);
      modalTable.find("input[name*='[openrtb_floor]']").attr('placeholder', self.tagOpenrtbFloor);
      setCurrency();

      $.each(self.setOpenMarketBidders, function(index, setOpenMarketBidder) {
        modalTable.find("#omeb_checkbox_" + setOpenMarketBidder.external_bidder_id).prop('checked', true);
        var bidFloorField = bidFloorFieldFor(setOpenMarketBidder.external_bidder_id);
        bidFloorField.val(setOpenMarketBidder.openrtb_floor);
        styleBidFloorField(bidFloorField, setOpenMarketBidder.openrtb_floor);
      });

      var relatedTarget = $(e.relatedTarget);
      self.slotNumber = relatedTarget.parents('tr').data('sn');
      self.slotOrder = relatedTarget.parents('tr').data('so');

      setActiveTab($('#OMModalTabs li a.active'));
      createBidDensityChart();
      updateReportData();
      $scope.$apply();
    });

    modal.on('hide.bs.modal', function(e) {
      waterfallHelper.syncDateRange(openMarketModalDateRange(), waterfallTableDateRange(), {triggerChange: true});
    });

    this.slotTitle = function() {
      return waterfallHelper.slotTitle(self);
    };

    this.handleBidderChange = function(selectedExternalBidder) {
      self.selectedExternalBidder = selectedExternalBidder;
      createBidDensityChart();
      updateReportData();
    };

    this.setAutoAddExternalBidders = function(val) {
      if (self.readOnly) {
        return;
      }

      self.autoAddExternalBidders = val;
    };

    this.bidDensityReportLinkWithBidderAccount = function() {
      var reportLink = self.bidDensityReportLink;

      if (self.selectedExternalBidder) {
        reportLink += encodeURI('&bidder_account_ids[]=' + self.selectedExternalBidder);
      }

      return reportLink;
    };

    var updateReportData = function() {
      $rootScope.$broadcast('updateReportData', {
        table: self.tableId,
        filterParams: {
          bidder_account_id: self.selectedExternalBidder
        }
      });
    };

    var externalBidderMatch = function(omeb, id) {
      return parseInt(omeb.external_bidder_id) === parseInt(id);
    };

    var findExternalBidder = function(id) {
      return _.find(self.setOpenMarketBidders, function(omeb) {
        return externalBidderMatch(omeb, id);
      });
    };

    var addExternalBidder = function(externalBidderId) {
      if (findExternalBidder(externalBidderId)) {
        return;
      }
      self.setOpenMarketBidders.push({
        'external_bidder_id': parseInt(externalBidderId),
        'openrtb_floor': bidFloorFieldFor(externalBidderId).val()
      });
    };

    var removeExternalBidder = function(externalBidderId) {
      self.setOpenMarketBidders = _.reject(self.setOpenMarketBidders, function(omeb) {
        return externalBidderMatch(omeb, externalBidderId);
      });
    };

    var setExternalBidders = function() {
      self.externalBidders = [{id: '', name: 'All Bidders'}].concat(
        _.chain(oTable.fnGetData())
          .map(function(k) {
            return {
              id: '' + (k.DT_RowAttr && k.DT_RowAttr['data-bidder-account-id']),
              name: $(k.name).text()
            };
          })
          .sortBy(function(k) {
            return k.name;
          })
          .value()
      );
    };

    var setCurrency = function() {
      modalTable.find('td .input-group .input-group-append .input-group-text').text(self.rateCurrency);
    };

    modalTable.on('init.dt', function() {
      $('<input type="hidden" name="'+ self.dummyName + '">').insertBefore(this);
      setExternalBidders();
      setCurrency();
      if (Array.isArray(self.reportData) && self.reportData.length > 0) {
        refreshReportTableData();
      }
      $scope.$apply();
      $element.find('#omeb_bidder_select').trigger('chosen:updated');
      $rootScope.$broadcast('openMarketModalTableInit', {table: self.tableId});
    });

    modalTable.on('click', 'td .checkbox-custom', function() {
      modalDataTable.draw();
    });

    $element.on('change', "#" + self.tableId +  " tbody input[type='checkbox']", function() {
      var externalBidderId = $(this).val();

      if ($(this).prop('checked') === true) {
        addExternalBidder(externalBidderId);
      }
      else {
        removeExternalBidder(externalBidderId);
      }
      $scope.$apply();
    });

    $element.on('change', "#" + self.tableId +  " tbody input[name*='[openrtb_floor]']", function() {
      var externalBidderId = $(this).data('id');

      var eb = findExternalBidder(externalBidderId);

      if (eb) {
        eb.openrtb_floor = $(this).val();
        $scope.$apply();
      }
    });

    $element.on('focusout', "#" + self.tableId +  " tbody input[name*='[openrtb_floor]']", function() {
      modalDataTable.draw();
    });

    var bidDensityChartTitle = function() {
      var selectedBidder = _.findWhere(self.externalBidders, {id: self.selectedExternalBidder});
      if (selectedBidder) {
         return 'Bid Density for ' + selectedBidder.name;
      }
    };

    var createBidDensityChart = function() {
      $element.find('#bid_density_graph').createBidDensityChart({
        demand_tag_id: self.openMarketDemandTagId,
        supply_tag_id: self.objectId,
        bidder_account_id: self.selectedExternalBidder,
        slot_order: self.slotOrder,
        slot_number: self.slot_number,
        date_range: 'Today'
      }, {
        chart_title: bidDensityChartTitle()
      });
    };

    this.save = function() {
      self.autoAddModel = self.autoAddExternalBidders;
      self.model = angular.copy(self.setOpenMarketBidders);
    };

    var tableColumns = function() {
      return [
        {data: 'checkbox', orderDataType: 'dom-checkbox', class: self.readOnly ? 'hidden-column' : ''},
        {data: 'id'},
        {data: 'name'},
        {data: 'bid_floor', orderDataType: 'dom-input', type: 'numeric'},
        {data: 'popover'},
        {data: 'targeting', class: 'targeting'},
        {data: 'openrtb_bid_requests', class: 'om_report_openrtb_bid_requests', orderDataType: 'range-compare-span', type: 'numeric'},
        {data: 'openrtb_bids', class: 'om_report_openrtb_bids', orderDataType: 'range-compare-span', type: 'numeric'},
        {data: 'openrtb_wins', class: 'om_report_openrtb_wins', orderDataType: 'range-compare-span', type: 'numeric'},
        {data: 'impressions', class: 'om_report_impressions', orderDataType: 'range-compare-span', type: 'numeric'},
        {data: 'openrtb_bid_rate', class: 'om_report_openrtb_bid_rate', orderDataType: 'range-compare-span', type: 'numeric'},
        {data: 'openrtb_bid_fill_rate', class: 'om_report_openrtb_bid_fill_rate', orderDataType: 'range-compare-span', type: 'numeric'},
        {data: 'openrtb_win_rate', class: 'om_report_openrtb_win_rate', orderDataType: 'range-compare-span', type: 'numeric'},
        {data: 'openrtb_win_fill_rate', class: 'om_report_openrtb_win_fill_rate', orderDataType: 'range-compare-span', type: 'numeric'},
        {data: 'revenue', class: 'om_report_revenue', orderDataType: 'range-compare-span', type: 'numeric'},
        {data: 'rpm', class: 'om_report_rpm', orderDataType: 'range-compare-span', type: 'numeric'},
        {data: 'cost', class: 'om_report_cost', orderDataType: 'range-compare-span', type: 'numeric'},
        {data: 'profit', class: 'om_report_profit', orderDataType: 'range-compare-span', type: 'numeric'},
        {data: 'actions', class: 'actions'}
      ];
    };

    var buildTable = function() {
      modalDataTable = modalTable.defaultDataTable({
        paging: false,
        searching: false,
        order: [
          [7, 'desc']
        ],
        orderFixed: [
          [0, 'desc']
        ],
        ajax: {
          url: self.path,
          method: 'POST',
          data: {
            set_open_market_external_bidders: self.setOpenMarketBidders,
            currency: self.currency
          }
        },
        "oLanguage": {
          "sProcessing": "<div class='dt-spin'></div>",
          "sEmptyTable": "No external bidders connected yet."
        },
        columns: tableColumns()
      });

      oTable = modalTable.dataTable();
    };

    var refreshReportTableData = function() {
      $.each(modalTable.find('tr'), function (i, r) {
        var tr = $(r);
        var bidderAccountId = tr.data("bidderAccountId");
        if (bidderAccountId) {
          var row = _getReportRow(bidderAccountId);
          _refreshRowData(tr, row);
        }
      });
      platformUtilities.triggerDataTablesProcessing(modalTable, false);
      modalDataTable.draw();
    };

    var _getReportRow = function(bidderAccountId) {
      if (!self.reportData) {
        return {};
      }
      for (var i = 0; i < self.reportData.length; i++) {
        var row = self.reportData[i];
        if (row.bidder_account_id && row.bidder_account_id.toString() === bidderAccountId.toString()) {
          return row;
        }
      }
      return {};
    };

    var refreshCell = function(tr, className, value) {
      modalDataTable.cell(tr.find('td.' + className)).data(value);
    };

    var _refreshRowData = function(tr, row) {
      if (modalDataTable.row(tr).length > 0) {
        var currencyPlaceholder = self.currencyPlaceholder || '$0.00';

        refreshCell(tr, 'om_report_openrtb_bid_requests', row.openrtb_bid_requests || 0);
        refreshCell(tr, 'om_report_openrtb_bids', row.openrtb_bids || 0);
        refreshCell(tr, 'om_report_openrtb_wins', row.openrtb_wins || 0);
        refreshCell(tr, 'om_report_impressions', row.impressions || 0);
        refreshCell(tr, 'om_report_openrtb_bid_rate', row.openrtb_bid_rate || '0.00%');
        refreshCell(tr, 'om_report_openrtb_bid_fill_rate', row.openrtb_bid_fill_rate || '0.00%');
        refreshCell(tr, 'om_report_openrtb_win_rate', row.openrtb_win_rate || '0.00%');
        refreshCell(tr, 'om_report_openrtb_win_fill_rate', row.openrtb_win_fill_rate || '0.00%');
        refreshCell(tr, 'om_report_revenue', row.revenue || currencyPlaceholder);
        refreshCell(tr, 'om_report_rpm', row.rpm || currencyPlaceholder);
        refreshCell(tr, 'om_report_cost', row.cost || currencyPlaceholder);
        refreshCell(tr, 'om_report_profit', row.profit || currencyPlaceholder);
      }
    };

    $scope.$on('updatingReportData', function(e, args) {
      $.when( onInitLoad ).done(function() {
        if (args.table === self.tableId) {
          platformUtilities.triggerDataTablesProcessing(modalTable, true);
        }
      });
    });

    $scope.$on('updatedReportData', function(e, args) {
      $.when( onInitLoad ).done(function() {
        if (args.table === self.tableId) {
          self.reportData = args.resp.external_bidder_report;
          self.currencyPlaceholder = args.resp.currency_placeholder;
          refreshReportTableData();
        }
      });
    });

    $element.on('shown.bs.tab', 'a[data-toggle="tab"][href="#tabBidDensity"]', function() {
      var highchart = $element.find('#bid_density_graph').highcharts();
      if(highchart) highchart.reflow();
    });

    $element.on('shown.bs.tab', '#OMModalTabs a[data-toggle="tab"]', function (e) {
      setActiveTab($(this));
    });

    $rootScope.$on('reportCurrencyChange', function(e, args) {
      self.currency = args.currency;
    });

    var setActiveTab = function(activeAnchor) {
      self.activeTab = activeAnchor.attr('href');
      $scope.$apply();
    };

    var resetBidders = function() {
      self.setOpenMarketBidders = angular.copy(self.model);
      self.autoAddExternalBidders = self.autoAddModel;
    };

    var init = function() {
      resetBidders();
      buildTable();
    };

    this.$onInit = function() {
      setTimeout(function() {
        init();
        onInitLoad.resolve();
      }, 0);
    };

  }]
});

angular.module('vastdesk').
  component('watermarkFields',  {
    bindings: {
      defaultExternalPublisherId: '@',
      demandTagsCount: '<'
    },
    controller: ['$scope', function($scope) {

      var self = this;
      var bodySelector = $('body');

      bodySelector.on('click', '#add_watermark', function() {
        if ($(this).attr('disabled')) {
          return false;
        }
      });

      bodySelector.on('cocoon:after-insert', '#watermark', function(e, addedWatermark) {
        if (!addedWatermark.hasClass('watermark')) {
          return;
        }

        toggleAddWatermarkVisibility();
        setDefaultExternalPublisherId(this);

        setTimeout(function() {
          $('.nested-fields.watermark:not([style*="display: none"]) #add_watermark_pixel:not([style*="display: none"])').click();
        }, 0);
      });

      bodySelector.on('cocoon:after-remove', '#watermark', function(e, watermark) {
        if (!watermark.hasClass('watermark')) {
          return;
        }

        toggleAddWatermarkVisibility();
        $('.watermark-pixels .remove_fields').click();
      });

      bodySelector.on('cocoon:after-insert', '#watermark_pixels', function(e, addedWatermarkPixel) {
        $(addedWatermarkPixel).find('select').defaultChosen();
      });

      bodySelector.on('click', '.watermark .fa-pencil', function() {
        $(this).addClass('hidden');
        $('.watermark input[name*="external_id"]').removeAttr('readonly');
        $('.wm-external-id-wrapper').removeClass('event-value').addClass('event-full-input');
      });

      bodySelector.on('change', 'input[name="creative[watermark_attributes][generate_external_id]"]', function() {
        $('.wm-external-id-wrapper').toggleClass('hidden', $(this).val() === 'true');
      });

      var toggleAddWatermarkVisibility = function() {
        var hasWatermark = $('.nested-fields.watermark:not([style*="display: none"])').length > 0;

        $('#add_watermark').toggleClass('hidden', hasWatermark);
        $('#add_watermark').toggleClass('add_fields', !hasWatermark);
        $('#videoTabs .line_items').parents('li').toggleClass('hidden', hasWatermark);
      };

      var setDefaultExternalPublisherId = function(add_form) {
        $(add_form).find('input[name*="[external_publisher_id]"]').val(self.defaultExternalPublisherId).attr('placeholder', self.defaultExternalPublisherId);
      };

      $scope.$on('lineItemDemandTagsCountChange', function(e, args) {
        var watermarkButton = $('#add_watermark');

        if (self.demandTagsCount > 1 || args.lineItemDemandTagsCount > 0) {
          watermarkButton.attr('disabled', 'disabled');
          watermarkButton.attr('data-original-title', watermarkButton.data('disabledTitle'));
        }
        else {
          watermarkButton.removeAttr('disabled');
          watermarkButton.attr('data-original-title', '');
        }
      });

      setTimeout(function() {
        toggleAddWatermarkVisibility();
      }, 0);
    }]
});

angular.module('vastdesk').
  component('accountMonthlyMinimum',  {
    bindings: {
      monthlyMinimum: '<',
      minimumCommencementDate: '@',
      readOnly: '<'
    },
    template: require("../templates/accountMonthlyMinimum.html"),
    controllerAs: '$accountMonthlyMinimumCtrl',
    controller: function() {

    }
});

import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('suggestedMacros',  {
    bindings: {
      formName: '@',
      formId: '@',
      environment: '@',
      adServerOptions: '<',
      showWikiLink: '<',
      includeAll: '<'
    },
    template: require("../templates/suggestedMacros.html"),
    controllerAs: '$suggestedMacrosCtrl',
    controller: ['$scope', '$rootScope', function($scope, $rootScope) {

      var self = this;
      var bodySelector = $('body');

      this.replaceMacros = false;

      this.replaceMacroOptions = [
        {value: false, label: 'As Is'},
        {value: true, label: 'Replace Macros'},
      ];

      this.detectSuggestedMacros = _.throttle(function () {
        var params = {
          vast_endpoint_url: $('.vast-endpoint-url').val(),
          ad_server_id: self.adServerId,
          environment: self.environment,
          include_all: self.includeAll
        };

        $.post('/demand/macro_suggester', params, function(response) {
          self.suggestedMacroEndpointUrl = response.vast_endpoint_url;

          if (!self.adServerId || self.adServerId === '') {
            self.adServerId = response.ad_server_id ? response.ad_server_id.toString() : '';
          }

          $scope.$apply();

          setTimeout(function() {
            $('#adServers').trigger('chosen:updated');
          }, 0);
        });
      }, 300, {leading: false});

      $rootScope.$on('environmentChange', function(e, args) {
        if (args.environment !== self.environment) {
          self.environment = args.environment;
          self.detectSuggestedMacros();
        }
      });

      bodySelector.on('keyup', '.vast-endpoint-url', function() {
        self.detectSuggestedMacros();
      });

      this.runValidation = function() {
        var url = (self.replaceMacros) ? $('#custom-vast-url').val() : self.suggestedMacroEndpointUrl;
        if(!url) { return; }
        url = url.replace('http://', 'https://');

        $.get({
          url: url,
          datatype: 'json'
        }).done(function(resp) {
          self.showValidationResponse = true;
          self.validationResponse = ($(resp).find('Ad').length) ? 'valid' : 'empty';
          $scope.$apply();
        }).fail(function(resp) {
          self.showValidationResponse = true;
          self.validationResponse = 'error';
          $scope.$apply();
        });
      };

      this.resetValidation = function() {
        self.showValidationResponse = false;
        self.validationResponse = null;
      };

      this.$onInit = function() {
        self.detectSuggestedMacros();
      };

    }]
});

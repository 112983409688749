import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('postalCodeInput',  {
    bindings: {
      formName: '@',
      formId: '@',
      selectedOptions: '<'
    },
    template: require("../templates/postalCodeInput.html"),
    controllerAs: '$postalCodeInputCtrl'
});

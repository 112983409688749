import HighchartsBuilder from '../../modules/highcharts_builder.js';

angular.module('vastdesk')
.component('realTimeChart', {
  bindings: {
    chartId: '@',
    pod: '='
  },
  template: '<div class="graphChart realTimeChart"><div></div></div>',
  controller: ['$scope', '$element', function($scope, $element) {

    var self = this;
    var theme = window.platformTheme;
    var onInitLoad = $.Deferred();
    var highchartsBuilder = new HighchartsBuilder();
    var colors = highchartsBuilder.chartColors;

    var constructAdditionalSeries = function(chart, name) {
      chart.highcharts().addSeries({
        name: name,
        type: 'column',
        yAxis: 1,
        zIndex: 1,
        formatType: 'percent',
        color: theme.quaternaryColor,
        shadow: false,
        data: []
      });
    };

    var chartDiv = $element.find('div');

    var setChart = function() {
      chartDiv.highcharts({
        chart: {
          defaultSeriesType: 'spline',
          zooming: {
            type: 'x'
          },
          backgroundColor: null
        },
        title: {
          text: ''
        },
        credits: {enabled: false},
        colors: colors,
        xAxis: [{
          type: 'datetime',
          crosshair: true,
          tickLength: 0,
          lineColor: '#ccd6eb',
          tickPixelInterval: 150,
          maxZoom: 20 * 1000,
          labels: {
            reserveSpace: true,
            y: 10,
            style: {
              color: '#acacac',
              fontSize: '9px'
            },
            formatter: function() {
              return moment(this.value).format('h:mm:ss a');
            }
          }
        }],
        yAxis: [
          {//secondary
          title: {text: ''},
          labels: {enabled:false},
          gridLineColor: '#d7d7d7'
          },
          {//primary
            gridLineWidth: 0,
            title: {text: ''},
            labels: {enabled: false}
          },
          {//tertiary
            gridLineWidth: 0,
            title: {text: ''},
            labels: {enabled: false},
            opposite: true
          }
        ],
        tooltip: {
          formatter: function() {
            return highchartsBuilder.tooltipFormatter(this, true);
          },
          shared: true,
          backgroundColor: '#FFFFFF',
          borderColor: '#484848',
          style: {
            fontSize: '10px',
            lineHeight: '14px',
            opacity: '0.95'
          }
        },
        legend: highchartsBuilder.defaultLegend(),
        plotOptions: {
          series: {
            marker: {
              enabled: false
            },
            states: {
              hover: {
                enabled: false
              }
            },
            shadow: false
          },
          column: {
            grouping: false,
            shadow: false,
            borderWidth: 0
          }
        },
        series: [{
          name: 'Request Fill',
          type: 'column',
          shadow: false,
          yAxis: 1,
          formatType: 'percent',
          color: colors[0],
          zIndex: 1,
          data: []
        }, {
          name: 'Requests',
          type: 'spline',
          yAxis: 0,
          formatType: 'number',
          color: colors[1],
          shadow: highchartsBuilder.defaultShadow(),
          lineWidth: 2,
          zIndex: 2,
          data: []
        }, {
          name: 'Impressions',
          type: 'spline',
          yAxis: 2,
          formatType: 'number',
          color: colors[2],
          shadow: highchartsBuilder.defaultShadow(),
          lineWidth: 2,
          zIndex: 3,
          data: []
        }]
      });

      if (self.pod) {
        constructAdditionalSeries(chartDiv, 'Pod Time Req Fill %');
      }
    };

    var addPointToSeries = function(timePoint, point, series, shiftIt) {
      series.addPoint([timePoint, point ], true, shiftIt);
    };

    $scope.$on('refreshHighchart', function(e, args) {
      $.when( onInitLoad ).done(function() {
        if (args.id === self.chartId) {
          setTimeout(function() {
            if (args.data) {
              var chart = chartDiv.highcharts();
              var timePoint = moment().unix() * 1000;
              // only keep last 50 data points
              if (chart && chart.series) {
                var shiftIt = chart.series[0].data.length > 50;
                addPointToSeries(timePoint, args.data.fill_rate, chart.series[0], shiftIt);
                addPointToSeries(timePoint, args.data.requests, chart.series[1], shiftIt);
                addPointToSeries(timePoint, args.data.impressions, chart.series[2], shiftIt);

                if (self.pod) {
                  addPointToSeries(timePoint, args.data.ad_pod_fill_rate, chart.series[3], shiftIt);
                }
              }
            }
          }, 0);
        }
      });
    });

    $scope.$on('reflowHighcharts', function(e, args) {
      highchartsBuilder.deferredReflowChart(onInitLoad, chartDiv);
    });

    this.$onInit = function() {
      setChart();
      onInitLoad.resolve();
    };

  }]
});

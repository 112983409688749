angular.module('vastdesk').
  component('frequencyCaps', {
    bindings: {},
    controller: ['$element', function($element) {

      var bodySelector = $('body');
      var frequencyCapsElement = $element.parents('#frequency_caps');

      var toggleTypeFields = function(add_form) {
        setTimeout(function() {
          var frequencyCapType = add_form.find('.frequency-cap-type input:checked').val();

          add_form.find('.fcap_metric').toggleClass('hidden', frequencyCapType !== 'springserve');
          add_form.find('.fcap_third_party').toggleClass('hidden', frequencyCapType !== 'global');
          resetPeriods(add_form);

          if (frequencyCapType === 'global') {
            var globalFcapSelect = add_form.find('.global-fcap-selector');

            if ( globalFcapSelected(globalFcapSelect.val()) ) {
              var selectedOption = globalFcapSelect.find('option:selected');
              disableInvalidPeriods(add_form, selectedOption);
            }
          }

          if (frequencyCapType !== 'global') {
            if (add_form.find('.fcap_metric .btn-group label.active').length < 1) {
              add_form.find('.fcap_metric .btn-group label[for*=frequency_cap_metric_impressions]').click();
            }
          }
        }, 0);
      };

      bodySelector.on('cocoon:after-insert', '#frequency_caps', function() {
        frequencyCapsElement.find('.chosen-select').defaultChosen();
      });

      frequencyCapsElement.on('click', '.frequency-cap-type', function() {
        var add_form = $(this).closest('.add_form');
        toggleTypeFields(add_form);
      });

      var resetPeriods = function(add_form) {
        add_form.find('.fcap_periods .btn-group label').attr('disabled', false);
      };

      var disableInvalidPeriods = function(add_form, selectedOption) {
        add_form.find('.fcap_periods .btn-group label').filter(function() {
          return $(this).data('periodMinutes') > selectedOption.data('periodMinutes');
        }).attr('disabled', true);
      };

      var globalFcapSelected = function(globalFcapId) {
        return globalFcapId && globalFcapId !== '';
      };

      frequencyCapsElement.on('change', '.global-fcap-selector', function() {
        var add_form = $(this).closest('.add_form');
        resetPeriods(add_form);
        var globalFcapId = $(this).val();
        
        if (globalFcapSelected(globalFcapId)) {
          var selectedOption = $(this).find('option:selected');
          var selectedPeriod = selectedOption.data('fcapPeriod');
          add_form.find('.fcap_periods .btn-group label[for*=frequency_cap_period_' + selectedPeriod + ']').click();
          disableInvalidPeriods(add_form, selectedOption);
          add_form.find('input[id*=frequency_cap_period_amount]').val(selectedOption.data('fcapPeriodAmount'));            
        }
      });
    }]
});

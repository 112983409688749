import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('optimizationDefaults',  {
    bindings: {
      formName: '@',
      formId: '@',
      first: '<',
      optimizationCustomDefaults: '@',
      optimizationCustomMappings: '<',
      optimizationActiveDefault: '<',
      includeOptimizationModeDefault: '<',
      optimizationModeDefault: '@',
      platformOptimizationTypeTooltip: '@',
      customOptimizationTypeTooltip: '@',
      optimizationSettingsDefault: '<',
      showWikiLink: '<',
      optimizationMetricOptions: '<',
      minimumLookbackMinutesOptions: '<',
      podSettingDefault: '@',
      supplyEnvironmentDefault: '@'
    },
    template: require("../templates/optimizationDefaults.html"),
    controllerAs: '$optimizationDefaultsCtrl',
    controller: ['$rootScope', function($rootScope) {
      var self = this;

      var POD_OPTI_TIME_FILL = 'pod_time_fill';
      var POD_OPTI_REV_PER_POD = 'revenue_per_pod';

      this.attributeEnabledMappings = componentHelper.attributeEnabledMappings();
      this.optimizationModeOptions = componentHelper.optimizationModeOptions();

      this.$onInit = function() {
        setTimeout(function () {
          self.setPodOptiMetricVisibility();
        }, 0);
      };

      this.optimizationModeDefaultTooltipFor = function(optimizationModeValue) {
        if (optimizationModeValue === 'black_box') {
          return self.platformOptimizationTypeTooltip;
        }
        else {
          return self.customOptimizationTypeTooltip;
        }
      };

      this.optimizationModeDefaultActive = function(optimizationModeValue) {
        if (optimizationModeValue === 'white_box') {
          return self.optimizationModeDefault === 'white_box';
        }
        else {
          return self.optimizationModeDefault !== 'white_box';
        }
      };

      this.setPodOptiMetricVisibility = function() {
        var optiMetrics = $('#' + self.formId + '_optimization_settings_default_metric');

        if (self.podSettingDefault !== 'none' && self.supplyEnvironmentDefault === 'ctv') {
          optiMetrics.find('option').attr('disabled', false).removeClass('hidden');
        }
        else {
          var metric = self.optimizationSettingsDefault.metric;

          if (metric == POD_OPTI_REV_PER_POD) {
            self.optimizationSettingsDefault.metric = 'rpm';
          } else if (metric == POD_OPTI_TIME_FILL) {
            self.optimizationSettingsDefault.metric = 'fill_rate';
          }

          optiMetrics.find('option').filter(function() {
            var optionValue = $(this).val();
            return optionValue === '' || _.contains([POD_OPTI_TIME_FILL, POD_OPTI_REV_PER_POD], optionValue);
          }).attr('disabled', true).addClass('hidden');
        }

        setTimeout(function () {
          optiMetrics.trigger('chosen:updated');
        }, 0);
      };

      $rootScope.$on('podSettingDefaultChange', function(e, podSetting) {
        self.podSettingDefault = podSetting;
        self.setPodOptiMetricVisibility();
      });

      $rootScope.$on('supplyEnvironmentDefaultChange', function(e, args) {
        self.supplyEnvironmentDefault = args.environmentDefault;
        self.setPodOptiMetricVisibility();
      });
    }]
});

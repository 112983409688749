import HighchartsBuilder from '../../modules/highcharts_builder.js';

angular.module('vastdesk')
.component('xyScatterChart', {
  bindings: {
    chartId: '@',
    chartData: '=',
    playerSize: '@'
  },
  template: '<div class="graphChart xyScatterChart"><div></div></div>',
  controller: ['$scope', '$element', function($scope, $element) {

    var highchartsBuilder = new HighchartsBuilder();
    var chartDiv = $element.find('div');

    var self = this;
    var onInitLoad = $.Deferred();

    var scatterZones = function() {
      if (self.playerSize !== 'true') {
        return [];
      }

      return highchartsBuilder.playerSizeZones(true);
    };

    var setChart = function() {
      chartDiv.highcharts({
        chart: {
          type: 'scatter',
          zooming: {
            type: 'xy'
          },
          backgroundColor: null,
          animation: false
        },
        title: {
          text: ''
        },
        subtitle: {
          text: ''
        },
        xAxis: {
          title: {
              text: ''
          },
          lineColor: '#ccd6eb'
        },
        yAxis: {
          title: {
              text: ''
          }
        },
        legend: {
          enabled: false
        },
        credits: {enabled: false},
        tooltip: {
          formatter: function() {
            var header = '';
            if (self.playerSize === 'true') {
              var playerSizeName = highchartsBuilder.playerSizeNameFor(this.x);

              if (playerSizeName !== '') {
                header = '<div style="font-size: 10px;">' + playerSizeName + '</div><br/>';
              }
            }
            return header + '<b>' + this.x + 'x' + this.y + '</b>';
          },
          style: {
            opacity: '0.95'
          }
        },
        plotOptions: {
          scatter: {
            marker: {
              radius: 5,
              states: {
                hover: {
                  enabled: true,
                  lineColor: 'rgb(8,145,145)'
                }
              }
            },
            states: {
              hover: {
                marker: {
                  enabled: false
                }
              }
            },
            zoneAxis: 'x',
            zones: scatterZones()
          }
        },
        series: [{
          name: 'Data',
          color: 'rgba(64,193,209, .5)',
          data: self.chartData
        }]
      });
    };

    $scope.$on('refreshHighchart', function(e, args) {
      $.when( onInitLoad ).done(function() {
        if (args.id === self.chartId) {
          setTimeout(function() {
            var chart = chartDiv.highcharts();

            if (chart && chart.series) {
              chart.series[0].setData(self.chartData);
            }
          }, 0);
        }
      });
    });

    $scope.$on('reflowHighcharts', function(e, args) {
      highchartsBuilder.deferredReflowChart(onInitLoad, chartDiv);
    });

    this.$onInit = function() {
      setChart();
      onInitLoad.resolve();
    };

  }]
});
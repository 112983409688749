import HighchartsBuilder from '../modules/highcharts_builder.js';

$.fn.toggleResponseTimesVisibility = function() {
  var that = this;
  var responseTimesPath = $(that).attr('data-path');

  $.post(responseTimesPath, function(resp) {
    $(that).toggleClass('hidden', !resp.has_data);
  });
};

$.fn.createResponseTimesChart = function(options = {}) {
  var that = this;
  var responseTimesPath = $(that).attr('data-path');
  var highchartsBuilder = new HighchartsBuilder();

  $.post(responseTimesPath, function(resp) {
    $(options.target || '.popover-chart-container').highcharts({
      colors: highchartsBuilder.chartColors,
      chart: {
        type: 'column',
        renderTo: 'chartContainer'
      },
      title: {
        text: resp.chart_title
      },
      subtitle: {
        text: resp.date_range
      },
      xAxis: {
        title: {
          text: resp.x_axis_label
        },
        lineColor: '#ccd6eb',
        categories: resp.categories
      },
      yAxis: {
        min: 0,
        title: {
          text: resp.y_axis_label
        },
        labels: {
          formatter:function() {
            return Highcharts.numberFormat(this.value,0,',') + '%';
          }
        }
      },
      plotOptions: {
        series: {
          groupPadding: 0.02
        }
      },
      tooltip: {
        shared: true,
        formatter: function() {
          var sText = '<b>' + this.x + ' ' + resp.x_axis_label + '</b><br/>';
          var sArray = [];
          $.each(this.points, function(i, point) {
            var pointData = Highcharts.numberFormat(this.point.metric,0,'.',',') + ' (' + Highcharts.numberFormat(point.y,2,'.') + '%)';

            sArray.push(
              '<span style="color:' + point.color + ';font-weight:bold;">' + point.series.name + ': </span>' +
              pointData + '<br/>'
            );
          });
          return sText + sArray.join('');
        },
        style: {
          opacity: '0.95'
        }
      },
      credits: {
        enabled: false
      },
      legend: _.extend({}, highchartsBuilder.defaultLegend(), {
        enabled: !!resp.show_legend
      }),
      series: resp.series
    });
  });
};

$.fn.createBidDensityChart = function(params, options = {}) {
  if (!params || !$.isPlainObject(params)) {
    return;
  }

  var that = this;
  var theme = window.platformTheme;
  var highchartsBuilder = new HighchartsBuilder();
  var bidsBelowFloorColor = 'rgba(250,146,0,0.8)';
  var validBidsColor = theme.primaryColor;

  $(that).parent().find('.refreshing-panel-bid-density').removeClass('hidden');

  var path = options.path || '/supply/bid_density_report';

  $.get(path, params, function(response) {
    if (!response.bid_density_report) {
      return;
    }

    var avgBidFloor = response.bid_density_report.avg_bid_floor || 0;

    var series1Data = _.pluck(response.bid_density_report.data, 'bid_below_floor');
    var series2Data = _.pluck(response.bid_density_report.data, 'valid_bids');

    var avgBidFloorPlotLine = response.bid_density_report.avg_bid_floor_plot_line;

    var maxY = _.max(series1Data.concat(series2Data));

    $(that).highcharts({
      chart: {
        type: 'column',
        animation: false,
        plotBorderWidth: null,
        backgroundColor: null,
        zooming: {
          type: 'xy'
        }
      },
      title: {
        text: (options.chart_title === undefined) ? 'Bid Density' : options.chart_title
      },
      subtitle: {
        text: 'Today UTC'
      },
      legend: _.extend({}, highchartsBuilder.defaultLegend(), {
        reversed: true
      }),
      credits: {
        enabled: false
      },
      xAxis: {
        categories: _.pluck(response.bid_density_report.data, 'bid_bucket'),
        type: 'category',
        tickLength: 0,
        lineColor: '#ccd6eb',
        labels: {
          formatter: function() {
            return highchartsBuilder.yAxisFormatter('money', this);
          }
        },
        plotLines: [{
          color: '#6a3074',
          value: avgBidFloorPlotLine,
          width: 2,
          zIndex: 4,
          label: {
            text: '$' + Highcharts.numberFormat(avgBidFloor, 2, '.', ',') + ' Average Bid Floor',
            textAlign: 'left',
            rotation: 0,
            y: 16,
            style: {
              color: '#666666'
            }
          }
        }],
      },
      yAxis: [{
        title: {text: ''},
        labels: {
          reserveSpace: true,
          style: {
            fontSize: '9px',
            fontWeight: 'bold',
            color: bidsBelowFloorColor
          }
        },
        min: 0,
        max: maxY,
        gridLineColor: '#d7d7d7'
      }, {
        title: {text: ''},
        labels: {
          reserveSpace: true,
          style: {
            fontSize: '9px',
            fontWeight: 'bold',
            color: validBidsColor
          }
        },
        opposite: true,
        gridLineWidth: 0,
        min: 0,
        max: maxY
      }],
      tooltip: {
        formatter: function() {
          var plusText = /\+/.test(this.x || '') ? '+' : '';
          var xValue = (this.x || '').replace(/[^0-9.]/g, '');
          var sText = '<b>$' + Highcharts.numberFormat(xValue, 2, '.', ',') + plusText + '</b><br/>';
          var sArray = [];
          for (var i = 0; i < this.points.length; i++) {
            sArray.push('<span style="color:' + this.points[i].color + ';font-weight:bold;">' + this.points[i].series.name + ': </span>' +
                        Highcharts.numberFormat(this.points[i].y, 0, '.', ',') + '<br/>');
          }
          return sText + sArray.join('');
        },
        shared: true,
        backgroundColor: '#FFFFFF',
        borderColor: '#000000',
        style: {
          opacity: '0.95'
        }
      },
      colors: [validBidsColor, bidsBelowFloorColor],
      plotOptions: {
        column: {
          borderWidth: 0,
          maxPointWidth: 50,
          shadow: false,
          grouping: false,
          animation: false
        }
      },
      series: [{
        name: 'Valid Bids',
        yAxis: 1,
        data: series2Data
       }, {
        name: 'Bids Below Floor',
        data: series1Data
      }]
    }, function() {
      setTimeout(function() {
        $(that).parent().find('.refreshing-panel-bid-density').addClass('hidden');
      }, 0);
    });

  });

};

angular.module('vastdesk').
  component('countryPrefixerModal',  {
    bindings: {
      formName: '@',
      formId: '@',
      target: '@',
      countryCode: '<'
    },
    template: require("../templates/countryPrefixerModal.html"),
    controllerAs: '$countryPrefixerModalCtrl',
    controller: function() {
      const prefixPostalCodes = ($select, postalCodes, countryCode) => {
        const prefixedPostalCodes = postalCodes.map((item) => {
          if (item.indexOf(':') == -1) {
            // add country prefixes
            return `${countryCode}:${item}`;
          } else {
            // replace country prefixes
            return item.replace(/^(.+?):(.+?)$/, `${countryCode}:$2`);
          }
        });
        // replace options in target select (chosen.js only)
        $select.empty();
        $.each(prefixedPostalCodes, (key, value) => {
          $select
            .append($('<option></option>')
            .attr('value', value)
            .prop('selected', true)
            .text(value));
        });
        $select.trigger('chosen:updated');
      };

      $('body').on('click', '#country_prefixer_modal .btn-ss-submit', () => {
        const $targetSelector = $(`#${this.target}`);
        const postalCodes = $targetSelector.val();
        if (postalCodes.length > 0) {
          prefixPostalCodes($targetSelector, postalCodes, this.countryCode);
        }
        // hide modal window
        $('#country_prefixer_modal').modal('hide');
      });
    }
});

import SwalHelper from '../../modules/swal_helper.js';

angular.module('vastdesk').
  component('creativeUpload',  {
    bindings: {
      formName: '@',
      formId: '@',
      format: '@',
      demandPartnerId: '@',
      creativeId: '@',
      creativeFormUrl: '@',
      creativeAction: '@',
      creativeRemoteUrl: '@',
      creativeFileName: '@',
      videoTooltip: '@',
      audioTooltip: '@',
      tileTooltip: '@',
      creativeTypeOptions: '@'
    },
    template: require("../templates/creativeUpload.html"),
    controllerAs: '$creativeUploadCtrl',
    controller: ['$scope', '$rootScope', function($scope, $rootScope) {

      var self = this;
      var selectedCreativeId;
      var selectedCreativeName;
      var selectedCreativeFormUrl;
      var creativeIdField = '#demand_tag_creative_id';
      var creativeFileUploadField = '#creative_creative';
      var bodySelector = $('body');
      var progressBarSelector = $('#progress_bar');
      var savingStatusSelector = $('.creative-saving-status');
      var errorMessageSelector = $('.error-message');
      var creativeModal = $('#creative_modal');
      var creativeModalTable = creativeModal.find('table');
      var creativeModalTableLoaded = $.Deferred();

      this.uploadingCreative = false;

      this.creativeOptions = [
        {value: 'file', label: 'File'},
        {value: 'url', label: 'URL'},
        {value: 'custom', label: 'Custom'},
        {value: 'existing', label: 'Existing'}
      ];

      this.hideCreativeOption = function(value) {
        return (value === 'custom' && (self.format !== 'tile' || self.formId !== 'demand_tag'));
      };

      this.fileLabel = function() {
        var fLabel = 'MP4 Video';

        if (self.format === 'audio') {
          fLabel = 'MP3 Audio';
        }
        else if(self.format === 'tile') {
          fLabel = 'Image';
        }

        return fLabel;
      };

      this.setCreativeAction = function(newVal) {
        if(!self.uploadingCreative && self.creativeAction !== newVal) {
          self.creativeAction = newVal;
          resetProgress();
        }
      };

      this.openCreativeFileDialog = function() {
        if(!self.uploadingCreative) {
          $(creativeFileUploadField).click();
        }
      };

      this.tooltipForFormat = function() {
        var ttip = self.videoTooltip;

        if (self.format === 'audio') {
          ttip = self.audioTooltip;
        }
        else if (self.format === 'tile') {
          ttip = self.tileTooltip;
        }
      };

      this.creativeOptionTooltip = function(val) {
        if (val === 'url') {
          return self.tooltipForFormat();
        }
      };

      var updateProgressBar = function(hidden, percentage) {
        progressBarSelector.toggleClass('hidden', hidden).css('width', percentage + '%');
      };

      var updateStatusMessage = function(selector, hidden, text) {
        selector.toggleClass('hidden', hidden);

        if (text) {
          selector.text(text);
        }
      };

      var resetProgress = function() {
        updateProgressBar(true, 0);
        updateStatusMessage(savingStatusSelector, true, '');
        updateStatusMessage(errorMessageSelector, true, '');
      };

      var setProgress = function(progress, isFileUpload) {
        var progressText = isFileUpload ? 'Uploading...' : 'Processing...';
        self.uploadingCreative = true;
        updateProgressBar(false, progress);
        updateStatusMessage(savingStatusSelector, false, progressText);
      };

      var handleSuccess = function(data, isFileUpload) {
        var successText = isFileUpload ? 'Uploaded' : 'Processed';
        $(creativeIdField).val(data.id);
        self.creativeId = data.id;
        self.creativeFileName = data.name;
        updateStatusMessage(savingStatusSelector, false, successText);
        updateStatusMessage(errorMessageSelector, true, '');
        showProgressBarMessage();
        updateProgressBar(false, 100);
        self.uploadingCreative = false;
        self.creativeAction = 'existing';
        clearCreativeParams();
        $scope.$apply();
      };

      var errorMessage = function(data) {
        var msg = 'Error';
        if (data && data.error) {
          msg = data.error;
        }
        return msg;
      };

      var handleError = function(data) {
        var msg = errorMessage(data);
        updateStatusMessage(savingStatusSelector, true, '');
        updateStatusMessage(errorMessageSelector, false, msg);
        showProgressBarMessage();
        self.uploadingCreative = false;
        $scope.$apply();
      };

      // Upload from URL or Custom
      this.uploadCreative = function(params) {
        if(!self.uploadingCreative) {
          self.uploadingCreative = true;

          resetProgress();
          setProgress(100, false);
          var path = fileUploadUrl() + '.json';
          var uploadCreativeParams = _.extend({demand_partner_id: self.demandPartnerId}, params);

          $.post(path, {creative: uploadCreativeParams}, function(resp) {
            handleSuccess(resp, false);
          })
          .fail(function(jqXHR, textStatus, errorThrown) {
            handleError(jqXHR.responseJSON);
          });
        }
      };

      var fileUploadUrl = function() {
        if(self.format === 'audio') {
          return '/audios';
        } else if (self.format === 'tile') {
          return '/tiles';
        } else {
          return '/videos';
        }
      };

      var clearCreativeParams = function() {
        self.creativeRemoteUrl = null;
        self.width = null;
        self.height = null;
        self.customCode = null;
        self.customName = null;
      };

      var clearCreative = function() {
        self.creativeFileName = null;
        self.creativeId = null;
        clearCreativeParams();
      };

      // Upload from File
      $(creativeFileUploadField).fileupload({
        acceptFileTypes: /(\.|\/)(mp4|mp3|m4a|jpg|png|gif|jpeg)$/i,
        maxFileSize: 500000000,
        dataType: 'json',
        progressall: function (e, data) {
          var progress = parseInt(data.loaded / data.total * 100, 10);
          setProgress(progress, true);
          $scope.$apply();
        },
        done: function (e, data) {
          handleSuccess(data.result, true);
          $scope.$apply();
        },
        error: function(e, data) {
          handleError(e.responseJSON);
          $scope.$apply();
        }
      }).on('fileuploadadd', function (e, data) {
        data.url = fileUploadUrl();
        data.formData = {
          'creative[demand_partner_id]': self.demandPartnerId
        };
      }).on('fileuploadprocessalways', function (e, data) {
        var currentFile = data.files[data.index];
        updateStatusMessage(savingStatusSelector, true);
        if (data.files.error && currentFile.error) {
          updateStatusMessage(errorMessageSelector, false, currentFile.error);
          showProgressBarMessage();
        } else {
          updateStatusMessage(errorMessageSelector, true, '');
          hideProgressBarMessage();
        }
      });

      var hideProgressBarMessage = function() {
        $('#progess_bar_message').removeClass('hidden');
      };

      var showProgressBarMessage = function() {
        $('#progess_bar_message').removeClass('hidden');
      };

      $(creativeIdField).parents('form').submit(function() {
        if(self.uploadingCreative) {
          SwalHelper.alertWarning('Please wait for the creative upload to finish to save.');
          return false;
        }
      });

      var setCreativeSelectDisabled = function() {
        $('#creative_select').attr('disabled', !selectedCreativeId);
      };

      creativeModal.on('show.bs.modal', function() {
        selectedCreativeId = self.creativeId;
        selectedCreativeName = self.creativeFileName;
        selectedCreativeFormUrl = self.selectedCreativeFormUrl;
        setCreativeSelectDisabled();
        creativeModalTable.show();

        $.when( creativeModalTableLoaded ).done(function() {
          var datatable = creativeModalTable.DataTable();
          datatable.rows().nodes().page.len(creativeModalTable.data("pageLength") || 50).search('').draw();

          // this ugly code updates the creative modal's creative type filter to match the currently selected demand tag format
          var creativeTypeColumn = $("#creativeTypeColumn");
          var filterOptions = creativeTypeFilterOptions();
          if (filterOptions.length <= 1) {
            creativeTypeColumn.removeClass("show-hidden-filter");
          } else if (!creativeTypeColumn.hasClass("show-hidden-filter")) {
            creativeTypeColumn.addClass("show-hidden-filter");
          }
          creativeTypeColumn.data("filterOptions", filterOptions);
          $("#creativeModalTable").trigger("reloadDataTableFilters");
          $("#dfilter_creative_type").val("").trigger("chosen:updated").change();

          //hack for datatable column bug
          //https://datatables.net/forums/discussion/24424/column-header-element-is-not-sized-correctly-when-scrolly-is-set-in-the-table-setup
          setTimeout( function () {
            datatable.draw();
          }, 150 );
        });
      });

      var creativeTypeFilterOptions = function () {
        if (!self.creativeTypeOptions) {
          return [];
        }

        if (typeof self.creativeTypeOptions === 'string') {
          self.creativeTypeOptions = JSON.parse(self.creativeTypeOptions);
        }

        return self.creativeTypeOptions
          .filter(({ formats_disabled }) => !formats_disabled || !formats_disabled.includes(self.format))
          .map(({ label, value }) => [label, value]);
      };

      creativeModalTable.on('draw.dt', function () {
        creativeModalTableLoaded.resolve();
      });

      creativeModalTable.on('draw.dt', function () {
        setSelectedRadioButton();
      });

      creativeModalTable.on('preXhr.dt', function(e, settings, data) {
        data.demand_partner_id = self.demandPartnerId;
        data.custom_data = data.custom_data || {};
        data.custom_data.selected_ids = [selectedCreativeId];
        data.custom_data.format = self.format || null;
      });

      bodySelector.on('change', "[name='selected_creative_id']", function(){
        var radio = $(this);
        var val = radio.val();
        var nameIndex = creativeModalTable.find('th.creative-name').index();
        if(radio.prop('checked')){
          // somewhat hacky code to extract the values we need from the modal's table
          selectedCreativeId = val;
          selectedCreativeName = $(this).closest('tr').find('td').eq(nameIndex).text();
          selectedCreativeFormUrl = $(this).closest('tr').find('td').eq(nameIndex).find('a').attr('href');
          setCreativeSelectDisabled();
        }
      });

      creativeModal.find('#creative_select').click(function() {
        if (self.creativeId !== selectedCreativeId) {
          resetProgress();
          self.creativeId = selectedCreativeId;
          self.creativeFileName = selectedCreativeName;
          self.creativeFormUrl = selectedCreativeFormUrl
          $scope.$apply();
        }

        creativeModal.modal('hide');
      });

      $rootScope.$on('formatChanged', function(e, args) {
        self.format = args.format;

        if (self.creativeAction === 'custom' && self.format !== 'tile') {
          self.creativeAction = 'file';
        }

        clearCreative();
      });

      var setSelectedRadioButton = function() {
        $("#creative_modal input[type='radio']").prop('checked', false);
        creativeModalTable.find('#selected_creative_id_' + selectedCreativeId).prop('checked', true);
      };

      self.fileIconClass = function() {
        if(self.format === 'video') {
          return self.creativeFormUrl.startsWith("/filepaths/") ? 'fa-folder-grid' : 'fa-video'
        } else if (self.format === 'audio') {
          return 'fa-headphones'
        } else if (self.format === 'tile') {
          return 'fa-stop'
        } else {
          return '';
        }
      }


    }]
});

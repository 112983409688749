import platformUtilities from '../../modules/platform_utilities.js';
import WaterfallHelper from '../../modules/waterfall_helper.js';
import WaterfallModalHelper from '../../modules/waterfall_modal_helper.js';

angular.module('vastdesk').
  component('demandTagDemandTagPriorities',  {
    bindings: {
      formName: '@',
      setDemandTagPriorities: '<',
      editable: '<',
      batchActions: '<',
      demandTagId: '<',
      demandClass: '@',
      modalPath: '@',
      partnerTierOverrideEnabled: '<',
      partnerTierOverride: '@',
      format: '@',
      environment: '@',
      currencyPlaceholder: '@',
      forecast: '<'
    },
    template: require("../templates/demandTagDemandTagPriorities.html"),
    controllerAs: '$demandTagDemandTagPrioritiesCtrl',
    controller: ['$scope', '$rootScope', function($scope, $rootScope) {

      var self = this;

      this.selectedIds = [];
      this.selectedSupplyTagBulkActionIds = [];
      this.bulkActionDisabledConditions = {};
      var waterfallModalHelper = new WaterfallModalHelper();
      var serverSideTable = $('#tag_results table');
      var serverSideTableId = $(serverSideTable).attr('id');
      var serverSideDataTable;
      var modal = $('#tag_modal');
      var modalTable = $('#tag_modal table');
      var modalTableLoaded = $.Deferred();
      var bodySelector = $('body');
      var filteredSetDemandTagPriorities = [];
      var reportData = [];
      var supplyPartnerIdsInWaterfall = [];
      var buyingStrategyIdsinWaterfall = [];
      var serverSideTableLoaded = false;
      var waterfallHelper = new WaterfallHelper();

      this.setDemandTagPriorityUniqIdFor = function(setDemandTagPriority) {
        return waterfallHelper.setDemandTagPriorityUniqIdFor(setDemandTagPriority, 'supply_tag_id');
      };

      this.$onInit = function() {
        setHouseAdDisabledCondition();
      };

      serverSideTable.on('init.dt', function() {
        serverSideDataTable = $(this).DataTable();
        waterfallHelper.addDateRangeSelect({forecast: self.forecast});

        if (Array.isArray(reportData) && reportData.length > 0) {
          setTimeout(function() {
            waterfallHelper.drawServerSideTable(serverSideDataTable, false);
          }, 0);
        }
      });

      serverSideTable.on('preXhr.dt', function (e, settings, data) {
        waterfallHelper.abortPendingAjaxRequests(serverSideTable, serverSideDataTable);

        data.set_demand_tag_priorities = self.setDemandTagPriorities;
        data.date_range = $('#date_range').val();
        data.report_data = waterfallHelper.getSupplyTagReportDataForDraw(serverSideTable, serverSideDataTable, reportData);
        data.custom_data = data.custom_data || {};
        data.custom_data.selected_ids = self.selectedSupplyTagBulkActionIds;

        $('.dtp-r').blur();
      });

      serverSideTable.on('xhr.dt', function(e, settings, json, xhr) {
        if (json && json.custom_data) {
          serverSideTableLoaded = true;
          filteredSetDemandTagPriorities = json.custom_data.filtered_set_demand_tag_priorities;
          supplyPartnerIdsInWaterfall = json.custom_data.supply_partner_ids;
          buyingStrategyIdsinWaterfall = json.custom_data.buying_strategy_ids;
          waterfallHelper.filterPartners(serverSideTable, serverSideTableLoaded, supplyPartnerIdsInWaterfall);
        }
        if (json && json.data) {
          attachReportData(json.data);
        }
      });

      serverSideTable.on('init.filter.ss.dt', function(e, filterId) {
        if (filterId === 'dfilter_partner') {
          setTimeout(function() {
            waterfallHelper.filterPartners(serverSideTable, serverSideTableLoaded, supplyPartnerIdsInWaterfall);
          }, 0);
        }
      });

      $scope.$on('demandPartnerChanged', function(e, args) {
        waterfallHelper.drawServerSideTable(serverSideDataTable, false);
      });

      $scope.$on('campaignChanged', function(e, args) {
        waterfallHelper.drawServerSideTable(serverSideDataTable, false);
      });

      $scope.$on('partnerTierFieldsChange', function(e, args) {
        self.partnerTierOverrideEnabled = args.partnerTierOverrideEnabled;
        self.partnerTierOverride = args.partnerTierOverride;
      });

      $scope.$on('demandClassChanged', function(e, args) {
        self.demandClass = args.demandClass;
        setHouseAdDisabledCondition();

        if (args.demandClass === '8' && Array.isArray(buyingStrategyIdsinWaterfall) && buyingStrategyIdsinWaterfall.length > 0) {
          removeSetDemandTagPriorities(buyingStrategyIdsinWaterfall, false, {allSlots: true});
        }
        else {
          waterfallHelper.drawServerSideTable(serverSideDataTable, false);
        }
      });

      $scope.$on('updatingReportData', function(e, args) {
        if (args.table === serverSideTableId) {
          platformUtilities.triggerDataTablesProcessing(serverSideTable, true);
        }
      });

      $scope.$on('updatedReportData', function(e, args) {
        if (args.table === serverSideTableId) {
          reportData = args.resp.supply_tag_report;
          self.currencyPlaceholder = args.resp.currency_placeholder;
          waterfallHelper.drawServerSideTable(serverSideDataTable, false);
        }
      });

      $scope.$on('bulkEditBroadcast', function(e, args) {
        if (args.tableId === serverSideTableId) {
          if (!args.selectedIds || !Array.isArray(args.selectedIds)) {
            return;
          }

          var supplyTagIds = _.map(args.selectedIds, function(id) {
            return parseInt(id);
          });

          if (args.id === 'remove') {
            removeSetDemandTagPriorities(supplyTagIds, false, {
              allSlots: true
            });
          }
          else if (args.id === 'tier') {
            handleBulkChange(supplyTagIds, 'tier', parseInt(args.value));
          }
          else if (args.id === 'ratio') {
            handleBulkChange(supplyTagIds, 'ratio', waterfallHelper.parseIntOrNull(args.value));
          }
        }
      });

      $scope.$on('openrtbGrossFloorRateChange', function(e, args) {
        waterfallHelper.drawServerSideTable(serverSideDataTable, false);
      });

      $scope.$on('copySupplyFromDemandSubmit', function(e, args) {
        var params = _.extend({}, args.params, {
          supply_tag_ids: currentSupplyTagIds(),
          current_demand_tag_id: self.demandTagId
        });

        $.post(args.path, params, function(resp) {
          addSetDemandTagPriorities(resp);
        });
      });

      $scope.$on('addSupplyModalSave', function(e, args) {
        var path = $('#tag_results').data('ssTableRowsPath');
        var params = _.extend({}, args.params, {
          "object_ids": self.selectedIds
        });

        if (self.partnerTierOverrideEnabled) {
          params["partner_tier_override"] = self.partnerTierOverride;
        }

        $.post(path, params, function(resp) {
          addSetDemandTagPriorities(resp);
        });
      });

      var priorityWithSupplyTagIdAndSlot = function(priorities, id, slotOrder, slotNumber) {
        return _.find(priorities, function(sdtp) {
          return parseInt(sdtp.supply_tag_id) === parseInt(id) && waterfallHelper.inSlot(sdtp, slotOrder, slotNumber);
        });
      };

      var setDemandTagPriorityFor = function(row) {
        var id = dataAttributeForRow(row, 'supplyTagId');
        var slotOrder = dataAttributeForRow(row, 'so');
        var slotNumber = dataAttributeForRow(row, 'sn');
        return priorityWithSupplyTagIdAndSlot(self.setDemandTagPriorities, id, slotOrder, slotNumber);
      };

      var updateSetDemandTagPriority = function(row, attributes, draw) {
        var sdtp = setDemandTagPriorityFor(row);

        if (sdtp) {
          sdtp = _.extend(sdtp, attributes);
          $scope.$apply();

          if (draw) {
            waterfallHelper.drawServerSideTable(serverSideDataTable, false);
          }
        }
      };

      var updateSetDemandTagPriorityFromElement = function(element, attribute, draw) {
        var row = waterfallHelper.rowForTableElement(element);
        var attributes = {};
        attributes[attribute] = waterfallHelper.valueForElement(element, attribute);
        updateSetDemandTagPriority(row, attributes, draw);
      };

      var handleBulkChange = function(supplyTagIds, attribute, value) {
        _.each(self.setDemandTagPriorities, function(sdtp) {
          if (_.contains(supplyTagIds, parseInt(sdtp.supply_tag_id))) {
            sdtp[attribute] = value;
          }
        });

        setTimeout(function() {
          $scope.$apply();
        }, 0);

        waterfallHelper.drawServerSideTable(serverSideDataTable, false);
      };

      bodySelector.on('change', 'td .dtp-t', function () {
        updateSetDemandTagPriorityFromElement(this, 'tier', true);
      });

      bodySelector.on('change', 'td .dtp-p', function () {
        updateSetDemandTagPriorityFromElement(this, 'priority', false);
      });

      bodySelector.on('change', 'td .dtp-r', function () {
        updateSetDemandTagPriorityFromElement(this, 'ratio', true);
      });

      bodySelector.on("click", "form.tag-form a.remove-tag", function(){
        var row = waterfallHelper.rowForTableElement(this);
        var supplyTagId = dataAttributeForRow(row, 'supplyTagId');

        removeSetDemandTagPriorities([supplyTagId], true, {
          slotOrder: dataAttributeForRow(row, 'so'),
          slotNumber: dataAttributeForRow(row, 'sn')
        });

        return false;
      });

      bodySelector.on('click', '.remove-all', function(e){
        removeAllFilteredSetDemandTagPriorities();
        return false;
      });

      modalTable.on('preXhr.dt', function(e, settings, data) {
        data.custom_data = data.custom_data || {};
        data.custom_data.selected_ids = [].concat(currentSupplyTagIds()).concat(self.selectedIds);
      });

      modalTable.on('init.dt', function () {
        modalTableLoaded.resolve();
      });

      modalTable.on('draw.dt', function () {
        waterfallModalHelper.setSelectedModalCheckboxes(modalTable, self.selectedIds);
        waterfallHelper.setModalCheckboxes(modalTable, currentSupplyTagIds());
      });

      bodySelector.on('click', '.open-tag-modal', function (e) {
        if ($(this).attr('disabled')) {
          e.preventDefault();
          return false;
        }
        self.selectedIds = [];
      });

      waterfallModalHelper.openEventHandler(bodySelector, '.open-tag-modal', modal, modalTable, function() {
        $.when( modalTableLoaded ).done(function() {
          waterfallModalHelper.setFormatFilter(modal, self.format);
          waterfallModalHelper.hideShowFormatFilters(modal, self.format);
          waterfallModalHelper.setEnvironmentFilter(modal, self.environment);
          $('.modal-content .dataTables_filter input').attr("placeholder", "Search by tag or partner name, or enter a comma-separated list of IDs...");
        });
      });

      waterfallModalHelper.modalCheckEventHandler(bodySelector, 'tag_ids', function(val) {
        self.selectedIds.push(val);
      }, function(val) {
        self.selectedIds = _.without(self.selectedIds, val);
      });

      var dataAttributeForRow = function(row, attribute) {
        return $(row).data(attribute);
      };

      var removeAllFilteredSetDemandTagPriorities = function() {
        self.setDemandTagPriorities = _.reject(self.setDemandTagPriorities, function(sdtp) {
          return _.find(filteredSetDemandTagPriorities, function(fsdtp) {
            return parseInt(sdtp.supply_tag_id) === parseInt(fsdtp.supply_tag_id) && waterfallHelper.inSlot(sdtp, fsdtp.slot_order, fsdtp.slot_number);
          });
        });
        $scope.$apply();
        waterfallHelper.drawServerSideTable(serverSideDataTable, true);
      };

      var reportRowFor = function(tableRow) {
        return _.find(reportData, function(reportRow) {
          return reportRow.supply_tag_id === tableRow.DT_RowAttr['data-supply-tag-id'] &&
            waterfallHelper.inSlot(reportRow, tableRow.DT_RowAttr['data-so'], tableRow.DT_RowAttr['data-sn']);
        }) || {};
      };

      var attachReportData = function(rows) {
        _.each(rows, function(r) {
          var reportRow = reportRowFor(r);
          waterfallHelper.addReportDataToRow(r, reportRow, {
            currencyPlaceholder: self.currencyPlaceholder
          });
        });
      };

      var currentSupplyTagIds = function() {
        return waterfallHelper.extractIdsFromSetList(self.setDemandTagPriorities, 'supply_tag_id');
      };

      var addSetDemandTagPriorities = function(resp) {
        if (!resp.rows) {
          return;
        }

        var currentIds = currentSupplyTagIds();

        var validNewDemandTagPriorities = _.reject(resp.rows, function(dtp) {
          return _.contains(currentIds, parseInt(dtp.supply_tag_id));
        });

        self.setDemandTagPriorities = self.setDemandTagPriorities.concat(validNewDemandTagPriorities);
        $scope.$apply();
        waterfallHelper.drawServerSideTable(serverSideDataTable, false);
      };

      var removeSetDemandTagPriorities = function(supplyTagIds, applyScope, slotOptions) {
        var startingLength = self.setDemandTagPriorities.length;

        self.setDemandTagPriorities = _.reject(self.setDemandTagPriorities, function(sdtp) {
          return _.contains(supplyTagIds, parseInt(sdtp.supply_tag_id)) && (slotOptions.allSlots || waterfallHelper.inSlot(sdtp, slotOptions.slotOrder, slotOptions.slotNumber));
        });

        var endingLength = self.setDemandTagPriorities.length;
        var tagsRemoved = startingLength - endingLength;

        if (tagsRemoved < 1) {
          return;
        }

        if (applyScope) {
          $scope.$apply();
        }

        waterfallHelper.drawServerSideTableAfterRemoval(serverSideDataTable, tagsRemoved);
      };

      var setHouseAdDisabledCondition = function() {
        self.bulkActionDisabledConditions.houseAd = parseInt(self.demandClass) === 8;
      };

      $rootScope.$on('formatChanged', function(e, args) {
        self.format = args.format;
      });

      $rootScope.$on('environmentChange', function(e, args) {
        self.environment = args.environment;
      });

      var intervalTime = 0;
      setInterval(function () {
        intervalTime += 1;
        if(intervalTime >= 60 && document.hasFocus() && $("#tabTags").is(".active")){
          $rootScope.$broadcast('updateReportData', {table: serverSideTableId});
          intervalTime = 0;
        }
      }, 1000);

    }]
});

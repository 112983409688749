angular.module('vastdesk').
  component('campaignDuplicateModal',  {
    bindings: {
      campaignId: '<'
    },
    template: require("../templates/campaignDuplicateModal.html"),
    controllerAs: '$campaignDuplicateModalCtrl',
    controller: ['$scope', function($scope) {

      const modal = $('#campaign_duplicate_modal');

      this.duplicateDemand = false;

      this.duplicateDemandOptions = [
        {value: false, label: 'Campaign'},
        {value: true, label: 'Campaign & Demand Tags'}
      ];

      this.dupePath = () => {
        return '/demand/campaigns/' + this.campaignId + '/duplicate';
      };

      modal.on('show.bs.modal', (e) => {
        const relatedTarget = $(e.relatedTarget);
        const campaignId = relatedTarget.data('campaignId');

        if (campaignId) {
          this.campaignId = campaignId;
          $scope.$apply();
        }
      });

    }]
});

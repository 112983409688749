import platformUtilities from '../../modules/platform_utilities.js';
import dataTableStickyTableHeaders from '../../modules/data_table_sticky_table_headers.js';

angular.module('vastdesk')
.component('reportBuilder', {
  bindings: {
    formPath: '@',
    params: '<',
    forecasting: '<',
    submitLabel: '@',
    debug: '<'
  },
  template: require("../templates/reportBuilder.html"),
  controllerAs: '$reportBuilderCtrl',
  controller: ['$scope', function($scope) {

    this.report = {
      dimensions: [],
      metrics: []
    };
    this.reportInfo = {};
    this.filters = [];
    this.demandReport = false;
    this.dataSource = {};
    this.filterHtmlOptions = {};
    this.accountInfo = {};
    const reportForm = $('#report_form');
    const bodyElement = $('body');
    let demandDimensions = [];
    let ortbDimensions = [];
    let customKeyValueDimensions = [];
    const countryDimensions = ['country'];
    const keyDimensions = ['key'];
    const vastErrorCodeDimensions = ['vast_error_code'];
    const keyValueDimensions = ['key_values'];
    const routerDimensions = ['supply_router_id'];
    const domainDimensions = [
      'declared_domain',
      'detected_domain',
      'app_bundle',
      'app_name'
    ];
    const magniteSspDimensions = [
      'spotx_channel_id',
      'magnite_tier',
      'spotx_campaign_id',
      'tier_selection',
      'foreign_deal_id',
      'foreign_deal_name',
      'foreign_deal_code'
    ];
    const detectedDimensions = [
      'detected_domain',
      'detected_player_size',
      'detected_adomain'
    ];
    let reportRetries = 0;

    reportForm.submit(() => {
      pollForHtmlReport(true);
      return false;
    });

    const reportFormJson = () => {
      const serialized = reportForm.serializeJSON();

      if (serialized) {
        if (Array.isArray(serialized.exclude)) {
          serialized.exclude = _.compact(serialized.exclude);
        }
        if (Array.isArray(serialized.exclude_keys)) {
          serialized.exclude_keys = _.compact(serialized.exclude_keys);
        }
      }

      return serialized;
    };

    const pollForCsvReport = (isFirstSubmission, reportId) => {
      pollForReport(isFirstSubmission, reportId, true);
    };

    const pollForHtmlReport = (isFirstSubmission, reportId) => {
      pollForReport(isFirstSubmission, reportId, false);
    };

    const pollForReport = (isFirstSubmission, reportId, isCsv) => {
      const formUrl = reportForm.attr("action");

      const formArrayWithDuplicates = reportForm.serializeArray();

      let cleanFormArray = _.filter(formArrayWithDuplicates, (formEntry) => !!formEntry.value);

      if (isCsv) {
        cleanFormArray.push({name: "csv", value: true});
      }

      const formData = $.param(cleanFormArray);

      if (isFirstSubmission) {
        setupReport(formData, formUrl);
      }

      let serviceRequest = "";

      const reportIdKey = (isCsv) ? "csv_report_id" : "report_id";

      if (reportId !== null && reportId !== undefined) {
        serviceRequest += ("&" + reportIdKey + "=" + reportId);
      }

      $.post(formUrl, (formData + serviceRequest), (data) => {
        if (isCsv) {
          csvSuccessReport(formData, data);
        } else {
          successReport(formData, data);
        }
        $scope.$apply();
      }).fail(() => {
        failReport();
      });
    };

    this.exportCsv = (setExportCsvLink) => {
      this.exportCsvLink = this.formPath + '.csv?' + reportForm.serialize();
      pollForCsvReport(true);
      return false;
    };

    this.cancelReport = () => {
      resetReport();
      mixpanel.track('report cancelled', reportFormJson());
      return false;
    };

    const setupReport = (formData, formUrl) => {
      this.reportLoading = true;
      this.lastFormData = formData;
      mixpanel.track("forecast run", reportFormJson());
      history.replaceState({}, '', formUrl + '?' + formData);
      this.startTime = Date.now();
    };

    const addCsvIdToExportLink = (reportId) => {
      this.exportCsvLink = this.exportCsvLink.replace(/&csv_report_id=\d+/, '').replace(/&csv_report_id=/, '') + '&csv_report_id=' + reportId;
    };

    const requestCsv = (csvReportId) => {
      addCsvIdToExportLink(csvReportId);

      setTimeout(() => {
        $('#export_link').click();
      }, 0);

      completeReport();
    };

    const intervalPoll = (pollForReportCb, reportId, formData, reportType) => {
      if (reportRetries < 600 || reportType === 'html') { //5 minutes
        if (reportRetries === 600) {
          reportErrorModal({
            reportType: reportType
          });
        }
        setTimeout(() => {
          if (this.lastFormData === formData) {
            reportRetries += 1;
            pollForReportCb(false, reportId);
          }
        }, 500);
      } else {
        failReport({
          timeout: true,
          reportType: reportType
        });
      }
    };

    const failedReportStatus = (status) => {
      return typeof(status) === 'string' && status.toLowerCase().startsWith('failed');
    };

    const csvSuccessReport = (formData, data) => {
      if (this.lastFormData === formData) {
        if (data.complete === true || data.complete === "true") {
          requestCsv(data.csv_report_id);
        }
        else if (failedReportStatus(data.status)) {
          failReport({
            status: data.status
          });
        }
        else {
          intervalPoll(pollForCsvReport, data.csv_report_id, formData, 'csv');
        }
      }
    };

    const successReport = (formData, data) => {
      if (this.lastFormData === formData) {
        if (failedReportStatus(data.status)) {
          failReport({
            status: data.status
          });
        }
        else if (data.report_id && !data.account_summary) {
          intervalPoll(pollForHtmlReport, data.report_id, formData, 'html');
        }
        else {
          this.report.metrics = data.metrics;
          setMetrics();

          $('#results').replaceWith(data.results);

          $('#results .datatable').defaultDataTable( {
            "processing": true,
            "serverSide": true,
            "deferLoading": data.row_count,
            "ajax": {
              "url": this.formPath + "?follow_up_request=true&report_id=" + data.report_id + "&" + formData,
              "type": "POST"
            },
            initComplete: function() {
              dataTableStickyTableHeaders.sticky(this);
            }
          });

          if (data.csv_report_form) {
            const modal = data.shared_report ? $('#share_link_modal') : $('#large_report_modal');
            modal.find("form").replaceWith(data.csv_report_form);
            modal.modal();
          }

          completeReport();
        }
      }
    };

    const FORECASTING_ERROR_CODE_MESSAGES = {
      "FAILED_LOOKBACK_REQUEST": "Try again or contact support.",
      "FAILED_INTERIM_REPORT": "Try again or contact support.",
      "FAILED_MFE_REQUEST": "Try again or contact support.",
      "FAILED_CARDINALITY_EXCEEDED": "Your query exceeded our cardinality limit of 1000.",
      "FAILED_EMPTY_INTERIM_REPORT": "There is not enough historical data with your current filters to generate this forecast.",
      "FAILED_MFE_COPY": "Try again or contact support.",
      "FAILED": "Try again or contact support."
    };

    const reportErrorModal = (options) => {
      let modalTitle = 'Report Error';
      let errorMessage = 'There was an unexpected error running this report. Please try again.';

      if (options.reportType === 'csv') {
        modalTitle = 'Report Running';
        errorMessage = 'This report is reading a large amount of data and will appear in downloads when complete.';
      }
      else if (options.reportType === 'html') {
        modalTitle = 'Report Running';
        errorMessage = 'This report is reading a large amount of data; it will continue to run if you stay on this page.';
      }
      else if (options.status) {
        errorMessage = FORECASTING_ERROR_CODE_MESSAGES[options.status] || 'Report failed with ' + options.status + ' status';
      }

      const errorModal = $("#report_error_modal");
      errorModal.find('.modal-title').text(modalTitle);
      errorModal.find('#report-error-msg').text(errorMessage);
      errorModal.modal();
    };

    const failReport = (options = {}) => {
      resetReport();

      reportErrorModal(options);
      if (options.timeout) {
        mixpanel.track("forecast timeout", reportFormJson());
      }
      else {
        mixpanel.track("forecast failed", reportFormJson());
      }
    };

    const completeReport = () => {
      resetReport();

      this.endTime = Date.now();

      let json = reportFormJson();
      json.totalTimeMs = this.endTime - this.startTime;
      mixpanel.track("forecast completed", json);
    };

    const resetReport = () => {
      this.lastFormData = null;
      this.reportLoading = false;
      reportRetries = 0;
    };

    const safeArray = (attr) => {
      return Array.isArray(attr) ? attr : [];
    };

    const formatReportArrayParam = (attr) => {
      if (!Array.isArray(this.report[attr])) {
        this.report[attr] = [];
      }
    };

    const formatObjectParam = (attr) => {
      if (typeof(this.report[attr]) !== 'object') {
        this.report[attr] = {};
      }
    };

    const formatReport = () => {
      formatReportArrayParam('metrics');
      formatReportArrayParam('dimensions');
      formatReportArrayParam('exclude');
      formatReportArrayParam('exclude_keys');
      formatObjectParam('key_values');
    };

    const getReportInfo = () => {
      let self = this;
      const params = _.extend({}, this.params, {forecasting: this.forecasting});

      $.post('/reports/info', params, (resp) => {
        self.reportInfoLoaded = true;
        self.report = self.params;
        self.reportInfo = resp;
        self.accountInfo =  self.reportInfo.account_info;
        self.filterHtmlOptions = self.reportInfo.filter_options.html_options;
        self.filters = _.chain(self.reportInfo.filter_options.allowlist)
                        .filter((f) => f.in_view)
                        .map((f) => _.pick(f, 'param'))
                        .value();
        demandDimensions = safeArray(self.reportInfo.dimension_options.demand_dimensions);
        ortbDimensions = safeArray(self.reportInfo.dimension_options.ortb_dimensions);
        customKeyValueDimensions = _.chain(self.reportInfo.filter_options.allowlist)
                                    .filter((f) => f.custom_key_filter)
                                    .map((f) => f.dimension)
                                    .value();

        if (self.debug) {
          self.debugDimensions = _.chain(safeArray(self.reportInfo.data_source_options.data_sources))
                                .map((ds) => ds.dimensions)
                                .flatten()
                                .uniq()
                                .value()
                                .sort();
        }

        formatReport();
        self.setDataSource();

        if (filtersWithValues().length > 0) {
          $('#collapse-filters, #collapse-metrics').addClass('show');
          $('a[href="#collapse-filters"], a[href="#collapse-metrics"]').removeClass('collapsed');
        }

        $scope.$apply();

        setMetrics();
        $('#forecast_date_range, #interval, #interval_start_day, #timezone, #dimensions').trigger('chosen:updated');
        self.setDatePicker();
        initFilterSelects();
      });
    };

    const initFilterSelects = () => {
      setTimeout(() => {
        $.each($('.filter-chosen-select, #filters .chosen-select'), (index, select) => {
          $(select).defaultChosen();
        });

        $.each($('select.dynamic-chosen-select'), (index, select) => {
          $(select).dynamicChosen();
        });

        $.each($('select.filter-tom-select'), (index, select) => {
          $(select).defaultTomSelect();
        });
      }, 0);
    };

    const updateFilterChosenSelects = () => {
      setTimeout(() => {
        $('.filter-chosen-select, #filters .chosen-select').trigger('chosen:updated');
      }, 0);
    };

    const updateFilterTomSelects = () => {
      setTimeout(() => {
        $.each($('select.filter-tom-select'), (index, select) => {
          const ts = $(select).get(0).tomselect;

          if (ts) {
            $(select).attr('disabled') ? ts.disable() : ts.enable();
          }
        });
      }, 0);
    };

    const setMetrics = () => {
      $scope.$broadcast('setReportMetrics', {
        metrics: this.report.metrics || [],
        applyScope: true
      });
    };

    this.setDatePicker = () => {
      const timezone = this.report.timezone || 'Etc/UTC';
      const dateFormat = 'MM/DD/YY HH:mm';
      const momentTz = moment().tz(timezone);
      const minDate = momentTz.clone().add(1, 'd').startOf('day');
      const maxDate = momentTz.clone().add(31, 'd').endOf('day');
      const defaultStartDate = minDate;
      const defaultEndDate = momentTz.clone().add(1, 'd').endOf('day');
      const customSplit = (this.report.custom_forecast_date_range || '').split(' - ');
      const startDate = customSplit[0] || defaultStartDate;
      const endDate = customSplit[1] || defaultEndDate;

      const dateRangeOptions = {
        minDate: minDate.format(dateFormat),
        maxDate: maxDate.format(dateFormat),
        opens: 'center',
        locale: {
          format: dateFormat,
          direction: 'datehourrange-future-wrapper'
        },
        parentEl: '.daterange-place'
      };

      if (!moment.tz(startDate, timezone).isBetween(minDate, maxDate) || !moment.tz(endDate, timezone).isBetween(minDate, maxDate) || endDate < startDate) {
        dateRangeOptions.startDate = defaultStartDate.format(dateFormat);
        dateRangeOptions.endDate = defaultEndDate.format(dateFormat);
      }

      $("#custom_forecast_date_range").daterangepicker(dateRangeOptions);
    };

    const allDimensionsSupported = (reportDimensions, dataSourceDimensions) => {
      return _.difference(reportDimensions, dataSourceDimensions).length === 0;
    };

    const validDataSources = () => {
      const dataSources = safeArray(this.reportInfo.data_source_options.data_sources);
      const filtersWithValuesDimensions = _.chain(filtersWithValues())
                                           .map((f) => {
                                             return dimensionForFilter(f.param);
                                           })
                                           .uniq()
                                           .compact()
                                           .value();

      return _.filter(dataSources, (ds) => {
        return allDimensionsSupported(this.report.dimensions, ds.dimensions) &&
          allDimensionsSupported(filtersWithValuesDimensions, ds.dimensions);
      });
    };

    const selectableDataSourceAttr = (attr) => {
      return _.chain(validDataSources())
              .pluck(attr)
              .flatten()
              .uniq()
              .value();
    };

    const reportHasAnyDimensions = (dims) => {
      return _.intersection(this.report.dimensions, dims).length > 0;
    };

    const allowListFilterFor = (param) => {
      return _.findWhere(safeArray(this.reportInfo.filter_options.allowlist), {param: param});
    };

    const dimensionForFilter = (param) => {
      const filter = allowListFilterFor(param);
      return (filter && filter.dimension) ? filter.dimension : null;
    };

    const arrayFilterHasValues = (param, values) => {
      return _.intersection(safeArray(this.report[param]), values).length > 0;
    };

    const filterValuePresent = (param) => {
      let filterValues = null;

      if (param) {
        const allowListFilter = allowListFilterFor(param);

        if (allowListFilter && allowListFilter.custom_key_filter) {
          filterValues = this.report.key_values[allowListFilter.custom_key_param];
        }
        else {
          filterValues = this.report[param];
        }
      }
      return Array.isArray(filterValues) ? filterValues.length > 0 : filterValues && filterValues !== '';
    };

    const filtersWithValues = () => {
      return _.filter(this.filters, (f) => {
        return filterValuePresent(f.param);
      });
    };

    const reportHasAnyFilters = (dims) => {
      return !!_.find(filtersWithValues(), (f) => {
        const dimension = dimensionForFilter(f.param);
        return dimension && _.contains(dims, dimension);
      });
    };

    const reportHasAnyDimensionsOrFilters = (dims) => {
      return reportHasAnyDimensions(dims) || reportHasAnyFilters(dims);
    };

    const clearNonApplicableFilters = () => {
      const allFilters = _.pluck(this.reportInfo.filter_options.allowlist, 'param');
      const filtersInView = _.pluck(this.filters, 'param');

      _.difference(allFilters, filtersInView).forEach((filterParam) => {
        clearFilter(filterParam);
      });
    };

    const clearFilter = (filterParam) => {
      const allowListFilter = allowListFilterFor(filterParam);

      if (allowListFilter && allowListFilter.custom_key_filter) {
        delete this.report.key_values[allowListFilter.custom_key_param];
        this.report.exclude_keys = _.without(safeArray(this.report.exclude_keys), filterParam);
      }
      else {
        delete this.report[filterParam];
        this.report.exclude = _.without(safeArray(this.report.exclude), filterParam);
      }

      delete this.reportInfo.filter_options.select_options.search[filterParam];
    };

    this.timezoneDisabled = (timezone) => {
      return timezone !== 'Etc/UTC' && this.forceUTC;
    };

    const isPcFilterReport = () => {
      return this.accountInfo.pc_seller && (
        arrayFilterHasValues('demand_classes', ['6','7']) ||
          arrayFilterHasValues('marketplace_type_ids', ['5','6','7','8','9','10']) ||
          arrayFilterHasValues('demand_tag_ids', [this.accountInfo.open_market_demand_tag_id || -1]) ||
          arrayFilterHasValues('campaign_ids', [this.accountInfo.open_market_campaign_id || -1]) ||
          arrayFilterHasValues('demand_partner_ids', [this.accountInfo.open_market_demand_partner_id || -1])
      );
    };

    this.setDataSource = () => {
      this.demandReport = reportHasAnyDimensionsOrFilters(demandDimensions);
      this.dataSource = validDataSources()[0];
      this.forceUTC = this.dataSource && _.contains(['SupplyContent', 'ContentParam', 'SupplyDomain', 'DemandDomain', 'ADomain'], this.dataSource.name);
      this.magniteSspReport = reportHasAnyDimensionsOrFilters(magniteSspDimensions);
      this.detectedReport = reportHasAnyDimensionsOrFilters(detectedDimensions);
      this.domainReport = reportHasAnyDimensionsOrFilters(domainDimensions);
      this.countryReport = reportHasAnyDimensionsOrFilters(countryDimensions);
      this.keyReport = reportHasAnyDimensionsOrFilters(keyDimensions);
      this.keyValueReport = reportHasAnyDimensionsOrFilters(keyValueDimensions);
      this.customKeyValueReport = reportHasAnyDimensionsOrFilters(customKeyValueDimensions);
      this.ortbReport = reportHasAnyDimensionsOrFilters(ortbDimensions);
      this.vastErrorCodeReport = reportHasAnyDimensionsOrFilters(vastErrorCodeDimensions);
      this.routerReport = reportHasAnyDimensionsOrFilters(routerDimensions);
      this.audioReport = arrayFilterHasValues('demand_tag_formats', ['audio']) || arrayFilterHasValues('supply_tag_formats', ['audio']);
      this.pcFilterReport =  isPcFilterReport();

      if (this.forceUTC) {
        this.report.timezone = 'Etc/UTC';
      }

      updateFilterChosenSelects();
      updateFilterTomSelects();

      setTimeout(() => {
        $('#dimensions, #timezone').trigger('chosen:updated');
      }, 0);
    };

    this.dimensionDisabled = (dimension) => {
      const selectable = selectableDataSourceAttr('dimensions');

      if (!this.dataSource) {
        return false;
      }

      return (Array.isArray(selectable) && !_.contains(selectable, dimension)) ||
        (this.forecasting && this.countryReport && _.contains(domainDimensions, dimension)) ||
        (this.forecasting && this.domainReport && _.contains(countryDimensions, dimension)) ||
        ((this.keyReport || this.keyValueReport) && _.contains(customKeyValueDimensions, dimension)) ||
        ((this.keyReport || this.customKeyValueReport) && _.contains(keyValueDimensions, dimension)) ||
        ((this.keyValueReport || this.customKeyValueReport) && _.contains(keyDimensions, dimension));
    };

    this.quickAddFilters = () => {
      if (!this.reportInfo || !this.reportInfo.filter_options) {
        return;
      }

      return _.chain(this.reportInfo.filter_options.allowlist)
              .filter((f) => {
                return f.quick_add;
              })
              .sortBy((f) => {
                return f.name;
              })
              .value();
    };

    this.addFilter = (param) => {
      this.filters.unshift({param: param});
      this.handleFilterSelectChange();
    };

    this.filterInView = (filterParam) => {
      return !!_.findWhere(this.filters, {param: filterParam});
    };

    this.filterSelectOptions = (filterParam, displayType) => {
      return filterParam ? this.reportInfo.filter_options.select_options[displayType][filterParam] : [];
    };

    this.filterHtmlOptionProp = (filterParam, prop) => {
      return _.get(this.filterHtmlOptions || {}, [filterParam, prop]);
    };

    this.handleFilterSelectChange = () => {
      initFilterSelects();
      clearNonApplicableFilters();
      this.setDataSource();
    };

    this.filterExcludedValue = (allowlistFilter) => {
      return allowlistFilter.excludable_value || allowlistFilter.param;
    };

    this.setFilterExcluded = (allowlistFilter, excluded) => {
      const excludeTarget = this.excludeNameFor(allowlistFilter);
      const excludeValue = this.filterExcludedValue(allowlistFilter);

      if (excluded) {
        if (Array.isArray(this.report[excludeTarget])) {
          this.report[excludeTarget].push(excludeValue);
        }
        else {
          this.report[excludeTarget] = [excludeValue];
        }
      }
      else {
        this.report[excludeTarget] = _.without(safeArray(this.report[excludeTarget]), excludeValue);
      }
    };

    this.filterExcluded = (allowlistFilter) => {
      const excludeTarget = this.excludeNameFor(allowlistFilter);
      return _.contains(safeArray(this.report[excludeTarget]), this.filterExcludedValue(allowlistFilter));
    };

    this.dropFilter = (filterIndex, filter) => {
      this.filters.splice(filterIndex, 1);

      if (filter && filter.param) {
        clearFilter(filter.param)
        this.setDataSource();
      }
    };

    const reportHasDimension = (dimension) => {
      return _.includes(safeArray(this.report.dimensions), dimension);
    };

    const reportHasFilterForDimension = (dimension) => {
      return !!_.find(this.reportInfo.filter_options.allowlist, (f) => {
        return f.dimension === dimension && filterValuePresent(f.param);
      });
    };

    this.showDebugDimension = (dimension) => {
       return reportHasDimension(dimension) || reportHasFilterForDimension(dimension);
    };

    this.excludeNameFor = (allowListFilter) => {
      return allowListFilter.custom_key_filter ? 'exclude_keys' : 'exclude';
    };

    bodyElement.on('draw.dt', '.report-table', () => {
      platformUtilities.initSvgFontAwesomeIcons();
    });

    bodyElement.on('click', '.demand-code-wrapper i', (e) => {
      const demandCodeText = $(e.target).parent().text();
      $('#report_demand_code_modal').find('.demand_code_value').text(demandCodeText);
      $('#report_demand_code_modal').modal();
    });

    this.$onInit = () => {
      getReportInfo();
    };

  }]
});

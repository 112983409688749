angular.module('vastdesk')
.component('universalSearchSelector', {
  bindings: {
    excludedTagTypes: '=',
    nameSuffix: '@',
    tagType: '=',
    tagId: '=',
    displayName: '=',
    tagEnabled: '=',
    placeholder: '@',
    unselectCb: '&',
    selectCb: '&',
    filterId: '@',
    scope: '@',
    searchUrl: '@',
    format: '@',
    searchParams: '=?'
  },
  template: require("../templates/universalSearchSelector.html"),
  controllerAs: '$universalSearchCtrl',
  controller: ['$scope', '$rootScope', '$element', function($scope, $rootScope, $element) {

    var self = this;

    this.resourceSelected = function() {
      return self.tagType && self.tagType !== '' && self.tagId && self.tagId !== '';
    };

    this.unselectResource = function() {
      self.tagType = '';
      self.tagId = '';
      self.displayName = '';

      if ( self.unselectCb() ) {
        self.unselectCb()();
      }
    };

    var searchTagTypes = function(excludedTagTypes) {
      var tagTypes = ['1','2','3','4','6','10','11'];
      if ( Array.isArray(excludedTagTypes) ) {
        tagTypes = _.difference(tagTypes, excludedTagTypes);
      }
      return tagTypes;
    };

    var searchTagId = function(tagTypes, tagType) {
      if (tagTypes.length === 1 && tagTypes[0] === tagType) {
        return self.filterId;
      }
    };

    $rootScope.$on('formatChanged', function(e, args) {
      self.format = args.format;
    });

    this.$onInit = function() {
      $element.find('.universal-search-selector').groupAutocomplete({
        minLength: 1,
        source: function( request, response ) {
          var tagTypes = searchTagTypes(self.excludedTagTypes);
          var data = _.extend({}, self.searchParams || {}, {
            q: request.term,
            tag_types: tagTypes,
            demand_tag_id: searchTagId(tagTypes, '1'),
            supply_tag_id: searchTagId(tagTypes, '2'),
            scope: self.scope,
            format: self.format
          });

          $.ajax({
            url: self.searchUrl || '/universal_search',
            data: data,
            dataType: 'json',
            xhrFields: {
              withCredentials: true
            }
          }).done(function( data ) {
            response($.map( data, function( item ) {
              return {
                group: item.group,
                label: item.label,
                value: item.value,
                class: item.class,
                tag_type: item.tag_type
              };
            }));
          });
        },
        select: function(e, ui){
          var item = ui.item;
          $element.find('.universal-search-selector').val(item.label);
          self.tagType = item.tag_type;
          self.tagId = item.value;
          $scope.$apply();
          if ( self.selectCb() ) {
            self.selectCb()(self.tagId);
          }
          return false;
        }
      });
    };

  }]
});

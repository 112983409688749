angular.module('vastdesk').
  component('keyPredefinedValues',  {
    bindings: {
      valueRowPath: '@'
    },
    template: require("../templates/keyPredefinedValues.html"),
    controllerAs: '$keyPredefinedValuesCtrl',
    controller: ['$scope', '$timeout', function($scope, $timeout) {

      var self = this;
      var valuesTable = $('#value_results .datatable');
      var valuesTableId = $(valuesTable).attr('id');
      this.valuesDataTable;

      valuesTable.on('init.dt', function() {
        self.valuesDataTable = $(this).DataTable();
        $scope.$apply();
      });

      $('body').on('click', '.value-row a.remove-value', function(e) {
        e.preventDefault();
        var valueRow = $(this).parents('tr.value-row');

        if (valueRow.data('valueId')) {
          $(this).find('input').val(1);
          valueRow.hide();
        } else {
          self.valuesDataTable.row( valueRow ).remove().draw();
        }
        return false;
      });

      this.addValueRow = function() {
        $.get(self.valueRowPath, function(resp) {
          self.valuesDataTable.rows.add(resp.data).draw();
          self.valuesDataTable.order([[ 0, 'asc' ], [ 1, 'asc' ]]).draw();

          $timeout(function() {
            var firstNameInput = valuesTable.find('tbody tr:visible:first td:eq(1) input');
            if (firstNameInput) {
              $(firstNameInput).focus();
            }
          }, 250);
        });
      };

      var handleNameChange = function(valueAttrIds, name) {
        var nameIndex = valuesTable.find("th.value-name").index();

        self.valuesDataTable.rows().every(function () {
          var d = this.data();
          var valueAttrId = d.DT_RowAttr['data-value-attr-id'];

          if (_.contains(valueAttrIds, parseInt(valueAttrId))) {
            var input = $.parseHTML(d[nameIndex]);
            d[nameIndex] = $(input).attr('value', name).prop('outerHTML');
            this.data(d);
          }
        });
      };

      $scope.$on('bulkEditBroadcast', function(e, args) {
        if (args.tableId === valuesTableId) {
          if (!args.selectedIds || !Array.isArray(args.selectedIds)) {
            return;
          }

          var valueAttrIds = _.map(args.selectedIds, function(id) {
            return parseInt(id);
          });

          if (args.id === 'name') {
            handleNameChange(valueAttrIds, args.value);
          }
        }
      });

    }]
});

angular.module('vastdesk').
  component('listActions',  {
    bindings: {
      className: '@',
      listAction: '@',
      addName: '@',
      removeName: '@',
      displayNamePlural: '@',
      excludeManual: '<'
    },
    template: require("../templates/listActions.html"),
    controllerAs: '$listActionsCtrl',
    controller: function() {}
});

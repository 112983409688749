class WaterfallConverter {
  convert(scope, waterfallName, slotPositioningWas, slotPositioning, maxNumAdSlots) {
    var waterfall = scope[waterfallName];

    if (this.blank(slotPositioningWas) && slotPositioning === 'order') {
      scope[waterfallName] = this.bulkUpdate(waterfall, 'first', 0);
    }
    else if (this.blank(slotPositioningWas) && slotPositioning === 'number') {
      scope[waterfallName] = this.bulkUpdate(waterfall, 'n/a', 1);
    }
    else if (slotPositioningWas === 'number' && slotPositioning === 'order') {
      scope[waterfallName] = this.convertNumberToOrder(waterfall, maxNumAdSlots);
    }
    else if (slotPositioningWas === 'order' && slotPositioning === 'number') {
      scope[waterfallName] = this.convertOrderToNumber(waterfall, maxNumAdSlots);
    }
    else if (_.contains(['number', 'order'], slotPositioningWas) && this.blank(slotPositioning)) {
      scope[waterfallName] = this.flattenWithTiebreakers(waterfall, 'n/a', 0);
    }
  }

  rejectInvalidSlotOrders(scope, waterfallName, slotPositioningOrder, validSlotOrders) {
    if (!slotPositioningOrder) {
      return;
    }

    var waterfall = scope[waterfallName];

    scope[waterfallName] = _.select(waterfall, function(row) {
      return _.contains(validSlotOrders, row.slot_order);
    });
  }

  rejectInvalidSlotNumbers(scope, waterfallName, slotPositioningNumber, validSlotNumbers) {
    if (!slotPositioningNumber) {
      return;
    }

    var waterfall = scope[waterfallName];

    scope[waterfallName] = _.select(waterfall, function(row) {
      return _.contains(validSlotNumbers, parseInt(row.slot_number));
    });
  }

  blank(sp) {
    return !sp || sp === '';
  }

  bulkUpdate(waterfall, slot_order, slot_number) {
    return _.map(waterfall, function(row) {
      row.slot_order = slot_order;
      row.slot_number = slot_number;
      return row;
    });
  }

  convertNumberToOrder(waterfall, maxNumAdSlots) {
    var initialWaterfall = _.map(waterfall, function(row) {
      if (parseInt(row.slot_number) === 1) {
        row.slot_number = 0;
        row.slot_order = 'first';
      }
      else if (parseInt(row.slot_number) === maxNumAdSlots && maxNumAdSlots > 1) {
        row.slot_number = 0;
        row.slot_order = 'last';
      }
      return row;
    });

    if (maxNumAdSlots >= 3) {
      var middleRows = _.filter(initialWaterfall, function(row) {
        return !!row.slot_number;
      });

      var initialWaterfallWithMiddleRemoved = _.difference(initialWaterfall, middleRows);
      var smooshedMiddle = this.flattenWithTiebreakers(middleRows, 'middle', 0);

      return _.union(initialWaterfallWithMiddleRemoved, smooshedMiddle);
    }
    else {
      return initialWaterfall;
    }
  }

  convertOrderToNumber(waterfall, maxNumAdSlots) {
    if (maxNumAdSlots === 1) {
      return this.flattenWithTiebreakers(waterfall, 'n/a', 1);
    }
    else if (maxNumAdSlots === 2) {
      var initialWaterfall = _.map(waterfall, function(row) {
        if (row.slot_order === 'first') {
          row.slot_number = 1;
          row.slot_order = 0;
        }
        return row;
      });

      var nonFirstRows = _.filter(initialWaterfall, function(row) {
        return !!row.slot_order;
      });

      var initialWaterfallWithNonFirstRowsRemoved = _.difference(initialWaterfall, nonFirstRows);
      var smoosh = this.flattenWithTiebreakers(nonFirstRows, 'n/a', 2);

      return _.union(initialWaterfallWithNonFirstRowsRemoved, smoosh);
    }
    else if (maxNumAdSlots >= 3) {
      return _.map(waterfall, function(row) {
        if (row.slot_order === 'first') {
          row.slot_number = 1;
          row.slot_order = 'n/a';
        }
        else if (row.slot_order === 'middle') {
          row.slot_number = 2;
          row.slot_order = 'n/a';
        }
        else if (row.slot_order === 'last') {
          row.slot_number = maxNumAdSlots;
          row.slot_order = 'n/a';
        }
        return row;
      });
    }
    else {
      return waterfall;
    }
  }

  flattenWithTiebreakers(waterfall, slot_order, slot_number) {
    return _.chain(waterfall)
            .sortBy(function(row) {
              return [row.tier, row.priority];
            })
            .groupBy(function(row) {
              return [row.demand_tag_id, row.campaign_id];
            })
            .map(function(rows) {
              var firstRow = rows[0];
              firstRow.slot_order = slot_order;
              firstRow.slot_number = slot_number;
              return firstRow;
            })
            .value();
  }
}

export default WaterfallConverter;
import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('playerSizeTargeting',  {
    bindings: {
      formName: '@',
      formId: '@',
      allPlayerSizes: '<',
      playerSizeOptions: '<',
      playerSizeSelections: '<',
      readOnly: '<'
    },
    template: require("../templates/playerSizeTargeting.html"),
    controllerAs: '$playerSizeCtrl',
    controller: function() {
      this.targetingOptions = componentHelper.allCustomOptions2();
    }
});

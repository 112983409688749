angular.module('vastdesk')
.component('ajaxPartial', {
  bindings: {
    ajaxId: '@',
    ajaxPath: '@'
  },
  controller: ['$element', '$rootScope', function($element, $rootScope) {
    var self = this;

    var getAjaxPartial = function(ajaxPath) {
      $.ajax({
        url: ajaxPath,
        cache: false
      }).done(function(html) {
        $element.html(html);
        $element.find('[data-toggle="popover"]').popover();     
      });
    };

    var executeAjax = function() {
      if (self.ajaxPath) {
        getAjaxPartial(self.ajaxPath);
      }
    };

    this.$onInit = function() {
      executeAjax();
    };

    $rootScope.$on('refreshAjaxPartial', function(e, args) {
      if (args.ajaxId === self.ajaxId) {
         executeAjax();
      }
    });

  }]
});

import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('gdprConsentTargeting',  {
    bindings: {
      formName: '@',
      formId: '@',
      allValues: '<',
      valueSelections: '<',
      paramRequired: '<',
      readOnly: '<',
      valueMappings: '<'
    },
    template: require("../templates/gdprConsentTargeting.html"),
    controllerAs: '$gdprConsentTargetingCtrl',
    controller: function() {
      this.allCustomOptions2 = componentHelper.allCustomOptions2();
    }
});

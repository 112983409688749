angular.module('vastdesk').
  component('demandPartnerTierOverride',  {
    bindings: {
      formName: '@',
      formId: '@',
      partnerTierOverrideEnabled: '<',
      partnerTierOverride: '@',
      tierOptions: '<',
      readOnly: '<'
    },
    template: require("../templates/demandPartnerTierOverride.html"),
    controllerAs: '$demandPartnerTierOverrideCtrl',
    controller: ['$rootScope', function($rootScope) {

      var self = this;
      var serverSideTable = $('#tag_results table');

      serverSideTable.on('preXhr.dt', function (e, settings, data) {
        if (self.partnerTierOverrideEnabled) {
          data.partner_tier_override = self.partnerTierOverride;
        }
      });

      this.checkboxCb = function(val) {
        self.triggerWaterfallDraw();
      };

      this.triggerWaterfallDraw = function() {
        setTimeout(function() {
          $rootScope.$broadcast('partnerTierOverrideChange');
          $rootScope.$broadcast('partnerTierFieldsChange', {
            partnerTierOverrideEnabled: self.partnerTierOverrideEnabled,
            partnerTierOverride: self.partnerTierOverride
          });
        }, 0);
      };

    }]
});

import SwalHelper from '../../modules/swal_helper.js';

angular.module('vastdesk').
  component('bulkEdit',  {
    bindings: {
      tableId: '@',
      batchPath: '@',
      authToken: '@',
      modalPath: '@',
      objectId: '@',
      batchActions: '=',
      selectedIds: '=?',
      disabledConditions: '=?',
      enableSearch: '=?',
      bulkEditsDisabled: '@'
    },
    template: require("../templates/bulkEdit.html"),
    controllerAs: '$bulkEditCtrl',
    controller: ['$scope', '$element', '$rootScope', '$compile', function($scope, $element, $rootScope, $compile) {
      var self = this;
      var sourceTable;
      var bodyElement = $('body');

      this.searchText = '';

      this.batchExecuting = false;

      this.$onInit = function() {
        sourceTable = $('#' + self.tableId);
        self.selectedIds = [];

        sourceTable.on('draw.dt', function () {
          setCheckboxes();
        });

        bodyElement.on('change', '#' + self.tableId + ' .batch-object-id-checkbox', function() {
          var checkbox = $(this);
          var val = checkbox.val();
          var checked = checkbox.prop('checked');

          if (checked) {
            self.selectedIds.push(val);
          } else {
            self.selectedIds = _.without(self.selectedIds, val);
          }

          $scope.$apply();
        });
      };

      var setCheckboxes = function() {
        sourceTable.find('thead input[type="checkbox"]').attr('disabled', false).prop('checked', false);
        _.each(self.selectedIds, function(id) {
          sourceTable.find('.batch-object-id-checkbox[value="' + id + '"]').prop('checked', true);
        });
      };

      var appendHiddenInput = function(form, name, value) {
        form.append($("<input>", {
          type: 'hidden',
          name: name,
          value: value
        }));
      };

      var appendOptionToSelect = function(select, value) {
        select.append($("<option>", {
          text: value,
          selected: true,
          value: value
        }));
      };

      var selectorWithId = function(selector, id) {
        return selector.replace(/{ID}/g, id);
      };

      var getBulkValue = function(inputType) {
        var inputSelector = 'input[name=bulk_value]';
        if (inputType === 'radio') {
          inputSelector += ':checked';
        }
        return $('#bulk-edit-modal').find(inputSelector).val();
      };

      var destroyBulkEditModal = function() {
        $('#bulk-edit-modal').remove();
      };

      var setBatchExecutingWithApply = function(value) {
        self.batchExecuting = value;
        $scope.$apply();
      };

      bodyElement.on('keyup keypress', '#bulk-edit-modal input', function(e) {
        var keyCode = e.keyCode || e.which;
        if (keyCode === 13) {
          $(this).blur();
          e.preventDefault();
          return false;
        }
      });

      bodyElement.on('hidden.bs.modal', '#bulk-edit-modal', function() {
        destroyBulkEditModal();
      });

      bodyElement.on('change', '#bulk-edit-modal input[name="post_imp_detection_enabled"]', function() {
        if ( $(this).val() === 'true' ) {
          $('#bulk-edit-modal input[name^="post_imp_percentage_"]').val('0').trigger('change');
        }
      });

      bodyElement.on('click', '#bulk-edit-modal .btn-enabled', function() {
        $('.enabled').removeClass('hidden');
      });

      bodyElement.on('click', '#bulk-edit-modal .btn-disabled', function() {
        $('.enabled').addClass('hidden');
      });

      bodyElement.on('click', '#bulk-edit-modal .btn-bulk-source-list', function() {
        $('.bulk-source-list').removeClass('hidden');
        $('.bulk-source-values').addClass('hidden');
      });

      bodyElement.on('click', '#bulk-edit-modal .btn-bulk-source-values', function() {
        $('.bulk-source-list').addClass('hidden');
        $('.bulk-source-values').removeClass('hidden');
      });

      bodyElement.on('click', '#bulk-edit-modal .btn-custom', function() {
        $('.custom').removeClass('hidden');
      });

      bodyElement.on('click', '#bulk-edit-modal .btn-account', function() {
        $('.custom').addClass('hidden');
      });

      var preBidComponents = function(){
        return [
          $('#bulk-edit-modal').find('#pre_bid_blocking_components_whiteops:checked').val(),
          $('#bulk-edit-modal').find('#pre_bid_blocking_components_springserve:checked').val()
        ].filter(Boolean);
      };

      bodyElement.on('click', '#bulk-edit-modal .btn-ss-submit', function() {
        if (self.action && self.action.options) {
          if(self.action.options.selector === 'updateAttribute') {
            var form = $('#bulk-edit-modal').find('form');
            _.each(preBidComponents(), function(component) {
              appendOptionToSelect(form.find('#pre_bid_blocking_components'), component);
            });
            submitForm(form);
          } else {
            var bulkValue = getBulkValue(self.action.options.inputType);

            if (bulkValue && bulkValue !== '') {
              if (self.action.options.broadcast) {
                var attributes = {
                  id: self.action.options.id,
                  tableId: self.tableId,
                  selectedIds: self.selectedIds,
                  value: bulkValue
                };

                $rootScope.$broadcast('bulkEditBroadcast', attributes);
              }
              else {
                _.each(self.selectedIds, function(id) {
                  var selector = selectorWithId(self.action.options.selector, id);
                  $(selector).val(bulkValue);
                  $(selector).trigger('change').trigger('focusout');
                });
              }
            }
          }
        }

        $('#bulk-edit-modal').modal('hide');
      });

      var executeClientSideAction = {
        broadcast: function(opts, label) {
          if (opts && opts.id) {
            $rootScope.$broadcast('bulkEditBroadcast', {
              id: opts.id,
              tableId: self.tableId,
              selectedIds: self.selectedIds
            });
          }

          $('.dropdown-bulk-edit').click();
          self.batchExecuting = false;
        },
        click: function(opts, label) {
          if (opts && opts.selector) {
            _.each(self.selectedIds, function(id) {
              var selector = selectorWithId(opts.selector, id);
              $(selector).click();
            });
          }

          self.batchExecuting = false;
        },
        modal: function(opts, label) {
          if (opts && opts.field && (opts.selector || opts.broadcast)) {
            $.post(self.modalPath, {
                field: opts.field,
                title: label,
                ids: self.selectedIds,
                object_id: self.objectId,
                batch_path: self.batchPath
              }, function(bulkEditModal) {
                destroyBulkEditModal();
                bodyElement.append($compile(bulkEditModal)($rootScope));
                // defer tokenization so it is performed after Angular compilation
                setTimeout(() => {
                  $('#bulk-edit-modal').modal();

                  $.each($("#bulk-edit-modal select[data-ajax]:not([data-tom-select])"), function(index, select){
                    $(select).defaultTokenize();
                  });
                  $.each($("#bulk-edit-modal select[data-ajax][data-tom-select]"), function(index, select){
                    $(select).defaultTomSelect();
                  });
                  $.each($("#bulk-edit-modal select[data-dynamic-chosen]"), function(index, select){
                    $(select).dynamicChosen();
                  });
                  $.each($("#bulk-edit-modal select:not([data-ajax]):not([data-dynamic-chosen])"), function(index, select){
                    $(select).defaultChosen();
                  });

                  setBatchExecutingWithApply(false);
                }, 0);
              }).fail(function() {
                setBatchExecutingWithApply(false);
              });
          }
          else {
            self.batchExecuting = false;
          }
        },
        openCsvModal: function(opts, label) {
          var modal = $(opts.exportModalSelector);
          var modalTable = modal.find('.datatable').defaultDataTable({"retrieve": true});
          modal.find('.modal-content .dataTables_filter input').attr("placeholder", opts.searchText);

          _.map(modal.find('.batch-object-id-checkbox'), function(checkbox) {
            if(_.contains(self.selectedIds, checkbox.value)) {
              checkbox.checked = true;
            }
          });
          $rootScope.$broadcast('selectedIdsChanged', {selectedIds: self.selectedIds});
          modal.modal();
          modalTable.draw();
          self.batchExecuting = false;
        },
        runReport: function(opts, label) {
          var reportParams = {
            dimensions: opts.dimensions || [],
            metrics: opts.metrics || []
          };

          reportParams[opts.object_filter] = self.selectedIds;

          reportParams = _.extend(reportParams, opts.extra_filters || {});

          var reportURL = '/reports?' + $.param(reportParams);

          window.open(reportURL, "_blank");
          self.batchExecuting = false;
        },
      };

      var executeServerSideAction = function(action) {
        $element.find('form').remove();

        $element.append($("<form>", {
          action: self.batchPath,
          method: 'post',
          class: 'hidden',
          'data-turbo': false
        }));

        var form = $element.find('form');

        _.each(action.params, function(v, k) {
          appendHiddenInput(form, k, v);
        });

        if (action.confirmation_message) {
          SwalHelper.confirmWarning(
            {
              title: action.confirmation_message
            },
            function() {
              submitForm(form);
            },
            function() {
              self.batchExecuting = false;
              $scope.$apply();
            }
          );
        }
        else {
          submitForm(form);
        }
      };

      var submitForm = function(form) {

        appendHiddenInput(form, 'form_referrer', window.location.href);
        appendHiddenInput(form, 'authenticity_token', self.authToken);

        _.each(self.selectedIds, function(id) {
          appendHiddenInput(form, 'batch_object_ids[]', id);
        });

        form.submit();
      };

      this.hideAction = (action, actionDisabled) => {
        if (actionDisabled) {
          return true;
        }
        else if (!this.enableSearch || !this.searchText) {
          return false;
        }
        else {
          return !action.label.toLowerCase().includes(this.searchText.toLowerCase())
        }
      };

      this.executeAction = function($event, action) {
        if (self.selectedIds.length > 0 && !self.batchExecuting) {
          $('.dropdown-bulk-edit').click();
          self.batchExecuting = true;
          self.action = action;

          if (action.clientSideAction) {
            var actionFunction = executeClientSideAction[action.clientSideAction];

            if (typeof actionFunction === "function") {
              actionFunction(action.options, action.label);
            }
          }
          else {
            executeServerSideAction(action);
          }
        }
        $event.stopPropagation();
      };
    }]
});

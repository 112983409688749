angular.module('vastdesk').
  component('accountSsbFillThrottleOverride',  {
    bindings: {
      ssbFillThrottleOverrideEnabled: '<',
      readOnly: '<'
    },
    template: require("../templates/accountSsbFillThrottleOverride.html"),
    controllerAs: '$accountSsbFillThrottleOverrideCtrl',
    controller: ['$rootScope', function($rootScope) {

      var self = this;

      $rootScope.$on('accountTypeChange', function(e, args) {
        if (_.contains(['2', '17'], args.accountTypeId)) {
          self.ssbFillThrottleOverrideEnabled = true;
        }
      });

    }]
});

import ReportMetricHelper from '../../modules/report_metric_helper.js';

angular.module('vastdesk')
.component('reportConditions', {
  bindings: {
    metricGroups: '=',
    reportParams: '=',
    disabledByDetectedReporting: '=',
    demandReport: '=',
    pcFilterReport: '=',
    vastErrorCodeReport: '=',
    audioReport: '=',
    bidDensityReport: '=',
    advertiserDomainReport: '=',
    partnerSegmentReport: '=',
    dspCityReport: '<',
    dspDomainReport: '<',
    dspInventoryReport: '<',
    dspSourceReport: '<',
    ortbReport: '=',
    ortbBuyer: '=',
    routerReport: '=',
    contentReport: '=',
    magniteSspReport: '=',
    freqReachReport: '<',
    postalCodeReport: '<'
  },
  template: require("../templates/reportConditions.html"),
  controllerAs: '$reportConditionsCtrl',
  controller: ['$scope', function($scope) {

    var self = this;
    var onInitLoad = $.Deferred();
    var bodySelector = $('body');
    var reportMetricHelper = new ReportMetricHelper();

    this.metrics = [];
    this.conditions = [];

    this.isDisabled = function(metric) {
      return reportMetricHelper.metricDisabled(self, metric);
    };

    this.metricUsedAsCondition = function(metric) {
      return !!_.findWhere(self.conditions, {metric: metric.value[0]});
    };

    this.metricOptionDisabled = function(selectedMetricValue, metric) {
      return self.metricUsedAsCondition(metric) && metric.value[0] !== selectedMetricValue;
    };

    this.stubbedNewMetric = {
      value: [''],
      condition: {
        max: false
      }
    };

    this.addCondition = function(metric) {
      self.conditions.unshift({
        metric: metric.value[0],
        max: !!(metric.condition && metric.condition.max)
      });

      setTimeout(function() {
        initializeConditionChosens();
        triggerAllConditionChosenUpdated();
      }, 0);
    };

    this.removeCondition = function(index) {
      if (index > -1) {
        self.conditions.splice(index, 1);
      }
    };

    this.placeholderFor = function(condition) {
      var metric = getMetricFromCondition(condition);

      if (metric) {
        return metric.condition.placeholder;
      }
    };

    this.hideGroup = (metricGroup) => {
      const firstMetric = Array.isArray(metricGroup[1]) ? metricGroup[1][0] : {};

      if (firstMetric && firstMetric.group) {
        return _.every(this.metrics, (metric) => {
          return metric.group !== firstMetric.group || this.isDisabled(metric);
        });
      } else {
        return true;
      }
    };

    var getMetricFromCondition = function(condition) {
      if (!condition) {
        return;
      }

      return _.find(self.metrics, function(metric) {
        return metric.value[0] == condition.metric;
      });
    };

    var invalidAdRequest = function(metric, index) {
      if (metric.value[0] !== 'ad_requests') {
        return;
      }

      var label = $('#conditions_' + index + ' option:selected').text().trim();
      return (label === 'Total Calls' && self.demandReport) || (label === 'Ad Reqs' && !self.demandReport);
    };

    var invalidOpportunities = function(metric, index) {
      if (metric.value[0] !== 'opportunities') {
        return;
      }

      var label = $('#conditions_' + index + ' option:selected').text().trim();
      return (label === 'Opps' && self.demandReport) || (label === 'Wins' && !self.demandReport);
    };

    var invalidOpportunityRate = function(metric, index) {
      if (metric.value[0] !== 'opportunity_rate') {
        return;
      }

      var label = $('#conditions_' + index + ' option:selected').text().trim();
      return (label === 'Opp %' && self.demandReport) || (label === 'Win %' && !self.demandReport);
    };

    var invalidOpportunityFillRate = function(metric, index) {
      if (metric.value[0] !== 'opportunity_fill_rate') {
        return;
      }

      var label = $('#conditions_' + index + ' option:selected').text().trim();
      return (label === 'Opp Fill %' && self.demandReport) || (label === 'Win Fill %' && !self.demandReport);
    };

    var removeInvalidConditions = function() {
      var indicesToRemove = [];

      _.each(self.conditions, function(condition, index) {
        var metric = getMetricFromCondition(condition);

        if (condition.metric === '' || (metric && (self.isDisabled(metric) || invalidAdRequest(metric, index) || invalidOpportunities(metric, index) || invalidOpportunityRate(metric, index) || invalidOpportunityFillRate(metric, index)) ) ) {
          indicesToRemove.push(index);
        }
      });

      for (var i = indicesToRemove.length - 1; i >= 0; i--) {
        self.removeCondition(indicesToRemove[i]);
      }
    };

    $scope.$on('reloadConditions', function(e, args) {
      $.when( onInitLoad ).done(function() {
        self.reportParams = args;
        setConditionsFromParams();
        triggerAllConditionChosenUpdated();
      });
    });

    $scope.$on('settingReportType', function(e, args) {
      $.when( onInitLoad ).done(function() {
        setTimeout(function() {
          removeInvalidConditions();
        }, 0);
      });
    });

    bodySelector.on('change', '.condition-chosen-select', function() {
      triggerAllConditionChosenUpdated();
    });

    var initializeConditionChosens = function() {
      $('.condition-chosen-select').defaultChosen();
    };

    var triggerAllConditionChosenUpdated = function() {
      $('.condition-chosen-select').trigger('chosen:updated');
    };

    var setConditionsFromParams = function() {
      self.conditions = _.map(self.reportParams.having || {}, function(v,k) {
        if (v["max"]) {
          v["max"] = (v["max"] === 'true') ? true : false;
        }

        return v;
      });
    };

    var initializeConditions = function() {
      self.metrics = _.chain(self.metricGroups)
                      .map(function(metricGroups) {
                        return metricGroups[1];
                      })
                      .flatten()
                      .value();

      setConditionsFromParams();
    };

    this.$onInit = function() {
      initializeConditions();
      onInitLoad.resolve();
    };

  }]
});

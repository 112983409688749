import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('campaignPartnerTierOverride',  {
    bindings: {
      formName: '@',
      formId: '@',
      partnerTierOverrideEnabled: '<',
      partnerTierOverride: '@',
      tierOptions: '<'
    },
    template: require("../templates/campaignPartnerTierOverride.html"),
    controllerAs: '$campaignPartnerTierOverrideCtrl',
    controller: ['$rootScope', function($rootScope) {

      var self = this;

      this.attributeEnabledMappings = componentHelper.attributeEnabledMappings();

      this.setPartnerTierOverrideEnabled = function(val) {
        self.partnerTierOverrideEnabled = val;
        self.broadcastPartnerTierFieldsChange();
      };

      this.broadcastPartnerTierFieldsChange = function() {
        $rootScope.$broadcast('partnerTierFieldsChange', {
          partnerTierOverrideEnabled: self.partnerTierOverrideEnabled,
          partnerTierOverride: self.partnerTierOverride
        });
      };

    }]
});

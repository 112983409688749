angular.module('vastdesk').
  component('accountSupplyTrafficExclusions',  {
    bindings: {
      allowSupplyTrafficExclusions: '<',
      readOnly: '<'
    },
    template: '<form-check-box-tag field-id="account_allow_supply_traffic_exclusions" field-name="account[allow_supply_traffic_exclusions]" model="$accountSupplyTrafficExclusionsCtrl.allowSupplyTrafficExclusions" label-display="Supply Tag Traffic Exclusions" read-only="$accountSupplyTrafficExclusionsCtrl.readOnly"></form-check-box-tag>',
    controllerAs: '$accountSupplyTrafficExclusionsCtrl',
    controller: ['$rootScope', function($rootScope) {

      var self = this;

      $rootScope.$on('openrtbEnabledChange', function(e, args) {
        self.allowSupplyTrafficExclusions = args.openrtbEnabled;
      });

    }]
});

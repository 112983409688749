angular.module('vastdesk')
.component('imageUploader', {
  bindings: {
    formName: '@',
    fieldLabel: '@',
    fieldName: '@',
    tooltipText: '@',
    imageUrl: '@',
    showImage: '<',
    width: '@',
    height: '@',
    required: '<',
    showImageBackground: '<'
  },
  template: require("../templates/imageUploader.html"),
  controllerAs: '$imageUploaderCtrl',
  controller: ['$scope', '$element', function($scope, $element) {
    var self = this;
    this.fileSelected = 'No file chosen';

    this.destroy = function() {
      this.showImage = false;
      this.destroyImage = true;
    };

    $element.find('input[type="file"]').on('change', function() {
      self.fileSelected = "File selected!";
      self.destroyImage = false;
      $scope.$apply();
    });
  }]
});

angular.module('vastdesk').
  component('fallbackSupplyModal',  {
    bindings: {
      supplyPartnerId: '<',
      format: '@',
      environment: '@',
      searchUrl: '@'
    },
    template: require("../templates/fallbackSupplyModal.html"),
    controllerAs: '$fallbackSupplyModalCtrl',
    controller: ['$scope', '$rootScope', '$timeout', function($scope, $rootScope, $timeout) {

      var self = this;
      var bodySelector = $('body');
      var fallbackModal = $('#fallback_supply_modal');

      var clearFallbackSupplyVariables = function() {
        self.fallbackSupplyTagId = '';
        self.fallbackSupplyTagDisplayName = '';
        self.fallbackParentSupplyTagId = '';
        self.fallbackSupplyTagParentTitle = '';
      };

      fallbackModal.on('show.bs.modal', function(event) {
        var relatedTarget = $(event.relatedTarget);
        var id = relatedTarget.data('fallbackSupplyTagId');
        var name = relatedTarget.data('fallbackSupplyTagNameWithId');
        var parentId = relatedTarget.data('supplyTagId');
        var parentName = relatedTarget.data('supplyTagNameWithId');

        if (id && name) {
          self.fallbackSupplyTagId = id;
          self.fallbackSupplyTagDisplayName = name;
        }
        else {
          clearFallbackSupplyVariables();
        }

        self.fallbackSupplyTagParentTitle = parentName ? 'Set ' + parentName + ' ' : '';
        self.fallbackParentSupplyTagId = parentId;

        $scope.$apply();
      });

      bodySelector.on('click', '#fallback_supply_modal .btn-ss-submit', function() {
        $('#fallback_supply_modal').modal('hide');

        var fallbackSupplyTagId = (self.fallbackSupplyTagId && self.fallbackSupplyTagId !== '') ? parseInt(self.fallbackSupplyTagId) : null;

        $rootScope.$broadcast('fallbackSupplyTagIdChange', {
          fallbackParentSupplyTagId: self.fallbackParentSupplyTagId,
          fallbackSupplyTagId: fallbackSupplyTagId
        });

        $timeout(function() {
          $scope.$apply();
        });
      });

      $rootScope.$on('formatChanged', function(e, args) {
        self.format = args.format;
      });

      $rootScope.$on('environmentChange', function(e, args) {
        self.environment = args.environment;
      });

      $rootScope.$on('supplyPartnerIdChange', function(e, args) {
        self.supplyPartnerId = args.supplyPartnerId;
      });

    }]
});

import platformUtilities from './platform_utilities.js';

class WaterfallHelper {
  WARNING_COLOR = '#F2DEDE';
  allTiers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 100];

  addDateRangeSelect(options = {}) {
    var section = options.section || '#associated_tag_section';
    var dateRange = $('<select></select>').defaultDateRangeSelect();
    var dateFilter = $('<div class="date-filter"></div>').append(dateRange);
    $(section + ' .top > .date-group').append(dateFilter);
    $(section + ' #date_range').chosen({disable_search: true, width: '100%'});
    $('[data-toggle="tooltip"]').tooltip({container: 'body', boundary: 'viewport'});

    if (options.forecast) {
      this.addForecastDateRangeSelect(section);
    }
  }

  dateRangeize(element, options = {}) {
    element.defaultDateRangeSelect().chosen({disable_search: true, width: "100%"});

    if (options.changeCb) {
      element.on('change', () => {
        options.changeCb();
      });
    }
  }

  addForecastDateRangeSelect(section) {
    var forecastDateFilter = $('<div></div>').defaultForecastFilter();
    forecastDateFilter.insertAfter($(section + ' .top > .date-group'));
    $(section + ' #forecast_date_range').chosen({disable_search: true, width: '100%'});
  }

  getHeaderMetricFromTh(th) {
    return _.chain($(th).attr('class').split(' '))
            .filter(function(headerClass) {
              return /^report-/.test(headerClass);
            })
            .map(function(headerClass) {
              return headerClass.replace(/report-/g, '').replace(/-/g, '_');
            })
            .value();
  }

  getReportMetricFromHeaders(serverSideTable, serverSideDataTable) {
    var reportMetric = '';
    var self = this;

    if (serverSideDataTable) {
      var indices = _.map(serverSideDataTable.order(), function(d) { return d[0]; });

      _.each(indices, function(i) {
        var th = serverSideTable.find('thead:first th').eq(i);
        var headerMetrics = self.getHeaderMetricFromTh(th);
        if (headerMetrics.length > 0) {
          reportMetric = headerMetrics[0];
        }
      });
    }

    return reportMetric;
  }

  extractMetricFromReportString(reportRow, metric) {
    var metricString = reportRow[metric] || '';
    var match = metricString.match(/data-raw-value=\"(.*?)\"/) || metricString.match(/<span class="metric-span">(.*?)<\/span>/);
    if (match) {
      metricString = match[1];
    }
    return metricString.replace(/[^0-9.-]/g, '');
  }

  drawServerSideTable(serverSideDataTable, goToFirstPage, options = {}) {
    if (!serverSideDataTable) {
      return;
    }

    if (options.page) {
      serverSideDataTable.page(options.page);
    }
    serverSideDataTable.draw(goToFirstPage);
  }

  rowForTableElement(element) {
    return $(element).parents('tr');
  }

  setModalCheckboxes(modalTable, ids) {
    _.each(ids, function(id) {
      var checkbox = modalTable.find("#checkbox_" + id);
      checkbox.attr("disabled", true).prop("checked", true);
    });
  }

  isLastPage(pageInfo) {
    return pageInfo && pageInfo.page !== 0 && pageInfo.page === (pageInfo.pages - 1);
  }

  numTagsCurrentPage(pageInfo) {
    if (this.isLastPage(pageInfo)) {
      return pageInfo.recordsDisplay - (pageInfo.length * (pageInfo.pages - 1));
    }
    return pageInfo.length;
  }

  drawToPreviousPage(pageInfo, tagsRemoved) {
    return pageInfo &&
      this.isLastPage(pageInfo) &&
      tagsRemoved >= this.numTagsCurrentPage(pageInfo) &&
      tagsRemoved < (this.numTagsCurrentPage(pageInfo) + pageInfo.length);
  }

  drawToSamePage(pageInfo, tagsRemoved) {
    return pageInfo && tagsRemoved < this.numTagsCurrentPage(pageInfo);
  }

  drawServerSideTableAfterRemoval(serverSideDataTable, tagsRemoved) {
    var pageInfo;

    if (serverSideDataTable && serverSideDataTable.page) {
      pageInfo = serverSideDataTable.page.info();
    }

    if (this.drawToPreviousPage(pageInfo, tagsRemoved)) {
      this.drawServerSideTable(serverSideDataTable, false, {page: 'previous'});
    }
    else if (this.drawToSamePage(pageInfo, tagsRemoved)) {
      this.drawServerSideTable(serverSideDataTable, false);
    }
    else {
      this.drawServerSideTable(serverSideDataTable, true);
    }
  }

  setRowSpans(table) {
    var rowSpan = 1;
    var firstInTier;
    var currentTier;
    var rows = $(table).find('tbody tr');
    var rowsLength = rows.length;
    var self = this;

    _.each(rows, function(tr, i) {
      var row = $(tr);
      var tierNameValue = self.tierNameValueFor(row);

      if (currentTier === undefined) {
        currentTier = tierNameValue;
        firstInTier = row;
      }
      else {
        if (currentTier === tierNameValue) {
          rowSpan += 1;
        }
        else {
          firstInTier.find('td.tier_name').attr('rowspan', rowSpan);
          currentTier = tierNameValue;
          firstInTier = row;
          rowSpan = 1;
        }
      }

      if (i === (rowsLength -1)) {
        firstInTier.find('td.tier_name').attr('rowspan', rowSpan);
        rows.find('td.tier_name:not([rowspan])').addClass('hidden');
      }
    });
  }

  tierValueFor(row) {
    return $(row).data('t');
  }

  tierNameValueFor(row) {
    return $(row).find('.tier_name').text();
  }

  filterPartners(serverSideTable, tableLoaded, idsInWaterfall) {
    if (tableLoaded && idsInWaterfall) {
      var partnerFilter = serverSideTable.parents('.dataTables_wrapper').find('#dfilter_partner');
      var currentSelectValue = $(partnerFilter).val();

      $(partnerFilter).find('option').filter(function() {
        var optionValue = $(this).val();
        return optionValue === '' || _.contains(idsInWaterfall, optionValue);
      }).attr('disabled', false).removeClass('hidden');

      $(partnerFilter).find('option').filter(function() {
        var optionValue = $(this).val();
        return optionValue !== '' && !_.contains(idsInWaterfall, optionValue);
      }).attr('disabled', true).addClass('hidden');

      if (currentSelectValue !== '' && !_.contains(idsInWaterfall, currentSelectValue)) {
        $(partnerFilter).val('').trigger('change');
      }

      $(partnerFilter).trigger("chosen:updated");
    }
  }

  setResponseTimeValue(s, value) {
    return (s || '').replace(/<span class="response-time-value">.*<\/span>/, '<span class="response-time-value">' + value + '</span>');
  }

  addReportDataToRow(r, reportRow, options = {}) {
    var currencyPlaceholder = options.currencyPlaceholder || '$0.00';

    r['ad_requests'] = reportRow.ad_requests || 0;
    r['fill_rate'] = reportRow.fill_percentage || '0.00%';
    r['timeout_rate'] = reportRow.timeout_rate || '0.00%';
    r['has_ads'] = reportRow.has_ads || 0;
    r['opportunities'] = reportRow.opportunities || 0;
    r['missed_opportunities'] = reportRow.missed_opportunities || 0;
    r['impressions'] = reportRow.impressions || 0;
    r['fourth_quartile'] = reportRow.fourth_quartile || 0;
    r['timeouts'] = reportRow.timeouts || 0;
    r['clicks'] = reportRow.clicks || 0;
    r['opportunity_rate'] = reportRow.opportunity_percentage || '0.00%';
    r['ad_rate'] = reportRow.ad_percentage || '0.00%';
    r['use_rate'] = reportRow.use_rate || '0.00%';
    r['fourth_quartile_rate'] = reportRow.completion_rate || '0.00%';
    r['click_through_rate'] = reportRow.click_through_rate || '0.00%';
    r['efficiency_rate'] = reportRow.efficiency_rate || '0.00%';
    r['opportunity_fill_rate'] = reportRow.opportunity_fill_percentage || '0.00%';
    r['score'] = reportRow.score || '0.0';
    r['rpm'] = reportRow.rpm || currencyPlaceholder;
    r['rpmr'] = reportRow.rpmr || currencyPlaceholder;
    r['cpm'] = reportRow.cpm || currencyPlaceholder;
    r['ppm'] = reportRow.ppm || currencyPlaceholder;
    r['revenue'] = reportRow.revenue || currencyPlaceholder;
    r['cost'] = reportRow.cost || currencyPlaceholder;
    r['profit'] = reportRow.profit || currencyPlaceholder;
    r['margin'] = reportRow.margin || '0.00%';
    r['response_time'] = this.setResponseTimeValue(r['response_time'], reportRow.response_time || '0.00');
    r['vpaid_window'] = reportRow.vpaid_window || '0.00';
    r['ap_slots_seconds_available'] = reportRow.ap_slots_seconds_available || '0';
    r['ap_slots_seconds_filled'] = reportRow.ap_slots_seconds_filled || '0';
    r['ap_demand_opportunity_seconds'] = reportRow.ap_demand_opportunity_seconds || '0';
    r['ad_pod_fill_rate'] = reportRow.ad_pod_fill_rate || '0.00%';
    r['router_usable_requests'] = reportRow.router_usable_requests || '0';
    r['router_request_fill_rate'] = reportRow.router_request_fill_rate || '0.00%';
  }

  valueForElement(element, attribute) {
    if (_.contains(['tier', 'priority', 'ratio', 'allocation'], attribute)) {
      var value = $(element).val();
      return this.parseIntOrNull(value);
    }
    else if (attribute === 'locked') {
      return $(element).prop('checked');
    }
  }

  getReportRowDataFor(reportRow, sortMetric, dimension) {
    var data = {};
    data[dimension] = reportRow[dimension];

    if (dimension === 'supply_tag_id') {
      data.sort_metric = this.extractMetricFromReportString(reportRow, sortMetric);
    }
    else if (_.contains(['demand_tag_id', 'creative_id'], dimension)) {
      data.rpm = this.extractMetricFromReportString(reportRow, 'rpm');

      if (sortMetric && !_.contains(['', 'rpm'], sortMetric)) {
        data.sort_metric = this.extractMetricFromReportString(reportRow, sortMetric);
      }
    }

    if (reportRow.slot_order != null) {
      data.slot_order = reportRow.slot_order;
    }
    if (reportRow.slot_number != null) {
      data.slot_number = reportRow.slot_number;
    }

    return data;
  }

  getReportDataForDraw(serverSideTable, serverSideDataTable, reportData, dimension) {
    var sortMetric = this.getReportMetricFromHeaders(serverSideTable, serverSideDataTable);
    var self = this;

    if (!sortMetric && dimension === 'supply_tag_id') {
      return [];
    }

    return _.chain(reportData)
            .map(function(reportRow) {
              return self.getReportRowDataFor(reportRow, sortMetric, dimension);
            })
            .value();
  }

  getSupplyTagReportDataForDraw(serverSideTable, serverSideDataTable, reportData) {
    return this.getReportDataForDraw(serverSideTable, serverSideDataTable, reportData, 'supply_tag_id');
  }

  getDemandTagReportDataForDraw(serverSideTable, serverSideDataTable, reportData) {
    return this.getReportDataForDraw(serverSideTable, serverSideDataTable, reportData, 'demand_tag_id');
  }

  attachTableData(data, fields) {
    _.each(fields, function(v, k) {
      data[k] = v;
    });
  }

  syncDateRange(source, target, options = {}) {
    var sourceVal = source.val();

    if (sourceVal && sourceVal !== '' && sourceVal !== target.val()) {
      target.val(sourceVal).trigger('chosen:updated');

      if (options.triggerChange) {
        target.trigger('change');
      }
    }
  }

  extractIdsFromSetList(list, idLabel) {
    return _.chain(list)
            .pluck(idLabel)
            .map(function(id) {
              return parseInt(id);
            })
            .value();
  }

  abortPendingAjaxRequests(serverSideTable, serverSideDataTable) {
    if (serverSideDataTable) {
      serverSideDataTable.settings()[0].jqXHR.abort();
      platformUtilities.triggerDataTablesProcessing(serverSideTable, true);
    }
  }

  parseIntOrNull(val) {
    var value = parseInt(val);

    if (isNaN(value)) {
      value = null;
    }

    return value;
  }

  removeIds(scope, attr, idsToRemove, serverSideDataTable, cb) {
    var startingLength = scope[attr].length;

    scope[attr] = _.reject(scope[attr], function(id) {
      return _.contains(idsToRemove, parseInt(id));
    });

    var endingLength = scope[attr].length;
    var tagsRemoved = startingLength - endingLength;

    if (tagsRemoved < 1) {
      return;
    }

    if (cb) {
      cb();
    }

    this.drawServerSideTableAfterRemoval(serverSideDataTable, tagsRemoved);
  }

  addIds(scope, attr, idsToAdd, serverSideDataTable) {
    if (!idsToAdd) {
      return;
    }
    idsToAdd = _.chain(idsToAdd).map(function(id) {
      return parseInt(id);
    }).compact().value();

    scope[attr] = _.uniq(scope[attr].concat(idsToAdd));

    if (scope.$apply) {
      scope.$apply();
    }

    this.drawServerSideTable(serverSideDataTable, false);
  }

  disableAllSlotsFields(serverSideDataTable, allSlots) {
    var allSlotsBool = !!allSlots;
    $(serverSideDataTable).find('th .checkbox-custom-label').attr('disabled', allSlotsBool);
    $(serverSideDataTable).find('th .remove-all').toggleClass('hidden', allSlotsBool);
    $('#tag_results .actions-wrapper .dropdown-toggle.add-tag-toggle, #campaign_results .actions-wrapper .dropdown-toggle.add-tag-toggle').attr('disabled', allSlotsBool);
  }

  reportRowMatchConditions(base, slotPositioningOrder, slotPositioningNumber, tableRow) {
    var slotConditions = {};

    if (slotPositioningOrder) {
      slotConditions["slot_order"] = tableRow.DT_RowAttr['data-so'];
    }
    else if (slotPositioningNumber) {
      slotConditions["slot_number"] = parseInt(tableRow.DT_RowAttr['data-sn']);
    }

    return _.extend({}, base, slotConditions);
  }

  slotTitle(self) {
    if (!!self.slotOrder) {
      return platformUtilities.titleize(self.slotOrder) + ' Slot - ';
    }
    else if (!!self.slotNumber) {
      return 'Slot ' + self.slotNumber + ' - ';
    }
  }

  clearChosenSelect(self, attr, select_id) {
    self[attr] = '';

    setTimeout(function() {
      $(select_id).trigger('chosen:updated');
    }, 0);
  }

  setDemandTagPriorityUniqIdFor(setDemandTagPriority, idLabel) {
    return setDemandTagPriority[idLabel] + '-' + setDemandTagPriority.slot_order + '-' + setDemandTagPriority.slot_number;
  }

  isDefaultSlotOrder(so) {
    return _.contains(['', 'n/a'], so || '');
  }

  isDefaultSlotNumber(sn) {
    return (parseInt(sn) || 0) === 0;
  }

  slotOrderMatch(so1, so2) {
    return (so1 === so2) ||
      (this.isDefaultSlotOrder(so1) && this.isDefaultSlotOrder(so2));
  }

  slotNumberMatch(sn1, sn2) {
    return ( parseInt(sn1) === parseInt(sn2) ) ||
      (this.isDefaultSlotNumber(sn1) && this.isDefaultSlotNumber(sn2));
  }

  inSlot(wf, so, sn) {
    return this.slotOrderMatch(wf.slot_order, so) &&
      this.slotNumberMatch(wf.slot_number, sn);
  }

  objectFormat() {
    return $('input[name*="[format]"]:checked').val() || 'video';
  }
}

export default WaterfallHelper;

import platformUtilities from '../../modules/platform_utilities.js';
import dataTableStickyTableHeaders from '../../modules/data_table_sticky_table_headers.js';

angular.module('vastdesk').
  controller('ReportFormFlexibleController', ['$scope', '$rootScope', '$timeout', function($scope, $rootScope, $timeout) {
    var reportRetries = 0;
    var demandFilters = [];
    var ortbFilters = [];
    var vastErrorCodeFilters = [];
    var audioFilters = [];
    var routerFilters = [];
    var csvReady = false;
    var scheduledReportCancelled = false;
    var bodyElement = $('body');
    var reportForm = $("#report_form");

    var reportFormJson = function() {
      var serialized = $('#report_form').serializeJSON();

      if (serialized && Array.isArray(serialized.exclude)) {
        serialized.exclude = _.compact(serialized.exclude);
      }

      return serialized;
    };

    $scope.filterJson = {};
    $scope.enterList = {};
    $scope.filters = [];

    bodyElement.on('change input', '.report-filter-element', function() {
      handleDimensionOrFilterChange();
      $scope.$apply();
    });

    reportForm.submit(function() {
      pollForHtmlReport(true);
      return false;
    });

    $("#export_link").click(function() {
      return exportCsv();
    });

    $("#export_link").on('mousedown', function() {
      $(this).attr("href", "/reports.csv?" + reportForm.serialize());
    });

    $('#schedule_report_link').on('mousedown', function() {
      $(this).attr("href", "/reports/scheduled_reports/new?" + reportForm.serialize());
    });

    $('#report_template_link').on('mousedown', function() {
      $(this).attr("href", "/reports/templates/new?" + reportForm.serialize());
    });

    $("#download-now").click(function() {
      scheduledReportCancelled = false;
      return exportCsv();
    });

    var exportCsv = function() {
      if (csvReady) {
        csvReady = false;
        return true;
      } else {
        if ($scope.scheduledReport) {
          pollForScheduledReportCsv(true);
        }
        else {
          pollForCsvReport(true);
        }
        return false;
      }
    };

    $scope.setEnterList = function(k, value) {
      $scope.enterList[k] = !!value;

      $timeout(function() {
        handleDimensionOrFilterChange();
      }, 0);
    };

    $scope.setVastErrorCodeFilters = function(filters) {
      vastErrorCodeFilters = filters;
    };

    $scope.setAudioFilters = function(filters) {
      audioFilters = filters;
    };

    $scope.setDemandFilters = function(filters) {
      demandFilters = filters;
    };

    $scope.setOrtbFilters = function(filters) {
      ortbFilters = filters;
      setBidDensityReport();
    };

    $scope.setRouterFilters = function(filters) {
      routerFilters = filters;
    };

    $('#report_template').on('change', function() {
      setTemplateParams();
    });

    var setTemplateParams = function() {
      var templateParams = $('#report_template').find(':selected').data();
      var underscoreTemplateParams = {};
      if (Object.keys(templateParams).length > 0) {
        _.each(templateParams, function(v, k) {
          underscoreTemplateParams[k.toUnderscore()] = v;
        });
      }

      setTemplateTimeParams(templateParams);
      setTemplateDimensions(templateParams);
      $scope.reloadFilters(underscoreTemplateParams);
      $scope.$broadcast('reloadConditions', templateParams);
      $scope.$broadcast('setReportMetrics', {
        metrics: templateParams.metrics
      });
      $rootScope.$broadcast('setReportCurrency', {
        currency: templateParams.currency || 'USD'
      });
    };

    $scope.reloadFilters = function(templateParams) {
      $('#initial_filters').remove();

      $.post('/reports/filters', templateParams, function(resp) {
        $scope.filterJson.content = resp;
        $scope.$apply();

        $timeout(function() {
          $('.filter-chosen-select').defaultChosen();

          // hacky workaround for filter toggles after template load
          $timeout(function() {
            $('.enter-list-init').each(function(i, enterListDiv) {
              if ($(this).data('listName')) {
                $scope.setEnterList($(this).data('listName'), $(this).data('listValue'));
              }
            });
          }, 0);
        }, 0);
      });
    };

    var setTemplateTimeParams = function(templateParams) {
      $scope.report.interval = templateParams.interval;
      setReportDateRange(templateParams.dateRange || 'Today');
      $scope.report.customDateRange = templateParams.customDateRange || '';
      $scope.report.interval_start_day = templateParams.intervalStartDay || '';
      $scope.report.timezone = templateParams.timezone || 'America/New_York';

      $('#interval').val($scope.report.interval).trigger('chosen:updated');
      $('#date_range').val($scope.report.dateRange).trigger('chosen:updated');
      $('#custom_date_range').val($scope.report.customDateRange).trigger('chosen:updated');
      $('#interval_start_day').val($scope.report.intervalStartDay).trigger('chosen:updated');
      $('#timezone').val($scope.report.timezone).trigger('chosen:updated');
    };

    var setTemplateDimensions = function(templateParams) {
      $scope.report.dimensions = templateParams.dimensions || [];
      $('#dimensions').val($scope.report.dimensions).trigger('chosen:updated');
    };

    var filterIsInitiallyInView = function(filterKey, reportParams) {
      return (filterKey.includes("key:") && reportParams["key_values"] && reportParams["key_values"][filterKey.replace("key:", "")]) ||
        (filterKey == 'declared_domain' && reportParams['declared_domain_list_ids']) ||
        (filterKey == 'detected_domain' && reportParams['detected_domain_list_ids']) ||
        (filterKey == 'adomain' && reportParams['advertiser_domain_list_ids']) ||
        (filterKey == 'detected_adomain' && reportParams['detected_advertiser_domain_list_ids']) ||
        (filterKey == 'app_name' && reportParams['app_name_list_ids']) ||
        (filterKey == 'app_bundle' && reportParams['app_bundle_list_ids']) ||
        (reportParams[filterKey]);
    };

    $scope.initialize = function(reportParams, filtersAllowlist) {
      $scope.allowlistedFilters = filtersAllowlist;

      var filtersInitiallyInView = [];
      var filtersInitiallyInQuickAdd = [];
      $scope.quickAddFilters = [];

      _.each($scope.allowlistedFilters, function(filterKey) {
        var filter;

        if ($scope.filterAllowlistMappings[filterKey]) {
          filter = _.clone($scope.filterAllowlistMappings[filterKey]);
        }

        if (filter != null) {
          if (filter.quick_add && !filter.in_view && !reportParams[filterKey]) {
            filtersInitiallyInQuickAdd.push(filter.name);
          }

          if (filterIsInitiallyInView(filterKey, reportParams)) {
            filter.in_view = true;
          }

          if (filter.in_view) {
            filtersInitiallyInView.push(filter);
          }
        }
      });

      $scope.quickAddFilters = filtersInitiallyInQuickAdd.sort();
      $scope.filters = sortInitiallyInView(filtersInitiallyInView);
      handleDimensionOrFilterChange();
    };

    var sortInitiallyInView = function(filters) {
      return _.sortBy(filters, function(filter) {
        return filter.initial_position;
      });
    };

    var triggerFiltersChosenUpdated = function() {
      setTimeout(function() {
        $('.filter-chosen-select').trigger('chosen:updated');
      }, 0);
    };

    $scope.refreshQuickAddFilters = function() {
      var unorderedQuickAddFilters = [];
      _.each($scope.filterAllowlistMappings, function(filter, filterKey) {
        if (filter.quick_add && !$scope.filterIsInView(filter.name) && $scope.allowlistedFilters.includes(filterKey)) {
          unorderedQuickAddFilters.push(filter.name);
        }
      });

      $scope.quickAddFilters = unorderedQuickAddFilters.sort();

      triggerFiltersChosenUpdated();
    };

    $scope.tomSelectize = function(targetClass) {
      setTimeout(function() {
        if ($(targetClass).length > 0) {
          $(targetClass).defaultTomSelect();
        }
      },0);
    };

    $scope.chosenize = function(targetClass) {
      setTimeout(function() {
        if ($(targetClass).length > 0) {
          $(targetClass).defaultChosen();
        }
      },0);
    };

    $scope.dynamicChosenize = function(targetClass) {
      setTimeout(function() {
        if ($(targetClass).length > 0) {
          $(targetClass).dynamicChosen();
        }
      },0);
    };

    $scope.addNewFilter = function(filterName) {
      $scope.filters.unshift({
        name: filterName,
        in_view: true
      });

      $scope.refreshQuickAddFilters();

      setTimeout(function() {
        chosenizeNewFilter();
      }, 0);
    };

    var chosenizeNewFilter = function() {
      $("#filters_0").defaultChosen();
    };

    $scope.dropFilter = function(filterIndex, filter) {
      $scope.shiftFiltersForward(filterIndex);
      $scope.refreshQuickAddFilters();

      $timeout(function() {
        handleDimensionOrFilterChange();
      }, 0);
    };

    $scope.shiftFiltersForward = function(filterIndex) {
      $scope.filters.splice(filterIndex, 1);
    };

    $scope.filterIsInView = function(filterName) {
      return !!_.find($scope.filters, function(filter) {
        return filter.name === filterName && filter.in_view;
      });
    };

    var pollForScheduledReportCsv = function(isFirstSubmission, reportId) {
      if (isFirstSubmission) {
        $("#spinner").show();
      }

      var formUrl = $("#download-now").data('pollUrl');

      $.post(formUrl, {csv_report_id: reportId}, function(data) {
        if (data.complete === true || data.complete === "true") {
          addCsvIdToExportLink(data.csv_report_id, "#download-now");
          csvReady = true;
          $("#download-now").click();
          $("#spinner").hide();
          csvReady = false;
          reportRetries = 0;
        }
        else if (data.status === 'failed') {
          failReport();
        }
        else {
          if (reportRetries < 600 && !scheduledReportCancelled) { //5 minutes
            setTimeout( function () {
              reportRetries += 1;
              console.log("RETRYING REPORT", reportRetries);
              pollForScheduledReportCsv(false, data.csv_report_id);
            }, 500);
          } else {
            failReport({
              timeout: true,
              reportType: 'csv'
            });
          }
        }
      }).fail(function() {
        failReport();
      });
    };

    function pollForCsvReport(isFirstSubmission, reportId) {
      pollForReport(isFirstSubmission, reportId, true);
    }

    function pollForHtmlReport(isFirstSubmission, reportId) {
      pollForReport(isFirstSubmission, reportId, false);
    }

    function pollForReport(isFirstSubmission, reportId, isCsv) {
      var formUrl = reportForm.attr("action");

      var formArrayWithDuplicates = reportForm.serializeArray();

      var cleanFormArray = _.filter(formArrayWithDuplicates, function(formEntry) {
        return !!formEntry.value;
      });

      if (isCsv) {
        cleanFormArray.push({name: "csv", value: true});
      }

      var formData = $.param(cleanFormArray);

      if (isFirstSubmission) {
        setupReport(formData, formUrl);
      }

      var serviceRequest = "";
      //retries should only be during report service requests. add this to ensure
      //that the next try doesn't get switched by chance to a non-service report
      if (!isFirstSubmission && formData && !/report_service=true/.test(formData)) {
        serviceRequest = "&report_service=true";
      }

      var reportIdKey = "report_id";

      if (isCsv) {
        reportIdKey = "csv_report_id";
      }

      if (reportId !== null && reportId !== undefined) {
        serviceRequest += ("&" + reportIdKey + "=" + reportId);
      }

      $.post(formUrl, (formData + serviceRequest), function(data) {
        if (isCsv) {
          csvSuccessReport(formData, data);
        } else {
          successReport(formData, data);
        }
      }).fail(function() {
        failReport();
      });
    }

    $scope.$watch("report", function(oldVal, newVal) {
      $scope.setReportType();
    }, true);

    function setupReport(formData, formUrl) {
      $("#spinner").show();
      $scope.lastFormData = formData;
      mixpanel.track("report run", reportFormJson());
      var url = formUrl +"?" + formData;
      history.replaceState({}, '', url);
      $scope.report.startTime = Date.now();
    }

    function initDemandCodeModal() {
      $('.report-table').find('.demand-code-wrapper i').on('click', function() {
        var demandCodeText = $(this).parent().text();
        $('#report_demand_code_modal').find('.demand_code_value').text(demandCodeText);
        $('#report_demand_code_modal').modal();
      });
    }

    function addReportTableEventListeners() {
      $('.report-table').on('processing.dt', function(e, settings, processing) {
        if (!processing) {
          initDemandCodeModal();
        }
      });
      $('.report-table').on('draw.dt', function() {
        initDemandCodeModal();
        platformUtilities.initSvgFontAwesomeIcons();
      });
    }

    function addCsvIdToExportLink(reportId, selector) {
      var ref = $(selector)[0].href;
      var csvReportIdMatcher = /&csv_report_id=\d+/;
      var blankCsvReportIdMatcher = /&csv_report_id=/;
      var newRef = ref.replace(csvReportIdMatcher, "").replace(blankCsvReportIdMatcher, "") + "&csv_report_id=" + reportId;
      $(selector)[0].href = newRef;
    }

    function requestCsv(csvReportId) {
      addCsvIdToExportLink(csvReportId, "#export_link");
      csvReady = true;
      $("#export_link").click();
      $("#spinner").hide();
      completeReport();
    }

    function intervalPoll(pollForReportCb, reportId, formData, reportType) {
      if (reportRetries < 600 || reportType === 'html') { //5 minutes
        if (reportRetries === 600) {
          reportErrorModal({
            reportType: reportType
          });
        }
        setTimeout( function () {
          if ($scope.lastFormData === formData) {
            reportRetries += 1;
            console.log("RETRYING REPORT", reportRetries, $scope.lastFormData);
            pollForReportCb(false, reportId);
          }
        }, 500);
      } else {
        failReport({
          timeout: true,
          reportType: reportType
        });
      }
    }

    function csvSuccessReport(formData, data) {
      if ($scope.lastFormData === formData) {
        if (data.complete === true || data.complete === "true") {
          requestCsv(data.csv_report_id);
        }
        else if (data.status === 'failed') {
          failReport();
        }
        else {
          intervalPoll(pollForCsvReport, data.csv_report_id, formData, 'csv');
        }
      }
    }

    function successReport(formData, data) {
      if ($scope.lastFormData === formData) {
        if (data.status === 'FAILED') {
          failReport();
        }
        else if (data.report_id && !data.account_summary) {
          intervalPoll(pollForHtmlReport, data.report_id, formData, 'html');
        }
        else {
          $scope.$broadcast('setReportMetrics', {
            metrics: data.metrics
          });
          $scope.lastFormData = null;
          reportRetries = 0;
          $("#results").replaceWith(data.results);

          $("#results .datatable").defaultDataTable( {
            "processing": true,
            "serverSide": true,
            "deferLoading": data.row_count,
            "ajax": {
              "url": "/reports?follow_up_request=true&report_id=" + data.report_id + "&"+ formData,
              "type": "POST"
            },
            initComplete: function() {
              dataTableStickyTableHeaders.sticky(this);
            }
          });

          platformUtilities.initSvgFontAwesomeIcons();

          addReportTableEventListeners();

          if (data.csv_report_form) {
            var modal;
            if (data.shared_report) {
              modal = $("#share_link_modal");
            } else {
              modal = $("#large_report_modal");
            }
            modal.find("form").replaceWith(data.csv_report_form);
            modal.modal();
          }

          $("#spinner").hide();
          completeReport();
        }
      }
    }

    var reportErrorModal = function(options) {
      var modalTitle = 'Report Error';
      var errorMessage = 'There was an unexpected error running this report. Please try again.';

      if (options.reportType === 'csv') {
        modalTitle = 'Report Running';
        errorMessage = 'This report is reading a large amount of data and will appear in downloads when complete.';
      }
      else if (options.reportType === 'html') {
        modalTitle = 'Report Running';
        errorMessage = 'This report is reading a large amount of data; it will continue to run if you stay on this page.';
      }

      var errorModal = $("#report_error_modal");
      errorModal.find('.modal-title').text(modalTitle);
      errorModal.find('#report-error-msg').text(errorMessage);
      errorModal.modal();
    };

    function failReport(options = {}) {
      $scope.lastFormData = null;
      reportErrorModal(options);
      if (options.timeout) {
        mixpanel.track("report timeout", reportFormJson());
      }
      else {
        mixpanel.track("report failed", reportFormJson());
      }
      reportRetries = 0;
      csvReady = false;
      $("#spinner").hide();
    }

    function completeReport() {
      initDemandCodeModal();
      csvReady = false;
      $scope.report.endTime = Date.now();
      reportRetries = 0;
      var json = reportFormJson();
      json.totalTimeMs = $scope.report.endTime - $scope.report.startTime;
      mixpanel.track("report completed", json);
    }

    $("#spinner .cancel-spinner").click(function() {
      $scope.lastFormData = null;
      scheduledReportCancelled = true;
      reportRetries = 0;
      $("#spinner").hide();
      mixpanel.track("report cancelled", reportFormJson());
      return false;
    });

    var handleDimensionOrFilterChange = function() {
      setDomainReportStatus();
      setSpecificKeyDimensionDisabled();
      setKeyValueDimensionDisabled();
      setKeyDimensionDisabled();
      setOrtbReport();
      $scope.setReportType();
    };

    $scope.$watchCollection('[report.dimensions]', function(newVal, oldVal) {
      handleDimensionOrFilterChange();
    });

    $scope.$watch('[report.dateRange, report.customDateRange, report.interval, report.timezone]', function(new_val, old_val) {
      setDomainReportingDisabled();
      setDisabledByPastDomainReporting();
      setSpecificKeyDimensionDisabled();
      setKeyValueDimensionDisabled();
      setKeyDimensionDisabled();
    });

    $("#dimensions option").on('disabledChanged', function() {
      $("#dimensions").trigger("chosen:updated");

      setTimeout(function() {
        $('.report-form-field select').trigger('chosen:updated');
      }, 0);
    });

    $scope.$watch('[domainReportingDisabled]', function(new_val, old_val) {
      if (!old_val[0] && new_val[0]) {
        clearDomainFields();
        setDisabledByDomainReporting();
        setDisabledByPastDomainReporting();
      }
      else if (old_val[0] && !new_val[0]) {
        setTomSelectEnabled('domainReportingDisabled', true);
      }
    });

    $scope.$watch('[magniteSspReport]', function(new_val, old_val) {
      if (!old_val[0] && new_val[0]) {
        clearFiltersWithDisabledLabel('magniteSspReport');
      }
      else if (old_val[0] && !new_val[0]) {
        setTomSelectEnabled('magniteSspReport', true);
      }
    });

    $scope.$watch('[partnerSegmentDisabled]', function(new_val, old_val) {
      if (!old_val[0] && new_val[0]) {
        clearFiltersWithDisabledLabel('partnerSegmentDisabled');
      }
      else if (old_val[0] && !new_val[0]) {
        setTomSelectEnabled('partnerSegmentDisabled', true);
      }
    });

    var dspCityDisabledDateRanges = ['Last Month', 'Quarter to Date', 'All Time'];

    var toggleDspCityIntervalVisibility = function(hidden) {
      _.each(dspCityDisabledDateRanges, function(dateRange) {
        $("#date_range option[value='" + dateRange +"']").toggle('hidden', hidden);
      });

      $('#date_range').trigger('chosen:updated');
    };

    $scope.$watch('[dspCityReport]', function(new_val, old_val) {
      if (!old_val[0] && new_val[0]) {
        clearFiltersWithDisabledLabel('dspCityReport');
        toggleDspCityIntervalVisibility(false);
      }
      else if (old_val[0] && !new_val[0]) {
        setTomSelectEnabled('dspCityReport', true);
        toggleDspCityIntervalVisibility(true);
      }
    });

    $scope.$watch('[dspDomainReport]', function(new_val, old_val) {
      if (!old_val[0] && new_val[0]) {
        clearFiltersWithDisabledLabel('dspDomainReport');
      }
      else if (old_val[0] && !new_val[0]) {
        setTomSelectEnabled('dspDomainReport', true);
      }
    });

    $scope.$watch('[dspInventoryReport]', function(new_val, old_val) {
      if (!old_val[0] && new_val[0]) {
        clearFiltersWithDisabledLabel('dspInventoryReport');
      }
      else if (old_val[0] && !new_val[0]) {
        setTomSelectEnabled('dspInventoryReport', true);
      }
    });

    $scope.$watch('[dspSourceReport]', function(new_val, old_val) {
      if (!old_val[0] && new_val[0]) {
        clearFiltersWithDisabledLabel('dspSourceReport');
      }
      else if (old_val[0] && !new_val[0]) {
        setTomSelectEnabled('dspSourceReport', true);
      }
    });

    $scope.$watch('[disabledByPastDomainReporting]', function(new_val, old_val) {
      if (new_val[0]) {
        clearDisabledByPastDomainFields();
      }
    });

    $scope.$watch('[disabledByDomainReporting]', function(new_val, old_val) {
      $(".report-select-wrapper select[ng-disabled*='disabledByDomainReporting']").trigger('chosen:updated');

      if (!old_val[0] && new_val[0]) {
        setDomainReportingDisabled();
      }
    });

    var setDisabledByPastDomainReporting = function() {
      $scope.disabledByPastDomainReporting = $scope.disabledByDomainReporting &&
        dateRangeStartsMoreThanTwoDaysAgo() &&
        !notUtcOrIsHourly();
    };

    var dimensionsSelector = function() {
      return $($("select[name='dimensions[]']").children());
    };

    var setSpecificKeyDimensionDisabled = function() {
      $scope.specificKeyDimensionDisabled = (hasDimension("key_values") || hasDimension("key"));
    };

    var setKeyValueDimensionDisabled = function() {
      $scope.keyValueDimensionDisabled = (hasCustomKeyDimension() || hasDimension("key"));
    };

    var setKeyDimensionDisabled = function() {
      $scope.keyDimensionDisabled = (hasCustomKeyDimension() || hasDimension("key_values"));
    };

    var setDisabledByDomainReporting = function() {
      $scope.disabledByDomainReporting = hasDisabledDimensionOrFilterLabel('domainReportingDisabled');

      setDatePicker();
    };

    var setDisabledByDetectedReporting = function() {
      $scope.disabledByDetectedReporting = dimensionsSelector().find("option[value*='detected']:selected").length > 0 ||
        !!_.find($(
          "#filters select[name=detected_domain], #filters input[name=detected_domain], #filters input[name='detected_player_sizes[]'], #filters select[name='detected_player_sizes[]']"
        ), function(input) { return platformUtilities.elementValPresent(input); });
    };

    $scope.$watch("ortbReport", function(newVal, oldVal) {
      if (newVal && !oldVal) {
        $scope.$broadcast('selectOpenrtbMetrics');
      }
    });

    $scope.advertiserDomainOrAppDimensionSelected = function() {
      if (hasDimension("detected_domain") || hasDimension("declared_domain") || hasDimension("app_name") || hasDimension("app_bundle") || filterIsPresent(["detected_domain", "declared_domain", "app_name", "app_bundle"])) {
        return true;
      }
    };

    var startsMoreThanDaysAgo = function(dateRange, days) {
      var moreThanDaysAgo = false;
      var startDateString = (dateRange || '').split(" - ")[0];
      var startDate = moment(startDateString, "MM/DD/YY HH");

      if (startDate.isValid()) {
        moreThanDaysAgo = moment().subtract(days, 'd').startOf('d').isAfter(startDate);
      }

      return moreThanDaysAgo;
    };

    var customDateRangeStartsMoreThanDaysAgo = function(days) {
      return $scope.report.dateRange === 'Custom' && startsMoreThanDaysAgo($scope.report.customDateRange, days);
    };

    var notUtcOrIsHourly = function() {
      return !isUTC() || $scope.report.interval === 'Hour';
    };

    var isUTC = function() {
      return !$scope.report.timezone || _.contains(['Etc/UTC', 'UTC'], $scope.report.timezone);
    };

    var hasDimension = function(dimension) {
      return dimensionsSelector().find("option[value='"+dimension+"']").prop("selected");
    };

    var hasDisabledDimensionLabel = function(disabledLabel) {
      return dimensionsSelector().find("option:selected[ng-disabled*='"+disabledLabel+"']").length > 0;
    };

    var hasDisabledFilterLabel = function(disabledLabel) {
      return ($(".report-select-wrapper select[ng-disabled*='"+disabledLabel+"'] option:selected:not([value=''])").length > 0) ||
        ($(".report-select-wrapper input[ng-disabled*='"+disabledLabel+"']").filter(function(){ return !!this.value; }).length > 0);
    };

    var hasDisabledDimensionOrFilterLabel = function(disabledLabel) {
      return hasDisabledDimensionLabel(disabledLabel) || hasDisabledFilterLabel(disabledLabel);
    };

    var hasCustomKeyDimension = function() {
      return dimensionsSelector().find("option[value^='key:']").filter(function(idx, dim) {
        return dim.selected;
      }).length > 0;
    };

    var setDomainReportingDisabled = function() {
      $scope.domainReportingDisabled =
        hasDisabledDimensionOrFilterLabel('disabledByDomainReporting') ||
        (hasDisabledDimensionOrFilterLabel('disabledByPastDomainReporting') && !hasDisabledDimensionOrFilterLabel('domainReportingDisabled') && dateRangeStartsMoreThanTwoDaysAgo()) ||
        $scope.report.dateRange === 'All Time' ||
        ($scope.report.dateRange !== 'Today' && $scope.report.dateRange !== 'Yesterday' && $scope.report.dateRange !== 'Last 24 Hours' && $scope.report.dateRange !== 'Custom' && notUtcOrIsHourly() ) ||
        (notUtcOrIsHourly() && customDateRangeStartsMoreThanDaysAgo(2)) ||
        (isUTC() && customDateRangeStartsMoreThanDaysAgo(100));

      setDatePicker();
    };

    var dateRangeStartsMoreThanSevenDaysAgo = function() {
      if ($scope.report.dateRange === 'Custom') {
        return customDateRangeStartsMoreThanDaysAgo(7);
      }
      else {
        return !_.contains(['Today', 'Yesterday', 'Last 24 Hours', 'Last 72 Hours', 'Last 7 Days'], $scope.report.dateRange);
      }
    };

    var dateRangeStartsMoreThanTwoDaysAgo = function() {
      if ($scope.report.dateRange === 'Custom') {
        return customDateRangeStartsMoreThanDaysAgo(2);
      }
      else {
        return !_.contains(['Today', 'Yesterday', 'Last 24 Hours'], $scope.report.dateRange);
      }
    };

    const broadcastReportDateRangeChange = () => {
      $rootScope.$broadcast('reportDateRangeChange', {
        reportDateRange: $scope.report.dateRange
      });
    };

    const setReportDateRange = (dateRange) => {
      $scope.report.dateRange = dateRange;
      broadcastReportDateRangeChange();
    };

    $scope.reportDateRangeChange = () => {
      broadcastReportDateRangeChange();
      $scope.allTimeAndYtdDateRangeDisableHourInterval();
    };

    $scope.allTimeAndYtdDateRangeDisableHourInterval = function() {
      enforceInterval();
      enforceDateRange();
    };

    var setDatePicker = function() {
      if ($scope.freqReachReport) {
        setDatePickerWithOptions({minDate: moment().subtract(365, 'd').startOf('d')});
      } else if (($scope.advertiserDomainReport || $scope.contentReport) && notUtcOrIsHourly()) {
        setDatePickerWithOptions({minDate: moment().subtract(7, 'd').startOf('d')});
      } else if ($scope.domainReportingDisabled) {
        setDatePickerWithOptions();
      } else if ($scope.disabledByDomainReporting && notUtcOrIsHourly()) {
        setDatePickerWithOptions({minDate: moment().subtract(2, 'd').startOf('d')});
      } else {
        setDatePickerWithOptions();
      }
    };

    var setDatePickerWithOptions = function(options = {}) {
      var dateRangeOptions = _.extend({}, {
        maxDate: moment().endOf('day'),
        opens: 'center',
        timePicker: true,
        timePickerIncrement: 1,
        timePicker24Hour: true,
        locale: {
          format: 'MM/DD/YY HH:mm',
          direction: 'datehourrange-future-wrapper'
        },
        parentEl: '.daterange-place'
      }, options);

      $("#custom_date_range").daterangepicker(dateRangeOptions);
    };

    var filterIsPresent = function(filtersToCheck) {
      var filterPresent = false;

      $.each(filtersToCheck, function(i, filter) {
        var selector =  $(".filter-select-" + filter);

        if (selector && platformUtilities.elementValPresent(selector)) {
          filterPresent = true;
          return false;
        }
      });

      return filterPresent;
    };

    var anyFilterIsPresent = function() {
      var filterPresent = false;

      $.each($("input[class*='filter-select-'], select[class*='filter-select-']"), function(i, filter) {
        if (platformUtilities.elementValPresent(this)) {
          filterPresent = true;
          return false;
        }
      });

      return filterPresent;
    };

    var vastErrorCodeFilterPresent = function() {
      return filterIsPresent(vastErrorCodeFilters);
    };

    var audioFilterPresent = function() {
      return filterIsPresent(audioFilters) &&
        (platformUtilities.elementValPresent('.filter-select-demand_tag_formats') && $(".filter-select-demand_tag_formats").val().includes('audio')) ||
        (platformUtilities.elementValPresent('.filter-select-supply_tag_formats') && $(".filter-select-supply_tag_formats").val().includes('audio'));
    };

    var demandFilterPresent = function() {
      return filterIsPresent(demandFilters);
    };

    var ortbFilterPresent = function() {
      return filterIsPresent(ortbFilters);
    };

    var setVastErrorCodeReport = function() {
      $scope.vastErrorCodeReport = dimensionsSelector().find("option[value='vast_error_code']:selected").length > 0 || vastErrorCodeFilterPresent();
    };

    var setAudioReport = function() {
      $scope.audioReport = audioFilterPresent();
    };

    var setBidDensityReport = function() {
      $scope.bidDensityReport = filterIsPresent(['bid_bucket_actual_min', 'bid_bucket_actual_max']) ||
        dimensionsSelector().find("option[value='bid_bucket_actual']:selected").length > 0;
    };

    var setBidDensityDisabled = function() {
      $scope.bidDensityDisabled = hasDisabledDimensionOrFilterLabel('bidDensityReport');

      triggerFiltersChosenUpdated();
    };

    var setMagniteSspReport = function() {
      $scope.magniteSspReport = filterIsPresent([
          'spotx_channel_ids',
          'magnite_tiers',
          'spotx_campaign_ids',
          'tier_selections',
          'foreign_deal_ids',
          'foreign_deal_names',
          'foreign_deal_codes'
        ]) || dimensionIsPresent([
          'spotx_channel_id',
          'magnite_tier',
          'spotx_campaign_id',
          'tier_selection',
          'foreign_deal_id',
          'foreign_deal_name',
          'foreign_deal_code'
        ]);
    };

    var anyDimensionIsPresent = function() {
      return dimensionsSelector().find('option:selected').length > 0;
    };

    var dimensionIsPresent = function(dimensionArray) {
      var selector = _.map(dimensionArray, function(d) {
        return "option[value='" + d + "']:selected";
      }).join(', ');

      return dimensionsSelector().find(selector).length > 0;
    };

    var setSpecialReportTypes = function(specialLabel, specialDisabledLabel, options = {}) {
      var prevVal = $scope[specialLabel];

      $scope[specialLabel] = hasDisabledDimensionOrFilterLabel(specialDisabledLabel);

      if (!prevVal && $scope[specialLabel]) {
        clearFiltersWithDisabledLabel(specialLabel);
      }
      else if (prevVal && !$scope[specialLabel]) {
        setTomSelectEnabled(specialLabel, true);
      }

      var prevDisabledVal = $scope[specialDisabledLabel];
      $scope[specialDisabledLabel] = hasDisabledDimensionOrFilterLabel(specialLabel) || (options.setDisabledCb && options.setDisabledCb());

      if (options.disabledWatcherCb) {
        options.disabledWatcherCb(prevDisabledVal, $scope[specialDisabledLabel]);
      }

      triggerFiltersChosenUpdated();
    };

    var setDspSourceReport = function() {
      $scope.dspSourceReport = hasDisabledDimensionOrFilterLabel('dspSourceDisabled') && !$scope.dspInventoryReport && !$scope.dspDomainReport && !$scope.dspCityReport;
    };

    var setDspSourceDisabled = function() {
      $scope.dspSourceDisabled = hasDisabledDimensionOrFilterLabel('dspSourceReport');
      triggerFiltersChosenUpdated();
    };

    var setDspInventoryReport = function() {
      $scope.dspInventoryReport = hasDisabledDimensionOrFilterLabel('dspInventoryDisabled');
    };

    var setDspInventoryDisabled = function() {
      $scope.dspInventoryDisabled = hasDisabledDimensionOrFilterLabel('dspInventoryReport');
      triggerFiltersChosenUpdated();
    };

    var setDspDomainReport = function() {
      $scope.dspDomainReport = hasDisabledDimensionOrFilterLabel('dspDomainDisabled');
    };

    var setDspDomainDisabled = function() {
      $scope.dspDomainDisabled = hasDisabledDimensionOrFilterLabel('dspDomainReport');
      triggerFiltersChosenUpdated();
    };

    var setDspCityReport = function() {
      $scope.dspCityReport = hasDisabledDimensionOrFilterLabel('dspCityDisabled');
    };

    var setDspCityDisabled = function() {
      $scope.dspCityDisabled = hasDisabledDimensionOrFilterLabel('dspCityReport') ||
        _.contains(dspCityDisabledDateRanges, $scope.report.dateRange) ||
        customDateRangeStartsMoreThanDaysAgo(30);
      triggerFiltersChosenUpdated();
    };

    var setRouterReport = function() {
      $scope.routerReport = filterIsPresent(routerFilters) ||
        dimensionsSelector().find("option[value='supply_router_id']:selected").length > 0;
    };

    var setPostalCodeReport = function() {
      $scope.postalCodeReport = hasDisabledDimensionOrFilterLabel('postalCodeDisabled');
    };

    var setPostalCodeDisabled = function() {
      $scope.postalCodeDisabled = hasDisabledDimensionOrFilterLabel('postalCodeReport');
      triggerFiltersChosenUpdated();
    };

    var adomainAndContentSetDisabledCb = function() {
      return dateRangeStartsMoreThanSevenDaysAgo() && notUtcOrIsHourly();
    };

    $scope.setReportType = function() {
      $scope.$broadcast('settingReportType');

      setDemandReport();
      setVastErrorCodeReport();
      setAudioReport();
      setDisabledByDomainReporting();
      setOrtbReport();
      setBidDensityReport();
      setBidDensityDisabled();
      setSpecialReportTypes('advertiserDomainReport', 'advertiserDomainDisabled', {
        setDisabledCb: adomainAndContentSetDisabledCb,
        disabledWatcherCb: function(wasDisabled, isDisabled) {
          if (!wasDisabled && isDisabled) {
            clearFiltersAndDimensionsWithDisabledLabel('advertiserDomainDisabled');
          }
          else if (wasDisabled && !isDisabled) {
            setTomSelectEnabled('advertiserDomainDisabled', true);
          }
        }
      });
      setSpecialReportTypes('partnerSegmentReport', 'partnerSegmentDisabled');
      setRouterReport();
      setSpecialReportTypes('contentReport', 'contentDisabled', {
        setDisabledCb: adomainAndContentSetDisabledCb,
        disabledWatcherCb: function(wasDisabled, isDisabled) {
          if (!wasDisabled && isDisabled) {
            clearFiltersAndDimensionsWithDisabledLabel('contentDisabled');
          }
          else if (wasDisabled && !isDisabled) {
            setTomSelectEnabled('contentDisabled', true);
          }
        }
      });
      setSpecialReportTypes('countryReport', 'countryDisabled', {
        setDisabledCb: adomainAndContentSetDisabledCb,
        disabledWatcherCb: function(wasDisabled, isDisabled) {
          if (!wasDisabled && isDisabled) {
            clearFiltersAndDimensionsWithDisabledLabel('countryDisabled');
          }
          else if (wasDisabled && !isDisabled) {
            setTomSelectEnabled('countryDisabled', true);
          }
        }
      });
      setDspCityReport();
      setDspCityDisabled();
      setDspDomainReport();
      setDspDomainDisabled();
      setDspInventoryReport();
      setDspInventoryDisabled();
      setDspSourceReport();
      setDspSourceDisabled();
      setMagniteSspReport();
      setSpecialReportTypes('freqReachReport', 'freqReachDisabled');
      setPostalCodeReport();
      setPostalCodeDisabled();
      setDatePicker();
      enforceTimezone();
      enforceInterval();
      enforceDateRange();
    };

    var enforceTimezone = function() {
      if ($scope.freqReachReport && $scope.report.timezone !== 'Etc/UTC') {
        $scope.report.timezone = 'Etc/UTC';
      }

      setTimeout(function() {
        $('#timezone option:not([value="Etc/UTC"])').toggleClass('hidden', $scope.freqReachReport);
        $('#timezone').trigger('chosen:updated');
      }, 0);
    };

    var enforceInterval = function() {
      var hasFreqDimension = hasDisabledDimensionLabel('freqReachDisabled');

      var hideNonCumulativeHours = $scope.freqReachReport && hasFreqDimension;

      var hideHour = ($scope.freqReachReport && !hasFreqDimension) ||
        ($scope.scheduledReport && _.contains(['All Time', 'Year to Date'], $scope.report.dateRange));

      $('#interval option:not([value=""])').toggleClass('hidden', hideNonCumulativeHours);
      $("#interval option[value='Hour']").toggleClass('hidden', hideHour || hideNonCumulativeHours);

      if ($scope.freqReachReport) {
        if (hasFreqDimension) {
          if ($scope.report.interval !== '') {
            $scope.report.interval = '';
          }
        }
        else {
          if ($scope.report.interval === 'Hour') {
            $scope.report.interval = 'Day';
          }
        }
      }

      setTimeout(function() {
        $('#interval').trigger('chosen:updated');
      }, 0);
    };

    var enforceDateRange = function() {
      var scheduledReportAndHour = $scope.scheduledReport && $scope.report.interval === 'Hour';

      $("#date_range option[value='All Time']").toggleClass('hidden', scheduledReportAndHour || $scope.freqReachReport);
      $("#date_range option[value='Year to Date']").toggleClass('hidden', scheduledReportAndHour);

      if ($scope.freqReachReport && $scope.report.dateRange === 'All Time') {
        setReportDateRange('Last 30 Days');
      }

      setTimeout(function() {
        $('#date_range').trigger('chosen:updated');
      }, 0);
    };

    var filterPresentWithAnyOfSpecifiedValues = function(filterSelector, valuesArray) {
      var filterVal = $(filterSelector).val();
      var filterArrayValues = Array.isArray(filterVal) ? filterVal : (filterVal || '').split(',');

      return _.intersection(valuesArray, filterArrayValues).length > 0;
    };

    var setPcFilterReport = function() {
      $scope.pcFilterReport = $scope.pcSeller && (
        filterPresentWithAnyOfSpecifiedValues('#demand_classes', ['6','7']) ||
        filterPresentWithAnyOfSpecifiedValues('#marketplace_type_ids', ['5','6','7','8','9','10']) ||
        filterPresentWithAnyOfSpecifiedValues('#demand_tag_ids', [$scope.omDemandTagId || -1]) ||
        filterPresentWithAnyOfSpecifiedValues('#campaign_ids', [$scope.omCampaignId || -1]) ||
        filterPresentWithAnyOfSpecifiedValues('#demand_partner_ids', [$scope.omDemandPartnerId || -1])
      );
    };

    var setDemandReport = function() {
      if ($scope.demandClient) {
        $scope.demandReport = true;
      } else {
        $scope.demandReport = $("select[name='dimensions[]']").find("option[data-demand]:selected").length > 0 || demandFilterPresent();
      }
      setPcFilterReport();
    };

    var setOrtbReport = function() {
      $scope.ortbReport = $("select[name='dimensions[]']").find("option[data-ortb]:selected").length > 0 || ortbFilterPresent() || $scope.ortbBuyer;
    };

    var setTomSelectEnabled = function(disabledLabel, enabled) {
      var tomSelects = $(".report-select-wrapper select[ng-disabled*='"+ disabledLabel + "'][data-ajax]");

      setTimeout(function() {
        tomSelects.each(function(t, v) {
          if (enabled) {
            $(v).get(0).tomselect.enable();
          }
          else {
            $(v).defaultTomSelect();
            $(v).get(0).tomselect.disable();
          }
        });
      }, 0);
    };

    var clearFiltersWithDisabledLabel = function(disabledLabel) {
      var chosenSelects = $(".report-select-wrapper select[ng-disabled*='"+disabledLabel+"']").not('[data-ajax]');
      chosenSelects.children().prop('selected', false);
      chosenSelects.trigger('chosen:updated');

      setTomSelectEnabled(disabledLabel, false);

      $(".report-select-wrapper input[ng-disabled*='"+disabledLabel+"']").val('');
    };

    var clearDimensionsWithDisabledLabel = function(disabledLabel) {
      var dimensionsToRemove = dimensionsSelector().find("option[ng-disabled*='" + disabledLabel + "']").map(function(){ return $(this).attr('value'); }).toArray();
      $scope.report.dimensions = _.difference($scope.report.dimensions, dimensionsToRemove);
    };

    var clearFiltersAndDimensionsWithDisabledLabel = function(disabledLabel) {
      clearFiltersWithDisabledLabel(disabledLabel);
      clearDimensionsWithDisabledLabel(disabledLabel);
    };

    var clearDomainFields = function() {
      clearFiltersWithDisabledLabel('domainReportingDisabled');

      $scope.report.dimensions = _.reject($scope.report.dimensions, function(d) {
        return isDomainDimension(d);
      });
    };

    var isDomainDimension = function(dimension) {
      return (/_domain$/.test(dimension) || /^app/.test(dimension)) && !/^key/.test(dimension);
    };

    var clearDisabledByPastDomainFields = function() {
      clearFiltersWithDisabledLabel('disabledByPastDomainReporting');

      $scope.report.dimensions = _.reject($scope.report.dimensions, function(d) {
        return d === 'country';
      });
    };

    const setCustomDateRangeDisabled = () => {
      if (!$scope.scheduledReport) {
        return;
      }

      $("#date_range option[value='Custom']").prop('disabled', $scope.frequency !== 'ad_hoc');
      $('#date_range').trigger('chosen:updated');
    };

    $rootScope.$on('scheduledReportFrequencyChange', (e, args) => {
      $scope.frequency = args.frequency;

      setCustomDateRangeDisabled();
    });

    var setDomainReportStatus = function(options = {}) {
      setDisabledByDomainReporting();
      setDisabledByPastDomainReporting();
      setDisabledByDetectedReporting();
      setDomainReportingDisabled();
    };

    $timeout(function() {
      handleDimensionOrFilterChange();
      setCustomDateRangeDisabled();
      broadcastReportDateRangeChange();
    }, 0);
}]);

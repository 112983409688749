angular.module('vastdesk')
.component('creativeFileUploader', {
  bindings: {
    formName: '@',
    formId: '@',
    fieldLabel: '@',
    fieldName: '@',
    tooltipText: '@',
    creativeAction: '@',
    creativeRemoteUrl: '@',
    custom: '<',
    customCode: '@',
    width: '<',
    height: '<',
    required: '<',
    tiles: '<'
  },
  template: require("../templates/creativeFileUploader.html"),
  controllerAs: '$creativeFileUploaderCtrl',
  controller: ['$scope', '$element', function($scope, $element) {
    var self = this;
    var defaultFileSelectedText = 'No file chosen';
    this.fileSelected = defaultFileSelectedText;

    this.creativeOptions = [
      {value: 'file', label: 'File'},
      {value: 'url', label: 'URL'}
    ];

    this.creativeOptionTooltip = function(val) {
      if (val === 'url') {
        return self.tooltipText;
      }
    };

    this.$onInit = function() {
      if (self.customCode) {
        self.decodedCustomCode = self.customCode.replaceAll('%7B%7B', '{{').replaceAll('%7D%7D', '}}');
      }
      
      if (self.tiles) {
        self.creativeOptions.push({value: 'custom', label: 'Custom'});
      }
    };

    $element.find('input[type="file"]').on('change', function() {
      if (this.files[0] && this.files[0].size > 500000000) {
        self.fileSelected = defaultFileSelectedText;
        self.errorMessage = 'File is too large';
        $('#' + self.formName  + '_' + self.fieldName).val('');
      }
      else {
        self.fileSelected = 'File selected!';
        self.errorMessage = null;
      }

      $scope.$apply();
    });
  }]
});

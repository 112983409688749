import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('accountPartnerSegments',  {
    bindings: {
      partnerSegmentsEnabled: '<',
      defaultSemcastingMarkup: '@'
    },
    template: require("../templates/accountPartnerSegments.html"),
    controllerAs: '$accountPartnerSegmentsCtrl',
    controller: ['$scope', '$timeout', function($scope, $timeout) {

      var self = this;
      var bodySelector = $('body');

      this.customDisabledOptions = componentHelper.customDisabledOptions();

      var autoAddMarkups = function() {
        $('.add_markup').click();
        $('.add_markup').click();

        $('.nested-fields.markups:not([style*="display: none"])').each(function(i, j) {
          if (i == 0) {
            $(this).find('.vendor select').val(100).trigger('chosen:updated');
            $(this).find('input[name$="[markup_pct]"]').val('0');
          }
          else if (i == 1) {
            $(this).find('.vendor select').val(101).trigger('chosen:updated');
            $(this).find('input[name$="[markup_pct]"]').val(self.defaultSemcastingMarkup);
          }
        });
      };

      var vendorChangeCallbacks = function() {
        setVendorOptionsEnabled();
        showHideAddVendorsButton();
        showHideVendorRemovalWarnings();
      };

      var showHideVendorRemovalWarnings = function() {
        $('.removal-alert').removeClass('hidden');

        _.chain($('.markups select[name$="[vendor_id]"]'))
         .filter(function(select) {
           return $(select).closest('.markups').is(':not([style*="display: none"])');
         })
         .each(function(select) {
           var vendorId = $(select).val();
           $('.removal-alert-' + vendorId).addClass('hidden');
         });
      };

      var setVendorOptionsEnabled = function() {
        var selected = $('#partner_segment_markups .markups:not([style*="display: none"]) select').map(function() {
          return $(this).val();
        });

        $('#partner_segment_markups select option').each(function() {
          if ( _.contains(selected, $(this).attr('value')) && !$(this).is(':selected')) {
            if ($(this).attr('value') && $(this).attr('value') !== '') {
              $(this).attr('disabled', true);
            }
          }
          else {
            $(this).attr('disabled', false);
          }
        });
        setTimeout(function() {
          $('#partner_segment_markups select').trigger('chosen:updated');
        }, 100);
      };

      var showHideAddVendorsButton = function() {
        var hidden = false;
        var numOptions = $('.nested-fields.markups:first select option').length;

        if (numOptions > 0 && $('.nested-fields.markups:not([style*="display: none"])').length >= (numOptions - 1)) {
          hidden = true;
        }

        $('.add_markup').toggleClass('hidden', hidden);
      };

      this.setPartnerSegmentsEnabled = function(value) {
        self.partnerSegmentsEnabled = value;

        $('.partner-segments-enabled-toggle').toggleClass('hidden', !self.partnerSegmentsEnabled);

        setTimeout(function() {
          if (value && $('.nested-fields.markups:not([style*="display: none"])').length < 1) {
            autoAddMarkups();
          }
          showHideVendorRemovalWarnings();
        }, 0);
      };

      bodySelector.on('cocoon:after-insert', '#partner_segment_markups', function() {
        $(this).find('.chosen-select').defaultChosen();
        vendorChangeCallbacks();
      });

      bodySelector.on('cocoon:after-remove', '#partner_segment_markups', function() {
        vendorChangeCallbacks();

        if ($('.nested-fields.markups').length < 1) {
          self.partnerSegmentsEnabled = false;
          $scope.$apply();
        }
      });

      bodySelector.on('change', '.markups select', function() {
        vendorChangeCallbacks();
      });

      $timeout(function() {
        vendorChangeCallbacks();
      }, 300);

    }]
});

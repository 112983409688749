import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('auditedCreativeStatusTargeting',  {
    bindings: {
      formName: '@',
      formId: '@',
      allAuditStatuses: '<',
      auditStatusSelections: '<',
      readOnly: '<',
      auditStatusMappings: '<'
    },
    template: require("../templates/auditedCreativeStatusTargeting.html"),
    controllerAs: '$auditedCreativeStatusTargetingCtrl',
    controller: function() {
      this.allCustomOptions2 = componentHelper.allCustomOptions2();
    }
});

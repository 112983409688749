import platformUtilities from '../../modules/platform_utilities.js';
import WaterfallHelper from '../../modules/waterfall_helper.js';
import WaterfallModalHelper from '../../modules/waterfall_modal_helper.js';
import DecimalEnforcer from '../../modules/decimalEnforcer.js';

angular.module('vastdesk').controller('SupplyTagFormController', ['$scope', '$timeout', '$filter', '$rootScope', function ($scope, $timeout, $filter, $rootScope) {
  var bodyElement = $('body');
  var demandTagServerSideTable = $('#tag_results table');
  var demandTagServerSideTableId = $(demandTagServerSideTable).attr('id');
  $scope.selectedDemandTagIds = [];
  $scope.selectedCampaignIds = [];
  var campaignPerformancePricingIds = [];
  var demandPerformancePricingIds = [];
  var CPM_VAL = 0;
  var REV_SHARE_VAL = 1;
  var DYNAMIC_VAL = 2;
  var CLASS_OPEN_RTB = 5;
  var CLASS_HEADER_BIDDING = 3;
  var CLASS_OPEN_RTB_DIRECT_CONNECT = 6;
  var CLASS_OPEN_RTB_OPEN_MARKET = 7;
  var bodySelector = $('body');
  var waterfallModalHelper = new WaterfallModalHelper();
  var waterfallHelper = new WaterfallHelper();
  var decimalEnforcer = new DecimalEnforcer();
  var POD_OPTI_TIME_FILL = 'pod_time_fill';
  var POD_OPTI_REV_PER_POD = 'revenue_per_pod';
  var demandTagModal = $('#tag_modal');
  var demandTagModalTable = demandTagModal.find('table');
  var demandTagModalTableLoaded = $.Deferred();
  var campaignModal = $('#campaign_modal');
  var campaignModalTable = campaignModal.find('table');
  var campaignModalTableLoaded = $.Deferred();
  $scope.optimizationFieldsDirty = false;
  $scope.environment = '';
  $scope.numAdSlots = 0;

  $scope.setWaterfallManagement = function(val) {
    if ($scope.isNewRecord || $scope.isNewDuplicateRecord) {
      $scope.waterfallManagement = val;
    }
  };

  $scope.hidePodTab = function() {
    return $scope.jsVpaid() || $scope.vastVersion === '2.0' || $scope.supplyClass === '3';
  };

  $scope.setCustomAdSlotDuration = function(val) {
    $scope.setHasPod(true, true);
    $scope.customAdSlotDuration = val;
    if (!$scope.customAdSlotDuration && $scope.slotPositioning === 'number') {
      $scope.setSlotPositioning('order', true, true);
    }
    else {
      broadcastWfSlotFieldChanged();
    }

    conditionallySetOptiMetricToRPS();
  };

  $scope.setWaterfallSlotBuild = function(val) {
    $scope.waterfallSlotBuild = val;
    val ? $('#pod_slot_positioning').removeClass('ng-hide')
        : $('#pod_slot_positioning').addClass('ng-hide');

    if (!$scope.waterfallSlotBuild) {
      $scope.setSlotPositioning('', true, false);
    }
    else {
      broadcastWfSlotFieldChanged();
    }
  };

  $scope.slotPositioningDisabled = function(sp) {
    return !$scope.customAdSlotDuration && sp === 'number';
  };

  $scope.setSlotPositioning = function(val, ignoreDisabled, useNumAdSlots) {
    if ($scope.slotPositioningDisabled(val) && !ignoreDisabled) {
      return;
    }
    var slotPositioningWas = $scope.slotPositioning;
    $scope.slotPositioning = val;
    clearSelectedBulkActionIds();
    ensureValidSlotIsSelected();

    $scope.$broadcast('slotPositioningChange', {
      slotPositioningWas: slotPositioningWas,
      slotPositioning: $scope.slotPositioning,
      maxNumAdSlots: ($scope.customAdSlotDuration || useNumAdSlots) ? $scope.numAdSlots : 10,
      openMarketEnabled: $scope.openMarketEnabled
    });
  };

  $scope.allSlots = function() {
     return $scope.slotPositioningOrderAll() || $scope.slotPositioningNumberAll();
  };

  $scope.slotPositioningOrderAll = function() {
    return $scope.slotPositioningOrder() && $scope.slotOrder === 'all';
  };

  $scope.slotPositioningNumberAll = function() {
    return $scope.slotPositioningNumber() && $scope.slotNumber === 'all';
  };

  $scope.slotPositioningOrder = function() {
    return $scope.hasPod && $scope.waterfallSlotBuild && $scope.slotPositioning === 'order';
  };

  $scope.slotPositioningNumber = function() {
    return $scope.hasPod && $scope.waterfallSlotBuild && $scope.slotPositioning === 'number';
  };

  $scope.currentSlotOrder = function() {
    return $scope.slotPositioningOrder() && $scope.slotOrder !== 'all' ? $scope.slotOrder : null;
  };

  $scope.currentSlotNumber = function() {
    return $scope.slotPositioningNumber() && $scope.slotNumber !== 'all' ? $scope.slotNumber : null;
  };

  $scope.currentSlotDimension = function() {
    if ($scope.slotPositioningOrder()) {
      return 'slot_order';
    }
    else if ($scope.slotPositioningNumber()) {
      return 'slot_number';
    }
  };

  $scope.inCurrentSlot = function(wf) {
    return $scope.inSlot(wf, $scope.currentSlotOrder(), $scope.currentSlotNumber());
  };

  $scope.inSlot = function(wf, so, sn) {
    return waterfallHelper.inSlot(wf, so, sn);
  };

  $scope.validSlotOrders = function() {
    var filteredSlotOrders = $scope.allSlotOrders;

    if ($scope.customAdSlotDuration) {
      filteredSlotOrders = _.reject($scope.allSlotOrders, function(slot) {
        return ($scope.numAdSlots < 3 && slot === 'middle') ||
         ($scope.numAdSlots < 2 && slot === 'last');
      });
    }

    return filteredSlotOrders;
  };

  $scope.validSlotNumbers = function() {
    var filteredSlotNumbers = $scope.allSlotNumbers;

     if ($scope.customAdSlotDuration) {
      filteredSlotNumbers = _.reject($scope.allSlotNumbers, function(slot) {
        return parseInt(slot) > $scope.numAdSlots;
      });
    }

    return filteredSlotNumbers;
  };

  $scope.slotLists = function() {
    var sl = [
      ['n/a', 0]
    ];

    if ($scope.slotPositioningNumber()) {
      sl = _.map($scope.validSlotNumbers(), function(sn) {
        return [null, sn];
      });
    }
    else if ($scope.slotPositioningOrder()) {
      sl = _.map($scope.validSlotOrders(), function(so) {
        return [so, null];
      });
    }

    return sl;
  };

  $scope.showSlot = function(slot, positioning) {
    if (slot === 'all') {
      return true;
    }
    else if (positioning === 'order') {
      return _.contains($scope.validSlotOrders(), slot);
    }
    else if (positioning === 'number') {
      return _.contains($scope.validSlotNumbers(), parseInt(slot));
    }
  };

  $scope.setSlotOrder = function(val) {
    $scope.slotOrder = val;
    clearSelectedBulkActionIds();
    broadcastWfSlotFieldChanged();
  };

  $scope.setSlotNumber = function(val) {
    $scope.slotNumber = val;
    clearSelectedBulkActionIds();
    broadcastWfSlotFieldChanged();
  };

  var broadcastWfSlotFieldChanged = function() {
    $scope.$broadcast('wfSlotFieldChanged', {
      openMarketEnabled: $scope.openMarketEnabled
    });
  };

  $scope.vastVersionTooltip = function(val) {
    if ($scope.vastVersionDisabled(val)) {
      return 'VAST 2.0 is not allowed on Pod Supply Tags';
    }
  };

  $scope.vastVersionDisabled = function(val) {
    return $scope.hasPod && val === '2.0';
  };

  $scope.setVastVersion = function(val) {
    if ($scope.vastVersionDisabled(val)) {
      return;
    }
    $scope.vastVersion = val;
  };

  $scope.tcPlusTooltip = function() {
    if ($scope.supplyClass === '3' && $scope.hasPod) {
      return 'BidLink is not available for pod supply tags.';
    }
  };

  $scope.objectStatsPanelObjectTable = function() {
    return ($scope.waterfallManagement === 'campaign') ? 'supply_tag_campaigns_table' : 'supply_tag_demand_tags_table';
  };

  $scope.setOptimizationFieldsDirty = function (val) {
    $scope.optimizationFieldsDirty = val;
  };

  $scope.setIsNewRecord = function (isNewRecord, isNewDuplicateRecord) {
    $scope.isNewRecord = isNewRecord;
    $scope.isNewDuplicateRecord = isNewDuplicateRecord;
  };

  $scope.updateSupplyPartner = function (supplyPartnerId) {
    var selectedSupplyPartnerOption = $('#supply_tag_supply_partner_id').find("option:selected");
    setAccountManagers(selectedSupplyPartnerOption);
    setDefaultOpenMarketTier(selectedSupplyPartnerOption);
    setSupplyAllocationDefault(selectedSupplyPartnerOption);
    updateOptimizationFields(supplyPartnerId);
    broadcastSsbReturnBidPrice(selectedSupplyPartnerOption);
  };

  $scope.vastOnly = function() {
    return !$scope.jsVpaidEnabled && !$scope.isDc;
  };

  $scope.jsVpaid = function() {
    return $scope.jsVpaidEnabled && !$scope.isDc;
  };

  $scope.blackBoxOpti = function() {
    return ($scope.optimizationMode === 'black_box' && $scope.optimizationActive);
  };

  var ensureValidSlotIsSelected = function() {
    if (!$scope.customAdSlotDuration || $scope.allSlots()) {
      return;
    }

    if ($scope.slotPositioningOrder() && !_.contains($scope.validSlotOrders(), $scope.currentSlotOrder())) {
      $scope.setSlotOrder('first');
    }
    else if ($scope.slotPositioningNumber() && !_.contains($scope.validSlotNumbers(), parseInt($scope.currentSlotNumber()))) {
      $scope.setSlotNumber('1');
    }
  };

  var clearSelectedBulkActionIds = function() {
    $scope.$broadcast('clearSelectedWaterfallBulkActionIds');
  };

  var setAccountManagers = function (selectedSupplyPartnerOption) {
    $scope.accountManagers = selectedSupplyPartnerOption.data('accountManagers');
  };

  var broadcastSsbReturnBidPrice = function (selectedSupplyPartnerOption) {
    $scope.$broadcast('supplyPartnerSsbReturnBidPriceChange', {
      ssb_return_bid_price: selectedSupplyPartnerOption.data('ssbReturnBidPrice')
    });
  };

  var setDefaultOpenMarketTier = function(selectedSupplyPartnerOption) {
    $scope.defaultOpenMarketTier = selectedSupplyPartnerOption.data('defaultOpenMarketTier');
  };

  var setSupplyAllocationDefault = function(selectedSupplyPartnerOption) {
    if ($scope.isNewRecord) {
      $scope.$broadcast('supplyAllocationDefaultChange', {
        supply_allocation_default: selectedSupplyPartnerOption.data('supplyAllocationDefault')
      });
    }
  };

  var updateOptimizationFields = function (supplyPartnerId) {
    if (!$scope.isNewRecord || $scope.optimizationFieldsDirty) {
      return;
    }
    $.get('/supply/optimization_defaults', {supply_partner_id: supplyPartnerId}, function (resp) {
      if (resp.optimization_settings) {
        $scope.optimizationMetric = resp.optimization_settings.metric;
        $scope.lookbackMinutes = resp.optimization_settings.lookback_minutes;
        setTimeout(function () {
          $('#supply_tag_optimization_settings_metric').trigger('chosen:updated');
          $('#supply_tag_optimization_settings_lookback_minutes').trigger('chosen:updated');
        }, 0);
      }
      $scope.setOptimizationActive(resp.optimization_active);
      $scope.supplyPartnerOptiCustomDefaults = resp.optimization_active;
      conditionallySetOptiMetricToRPS();
      $scope.$apply();
    });
  };

  var broadcastOptimizationChange = function() {
    $scope.$broadcast('supplyTagOptimizationChange');
  };

  $scope.setOptimizationActive = function(val) {
    $scope.optimizationActive = val;
    broadcastOptimizationChange();
  };

  $scope.setOptimizationMode = function(val) {
    $scope.optimizationMode = val;
    broadcastOptimizationChange();
  };

  $scope.disableOptimization = function() {
    if ($scope.jsVpaid() && $scope.optimizationMetric === 'revenue_per_second') {
      $scope.setOptimizationActive(false);
    }
  };

  var setRpmFloorOpenrtb = function(val) {
    if ($scope.rpmFloorOpenrtb !== undefined && val === true) {
      $scope.rpmFloorOpenrtb = true;
      $scope.setOpenrtbFloor();
    }
  };

  $scope.setOpenMarketEnabled = function(val) {
    var changed = (val !== $scope.openMarketEnabled);
    $scope.openMarketEnabled = val;

    if (changed) {
      $rootScope.$broadcast('openMarketEnabledChange', {
        openMarketEnabled: val
      });
    }
    setRpmFloorOpenrtb(val);
  };

  $scope.initBroadfallSettings = function(broadcastTiersEnabled) {
    $scope.broadcastTiersEnabled = broadcastTiersEnabled;
  };

  $scope.currentSupplyTagFields = function() {
    return {
      rate: $scope.rate,
      rate_currency: $scope.rateCurrency,
      openrtb_floor: $scope.openrtbFloor,
      js_vpaid_enabled: $scope.jsVpaidEnabled,
      vast_only: $scope.vastOnly(),
      dc_ctv: ($scope.isDc && $scope.environment === 'ctv'),
      has_pod: $scope.hasPod,
      dc: $scope.isDc,
      ignore_advertiser_domain_targeting: $scope.ignoreAdvertiserDomainTargeting
    };
  };

  var conditionallySetOptiMetricToRPS = function() {
    if (!$scope.persisted && $scope.hasPod && !$scope.accountOptiCustomDefaults && !$scope.supplyPartnerOptiCustomDefaults) {
      $scope.optimizationMetric = 'revenue_per_second';
    }
  };

  var bidDensityModal = $('#bid_density_modal');

  bidDensityModal.on('show.bs.modal', function(e) {
    var triggerElement = $(e.relatedTarget);

    bidDensityModal.find('.modal-title').text(triggerElement.data('modalTitle'));

    bidDensityModal.find('#bid_density_graph').createBidDensityChart({
      demand_tag_id: triggerElement.data('demandTagId'),
      supply_tag_id: triggerElement.data('supplyTagId'),
      slot_order: triggerElement.data('slotOrder'),
      slot_number: triggerElement.data('slotNumber'),
      date_range: 'Today'
    }, {
      chart_title: triggerElement.data('chartTitle')
    });
  });

  var toggleBulkSlotVisibility = function(wrapper, validSlots) {
    $(wrapper).removeClass('hidden');

    _.each(validSlots, function(slot) {
      $('#bulk-edit-modal label[for=bulk_value_' + slot + ']').removeClass('hidden');
    });
  };

  bodyElement.on('show.bs.modal', '#bulk-edit-modal', function() {
    if ($('#bulk-edit-modal .modal-title').text() !== 'Change Slot') {
      return;
    }

    if ($scope.slotPositioning === 'number') {
      toggleBulkSlotVisibility('.slot-number-wrapper', $scope.validSlotNumbers());
    }
    else if ($scope.slotPositioning === 'order') {
      toggleBulkSlotVisibility('.slot-order-wrapper', $scope.validSlotOrders());
    }
  });

  campaignModalTable.on('draw.dt', function () {
    _styleModalRows(campaignModalTable, campaignPerformancePricingIds);
    waterfallModalHelper.setSelectedModalCheckboxes(demandTagModalTable, $scope.selectedDemandTagIds);
  });

  campaignModalTable.on('init.dt', function() {
    campaignModalTableLoaded.resolve();
  });

  campaignModalTable.on('xhr.dt', function(e, settings, json, xhr) {
    if (json && json.custom_data) {
       campaignPerformancePricingIds = json.custom_data.performance_pricing_ids || [];
    }
  });

  waterfallModalHelper.openEventHandler(bodySelector, '.open-campaign-modal', campaignModal, campaignModalTable, function() {
    $scope.selectedCampaignIds = [];

    $.when( campaignModalTableLoaded ).done(function() {
      _setRateFilter(campaignModalTable);
    });
  });

  waterfallModalHelper.saveEventHandler(bodySelector, campaignModal, campaignModalTable, function() {
    $scope.$broadcast('campaignModalSave', {
      selectedIds: $scope.selectedCampaignIds
    });
  });

  waterfallModalHelper.modalCheckEventHandler(bodySelector, 'campaign_ids', function(val) {
    $scope.selectedCampaignIds.push(val);
  }, function(val) {
    $scope.selectedCampaignIds = _.without($scope.selectedCampaignIds, val);
  });

  demandTagModalTable.on('xhr.dt', function(e, settings, json, xhr) {
    if (json && json.custom_data) {
       demandPerformancePricingIds = json.custom_data.performance_pricing_ids || [];
    }
  });

  demandTagModalTable.on('init.dt', function() {
    demandTagModalTableLoaded.resolve();
  });

  demandTagModalTable.on('draw.dt', function () {
    _styleModalRows(demandTagModalTable, demandPerformancePricingIds);
    waterfallModalHelper.setSelectedModalCheckboxes(demandTagModalTable, $scope.selectedDemandTagIds);
  });

  waterfallModalHelper.openEventHandler(bodySelector, '.open-tag-modal', demandTagModal, demandTagModalTable, function() {
    $scope.selectedDemandTagIds = [];

    $.when( demandTagModalTableLoaded ).done(function() {
      var format = waterfallHelper.objectFormat();
      waterfallModalHelper.setFormatFilter(demandTagModal, format);
      waterfallModalHelper.hideShowFormatFilters(demandTagModal, format);
      _setRateFilter(demandTagModalTable);
      waterfallModalHelper.setEnvironmentFilter(demandTagModal, $scope.environment);
      demandTagModal.find('.modal-content .dataTables_filter input').attr("placeholder", "Search by tag, campaign, or partner name; or enter a comma-separated list of IDs...");
    });
  });

  waterfallModalHelper.saveEventHandler(bodySelector, demandTagModal, demandTagModalTable, function() {
    $scope.$broadcast('demandTagModalSave', {
      selectedIds: $scope.selectedDemandTagIds
    });
  });

  waterfallModalHelper.modalCheckEventHandler(bodySelector, 'demand_tag_ids', function(val) {
    $scope.selectedDemandTagIds.push(val);
  }, function(val) {
    $scope.selectedDemandTagIds = _.without($scope.selectedDemandTagIds, val);
  });

  var _setRateFilter = function(modalTable) {
    var filterVal = "";
    var rate = $scope.effectiveRpmFloor();
    if (rate && $scope.isCpmTag()) {
      filterVal = rate.toFixed(2);
    }
    modalTable.parents('.dataTables_wrapper').find('#dfilter_rate').val(filterVal).trigger("keyup");
  };

  var resetCopyModalSlotFields = function() {
    $('.copy-slot-number, .copy-slot-order').addClass('hidden');
    $('.copy-slot-number input, .copy-slot-order input').prop('checked', false);
    $('.copy-slot-number label, .copy-slot-order label').removeClass('active');
  };

  bodySelector.on('click', '.copy-slot-number label, .copy-slot-order label', function() {
     $('#copy_save').attr('disabled', false);
  });

  $scope.modalTitleSuffix = function() {
    var sfx = '';

    if ($scope.slotPositioningOrder()) {
      sfx = ' To ' + $filter('titleize')($scope.slotOrder) + ' Slot';
    }
    else if ($scope.slotPositioningNumber()) {
      sfx = ' To Slot ' + $scope.slotNumber;
    }

    return sfx;
  };

  $('#copy_modal').on('show.bs.modal', function () {
    $('#copy_modal .modal-title').text('Choose Supply To Copy' + $scope.modalTitleSuffix());
    $('#copy_waterfall_management').val($scope.waterfallManagement);
    $('#copy_supply_tag_name').val('');
    $('#copy_supply_tag_id').val('');
    resetCopyModalSlotFields();
    $('#copy_save').attr('disabled', true);
  });

  $('#copy_supply_tag_name').autocomplete({
    minLength: 1,
    source: function( request, response ) {
      $.ajax({
        url: '/searches/supply_tags?text_only=true&waterfall_management=' + ($scope.waterfallManagement || 'demand_tag') + '&format=' + waterfallHelper.objectFormat() + '&search=' + request.term,
        dataType: 'json'
      }).done(function( data ) {
        response(
          _.chain(data)
           .map(function(item) {
             return {
               label: item.text,
               value: item.value,
               custom_data: item.custom_data
             };
           })
           .reject(function(item) {
             return item.value == $scope.supplyTagId;
           })
           .value()
        );
      });
    },
    select: function(e, ui){
      var item = ui.item;
      var itemId = item.value;
      var customData = item.custom_data;
      var slotOrders = null;
      var slotNumbers = null;

      resetCopyModalSlotFields();

      if (customData && Array.isArray(customData.slot_numbers)) {
        slotNumbers = customData.slot_numbers;
        $('.copy-slot-number label').addClass('hidden');
        _.each(slotNumbers, function(slotNumber) {
          $('.copy-slot-number label.sn-' + slotNumber).removeClass('hidden');
        });
        $('.copy-slot-number').removeClass('hidden');
      }
      else if (customData && Array.isArray(customData.slot_orders)) {
        slotOrders = customData.slot_orders;
        $('.copy-slot-order label').addClass('hidden');
        _.each(slotOrders, function(slotOrder) {
          $('.copy-slot-order label.so-' + slotOrder).removeClass('hidden');
        });
        $('.copy-slot-order').removeClass('hidden');
      }

      $('#copy_supply_tag_name').val($('<div></div>').html(item.label).text());
      $('#copy_supply_tag_id').val(itemId);

      $('#copy_save').attr('disabled', slotOrders || slotNumbers);
      return false;
    }
  }).data('ui-autocomplete')._renderItem = function (ul, item) {
     return $('<li></li>')
         .data('item.autocomplete', item)
         .append(item.label)
         .appendTo(ul);
   };

  $scope.setIsOutstream = function (value) {
    if(typeof value === 'undefined'){
      $scope.isOutstream = null;
    } else {
      $scope.isOutstream = value === 0;
    }
    if (!$scope.isOutstream) {
      $scope.trackingPlayerCost = false;
    }
  };

  $scope.$watch('[trackingPlayerCost]', function (new_val, old_val) {
    if (new_val === old_val) return;
    if ($scope.trackingPlayerCost && $scope.isDynamicTag()) {
      $scope.setRevenueType(CPM_VAL);
    }
    $scope.$broadcast('trackingPlayerCostChange');
  });

  var broadcastSsbFieldChange = function() {
    $rootScope.$broadcast('ssbFieldChange', {
      vastOnly: $scope.vastOnly(),
      environment: $scope.environment
    });
  };

  var broadcastApplyVastOnlyVendorsFieldChange = function() {
    $rootScope.$broadcast('applyVastOnlyVendorsFieldChange');
  };

  $scope.ssb = function() {
    return _.contains(['ctv', 'dooh'], $scope.environment) || $scope.vastOnly();
  };

  $scope.setRevenueType = function (value) {
    if ($scope.revenueTypeIsDisabled(value) || $scope.revenueModelType === value) {
      return;
    }
    $scope.revenueModelType = value;
    $scope.$broadcast('revenueModelTypeChange');
    $scope.rate = '';
    $scope.setRpmFloor();
    setTimeout(function() {
      $("select[name*='[rate_currency]']").defaultChosen();
      $("select[name*='[rate_currency]']").trigger('chosen:updated');
    }, 0);
  };

  $scope.setSupplyClass = function(supplyClass) {
    if (!$scope.persisted) {
      $scope.supplyClass = supplyClass;
      if (supplyClass === '3') {
        $scope.setPodNone();
      }
      if(supplyClass === '1' && $scope.environment === 'dooh') {
        $scope.setEnvironment('ctv');
      }
    }
  };

  $scope.setOmsdkEnabled = function(value) {
    $scope.omsdkEnabled = value;
    $scope.$broadcast('omsdkEnabledChange');
  };

  $scope.revenueTypeIsDisabled = function (value) {
    return ($scope.isDc && $scope.persisted) ||
      (value === DYNAMIC_VAL && $scope.trackingPlayerCost);
  };

  var environmentJsVpaidMappings = {
    desktop: true,
    mobile_web: true,
    in_app: false,
    ctv: false,
    dooh: false
  };

  var getEnvironmentJsVpaidMapping = function () {
    return environmentJsVpaidMappings[$scope.environment];
  };

  var setVpaidSettings = function () {
    if($scope.environment === 'ctv' || $scope.environment == 'in_app') {
      $scope.vpaidNoneAllowVpaidDemand = false;
    }

    var jsVpaidMapping = getEnvironmentJsVpaidMapping();

    if (typeof jsVpaidMapping !== 'undefined') {
      $scope.setJsVpaidEnabled(jsVpaidMapping);
    }
  };

  $scope.setEnvironment = function (environment, dc) {
    $scope.environment = environment;

    if(dc===false) {
      $rootScope.$broadcast('environmentChange', {
        environment: $scope.environment
      });
      setVpaidSettings();

      if($scope.environment === 'ctv') {
        $scope.detectedSizeTargeting = false;
      }
    } else {
      drawServerSideTable(demandTagServerSideTableId);
    }

    broadcastSsbFieldChange();
    toggleEnvClasses();

    if($scope.domainTargetingDisabled()) {
      $scope.detectedDomainTargeting = false;
    }

    if($scope.doohEnvironmentSelected()) {
      $scope.setOpenMarketEnabled(false);
    }
  };

  $scope.doohEnvironmentSelected = function() {
    return _.contains(['dooh'], $scope.environment);
  };

  $scope.domainTargetingDisabled = function() {
    return _.contains(['in_app', 'ctv', 'dooh'], $scope.environment) || $scope.vastOnly();
  };

  $scope.detectedDomainTargetingSelected = function(value) {
    if($scope.domainTargetingDisabled()) {
      return value === false;
    } else {
      return value === $scope.detectedDomainTargeting;
    }
  };

  $scope.detectedSizeTargetingSelected = function(value, direct_connect) {
    if($scope.ssb()) {
      return value === false;
    } else {
      return value === $scope.detectedSizeTargeting;
    }
  };

  $scope.setRpmFloor = function() {
    if ($scope.isCpmTag()) {
      if (platformUtilities.isNumeric($scope.rate)) {
        $scope.rpmFloor = parseFloat(($scope.rate * (1 + ($scope.rpmFloorMarginDefault/100))).toFixed(2));
      }
      else {
        $scope.rpmFloor = null;
      }
    }
    else {
      $scope.rpmFloor = 0;
    }

    $scope.setOpenrtbFloor();
  };

  $scope.setOpenrtbFloorValue = function(val) {
    $scope.openrtbFloor = decimalEnforcer.enforce(val, 'ceil', 2);
  };

  $scope.setOpenrtbFloor = function() {
    if (!$scope.rpmFloorOpenrtb) {
      var omFloorValue = platformUtilities.isNumeric($scope.openrtbFloor) ? $scope.openrtbFloor.toString() : null;
      $scope.setOpenrtbFloorValue(omFloorValue);
    }
    else if (platformUtilities.isNumeric($scope.rpmFloor)) {
      $scope.setOpenrtbFloorValue($scope.rpmFloor.toString());
    }
    else {
      $scope.setOpenrtbFloorValue(null);
    }
  };

  $scope.effectiveRpmFloor = function() {
    if (platformUtilities.isNumeric($scope.rpmFloor)) {
      return $scope.rpmFloor;
    }
    else {
      return $scope.rate;
    }
  };

  var toggleMacroOverrideClasses = function() {
    $('.desktop-mobile-web-hidden').toggleClass('hidden', _.contains(['desktop', 'mobile_web'], $scope.environment) && !$scope.vastOnly());
  };

  var toggleEnvClasses = function() {
    $('.in-app-ctv-hidden').toggleClass('hidden', _.contains(['in_app', 'ctv'], $scope.environment));
    $('.desktop-mobile-web-hidden').toggleClass('hidden', _.contains(['desktop', 'mobile_web'], $scope.environment));
    $('.show-dc-media-file-targeting').toggleClass('hidden', !_.contains(['dc', 'ctv'], $scope.environment));
    toggleMacroOverrideClasses();
  };

  $scope.showRpmFloorWarning = function() {
    return $scope.isCpmTag() && $scope.rpmFloor < $scope.rate;
  };

  $scope.isCpmTag = function () {
    return !$scope.revenueModelType || $scope.revenueModelType === CPM_VAL;
  };

  $scope.isRevShareTag = function() {
    return $scope.revenueModelType === REV_SHARE_VAL;
  };

  $scope.isDynamicTag = function () {
    return $scope.revenueModelType === DYNAMIC_VAL;
  };

  $scope.isHeaderBiddingTag = function(row) {
    return row.data('demandClass') === CLASS_HEADER_BIDDING;
  };

  $scope.isOpenMarketTag = function(row) {
    return row.data('demandClass') === CLASS_OPEN_RTB_OPEN_MARKET;
  };

  $scope.broadfallFor = function(tier) {
    return $scope.tierBroadfallInputFor(tier).prop('checked');
  };

  $scope.tierBroadfallInputFor = function(tier) {
    return $('input[type="checkbox"]#supply_tag_tier_' + tier + '_broadfall');
  };

  $scope.setBroadfallForTier = function(tierValue, broadfallEnabled) {
    var tierBroadfallSetting = $scope.vastOnly() ? true : broadfallEnabled;
    $scope.tierBroadfallInputFor(tierValue).prop('checked', tierBroadfallSetting);
  };

  $scope.jsVpaidEnabledTooltip = function(value) {
    if ($scope.jsVpaidEnabledDisabled(value)) {
      return 'JS VPAID is not allowed on Pod Supply Tags';
    }
  };

  $scope.jsVpaidEnabledDisabled = function(value) {
    return $scope.hasPod && value === true;
  };

  $scope.setJsVpaidEnabled = function(value) {
    if ($scope.jsVpaidEnabledDisabled(value)) {
      return;
    }
    $scope.jsVpaidEnabled = value;
    broadcastSsbFieldChange();
    broadcastApplyVastOnlyVendorsFieldChange();
    broadcastJsVpaidEnabledChange();
    drawServerSideTable(demandTagServerSideTableId);
    $scope.disableOptimization();
    toggleMacroOverrideClasses();
    $scope.disableVastExtReturnNetPriceEnabled();
  };

  $scope.setVastExtReturnNetPriceEnabled = function(val) {
    $scope.vastExtReturnNetPriceEnabled = val;
  };

  $scope.disableVastExtReturnNetPriceEnabled = function() {
    if ($scope.jsVpaidEnabled) {
      $scope.setVastExtReturnNetPriceEnabled(false);
    }
  };

  $scope.connnectedDemandNamePlaceholder = function(partnerName) {
    var placeholder = 'No name entered';

    if ($scope.rate && partnerName && partnerName !== '') {
      if ($scope.revenueModelType === 1) {
        placeholder = '' + partnerName + ' ' + $scope.rate + '%';
      }
      else {
        placeholder = '' + partnerName + ' ' + $scope.rate + ' ' + $scope.rateCurrency;
      }
    }

    return placeholder;
  };

  $scope.tierValueFor = function(row) {
    return row.find('td.tier select').val() || row.find('td.tier input').val();
  };

  $scope.setIgnoreAdvertiserDomainTargeting = function(val) {
    $scope.ignoreAdvertiserDomainTargeting = val;

    drawServerSideTable(demandTagServerSideTableId);
  };

  $scope.demandTagTableData = function() {
    return {
      rate: $scope.rate,
      openrtb_floor: $scope.openrtbFloor,
      supply_partner_id: $scope.supplyPartnerId,
      optimization_active: $scope.optimizationActive,
      optimization_mode: $scope.optimizationMode,
      revenue_model_type: $scope.revenueModelType
    };
  };

  var _styleModalRows = function(modalTable, performancePricingIds) {
    var bulkIndex = modalTable.find("th.bulk-add-enabled").index();
    var statusIndex = modalTable.find("th.status").index();

    modalTable.find("tr").each(function (i, tr) {
      waterfallModalHelper.styleWithBulkAddEnabled(tr, bulkIndex);
      waterfallModalHelper.styleStatus(tr, statusIndex);
    });
  };

  $scope.setHasPod = function(hasPod, broadcast) {
    $scope.hasPod = hasPod;

    setPodOptiMetricVisibility();

    if (broadcast) {
      $rootScope.$broadcast('hasPodChange', {
        hasPod: $scope.hasPod
      });
    }
  };

  var setPodOptiMetricVisibility = function() {
    var optiMetrics = $('#supply_tag_optimization_settings_metric');

    if (!$scope.hasPod) {
      if ($scope.optimizationMetric == POD_OPTI_REV_PER_POD) {
        $scope.optimizationMetric = 'rpm';
      } else if ($scope.optimizationMetric == POD_OPTI_TIME_FILL) {
        $scope.optimizationMetric = 'fill_rate';
      }

      optiMetrics.find('option').filter(function() {
        var optionValue = $(this).val();
        return optionValue === '' || _.contains([POD_OPTI_TIME_FILL, POD_OPTI_REV_PER_POD], optionValue);
      }).attr('disabled', true).addClass('hidden');

    } else {
      optiMetrics.find('option').attr('disabled', false).removeClass('hidden');
    }

    setTimeout(function () {
      $('#supply_tag_optimization_settings_metric').trigger('chosen:updated');
    }, 0);
  };

  $scope.setPodNone = function() {
    if ($scope.removePodDisabled) {
      return;
    }
    $scope.setHasPod(false, true);
    $scope.setWaterfallSlotBuild(false);
  };

  var setPodAddFieldsDisabled = function(applyScope) {
    $scope.numAdSlots = $('.nested-fields.ad-slots:not([style*="display: none"])').length;
    var addFieldsButton = $('#ad_slots .add_fields');

    if ($scope.numAdSlots >= 10) {
      addFieldsButton.addClass('disabled');
    }
    else {
      addFieldsButton.removeClass('disabled');
    }

    ensureValidSlotIsSelected();

    $scope.$broadcast('numAdSlotsChange', {
      numAdSlots: $scope.numAdSlots,
      openMarketEnabled: $scope.openMarketEnabled
    });

    if (applyScope) {
      $scope.$apply();
    }
  };

  var setAdSlotPositions = function() {
    $('#ad_slots .nested-fields.ad-slots:not([style*="display: none"])').each(function(index, adSlot) {
      var position = index + 1;

      $(adSlot).find('.position input').val(position);
      $(adSlot).find('.position .label').text(position);
      setPodAddFieldsDisabled(true);
    });
  };

  var showHidePodElements = function() {
    setTimeout(function() {
      var elementsHiddenByDynamicDuration = $('#pod_max_duration_wrapper, #ad_slots');
      if ($('input[id*=custom_ad_slot_duration_false]').prop('checked')) {
        elementsHiddenByDynamicDuration.addClass('hidden');
      }
      else {
        elementsHiddenByDynamicDuration.removeClass('hidden');
      }
    }, 0);
  };

  bodySelector.on('click', '#no_pod', function() {
    if ($scope.removePodDisabled) {
      return;
    }
    $('.add_pod').addClass('add_fields');
    $('.nested-fields.pod').addClass('hidden');
  });

  var drawServerSideTable = function(tableId) {
    $scope.$broadcast('drawServerSideTable', {
      tableId: tableId
    });
  };

  var broadcastJsVpaidEnabledChange = function() {
    if ($scope.isDc) {
      return;
    }
    $scope.$broadcast('jsVpaidEnabledChange', {
      jsVpaidEnabled: $scope.jsVpaidEnabled
    });
  };

  var initAdSlot = function(customAdSlotDuration) {
    if ( customAdSlotDuration === 'true' && $('.nested-fields.ad-slots').length < 1 ) {
      $('#add_ad_slot').click();
      setTimeout(function() {
        setAdSlotPositions();
      }, 0);
    }
  };

  var setCustomAdSlotDuration = function() {
    if ( $('.nested-fields.ad-slots').length < 1 ) {
      $('#ad_slot_false').parent().click();
    }
  };

  bodySelector.on('change', "input[name*='[unfilled_slot_opt_out_enabled]']", function() {
    $('.max-unfilled-slots').toggleClass('hidden', $(this).val() === 'false');
  });

  bodySelector.on('click', '.add_pod', function(e) {
    var customAdSlotDuration = $(e.target).find('input').val();
    showHidePodElements();

    if ( $('.nested-fields.pod').length > 0 ) {
      $('.add_pod').removeClass('add_fields');
    }
    $('.nested-fields.pod').removeClass('hidden');
    $scope.setEnvironment('ctv', $scope.isDc);
    $scope.$apply();

    setTimeout(function() {
      $('.add_pod').removeClass('add_fields');
      $('.nested-fields.pod label[for="custom_ad_slot_duration_' + customAdSlotDuration + '"]').click();
      initAdSlot(customAdSlotDuration);
    }, 0);
  });

  bodySelector.on('click', '.custom-ad-slot-duration-group label', function() {
    showHidePodElements();

    setTimeout(function() {
      $rootScope.$broadcast('customAdSlotDurationChange');
    }, 0);
  });

  bodySelector.on('cocoon:after-insert', '#ad_slots', function(e) {
    setAdSlotPositions();
  });

  bodySelector.on('cocoon:after-remove', '#ad_slots', function(e) {
    setCustomAdSlotDuration();
    setAdSlotPositions();
  });

  bodySelector.on('click', '.nested-fields.ad-slots .duration-type .btn', function() {
    var durationInputs = $(this).closest('.ad-slots').find('.duration input');

    if ( $(this).find('input').val() === 'any' ) {
      durationInputs.val(null).attr('readonly', true);
    }
    else {
      durationInputs.attr('readonly', false);
    }
  });

  bodySelector.on('submit', '.tag-form', function() {
    if ( $('input[name="ad_slot"]:checked').val() === 'none' ) {
      $('.nested-fields.pod').remove();
    }
    $scope.$apply();
  });

  var fixAdSlotsOrder = function() {
    if ( $('.nested-fields.pod').length > 0 ) {
      $('.nested-fields.pod').insertAfter('.ad-slot-group');
    }
  };

  bodySelector.on('click', '#omeb_modal .btn-ss-submit', function() {
    $('#omeb_modal').modal('hide');

    $timeout(function() {
      $scope.$apply();
    });
  });

  $('#convert_modal #modal_save').click(function() {
    $('#convert_modal').modal('hide');
  });

  $scope.$on('openMarketModalTableInit', function(e, args) {
    if (window.location.search.match('om_modal=true') && args.table === 'open_market_external_bidders_table') {
      $('#omeb_modal').modal('show');
    }
  });

  $scope.$on('updateSupplyTagFormControllerVar', function(e, args) {
    $scope[args.variable] = args.val;

    if (args.applyScope) {
      $scope.$apply();
    }
  });

  $rootScope.$on('formatChanged', function(e, args) {
    if (_.contains(['audio', 'tile'], args.format)) {
      $scope.optimizationMetric = 'rpm';

      setTimeout(function () {
        $('#supply_tag_optimization_settings_metric').trigger('chosen:updated');
      }, 0);
    }
  });

  $timeout(function () {
    if ($scope.isNewRecord && !$scope.isDc) {
      setVpaidSettings();
    }
    setPodAddFieldsDisabled(false);
    showHidePodElements();
    fixAdSlotsOrder();
    broadcastJsVpaidEnabledChange();
    toggleEnvClasses();
  }, 0);
}]);
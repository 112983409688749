import ReactOnRails from 'react-on-rails';

import * as component_springserve_theme from '@rubicon/component-springserve-theme';

// This allows us to automatically import all components from a npm package
// and prefix the component name.
// Usage: component_exporter("AntdComponents", antd_components)
// The above would allow you to render a react component in a view file as follows
// = react_component("AntdComponents-Footer", props: {any_prop: any_value})
const component_exporter = (prefix, object) => {
  const register_object = {}; 
  for (const component in object){
    register_object[prefix + "-" + component] = object[component];
  }
  return register_object;
};


// This is how react_on_rails can see the Components in the browser.
ReactOnRails.register({
  ...component_exporter("ComponentSpringserveTheme", component_springserve_theme)
});
import Swal from 'sweetalert2';

export default {
  alertWarning(title) {
    Swal.fire({
      title: title,
      icon: 'warning'
    });
  },
  confirmWarning(options, successCb, cancelCb) {
    var fireOptions = _.extend({
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel'
    }, options);

    Swal.fire(fireOptions).then((result) => {
      if (result.value) {
        successCb();
      }
      else {
        if (cancelCb) {
          cancelCb();
        }
      }
    });
  }
};

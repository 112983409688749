angular.module('vastdesk').
  component('accountInfrastructureFees',  {
    bindings: {
      infrastructureFeeType: '@',
      infrastructureFillCutoff: '<',
      infrastructureCpmFee: '<'
    },
    template: require("../templates/accountInfrastructureFees.html"),
    controllerAs: '$accountInfrastructureFeesCtrl',
    controller: ['$scope', function($scope) {

      var self = this;
      var bodyElement = $('body');

      bodyElement.on('change', '.data-analytics-fee input', function() {
        var parsedValue = parseFloat($(this).val());
        var floatValue = isNaN(parsedValue) ? undefined : parsedValue;

        self.infrastructureCpmFee = floatValue;
        $scope.$apply();
      });

      this.syncInfrastructureFee = function() {
        $('.data-analytics-fee input').val(self.infrastructureCpmFee);
      };

      this.feeTypeOptions = [
        {value: 'none', label: 'None'},
        {value: 'impressions', label: 'Imps'},
        {value: 'billable_requests', label: 'Billable Reqs'}
      ];

    }]
});

import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('keyValueTargetingToggle',  {
    bindings: {
      formName: '@',
      formId: '@',
      keyValueTargeting: '<',
      toggleClass: '@'
    },
    template: require("../templates/keyValueTargetingToggle.html"),
    controllerAs: '$keyValueTargetingToggleCtrl',
    controller: function() {

      var self = this;

      this.allCustomOptions = componentHelper.allCustomOptions();

      this.setKeyValueTargeting = function(value) {
        self.keyValueTargeting = value;

        $('.' + self.toggleClass).toggleClass('hidden', !value);
      };

      this.$onInit = function() {
        self.setKeyValueTargeting(self.keyValueTargeting);
      };
    }
});

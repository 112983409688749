export default {
  sticky: function(that) {
    if ($(that).data('stickyHeaders') === false) {
      return;
    }

    var isSticky = false;

    /**
     * Create it even if it's not neccessary just in case
     */
    var initScrollBar = function() {
      var _scrollBar = $(document.createElement('div'));

      _scrollBar.addClass('datatable-top-scroll-bar');

      return _scrollBar;
    };

    var scrollBar = initScrollBar();
    var scrollContent = $(document.createElement("div"));

    var resizeScrollBar = function(_wrapper) {
      scrollContent.width(_wrapper.children("table").width() || _wrapper.width());
    };
    /**
     * This creates the top scrollbar and attaches the scrollEvents of it
     * to the underlying table
     */
    var insertScrollBar = function(_wrapper) {

      if (!scrollBar) { initScrollBar(); }

      scrollContent.addClass('scroll-fake-content');
      scrollContent.innerHTML = "&nbsp;";

      resizeScrollBar(_wrapper);

      scrollBar.prepend(scrollContent);
      scrollBar.insertBefore(wrapper);

      var _table = wrapper.first("table");
      var syncingTableScroll = false;
      var syncingScrollBarScroll = false;

      scrollBar.scroll(function() {
        if (!syncingTableScroll) {
          syncingScrollBarScroll = true;
          _table.scrollLeft($(scrollBar).scrollLeft());
        }
        syncingTableScroll = false;
      });

      _table.scroll(function() {
        if (!syncingScrollBarScroll) {
          syncingTableScroll = true;
          scrollBar.scrollLeft(_table.scrollLeft());
        }
        syncingScrollBarScroll = false;
      });

      return scrollBar;
    };

    $(that).on('enabledStickiness.stickyTableHeaders', function() {
      scrollBar.addClass("sticky");
      scrollBar.css({'top': $('#navbar-fixed-top').outerHeight()});

      isSticky = true;
    });

    $(that).on('disabledStickiness.stickyTableHeaders', function() {
      scrollBar.removeClass("sticky");
      isSticky = false;
    });

    var navbarFixedTopOuterHeight = function() {
      return $('#navbar-fixed-top').outerHeight() + scrollBar.outerHeight();
    };

    $(that).stickyTableHeaders({fixedOffset: navbarFixedTopOuterHeight()});

    $(that).find('th').on('click', function() {
      if ($(this).hasClass('no-sort')) { return; }
      if (isSticky) {
        var scrollTarget = $(that).offset().top - navbarFixedTopOuterHeight();
        $('body').scrollTop(scrollTarget);
      }
    });

    var headerDiv = $(that).find('.tableFloatingHeaderOriginal');
    var wrapper = $(that).closest('.wrapper');

    // by default the top scroll is on, but you can turn it off by putting
    // 'top-scroll': 'false" in the data hash
    if ($(that).data('topScroll') !== false) {
      scrollBar = insertScrollBar(wrapper);
    }

    var stickyTableHeaderScrollHandler = function(timeout) {
      var offsetLeft = wrapper.offset().left;
      var clipLeft = wrapper.scrollLeft();
      var leftPx = (offsetLeft - clipLeft) + 'px';

      var newCss = {
        'left': leftPx
      };
      var clipPath = 'none';
      var scrollBarCss = {'left': leftPx, 'width': wrapper.width()};

      var setStyles = function() {
        if (isSticky && wrapper.get(0).scrollWidth > wrapper.width()) {
          var bottom = (headerDiv.css('position') === 'fixed') ? 0 : 1;
          clipPath = 'inset(0px ' + (headerDiv.width() - wrapper.width() - clipLeft ) + 'px ' + bottom + 'px ' + clipLeft + 'px)';

          scrollBarCss.left = wrapper.offset()['left'];
        }

        newCss.clipPath = clipPath;

        scrollBar.css(scrollBarCss);
        headerDiv.css(newCss);
      };

      if (timeout) {
        setTimeout(function() {
          setStyles();
        }, 0);
      }
      else {
        setStyles();
      }
    };

    var throttledStickyTableScroll = _.throttle(function() {
      stickyTableHeaderScrollHandler(false);
    }, 10, {leading: false});

    var reInitializeStickyTableHeaders = function() {
      $(that).stickyTableHeaders('destroy');
      $(that).stickyTableHeaders({fixedOffset:  navbarFixedTopOuterHeight()});
      stickyTableHeaderScrollHandler(true);
      resizeScrollBar(wrapper);
    };

    stickyTableHeaderScrollHandler(true);
    wrapper.scroll(throttledStickyTableScroll);
    $(window).scroll(throttledStickyTableScroll);
    $(window).resize(throttledStickyTableScroll);

    $(window).resize(function() {
      resizeScrollBar(wrapper);
    });

    $(that).on('reflowScrollBar', function() {
      throttledStickyTableScroll();
      resizeScrollBar(wrapper);
    });

    $(that).on('init.dt', function() {
      $(this).closest('.dataTables_wrapper').find('.toggle-full, .toggle-lite').click(reInitializeStickyTableHeaders);
    });
    $(that).on('draw.dt', reInitializeStickyTableHeaders);

    // this catches a weird timing thing where the table changes size after it
    // renders
    setTimeout(function() {
      resizeScrollBar(wrapper);
    }, 100);
  }
};
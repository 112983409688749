angular.module('vastdesk').
  component('hiddenReportCurrency',  {
    bindings: {
      currency: '@'
    },
    template: "<input type='hidden' name='currency' id='currency' value='{{$hiddenReportCurrencyCtrl.currency}}' />",
    controllerAs: '$hiddenReportCurrencyCtrl',
    controller: ['$rootScope', function($rootScope) {

      var self = this;

      $rootScope.$on('reportCurrencyChange', function(e, args) {
        self.currency = args.currency;
      });

    }]
});

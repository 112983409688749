import DashboardHelper from '../../modules/dashboard_helper.js';

angular.module('vastdesk').
  component('dashboardTopObjectToggles',  {
    bindings: {
      toggleOptions: '<'
    },
    template: require("../templates/dashboardTopObjectToggles.html"),
    controllerAs: '$dashboardTopObjectTogglesCtrl',
    controller: function() {

      var self = this;
      var dashboardHelper = new DashboardHelper();

      this.reflowTopSection = function(option) {
        self.activeToggle = option.labels[0];

        mixpanel.track('dashboard top 10 objects click', {
          objects: option.toggles[0] + option.toggles[1]
        });

        dashboardHelper.reflowDashboard(
          option.toggles[0] + 'Wrapper',
          option.toggles[0] + option.toggles[1] + 'Wrapper'
        );
      };

      this.$onInit = function() {
        self.activeToggle = self.toggleOptions[0].labels[0];
      };

    }
});

angular.module('vastdesk').
  component('supplyEnvironment',  {
    bindings: {
      formName: '@',
      environment: '@',
      environments: '<',
      environmentMappings: '<'
    },
    template: require("../templates/supplyEnvironment.html"),
    controllerAs: '$supplyEnvironmentCtrl',
    controller: ['$rootScope', function($rootScope) {

      var self = this;

      this.setEnvironment = function (environment) {
        self.environment = environment;

        $rootScope.$broadcast('environmentChange', {
          environment: self.environment
        });

        toggleEnvClasses();
        broadcastSsbFieldChange();
      };

      var toggleEnvClasses = function() {
        $('.in-app-ctv-hidden').toggleClass('hidden', _.contains(['in_app', 'ctv'], self.environment));
        $('.desktop-mobile-web-hidden').toggleClass('hidden', _.contains(['desktop', 'mobile_web'], self.environment));
      };

      var broadcastSsbFieldChange = function() {
        $rootScope.$broadcast('ssbFieldChange', {
          environment: self.environment
        });
      };

      this.$onInit = function() {
        toggleEnvClasses();
      };

    }]
});

angular.module('vastdesk').
  component('accountBidderTypeFees',  {
    bindings: {
      "dspBidderFeeType": '@',
      "sspBidderFeeType": '@',
      "oemBidderFeeType": '@',
      "dspBidderFeeValue": '<',
      "sspBidderFeeValue": '<',
      "oemBidderFeeValue": '<'
    },
    template: require("../templates/accountBidderTypeFees.html"),
    controllerAs: '$accountBidderTypeFeesCtrl',
    controller: ['$rootScope', function($rootScope) {
      this.bidderTypes = ["dsp", "ssp", "oem"];

      this.bidderFeeTypeOptions = [
        {value: 'cpm', label: 'CPM'},
        {value: 'pos', label: 'PoS'}
      ];

      this.feeChange = (value, type) => {
        this[type + "BidderFeeType"] = value;
      };
    }]
});

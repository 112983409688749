import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('nielsenRatingIntegration',  {
    bindings: {
      formName: '@',
      formId: '@',
      active: '<',
      trafCid: '@',
      defaultCustomParamValue: '<'
    },
    template: require("../templates/nielsenRatingIntegration.html"),
    controllerAs: '$nielsenRatingIntegrationCtrl',
    controller: function() {
      var self = this;
      
      this.statusOptions = componentHelper.statusOptions();
      var nielsenCustomFormSelector = $('#nielsen_custom_param_form');
      var nielsenCustomFormValueSelector = $('#nielsen_custom_param_form_value');

      var showCustomParamField = function(active_value) {
        if (active_value) {
          nielsenCustomFormSelector.removeClass('hidden');
          if (!nielsenCustomFormValueSelector.val()) {
            nielsenCustomFormValueSelector.val(self.defaultCustomParamValue);
          }
        }
        else {
          nielsenCustomFormSelector.addClass('hidden');
        }
      };

      this.$onInit = function() {
        showCustomParamField(self.active);
      };

      this.setActive = function(val) {
        self.active = val;

        showCustomParamField(val);
      };
    }
});

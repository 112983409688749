import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('accountALaCarte',  {
    bindings: {
      alaEnabled: '<',
      whiteopsCachePct: '<',
      protectedCachePct: '<'
    },
    template: require("../templates/accountALaCarte.html"),
    controllerAs: '$accountALaCarteCtrl',
    controller: ['$rootScope', function($rootScope) {

      var self = this;

      this.customDisabledOptions = componentHelper.customDisabledOptions();

      this.setAlaEnabled = function(alaEnabled) {
        if (alaEnabled === self.alaEnabled) {
          return;
        }

        self.alaEnabled = alaEnabled;
        $('#account_whiteops_cache_pct').val(alaEnabled ? self.whiteopsCachePct : 0);
        $('#account_protected_cache_pct').val(alaEnabled ? self.protectedCachePct : 0);
        togglealaEnabledVisibility();
      };

      var togglealaEnabledVisibility = function() {
        $('.ala-carte-hidden').toggleClass('hidden', self.alaEnabled);
        $('.ala-carte-shown').toggleClass('hidden', !self.alaEnabled);
      };

      this.$onInit = function() {
        togglealaEnabledVisibility();
      };

    }]
});

import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('targetedIabTier2Categories',  {
    bindings: {
      formName: '@',
      formId: '@',
      allowList: '@',
      iabSource: '@',
      selectedLists: '<',
      selectedCategoryCodes: '<',
      categoryOptions: '<',
    },
    template: require("../templates/targetedIabTier2Categories.html"),
    controllerAs: '$targetedIabTier2CategoriesCtrl',
    controller: function() {
      this.allowlistButtonOptions = componentHelper.targetingOptions();
      this.targetingSourceOptions = componentHelper.targetingSourceOptions();
    }
});

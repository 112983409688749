import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('usPrivacyTargeting',  {
    bindings: {
      formName: '@',
      formId: '@',
      values: '<',
      paramRequired: '<',
      allowList: '@',
      readOnly: '<'
    },
    template: require("../templates/usPrivacyTargeting.html"),
    controllerAs: '$usPrivacyTargetingCtrl',
    controller: function() {
      this.allowlistOptions = componentHelper.allowlistOptions();
    }
});

import HighchartsBuilder from '../../modules/highcharts_builder.js';

angular.module('vastdesk')
.component('comboChart', {
  bindings: {
    chartId: '@',
    pointsType: '@',
    dateRange: '@',
    forecastDateRange: '@',
    timezone: '@',
    metrics: '=',
    currencySymbol: '@'
  },
  template: '<div class="graphChart comboChart"><div></div></div>',
  controller: ['$scope', '$rootScope', '$element', function($scope, $rootScope, $element) {

    var self = this;
    var onInitLoad = $.Deferred();
    var highchartsBuilder, colors;

    this.$onInit = function() {
      highchartsBuilder = new HighchartsBuilder(self.timezone);
      colors = highchartsBuilder.chartColors;
      onInitLoad.resolve();
    };

    var chartDiv = $element.find('div');
    var chart;

    var getMetrics = function() {
      return self.metrics || [];
    };

    var setChart = function(chartData, forecastData) {
      var dataPoints = highchartsBuilder.getDataPointsFromDateRange(self.dateRange, {
        interval: self.pointsType,
        forecastDateRange: self.forecastDateRange
      });

      var dateFormat = highchartsBuilder.getDateFormatFromInterval(self.pointsType);

      var metric1 = getMetrics()[0] || [];
      var metric2 = getMetrics()[1] || [];
      var formatType1 = highchartsBuilder.formatTypeForMetric(metric1[0]);
      var formatType2 = highchartsBuilder.formatTypeForMetric(metric2[0]);

      chart = chartDiv.highcharts({
        chart: {
          zooming: {
            type: 'xy'
          },
          backgroundColor: null,
          animation: false
        },
        title: {
          text: ''
        },
        xAxis: [{
          categories: _.map(dataPoints, function (time) {
            return time.format(dateFormat);
          }),
          crosshair: true,
          tickLength: 0,
          lineColor: '#ccd6eb',
          labels: {
            reserveSpace: true,
            y: 10,
            style: {
              color: '#acacac',
              fontSize: '9px'
            }
          }
        }],
        credits: {enabled: false},
        colors: colors,
        legend: highchartsBuilder.defaultLegend(),
        plotOptions: {
          series: {
            marker: {
              enabled: false
            }
          }
        },
        yAxis: [
          {//secondary
            title: {text: ''},
            labels: {
              formatter: function() {
                return highchartsBuilder.yAxisFormatter(formatType1, this, self.currencySymbol);
              },
              reserveSpace: true,
              style: {
                fontSize: '9px',
                fontWeight: 'bold',
                color: colors[0],
                opposite: true
              }
            },
            opposite: true,
            gridLineColor: '#d7d7d7'
          },
          {//primary
            gridLineWidth: 0,
            title: {text: ''},
            labels: {
              formatter: function() {
                return highchartsBuilder.yAxisFormatter(formatType2, this, self.currencySymbol);
              },
              reserveSpace: true,
              style: {
                fontSize: '9px',
                fontWeight: 'bold',
                color: colors[1]
              }
            },
            gridLineColor: '#d7d7d7'
          }
        ],
        tooltip: {
          formatter: function () {
            return highchartsBuilder.tooltipFormatter(this, null, self.currencySymbol);
          },
          shared: true,
          backgroundColor: '#FFFFFF',
          borderColor: '#000000',
          style: {
            opacity: '0.95'
          }
        },
        series: [
          metric1Series(dataPoints, chartData, metric1, formatType1, false),
          metric2Series(dataPoints, chartData, metric2, formatType2, false)
        ]
      });

      if (highchartsBuilder.includeForecastingSeries(self.forecastDateRange, self.pointsType, forecastData)) {
        chart.highcharts().addSeries(
          metric1Series(dataPoints, forecastData, metric1, formatType1, true)
        );
        chart.highcharts().addSeries(
          metric2Series(dataPoints, forecastData, metric2, formatType2, true)
        );
      }
    };

    var metric1Series = function(dataPoints, chartData, metric, formatType, forecast) {
      var metric1FillColor = forecast ? highchartsBuilder.stripeFillColor(colors[0]) : highchartsBuilder.springyAreaSplineFill(colors[0]);

      return {
        name: highchartsBuilder.seriesNameFor(metric[1], forecast),
        type: 'areaspline',
        data: highchartsBuilder.getDataFromTimeValues(dataPoints, chartData, metric[0], highchartsBuilder.forecastDataFromTimeValuesOptions(self, forecast)),
        formatType: formatType,
        color: colors[0],
        lineWidth: 2,
        fillColor: metric1FillColor,
        connectNulls: false,
        dashStyle: forecast ? 'dash' : 'solid',
        legendSymbol: 'rectangle',
        legendColor: forecast ? highchartsBuilder.stripeFillColor(colors[0]) : null
      };
    };

    var metric2Series = function(dataPoints, chartData, metric, formatType, forecast) {
      return {
        name: highchartsBuilder.seriesNameFor(metric[1], forecast),
        type: 'spline',
        yAxis: 1,
        data: highchartsBuilder.getDataFromTimeValues(dataPoints, chartData, metric[0], highchartsBuilder.forecastDataFromTimeValuesOptions(self, forecast)),
        formatType: formatType,
        color: colors[1],
        shadow: highchartsBuilder.defaultShadow(),
        lineWidth: 2,
        connectNulls: false,
        dashStyle: forecast ? 'dash' : 'solid',
        legendSymbol: forecast ? 'rectangle' : 'lineMarker',
        legendColor: forecast ? highchartsBuilder.stripeFillColor(colors[1]) : null
      };
    };

    $rootScope.$on('dashboardDateRangeChange', function(e, args) {
      self.dateRange = args.dateRange;
    });

    $rootScope.$on('forecastDashboardDateRangeChange', function(e, args) {
      self.forecastDateRange = args.forecastDateRange;
    });

    $scope.$on('setHighchartLoading', function(e, args) {
      highchartsBuilder.loadData(onInitLoad, chartDiv, self, args);
    });

    $scope.$on('refreshHighchart', function(e, args) {
      highchartsBuilder.refreshData(onInitLoad, chartDiv, self, args, function() {
        setChart(args.data, args.forecastData);
      });
    });

    $scope.$on('reflowHighcharts', function(e, args) {
      highchartsBuilder.deferredReflowChart(onInitLoad, chartDiv);
    });

    $rootScope.$on('reportCurrencySymbolChange', function (e, args) {
      self.currencySymbol = args.currencySymbol;
    });

  }]
});
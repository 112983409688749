angular.module('vastdesk').
  component('accountStatus',  {
    bindings: {
      disabled: '<',
      status: '@',
      reactivationDate: '@'
    },
    template: require("../templates/accountStatus.html"),
    controllerAs: '$accountStatusCtrl',
    controller: function() {
      var self = this;

      this.$onInit = function() {
        self.initialReactivationDate = self.reactivationDate;
      };

      this.setStatus = function(status) {
        if (self.disabled) {
          return;
        }

        self.status = status;

        if (status === 'paused') {
          self.reactivationDate = self.initialReactivationDate;
        } else {
          self.reactivationDate = "";
        }
      };
    }
});

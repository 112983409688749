import componentHelper from '../../modules/component_helper.js';
import DecimalEnforcer from '../../modules/decimalEnforcer.js';

angular.module('vastdesk').
  component('mediaFileBitRateTargeting',  {
    bindings: {
      formName: '@',
      tooltipText: '@',
      readOnly: '<',
      bitRateTargeting: '<',
      minBitRate: '<',
      maxBitRate: '<',
      blockingUnknownBitRates: '<'
    },
    template: require("../templates/mediaFileBitRateTargeting.html"),
    controllerAs: '$mediaFileBitRateCtrl',
    controller: function() {
      var self = this;
      var decimalEnforcer = new DecimalEnforcer();

      this.bitRateButtonOptions = componentHelper.allCustomOptions();

      this.customTooltip = function(condition) {
        return componentHelper.valueIfCondition(condition, self.tooltipText);
      };

      this.setMinBitRate = function(val) {
        self.minBitRate = decimalEnforcer.enforce(val, 'round', 0);
      };

      this.setMaxBitRate = function(val) {
        self.maxBitRate = decimalEnforcer.enforce(val, 'round', 0);
      };
    }
});

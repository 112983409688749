import platformUtilities from '../../modules/platform_utilities.js';
import FlightDatesHelper from '../../modules/flight_dates_helper.js';

angular.module('vastdesk').
  component('budgetSpend', {
    bindings: {
      spendId: '@',
      rateCurrency: '@',
      currencyMetrics: '<'
    },
    controller: ['$element', '$rootScope', function($element, $rootScope) {

      var self = this;
      var bodySelector = $('body');
      var budgetsElement;
      var flightDatesElement = $('#flight_dates');
      const flightDatesHelper = new FlightDatesHelper();
      let budgetFlightDatesInactive = false;

      const togglePacingFields = function(add_form) {
        setTimeout(function() {
          const budgetPeriod = add_form.find('.budget-period input:checked').val();
          const flightDateEnabled = $('.flight-dates input:checked').val();

          if (budgetPeriod === 'lifetime' && flightDateEnabled !== 'true' && visibleBudgetFlightDates(add_form).length < 1) {
            add_form.find('.budget_pacing .btn-group label[for$=budget_pacing_]').click();
            add_form.find('.budget_pacing .btn-group label[for*=budget_pacing_even]').addClass('disabled').tooltip();
            add_form.find('.budget_pacing .btn-group label[for*=budget_pacing_front_loaded]').addClass('disabled').tooltip();
          }
          else {
            add_form.find('.budget_pacing .btn-group label[for*=budget_pacing_even]').removeClass('disabled').tooltip('dispose');
            add_form.find('.budget_pacing .btn-group label[for*=budget_pacing_front_loaded]').removeClass('disabled').tooltip('dispose');
          }
        }, 0);
      };

      const toggleFieldVisibilityBasedOnMetric = function(add_form) {
        setTimeout(function() {
          const budgetMetric = add_form.find('input[id*=budget_metric]:checked').val();
          add_form.find('.budget_vast_caching_adjustment').toggleClass('hidden', budgetMetric !== 'impressions');
          add_form.find('.budget-currency-addon').toggleClass('hidden', !_.contains(self.currencyMetrics, budgetMetric));
        }, 0);
      };

      const broadcastSupplyAllocationChange = function() {
        setTimeout(function(){
          $rootScope.$broadcast('budgetSupplyAllocationChange');
        }, 0);
      };

      const broadcastBudgetCountChange = function() {
        setTimeout(function(){
          $rootScope.$broadcast('budgetCountChange', {
            spendId: self.spendId
          });
        }, 0);
      };

      const setInitialBudgetCurrency = function(addedBudget) {
        if (self.rateCurrency && self.rateCurrency !== '') {
          addedBudget.find('select[name*="[budget_value_currency]"]').val(self.rateCurrency);
        }
      };

      const setFlightingActive = function(add_form, flightingActive) {
        add_form.find('.flighting-activator').toggleClass('active', flightingActive);
        add_form.find('.flighting-card').toggleClass('hidden', !flightingActive);
      };

      const visibleBudgetFlightDates = function(add_form) {
        return add_form.find('.budget-flight-dates:not([style*="display: none"])').not('.hidden');
      };

      const budgetFlightDateTimezone = function(flighting_card) {
        return $(flighting_card).find('select[name$="[budget_flight_dates_timezone]"]').val() || 'Etc/UTC';
      };

      const setBudgetFlightDatesInactive = (applyScope) => {
        setTimeout(function() {
          const budgetData = _.map($('.budget-group:not([style*="display: none"])'), function(add_form) {
            const budgetFlights = _.map(visibleBudgetFlightDates($(add_form)).find('input[name$="[flight_dates]"]'), function(input) {
              return $(input).val().split(' - ');
            });

            return [budgetFlights, budgetFlightDateTimezone(add_form)];
          });

          const temp = flightDatesHelper.inactiveByBudgetFlightDates(budgetData);

          if (temp !== budgetFlightDatesInactive) {
            budgetFlightDatesInactive = temp;

            $rootScope.$broadcast('budgetFlightDatesInactiveChange', {
              budgetFlightDatesInactive: budgetFlightDatesInactive,
              applyScope: applyScope
            });
          }
        }, 0);
      };

      const setPacingVisibility = (add_form) => {
        const budgetFlightDatesPacing = add_form.find('input[type="checkbox"][name$="[budget_flight_dates_pacing]"]').prop('checked');

        add_form.find('.budget_pacing').toggleClass('hidden', budgetFlightDatesPacing);
        add_form.find('.budget_flight_date_budget_pacing').toggleClass('hidden', !budgetFlightDatesPacing);
      };

      this.$onInit = function() {
        budgetsElement = $element.parents('#' + self.spendId);

        bodySelector.on('cocoon:after-insert', '#' + self.spendId, function(e, addedBudget) {
          var that = this;
          addedBudget.find('.budget_pacing .btn-group label[for*=budget_pacing_even]').click();
          broadcastSupplyAllocationChange();
          broadcastBudgetCountChange();
          setInitialBudgetCurrency(addedBudget);

          setTimeout(function() {
            $(that).find('select').defaultChosen();
            setBudgetFlightDatesInactive(true);
          }, 0);
        });

        bodySelector.on('cocoon:after-insert', '#budget_flight_dates', function(e, addedBudgetFlightDate) {
          const add_form = $(this).closest('.add_form');
          $(this).find('.datehourrange-future').daterangepicker(platformUtilities.dateRangeFutureOptions);
          togglePacingFields(add_form);
          setBudgetFlightDatesInactive(true);
          setPacingVisibility(add_form);
        });

        bodySelector.on('cocoon:after-remove', '#budget_flight_dates', function(e) {
          const add_form = $(this).closest('.add_form');

          setTimeout(function() {
            if (visibleBudgetFlightDates(add_form).length < 1) {
              add_form.find('.flighting-card .delgroup').click();
            }

            setBudgetFlightDatesInactive(true);
          }, 0);
        });

        bodySelector.on('cocoon:after-remove', '#' + self.spendId, function(e) {
          broadcastSupplyAllocationChange();
          broadcastBudgetCountChange();
          setBudgetFlightDatesInactive(true);
        });

        budgetsElement.on('click', '.flighting-activator', function() {
          const add_form = $(this).closest('.add_form');
          setFlightingActive(add_form, true);

          setTimeout(function() {
            if (visibleBudgetFlightDates(add_form).length < 1) {
              add_form.find('.add_budget_flight_date').trigger('click');
            }
          }, 0);
        });

        budgetsElement.on('click', '.flighting-card .delgroup', function() {
          const add_form = $(this).closest('.add_form');
          setFlightingActive(add_form, false);
          visibleBudgetFlightDates(add_form).find('.remove_fields').trigger('click');
          add_form.find('input[type="checkbox"][name$="[budget_flight_dates_pacing]"]').prop('checked', false);

          setTimeout(function() {
            togglePacingFields(add_form);
            setPacingVisibility(add_form);
          }, 0);
        });

        budgetsElement.on('change', 'input[type="checkbox"][name$="[budget_flight_dates_pacing]"]', function() {
          const add_form = $(this).closest('.add_form');
          setPacingVisibility(add_form);
        });

        budgetsElement.on('change', '.budget-period', function() {
          const add_form = $(this).closest('.add_form');
          togglePacingFields(add_form);
        });

        budgetsElement.on('change', 'select[name$="[budget_flight_dates_timezone]"], input[name$="[flight_dates]"]', function() {
          setBudgetFlightDatesInactive(true);
        });

        flightDatesElement.on('click', 'label.flight-dates.btn', function() {
          budgetsElement.find('.add_form').each(function(index, add_form) {
            togglePacingFields($(add_form));
          });
        });

        budgetsElement.on('change', 'input[id*=budget_metric]', function() {
          const add_form = $(this).closest('.add_form');
          toggleFieldVisibilityBasedOnMetric(add_form);
        });

        budgetsElement.on('change', 'input[type="checkbox"][name$="[ignore_supply_allocations]"]', function() {
          broadcastSupplyAllocationChange();
        });

        setTimeout(function() {
          budgetsElement.find('.add_form').each(function(index, add_form) {
            togglePacingFields($(add_form));
          });

          setBudgetFlightDatesInactive(true);
        }, 0);
      };

    }]
  });

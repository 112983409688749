angular.module('vastdesk').
  component('accountPodsEnabled',  {
    bindings: {
      podsEnabled: '<',
      readOnly: '<'
    },
    template: '<form-check-box-tag field-id="account_pods_enabled" field-name="account[pods_enabled]" model="$accountPodsEnabledCtrl.podsEnabled" label-display="Pods" click-cb="$accountPodsEnabledCtrl.podsEnabledCb" read-only="$accountPodsEnabledCtrl.readOnly"></form-check-box-tag>',
    controllerAs: '$accountPodsEnabledCtrl',
    controller: function() {

      this.podsEnabledCb = function(val) {
        if (val) {
          $('#account_reporting_view').val('ctv_publisher').trigger('chosen:updated');
        }
      };

    }
});

import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk')
.component('supplyAllocationDefault', {
  bindings: {
    formName: '@',
    customSupplyAllocationDefault: '<',
    supplyAllocationDefault: '@',
    supplyAllocationDefaultOptions: '<'
  },
  template: require("../templates/supplyAllocationDefault.html"),
  controllerAs: '$supplyAllocationDefaultCtrl',
  controller: ["$element", function($element) {

    this.defaultOptions = componentHelper.customNoneOptions();

  }]
});
import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('bulkPostalCodeTargeting',  {
    bindings: {
        countryCode: '<',
        inputPostalCodes: '<'
    },
    template: require("../templates/bulkPostalCodeTargeting.html"),
    controllerAs: '$bulkPostalCodeTargetingCtrl',
    controller: function () {
        const prefixPostalCodes = ($select, postalCodes, countryCode) => {
          const prefixedPostalCodes = postalCodes.map((item) => {
            if (!countryCode || countryCode === '') {
              // remove country prefixes
              return item.replace(/^(.+?):(.+?)$/, `$2`);
            } else if (item.indexOf(':') == -1) {
              // add country prefixes
              return `${countryCode}:${item}`;
            } else {
              // replace country prefixes
              return item.replace(/^(.+?):(.+?)$/, `${countryCode}:$2`);
            }
          });
          // replace options in target select (chosen.js only)
          $select.empty();
          $.each(prefixedPostalCodes, (key, value) => {
            $select
              .append($('<option></option>')
              .attr('value', value)
              .prop('selected', true)
              .text(value));
          });
        };

        const updatePostalCodes = (inputPostalCodes, countryCode) => {
          const $select = $('#bulk_postal_codes');
          if (inputPostalCodes && inputPostalCodes.length > 0) {
              prefixPostalCodes($select, inputPostalCodes, countryCode[0]);
          }
          let placeholder = 'US:10003, GB:SW1A, FR:75001, AR:B1228';
          if (countryCode && countryCode[0] && countryCode[0] !== '') {
              placeholder = '10003, SW1A, 75001, B1228';
          }
          $select.attr('data-placeholder', placeholder);
          setTimeout(() => $select.trigger('chosen:updated'), 0);
        };
        
        this.bulkUpdatePostalCodes = () => {
            setTimeout(() => updatePostalCodes(this.inputPostalCodes, this.countryCode), 100);
        };
    }
});

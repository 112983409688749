angular.module('vastdesk').
  component('vendorSelectionDefaults',  {
    bindings: {
      defaultType: '@',
      defaultPostImp: '<',
      preBidBlockingEnabled: '<',
      postImpEnabled: '<',
      preBidSpringserve: '<',
      preBidWhiteops: '<',
      preBidProtected: '<',
      postImpPercentageWhiteops: '<',
      postImpPercentageProtected: '<',
      postImpPercentageMoat: '<',
      postImpPercentageIas: '<',
      postImpPercentageForensiq: '<',
      platformIconUrl: '@',
      preBidDisabled: '<'
    },
    template: require("../templates/vendorSelectionDefaults.html"),
    controllerAs: '$vendorSelectionDefaultsCtrl',
    controller: ['$timeout', function($timeout) {

      var self = this;

      this.setPreBidComponents = function(checked) {
        self.preBidWhiteops = checked;
        self.preBidSpringserve = checked;
        self.preBidProtected = checked;
      };

      this.setPostImpSettings = function(enabled) {
        var postImpVal = (enabled) ? self.defaultPostImp : 0;
        self.postImpPercentageWhiteops = postImpVal;
        self.postImpPercentageMoat = postImpVal;
        self.postImpPercentageIas = postImpVal;
        self.postImpPercentageForensiq = postImpVal;
        self.postImpPercentageProtected = postImpVal;
      };

      this.setPreBidBlockingEnabled = function() {
        $timeout(function() {
         self.preBidBlockingEnabled = self.preBidWhiteops ||
            self.preBidSpringserve ||
            self.preBidProtected;

          self.setPreBidEnabledIndeterminate();
        });
      };

      var preBidComponentsEqual = function() {
        return self.preBidProtected === self.preBidSpringserve &&
          (self.preBidProtected === self.preBidWhiteops || self.preBidWhiteopsDisabled);
      };

      this.setPreBidEnabledIndeterminate = function() {
        $('#account_setting_' + self.defaultType + '_pre_bid_blocking_enabled_default').prop("indeterminate", !preBidComponentsEqual());
      };

      this.setPostImpEnabled = function() {
        self.postImpEnabled = self.postImpPercentageWhiteops > 0 ||
          self.postImpPercentageProtected > 0 ||
          self.postImpPercentageMoat > 0 ||
          self.postImpPercentageIas > 0 ||
          self.postImpPercentageForensiq > 0;
      };

      this.$onInit = function() {
        $timeout(function() {
          self.setPreBidEnabledIndeterminate();
        });
      };
    }]
});

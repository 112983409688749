import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('countryTargeting',  {
    bindings: {
      formName: '@',
      formId: '@',
      allowList: '@',
      allowListAttr: '@',
      geoSource: '@',
      selectedLists: '<',
      selectedOptions: '<'
    },
    template: require("../templates/countryTargeting.html"),
    controllerAs: '$countryTargetingCtrl',
    controller: function() {
      this.allowlistButtonOptions = componentHelper.targetingOptions();
      this.targetingSourceOptions = componentHelper.targetingSourceOptions();
    }
});

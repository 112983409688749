angular.module('vastdesk')
.component('ssObjectExport', {
  bindings: {
    macros: '=',
    keys: '=',
    exportTypes: '=',
    categories: '=',
    demandKeyIds: '=',
    showSyndicatedPixel: '=',
    format: '@',
    exportType: '@',
    environment: '@',
    syndicatedPixelUrl: '@',
    objectId: '@',
    revenueModelType: '@',
    omsdkEnabled: '@',
    ssObjectType: '@',
    openMarketEnabled: '<',
    platformName: '@',
    supplyClass: '@',
    totalConnectPlusResponseType: '@',
    supplyTagId: '@',
    accountId: '@',
    dynamicTile: '<',
    customResponse: '<',
    screenSetting: '@',
    hasPod: '<'
  },
  template: require("../templates/ssObjectExport.html"),
  controllerAs: '$ssObjectExportCtrl',
  controller: ['$scope', '$rootScope', '$timeout', function($scope, $rootScope, $timeout) {

    var self = this;
    var onInitLoad = $.Deferred();
    var throttledUrlRequest;
    var theme = window.platformTheme;
    var keyIds = [];
    this.macroMappings = {};
    this.macroWikiUrl = theme.macroWikiUrl;

    var combinedKeyIds = function() {
      var allKeyIds = (self.demandKeyIds || []).concat(keyIds);
      return _.uniq(allKeyIds);
    };

    var supplyEnvironment = function () {
      return self.environment || 'desktop';
    };

    this.totalConnectPlusSupplyClass = function() {
      return _.contains(['3'], self.supplyClass);
    };

    this.rtbTotalConnectPlusResponseType = function() {
      return _.contains(['rtb'], self.totalConnectPlusResponseType);
    };

    var dynamicPod = function() {
      return self.dynamicPod ||
       $('input[id*=custom_ad_slot_duration_false]').prop('checked') === true;
    };

    this.setExportTag = function() {
      throttledUrlRequest();
    };

    this.environmentLabel = function() {
      var label = 'your supply tag';

      if (self.ssObjectType === 'Schedule') {
        label = 'the ctv';
      }
      else if (self.ssObjectType === 'Router') {
        label = 'your router';
      }
      return label;
    };

    var getMacros = function() {
      return _.map(self.macroMappings, function(opts, macro) {
        if(opts.selected === true && opts.category !== 'custom keys') {
          return macro;
        }
      }).filter(Boolean);
    };

    var getKeys = function() {
      return _.map(self.macroMappings, function(opts, macro) {
        if(opts.selected === true && opts.category === 'custom keys') {
          return macro;
        }
      }).filter(Boolean);
    };

    var getPath = function() {
      if (self.ssObjectType === 'Tag') {
        return '/supply/export_url.json';
      }
      else if (self.ssObjectType === 'Schedule') {
        return '/supply/pod_schedules/export_url.json';
      }
      else if (self.ssObjectType === 'Router') {
        return '/supply/routers/export_url.json';
      }
    };

    var applyTagResponse = function(response) {
      self.exportTag = response.url;
      $scope.$apply();
    };

    this.toggleSelectedMacroWithParam = function(macro, param) {
      if (environmentMacroDisabled(macro, param)) {
        return;
      }
      $timeout(function() {
        self.toggleSelectedMacro(macro);
      }, 0);
    };

    this.toggleSelectedMacro = function (macro) {
      macro.selected = !macro.selected;
      self.setExportTag();
    };

    this.hideCategory = function (category) {
      return (category === 'custom keys' && combinedKeyIds().length < 1) ||
        (category === 'pod' && !dynamicPod());
    };

    this.setCategoryChecked = function (category, checked) {
      _.each(self.macroMappings, function (macro) {
        if (macro.category === category && !macro.hidden && !macro.disabled) {
          macro.selected = checked;
        }
      });
      self.setExportTag();
    };

    this.collapseCategory = function(category) {
      return 'collapse-' + category.replace(/\s/g, '-');
    };

    var initMacros = function () {
      var mappings = {};
      _.each(self.macros, function(obj) {
        mappings[obj[0]] = obj[1];
      });

      _.each(self.keys, function (key) {
        if (key && !mappings[key[0]]) {
          var preselected = _.contains(combinedKeyIds(), key[1].id.toString()) || null;

          var keyMapping = {
            label: key[1].label,
            category: 'custom keys',
            selected: preselected,
            hidden: !preselected
          };

          mappings[key[0]] = keyMapping;
        }
      });

      self.macroMappings = mappings;
    };

    var setSizesSelected = function() {
      var sizesMacro = self.macroMappings['sizes'];
      if (!sizesMacro) {
        return;
      }

      if (self.dynamicTile) {
        if (!sizesMacro.selected) {
          self.toggleSelectedMacro(sizesMacro);
        }
      }
    };

    var setPricePaidSelected = function () {
      var ppMacro = self.macroMappings['pp'];
      if (!ppMacro) {
        return;
      }

      if (self.revenueModelType === '2') {
        if (!ppMacro.selected) {
          self.toggleSelectedMacro(ppMacro);
        }
      }
      else if (ppMacro.selected) {
        self.toggleSelectedMacro(ppMacro);
      }
    };

    var setScreenSetting = function () {
      var macro = self.macroMappings['screen_id'];
      if (!macro) {
        return;
      }

      var screenSettingSet = !!(self.screenSetting && self.screenSetting !== '');

      macro.selected = self.format === 'tile' && screenSettingSet;
      macro.hidden = !macro.selected;
    };

    var setUniquePodId = function () {
      var macro = self.macroMappings['unique_pod_id'];
      if (!macro) {
        return;
      }

      macro.hidden = !self.hasPod;
      if (macro.hidden) {
        macro.selected = false;
      }
    };

    var setOmsdkSelected = function () {
      if (self.format !== 'video') {
        return;
      }

      var macros = [self.macroMappings['omidpn'], self.macroMappings['omidpv']];

      _.each(macros, function(macro) {

          if (!macro) { return; }

          if (self.omsdkEnabled==='true') {
            if (!macro.selected) {
              self.toggleSelectedMacro(macro);
            }
          } else if (macro.selected){
              self.toggleSelectedMacro(macro);
          }

        }
      );
    };

    var disablePreSelectedMacrosWhenRtbTcPlusResponseType = function () {
      if (self.totalConnectPlusResponseType === 'rtb' && self.totalConnectPlusSupplyClass()) {
        _.each(self.macroMappings, function(opts, macro) {
          opts.selected = false;
          macro.disabled = true;
          return;
        });
      }
    };

    var setSchainSelected = function() {
      var sChainMacro = self.macroMappings['schain'];
      if (!sChainMacro) {
        return;
      }

      if (self.openMarketEnabled) {
        if (!sChainMacro.selected) {
          self.toggleSelectedMacro(sChainMacro);
        }
      }
      else if (sChainMacro.selected) {
        self.toggleSelectedMacro(sChainMacro);
      }
    };

    var setEnvironmentMacros = function () {
      _.each(self.macroMappings, function (macro, k) {
        if (macro.category === 'custom keys') {
          return;
        }
        self.macroMappings[k].selected = environmentMacroSelected(macro, k);
        self.macroMappings[k].disabled = environmentMacroDisabled(macro, k);
      });
    };

    var environmentMacroSelected = function (macro, k) {
      return _.contains(macro.envs, supplyEnvironment());
    };

    var environmentMacroDisabled = function (macro, k) {
      return (k === 'url' && _.contains(['in_app', 'ctv'], supplyEnvironment())) ||
        (_.contains(['w', 'h', 'consent'], k) && supplyEnvironment() === 'ctv');
    };

    var updateKeyMacrosSelected = function () {
      _.each(self.keys, function (key) {
        var macro = self.macroMappings[key[0]];

        if (!macro) {
          return;
        }

        if (_.contains(combinedKeyIds(), key[1].id.toString())) {
          macro.hidden = false;
          if (macro.selected !== false) {
            macro.selected = true;
          }
        }
        else {
          macro.hidden = true;
          if (macro.selected) {
            macro.selected = null;
          }
        }
      });
    };

    $rootScope.$on('dynamicPodChange', function(e, args) {
      self.dynamicPod = args.dynamicPod;
    });

    $rootScope.$on('screenSettingChange', function(e, args) {
      self.screenSetting = args.screenSetting;
      setScreenSetting();
      self.setExportTag();
    });

    $rootScope.$on('hasPodChange', function(e, args) {
      self.hasPod = args.hasPod;
      setUniquePodId();
      self.setExportTag();
    });

    $rootScope.$on('customResponseChanged', function (e, args) {
      self.customResponse = args.customResponse;
      self.setExportTag();
    });

    $rootScope.$on('dynamicTileChanged', function (e, args) {
      self.dynamicTile = args.dynamicTile;
      setSizesSelected();
      self.setExportTag();
    });

    $scope.$on('keyIdsChange', function (e, args) {
      $.when( onInitLoad ).done(function() {
        if (Array.isArray(args.keyIds)) {
          keyIds = args.keyIds;
        }

        setTimeout(function() {
          updateKeyMacrosSelected();
          self.setExportTag();
        }, 0);
      });
    });

    $scope.$on('demandKeyIdsChange', function (e, args) {
      $.when( onInitLoad ).done(function() {
        if (Array.isArray(args.demandKeyIds)) {
          self.demandKeyIds = args.demandKeyIds;
        }
        setTimeout(function() {
          updateKeyMacrosSelected();
          self.setExportTag();
        }, 0);
      });
    });

    $scope.$on('omsdkEnabledChange', function (e, args) {
      $.when( onInitLoad ).done(function() {
        setTimeout(function() {
          setOmsdkSelected();
        }, 0);
      });
    });

    $scope.$on('revenueModelTypeChange', function (e, args) {
      $.when( onInitLoad ).done(function() {
        setTimeout(function() {
          setPricePaidSelected();
        }, 0);
      });
    });

    $rootScope.$on('environmentChange', function (e, args) {
      self.environment = args.environment;

      $.when( onInitLoad ).done(function() {
        setTimeout(function() {
          setEnvironmentMacros();
          self.setExportTag();
        }, 0);
      });
    });

    $rootScope.$on('customAdSlotDurationChange', function (e, args) {
      $.when( onInitLoad ).done(function() {
        setTimeout(function() {
          self.setExportTag();
        }, 0);
      });
    });

    $rootScope.$on('openMarketEnabledChange', function(e, args) {
      self.openMarketEnabled = args.openMarketEnabled;

      $.when( onInitLoad ).done(function() {
        setTimeout(function() {
          setSchainSelected();
        }, 0);
      });
    });

    this.$onInit = function() {
      throttledUrlRequest = _.throttle(function () {
        var path = getPath();

        if (!path) {
          return;
        }
        $.post(path, {
          'supply_tag_id': self.objectId,
          'ss_object_id': self.objectId,
          'environment': supplyEnvironment(),
          'export_type': self.exportType,
          'selected_macros': getMacros(),
          'selected_keys': getKeys(),
          'dynamic_pod': dynamicPod(),
          'format': self.format,
          'custom_response': self.customResponse
        }).then(applyTagResponse);
      }, 100, {leading: false});

      initMacros();
      setEnvironmentMacros();
      setPricePaidSelected();
      setOmsdkSelected();
      setSchainSelected();
      disablePreSelectedMacrosWhenRtbTcPlusResponseType();
      setSizesSelected();
      setScreenSetting();
      setUniquePodId();

      setTimeout(function () {
        self.setExportTag();
      }, 0);

      onInitLoad.resolve();
    };

  }]
});

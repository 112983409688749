angular.module('vastdesk').
  component('minMaxDisabler', {
    bindings: {
      formName: '@',
      fieldName: '@',
      fieldValue: '@',
      disableTarget: '@',
      disableType: '@',
      optgroups: '='
    },
    template: require("../templates/minMaxDisabler.html"),
    controllerAs: '$minMaxCtrl',
    controller: ['$scope', '$element', function($scope, $element) {

      var self = this;

      var setDisabledAttribute = function(currentVal, option) {
        var optionVal = $(option).val();

        if (!currentVal || currentVal === '' || !optionVal || optionVal === '') {
          $(option).removeAttr('disabled');
        }
        else if (self.disableType === 'min' && optionVal > currentVal) {
          $(option).attr('disabled', 'disabled');
        }
        else if (self.disableType === 'max' && optionVal < currentVal) {
          $(option).attr('disabled', 'disabled');
        }
        else {
          $(option).removeAttr('disabled');
        }
      };

      this.setDisabledOptions = function() {
        if (self.disableTarget && self.disableTarget !== '') {
          var currentVal = self.fieldValue;
          var targetSelect = $(self.disableTarget);

          targetSelect.find('option').each(function() {
            setDisabledAttribute(currentVal, this);
          });

          targetSelect.trigger('chosen:updated');
        }
      };

      this.$onInit = function() {
        setTimeout(function() {
          self.setDisabledOptions();
        }, 0);
      };

    }]
});

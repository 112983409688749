angular.module('vastdesk').filter('boldLetters', () => {
    return (val, searchText) => {
        if (!searchText) {
            return val;
        }
        const filtered = [];
        const regex = new RegExp(searchText, 'gi');

        return val.replace(regex, match => '<b>' + match + '</b>');
    };
});

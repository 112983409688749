import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('competitiveExclusionAdvertiserDomains',  {
    bindings: {
      formName: '@',
      formId: '@',
      targetingEnabled: '<',
      selectedOptions: '<'
    },
    template: require("../templates/competitiveExclusionAdvertiserDomains.html"),
    controllerAs: '$competitiveExclusionAdvertiserDomainsCtrl',
    controller: function() {
      var self = this;

      this.targetingEnabledButtonOptions = componentHelper.attributeEnabledMappings();

      this.targetingEnabledTooltip = function(val) {
        if (val) {
          return 'Applies to all demand sources that return a valid adomain in the VAST response';
        }
      };
    }
});

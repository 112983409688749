class SelectOptionDisabler {
  constructor(selectSelector, dependentOptions) {
    this.selectSelector = selectSelector;
    this.dependentOptions = dependentOptions;
  }

  selectedOptions(selects) {
    return _.chain(selects)
            .map(function(s) {
              return $(s).val();
            })
            .without('')
            .value();
  }

  disableParamOptions(wrapper) {
    wrapper = wrapper || 'body';
    var selects = $(wrapper).find(this.selectSelector);
    var selected = this.selectedOptions(selects);
    var self = this;

    if (self.dependentOptions) {
      _.each(self.dependentOptions, function(v, k) {
        if (!_.contains(selected, v)) {
          selected = selected.concat(k);
        }
      });
    }

    selects.find('option').removeAttr('disabled');

    _.each(selected, function(param) {
      selects.find('option:not(:selected)[value="' + param + '"]').attr('disabled','disabled');
    });

    selects.trigger('chosen:updated');
  }
}

export default SelectOptionDisabler;

import WaterfallHelper from '../../modules/waterfall_helper.js';
import WaterfallModalHelper from '../../modules/waterfall_modal_helper.js';

angular.module('vastdesk').
  component('campaignDemandTags',  {
    bindings: {
      editable: '<',
      addExistingOnly: '<',
      batchActions: '<',
      batchUpdatePath: '@',
      authToken: '@',
      modalPath: '@',
      demandTagIds: '<',
      newDemandPath: '@',
      newProgrammaticGuaranteedPath: '@',
      campaignId: '@',
      demandPartnerId: '@',
      costModelType: '<',
      hasRate: '<',
      dupingDemand: '<',
      includePgOption: '<'
    },
    template: require('../templates/campaignDemandTags.html'),
    controllerAs: '$campaignDemandTagsCtrl',
    controller: ['$scope', '$rootScope', function($scope, $rootScope) {

      this.selectedDemandTagBulkActionIds = [];

      var self = this;
      var serverSideTable = $('#campaign-demand-tags-table');
      var serverSideDataTable;
      var bodySelector = $('body');
      var modal = $('#tag_modal');
      var modalTable = $('#tag_modal table');
      var selectedIds = [];
      var waterfallHelper = new WaterfallHelper();
      var waterfallModalHelper = new WaterfallModalHelper();

      this.newTagLabel = () => {
        return this.includePgOption ? "New Line Item" : "Create New";
      };

      this.demandTagIdsAttrName = () => {
        return this.dupingDemand ? 'campaign[dupe_demand_tag_ids][]' : 'campaign[demand_tag_ids][]';
      };

      this.$onInit = () => {
        bodySelector.on("click", "#campaign-demand-tags-table a.remove-tag", function() {
          const demandTagId = $(this).data('id');

          waterfallHelper.removeIds(self, 'demandTagIds', [demandTagId], serverSideDataTable, () => {
            $scope.$apply();
          });

          return false;
        });
      };

      serverSideTable.on('init.dt', function() {
        serverSideDataTable = $(this).DataTable();
      });

      serverSideTable.on('preXhr.dt', function (e, settings, data) {
        waterfallHelper.abortPendingAjaxRequests(serverSideTable, serverSideDataTable);

        data.campaign_id = self.campaignId;
        data.demand_partner_id = self.demandPartnerId;
        data.demand_tag_ids = self.demandTagIds;
        data.custom_data = data.custom_data || {};
        data.custom_data.selected_ids = self.selectedDemandTagBulkActionIds;
      });

      modalTable.on('preXhr.dt', function(e, settings, data) {
        data.campaign_id = self.campaignId;
        data.demand_partner_id = self.demandPartnerId;
        data.cost_model_type = self.costModelType;
        data.has_rate = self.hasRate;
        data.custom_data = data.custom_data || {};
        data.custom_data.selected_ids = [].concat(self.demandTagIds).concat(selectedIds);
      });

      modalTable.on( 'draw.dt', function () {
        _styleModalRows();
        waterfallModalHelper.setSelectedModalCheckboxes(modalTable, selectedIds);
        waterfallHelper.setModalCheckboxes(modalTable, self.demandTagIds);
      });

      waterfallModalHelper.openEventHandler(bodySelector, '.open-modal', modal, modalTable, function() {
        selectedIds = [];
        $('.modal-content .dataTables_filter input').attr('placeholder', 'Search by tag name, or enter a comma-separated list of IDs...');
      });

      waterfallModalHelper.saveEventHandler(bodySelector, modal, modalTable, function() {
        waterfallHelper.addIds(self, 'demandTagIds', selectedIds, serverSideDataTable);
        $scope.$apply();
      });

      waterfallModalHelper.modalCheckEventHandler(bodySelector, 'demand_tag_ids', function(val) {
        selectedIds.push(val);
      }, function(val) {
        selectedIds = _.without(selectedIds, val);
      });

      var _styleModalRows = function (){
        var statusIndex = modalTable.find('th.status').index();

        modalTable.find('tr').each(function(i, tr) {
          waterfallModalHelper.styleStatus(tr, statusIndex);
        });
      };

      // demandPartnerIdChange, costModelTypeChange, hasRateChange
      _.each(['demandPartnerId', 'costModelType', 'hasRate'], function(attr) {
        $rootScope.$on(attr + 'Change', function(e, args) {
          self[attr] = args[attr];
        });
      });

      $rootScope.$on('clearCampaignDemandTags', function(e, args) {
        self.demandTagIds = [];
        waterfallHelper.drawServerSideTable(serverSideDataTable, true);
      });

    }]
});

// this is a workaround for changes in angular 1.6

angular.module('vastdesk').directive('input', function() {
  return {
    restrict: 'E',
    require: '?ngModel',
    link: function (scope, elem, attrs, ngModelCtrl) {
      if ((attrs.type === 'number') && ngModelCtrl) {
        delete ngModelCtrl.$validators.step;
        delete ngModelCtrl.$validators.min;
        delete ngModelCtrl.$validators.max;
      }
    }
  };
});
import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('demandTagActive',  {
    bindings: {
      formName: '@',
      formId: '@',
      isActive: '<'
    },
    template: require("../templates/demandTagActive.html"),
    controllerAs: '$demandTagActiveCtrl',
    controller: ['$rootScope', function($rootScope) {

      var self = this;
      this.statusOptions = componentHelper.statusOptions();
      this.campaignActive = true;

      $rootScope.$on('campaignActiveChange', function(e, args) {
        self.campaignActive = args.campaignActive;
      });

    }]
});

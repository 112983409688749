angular.module('vastdesk').
  component('supplyEnvironmentDefault', {
    bindings: {
      environmentDefault: '@',
      environments: '<',
      environmentMappings: '<',
      supplyClass: '@'
    },
    template: require("../templates/supplyEnvironmentDefault.html"),
    controllerAs: '$supplyEnvironmentDefaultCtrl',
    controller: ['$rootScope', function ($rootScope) {

      this.setEnvironmentDefault = (e) => {
        this.environmentDefault = e;

        $rootScope.$broadcast('supplyEnvironmentDefaultChange', {
          environmentDefault: this.environmentDefault
        });
      };

      const showHideDooh = () => {
        const doohLabelElement = $('input#account_setting_supply_environment_default_dooh').parent();

        if (parseInt(this.supplyClass) === 1) {
          if (this.environmentDefault === 'dooh') {
            this.setEnvironmentDefault('ctv');
          }
          doohLabelElement.hide();
        } else {
          doohLabelElement.show();
        }
      };

      $rootScope.$on('supplyClassDefaultChange', (e, args) => {
        this.supplyClass = args.supplyClassDefault;
        showHideDooh();
      });


      this.$onInit = () => {
        setTimeout(() => {
          showHideDooh();
        }, 0);
      };
    }]
  });

import SwalHelper from '../../modules/swal_helper.js';

angular.module('vastdesk').
  component('accountArchivePanel',  {
    bindings: {
      archiveEligible: '<',
      cleanObjectMessages: '<'
    },
    template: require("../templates/accountArchivePanel.html"),
    controllerAs: '$accountArchivePanelCtrl',
    controller: ['$scope', function($scope) {

      var self = this;

      this.clickCb = function(val) {
        if (!val) {
          return;
        }

        SwalHelper.confirmWarning(
          {
            title: 'Cleaning Object Messages will hard delete all object messages. This action cannot be undone. Are you sure you want to proceed?'
          },
          function() {},
          function() {
            self.cleanObjectMessages = false;
            $scope.$apply();
          }
        );
      };

    }]
});

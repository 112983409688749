import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('accountServerSideHbToggle',  {
    bindings: {
      serverSideHbEnabled: '<',
      toggleClass: '@'
    },
    template: require("../templates/accountServerSideHbToggle.html"),
    controllerAs: '$accountServerSideHbToggleCtrl',
    controller: function() {

      var self = this;

      this.attributeEnabledMappings = componentHelper.attributeEnabledMappings();

      this.setserverSideHbEnabled = function(val) {
        self.serverSideHbEnabled = val;
        $('.' + self.toggleClass).toggleClass('hidden', !val);
      };

      this.$onInit = function() {
        self.setserverSideHbEnabled(self.serverSideHbEnabled);
      };
    }
});

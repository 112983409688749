angular.module('vastdesk').
  component('accountWhiteLabelFields',  {
    bindings: {
      consoleWhiteLabelType: '@',
      endpointWhiteLabelType: '@',
      consoleDomainOptions: '<',
      endpointDomainOptions: '<',
      consoleDomain: '@',
      endpointDomain: '@',
      consoleDomainErrorClass: '@',
      endpointDomainErrorClass: '@',
      whiteLabelStart: '@',
      whiteLabelFee: '<'
    },
    template: require("../templates/accountWhiteLabelFields.html"),
    controllerAs: '$accountWhiteLabelFieldsCtrl',
    controller: function() {

      this.stringPresent = function(str) {
        return str && str !== '';
      };

      this.whiteLabelTypeOptions = [{
        value: '',
        icon: 'fa-times'
      }, {
        value: 'custom',
        icon: 'fa-check'
      }];

      this.whiteLabelTooltip = 'Note that private labeling requires manual overrides to take effect. Coordinate with Product to enable this feature.';

    }
});

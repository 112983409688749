angular.module('vastdesk').
  component('formatFields',  {
    bindings: {
      formName: '@',
      formId: '@',
      format: '@',
      persisted: '<',
      formatOptions: '<',
      allFormatOptions: '<',
      demandClass: '<',
      customResponse: '<',
      tileTagDemandClass: '<',
      progTilesEnabled: '<'
    },
    template: require("../templates/formatFields.html"),
    controllerAs: '$formatFieldsCtrl',
    controller: ['$rootScope', '$scope', function($rootScope, $scope) {

      var self = this;
      const CLASS_DIRECT_CONNECT = 2;
      const CLASS_OPEN_RTB_DIRECT_CONNECT = 6;

      this.setFormat = function(format) {
        if (self.formatDisabled(format)) {
          return;
        }
        self.format = format;

        showHideFormatElements();

        $rootScope.$broadcast('formatChanged', {
          format: format
        });
      };

      const tileCompatibleDemandClass = () => {
        const demandClasses = [4, 8, 11];

        if (this.tileTagDemandClass) {
          demandClasses.push(1);
        }

        if (this.progTilesEnabled) {
          demandClasses.push(3);
        }

        return _.contains(demandClasses, this.demandClass);
      };

      this.formatDisabled = function(format) {
        return self.persisted ||
          (format === 'audio' && self.demandClass === 3) ||
          (format === 'tile' && self.demandClass && !tileCompatibleDemandClass()) ||
          (_.contains([CLASS_DIRECT_CONNECT, CLASS_OPEN_RTB_DIRECT_CONNECT], self.demandClass));
      };

      this.checkboxCb = function(val) {
        $rootScope.$broadcast('customResponseChanged', {
          customResponse: val
        });
      };

      this.showCustomResponse = function() {
        return self.format === 'tile' && _.contains(['supply_tag', 'supply_router'], self.formName);
      };

      $scope.$on('demandClassChanged', function(e, args) {
        self.demandClass = parseInt(args.demandClass);
      });

      var showHideElement = function(format, shownSuffix, disabledSuffix) {
        $('.' + format + '-shown' + shownSuffix).toggleClass('hidden', format !== self.format);
        $('.' + format + '-disabled' + disabledSuffix).attr('disabled', format === self.format);
      };

      var showHideFormatElements = function() {
        _.each(self.allFormatOptions, function(formatOption) {
          var shownSuffix = '';
          var disabledSuffix = '';

          if (formatOption[1] !== self.format) {
            shownSuffix = ':not(.' + self.format + '-shown)';
            disabledSuffix = ':not(.' + self.format + '-disabled)';
          }

          showHideElement(formatOption[1], shownSuffix, disabledSuffix);
        });
      };

      this.$onInit = function() {
        showHideFormatElements();
      };

    }]
});

angular.module('vastdesk').
  component('scannedCreativeAssetPreviews',  {
    bindings: {
      playerId: '@',
      videoUrl: '@',
      imageUrl: '@',
      assetId: '@',
      assetOptions: '<'
    },
    template: require("../templates/scannedCreativeAssetPreviews.html"),
    controllerAs: '$scannedCreativeAssetPreviewsCtrl',
    controller: ['$rootScope', function($rootScope) {

      var self = this;

      this.assetChange = function() {
        var selectedAsset = _.find(self.assetOptions, function(ao) {
          return ao[1] === self.assetId;
        });

        $rootScope.$broadcast('bridVideoChange', {
          playerId: self.playerId,
          videoUrl: selectedAsset[2],
          imageUrl: selectedAsset[3]
        });
      };

    }]
});

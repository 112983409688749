angular.module('vastdesk').
  component('tagPixels', {
    bindings: {
      format: '@',
      environment: '@'
    },
    controller: ['$rootScope', function($rootScope) {
      
      var self = this;
      var bodySelector = $('body');

      bodySelector.on('cocoon:after-insert', '#tag_pixels', function() {
        $(this).find('.chosen-select').defaultChosen();

        filterEventPixelsOptions();
      });

      var filterEventPixelsOptions = function() {
        var pixelType = $('#tag_pixels_options option:selected').val();
        setTimeout(function() {
          $('[id^=demand_tag_tag_pixels_attributes_][id$=pixel_type]').find('option').each(function() {
            if (self.format === 'tile') {
              $("#tag_pixels_options option:not([value='AdImpression']):not([value='AdClickThru'])").hide();
            }
            else {
              $("#tag_pixels_options option:not([value='AdImpression']):not([value='AdClickThru'])").show();
            }

            if (self.environment === 'ctv') {
              $("#tag_pixels_options option[value='AdRequest']").hide();
            } else if (self.format !== 'tile') {
              $("#tag_pixels_options option[value='AdRequest']").show();
            }

            if ((self.environment === 'ctv' && pixelType === 'AdRequest') || (self.format === 'tile' && !_.contains(['AdImpression', 'AdClickThru'], pixelType))) {
              $('[id^=demand_tag_tag_pixels_attributes_][id$=pixel_type]').val('');
            }

            if ((self.environment === 'dooh' && $(this).data('excludeDooh'))) {
              $("#tag_pixels_options option[value='AdClickThru']").hide();
            }
          });
          $('[id^=demand_tag_tag_pixels_attributes_][id$=pixel_type]').trigger('chosen:updated');
        }, 0);

        setTimeout(function(){
          $('[id^=supply_tag_tag_pixels_attributes_][id$=pixel_type]').find('option').each(function() {
            if ((self.environment === 'dooh' && $(this).data('excludeDooh'))) {
              $("#tag_pixels_options option[value='AdClickThru']").hide();
            }            
          });
  
          $('[id^=supply_tag_tag_pixels_attributes_][id$=pixel_type]').trigger('chosen:updated');
        }, 0);
      };

      bodySelector.on('change', 'input[name$="[environment]"]', function() {
        filterEventPixelsOptions();
      });

      $rootScope.$on('environmentChange', function(e, args) {
        self.environment = args.environment;
        filterEventPixelsOptions();
      });

      $rootScope.$on('formatChanged', function(e, args) {
        self.format = args.format;

        filterEventPixelsOptions();
      });

      this.$onInit = function() {
        setTimeout(function() {
          filterEventPixelsOptions();
        }, 0);
      };
    }]
});

angular.module('vastdesk').
  component('objectExportCsv',  {
    bindings: {
      tableId: '@'
    },
    controllerAs: '$objectExportCsvCtrl',
    controller: ['$scope', '$element', function($scope, $element) {

      var self = this;
      var modal, modalTable;
      var selectedIds = [];

      var setCheckboxes = function() {
        modal.find('input[type="checkbox"]').attr('disabled', false).prop('checked', false);
        $.each(selectedIds, function(index, id) {
          var checkbox = modal.find("#checkbox_" + id);
          checkbox.prop("checked", true);
        });
      };

      $scope.$on('selectedIdsChanged', function(event, opts) {
        selectedIds = opts.selectedIds;
      });

      this.$onInit = function() {
        modal = $element.parents('#' + self.tableId);
        modalTable = modal.find('.datatable');

        modal.on('click', '#export-csv', function() {
          if(!selectedIds.length) { return; }

          $(this).attr("href", function() {
            return this.href + '?filename=' + modal.find('#filename').val() + '&' + $.param({object_ids: selectedIds});
          });
          modal.find('.export_csv_modal').modal('hide');
        });

        modal.on('change', '.batch-object-id-checkbox', function() {
          var checkbox = $(this);
          var val = checkbox.val();
          if (checkbox.prop("checked")) {
            selectedIds.push(val);
          } else {
            selectedIds.splice(selectedIds.indexOf(val), 1);
          }
        });

        modalTable.on('preXhr.dt', function (e, settings, data) {
          data.custom_data = data.custom_data || {};
          data.custom_data.selected_ids = selectedIds;
        });

        modalTable.on('draw.dt', function() {
          setCheckboxes();
        });
      };

    }]
});

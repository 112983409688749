import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk')
.component('shareOfVoice', {
  bindings: {
    formName: '@',
    formId: '@',
    customShareOfVoice: '<',
    shareOfVoiceGoalPercent: '<'
  },
  template: require("../templates/shareOfVoice.html"),
  controllerAs: '$shareOfVoiceCtrl',
  controller: ["$scope", "$element", function($scope, $element) {

    var self = this;
    this.customOptions = componentHelper.customDisabledOptions();

  }]
});
import FlightDatesHelper from '../../modules/flight_dates_helper.js';

angular.module('vastdesk').
  component('demandTagCampaign',  {
    bindings: {
      formName: '@',
      formId: '@'
    },
    template: require("../templates/demandTagCampaign.html"),
    controllerAs: '$demandTagCampaignCtrl',
    controller: ['$rootScope', function($rootScope) {

      const timezone = 'Etc/UTC';
      const flightDatesHelper = new FlightDatesHelper();

      this.inactiveFlight = function() {
        const selected = $("#demand_tag_campaign_id").find("option:selected");
        const campaignEndDate = selected.data("campaignEndDate");
        const budgetData = selected.data("budgetData");

        return flightDatesHelper.pastEndDate(campaignEndDate, timezone) || flightDatesHelper.inactiveByBudgetFlightDates(budgetData);
      };
    }]
});

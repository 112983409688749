$(document).on('turbo:load', function() {
  if(typeof Rollbar !== 'undefined'){
    Rollbar.configure({
      payload: {
        person: {
          email: $("#current_user_email").text(),
          account: $("#current_user_account").text(),
          id: $("#current_user_id").text()
        }
      }
    });
  }
});

angular.module('vastdesk')
.component('largeReportModal', {
  bindings: {},
  controller: function() {

    var bodyElement = $('body');

    bodyElement.on('click', '#large_report_modal .view-preview', function(e) {
      var name = $('#large_report_modal #csv_report_name').val();
      var target = $('#large_report_modal #csv_report_json_path').val();

      if (name != '' && target != '') {
        $.post(target, {'_method': 'patch', csv_report: {name: name}}, function(resp) {
          // success handler
        });
      }
    });

  }
});

angular.module('vastdesk').
  component('accountIsTest',  {
    bindings: {
      isTest: '<'
    },
    template: '<form-check-box-tag field-id="account_is_test" field-name="account[is_test]" model="$accountIsTestCtrl.isTest" label-display="Test Account" click-cb="$accountIsTestCtrl.isTestCb"></form-check-box-tag>',
    controllerAs: '$accountIsTestCtrl',
    controller: function() {

      var self = this;

      this.isTestCb = function(val) {
        $('.is-test-hidden').toggleClass('hidden', val);
      };

      this.$onInit = function() {
        self.isTestCb(self.isTest);
      };

    }
});

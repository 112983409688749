import HighchartsBuilder from '../../modules/highcharts_builder.js';

angular.module('vastdesk')
.component('financialChart', {
  bindings: {
    chartId: '@',
    pointsType: '@',
    dateRange: '@',
    forecastDateRange: '@',
    timezone: '@',
    metrics: '=',
    currencySymbol: '@'
  },
  template: '<div class="graphChart financialChart"><div></div></div>',
  controller: ['$scope', '$rootScope', '$element', function($scope, $rootScope, $element) {

    var self = this;
    var onInitLoad = $.Deferred();
    var highchartsBuilder, colors;

    this.$onInit = function() {
      highchartsBuilder = new HighchartsBuilder(self.timezone);
      colors = highchartsBuilder.chartColors;
      onInitLoad.resolve();
    };

    var chartDiv = $element.find('div');
    var chart;

    var getMetrics = function() {
      return self.metrics || defaultMetrics;
    };

    var defaultMetrics = [
      ['revenue', 'Revenue'],
      ['net_profit', 'Profit (Net)'],
      ['net_margin', 'Margin (Net)']
    ];

    var setChart = function(chartData, forecastData, changelogs) {
      var dataPoints = highchartsBuilder.getDataPointsFromDateRange(self.dateRange, {
        interval: self.pointsType,
        forecastDateRange: self.forecastDateRange
      });

      var metric1 = getMetrics()[0];
      var metric2 = getMetrics()[1];
      var metric3 = getMetrics()[2];

      var dateFormat = highchartsBuilder.getDateFormatFromInterval(self.pointsType);

      chart = chartDiv.highcharts({
        chart: {
          zooming: {
            type: 'xy'
          },
          backgroundColor: null,
          animation: false
        },
        title: {
          text: ''
        },
        credits: {enabled: false},
        colors: colors,
        xAxis: [{
          categories: dataPoints,
          crosshair: true,
          tickLength: 0,
          lineColor: '#ccd6eb',
          labels: {
              formatter: function() {
                if (highchartsBuilder.validAxisValue(this)) {
                  return this.value.format(dateFormat);
                }
              },
              reserveSpace: true,
              y: 10,
              style: {
                color: '#acacac',
                fontSize: '9px'
              }
            }
        }],
        yAxis: [
          {//primary
          title: {text: ''},
          labels: {
            formatter: function() {
              return highchartsBuilder.yAxisFormatter('moneyNoDecimal', this, self.currencySymbol);
            },
            reserveSpace: true,
            style: {
              fontSize: '9px',
              fontWeight: 'bold',
              color: colors[0]
            }
          },
          gridLineColor: '#d7d7d7'
        },
        {//secondary
          gridLineWidth: 0,
          title: {text: ''},
          labels: {
            formatter: function() {
              return highchartsBuilder.yAxisFormatter('percent', this, self.currencySymbol);
            },
            reserveSpace: true,
            style: {
              fontSize: '9px',
              fontWeight: 'bold',
              color: colors[1],
              opposite: true
            }
          },
          opposite: true
        },
        {//tertiary
          gridLineWidth: 0,
          title: {text: ''},
          labels: {enabled:false},
          opposite: true
        }
        ],
        legend: highchartsBuilder.defaultLegend(),
        plotOptions: {
          series: {
            animation: false,
            marker: {
              enabled: false
            },
            states: {
              hover: {
                  enabled: false
              }
            },
            shadow: false
          },
          column: {
            grouping: false,
            shadow: false,
            borderWidth: 0
          }
        },
        tooltip: {
          formatter: function() {
            return highchartsBuilder.changelogTooltipFormatter(this, self.pointsType, self.currencySymbol);
          },
          shared: true,
          backgroundColor: '#FFFFFF',
          borderColor: '#484848',
          style: {
            fontSize: '10px',
            lineHeight: '14px',
            opacity: '0.95'
          }
        },
        series: [
          columnSeries(dataPoints, chartData, metric1, colors[0], false),
          columnSeries(dataPoints, chartData, metric2, colors[2], false),
          splineSeries(dataPoints, chartData, metric3, false)
        ]
      });

      if (highchartsBuilder.includeForecastingSeries(self.forecastDateRange, self.pointsType, forecastData)) {
        chart.highcharts().addSeries(
          columnSeries(dataPoints, forecastData, metric1, colors[0], true)
        );
        chart.highcharts().addSeries(
          columnSeries(dataPoints, forecastData, metric2, colors[2], true)
        );
        chart.highcharts().addSeries(
          splineSeries(dataPoints, forecastData, metric3, true)
        );
      }

      if (changelogs) {
        highchartsBuilder.addChangelogs(chart, changelogs, dataPoints, dateFormat);
      }
    };

    var columnSeries = function(dataPoints, chartData, metric, color, forecast) {
      return {
        name: highchartsBuilder.seriesNameFor(metric[1], forecast),
        type: 'column',
        data: highchartsBuilder.getDataFromTimeValues(dataPoints, chartData, metric[0], highchartsBuilder.forecastDataFromTimeValuesOptions(self, forecast)),
        formatType: 'money',
        yAxis: 0,
        color: highchartsBuilder.columnColor(color, forecast),
        zIndex: 0,
        connectNulls: false
      };
    };

    var splineSeries = function(dataPoints, chartData, metric, forecast) {
      return {
        name: highchartsBuilder.seriesNameFor(metric[1], forecast),
        type: 'spline',
        yAxis: 1,
        data: highchartsBuilder.getDataFromTimeValues(dataPoints, chartData, metric[0], highchartsBuilder.forecastDataFromTimeValuesOptions(self, forecast)),
        formatType: 'percent',
        color: colors[1],
        zIndex: 1,
        shadow: highchartsBuilder.defaultShadow(),
        lineWidth: 2,
        connectNulls: false,
        dashStyle: forecast ? 'dash' : 'solid',
        legendSymbol: forecast ? 'rectangle' : 'lineMarker',
        legendColor: forecast ? highchartsBuilder.stripeFillColor(colors[1]) : null
      };
    };

    $rootScope.$on('dashboardDateRangeChange', function(e, args) {
      self.dateRange = args.dateRange;
    });

    $rootScope.$on('forecastDashboardDateRangeChange', function(e, args) {
      self.forecastDateRange = args.forecastDateRange;
    });

    $scope.$on('setHighchartLoading', function(e, args) {
      highchartsBuilder.loadData(onInitLoad, chartDiv, self, args);
    });

    $scope.$on('refreshHighchart', function(e, args) {
      highchartsBuilder.refreshData(onInitLoad, chartDiv, self, args, function() {
        setChart(args.data, args.forecastData, args.changelogs);
      });
    });

    $scope.$on('reflowHighcharts', function(e, args) {
      highchartsBuilder.deferredReflowChart(onInitLoad, chartDiv);
    });

    $rootScope.$on('reportCurrencySymbolChange', function (e, args) {
      self.currencySymbol = args.currencySymbol;
    });

  }]
});

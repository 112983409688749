import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('customIntegrations',  {
    bindings: {
      formName: '@',
      mediaoceanPrismaEnabled: '<',
      mediaoceanPrismaOrderId: '@',
      mediaoceanPrismaCampaignId: '@',
      mediaoceanPrismaCampaignLink: '@',
      mediaoceanPrismaPlacementId: '@'
    },
    template: require("../templates/customIntegrations.html"),
    controllerAs: '$customIntegrationsCtrl',
    controller: ['$rootScope', function($rootScope) {

      var self = this;

      this.statusOptions = componentHelper.statusOptions();

      this.setMediaoceanPrismaEnabled = function(mediaoceanPrismaEnabled) {
        if (mediaoceanPrismaEnabled === self.mediaoceanPrismaEnabled) {
          return;
        }

        self.mediaoceanPrismaEnabled = mediaoceanPrismaEnabled;
      };
    }]
});

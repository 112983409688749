import platformUtilities from '../../modules/platform_utilities.js';


angular.module('vastdesk').
  component('forecastFlightDates',  {
    bindings: {
      formName: '@',
      formId: '@',
      flightDates: '@',
    },
    template: require('../templates/forecastFlightDates.html'),
    controllerAs: '$forecastFlightDatesCtrl',
    controller: ['$rootScope', '$scope', function($rootScope, $scope) {

      const dateRangeOptions =  {
        ...platformUtilities.dateRangeFutureOptions,
        timePicker: false,
        minDate: moment().add(1, 'days').startOf('day'),
        maxDate: moment().add(119, 'days').endOf('day'), // maximum allowed date is 120 days in the future
      }

      $(".datehourrange-future-120-days").daterangepicker(dateRangeOptions);
      
    }]
});
function checkAll(elem, checkbox, checked){
  var col = $(checkbox).closest('th').index();
  var parent = $(elem).parents('.dataTables_scroll');
  if(parent.length == 0){
    parent = $(elem).parents('table');
  }
  parent.find('tbody tr').find('td:eq(' + col + ') input[type="checkbox"]:not(:disabled)').prop('checked', checked).trigger('change');
}

$(document).on('turbo:load', function() {
  $(".check-all-checkbox, .checkbox-custom-label").on("click", function(e) {
    if ($(this).attr('disabled')) {
      return;
    }
    var target = $(e.target);
    var checkbox;
    var checked;
    if(target.is("input[type='checkbox'].check-all-checkbox")){
      checkbox = target;
      checked = checkbox.prop("checked");
      checkAll(this, checkbox, checked);
      e.stopImmediatePropagation();
    }
    if(target.is("label.checkbox-custom-label")){
      if(target.prev().is("input[type='checkbox'].check-all-checkbox")){
        checkbox = target.prev();
        checked = !checkbox.prop("checked");
        checkAll(this, checkbox, checked);
        e.stopImmediatePropagation();
        if(checkbox.prop("checked") != checked) {
          checkbox.prop("checked", checked);
          return false;
        }
      }
    }
  });
});

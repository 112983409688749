import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('dcTagTargeting',  {
    bindings: {
      formName: '@',
      formId: '@',
      allowList: '@',
      targetedIds: '@',
      targetingType: '@',
      targetingLabel: '@'
    },
    template: require("../templates/dcTagTargeting.html"),
    controllerAs: '$dcTagTargetingCtrl',
    controller: function() {
      this.allowlistOptions = componentHelper.allowlistOptions();

      this.allowlistTooltip = function(val) {
        if (val !== '') {
          return 'Enter a comma-separated list of IDs';
        }
      };

    }
});

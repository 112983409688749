angular.module('vastdesk').
  component('billItemXeroCode',  {
    bindings: {
      xeroCode: '@',
      readonly: '<'
    },
    template: require("../templates/billItemXeroCode.html"),
    controllerAs: '$billItemXeroCodeCtrl',
    controller: function() {

      var self = this;

      this.updatePartnerField = function() {
        if (['404', '415'].includes(self.xeroCode)) {
          $('.other-account-id-select').removeClass('hidden');
          $('.other-account-id-disabled').addClass('hidden');
          $('#other_account_id_select').defaultChosen();
        } else {
          $('.other-account-id-select').addClass('hidden');
          $('.other-account-id-disabled').removeClass('hidden');
        }
      };

    }
});

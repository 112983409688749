$.fn.defaultChosen = function() {
  var allowSingleDeselect = ($(this).data('allowSingleDeselect') === false) ? false : true;
  $(this).chosen({
    width: '100%',
    disable_search_threshold: 4,
    allow_single_deselect: allowSingleDeselect,
    search_contains: true,
    placeholder_text_single: " ",
    placeholder_text_multiple: " "
  });
};

const escapedSelectOptionStr = (text) => {
  if (typeof(text) === 'string' && text.startsWith('<em>') && text.endsWith('</em>')) {
    return '<em>' +  _.escape(text.replace(/^<em>/, '').replace(/<\/em>$/, '')) + '</em>';
  }
  else {
    return _.escape(text);
  }
};

$.fn.dynamicChosen = function() {
  var that = this;
  var chosenId = "#" + $(that).attr("id") + "_chosen";

  $(that).chosen({
    width: "100%",
    placeholder_text_multiple: " ",
    no_results_text: "Add ",
    display_selected_options: false
  });

  var addDynamicOption = function() {
    var newInputs = $(chosenId).find(".search-field input").val().split(',');
    $.each(newInputs, function(k,v) {
      var newInput = v.replace(/^\s+|\s+$/g,'');
      $(that).append($("<option>", {
        value: newInput,
        text : newInput,
        selected: "selected"
      }));
    });
    $(that).trigger("chosen:updated").trigger('change');
  };

  var removeDynamicOption = function(val) {
    if (!val || val === '') {
      return;
    }

    $(that).find("option[value='" + val.replace("'", "\\\'") + "']").remove();
    $(that).trigger("chosen:updated");
  };

  $("body").on("click", chosenId + " .no-results", addDynamicOption);
  $("body").on("keyup", chosenId + " .search-field input", function(e) {
    if (e.which == 13 && $(chosenId + ' li.no-results').length > 0) {
      addDynamicOption();
    }
  });

  $(that).on('change', function(e, params) {
    if (params && params.deselected) {
      removeDynamicOption(params.deselected);
    }
  });
};

$.fn.defaultTokenize = function() {
  var that = this;

  var setTokenUrl = function(value, text) {
    var option = $(that).find('option[value="' + value + '"]');
    var optionUrl = option.data('url');
    var optionClass = option.data('class');

    if (optionUrl) {
      if (!text.match(/^<a .*>$/)) {
        var span = $(that).parent().find('.tokens-container li[data-value="' + value + '"] span');
        const classString = optionClass ? " class='" + optionClass + "'" : '';
        const escapedText = escapedSelectOptionStr(text);
        const html = (optionUrl === '#') ? escapedText : "<a href='" + optionUrl + "' target='_blank'" + classString + ">" + escapedText + "</a>";
        span.html(html);
      }
    }
  };

  $(this).tokenize2({
    dataSource: $(this).data('ajax'),
    debounce: 100,
    delimiter: ';',
    placeholder: $(this).data('placeholder'),
    searchMinLength: $(this).data('searchMinLength') || 0,
    dropdownMaxItems: 100,
    tokensMaxItems: $(this).data('tokensMaxItems') || 0
  }).trigger('tokenize:remap');

  $(this).find('option:selected').each(function(i, option) {
    var optionElement = $(option);
    setTokenUrl(optionElement.val(), optionElement.text());
  });

  $(this).on('tokenize:select', function(container) {
    if ( $(this).prop('disabled') ) {
      return;
    }
    $(this).tokenize2().trigger('tokenize:search', [$(this).tokenize2().input.val()]);
  });

  $(this).on('tokenize:tokens:added', function(e, value, text) {
    setTokenUrl(value, text);
    $(that).trigger('change');
    $(that).parent().find('.tokenize').blur();
  });

  $(this).on('tokenize:tokens:remove', function(e, value) {
    $(that).trigger('change');
    $(that).parent().find('.tokenize').blur();
  });
};

$.fn.defaultTomSelect = function() {
  var select = this;

  if (select.get(0).tomselect) {
    return;
  }

  var setTokenUrl = function(optionElement) {
    var option = $(optionElement);
    var optionUrl = option.data('url');

    if (optionUrl) {
      var span = $(select).parent().find('.ts-control div[data-value="' + option.val() + '"]');
      var removalAnchor = $(span).find('a.remove');
      if ($(span).find('a:not(.remove)').length < 1) {
        const escapedText = escapedSelectOptionStr(option.text());
        const html = "<a href='" + optionUrl + "' target='_blank'>" + escapedText + "</a>";
        span.html(html);
        span.append(removalAnchor);
      }
    }
  };

  let plugins = [];

  if ($(select).attr('multiple') && $(select).data('maxItems') !== 1) {
    plugins = ['remove_button'];
  }
  else if ($(select).data('allowSingleDeselect') !== false) {
    plugins = ['clear_button'];
  }

  var ts = new TomSelect(select, {
    plugins: plugins,
    hidePlaceholder: true,
    highlight: false,
    preload: 'focus',
    closeAfterSelect: $(select).data('closeAfterSelect') ? true : false,
    maxItems: $(select).data('maxItems'),
    searchField: $(select).data('ajax') ? [] : ['text'],
    load: function(query, callback) {
      var ajaxUrl = $(select).data('ajax');
      var separator = ajaxUrl.includes('?') ? '&' : '?';
      var url = ajaxUrl + separator + 'search=' + encodeURIComponent(query);
      fetch(url)
        .then(response => response.json())
        .then(json => {
          ts.clearOptions(); // It appends results when disabling search so have to clear first
          callback(json);
        }).catch(()=>{
          callback();
        });
    },
    render: {
      option: function(data, escape) {
        return $("<div></div>").html(data.text);
      },
      item: function(data, escape) {
        return $("<div></div>").html(data.text);
      },
      loading:function(data,escape){
        return '';
      }
    }
  });

  ts.on('item_add', function(value, item) {
    var optionElement = $(select).find('option[value="' + value + '"]');

    if (optionElement) {
      setTokenUrl(optionElement);
    }
  });

  ts.refreshItems();

  $(select).find('option:selected').each(function(i, optionElement) {
    setTokenUrl(optionElement);
  });
};

jQuery.propHooks.disabled = {
  set: function (el, value) {
    if (el.disabled !== value) {
      el.disabled = value;
      $(el).trigger('disabledChanged');
    }
  }
};

$(document).on('turbo:load', function() {
  $.each($("select[data-ajax]:not([data-tom-select])"), function(index, select){
    $(select).defaultTokenize();
  });
  $.each($("select[data-ajax][data-tom-select]"), function(index, select){
    $(select).defaultTomSelect();
  });
  $.each($("select[data-dynamic-chosen]"), function(index, select){
    $(select).dynamicChosen();
  });
  $.each($("select:not([data-ajax]):not([data-dynamic-chosen])"), function(index, select){
    $(select).defaultChosen();
  });

  $("option").on('disabledChanged', function(){
    $(this).parent("select").trigger("chosen:updated");
  });

  $("body").on("click", ".chosen-container .chosen-choices .search-choice span", function(e){
    var closeLink = $(e.target).next("a.search-choice-close");
    if(closeLink.length){
      var select = closeLink.parents(".chosen-container").prev("select.chosen-clickable");
      if(select.length) {
        var option = select.find("option").eq(closeLink.data("optionArrayIndex"));
        var url = option.data("url");
        if(url){
          window.open(url, "_blank");
        }
      }
    }
  });

  $("body").on("mouseup", ".ts-wrapper .ts-control .item a:not(.remove)", function(e) {
    var url = $(this).attr('href');

    if (url) {
      window.open($(this).attr('href'), '_blank');
    }
  });

  $("body").on("click, mousedown", ".tokenize-dropdown li a", function(e){
    var linkTarget = $(e.target);
    linkTarget.parent().click();
    return false;
  });

  $('body').on('click', '.chosen-add-all', function(e) {
    var element = $(this);
    var target = element.data('target');
    var values = element.data('values');

    if (!target || !values) {
      return;
    }

    $(target).attr('disabled', false).val(values).trigger('chosen:updated');
  });

  $('body').on('click', '.chosen-remove-all', function(e) {
    var element = $(this);
    var target = element.data('target');

    if (!target) {
      return;
    }

    $(target).val([]).attr('disabled', true).trigger('chosen:updated');
  });
});

import platformUtilities from '../../modules/platform_utilities.js';
import WaterfallHelper from '../../modules/waterfall_helper.js';
import WaterfallModalHelper from '../../modules/waterfall_modal_helper.js';

angular.module('vastdesk')
.component('supplyTagCampaignModal', {
  bindings: {
    metrics: '=',
    path: '@',
    objectId: '@',
    summaryPath: '@',
    qualityPath: '@',
    timezone: '@',
    selectedMetric: '@',
    chartPath: '@',
    reportingView: '@',
    externalBidder: '@',
    trackingPlayerCost: '=',
    excludeOpps: '@',
    completionReporting: '@',
    pod: '@',
    tableColumns: '=',
    tableHeaderColumnIds: '=',
    tableOrder: '@',
    tableHeader: '@',
    currentSupplyTagFields: '&',
    demandTagTableData: '&',
    leftAxisSeries: '@',
    tile: '<',
    currency: '@',
    currencySymbol: '@',
    currencyPlaceholder: '@',
    dateRanges: '<'
  },
  template: require("../templates/supplyTagCampaignModal.html"),
  controllerAs: '$supplyTagCampaignModalCtrl',
  controller: ['$scope', '$element', '$rootScope', '$filter', function($scope, $element, $rootScope, $filter) {

    this.campaignNameWithId = null;
    this.campaignId = null;
    this.tableId = 'supply_tag_campaign_demand_tags_table';
    this.reportData = [];
    this.activeTab = '';
    var waterfallHelper = new WaterfallHelper();
    var waterfallModalHelper = new WaterfallModalHelper();

    waterfallHelper.dateRangeize($element.find('.date-filter select'));

    var self = this;
    var onInitLoad = $.Deferred();
    var modal = $element.find('.modal');
    var serverSideTable;
    var serverSideDataTable;
    var serverSideTableLoaded = $.Deferred();

    var campaignTierDateRange = function() {
      return $('#supply_tag_campaigns_table').parents('.dataTables_wrapper').find('#date_range');
    };

    var campaignDemandTagDateRange = function() {
      return $element.find('#date_range');
    };

    modal.on('show.bs.modal', function(e) {
      waterfallHelper.syncDateRange(campaignTierDateRange(), campaignDemandTagDateRange());
      var relatedTarget = $(e.relatedTarget);
      self.campaignNameWithId = relatedTarget.data('campaignName');
      self.campaignId = relatedTarget.data('campaignId');
      self.slotNumber = relatedTarget.parents('tr').data('sn');
      self.slotOrder = relatedTarget.parents('tr').data('so');

      $.when( serverSideTableLoaded ).done(function() {
        var format = waterfallHelper.objectFormat();
        waterfallModalHelper.setFormatFilter(modal, format);
      });

      setActiveTab($('#campaignModalTabs li a.active'));
      updateReportData();
      $scope.$apply();
    });

    modal.on('hide.bs.modal', function(e) {
      waterfallHelper.syncDateRange(campaignDemandTagDateRange(), campaignTierDateRange(), {triggerChange: true});
    });

    this.slotTitle = function() {
      if (!!self.slotOrder) {
        return $filter('titleize')(self.slotOrder) + ' Slot - ';
      }
      else if (!!self.slotNumber) {
        return 'Slot ' + self.slotNumber + ' - ';
      }
    };

    this.$onInit = function() {
      serverSideTable = $('#' + self.tableId);

      serverSideTable.on('init.dt', function() {
        serverSideDataTable = $(this).DataTable();
        serverSideTableLoaded.resolve();

        if (Array.isArray(self.reportData) && self.reportData.length > 0) {
          setTimeout(function() {
            waterfallHelper.drawServerSideTable(serverSideDataTable, false);
          }, 0);
        }
      });

      serverSideTable.on('preXhr.dt', function (e, settings, data) {
        if (serverSideDataTable) {
          serverSideDataTable.settings()[0].jqXHR.abort();
          platformUtilities.triggerDataTablesProcessing(serverSideTable, true);
        }
        waterfallHelper.attachTableData(data, self.demandTagTableData()());
        data.campaign_id = self.campaignId;
        data.date_range = campaignDemandTagDateRange().val();
        data.report_data = waterfallHelper.getDemandTagReportDataForDraw(serverSideTable, serverSideDataTable, self.reportData);
        data.custom_data = data.custom_data || {};
        data.custom_data.selected_ids = self.currentSupplyTagFields()();
      });

      serverSideTable.on('xhr.dt', function(e, settings, json, xhr) {
        if (json && json.data) {
          attachReportData(json.data);
        }
      });

      onInitLoad.resolve();
    };

    var updateReportData = function() {
      $rootScope.$broadcast('updateReportData', {
        table: self.tableId,
        filterParams: {
          campaign_id: self.campaignId
        }
      });
    };

    var reportRowFor = function(tableRow) {
      return _.findWhere(self.reportData, {demand_tag_id: tableRow.DT_RowAttr['data-demand-tag-id']}) || {};
    };

    var attachReportData = function(rows) {
      _.each(rows, function(r) {
        var reportRow = reportRowFor(r);
        waterfallHelper.addReportDataToRow(r, reportRow, {
          currencyPlaceholder: self.currencyPlaceholder
        });
      });
    };

    $scope.$on('updatedReportData', function(e, args) {
      $.when( onInitLoad ).done(function() {
        if (args.table === self.tableId) {
          self.reportData = args.resp.demand_tag_report;
          self.currencyPlaceholder = args.resp.currency_placeholder;
          waterfallHelper.drawServerSideTable(serverSideDataTable, false);
        }
      });
    });

    $scope.$on('updatingReportData', function(e, args) {
      $.when( onInitLoad ).done(function() {
        if (args.table === self.tableId) {
          platformUtilities.triggerDataTablesProcessing(serverSideTable, true);
        }
      });
    });

    $element.on('shown.bs.tab', '#campaignModalTabs a[data-toggle="tab"]', function (e) {
      setActiveTab($(this));
    });

    var setActiveTab = function(activeAnchor) {
      self.activeTab = activeAnchor.attr('href');
      $scope.$apply();
    };

  }]
});

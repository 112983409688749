import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('irisTvIntegration',  {
    bindings: {
      formName: '@',
      formId: '@',
      active: '<',
      clientToken: '@',
      apiParam: '@',
      apiParamOptions: '<'
    },
    template: require("../templates/irisTvIntegration.html"),
    controllerAs: '$irisTvIntegrationCtrl',
    controller: function() {
      this.statusOptions = componentHelper.statusOptions();
    }
});

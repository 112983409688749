import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('competitiveExclusionIabContentCategories',  {
    bindings: {
      formName: '@',
      formId: '@',
      allCategoryCodes: '<',
      selectedCategoryCodes: '<',
      categoryOptions: '<',
      hasCodesOrList: "@",
      competitiveExclusionsSource: '@',
      selectedLists: '<',

    },
    template: require("../templates/competitiveExclusionIabContentCategories.html"),
    controllerAs: '$competitiveExclusionIabContentCategoriesCtrl',
    controller: function() {
      this.allCategoriesOptions = componentHelper.attributeEnabledMappings2();
      this.targetingSourceOptions = componentHelper.targetingSourceOptions();
    }
});

import platformUtilities from '../../modules/platform_utilities.js';
import DashboardHelper from '../../modules/dashboard_helper.js';
import HighchartsBuilder from '../../modules/highcharts_builder.js';

angular.module('vastdesk').
  component('dashboardTopObjectMetrics',  {
    bindings: {
      metrics: '<',
      panelInfo: '<',
      panelId: '@',
      selectedType: '@',
      selectedDateRange: '@',
      hideMetrics: '<',
      selectedMetric: '@',
      currency: '@'
    },
    template: require("../templates/dashboardTopObjectMetrics.html"),
    controllerAs: '$dashboardTopObjectMetricsCtrl',
    controller: ['$rootScope', function($rootScope) {

      var self = this;
      var ajaxRequests = {};
      var dashboardHelper = new DashboardHelper();
      var highchartsBuilder = new HighchartsBuilder();
      var colors = highchartsBuilder.chartColors;

      var getMetricLabel = function() {
        var match = _.find(self.metrics, function(value) {
          return value[0] === self.selectedMetric;
        });

        return match && match[1];
      };

      var _toFloat = function(s) {
        return parseFloat((s || '').replace(/[^0-9.]/g, ''), 10) || 0;
      };

      var rowForDimension = function(rows, top_row, dimension) {
        return _.find(rows, function(row) {
          return rows[dimension] === row[dimension];
        });
      };

      var pieChartMetricRow = function(name, metric, color, forecast) {
        return {
          name: name,
          y: _toFloat(metric),
          color: color,
          opacity: forecast ? 0.5 : 1
        };
      };

      var _pieDataFrom = function(results, metric) {
        metric = metric || 'impressions';
        var pieData;

        if (self.selectedForecastDateRange) {
          pieData = [];

          _.each(results.top_rows, function(top_row, index) {
            var historicalRow = rowForDimension(results.historical_top_rows, top_row, results.dimension);
            var forecastRow = rowForDimension(results.forecast_top_rows, top_row, results.dimension);

            if (historicalRow) {
              pieData.push(
                pieChartMetricRow(historicalRow.name, historicalRow[metric], colors[index], false)
              );
            }
            if (forecastRow) {
              pieData.push(
                pieChartMetricRow("Forecasted " + forecastRow.name, forecastRow[metric], colors[index], true)
              );
            }
          });

          if (results.historical_other_row) {
            pieData.push(
              pieChartMetricRow(results.historical_other_row.name, results.historical_other_row[metric], colors[10], false)
            );
          }
          if (results.forecast_other_row) {
            pieData.push(
              pieChartMetricRow("Forecasted " + results.forecast_other_row.name, results.forecast_other_row[metric], colors[10], true)
            );
          }
        }
        else {
          pieData = _.map(results.top_rows, function (row, index) {
            return pieChartMetricRow(row.name, row[metric], colors[index], false);
          });
          if (results.other_row) {
            pieData.push(
              pieChartMetricRow(results.other_row.name, results.other_row[metric], colors[10], false)
            );
          }
        }

        return pieData;
      };

      var setDataTable = function(table, orderingEnabled) {
        table.defaultDataTable({
          "processing": true,
          "ordering": orderingEnabled,
          "oLanguage": {
            "sSearch": "Search",
            "sZeroRecords": "",
            "sEmptyTable": "",
            "sProcessing": "<div class='dt-spin'></div>"
          },
          "searching": false,
          "destroy": $.fn.dataTable.isDataTable(table)
        });
      };

      var loadObjectSection = function(div, options = {}) {
        if (!div.length) {
          return;
        }

        var tableOrderingEnabled = _.contains([true, false], options.tableOrdering) ? options.tableOrdering : true;

        if (options.pieChart) {
          setChartPanelLoading(options.pieChart);
        }
        if (options.distributionPieChart) {
          setChartPanelLoading(options.distributionPieChart);
        }
        if (options.envPieChart) {
          setChartPanelLoading(options.envPieChart);
        }
        var table = $(div).find('.table');
        setDataTable(table, tableOrderingEnabled);

        platformUtilities.triggerDataTablesProcessing(table, true);

        var objectPath = div.data('path');
        var params = dashboardHelper.dashboardParams(self);

        if (self.selectedMetric) {
          params = _.extend({}, params, {
            metric: self.selectedMetric
          });
        }

        ajaxRequests[objectPath] = $.get(objectPath, params, function (results) {
          if (options.pieChart) {
            options.pieChart.data = _pieDataFrom(results, self.selectedMetric);
            options.pieChart.metric = getMetricLabel();
            refreshHighchart(options.pieChart);
          }
          if (options.distributionPieChart) {
            options.distributionPieChart.data = results[options.distributionPieMetric];
            refreshHighchart(options.distributionPieChart);
          }
          if (options.envPieChart) {
            options.envPieChart.data = results.environment_distribution;
            refreshHighchart(options.envPieChart);
          }

          if (results.table_partial) {
            $(div).html(results.table_partial);

            var resultsTable = $(div).find('table');
            setDataTable(resultsTable, tableOrderingEnabled);

            if (self.selectedMetric && !tableOrderingEnabled) {
              resultsTable.find('thead tr th').removeClass('sorting_desc');
              resultsTable.find('thead tr th.metric-' + self.selectedMetric).addClass('sorting_desc');
            }
          }

          dashboardHelper.reflowScrollBars();

          platformUtilities.triggerDataTablesProcessing(table, false);

          platformUtilities.initSvgFontAwesomeIcons();
        });
      };

      var refreshHighchart = function(chart) {
        $rootScope.$broadcast('refreshHighchart', chart);
      };

      var setChartPanelLoading = function(chart) {
        $rootScope.$broadcast('setHighchartLoading', chart);
      };

      var loadPanel = function() {
        _.each(self.panelInfo, function(info) {
          loadObjectSection($(info.div), info.options);
        });
      };

      this.setMetric = function(val) {
        self.selectedMetric = val;

        mixpanel.track('dashboard top 10 metric click', {
          metric: val,
          objects: self.panelId
        });

        loadPanel();
      };

      $rootScope.$on('reportCurrencyChange', function(e, args) {
        self.currency = args.currency;
      });

      $rootScope.$on('dashboardAbortPendingAjaxRequests', function(e, args) {
        dashboardHelper.abortAjaxRequests(ajaxRequests);
      });

      $rootScope.$on('dashboardDateRangeChange', function(e, args) {
        self.selectedDateRange = args.dateRange;
      });

      $rootScope.$on('forecastDashboardDateRangeChange', function(e, args) {
        self.selectedForecastDateRange = args.forecastDateRange;
      });

      $rootScope.$on('dashboardSelectedTypeChange', function (e, args) {
        self.selectedType = args.selectedType;
      });

      $rootScope.$on('dashboardSelectedFormatChange', function (e, args) {
        self.selectedFormat = args.selectedFormat;
      });

      $rootScope.$on('dashboardTopPanelLoad', function (e, args) {
        if (args.id === self.panelId) {
          loadPanel();
        }
      });

    }]
});

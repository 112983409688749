import HighchartsBuilder from '../../modules/highcharts_builder.js';

angular.module('vastdesk')
.component('performanceChart', {
  bindings: {
    chartId: '@',
    pointsType: '@',
    dateRange: '@',
    forecastDateRange: '@',
    timezone: '@',
    externalBidder: '@',
    pod: '@',
    leftAxisSeries: '@',
    magniteStreamingModal: '@',
    selectedType: '@',
    dashboard: '<',
    ctvReportingViews: '<',
    currencySymbol: '@',
    reportingView: '@',
    environment: '@'
  },
  template: '<div class="graphChart performanceChart"><div></div></div>',
  controller: ['$scope', '$rootScope', '$element', function($scope, $rootScope, $element) {

    var self = this;
    var theme = window.platformTheme;
    var initialLeftAxisSeries;
    var onInitLoad = $.Deferred();
    var highchartsBuilder, colors;

    var setDashboardVars =  function() {
      if (self.dashboard) {
        self.pod = (self.ctvReportingViews && self.selectedType !== 'demand') ? 'true' : 'false';
        self.leftAxisSeries = self.selectedType !== 'demand' ? initialLeftAxisSeries : '';
      }
    };

    this.$onInit = function() {
      highchartsBuilder = new HighchartsBuilder(self.timezone);
      colors = highchartsBuilder.chartColors;
      initialLeftAxisSeries = self.leftAxisSeries;
      setDashboardVars();
      onInitLoad.resolve();
    };

    var chartDiv = $element.find('div');
    var chart;

    var isExternalBidder = function() {
      return self.externalBidder === 'true';
    };

    const isMagniteStreamingModal = () => {
      return isExternalBidder() && this.magniteStreamingModal === 'true';
    };

    var isPod = function() {
      return self.pod === 'true';
    };

    var dspSub = function() {
      return self.reportingView === 'dsp_sub';
    };

    var getRequestsMetric = function() {
      var metric = ['usable_requests', 'Requests'];

      if (isMagniteStreamingModal()) {
        metric = ['openrtb_bids', 'Prog Bids'];
      }
      else if (isExternalBidder()) {
        metric = ['openrtb_bid_requests', 'Bid Reqs'];
      }
      else if (dspSub()) {
        metric = ['has_ads', 'Bids'];
      }

      return metric;
    };

    var getRequestFillMetric = function() {
      var metric = ['fill_rate', 'Req Fill %'];

      if (isExternalBidder()) {
        metric = ['openrtb_bid_rate', 'Prog Bid %'];
      }
      else if (dspSub()) {
        metric = ['efficiency_rate', 'Efficiency %'];
      }

      return metric;
    };

    var constructAdditionalSeries = function(chart, dataPoints, chartData, name, metric, forecast) {
      chart.highcharts().addSeries({
        name: name,
        type: 'column',
        shadow: false,
        yAxis: 1,
        data: highchartsBuilder.getDataFromTimeValues(dataPoints, chartData, metric, highchartsBuilder.forecastDataFromTimeValuesOptions(self, forecast)),
        formatType: 'percent',
        color: highchartsBuilder.columnColor(metric === "openrtb_bid_fill_rate" ? colors[0]: theme.quaternaryColor, forecast),
        zIndex: 1,
        connectNulls: false
      });
    };

    var getLeftAxisColor = function() {
      return (self.leftAxisSeries === 'impressions') ? colors[2] : colors[1];
    };

    var getRequestsYAxis = function() {
      return (self.leftAxisSeries === 'impressions') ? 2 : 0;
    };

    var getImpressionsYAxis = function() {
      return (self.leftAxisSeries === 'impressions') ? 0 : 2;
    };

    var setChart = function(chartData, forecastData, changelogs) {
      var dataPoints = highchartsBuilder.getDataPointsFromDateRange(self.dateRange, {
        interval: self.pointsType,
        forecastDateRange: self.forecastDateRange
      });

      var requestsMetric = getRequestsMetric();
      var requestFillMetric = getRequestFillMetric();
      var dateFormat = highchartsBuilder.getDateFormatFromInterval(self.pointsType);

      chart = chartDiv.highcharts({
        chart: {
          zooming: {
            type: 'xy'
          },
          backgroundColor: null,
          animation: false
        },
        title: {
          text: ''
        },
        credits: {enabled: false},
        colors: colors,
        xAxis: [{
          categories: dataPoints,
          crosshair: true,
          tickLength: 0,
          lineColor: '#ccd6eb',
          labels: {
            formatter: function() {
              if (highchartsBuilder.validAxisValue(this)) {
                return this.value.format(dateFormat);
              }
            },
            reserveSpace: true,
            y: 10,
            style: {
              color: '#acacac',
              fontSize: '9px'
            }
          }
        }],
        yAxis: [
        {//secondary
          title: {text: ''},
          labels: {
            formatter: function() {
              return highchartsBuilder.revisedDefaultLabelFormatter(this);
            },
            reserveSpace: true,
            style: {
              fontSize: '9px',
              fontWeight: 'bold',
              color: getLeftAxisColor()
            }
          },
          gridLineColor: '#d7d7d7'
        },
        {//primary
          gridLineWidth: 0,
          title: {text: ''},
          labels: {
            formatter: function() {
              return highchartsBuilder.yAxisFormatter('percent', this, self.currencySymbol);
            },
            reserveSpace: true,
            style: {
              fontSize: '9px',
              fontWeight: 'bold',
              color: colors[0],
              opposite: true
            }
          },
          opposite: true,
          gridLineColor: '#d7d7d7'
        },
        {//tertiary
          gridLineWidth: 0,
          title: {text: ''},
          labels: {enabled:false},
          opposite: true
        }
        ],
        legend: highchartsBuilder.defaultLegend(),
        tooltip: {
          formatter: function() {
            return highchartsBuilder.changelogTooltipFormatter(this, self.pointsType, self.currencySymbol);
          },
          shared: true,
          backgroundColor: '#FFFFFF',
          borderColor: '#484848',
          style: {
            fontSize: '10px',
            lineHeight: '14px',
            opacity: '0.95'
          }
        },
        plotOptions: {
          series: {
            marker: {
              enabled: false
            },
            states: {
              hover: {
                enabled: false
              }
            },
            shadow: false
          },
          column: {
            grouping: false,
            shadow: false,
            borderWidth: 0
          }
        },
        series: [
          requestsSeries(dataPoints, chartData, requestsMetric, false),
          impressionsSeries(dataPoints, chartData, false),
          self.environment === "dooh" ? null : requestFillSeries(dataPoints, chartData, requestFillMetric, false)
        ].filter( Boolean ) // Filter removes null if it is added to the array. 
      });

      addExtraSeries(chart, dataPoints, chartData, false);

      if (highchartsBuilder.includeForecastingSeries(self.forecastDateRange, self.pointsType, forecastData)) {
        chart.highcharts().addSeries(
          requestsSeries(dataPoints, forecastData, requestsMetric, true)
        );
        chart.highcharts().addSeries(
          impressionsSeries(dataPoints, forecastData, true)
        );
        if (self.environment != "dooh") {
          chart.highcharts().addSeries(
            requestFillSeries(dataPoints, forecastData, requestFillMetric, true)
          );
        }

        addExtraSeries(chart, dataPoints, forecastData, true);
      }

      if (changelogs) {
        highchartsBuilder.addChangelogs(chart, changelogs, dataPoints, dateFormat);
      }
    };

    var addExtraSeries = function(chart, dataPoints, chartData, forecast) {
      if (isExternalBidder() && !isMagniteStreamingModal()) {
        constructAdditionalSeries(chart, dataPoints, chartData, highchartsBuilder.seriesNameFor('Prog Bid Fill %', forecast), 'openrtb_bid_fill_rate', forecast);
      }
      else if (self.selectedFormat === 'tile') {
        constructAdditionalSeries(chart, dataPoints, chartData, highchartsBuilder.seriesNameFor('CTR', forecast), 'click_through_rate', forecast);
      }
      else if (isPod()) {
        constructAdditionalSeries(chart, dataPoints, chartData, highchartsBuilder.seriesNameFor('Pod Time Req Fill %', forecast), 'ad_pod_fill_rate', forecast);
      }
    };

    var requestsSeries = function(dataPoints, chartData, requestsMetric, forecast) {
      return {
        name: highchartsBuilder.seriesNameFor(requestsMetric[1], forecast),
        type: 'spline',
        yAxis: getRequestsYAxis(),
        data: highchartsBuilder.getDataFromTimeValues(dataPoints, chartData, requestsMetric[0], highchartsBuilder.forecastDataFromTimeValuesOptions(self, forecast)),
        formatType: 'number',
        color: colors[1],
        shadow: highchartsBuilder.defaultShadow(),
        lineWidth: 2,
        zIndex: 2,
        connectNulls: false,
        dashStyle: forecast ? 'dash' : 'solid',
        legendSymbol: forecast ? 'rectangle' : 'lineMarker',
        legendColor: forecast ? highchartsBuilder.stripeFillColor(colors[1]) : null
      };
    };

    var impressionsSeries = function(dataPoints, chartData, forecast) {
      return {
        name: highchartsBuilder.seriesNameFor('Impressions', forecast),
        type: 'spline',
        yAxis: getImpressionsYAxis(),
        data: highchartsBuilder.getDataFromTimeValues(dataPoints, chartData, "impressions", highchartsBuilder.forecastDataFromTimeValuesOptions(self, forecast)),
        formatType: 'number',
        color: colors[2],
        shadow: highchartsBuilder.defaultShadow(),
        lineWidth: 2,
        zIndex: 3,
        connectNulls: false,
        dashStyle: forecast ? 'dash' : 'solid',
        legendSymbol: forecast ? 'rectangle' : 'lineMarker',
        legendColor: forecast ? highchartsBuilder.stripeFillColor(colors[2]) : null
      };
    };

    var requestFillSeries = function(dataPoints, chartData, requestFillMetric, forecast) {
      return {
        name: highchartsBuilder.seriesNameFor(requestFillMetric[1], forecast),
        type: 'column',
        shadow: false,
        yAxis: 1,
        data: highchartsBuilder.getDataFromTimeValues(dataPoints, chartData, requestFillMetric[0], highchartsBuilder.forecastDataFromTimeValuesOptions(self, forecast)),
        formatType: 'percent',
        color: highchartsBuilder.columnColor(requestFillMetric[0] == "openrtb_bid_rate" ? theme.quaternaryColor: colors[0], forecast),
        zIndex: 1,
        connectNulls: false
      };
    };

    $rootScope.$on('dashboardDateRangeChange', function(e, args) {
      self.dateRange = args.dateRange;
    });

    $rootScope.$on('forecastDashboardDateRangeChange', function(e, args) {
      self.forecastDateRange = args.forecastDateRange;
    });

    $rootScope.$on('dashboardSelectedTypeChange', function (e, args) {
      self.selectedType = args.selectedType;
      setDashboardVars();
    });

    $rootScope.$on('dashboardSelectedFormatChange', function (e, args) {
      self.selectedFormat = args.selectedFormat;
    });

    $rootScope.$on('reportCurrencySymbolChange', function (e, args) {
      self.currencySymbol = args.currencySymbol;
    });

    $scope.$on('setHighchartLoading', function(e, args) {
      highchartsBuilder.loadData(onInitLoad, chartDiv, self, args);
    });

    $scope.$on('refreshHighchart', function(e, args) {
      highchartsBuilder.refreshData(onInitLoad, chartDiv, self, args, function() {
        setChart(args.data, args.forecastData, args.changelogs);
      });
    });

    $scope.$on('reflowHighcharts', function(e, args) {
      highchartsBuilder.deferredReflowChart(onInitLoad, chartDiv);
    });

  }]
});

import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('creativeLanguageTargeting',  {
    bindings: {
      formName: '@',
      allowList: '@',
      selectedCodes: '<',
      blockUnknown: '<',
      readOnly: '<'
    },
    template: require("../templates/creativeLanguageTargeting.html"),
    controllerAs: '$creativeLanguageCtrl',
    controller: function() {
      this.allowlistButtonOptions = componentHelper.allowlistOptions();
    }
});

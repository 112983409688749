angular.module('vastdesk').
  component('nonBillableFees',  {
    bindings: {
      formName: '@',
      formId: '@',
      addFeeLabel: '@',
      costBasisMappings: '<',
      currencyOptions: '<',
      currencyEditable: '<',
      fees: '<'
    },
    template: require("../templates/nonBillableFees.html"),
    controllerAs: '$nonBillableFeesCtrl',
    controller: function() {

      this.removeFee = (fee, index) => {
        if (fee.id) {
          fee._destroy = true;
        }
        else {
          this.fees.splice(index, 1);
        }
      };

      this.addFee = () => {
        this.fees.push({_destroy: false, currency: "USD"});
        setTimeout(() => $("non-billable-fees").find('select').defaultChosen(), 0);

        $("non-billable-fees").on('change', 'select', () => {
          $("non-billable-fees").find('.input-group-append select').defaultChosen();
        });
      };
    }

});

$(document).on('turbo:load', function() {
  $("form.autosubmit").on('change', 'select', function(){
    if($(this).val()){
      $(this).parents("form.autosubmit").submit();
    }
  });

  var updateFormAction = function(btn) {
    var element = $(btn).closest('form[js-update-form-anchor]');
    element.attr("action", element.attr("action").replace(/#.*$/,"") + window.location.hash);
  };

  $('form[js-update-form-anchor]').on('submit', null, function(e) {
    updateFormAction(this);
  });

  $('form[js-update-form-anchor]').find("input[type='submit'][value='Apply'], input[type='submit'][value='Submit']").on('click', null, function(e) {
    updateFormAction(this);
  });

  $('*[data-disable-fields=true]').each(function(i, node) {
    var element = $(node);
    element.find('*[ng-disabled]').not('.allow-read-only').removeAttr('ng-disabled');
    element.find('*[ng-click]').not('.allow-read-only').removeAttr('ng-click');
    element.find('a').not("a[data-toggle='collapse'], a.allow-read-only").addClass('no-pointer');
    element.find('.delgroup').not('.allow-read-only').addClass('no-pointer');
    element.find('.btn').not('.allow-read-only').addClass('no-pointer');
    element.find('*').not('.allow-read-only').attr('disabled', true);

    setTimeout(function() {
      $.each(element.find('select:not([data-ajax])'), function(index, select){
        $(select).trigger('chosen:updated');
      });
    }, 0);

    element.find('.btn-group label').click(function(e) {
      if ($(this).attr('disabled')) {
        e.stopImmediatePropagation();
        return false;
      }
    });
  });
});

angular.module('vastdesk').
  component('modifierRules', {
    bindings: {},
    controller: () => {
      const bodySelector = $('body');

      bodySelector.on('cocoon:after-insert', '#modifier_rules', function () {

        $.each($(this).find("select[data-ajax]"), (index, select) => {
          $(select).defaultTomSelect();
        });
        $.each($(this).find("select[data-dynamic-chosen]"), (index, select) => {
          $(select).dynamicChosen();
        });
        $.each($(this).find("select:not([data-ajax]):not([data-dynamic-chosen])"), (index, select) => {
          $(select).defaultChosen();
        });
      });

      const toggleValueAndIdsVisibility = (row) => {
        const sourceType = row.find('.modifier-source-type input:checked').val();
        const selectedObjectOption = $(row).find('.modifier-rule-object select option:selected');
        const idsAttribute = selectedObjectOption.data('associationIdsAttr');
        const valuesOnlyField = selectedObjectOption.data('valuesOnlyField');

        row.find('.modifier-list-ids, .modifier-values').addClass('hidden');

        if (sourceType === 'values') {
          if (valuesOnlyField !== '') {
            row.find('.modifier-' + valuesOnlyField).removeClass('hidden');
          }
          else {
            row.find('.modifier-modifier_rule_values').removeClass('hidden');
          }
        }
        else if (sourceType === 'list' && idsAttribute !== '') {
          row.find('.modifier-list-ids.modifier-' + idsAttribute).removeClass('hidden');
        }
        if (selectedObjectOption.val() === 'DealList' && sourceType === 'values') {
          row.find('.modifier-values.modifier-deal-values').toggleClass('hidden', false)
          row.find('.modifier-values:not(.modifier-deal-values)').toggleClass('hidden', true).attr('disabled', true);
        }
        else {
          row.find('.modifier-values.modifier-deal-values').toggleClass('hidden', true).attr('disabled', true);
        }
      };

      bodySelector.on('change', '.modifier-source-type input', function () {
        const row = $(this).closest('.modifier-rules');
        toggleValueAndIdsVisibility(row);
      });

      bodySelector.on('change', '#modifier_rules .modifier-rule-object select', function () {
        const row = $(this).closest('.modifier-rules');
        const modifierRuleObject = $(this).val();
        const modifierRuleObjectSelected = modifierRuleObject !== '';
        const selectedOption = $(this).find('option:selected');
        const valuesOnlyField = selectedOption.data('valuesOnlyField');
        const idsAttribute = selectedOption.data('associationIdsAttr');
        const valuesOnlyFieldSelected = valuesOnlyField !== '' && idsAttribute === '';

        const sourceType = row.find('.modifier-source-type input:checked').val();

        if (valuesOnlyFieldSelected && sourceType === 'list') {
          row.find('.modifier-source-type .btn-values').trigger("click");
        }

        row.find('.modifier-source-type, .modifier-multiplier').toggleClass('hidden', !modifierRuleObjectSelected);
        row.find('.modifier-modifier_rule_values select option').remove();
        row.find('.modifier-modifier_rule_values select').val([]).trigger('chosen:updated');
        row.find('.modifier-source-type .btn-list').toggleClass('hidden', valuesOnlyFieldSelected);

        toggleValueAndIdsVisibility(row);
      });

    }
  });

import platformUtilities from '../../modules/platform_utilities.js';
import WaterfallHelper from '../../modules/waterfall_helper.js';

angular.module('vastdesk').
  component('podSchedulePods',  {
    bindings: {
      placementType: '@',
      placementTypes: '<',
      currency: '@',
      currencyPlaceholder: '@'
    },
    template: require("../templates/podSchedulePods.html"),
    controllerAs: '$podSchedulePodsCtrl',
    controller: ['$rootScope', function($rootScope) {
      var self = this;
      var waterfallHelper = new WaterfallHelper();
      var bodyElement = $('body');
      var podPlacementsTableElement = $('#pod_schedule_pod_placements_table');
      var summaryReport = [];
      var reportColumnClasses = [];
      var podPlacementsDataTable;

      if (window.location.hash.match('#tabPods')) {
        window.location.hash = '#tabAdBreaks';
      }

      var throttledDraw = _.throttle(function () {
        podPlacementsDataTable.draw();
      }, 200, {leading: false});

      var extractReportClasses = function(columns) {
        return _.chain(columns).pluck('class').compact().value();
      };

      var buildTable = function () {
        var dataTablesColumns = podPlacementsTableElement.data('dtColumns');
        reportColumnClasses = extractReportClasses(dataTablesColumns);
        podPlacementsDataTable = podPlacementsTableElement.defaultDataTable({
          paging: false,
          columns: dataTablesColumns
        });
      };

      var setPositionsEnabled = function() {
        var selected = $('#pod_schedule_pod_placements_table td.placement_position select').map(function() {
          return $(this).val();
        });

        $('#pod_schedule_pod_placements_table td.placement_position select option').each(function() {
          if ( _.contains(selected, $(this).attr('value')) && !$(this).is(':selected')) {
            if ($(this).attr('value') && $(this).attr('value') !== '') {
              $(this).attr('disabled', true);
            }
          }
          else {
            $(this).attr('disabled', false);
          }
        });
        setTimeout(function() {
          $('#pod_schedule_pod_placements_table td.placement_position select').trigger('chosen:updated');
        }, 100);
      };

      var addDateRangeFilter = function() {
        waterfallHelper.addDateRangeSelect({
          section: '#pod_schedule_pod_placements_table_wrapper'
        });
        dateRangeSelector().change(updateReportData);
      };

      podPlacementsTableElement.on('init.dt', function() {
        $('<input type="hidden" name="pod_schedule[set_pod_placements][-1]">').insertBefore(podPlacementsTableElement);
        setPositionsEnabled();
        addDateRangeFilter();

        if (Array.isArray(summaryReport) && summaryReport.length > 0) {
          refreshReportTableData();
        }
      });

      podPlacementsTableElement.on('draw.dt', function() {
        $('#pod_schedule_pod_placements_table td select').defaultChosen();
      });

      this.setPlacementType = function(placementType) {
        self.placementType = placementType;

        _.each(self.placementTypes, function(pt) {
          var selected = placementType === pt;
          var elem = '#pod_schedule_pod_placements_table th.placement_' + pt;

          platformUtilities.toggleHiddenColumn(podPlacementsTableElement, elem, selected);
          if (selected && podPlacementsDataTable && pt !== "dynamic") {
            $(elem).removeClass('hidden-column');
            var index = $(elem).index();
            podPlacementsDataTable.order.fixed({pre: [index,'asc']}).draw();
          }
          else {
            $(elem).addClass('hidden-column');
          }
        });
      };

      this.placementTypeLabel = function(placementType) {
        return platformUtilities.titleize(placementType);
      };

      bodyElement.on('change', '#pod_schedule_pod_placements_table td:not(.placement_time) select', function() {
        setTimeout(function() {
          throttledDraw();
        }, 0);

        setTimeout(function() {
          setPositionsEnabled();
        }, 300);
      });

      bodyElement.on('chosen:showing_dropdown', '#pod_schedule_pod_placements_table td select', function() {
        var outerHeight = $('#pod_schedule_pod_placements_table_wrapper .wrapper').outerHeight();
        var scrollHeight = $('#pod_schedule_pod_placements_table_wrapper .wrapper').get(0).scrollHeight;
        if (scrollHeight > outerHeight) {
          $('#pod_schedule_pod_placements_table_wrapper .wrapper').css('height', scrollHeight + 'px');
        }
      });

      bodyElement.on('chosen:hiding_dropdown', '#pod_schedule_pod_placements_table td select', function() {
        setTimeout(function() {
          if ( $('.chosen-container.chosen-with-drop').length < 1 ) {
            $('#pod_schedule_pod_placements_table_wrapper .wrapper').css('height', 'auto');
          }
        }, 0);
      });

      bodyElement.on('click', '.remove-all', function(e){
        $.each($('.included-placements tr'), function(i, tr){
          podPlacementsDataTable.row( tr ).remove();
        });
        throttledDraw();
        return false;
      });

      bodyElement.on('click', 'form.pod-schedule-form a.remove-placement', function () {
        var row = $(this).parents('tr.placement-row');
        podPlacementsDataTable.row(row).remove();
        throttledDraw();
        return false;
      });

      var dateRangeSelector = function() {
        return podPlacementsTableElement.parents('.dataTables_wrapper').find('#date_range');
      };

      var refreshReportTableData = function() {
        $.each($('.included-placements tr'), function (i, r) {
          var tr = $(r);

          var placedId = tr.data('podPlacementPlacedId');
          if (!placedId) {
            return;
          }

          var placedType = tr.data('podPlacementPlacedType');
          if (placedType === 'SupplyTag') {
            refreshRowData(tr, getSupplyTagReportRow(placedId));
          } else if (placedType === 'SupplyRouter') {
            refreshRowData(tr, getSupplyRouterReportRow(placedId));
          }
        });
        platformUtilities.triggerDataTablesProcessing(podPlacementsTableElement, false);
        throttledDraw();
      };

      var getSupplyTagReportRow = function(supplyTagId) {
        if (!summaryReport) {
          return {};
        }
        for (var i = 0; i < summaryReport.length; i++) {
          var row = summaryReport[i];
          if (row.supply_tag_id && row.supply_tag_id.toString() === supplyTagId.toString()) {
            return row;
          }
        }
        return {};
      };

      var getSupplyRouterReportRow = function(supplyRouterId) {
        // this wasn't listed as a requirement SupplyRouters in PodSchedules so it will stay as a stub for now
        return {};
      };

      var refreshCell = function(tr, className, value) {
        if (_.contains(reportColumnClasses, className)) {
          podPlacementsDataTable.cell(tr.find('td.' + className)).data(value);
        }
      };

      var refreshRowData = function(tr, row) {
        if (podPlacementsDataTable.row(tr).length > 0) {
          refreshCell(tr, 'report-impressions', row.impressions || 0);
          refreshCell(tr, 'report-revenue', row.revenue || self.currencyPlaceholder || '$0.00');
        }
      };

      var updateReportData = function() {
        var params = {
          date_range: dateRangeSelector().val() || 'Today',
          currency: self.currency
        };

        platformUtilities.triggerDataTablesProcessing(podPlacementsTableElement, true);

        $.get(podPlacementsTableElement.data('summaryPath'), params, function(resp) {
          summaryReport = resp.tag_report;
          self.currencyPlaceholder = resp.currency_placeholder;
          refreshReportTableData();
        });
      };

      // refresh quickstats data after it becomes stale
      var intervalTime = 0;
      setInterval(function () {
        intervalTime += 1;
        if (intervalTime >= 60 && document.hasFocus() && $('#tabAdBreaks').is('.active')) {
          updateReportData();
          intervalTime = 0;
        }
      }, 1000);

      $rootScope.$on('reportCurrencyChange', function(e, args) {
        self.currency = args.currency;
        updateReportData();
      });


      buildTable();
      initModal(bodyElement, podPlacementsDataTable, refreshReportTableData, "SupplyTag", "#open-pod-supply-tags-modal", "#pod_supply_tags_modal", "[name='tag_ids[]']");
      initModal(bodyElement, podPlacementsDataTable, refreshReportTableData, "SupplyRouter", "#open-pod-supply-routers-modal", "#pod_supply_routers_modal", "[name='supply_router_ids[]']");
      updateReportData();
    }]
});

function initModal(bodyElement, dataTable, refreshReportTableData, placedType, openButtonSelector, modalRootSelector, checkboxSelector) {
  var modalTable = $(modalRootSelector).find('table');
  var selectedIds = [];

  modalTable.on('draw.dt', function () {
    setModalCheckboxes();
  });

  var setModalCheckboxes = function() {
    $(modalRootSelector).find("input[type='checkbox']").attr('disabled', false).prop('checked', false);
    dataTable.rows().every(function () {
      var type = this.data()['DT_RowAttr']['data-pod-placement-placed-type'];
      var id = this.data()['DT_RowAttr']['data-pod-placement-placed-id'];
      var checkbox = modalTable.find('#checkbox_' + type + '_' + id);
      checkbox.attr('disabled', true).prop('checked', true);
    });
    $.each(selectedIds, function (index, id) {
      var checkbox = modalTable.find('#checkbox_' + placedType + '_' + id);
      checkbox.prop('checked', true);
    });
  };

  $(modalRootSelector).on('show.bs.modal', function () {
    selectedIds = [];
    modalTable.show();
    var modalDataTable = modalTable.defaultDataTable({'retrieve': true});
    modalDataTable.rows().nodes().page.len(modalTable.data('pageLength') || 50).search('').draw();

    let placeholderText = 'Search by object or partner name, or enter a comma-separated list of IDs...';
    if (placedType === 'SupplyTag') {
      placeholderText = 'Search by tag or partner name, or enter a comma-separated list of IDs...';
    } else if (placedType === 'SupplyRouter') {
      placeholderText = 'Search by router or partner name, or enter a comma-separated list of IDs...';
    }
    $(modalRootSelector + ' .modal-content .dataTables_filter input').attr('placeholder', placeholderText);

    $(modalRootSelector).modal();

    //hack for datatable column bug
    //https://datatables.net/forums/discussion/24424/column-header-element-is-not-sized-correctly-when-scrolly-is-set-in-the-table-setup
    setTimeout(function () {
      modalDataTable.draw();
    }, 150);
  });

  bodyElement.on('change', checkboxSelector, function(){
    var checkbox = $(this);
    var val = checkbox.val();
    if (checkbox.prop('checked')){
      selectedIds.push(val);
    } else {
      selectedIds.splice(selectedIds.indexOf(val),1);
    }
  });

  bodyElement.on('click', modalRootSelector + ' #modal_save', function(){
    modalTable.hide();
    var path = $(modalRootSelector).data('tableRowsPath');
    // convert `selectedIds` into the newer pod placements format
    var pod_placements = (selectedIds || []).map((id) => ({ placed_type: placedType, placed_id: id }));
    var params = {
      pod_placements,
      currency: self.currency
    };
    $.post(path, params, function(json) {
      dataTable.rows.add(json.data).draw();
      refreshReportTableData();
    });
    $(modalRootSelector).modal('hide');
    return false;
  });
}

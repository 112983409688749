import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('accountOpenRtbHostMapping',  {
    bindings: {
      openRtbHostMapping: '<'
    },
    template: require("../templates/accountOpenRtbHostMapping.html"),
    controllerAs: '$accountOpenRtbHostMappingCtrl',
    controller: function() {

      var self = this;

      this.attributeEnabledMappings = componentHelper.attributeEnabledMappings();

      this.setOpenRtbHostMapping = function(val) {
        self.openRtbHostMapping = val;

        $('.open-rtb-host-mapping-shown').toggleClass('hidden', !val);
      };

      this.$onInit = function() {
        self.setOpenRtbHostMapping(self.openRtbHostMapping);
      };

    }
});

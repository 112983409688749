angular.module('vastdesk')
.component('formCheckBoxTag', {
  bindings: {
    model: '=',
    fieldId: '@',
    fieldName: '@',
    clickCb: '&',
    readOnly: '=?',
    labelDisplay: '@'
  },
  template: require("../templates/formCheckBoxTag.html"),
  controllerAs: '$formCheckBoxTagCtrl',
  controller: function() {

    var self = this;

    this.formCheckBoxTagClicked = function() {
      if (self.clickCb()) {
        self.clickCb()(self.model);
      }
    };

  }
});

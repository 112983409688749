// From: https://gist.github.com/dv/fc51f798d825bff5a371
// This library fixes common problems with turbo
// - Overwrite setTimeout and setInterval to intercept generated ID's
// - Keep track of Ajax requests
//
// When turbo' unload event is called, we:
// - Cancel all setTimeouts and setIntervals
// - Abort all still running Ajax requests

$.turboTurbo = {
  xhrPool: [],
  setTimeoutPool: [],
  setIntervalPool: [],
  setup: function() {
    window.turboTurboSetTimeout = window.setTimeout;
    window.turboTurboSetInterval = window.setInterval;

    window.setTimeout = function(func, delay) {
      var timeoutId;
      if (!$.turboTurbo) {
        return;
      }
      timeoutId = window.turboTurboSetTimeout(func, delay);
      $.turboTurbo.setTimeoutPool.push(timeoutId);
      return timeoutId;
    };

    window.setInterval = function(func, interval) {
      var intervalId;
      intervalId = window.turboTurboSetInterval(func, interval);
      $.turboTurbo.setIntervalPool.push(intervalId);
      return intervalId;
    };

    $.ajaxSetup({
      beforeSend: function(jqXHR) {
        return $.turboTurbo.xhrPool.push(jqXHR);
      },
      complete: function(jqXHR) {
        return $.turboTurbo.xhrPool.splice($.inArray(jqXHR, $.turboTurbo.xhrPool), 1);
      }
    });

    return $(document).on("beforeunload page:before-unload turbo:before-render", function() {
      return $.turboTurbo.unload();
    });
  },
  unload: function() {
    var intervalId, timeoutId, xhr, _results;
    while (xhr = $.turboTurbo.xhrPool.pop()) {
      xhr.abort();
    }
    while (timeoutId = $.turboTurbo.setTimeoutPool.pop()) {
      clearTimeout(timeoutId);
    }
    _results = [];
    while (intervalId = $.turboTurbo.setIntervalPool.pop()) {
      _results.push(clearInterval(intervalId));
    }
    return _results;
  }
};

$.turboTurbo.setup();
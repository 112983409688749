angular.module('vastdesk').
  component('scheduledReportSchedule',  {
    bindings: {
      frequency: '@',
      frequencyOptions: '<',
      hour: '@',
      hourOptions: '<',
      weekdays: '<',
      dayOfMonth: '@',
      dateRange: '@',
      dateRangeEnabled: '<'
    },
    template: require("../templates/scheduledReportSchedule.html"),
    controllerAs: '$scheduledReportScheduleCtrl',
    controller: ['$rootScope', function($rootScope) {

      this.dateRangeOptions = [
        {value: false, label: 'Indefinite'},
        {value: true, label: 'Enabled'}
      ];

      this.dayOfMonthOptions = _.map(_.range(1, 29).concat([-1]), (num) => {
        const label = num === -1 ? 'Last' : num;
        return [label, num]
      });

      this.scheduleStartDate = () => {
        if (this.dateRangeEnabled && this.frequency !== 'ad_hoc') {
          return this.dateRange.split(" - ").shift();
        } else {
          return new Date().toDateString();
        }
      };

      this.scheduleEndDate = () => {
        if (this.dateRangeEnabled && this.frequency !== 'ad_hoc') {
          return this.dateRange.split(" - ").pop();
        }
      };

      this.frequencyDisabled = (frequency) => {
        return frequency !== 'ad_hoc' && this.reportDateRange === 'Custom';
      };

      this.setFrequency = (frequency) => {
        if (this.frequencyDisabled(frequency)) {
          return;
        }

        this.frequency = frequency;

        $rootScope.$broadcast('scheduledReportFrequencyChange', {
          frequency: frequency
        });
      };

      $rootScope.$on('reportDateRangeChange', (e, args) => {
        this.reportDateRange = args.reportDateRange;
      });

      this.$onInit = () => {
        const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        this.days = {};

        _.each(dayNames, (dayName) => {
          this.days[dayName] = _.contains(this.weekdays, dayName);
        });
      };

    }]
});

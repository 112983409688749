import WaterfallHelper from '../../modules/waterfall_helper.js';
import WaterfallModalHelper from '../../modules/waterfall_modal_helper.js';

angular.module('vastdesk')
.component('modifierAssociations', {
  bindings: {
    formName: '@',
    associationName: '@',
    objectIds: '=',
    tableSection: '@',
    modalToggle: '@',
    modalElement: '@',
    buttonLabel: '@',
    tableId: '@',
    batchActions: '<',
    editable: '<'
  },
  template: require("../templates/modifierAssociations.html"),
  controllerAs: '$modifierAssociationsCtrl',
  controller: ['$scope', function($scope) {
    var self = this;
    self.selectedBulkActionIds = [];

    var bodySelector = $('body');
    var serverSideTable;
    var serverSideTableId;
    var serverSideDataTable;
    var modal;
    var modalTable;
    var filteredRecordIds = [];
    var selectedIds = [];
    var waterfallHelper = new WaterfallHelper();
    var waterfallModalHelper = new WaterfallModalHelper();

    this.$onInit = function() {
      serverSideTable = $(self.tableSection + ' table');
      serverSideTableId = $(serverSideTable).attr('id');
      modal = $(self.modalElement);
      modalTable = $(self.modalElement + ' table');

      serverSideTable.on('init.dt', function() {
        serverSideDataTable = $(this).DataTable();
      });

      serverSideTable.on('preXhr.dt', function (e, settings, data) {
        waterfallHelper.abortPendingAjaxRequests(serverSideTable, serverSideDataTable);

        data[self.associationName] = self.objectIds;
        data.custom_data = data.custom_data || {};
        data.custom_data.selected_ids = self.selectedBulkActionIds;
      });

      serverSideTable.on('xhr.dt', function(e, settings, json, xhr) {
        if (json && json.custom_data) {
          filteredRecordIds = json.custom_data.filtered_record_ids;
        }
      });

      modalTable.on('preXhr.dt', function(e, settings, data) {
        data.custom_data = data.custom_data || {};
        data.custom_data.selected_ids = [].concat(self.objectIds).concat(selectedIds);
      });

      modalTable.on( 'draw.dt', function () {
        waterfallModalHelper.setSelectedModalCheckboxes(modalTable, selectedIds);
        waterfallHelper.setModalCheckboxes(modalTable, self.objectIds);
      });

      waterfallModalHelper.openEventHandler(bodySelector, '.' + self.modalToggle, modal, modalTable, function() {
        selectedIds = [];
        $('.modal-content .dataTables_filter input').attr("placeholder", "Search by tag or partner name or enter a comma-separated list of IDs...");
      });

      waterfallModalHelper.saveEventHandler(bodySelector, modal, modalTable, function() {
        waterfallHelper.addIds(self, 'objectIds', selectedIds, serverSideDataTable);
        $scope.$apply();
      });

      bodySelector.on("click", self.tableSection + " a.remove-tag", function() {
        var associationObjectId = $(this).data('id');

        waterfallHelper.removeIds(self, 'objectIds', [associationObjectId], serverSideDataTable, function() {
          $scope.$apply();
        });

        return false;
      });

      bodySelector.on("click", self.tableSection + " .remove-all", function(e){
        removeAllFilteredObjects();
        return false;
      });

      bodySelector.on("change", self.modalElement + " [name='batch_object_ids[]']", function(){
        var checkbox = $(this);
        var val = checkbox.val();
        if(checkbox.prop("checked")){
          selectedIds.push(val);
        } else {
          selectedIds = _.without(selectedIds, val);
        }
      });
    };

    var removeAllFilteredObjects = function() {
      self.objectIds = _.filter(self.objectIds, function(objectId) {
        return !_.contains(filteredRecordIds, parseInt(objectId));
      });
      $scope.$apply();
      waterfallHelper.drawServerSideTable(serverSideDataTable, true);
    };

    $scope.$on('bulkEditBroadcast', function(e, args) {
      if (args.tableId === serverSideTableId) {
        if (!args.selectedIds || !Array.isArray(args.selectedIds)) {
          return;
        }

        var associationObjectIds = _.map(args.selectedIds, function(id) {
          return parseInt(id);
        });

        if (args.id === 'remove') {
          waterfallHelper.removeIds(self, 'objectIds', associationObjectIds, serverSideDataTable);
        }
      }
    });

  }]
});

import HighchartsBuilder from '../../modules/highcharts_builder.js';
import { highchartsComparisonConfigHelper } from '../../modules/highcharts_comparison_config_helper.js';

angular.module('vastdesk')
.component('comparisonChart', {
  bindings: {
    chartId: '@',
    metric: '@',
    timezone: '@',
    currencySymbol: '@'
  },
  template: '<div class="graphChart comparisonChart"><div></div></div>',
  controller: ['$scope', '$rootScope', '$element', function($scope, $rootScope, $element) {

    var self = this;
    var onInitLoad = $.Deferred();
    var highchartsBuilder, colors;

    this.$onInit = function() {
      highchartsBuilder = new HighchartsBuilder(self.timezone);
      colors = highchartsBuilder.chartColors;
      onInitLoad.resolve();
    };

    var chartDiv = $element.find('div');
    var chart;

    var setChart = function(chartData) {
      var todayAllHours = highchartsBuilder.getHourDataPoints('', 23);
      var currentHours = highchartsBuilder.getHourDataPoints();
      var formatType = highchartsBuilder.formatTypeForMetric(self.metric);

      var series = [{
        name: 'Today',
        type: 'areaspline',
        color: colors[0],
        lineWidth: 2,
        fillColor : highchartsBuilder.springyAreaSplineFill(colors[0]),
        data: highchartsBuilder.getDataFromHourValues(currentHours, chartData.today, self.metric),
        formatType: formatType,
        zIndex: 1
      },
      {
        name: 'Yesterday',
        type: 'spline',
        data: highchartsBuilder.getDataFromHourValues(todayAllHours, chartData.yesterday, self.metric),
        formatType: formatType,
        color: colors[1],
        shadow: highchartsBuilder.defaultShadow(),
        lineWidth: 2,
        zIndex: 3
      },
      {
        name: 'Seven Days Ago',
        type: "spline",
        data: highchartsBuilder.getDataFromHourValues(todayAllHours, chartData.seven_days_ago, self.metric),
        formatType: formatType,
        color: colors[2],
        shadow: highchartsBuilder.defaultShadow(),
        lineWidth: 2,
        zIndex: 2
      }];

      chart = highchartsComparisonConfigHelper({
        chartDiv,
        metric: self.metric,
        colors,
        todayAllHours,
        highchartsBuilder,
        formatType,
        series,
        currencySymbol: self.currencySymbol
      });
    };

    $scope.$on('setHighchartLoading', function(e, args) {
      highchartsBuilder.loadData(onInitLoad, chartDiv, self, args);
    });

    $scope.$on('refreshHighchart', function(e, args) {
      highchartsBuilder.refreshData(onInitLoad, chartDiv, self, args, function() {
        setChart(args.data);
      });
    });

    $scope.$on('reflowHighcharts', function(e, args) {
      highchartsBuilder.deferredReflowChart(onInitLoad, chartDiv);
    });

    $rootScope.$on('reportCurrencySymbolChange', function (e, args) {
      self.currencySymbol = args.currencySymbol;
    });

  }]
});
import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('bidPriceFields',  {
    bindings: {
      formName: '@',
      formId: '@',
      rateCurrency: '@',
      dspSubAccount: '<',
      bidFloorType: '@',
      minBidPrice: '<',
      maxBidPrice: '<',
      errors: '<'
    },
    template: require("../templates/bidPriceFields.html"),
    controllerAs: '$bidPriceFieldsCtrl',
    controller: ['$rootScope', function($rootScope) {
      const self = this;
      self.amountOfBidModifiers = 0;
      self.bidShading = '';
      
      this.attributeEnabledMappings = componentHelper.attributeEnabledMappings();

      $rootScope.$on('modifierFieldsChange', function(event, data) {
        self.amountOfBidModifiers = data.amountOfBidModifiers;
        self.bidShading = data.bidShading;
      });

      $rootScope.$on('bidFloorTypeChanged', function(event, data) {
        self.bidFloorType = data.bidFloorType;
      });

      this.formHasError = componentHelper.formHasError;

    }]
});

angular.module('vastdesk')
.component('playerAspectVisualizer', {
  bindings: {
    aspectType: '@'
  },
  template: require("../templates/playerAspectVisualizer.html"),
  controllerAs: '$playerAspectCtrl',
  controller: ['$scope', function($scope) {
    var self = this;

    this.widthPercentage = 0;
    this.heightPercentage = 0;

    this.playerAsepctStyle = function() {
      return {
        width: self.widthPercentage + '%',
        height: self.heightPercentage + '%'
      };
    };

    this.iconStyle = function() {
      var size = 40 - ((30/92) * Math.abs(self.heightPercentage - self.widthPercentage));
      var margin = '-' + (size / 2) + 'px';
      return {
        fontSize: size + 'px',
        marginTop: margin,
        marginLeft: margin,
        display: (self.heightPercentage === 0 || self.widthPercentage === 0) ? 'none' : 'block'
      };
    };

    var calculatePercentage = function(num, denom) {
      if (denom === 0) {
        return 0;
      }
      return (num / denom) * 100;
    };

    var updatePercentages = function(select) {
      var selectedOption = $(select).find('option:selected');
      var selectedW = selectedOption.data('w');
      var selectedH = selectedOption.data('h');

      if ( selectedOption.length > 0 && selectedW && selectedH) {
        self.widthPercentage = 100;
        self.heightPercentage = 100;

        if (selectedW > selectedH) {
          self.heightPercentage = calculatePercentage(selectedH, selectedW);
        }
        else if (selectedW < selectedH) {
          self.widthPercentage = calculatePercentage(selectedW, selectedH);
        }
      }
      else {
        self.widthPercentage = 0;
        self.heightPercentage = 0;
      }
      $scope.$apply();
    };

    this.$onInit = function() {
      if ( self.aspectType && self.aspectType !== '') {
        var selector = 'select[name*=' + self.aspectType + ']';

        $('body').on('change', selector, function() {
          updatePercentages(this);
        });

        setTimeout(function() {
          updatePercentages($(selector));
        }, 0);
      }
    };

  }]
});

import SelectOptionDisabler from '../../modules/select_option_disabler.js';

angular.module('vastdesk')
.component('bidOptionalParams', {
  bindings: {
    wrapper: '@',
    addElement: '@',
    fieldName: '@'
  },
  controller: ['$scope', '$rootScope', function($scope, $rootScope) {
    var self = this;
    var bodySelector = $('body');
    var selectOptionDisabler;

    this.$onInit = function() {
      selectOptionDisabler = new SelectOptionDisabler(
        self.wrapper + ' select',
        {
          'max_imps': 'multi_imp_enabled'
        }
      );

      setTimeout(function () {
        updateBidOptionalParamFields();
      }, 0);
    };

    var getPlaceholderTag = function(selectedOption) {
    
      var placeholder = 'stringPlaceholder';
      var paramType = selectedOption.data('type');
      var paramArrayValues = selectedOption.data('arrayValues');
      var paramMixedValues = selectedOption.data('mixedValues');

      if (paramType && paramType !== '') {
        placeholder = paramType;
        if (paramArrayValues) {
          placeholder += 'ArrayValues';
        }
        else if (paramMixedValues) {
          placeholder += 'MixedValues';
        }
        placeholder += 'Placeholder';
      }

      return placeholder;
    };

    var setBidOptionPlaceholders = function() {
      $('.bid-optional-params-group').each(function() {
        var selectedOption = $(this).find('select option:selected');
        var input = $(this).find('.bid-optional-params-input');
        var placeholder="";

        if (selectedOption.data('placeholderOverride') !== undefined) { 
          placeholder = selectedOption.data('placeholderOverride');
        } else {
          var placeholderTag = getPlaceholderTag(selectedOption);
          placeholder = input.data(placeholderTag);
        }

        input.attr('placeholder', placeholder);
      });
    };

    var updateBidOptionalParamFields = function() {
      selectOptionDisabler.disableParamOptions();
      setBidOptionPlaceholders();
    };

    var bidOptionalParamInputName = function(val) {
      var inputName = '';
      if (val && val !== '') {
        inputName = self.fieldName + '[' + val + ']';
      }
      return inputName;
    };

    bodySelector.on('click', '#remove_bid_optional_param', function() {
      $(this).closest('.bid-optional-params-group').remove();
      updateBidOptionalParamFields();
    });

    bodySelector.on('change', '.bid-optional-params-group select', function() {
      $(this).closest('.bid-optional-params-group').find('.bid-optional-params-input').attr('name', bidOptionalParamInputName($(this).val()));
      updateBidOptionalParamFields();
    });

    bodySelector.on('click', '#add_spotx_joint_solution_optional_param:not(:disabled)', function() {
      var path = $('#add_spotx_joint_solution_optional_param').data('path');
      if (!path) {
        return;
      }

      $.post(path, {platform: 'spotx_alpha'}, function(resp) {
        $('#spotx_joint_solution_optional_params_wrapper').append(resp);
        $('#spotx_joint_solution_optional_params_wrapper select').defaultChosen();

        setTimeout(function() {
          updateBidOptionalParamFields();
        }, 0);
      });
    });

    $rootScope.$on('updateBidOptionalParamFields', function(e, args) {
      updateBidOptionalParamFields();
    });
  }]
});

import platformUtilities from '../../modules/platform_utilities.js';

angular.module('vastdesk').
  component('accountRateTier',  {
    bindings: {
      tier: '@',
      cutoffType: '@',
      cutoffValue: '<',
      fees: '<',
      nextTier: '@',
      previousTier: '@',
      showAddButton: '<',
      showPanel: '<',
      hideRemoveButton: '<',
      newAttributes: '<',
      active: '<',
      cutoffTypeMappings: '<',
      feeTypeMappings: '<',
      tierFeeTypeMappings: '<',
      platformType: '@',
      readOnly: '<'
    },
    template: require("../templates/accountRateTier.html"),
    controllerAs: '$accountRateTierCtrl',
    controller: ['$rootScope', function($rootScope) {

      this.capitalizedTier = () => {
        return platformUtilities.titleize(this.tier);
      };

      this.dsp = () => {
        return _.contains(['dsp_sub', 'dsp_main'], this.platformType);
      };

      this.cutoffTypeDisabled = (cutoffTypeValue) => {
        return cutoffTypeValue === 'annual_spend' && !this.dsp();
      };

      this.feeTypeDisabled = (feeTypeValue) => {
        return feeTypeValue === 'percent_of_spend' && !this.dsp();
      };

      this.showAddFeeButton = (tierFeeType) => {
        return this.dsp() && nonDestroyedFees(tierFeeType).length < 2;
      };

      this.showDestroyFee = () => {
        return this.dsp() && nonDestroyedFees().length > 1;
      };

      this.addFee = (tierFeeType) => {
        if (this.readOnly) {
          return;
        }

        const newFee = _.extend({}, this.newAttributes, {tier_fee_type: tierFeeType})
        this.fees.push(newFee);
        sortFees();
      };

      this.destroyFee = (fee, index) => {
        if (this.readOnly) {
          return;
        }

        if (fee.id) {
          fee._destroy = true;
        }
        else {
          this.fees.splice(index, 1);
        }

        sortFees();
      };

      this.setCutoffType = (cutoffType) => {
        if (this.readOnly) {
          return;
        }

        this.cutoffType = cutoffType;

        $rootScope.$broadcast('rateTierCutoffTypeChange', {
          tier: this.tier,
          cutoffType: cutoffType
        });
      };

      this.setFeeType = (fee, feeTypeValue) => {
        if (this.readOnly) {
          return;
        }

        fee.fee_type = feeTypeValue;
      };

      this.showTierFeeTypeLabel = (feeIndex, tierFeeType) => {
        const previousFee = _.chain(this.fees)
                             .filter((fee, i) => {
                               return !fee._destroy && i < feeIndex;
                             })
                             .last()
                             .value();

        return this.dsp() && (!previousFee || previousFee.tier_fee_type !== tierFeeType);
      };

      this.toggleTier = (hidden) => {
        if (this.readOnly) {
          return;
        }

        this.showAddButton = hidden;
        this.showPanel = !hidden;

        if (this.showPanel) {
          if (nonDestroyedFees().length < 1) {
            this.addFee('platform');
          }
        }
        else {
          this.fees = _.filter(this.fees, (fee) => {
            return fee.id;
          });

          _.each(this.fees, (fee) => {
            fee._destroy = true;
          });

          this.cutoffValue = null;
          sortFees();
        }

        $rootScope.$broadcast('rateTierShowAddButtonChange', {
          tier: this.nextTier,
          hidden: hidden
        });

        $rootScope.$broadcast('rateTierHideRemoveButtonChange', {
          tier: this.previousTier,
          hidden: hidden
        });
      };

      this.$onInit = () => {
        sortFees();
      };

      const sortFees = () => {
        if (Array.isArray(this.fees)) {
          this.fees = _.sortBy(this.fees, 'tier_fee_type');
        }
      };

      const nonDestroyedFees = (tierFeeType) => {
        return _.filter(this.fees, (fee) => {
          return !fee._destroy &&
            (!tierFeeType || fee.tier_fee_type === tierFeeType);
        })
      };

      $rootScope.$on('rateTierCutoffTypeChange', (e, args) => {
        if (args.tier !== this.tier) {
          this.cutoffType = args.cutoffType;
        }
      });

      $rootScope.$on('rateTierShowAddButtonChange', (e, args) => {
        if (args.tier === this.tier) {
          this.showAddButton = !args.hidden;
        }
      });

      $rootScope.$on('rateTierHideRemoveButtonChange', (e, args) => {
        if (args.tier === this.tier) {
          this.hideRemoveButton = !args.hidden;
          if ( args.hidden ) {
            this.cutoffValue = null;
          }
        }
      });

      $rootScope.$on('accountPlatformTypeChange', (e, args) => {
        this.platformType = args.platformType;

        if (!this.dsp() && this.cutoffType === 'annual_spend') {
          this.cutoffType = 'impressions';
          this.cutoffValue = null;
        }
      });

    }]
});

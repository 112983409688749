import componentHelper from '../../modules/component_helper.js';
import DecimalEnforcer from '../../modules/decimalEnforcer.js';

angular.module('vastdesk').
  component('mediaFileDurationTargeting',  {
    bindings: {
      formName: '@',
      tooltipText: '@',
      readOnly: '<',
      durationTargeting: '<',
      minDuration: '<',
      maxDuration: '<',
      blockingUnknownDurations: '<'
    },
    template: require("../templates/mediaFileDurationTargeting.html"),
    controllerAs: '$mediaFileDurationCtrl',
    controller: function() {
      var self = this;
      var decimalEnforcer = new DecimalEnforcer();

      this.durationButtonOptions = componentHelper.allCustomOptions();

      this.customTooltip = function(condition) {
        return componentHelper.valueIfCondition(condition, self.tooltipText);
      };

      this.setMinDuration = function(val) {
        self.minDuration = decimalEnforcer.enforce(val, 'round', 0);
      };

      this.setMaxDuration = function(val) {
        self.maxDuration = decimalEnforcer.enforce(val, 'round', 0);
      };
    }
});

angular.module('vastdesk').
  component('campaignRateFields',  {
    bindings: {
      hasRate: '<',
      costModelType: '<',
      rateCurrency: '@',
      dspAccount: '<',
      rateLabel: '@',
      bookedRevenue: '<',
      multiCurrency: '<',
      rate: '<',
      costModelTypeOptions: '<',
      currencyOptions: '<'
    },
    template: require("../templates/campaignRateFields.html"),
    controllerAs: '$campaignRateFieldsCtrl',
    controller: ['$rootScope', function($rootScope) {

      var self = this;

      this.paymentSettingsOptions = [
        {value: true, label: 'Campaign'},
        {value: false, label: 'Tag'}
      ];

      this.setHasRate = function(val) {
        self.hasRate = val;

        $rootScope.$broadcast('hasRateChange', {
          hasRate: self.hasRate
        });
      };

      this.setCostModelType = function(val) {
        self.costModelType = val;

        $rootScope.$broadcast('costModelTypeChange', {
          costModelType: self.costModelType
        });
      };

      this.costModelTypeTooltip = function(costModelType) {
        if (costModelType === 0) {
          return 'Cost per mille';
        }
        else if (costModelType === 2) {
          return 'Cost per click';
        }
        else if (costModelType === 3) {
          return 'Cost per completed view';
        }
      };

    }]
});

angular.module('vastdesk')
.component('vendorSelection', {
  bindings: {
    formName: '@',
    preBidBlockingEnabled: '=',
    postImpEnabled: '=',
    whiteopsDefaultPostImp: '=',
    iasDefaultPostImp: '=',
    forensiqDefaultPostImp: '=',
    moatDefaultPostImp: '=',
    protectedDefaultPostImp: '=',
    preBidSpringserve: '=',
    preBidWhiteops: '=',
    postImpPercentageWhiteops: '=',
    preBidProtected: '=',
    postImpPercentageProtected: '=',
    postImpPercentageMoat: '=',
    postImpPercentageIas: '=',
    postImpPercentageForensiq: '=',
    minPostImp: '=',
    environment: '@',
    vastOnly: '<',
    routerVastOnly: '<',
    readOnly: '=',
    platformIconUrl: '@',
    defaultVastOnlyPostImpPct: '=',
    requireVastOnlyPreBid: '=',
    requireVastOnlyPostImp: '=',
    format: '@',
    preBidDisabled: '<'
  },
  template: require("../templates/vendorSelection.html"),
  controllerAs: '$vendorSelectionCtrl',
  controller: ['$scope', '$rootScope', '$filter', '$timeout', function($scope, $rootScope, $filter, $timeout) {

    var self = this;

    var vastOnlyReadOnlyFields = [];

    this.readOnlyField = function(field) {
      return self.readOnly || _.contains(vastOnlyReadOnlyFields, field);
    };

    this.setPostImpEnabled = function() {
      self.postImpEnabled = self.postImpPercentageWhiteops > 0 ||
        self.postImpPercentageProtected > 0 ||
        self.postImpPercentageMoat > 0 ||
        self.postImpPercentageIas > 0 ||
        self.postImpPercentageForensiq > 0;
    };

    $rootScope.$on('ssbFieldChange', function(e, args) {
      if (typeof(args) === 'object') {
        if (_.has(args, 'vastOnly')) {
          self.vastOnly = args.vastOnly;
        }
        if (_.has(args, 'environment')) {
          self.environment = args.environment;
        }
      }
      handleSsbFieldChange();
    });

    $rootScope.$on('applyVastOnlyVendorsFieldChange', function(e, args) {
      if (typeof(args) === 'object' && _.has(args, 'routerVastOnly')) {
        self.routerVastOnly = args.routerVastOnly;
      }
      handleApplyVastOnlyVendorsFieldChange();
    });

    $rootScope.$on('formatChanged', function(e, args) {
      self.format = args.format;
      handleApplyVastOnlyVendorsFieldChange();
    });

    this.setPreBidBlockingEnabled = function() {
      $timeout(function() {
        self.preBidBlockingEnabled = self.preBidWhiteops ||
          self.preBidSpringserve ||
          self.preBidProtected;

        setPreBidEnabledIndeterminate();
      });
    };

    this.shouldDisablePostImpComponentsMoat = function() {
      return self.environment !== 'ctv' && self.disablePostImpComponents;
    };

    this.ssb = function() {
      return self.environment === 'ctv' || self.vastOnly;
    };

    this.applyVastOnlyVendors = function() {
      return (self.vastOnly || self.routerVastOnly) && self.format === 'video';
    };

    this.setPostImpSettings = function(enabled) {
      if(enabled) {
        if(self.ssb()) {
          self.postImpPercentageProtected = 100;
        }
        else {
          self.postImpPercentageWhiteops = self.whiteopsDefaultPostImp;
          self.postImpPercentageMoat = self.moatDefaultPostImp;
          self.postImpPercentageIas = self.iasDefaultPostImp;
          self.postImpPercentageForensiq = self.forensiqDefaultPostImp;
          self.postImpPercentageProtected = self.protectedDefaultPostImp;
        }
      } else {
        self.postImpPercentageWhiteops = 0;
        self.postImpPercentageMoat = 0;
        self.postImpPercentageIas = 0;
        self.postImpPercentageProtected = 0;
        self.postImpPercentageForensiq = 0;
      }
    };

    var handleSsbFieldChange = function() {
      if (self.readOnly) {
        return;
      }

      setDisablePostImpComponents();

      if (self.disablePostImpComponents) {
        self.postImpPercentageIas = 0;
        if(self.shouldDisablePostImpComponentsMoat()) {
          self.postImpPercentageMoat = 0;
        }
      }
    };

    var handleApplyVastOnlyVendorsFieldChange = function() {
      if (self.readOnly) {
        return;
      }

      if ( self.applyVastOnlyVendors() ) {
        if (Array.isArray(self.requireVastOnlyPreBid)) {
          _.each(self.requireVastOnlyPreBid, function(vendor) {
            var scopeVar = 'preBid' + $filter('titleize')(vendor);
            self[scopeVar] = true;
            self.setPreBidBlockingEnabled();

            vastOnlyReadOnlyFields.push(scopeVar);
            vastOnlyReadOnlyFields.push('preBidBlockingEnabled');
          });
        }

        if (Array.isArray(self.requireVastOnlyPostImp)) {
          _.each(self.requireVastOnlyPostImp, function(vendor) {
            var scopeVar = 'postImpPercentage' + $filter('titleize')(vendor);
            if (!self[scopeVar] || self[scopeVar] < (self.defaultVastOnlyPostImpPct || 0)) {
              self[scopeVar] = self.defaultVastOnlyPostImpPct || 0;
            }
            self.setPostImpEnabled();

            vastOnlyReadOnlyFields.push(scopeVar);
            vastOnlyReadOnlyFields.push('postImpEnabled');
          });
        }
      }
      else {
        vastOnlyReadOnlyFields = [];
      }
    };

    this.setPreBidComponents = function(checked) {
      self.preBidSpringserve = checked;
      self.preBidProtected = checked;
      self.preBidWhiteops = checked;
    };

    var setDisablePostImpComponents = function() {
      self.disablePostImpComponents = self.ssb();
    };

    var preBidComponentsEqual = function() {
      return (self.preBidProtected === self.preBidSpringserve) &&
        (self.preBidProtected === self.preBidWhiteops);
    };

    var setPreBidEnabledIndeterminate = function() {
      $('#' + self.formName + '_pre_bid_blocking_enabled').prop("indeterminate", !preBidComponentsEqual());
    };

    this.$onInit = function() {
      setTimeout(function() {
        setPreBidEnabledIndeterminate();
        handleSsbFieldChange();
        handleApplyVastOnlyVendorsFieldChange();
      }, 0);
    };

  }]
});

import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('playerAspectTargeting',  {
    bindings: {
      formName: '@',
      formId: '@',
      allPlayerAspects: '<',
      minAspect: '@',
      maxAspect: '@',
      playerAspectOptions: '<'
    },
    template: require("../templates/playerAspectTargeting.html"),
    controllerAs: '$playerAspectCtrl',
    controller: function() {
      this.targetingOptions = componentHelper.allCustomOptions2();
    }
});

angular.module('vastdesk').
  component('accountType',  {
    bindings: {
      accountTypeId: '@',
      accountTypeOptions: '<'
    },
    template: require("../templates/accountType.html"),
    controllerAs: '$accountTypeCtrl',
    controller: ['$rootScope', '$timeout', function($rootScope, $timeout) {

      var self = this;

      this.setAccountTypeSettings = function() {
        $timeout(function() {
          $rootScope.$broadcast('accountTypeChange', {
            accountTypeId: self.accountTypeId
          });
        });
      };

    }]
});

import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('ipListTargeting',  {
    bindings: {
      formName: '@',
      formId: '@',
      allowList: '@',
      selectedOptions: '<',
      sharing: '<'
    },
    template: require("../templates/ipListTargeting.html"),
    controllerAs: '$ipListTargetingCtrl',
    controller: function() {
      var self = this;

      this.allowlistButtonOptions = componentHelper.allowlistOptions();
      this.shareButtonOptions = componentHelper.attributeEnabledMappings();

      this.shareButtonTooltip = function(val) {
        if (val) {
          return 'Allow DC partner to view IPs on IP list';
        }
      };
    }
});

import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('userNotificationOptOuts',  {
    bindings: {
      formName: '@',
      formId: '@',
      allNotificationOptOuts: '<',
      notificationOptOutsSelections: '<'
    },
    template: require("../templates/userNotificationOptOuts.html"),
    controllerAs: '$userNotificationOptOutsCtrl',
    controller: function() {

      var self = this;

      this.noneCustomOptions = componentHelper.customNoneOptions2();

      this.replaceSpaces = function(val) {
        return val.replace(/ /g, '_');
      };
    }
});

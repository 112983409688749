import HighchartsBuilder from '../../modules/highcharts_builder.js';
import DashboardHelper from '../../modules/dashboard_helper.js';

angular.module('vastdesk').
  component('dashboardReportBuilder',  {
    bindings: {
      charts: '<',
      dspDemand: '<',
      showDashboardTypes: '<',
      showFormats: '<',
      showCurrency: '<',
      dateRanges: '<',
      currency: '@',
      timezoneForForm: '@',
      selectedReportCurrency: '@',
      currencyOptions: '<',
      showForecast: '<',
      forecastDateRanges: '<'
    },
    template: require("../templates/dashboardReportBuilder.html"),
    controllerAs: '$dashboardReportBuilderCtrl',
    controller: ['$rootScope', '$timeout', function($rootScope, $timeout) {

      var self = this;
      var highchartsBuilder = new HighchartsBuilder();
      var dashboardHelper = new DashboardHelper();
      var ajaxRequests = {};

      if (window.location.pathname.match('/switch_user')) {
        Turbo.visit('/');
      }

      this.selectedType = 'overall';
      this.selectedDateRange = 'Today';
      this.selectedTypeOptions = ['overall', 'supply', 'demand'];
      this.formatOptions = [
        {
          value: 'video',
          label: 'Video',
          iconClass: 'fa-video'
        },
        {
          value: 'audio',
          label: 'Audio',
          iconClass: 'fa-headphones'
        },
        {
          value: 'tile',
          label: 'Tile',
          iconClass: 'fa-square'
        }
      ];

      var refreshHighchart = function(chart) {
        $rootScope.$broadcast('refreshHighchart', chart);
      };

      var setChartPanelLoading = function(chart) {
        $rootScope.$broadcast('setHighchartLoading', chart);
      };

      var broadcastTopPanelLoad = function(panelId) {
        $rootScope.$broadcast('dashboardTopPanelLoad', {
          id: panelId
        });
      };

      var setDataAndRefresh = function(chart, results, forecastResults) {
        chart.data = results;
        chart.forecastData = forecastResults;
        refreshHighchart(chart);
      };

      var getAccountSummaryData = function() {
        setChartPanelLoading(self.charts.performanceSunburst);
        setChartPanelLoading(self.charts.podSunburst);
        setChartPanelLoading(self.charts.financialSunburst);
        setChartPanelLoading(self.charts.completionSunburst);
        setChartPanelLoading(self.charts.revenueOneColumn);
        setChartPanelLoading(self.charts.supplyPcPie);
        setChartPanelLoading(self.charts.stackedFinancial);
        setChartPanelLoading(self.charts.staticFinancial);

        var accountSummaryPath = $('#account_summary_wrapper').data('path');

        ajaxRequests[accountSummaryPath] = $.get(accountSummaryPath, dashboardHelper.dashboardParams(self), function(data) {
          $('#account_summary').replaceWith(data.account_summary);

          if (data.account_summary_data) {
            self.charts.performanceSunburst.data = data.account_summary_data.performance_sunburst;
            self.charts.podSunburst.data = data.account_summary_data.pod_sunburst;
            self.charts.financialSunburst.data = data.account_summary_data.financial_sunburst;
            self.charts.completionSunburst.data = data.account_summary_data.completion_sunburst;
            self.charts.revenueOneColumn.data = data.account_summary_data.revenue_one_column;
            self.charts.supplyPcPie.data = data.account_summary_data.pc_distribution;
            self.charts.stackedFinancial.stacks = data.account_summary_data.financial_stacks;
            self.charts.staticFinancial.data = data.account_summary_data.financial_static;
            refreshHighchart(self.charts.performanceSunburst);
            refreshHighchart(self.charts.podSunburst);
            refreshHighchart(self.charts.financialSunburst);
            refreshHighchart(self.charts.completionSunburst);
            refreshHighchart(self.charts.revenueOneColumn);
            refreshHighchart(self.charts.supplyPcPie);
            refreshHighchart(self.charts.stackedFinancial);
            refreshHighchart(self.charts.staticFinancial);
          }
        });
      };

      var getDataFor = function(path, charts, options = {}) {
        _.each(charts, function(chart) {
          setChartPanelLoading(chart);
        });

        ajaxRequests[path] = $.get(path, dashboardHelper.dashboardParams(self), function (results) {
          _.each(charts, function(chart) {
            if (options.dataResults) {
              setDataAndRefresh(chart, results.data, results.forecast_data);
            }
            else {
              setDataAndRefresh(chart, results);
            }
          });
        });
      };

      var getMinuteData = function() {
        getDataFor($('.minutePerformanceWrapper').data('path'), [
          self.charts.performanceMinute,
          self.charts.dspFinancialMinute,
          self.charts.financialMinute,
          self.charts.comboFinancialMinute,
          self.charts.comboFinancialBidderMinute
        ]);
      };

      var getFiveMinuteData = function() {
        getDataFor($('.fiveMinutePerformanceWrapper').data('path'), [
          self.charts.performanceFiveMinute,
          self.charts.financialFiveMinute,
          self.charts.dspFinancialFiveMinute,
          self.charts.comboFinancialFiveMinute,
          self.charts.comboFinancialBidderFiveMinute
        ]);
      };

      var getHourData = function() {
        getDataFor($('.hourPerformanceWrapper').data('path'), [
          self.charts.performanceHour,
          self.charts.financialHour,
          self.charts.dspFinancialHour,
          self.charts.comboFinancialHour,
          self.charts.comboFinancialBidderHour
        ], {
          dataResults: true
        });
      };

      var getDayData = function() {
        getDataFor($('.dayPerformanceWrapper').data('path'), [
          self.charts.performanceDay,
          self.charts.financialDay,
          self.charts.dspFinancialDay,
          self.charts.comboFinancialDay,
          self.charts.comboFinancialBidderDay
        ], {
          dataResults: true
        });
      };

      var getInventoryQualityData = function() {
        var invQualityReportWrapperDiv = $('.invQualWrapper');

        if (invQualityReportWrapperDiv.length) {
          getDataFor(invQualityReportWrapperDiv.data('path'), [
            self.charts.comboMoat,
            self.charts.comboQuality
          ]);
        }
      };

      var getQualityData = function() {
        var qualityReportWrapperDiv = $('.viewabilityQualWrapper');

        if (qualityReportWrapperDiv.length) {
          setChartPanelLoading(self.charts.stackedQuality);
          setChartPanelLoading(self.charts.columnQuality);

          var qualityPath = qualityReportWrapperDiv.data('path');

          ajaxRequests[qualityPath] = $.get(qualityPath, dashboardHelper.dashboardParams(self), function (results) {
            self.charts.stackedQuality.stacks = highchartsBuilder.qualityStacks(results.quality_report);
            self.charts.columnQuality.data = results.quality_report.ivt_data;
            refreshHighchart(self.charts.stackedQuality);
            refreshHighchart(self.charts.columnQuality);
          });
        }
      };

      var getComparisonData = function() {
        getDataFor($('.comparativeDayPerformanceWrapper').data('path'), [
          self.charts.comparison
        ]);
      };

      var abortPendingAjaxRequests = function() {
        $rootScope.$broadcast('dashboardAbortPendingAjaxRequests');

        dashboardHelper.abortAjaxRequests(ajaxRequests);
      };

      var loadData = function(minuteOnly) {
        abortPendingAjaxRequests();
        getAccountSummaryData();
        getMinuteData();

        if (minuteOnly) {
          return false;
        }

        if (self.selectedType !== 'demand') {
          broadcastTopPanelLoad('topSupply');
        }
        if (self.selectedType !== 'supply') {
          broadcastTopPanelLoad('topDemand');
        }

        broadcastTopPanelLoad('topPcSupply');

        if (self.selectedType === 'demand') {
          broadcastTopPanelLoad('topPcDemand');
        }

        if (self.selectedType !== 'overall') {
          getQualityData();
        }

        getInventoryQualityData();
        getFiveMinuteData();
        getHourData();
        getDayData();
        getComparisonData();

        mixpanel.track('dashboard load', {
          dashboard_type: self.selectedType,
          format: self.selectedFormat,
          date_range: self.selectedDateRange,
          forecast_date_range: self.selectedForecastDateRange,
          currency: self.selectedReportCurrency
        });
      };

      setTimeout(function() {
        loadData();
      }, 0);

      var intervalTime = 0;
      setInterval(function () {
        intervalTime += 1;
        if (intervalTime >= 720 && document.hasFocus() && $('.dashboards-controller').length) {
          loadData(true);
          intervalTime = 0;
        }
      }, 1000);

      var showHideElementBySelectedType = function(selectedType) {
        var shownSuffix = (selectedType === self.selectedType) ? '' : ':not(.' + self.selectedType + '-dash-shown)';
        $('.' + selectedType + '-dash-shown' + shownSuffix).toggleClass('hidden', selectedType !== self.selectedType);
      };

      var showHideSelectedTypeElements = function() {
        _.each(self.selectedTypeOptions, function(selectedTypeOption) {
          showHideElementBySelectedType(selectedTypeOption);

          if (selectedTypeOption === 'overall') {
            $('.overall-dash-variable-width').each(function() {
              var typeWidths = $(this).data('widths');

              if (Array.isArray(typeWidths)) {
                if (self.selectedType === 'overall') {
                  $(this).addClass(typeWidths[0]).removeClass(typeWidths[1]);
                }
                else {
                  $(this).addClass(typeWidths[1]).removeClass(typeWidths[0]);
                }
              }
            });
          }
        });
      };

      this.setSelectedType = function(type) {
        var changed = (type !== self.selectedType);
        self.selectedType = type;

        $rootScope.$broadcast('dashboardSelectedTypeChange', {
          selectedType: type
        });

        if (changed) {
          self.refreshData();

          showHideSelectedTypeElements();

          setTimeout(function() {
            dashboardHelper.reflowGraphCharts();
          }, 0);

          dashboardHelper.reflowScrollBars();
        }
      };

      this.setSelectedFormat = function(format) {
        var changed = (format !== self.selectedFormat);
        self.selectedFormat = format;

        if (changed) {
          $rootScope.$broadcast('dashboardSelectedFormatChange', {
            selectedFormat: format
          });

          self.refreshData();
        }
      };

      this.refreshData = function() {
        loadData();
        $rootScope.$broadcast('setDefaultInterval', {
          dateRange: self.selectedDateRange
        });
        $('#refresh_button').blur();
      };

      $rootScope.$on('changeComparisonMetric', function (e, args) {
        refreshHighchart(self.charts.comparison);
      });

      $rootScope.$on('reportCurrencyChange', function(e, args) {
        self.currency = args.currency;

        $timeout(function() {
          self.refreshData();
        }, 0);
      });

      var getSelectedForecastDateRangeOptions = function(forecastDateRange) {
        return _.find(self.forecastDateRanges, function(option) {
          return option[0] === forecastDateRange;
        });
      };

      var forecastPanelTitle = function(includeForecast) {
        var forecastTitle = '';

        if (includeForecast && self.selectedForecastDateRange && self.selectedForecastDateRange !== '') {
          var forecastDateRangeOptions = getSelectedForecastDateRangeOptions(self.selectedForecastDateRange);
          if (forecastDateRangeOptions) {
            forecastTitle = ' + ' + forecastDateRangeOptions[1].label + ' Forecast';
          }
        }

        return forecastTitle;
      };

      var replaceDateRangeHtml = function(element, dateRange, includeForecast) {
        var html = $(element).html();
        if (html) {
          $(element).html(html.replace(/ *\([^)]*\) */g, ' (' + dateRange + forecastPanelTitle(includeForecast) + ')'));
        }
      };

      var updatePanelTitles = function() {
        $('.date-range-title').each(function() {
          replaceDateRangeHtml(this, self.selectedDateRange, true);
        });

        replaceDateRangeHtml($('.quality-title'), self.selectedDateRange === 'Last 7 Days' ? 'Last 7 Days' : 'Last 3 Days', false);
      };

      var broadcastSelectedForecastDateRangeChange = function() {
        $rootScope.$broadcast('forecastDashboardDateRangeChange', {
          forecastDateRange: self.selectedForecastDateRange
        });
      };

      this.selectedDateRangeChange = function() {
        $rootScope.$broadcast('dashboardDateRangeChange', {
          dateRange: self.selectedDateRange
        });
        if (self.showForecast) {
          if (self.forecastDateRangeDisabled()) {
            self.selectedForecastDateRange = undefined;
            broadcastSelectedForecastDateRangeChange();
          }
          setTimeout(function() {
            $('#forecast_date_range').trigger('chosen:updated');
          }, 0);
        }
        updatePanelTitles();
        self.refreshData();
      };

      this.forecastDateRangeDisabled = function() {
        var selectedDateRange = dashboardHelper.getSelectedDateRange(self.dateRanges, self.selectedDateRange);
        return selectedDateRange && selectedDateRange.forecast_disabled;
      };

      this.selectedForecastDateRangeChange = function() {
        if (self.forecastDateRangeDisabled()) {
          return;
        }
        $('.forecasting-hidden').toggleClass('hidden', self.selectedForecastDateRange && self.selectedForecastDateRange !== '');
        broadcastSelectedForecastDateRangeChange();
        updatePanelTitles();
        self.refreshData();
      };

      var dspDemandDash = function() {
        $timeout(function() {
          self.setSelectedType('demand');
        }, 0);
      };

      this.$onInit = function() {
        if (self.dspDemand) {
          dspDemandDash();
        }

        showHideSelectedTypeElements();

        setTimeout(function() {
          showHideSelectedTypeElements();
        }, 0);
      };

    }]
});

import componentHelper from '../../modules/component_helper.js';
import SelectOptionDisabler from '../../modules/select_option_disabler.js';

angular.module('vastdesk').
  component('targetedMacrosToggle',  {
    bindings: {
      formName: '@',
      formId: '@',
      targetedMacrosEnabled: '<',
      toggleClass: '@',
      contentIngestionEnabled: '<'
    },
    template: require("../templates/targetedMacrosToggle.html"),
    controllerAs: '$targetedMacrosToggleCtrl',
    controller: function() {

      const MACROS_MAPPING = {
        '{{CONTENT_ID}}' : 'videoId',
        '{{CONTENT_TITLE}}' : 'videoTitle',
        '{{CONTENT_SERIES}}' : 'seriesName',
        '{{CONTENT_SEASON}}' : 'season',
        '{{CONTENT_EPISODE}}' : 'episode',
        '{{DURATION}}' : 'contentLength',
        '{{CONTENT_CATEGORIES}}' : 'contentCategoriesSet',
        '{{RATING}}' : 'content_rating',
        '{{CONTENT_GENRE}}' : 'genre',
        '{{CONTENT_PRODUCER_NAME}}' : 'contentRating',
        '{{NETWORK_NAME}}' : 'network',
        '{{CHANNEL_NAME}}' : 'channel'
      };

      var self = this;
      var bodySelector = $('body');

      var macroSelectOptionDisabler = new SelectOptionDisabler(
        '#targeted_macros .nested-fields:not([style*="display: none"]) .macro-param select'
      );

      var updateTargetedMacroFields = function() {
        macroSelectOptionDisabler.disableParamOptions();
      };

      var updateParameterListSelect = function(section, macroValue) {
        var parameterListSelect = section.find('.tm-parameter-lists select');
        parameterListSelect.trigger('tokenize:clear');

        if (parameterListSelect.tokenize2()) {
          parameterListSelect.tokenize2().options.dataSource = '/searches/parameter_lists?macro_param=' + encodeURI(macroValue || '');
        }
      };

      var updateParameterValueSelect = function(section, macroValue) {
        var parameterValueSelect = section.find('.content-api-select select');
        parameterValueSelect.trigger('tokenize:clear');

        if (parameterValueSelect.tokenize2()) {
          parameterValueSelect.tokenize2().options.dataSource = `/magnite_streaming_api/search?field=${encodeURI(MACROS_MAPPING[macroValue] || '')}`;
        }
      };

      const updateApiSelect = (section) => {
        if (self.contentIngestionEnabled){
          const macroValue = $(section.find('.macro-param select')).val();
          const listType = section.find('.list_type select').val();
          if (MACROS_MAPPING.hasOwnProperty(macroValue) && _.contains(['white_list', 'black_list'], listType)) {
            section.find('.default-values select option').remove();
            section.find('.default-values select').val([]).trigger('chosen:updated');
          }else {
            const contentApiSelect = section.find('.content-api-select select');
            contentApiSelect.trigger('tokenize:clear');
          }
        }
      };

      var handleMacroChange = function(section) {
        const sourceType = section.find("input[name*='[source_type]']:checked").val();
        const macroValue = section.find('.macro-param select').val();
        const nonDefaultValues = _.contains(['{{CONTENT_LIVESTREAM}}', '{{PRODUCTION_QUALITY}}'], macroValue);
        const listType = section.find('.list_type select').val();

        if (!!self.contentIngestionEnabled && MACROS_MAPPING.hasOwnProperty(macroValue) && _.contains(['white_list', 'black_list'], listType)) {
          section.find('.content-api-select').toggleClass('hidden', sourceType !== 'values' || nonDefaultValues);
          section.find('.default-values').addClass('hidden');
          updateParameterValueSelect(section, macroValue);
        }
        else {
          section.find('.content-api-select').addClass('hidden');
          section.find('.default-values').toggleClass('hidden', sourceType !== 'values' || nonDefaultValues);
          updateApiSelect(section);
        }
 
        section.find('.production-quality-values').toggleClass('hidden', sourceType !== 'values' || macroValue !== '{{PRODUCTION_QUALITY}}');
        section.find('.livestream-value').toggleClass('hidden', sourceType !== 'values' || macroValue !== '{{CONTENT_LIVESTREAM}}');
        section.find('.tm-parameter-lists').toggleClass('hidden', sourceType !== 'list');

        updateParameterListSelect(section, macroValue);

        setTimeout(function() {
          section.find(".list_type select option[value*='white_list_partial_match']").prop('disabled', nonDefaultValues);
          section.find(".list_type select option[value*='black_list_partial_match']").prop('disabled', nonDefaultValues);
          section.find(".list_type select").trigger('chosen:updated');
        }, 0);
      };

      var visibleTargetedMacroDivs = function() {
        return $('#collapse-content-targeting .associationGroup:not([style*="display: none"])').not('.hidden');
      };

      bodySelector.on('cocoon:after-insert', '#targeted_macros', function(e, addedTargetedMacro) {
        $.each($(addedTargetedMacro).find('select:not([data-ajax]):not([data-dynamic-chosen])'), function(index, select){
          $(select).defaultChosen();
        });

        $.each($(addedTargetedMacro).find('select[data-dynamic-chosen]'), function(index, select){
          $(select).dynamicChosen();
        });

        $.each($(addedTargetedMacro).find('select[data-ajax]'), function(index, select){
          $(select).defaultTokenize();
        });

        updateTargetedMacroFields();
      });

      bodySelector.on('cocoon:after-remove', '#targeted_macros', function() {
        updateTargetedMacroFields();

        if (visibleTargetedMacroDivs().length < 1) {
          $('#collapse-content-targeting .groupedAssociationButtons .btn-false').click();
        }
      });

      bodySelector.on('change', '#targeted_macros .macro-param select', function(e) {
        var section = $(this).parents('.targeted-macros');

        updateTargetedMacroFields();
        handleMacroChange(section);

        section.find('.default-values select option').remove();
        section.find('.default-values select').val([]).trigger('chosen:updated');
      });

      this.updateApiSelectOnChange = function(e) {
        const section = $(this).parents('.targeted-macros');
        updateApiSelect(section);
      };

      this.updateSectionFieldsOnChange = function(e) {
        const section = $(this).parents('.targeted-macros');
        handleMacroChange(section);
      };

      bodySelector.on('change', '#targeted_macros .default-values select', this.updateApiSelectOnChange);
      bodySelector.on('change', '#targeted_macros .content-api-select select', this.updateApiSelectOnChange);
      bodySelector.on('change', "#targeted_macros input[name*='[source_type]']", this.updateSectionFieldsOnChange);
      bodySelector.on('change', "#targeted_macros .list_type select", this.updateSectionFieldsOnChange);

      bodySelector.on('click', '#collapse-content-targeting .groupedAssociationButtons .btn-true', function() {
        setTimeout(function() {
          if (visibleTargetedMacroDivs().length === 0) {
            $('#collapse-content-targeting #add_targeted_macro').trigger('click');
          }
        }, 100);
      });

      this.allCustomOptions = componentHelper.allCustomOptions();

      this.setTargetedMacrosEnabled = function(value) {
        self.targetedMacrosEnabled = value;

        $('.' + self.toggleClass).toggleClass('hidden', !value);
      };

      this.$onInit = function() {
        self.setTargetedMacrosEnabled(self.targetedMacrosEnabled);
      };

      setTimeout(function() {
        updateTargetedMacroFields();
      }, 0);
    }
});

import { dom } from '@fortawesome/fontawesome-svg-core';

export default {
  triggerDataTablesProcessing: function(table, isProcessing) {
    var showOrHide = (isProcessing) ? 'show' : 'hide';
    table.closest('.wrapper').find('.dataTables_processing')[showOrHide]();
    table.trigger('processing.dt', [null, isProcessing]);
  },
  cleanParseFloat: function (val){
    return parseFloat((val|| "").replace(/[^\d.-]/g,''));
  },
  initSvgFontAwesomeIcons: function() {
    $('.object-icon').each(function(i, icon) { dom.i2svg({node: icon}); });
    $('.object-icon.header-icon').removeClass('hidden');
  },
  toggleHiddenColumn: function(table, elem, display) {
    var th = $(elem);
    var index = th.index();
    th.toggle(display);
    table.find("tr td:nth-child(" + (index+1) + ")").toggle(display);
  },
  isNumeric(obj) {
    return _.contains(['number', 'string'], typeof obj) && !isNaN( obj - parseFloat( obj ) );
  },
  elementValPresent(element) {
    var val = $(element).val();
    return !!val && !(Array.isArray(val) && val.length < 1);
  },
  pillFilters: [
    'csv-report-filter',
    'dc-filter',
    'pod-filter',
    'creative-filter',
    'demand-filter',
    'scs-filter',
    'supply-class-filter'
  ],
  titleize: function (input) {
    if ( !input ) return;

    return input.charAt(0).toUpperCase() + input.slice(1);
  },
  dateRangeFutureOptions: {
    parentEl: ".daterange-place",
    opens: "left",
    timePicker: true,
    timePickerIncrement: 1,
    timePicker24Hour: true,
    locale: {
      format: 'MM/DD/YY HH:mm',
      direction: 'datehourrange-future-wrapper'
    }
  }
};
import WaterfallHelper from '../../modules/waterfall_helper.js';
import WaterfallModalHelper from '../../modules/waterfall_modal_helper.js';

angular.module('vastdesk').
  component('supplyLabelSupply',  {
    bindings: {
      supplyTagIds: '<',
      batchActions: '<',
      editable: '<'
    },
    template: require("../templates/supplyLabelSupply.html"),
    controllerAs: '$supplyLabelSupplyCtrl',
    controller: ['$scope', function($scope) {

      var self = this;
      this.selectedSupplyTagBulkActionIds = [];

      var serverSideTable = $('#tag_results table');
      var serverSideTableId = $(serverSideTable).attr('id');
      var serverSideDataTable;
      var bodySelector = $('body');
      var modal = $('#tag_modal');
      var modalTable = $("#tag_modal table");
      var selectedIds = [];
      var waterfallHelper = new WaterfallHelper();
      var waterfallModalHelper = new WaterfallModalHelper();

      serverSideTable.on('init.dt', function() {
        serverSideDataTable = $(this).DataTable();
      });

      serverSideTable.on('preXhr.dt', function (e, settings, data) {
        waterfallHelper.abortPendingAjaxRequests(serverSideTable, serverSideDataTable);

        data.supply_tag_ids = self.supplyTagIds;
        data.custom_data = data.custom_data || {};
        data.custom_data.selected_ids = self.selectedSupplyTagBulkActionIds;
      });

      modalTable.on('preXhr.dt', function(e, settings, data) {
        data.custom_data = data.custom_data || {};
        data.custom_data.selected_ids = [].concat(self.supplyTagIds).concat(selectedIds);
      });

      modalTable.on( 'draw.dt', function () {
        _styleModalRows();
        waterfallModalHelper.setSelectedModalCheckboxes(modalTable, selectedIds);
        waterfallHelper.setModalCheckboxes(modalTable, self.supplyTagIds);
      });

      bodySelector.on("click", "form.tag-form a.remove-tag", function() {
        var supplyTagId = $(this).data('id');

        waterfallHelper.removeIds(self, 'supplyTagIds', [supplyTagId], serverSideDataTable, function() {
          $scope.$apply();
        });

        return false;
      });

      waterfallModalHelper.openEventHandler(bodySelector, '.open-modal', modal, modalTable, function() {
        selectedIds = [];
        $('.modal-content .dataTables_filter input').attr("placeholder", "Search by tag or partner name, or enter a comma-separated list of IDs...");
      });

      waterfallModalHelper.saveEventHandler(bodySelector, modal, modalTable, function() {
        waterfallHelper.addIds(self, 'supplyTagIds', selectedIds, serverSideDataTable);
        $scope.$apply();
      });

      waterfallModalHelper.modalCheckEventHandler(bodySelector, 'tag_ids', function(val) {
        selectedIds.push(val);
      }, function(val) {
        selectedIds = _.without(selectedIds, val);
      });

      var _styleModalRows = function (){
        var statusIndex = modalTable.find("th.status").index();

        modalTable.find("tr").each(function(i, tr) {
          waterfallModalHelper.styleStatus(tr, statusIndex);
        });
      };

      $scope.$on('bulkEditBroadcast', function(e, args) {
        if (args.tableId === serverSideTableId) {
          if (!args.selectedIds || !Array.isArray(args.selectedIds)) {
            return;
          }

          var supplyTagIds = _.map(args.selectedIds, function(id) {
            return parseInt(id);
          });

          if (args.id === 'remove') {
            waterfallHelper.removeIds(self, 'supplyTagIds', supplyTagIds, serverSideDataTable);
          }
        }
      });

    }]
});

angular.module('vastdesk')
.component('formMultiCheckBoxTag', {
  bindings: {
    model: '=',
    fieldId: '@',
    fieldName: '@',
    fieldValue: '@',
    fieldDisabled: '=?',
    clickCb: '&',
    readOnly: '=?',
    labelDisplay: '@'
  },
  template: require("../templates/formMultiCheckBoxTag.html"),
  controllerAs: '$formMultiCheckBoxTagCtrl',
  controller: function() {

    var self = this;

    this.formMultiCheckBoxTagClicked = function() {
      if (self.clickCb()) {
        self.clickCb()();
      }
    };

  }
});

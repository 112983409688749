angular.module('vastdesk').
  component('demandForecast',  {
    bindings: {
        forecastObject: "<"
    },
    template: '',
    controllerAs: '$demandForecastCtrl',
    controller: ['$rootScope', function($rootScope) {

        this.$onInit = () => {
            this.getForecastData();   
        }

        this.getForecastData = () => {
            url = `/demand/forecasts/${this.forecastObject.id}/data.json`;
            $.get({
                url: url, 
                success: (resp) => {
                  if (resp) {
                    displayForecastData(resp);
                  }
                }, error: (resp) => {
                  if (resp) {
                    displayForecastError(resp.responseText);
                  }
                }
              });
        }

        displayForecastData = (data) => {
            if (data.status === 'completed') {
                const forecastTable = $(".forecast-display-table");
                const requestCell = forecastTable.find("tr:first-child td:first-child");
                const impressionCell = forecastTable.find("tr:first-child td:nth-child(2)");
                requestCell.text(data.total_requests.toLocaleString());
                impressionCell.text(data.total_impressions.toLocaleString());
                forecastTable.toggleClass('hidden');
                $("span#forecast-request-info").attr('class', 'hidden');
            } else if (data.status === 'querying' || data.status === 'forecasting') {
              // Poll again after 5 seconds
              setTimeout(this.getForecastData, 5000);
              displayForecastRequestInfo(data.status, '');
            } else {
              displayForecastRequestInfo(`${data.status} ${data.error}`, 'error');
            }
        }

        displayForecastRequestInfo = (displayData, classList) => {
          const statusSpan = $("span#forecast-request-info");
          statusSpan.text("Forecast Status: " + displayData);
          statusSpan.attr('class', classList);
        }
    }]
});

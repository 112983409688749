import DashboardHelper from '../../modules/dashboard_helper.js';

angular.module('vastdesk').
  component('dashboardTopTabToggles',  {
    bindings: {
      toggleOptions: '<',
      panelId: '@'
    },
    template: require("../templates/dashboardTopTabToggles.html"),
    controllerAs: '$dashboardTopTabTogglesCtrl',
    controller: ['$rootScope', function($rootScope) {

      var self = this;
      var dashboardHelper = new DashboardHelper();

      this.reflowTopSection = function(option) {
        self.activeToggle = option.labels[0];

        mixpanel.track('dashboard top 10 tab click', {
          tab: option.labels[0],
          objects: self.panelId
        });

        _.each(option.toggles, function(toggle) {
          var hideClass = toggle[0].toLowerCase() + 'Wrapper';
          var showClass = toggle[1] + toggle[0] + 'Wrapper';

          $rootScope.$broadcast('dashboardTopObjectReflow', {
            hideClass: hideClass,
            showClass: showClass
          });

          dashboardHelper.reflowDashboard(hideClass, showClass);
        });
      };

      this.$onInit = function() {
        self.activeToggle = self.toggleOptions[0].labels[0];
      };

    }]
});

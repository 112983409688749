import SwalHelper from '../modules/swal_helper.js';
import HighchartsBuilder from '../modules/highcharts_builder.js';

var bootstrapSanitizationWhiteList = $.fn.tooltip.Constructor.Default.whiteList;
if (bootstrapSanitizationWhiteList) {
  bootstrapSanitizationWhiteList.table = [];
  bootstrapSanitizationWhiteList.thead = [];
  bootstrapSanitizationWhiteList.tbody = [];
  bootstrapSanitizationWhiteList.tfoot = [];
  bootstrapSanitizationWhiteList.tr = ['data-path', 'data-user-notification-id'];
  bootstrapSanitizationWhiteList.th = ['colspan'];
  bootstrapSanitizationWhiteList.td = ['colspan'];
}

$(document).on('turbo:load', function() {
  var body = $('body');

  body.on('click', '[data-sweetalert2-confirm]', function() {
    if ($(this).data('sweetalert2Confirmed')) {
      return true;
    }

    var that = this;

    SwalHelper.confirmWarning(
      {
        title: $(that).data('sweetalert2Confirm')
      }, function() {
        $(that).data('sweetalert2Confirmed', true).click();
      }, function() {
        return false;
      }
    );

    return false;
  });

  body.tooltip({
    selector: '[data-toggle="tooltip"]',
    trigger : 'hover',
    container: 'body',
    boundary: 'viewport'
  });

  body.on('click', '[data-toggle="tooltip"]', function() {
    if ($(this).attr('aria-describedby')) {
      var that = this;

      setTimeout(function() {
        $(that).tooltip('hide');
      }, 0);
    }
  });

  $('[data-toggle="popover"]').popover();
  $('.nav-tabs a').click(function (e) {
    history.pushState( null, null, this.hash );
  });
  var activeTab = $('.nav-tabs a').filter('[href="' + window.location.hash + '"]');
  if(activeTab.length){
    window.scrollTo(0, 0);
    activeTab.tab('show');
    setTimeout(function() { window.scrollTo(0, 0); }, 200);
  }

  body.on("click", ".btn-group[data-toggle='buttons'] label[disabled]", function(){
    return false;
  });

  body.on('click', '.targeting-icons .obj-icon-active', function() {
    var path = $(this).parents('.targeting-icons').data('p');

    if (path && path !== '') {
      if ($(this).hasClass('fa-shield-alt') || $(this).hasClass('fa-award')) {
        path = path.split('#')[0];
      }
      else if ($(this).hasClass('fa-browser')) {
        path = path.split('#')[0] + '#tabProgrammatic_supply';
      }
      else if ($(this).hasClass('fa-sliders-up')) {
        path = path.split('#')[0] + '#tabSettings';
      }
      window.location = path;
    }
  });

  body.on('click', '.account-icons .obj-icon-active', function() {
    var path = $(this).parents('.account-icons').data('p');
    window.location = path;
  });

  // this fixes a bootstrap bug where multiple tab panes can be active at once if you click quickly between them
  $('body a[data-toggle="tab"]').on('shown.bs.tab', function(e) {
    var otherTabs = $(this).closest('ul').find('li a').not($(this));

    _.each(otherTabs, function(otherTab) {
      var otherTabPane = $(otherTab).attr('href');
      $(otherTabPane).removeClass('active');
    });
  });

  body.on("click", '.btn-group.checkbox-toggle[data-toggle="buttons"] label', function(e) {
    var checkbox = $(this).find('input[type="checkbox"]');
    if(checkbox.length < 1) { return; }

    if(checkbox[0].checked) {
      checkbox.prop("checked", true);
      if($(this).attr('disabled')) {
        $(this).addClass('active');
      } else {
        $(this).removeClass('active');
      }
    } else {
      checkbox.prop("checked", false);
      var otherLabels = $(this).closest('.btn-group.checkbox-toggle').find('label').not($(this));
      if(!$(this).attr('disabled')) {
        _.each(otherLabels, function(otherLabel) {
          $(otherLabel).removeClass('active');
          var otherCheckBox = $(otherLabel).find('input[type="checkbox"]');
          $(otherCheckBox).prop("checked", false);
        });
      }
    }
  });

  body.popover({
    selector: '.chart-popover',
    html: true,
    sanitize: false,
    trigger: 'hover',
    placement: function(tooltipElement, triggeringElement) {
      return $(triggeringElement).data('placement') || 'top';
    },
    fallbackPlacement: ['left', 'right', 'top', 'bottom'],
    container: 'body',
    boundary: 'viewport',
    template: '<div class="popover resp-popover" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>',
    content: '<div class="popover-chart-container"><div class="refreshing-panel-bid-density ss-white-background"><span class="refreshing-message">Refreshing...</span></div></div>'
  });

  body.on('shown.bs.popover', '.chart-popover', function() {
    if ($(this).hasClass('bid-density-popover')) {
      triggerBidDensityPopover(this);
    }
    else {
      $(this).createResponseTimesChart();
    }
  });

  // body.find('.chart-popover[data-data-check]').addClass('hidden').each(function() {
  //   $(this).toggleResponseTimesVisibility();
  // });

  var triggerBidDensityPopover = function(that) {
    var omebModal = $(that).data('omebModal');

    $('.popover-chart-container').createBidDensityChart({
      demand_tag_id: $(that).data('demandTagId'),
      supply_tag_id: $(that).data('supplyTagId'),
      slot_order: omebModal ? $(that).parents('table').attr('data-so') : $(that).data('slotOrder'),
      slot_number: omebModal ? $(that).parents('table').attr('data-sn') : $(that).data('slotNumber'),
      bidder_account_id: $(that).data('bidderAccountId'),
      date_range: $(that).data('dateRange')
    }, {
      chart_title: $(that).data('chartTitle')
    });
  };

  // mixpanel
  body.on('shown.bs.collapse', '.collapse', function() {
    mixpanel.track('panel expanded', {
      element_id: $(this).attr('id')
    });
  });

  body.on('hidden.bs.collapse', '.collapse', function() {
    mixpanel.track('panel collapsed', {
      element_id: $(this).attr('id')
    });
  });

});

import platformUtilities from '../../modules/platform_utilities.js';

angular.module('vastdesk').
  controller('AccountManagerDashboardsController', ['$scope', function($scope) {

  $scope.dateRange = 'Today';
  $scope.intervals = ['hour', 'day', 'static'];
  $scope.selectedPerformanceInterval = 'hour';
  $scope.selectedFinanceInterval = 'hour';
  $scope.sevenDayOptions = ['table', 'graph'];
  $scope.selectedSevenDayOption = 'graph';

  var replaceAccountsTableTop = false;
  var dcFilterVal = '';
  var aLaCarteFilterVal = '';
  var accountTypeFilterVal = '';
  var accountOwnerFilterVal = '';
  var accountManagerFilterVal = '';
  var pcBidderFilterVal = '';
  var pcSellerFilterVal = '';
  var accountsDataTable;
  var filterVals = [];
  var filtersCollapsed = true;
  var fullSelected = false;
  var sortDesc = '';
  var sortAsc = '';
  var financialMetrics = [
    ['total_fee' ,'Fees'],
    ['springserve_profit_net', 'SpringServe Profit (Net)'],
    ['springserve_margin_net', 'SpringServe Margin (Net)']
  ];

  var ajaxRequests = {};

  $scope.charts = {
    comparison: {
      data: [],
      id: 'accountManagerComparison'
    },
    accountPie: {
      data: [],
      id: 'accountDashboardPie',
      selector: '.refreshing-panel-a-pie'
    },
    bidderPie: {
      data: [],
      id: 'bidderDashboardPie',
      selector: '.refreshing-panel-b-pie'
    },
    lineSevenDay: {
      data: [],
      id: 'dashboardSevenDay',
      selector: '.refreshing-panel-s-line'
    },
    performanceDay: {
      data: [],
      id: 'dashboardPerformanceDay',
      selector: '.refreshing-panel-day'
    },
    performanceHour: {
      data: [],
      id: 'dashboardPerformanceHour',
      selector: '.refreshing-panel-hour'
    },
    performanceStatic: {
      data: [],
      id: 'dashboardPerformanceStatic',
      selector: '.refreshing-panel-static'
    },
    financialDay: {
      data: [],
      metrics: financialMetrics,
      id: 'dashboardFinancialDay',
      selector: '.refreshing-panel-day'
    },
    financialHour: {
      data: [],
      metrics: financialMetrics,
      id: 'dashboardFinancialHour',
      selector: '.refreshing-panel-hour'
    },
    financialStatic: {
      id: 'dashboardFinancialStatic',
    },
  };

  $scope.comparisonPerformanceMetric = 'impressions';

  $scope.comparisonPerformanceMetrics = [
    ['Total Calls', 'ad_requests'],
    ['Requests', 'usable_requests'],
    ['Billable Requests', 'billable_requests'],
    ['Opportunities', 'opportunities'],
    ['Impressions', 'impressions'],
    ['Opportunity Rate', 'opportunity_rate'],
    ['Missed Opportunities', 'missed_opportunities'],
    ['Fill Rate', 'fill_rate']
  ];

  $scope.topAccountsPanelMetrics = [
    ['ad_requests', 'Total Calls', 'TC'],
    ['impressions', 'Imps', 'I'],
    ['springserve_profit_net', 'SS Profit', '$']
  ];

  $scope.topBiddersPanelMetrics = [
    ['openrtb_bid_requests', 'Bid Reqs', 'BR'],
    ['impressions', 'Imps', 'I'],
    ['cost', 'Spend', '$']
  ];

  $scope.topAccountsPanelAccountTypes = [
    [null, 'All', 'All'],
    [2, 'Pubs', 'P'],
    [1, 'Network', 'N']
  ];

  var getMetricLabelFor = function(metric, list) {
    if (!metric) {
      return;
    }

    var match = _.find(list, function(value) {
      return value[0] === metric;
    });

    return match && match[1];
  };

  $scope.selectedTopAccountsPanelMetric = 'impressions';
  $scope.selectedTopBiddersPanelMetric = 'cost';
  $scope.selectedTopAccountsPanelAccountType = null;

  $scope.setTopAccountsPanelMetric = function(val) {
    $scope.selectedTopAccountsPanelMetric = val;
    $scope.loadTopAccountsPanel();
  };

  $scope.setTopBiddersPanelMetric = function(val) {
    $scope.selectedTopBiddersPanelMetric = val;
    $scope.loadTopBiddersPanel();
  };

  $scope.setTopAccountsPanelAccountType = function() {
    $scope.loadTopAccountsPanel();
  };

  $scope.changeComparisonMetric = function(metric) {
    refreshHighchart($scope.charts.comparison);
  };

  $scope.setSevenDayView = function(option) {
    $scope.selectedSevenDayOption = option;
    reflowScrollBars();
  };

  $scope.reflowTopSection = function(type, active) {
    $('.' + type + 'Wrapper').css('display', 'none');
    $('.' + type + active + 'Wrapper').css('display', 'block');

    reflowGraphCharts();
    reflowScrollBars();
  };

  $scope.reflowCharts = function(type, interval) {
    if($scope.intervalDisabled(interval)) { return; }
    $('.' + type.toLowerCase() + 'Wrapper').css('display', 'none');
    $('.' + interval + type + 'Wrapper').css('display', 'block');

    reflowGraphCharts();
    reflowScrollBars();
  };

  var reflowGraphCharts = function() {
    $('.graphChart').each(function() {
      var highchart = $(this).highcharts();
      if(highchart) highchart.reflow();
    });
  };

  var reflowScrollBars = function() {
    setTimeout(function() {
      $('table').trigger('reflowScrollBar');
    }, 0);
  };

  var getSelectedDateRange = function() {
    return $scope.dateRanges[$scope.dateRange];
  };

  $scope.intervalDisabled = function(interval) {
    var selectedDateRange = getSelectedDateRange();
    if (selectedDateRange) {
      return _.contains(selectedDateRange.disabled, interval);
    }
  };

  $scope.setGraphInterval = function(type, interval) {
    if ($scope.intervalDisabled(interval)) {
      return;
    }
    $scope['selected' + type + 'Interval'] = interval;
    $scope.reflowCharts(type, interval);
  };

  $scope.refreshData = function() {
    loadData();
    setSelectedInterval('Performance', $scope.selectedPerformanceInterval);
    setSelectedInterval('Finance', $scope.selectedFinanceInterval);
  };

  var setSelectedInterval = function(type, current) {
    var selectedDateRange = getSelectedDateRange();
    if (!_.contains(selectedDateRange.disabled, current)) {
      $scope.setGraphInterval(type, current);
    } else if (selectedDateRange) {
      $scope.setGraphInterval(type, selectedDateRange.default);
    }
  };

  var refreshHighchart = function(chart) {
    $scope.$broadcast('refreshHighchart', chart);
  };

  var dashboardParams = function() {
    return {
      selected_date_range: $scope.dateRange
    };
  };

  var setDataAndRefresh = function(chart, results) {
    chart.data = results;
    refreshHighchart(chart);
  };

  function emptyString(val) {
    return val === '';
  }

  $('body').on('preXhr.dt', '#accounts_table table, #seven_day_table', function (e, settings, data) {
    data.selected_date_range = $scope.dateRange;
  });

  $('body').on('init.dt', '#accounts_table table', function() {
    if(replaceAccountsTableTop) {
      if(!filtersCollapsed) {
        $('.filter-collapse').click();
      }
      if(!filterVals.every(emptyString)) {
        $('#dfilter_dc').val(dcFilterVal).trigger('chosen:updated').change();
        $('#dfilter_a_la_carte').val(aLaCarteFilterVal).trigger('chosen:updated').change();
        $('#dfilter_account_type').val(accountTypeFilterVal).trigger('chosen:updated').change();
        $('#dfilter_account_owner').val(accountOwnerFilterVal).trigger('chosen:updated').change();
        $('#dfilter_account_manager').val(accountManagerFilterVal).trigger('chosen:updated').change();
        $('#dfilter_pc_bidder').val(pcBidderFilterVal).trigger('chosen:updated').change();
        $('#dfilter_pc_seller').val(pcSellerFilterVal).trigger('chosen:updated').change();
      }
      if(fullSelected) {
        $('#accounts_table').find('.toggle-full').click();
      }
      if(sortDesc && sortDesc !== 'Imps') {
        $('#accounts_table').find('th:contains(' + sortDesc + ')').click();
      } else if(sortAsc) {
        $('#accounts_table').find('th:contains(' + sortAsc + ')').click();
        $('#accounts_table').find('th:contains(' + sortAsc + ')').click();
      }
    }

    accountsDataTable.draw();
    replaceAccountsTableTop = true;
  });

  var resetAccountsTable = function() {
    $.post('/account_manager_dashboards/accounts', dashboardParams(), function(resp){
      dcFilterVal = $('#dfilter_dc').val();
      aLaCarteFilterVal = $('#dfilter_a_la_carte').val();
      accountTypeFilterVal = $('#dfilter_account_type').val();
      accountOwnerFilterVal = $('#dfilter_account_owner').val();
      accountManagerFilterVal = $('#dfilter_account_manager').val();
      pcBidderFilterVal = $('#dfilter_pc_bidder').val();
      pcSellerFilterVal = $('#dfilter_pc_seller').val();
      filterVals = [dcFilterVal, aLaCarteFilterVal, accountTypeFilterVal, accountOwnerFilterVal, accountManagerFilterVal, pcBidderFilterVal, pcSellerFilterVal];
      filtersCollapsed = $('.filter-collapse').hasClass('collapsed');
      fullSelected = $('#accounts_table').find('.toggle-full').hasClass('active');
      sortDesc = $('#accounts_table').find('.tableFloatingHeaderOriginal').find('.sorting_desc').text();
      sortAsc = $('#accounts_table').find('.tableFloatingHeaderOriginal').find('.sorting_asc').text();

      $('#accounts_table').replaceWith(resp);
      accountsDataTable = $('#accounts_table .datatable').defaultDataTable();
    });
  };

  var loadData = function() {
    $.get($('#account_summary_wrapper').data('path'), dashboardParams(), function(data) {
      if (data.account_summary) {
        $('#account_summary').replaceWith(data.account_summary);
      }
    });
    resetAccountsTable();

    setChartPanelLoading($scope.charts.comparison);
    $.get($('.comparativeDayPerformanceWrapper').data('path'), function(results){
      $scope.charts.comparison.data = results;
      refreshHighchart($scope.charts.comparison);
    });
    setChartPanelLoading($scope.charts.performanceHour);
    setChartPanelLoading($scope.charts.financialHour);
    $.get($('.hourPerformanceWrapper').data('path'), dashboardParams(), function (results) {
      setDataAndRefresh($scope.charts.performanceHour, results);
      setDataAndRefresh($scope.charts.financialHour, results);
    });
    setChartPanelLoading($scope.charts.performanceDay);
    setChartPanelLoading($scope.charts.financialDay);
    $.get($('.dayPerformanceWrapper').data('path'), dashboardParams(), function (results) {
      setDataAndRefresh($scope.charts.performanceDay, results);
      setDataAndRefresh($scope.charts.financialDay, results);
    });
    setChartPanelLoading($scope.charts.performanceStatic);
    setChartPanelLoading($scope.charts.financialStatic);
    $.get($('.staticPerformanceWrapper').data('path'), dashboardParams(), function (results) {
      $scope.charts.performanceStatic.data = results.sunburst.performance_sunburst;
      $scope.charts.financialStatic.stacks = [
        results.stacked.fee_data,
        results.stacked.second_fee_data,
        results.stacked.cost_data
      ];
      $scope.$apply();
      refreshHighchart($scope.charts.performanceStatic);
      refreshHighchart($scope.charts.financialStatic);
    });
    $scope.loadTopAccountsPanel();
    $scope.loadTopBiddersPanel();
    loadObjectSection($('#leader_board_manager_table_wrapper'), 'manager_data');
    loadObjectSection($('#leader_board_owner_table_wrapper'), 'owner_data');
    loadObjectSection($('#leader_board_type_table_wrapper'), 'type_data');

  };

  $scope.loadTopAccountsPanel = function() {
    var metric = $scope.selectedTopAccountsPanelMetric;
    var metricLabel = getMetricLabelFor(metric, $scope.topAccountsPanelMetrics);
    var accountType = $scope.selectedTopAccountsPanelAccountType;

    loadObjectSection($('#top_accounts_table_wrapper'), 'top_account_data', {
      pieChart: $scope.charts.accountPie,
      metric: metric,
      metricLabel: metricLabel,
      tableOrdering: false,
      accountType: accountType
    });
  };

  $scope.loadTopBiddersPanel = function() {
    var metric = $scope.selectedTopBiddersPanelMetric;
    var metricLabel = getMetricLabelFor(metric, $scope.topBiddersPanelMetrics);

    loadObjectSection($('#top_bidders_table_wrapper'), 'top_bidder_data', {
      pieChart: $scope.charts.bidderPie,
      metric: metric,
      metricLabel: metricLabel,
      tableOrdering: false
    });
  };

  setTimeout(function() {
    loadData();
  }, 0);

  var intervalTime = 0;
  setInterval(function () {
    intervalTime += 1;
    if (intervalTime >= 720 && document.hasFocus() && $('.account-manager-dashboards-controller').length) {
      loadData();
      intervalTime = 0;
    }
  }, 1000);

  var setDataTable = function(table, orderingEnabled) {
    table.defaultDataTable({
      "processing": true,
      "ordering": orderingEnabled,
      "oLanguage": {
        "sSearch": "Search",
        "sZeroRecords": "",
        "sEmptyTable": "",
        "sProcessing": "<div class='dt-spin'></div>"
      }
    });
  };

  var loadObjectSection = function(div, data_name, options = {}) {
    if (!div.length) {
      return;
    }

    var pieChart = options.pieChart;
    var tableOrderingEnabled = _.contains([true, false], options.tableOrdering) ? options.tableOrdering : true;

    if (pieChart) {
      setChartPanelLoading(pieChart);
    }
    var table = $(div).find('.table');
    platformUtilities.triggerDataTablesProcessing(table, true);

    if (!tableOrderingEnabled) {
      setDataTable(table, false);
    }

    platformUtilities.triggerDataTablesProcessing(table, true);

    var objectPath = div.data('path');
    var params = dashboardParams();

    if (options.metric) {
      params = _.extend({}, params, {
        metric: options.metric
      });
    }

    if (options.accountType) {
      params = _.extend({}, params, {
        account_type_id: options.accountType
      });
    }

    ajaxRequests[objectPath] = $.get(objectPath, params, function (results) {
      if (pieChart) {
        pieChart.data = _pieDataFrom(results, options.metric);
        pieChart.metric = options.metricLabel;
        refreshHighchart(pieChart);
      }
      $scope[data_name] = results;
      $scope.$apply();

      reflowScrollBars();

      if (tableOrderingEnabled) {
        setDataTable(table, true);
      }
      platformUtilities.triggerDataTablesProcessing(table, false);

      if (options.metric) {
        table.find('thead tr th').removeClass('sorting_desc');
        table.find('thead tr th.metric-' + options.metric).addClass('sorting_desc');
      }

      platformUtilities.initSvgFontAwesomeIcons();
    });
  };

  var setChartPanelLoading = function(chart) {
    $scope.$broadcast('setHighchartLoading', chart);
  };

  var _toFloat = function(s) {
    return parseFloat((s || '').replace(/[^0-9.]/g, ''), 10) || 0;
  };

  var _pieDataFrom = function(results, metric) {
    metric = metric || 'impressions';
    var pieData = _.map(results.top_rows, function (row) {
      return {name: row.name, y: _toFloat(row[metric])};
    });
    pieData.push({name: results.other_row.name, y: _toFloat(results.other_row[metric])});
    return pieData;
  };

}]);

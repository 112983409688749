angular.module('vastdesk')
.component('tabLink', {
  bindings: {
    label: '@',
    target: '@'
  },
  controllerAs: '$tabLinkCtrl',
  template: '<a ng-click="$tabLinkCtrl.activateTab()" class="clickable">{{$tabLinkCtrl.label}}</a>',
  controller: function() {

    var self = this;

    this.activateTab = function() {
      $(self.target).tab('show');
    };

  }
});
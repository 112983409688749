angular.module('vastdesk').
  component('priceExtensionType', {
    bindings: {
      formName: '@',
      isEnabled: '<',
      priceExtType: '@'
    },
    template: require("../templates/priceExtensionType.html"),
    controllerAs: '$priceExtensionTypeCtrl',
    controller: ["$scope", "$element", function($scope, $element) {

      var self = this;

      this.$onInit = function() {
        if (self.priceExtType) self.isEnabled = true;
        setTimeout( () => self.updatePriceExtensionType(), 0);
      };

      this.setPriceExtType = function(priceExtType) {
        self.priceExtType = priceExtType;
      };

      $scope.$on('supplyPartnerSsbReturnBidPriceChange', function(e, args) {
        self.isEnabled = args.ssb_return_bid_price;
        self.updatePriceExtensionType();
      });

      this.updatePriceExtensionType = function() {
        if (self.isEnabled){ 
          // if partner has flag and priceExtType not set, set to default
          if (!self.priceExtType) {
            self.priceExtType = 'iab';
          }
        }
        else {
          // partner does not have flag so clear field
          self.priceExtType = null;
        }
      };
    }]
  });

angular.module('vastdesk').
  component('forecastFrequencyCaps', {
    bindings: {
      hasFrequencyCaps: '<'
    },
    template: '',
    controllerAs: '$forecastFrequencyCaps',
    controller: [function() {
      const bodySelector = $('body');

      customizeForObjectForecast = function() {
        $('input[id*="frequency_cap_period_amount"]').prop('disabled', true);
        $('label[for*="frequency_cap_period_minute"]').hide()
        $('label[for*="frequency_cap_period_hour"]').hide()
        $('label[for*="frequency_cap_period_day"]').trigger("click")
        $('#add_fcaps').hide();
      }

      this.$onInit = function () {
        bodySelector.on('cocoon:after-insert', '#frequency_caps', function() {
          $('#add_fcaps').hide();
          customizeForObjectForecast()
        });

        bodySelector.on('cocoon:after-remove', '#frequency_caps', function() {
          $('#add_fcaps').show();
        });
        
        if (this.hasFrequencyCaps) {
          customizeForObjectForecast();
        }
      }
    }]
});

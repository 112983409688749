angular.module('vastdesk').
  component('billForm',  {
    bindings: {
      isInvoice: '<',
      billType: '@',
      selectedMonth: '@',
      selectedYear: '@',
      formMonths: '<',
      formYears: '<'
    },
    template: require("../templates/billForm.html"),
    controllerAs: '$billFormCtrl',
    controller: function() {

      var self = this;

      this.otherType = '';

      this.invoiceOptions = [
        [true, 'Invoice'],
        [false, 'Statement']
      ];

      this.billTypeOptions = [
        ['direct_connect', 'Direct Connect', false],
        ['openrtb', 'OpenRTB', false],
        ['ad_serving', 'Ad Serving', true],
        ['other', 'Other', true]
      ];

      this.setName = function() {
        if(self.billType === 'ad_serving') {
          return 'Monthly Ad Serving Bill ' + self.nameDateRange();
        } else if (!_.contains(['direct_connect', 'openrtb'], self.billType)) {
          return self.otherType + ' ' + self.nameDateRange();
        }

        var text = "";

        if(self.billType === 'direct_connect') {
          text += 'DC ';
        } else if (self.billType === 'openrtb') {
          text += 'OpenRTB ';
        }

        if(self.isInvoice) {
          text += 'Buyer Invoice ';
        } else {
          text += 'Seller Statement ';
        }

        return text + self.nameDateRange();
      };

      this.nameDateRange = function() {
        return self.selectedYear + '-' + formattedMonth(self.selectedMonth) + '-01 - ' +
          getNextYear() + '-' + getNextMonth() + '-01';
      };

      this.setBillMonth = function() {
        return self.selectedYear + '-' + formattedMonth(self.selectedMonth) + '-01';
      };

      var getNextYear = function() {
        return (parseInt(self.selectedMonth) == 12) ? parseInt(self.selectedYear) + 1 : self.selectedYear;
      };

      var getNextMonth = function() {
        var nextMonth = (parseInt(self.selectedMonth) == 12) ? '1' : parseInt(self.selectedMonth) + 1;
        return formattedMonth(nextMonth);
      };

      var formattedMonth = function(month) {
        return (parseInt(month) < 10 ? '0' : '') + month;
      };

    }
});

angular.module('vastdesk')
.component('changelogModal', {
  bindings: {
    basePath: '@'
  },
  controller: function() {
    var self = this;
    var bodyElement = $("body");

    bodyElement.on("click", ".version-modal-link", function(){
      $(this).parents("tr").addClass("active-version");
      history.replaceState({}, '', $(this).attr('href'));
    });

    bodyElement.on("click", ".toggle-show-all", function(){
      $(".full_view").show();
      $(".filtered_view").hide();
    });

    bodyElement.on("click", ".toggle-show-changed", function(){
      $(".full_view").hide();
      $(".filtered_view").show();
    });

    $('#version_modal').on('hidden.bs.modal', function () {
      history.replaceState({}, '', self.basePath);
      $("table tr").removeClass("active-version");
    });

  }
});
import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('mediaFileStitchedCreativesTargeting',  {
    bindings: {
      formName: '@',
      tooltipText: '@',
      readOnly: '<',
      blockingStitchedCreatives: '<',
    },
    template: require("../templates/mediaFileStitchedCreativesTargeting.html"),
    controllerAs: '$mediaFileStitchedCreativesCtrl',
    controller: function() {
      var self = this;

      this.blockingStitchedCreativesButtonOptions = componentHelper.attributeEnabledMappings();

      this.customTooltip = function(condition) {
        return componentHelper.valueIfCondition(condition, self.tooltipText);
      };

    }
});


angular.module('vastdesk').
  component('scannedCreativeAdInfo',  {
    bindings: {
      originalHostname: '@',
      originalIabTier1: '<',
      originalIabTier2: '<',
      originalObjectionable: '<',
      originalBrandSafe: '@',
      originalMalicious: '@',
      originalDuration: '@',
      originalStitched: '@',
      originalPolitical: '@',
      overrideHostname: '@',
      overrideIabTier1: '<',
      overrideIabTier2: '<',
      overrideObjectionable: '<',
      overrideBrandSafe: '@',
      overrideMalicious: '@',
      overrideStitched: '@',
      overridePolitical: '@',
      iabTier1Options: '<',
      iabTier2Options: '<',
      iabTier1Raw: '<',
      iabTier2Raw: '<',
      canOverride: '<'
    },
    template: require("../templates/scannedCreativeAdInfo.html"),
    controllerAs: '$scannedCreativeAdInfoCtrl',
    controller: function() {

      this.$onInit = () => {
        this.metadataFields = [{
          type: 'text',
          label: 'Advertiser Domain',
          name: 'adomain',
          original: this.originalHostname,
          override: this.overrideHostname
        }, {
          label: 'IAB Categories',
          menuOnly: true,
          overrideFieldNames: ['iab_tier_1', 'iab_tier_2']
        },{
          type: 'multi_select',
          label: 'IAB Tier 1 Category',
          name: 'iab_tier_1',
          originalArray: this.originalIabTier1,
          overrideArray: this.overrideIabTier1,
          arrayOptions: this.iabTier1Options,
          arrayLabels: this.iabTier1Raw,
          menuHidden: true
        }, {
          type: 'multi_select',
          label: 'IAB Tier 2 Category',
          name: 'iab_tier_2',
          originalArray: this.originalIabTier2,
          overrideArray: this.overrideIabTier2,
          arrayOptions: this.iabTier2Options,
          arrayLabels: this.iabTier2Raw,
          menuHidden: true
        }, {
          type: 'multi_select',
          label: 'Audience Rating',
          name: 'objectionable',
          originalArray: this.originalObjectionable,
          overrideArray: this.overrideObjectionable,
          arrayOptions: [
            {label: 'All Audiences', value: 'All Audiences'},
            {label: 'Everyone over 12', value: 'Everyone over 12'},
            {label: 'Restricted', value: 'Restricted'},
            {label: 'Mature Audiences', value: 'Mature Audiences'}
          ]
        }, {
          type: 'boolean',
          label: 'Brand Safe',
          name: 'brand_safe',
          original: this.originalBrandSafe,
          override: this.overrideBrandSafe
        }, {
          type: 'boolean',
          label: 'Malicious',
          name: 'malicious',
          original: this.originalMalicious,
          override: this.overrideMalicious
        }];

        this.attributesFields = [{
          type: 'text',
          label: 'Duration',
          name: 'duration',
          original: this.originalDuration,
          hideWhenOriginalEmpty: true,
          menuHidden: true
        },
        {
          type: 'boolean',
          label: 'Stitched',
          name: 'stitched',
          original: this.originalStitched,
          override: this.overrideStitched
        }, {
          type: 'boolean',
          label: 'Political',
          name: 'political',
          original: this.originalPolitical,
          override: this.overridePolitical
        }];
      };

    }
});

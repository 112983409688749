import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('accountPcSellerToggle',  {
    bindings: {
      openRtbSeller: '<',
      serverSideSeller: '<',
      serverSideSellerToggleClass: '@'
    },
    template: require("../templates/accountPcSellerToggle.html"),
    controllerAs: '$accountPcSellerToggleCtrl',
    controller: function() {

      var self = this;

      this.attributeEnabledMappings = componentHelper.attributeEnabledMappings();

      this.setOpenRtbSeller = function(val) {
        self.openRtbSeller = val;
        $('.pc-fees-required').toggleClass('hidden', !val);
      };

      this.setServerSideSeller = function(val) {
        self.serverSideSeller = val;
        $('.' + self.serverSideSellerToggleClass).toggleClass('hidden', !val);
      };

      this.$onInit = function() {
        self.setServerSideSeller(self.serverSideSeller);
      };
    }
});

angular.module('vastdesk').
  component('reportingKeys',  {
    bindings: {
      formName: '@',
      formId: '@',
      tooltip: '@',
      keyIds: '<',
      keyOptions: '<'
    },
    template: require("../templates/reportingKeys.html"),
    controllerAs: '$reportingKeysCtrl',
    controller: ['$rootScope', function($rootScope) {

      var self = this;

      this.broadcastKeyIdsChange = function() {
        $rootScope.$broadcast('keyIdsChange', {
          keyIds: self.keyIds
        });
      };

      this.$onInit = function() {
        setTimeout(function() {
          self.broadcastKeyIdsChange();
        }, 0);
      };

    }]
});

import Picker from 'vanilla-picker';

angular.module('vastdesk')
.component('colorPicker', {
  bindings: {
    formName: '@',
    fieldName: '@',
    colorValue: '@'
  },
  template: require("../templates/colorPicker.html"),
  controllerAs: '$colorPickerCtrl',
  controller: ['$scope', '$element', function($scope, $element) {
    var self = this;

    const parentBasic = $element.find('.input-group-prepend');
    const popupBasic = new Picker({
      parent: parentBasic.get(0),
      alpha: false
    });

    popupBasic.onDone = function(color) {
      self.colorValue = color.hex.substring(0, 7);
      $scope.$apply();
    };

    popupBasic.onOpen = function(color) {
      popupBasic.setColor(self.colorValue);
    };
  }]
});

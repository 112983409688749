import platformUtilities from '../../modules/platform_utilities.js';
import WaterfallHelper from '../../modules/waterfall_helper.js';
import SwalHelper from '../../modules/swal_helper.js';
import WaterfallConverter from '../../modules/waterfall_converter.js';

angular.module('vastdesk').controller('SupplyTagServerSideDemandTagTableController', ['$scope', '$timeout', '$rootScope', function ($scope, $timeout, $rootScope) {

  $scope.selectedDemandTagBulkActionIds = [];
  $scope.demandTagBulkActionDisabledConditions = {
    houseAdsSelected: false,
    nonHouseAdsSelected: false,
    slotPositioningDisabled: true
  };
  var serverSideTable = $('#tag_results table');
  var serverSideTableId = $(serverSideTable).attr('id');
  var serverSideDataTable;
  var modalTable = $('#tag_modal').find('table');
  var bodySelector = $('body');
  var filteredRecordIds = [];
  var reportData = [];
  var demandPartnerIdsInWaterfall = [];
  var serverSideTableLoaded = false;
  var houseAdDemandTagIds = [];
  var waterfallHelper = new WaterfallHelper();
  var waterfallConverter = new WaterfallConverter();

  var setDemandTagPrioritiesParams = function() {
    return _.chain($scope.setDemandTagPriorities)
            .map(function(t) {
              return _.pick(t, 'demand_tag_id', 'tier', 'priority', 'locked', 'ratio', 'slot_order', 'slot_number');
            })
            .value();
  };

  serverSideTable.on('init.dt', function() {
    serverSideDataTable = $(this).DataTable();
    waterfallHelper.addDateRangeSelect({forecast: $scope.forecast});

    if (Array.isArray(reportData) && reportData.length > 0) {
      setTimeout(function() {
        waterfallHelper.drawServerSideTable(serverSideDataTable, false);
      }, 0);
    }

    if ($scope.isNewRecord) {
      if ($scope.openMarketEnabled) {
        $scope.setOpenMarketEnabled(true);

        setTimeout(function() {
          addOpenMarketDemand();
        }, 0);
      }
    }
  });

  serverSideTable.on('preXhr.dt', function (e, settings, data) {
    waterfallHelper.abortPendingAjaxRequests(serverSideTable, serverSideDataTable);
    waterfallHelper.attachTableData(data, $scope.demandTagTableData());

    data.set_demand_tag_priorities = setDemandTagPrioritiesParams();
    data.date_range = $('#date_range').val();
    data.report_data = waterfallHelper.getDemandTagReportDataForDraw(serverSideTable, serverSideDataTable, reportData);
    data.custom_data = data.custom_data || {};
    data.custom_data.selected_ids = $scope.selectedDemandTagBulkActionIds;
    data.custom_data.supply_tag = $scope.currentSupplyTagFields();
    data.custom_data.all_slots = $scope.allSlots();
    data.custom_data.current_slot_order = $scope.currentSlotOrder();
    data.custom_data.current_slot_number = $scope.currentSlotNumber();
    data.custom_data.valid_slot_orders = $scope.validSlotOrders();
    data.custom_data.valid_slot_numbers = $scope.validSlotNumbers();
    setTierBroadfallData(data);

    $('.dtp-r').blur();
  });

  serverSideTable.on('xhr.dt', function(e, settings, json, xhr) {
    if (json && json.custom_data && json.custom_data) {
      filteredRecordIds = json.custom_data.filtered_record_ids;
      serverSideTableLoaded = true;
      demandPartnerIdsInWaterfall = json.custom_data.demand_partner_ids;
      houseAdDemandTagIds = json.custom_data.house_ad_demand_tag_ids;
      setParentScopeVar('hasHbDemand', json.custom_data.has_hb_demand, true);
      waterfallHelper.filterPartners(serverSideTable, serverSideTableLoaded, demandPartnerIdsInWaterfall);
    }
    if (json && json.data) {
      attachReportData(json.data);
    }
  });

  serverSideTable.on('init.filter.ss.dt', function(e, filterId) {
    if (filterId === 'dfilter_partner') {
      waterfallHelper.filterPartners(serverSideTable, serverSideTableLoaded, demandPartnerIdsInWaterfall);
    }
  });

  serverSideTable.on('draw.dt', function() {
    waterfallHelper.disableAllSlotsFields(this, $scope.allSlots());
    waterfallHelper.setRowSpans(this);
  });

  var reportRowFor = function(tableRow) {
    var matchConditions = waterfallHelper.reportRowMatchConditions({
        demand_tag_id: tableRow.DT_RowAttr['data-demand-tag-id']
      },
      $scope.slotPositioningOrder(),
      $scope.slotPositioningNumber(),
      tableRow
    );

    return _.findWhere(reportData, matchConditions) || {};
  };

  var attachReportData = function(rows) {
    _.each(rows, function(r) {
      var reportRow = reportRowFor(r);
      waterfallHelper.addReportDataToRow(r, reportRow, {
        currencyPlaceholder: $scope.demandTagCurrencyPlaceholder
      });
    });
  };

  $('#tag_modal').on('show.bs.modal', function () {
    $('#tag_modal .modal-title').text('Add Demand' + $scope.modalTitleSuffix());
  });

  modalTable.on('preXhr.dt', function (e, settings, data) {
    data.custom_data = data.custom_data || {};
    data.custom_data.supply_tag = $scope.currentSupplyTagFields();
    data.custom_data.selected_ids = [].concat(currentDemandTagIds()).concat($scope.selectedDemandTagIds);
  });

  modalTable.on('draw.dt', function () {
    waterfallHelper.setModalCheckboxes(modalTable, currentDemandTagIds());
  });

  var setTierBroadfallData = function(data) {
    _.each(waterfallHelper.allTiers, function(tier) {
      data['tier_' + tier + '_broadfall'] = $scope.broadfallFor(tier);
    });
  };

  bodySelector.on("change", "[name*='[rate]'], [name*='[rate_currency]']", function () {
    waterfallHelper.drawServerSideTable(serverSideDataTable, false);
  });

  bodySelector.on('mousedown', 'td select.dtp-t', function(e) {
    if ( $(this).attr('readonly') === 'readonly' ) {
      e.preventDefault();
      this.blur();
      window.focus();
    }
  });

  bodySelector.on('change', 'td .dtp-t', function () {
    handleTierChange(this);
  });

  bodySelector.on('change', 'td .dtp-p', function () {
    handlePriorityChange(this);
  });

  bodySelector.on('change', 'td .dtp-r', function () {
    handleRatioChange(this);
  });

  bodySelector.on('change', 'td input[id*="dtp_lock_"]', function() {
    handleLockedChange(this);
  });

  bodySelector.on("click", "form.tag-form a.remove-tag", function () {
    var row = waterfallHelper.rowForTableElement(this);
    var demandTagId = dataAttributeForRow(row, 'demandTagId');
    var allSlots = parseInt(demandTagId) === $scope.openMarketDemandTagId;

    removeSetDemandTagPriorities([demandTagId], true, false, {
      allSlots: allSlots,
      slotOrder: dataAttributeForRow(row, 'so'),
      slotNumber: dataAttributeForRow(row, 'sn')
    });

    return false;
  });

  bodySelector.on("click", "#associated_tag_section .remove-all", function(e){
    removeAllFilteredSetDemandTagPriorities();
    return false;
  });

  bodySelector.on('click', '.broadfall-tier-icon', function() {
    if($scope.readOnly) { return; }
    var row = waterfallHelper.rowForTableElement(this);
    var tierValue = waterfallHelper.tierValueFor(row);
    var broadfall = $(this).hasClass('checked');

    $scope.setBroadfallForTier(tierValue, !broadfall);
    waterfallHelper.drawServerSideTable(serverSideDataTable, false);
    $('.tooltip').tooltip('hide');
  });

  var setParentScopeVar = function(variable, val, applyScope) {
    $rootScope.$broadcast('updateSupplyTagFormControllerVar', {
      variable: variable,
      val: val,
      applyScope: applyScope
    });
  };

  var removeSetDemandTagPriorities = function(demandTagIds, applyScope, excludeOm, slotOptions) {
    var startingLength = $scope.setDemandTagPriorities.length;

    if (_.contains(demandTagIds, $scope.openMarketDemandTagId)) {
      if (excludeOm) {
        demandTagIds = _.without(demandTagIds, $scope.openMarketDemandTagId);
      }
      else {
        setParentScopeVar('openMarketEnabled', false, applyScope);
      }
    }

    $scope.setDemandTagPriorities = _.reject($scope.setDemandTagPriorities, function(sdtp) {
      return _.contains(demandTagIds, parseInt(sdtp.demand_tag_id)) && (slotOptions.allSlots || waterfallHelper.inSlot(sdtp, slotOptions.slotOrder, slotOptions.slotNumber));
    });

    var endingLength = $scope.setDemandTagPriorities.length;
    var tagsRemoved = startingLength - endingLength;

    if (tagsRemoved < 1) {
      return;
    }

    if (applyScope) {
      $scope.$apply();
    }

    waterfallHelper.drawServerSideTableAfterRemoval(serverSideDataTable, tagsRemoved);
  };

  var isOpenMarketDemandTag = function(sdtp) {
    return parseInt(sdtp.demand_tag_id) === parseInt($scope.openMarketDemandTagId);
  };

  var removeAllFilteredSetDemandTagPriorities = function() {
    $scope.setDemandTagPriorities = _.filter($scope.setDemandTagPriorities, function(sdtp) {
      return isOpenMarketDemandTag(sdtp) || !$scope.inCurrentSlot(sdtp) || !_.contains(filteredRecordIds, parseInt(sdtp.demand_tag_id));
    });
    $scope.$apply();
    waterfallHelper.drawServerSideTable(serverSideDataTable, true);
  };

  var dataAttributeForRow = function(row, attribute) {
    return $(row).data(attribute);
  };

  var priorityWithDemandTagIdAndSlot = function(priorities, id, slotOrder, slotNumber) {
    return _.find(priorities, function(sdtp) {
      return parseInt(sdtp.demand_tag_id) === parseInt(id) && waterfallHelper.inSlot(sdtp, slotOrder, slotNumber);
    });
  };

  var setDemandTagPriorityFor = function(row) {
    var id = dataAttributeForRow(row, 'demandTagId');
    var slotOrder = dataAttributeForRow(row, 'so');
    var slotNumber = dataAttributeForRow(row, 'sn');
    return priorityWithDemandTagIdAndSlot($scope.setDemandTagPriorities, id, slotOrder, slotNumber);
  };

  var updateSetDemandTagPriority = function(row, attributes, draw) {
    var sdtp = setDemandTagPriorityFor(row);

    if (sdtp) {
      sdtp = _.extend(sdtp, attributes);
      $scope.$apply();

      if (draw) {
        waterfallHelper.drawServerSideTable(serverSideDataTable, false);
      }
    }
  };

  var enabledBroadfallForTier = function(tierElement) {
    if ($scope.isDc) {
      return;
    }

    var tierValue = $(tierElement).val();

    if ( $scope.vastOnly() ) {
      $scope.setBroadfallForTier(tierValue, true);
    }
  };

  var updateSetDemandTagPriorityFromElement = function(element, attribute, draw) {
    var row = waterfallHelper.rowForTableElement(element);
    var attributes = {};
    attributes[attribute] = waterfallHelper.valueForElement(element, attribute);
    updateSetDemandTagPriority(row, attributes, draw);
  };

  var handleTierChange = function(element) {
    enabledBroadfallForTier(element);
    updateSetDemandTagPriorityFromElement(element, 'tier', true);
  };

  var handlePriorityChange = function(element) {
    updateSetDemandTagPriorityFromElement(element, 'priority', false);
  };

  var handleRatioChange = function(element) {
    updateSetDemandTagPriorityFromElement(element, 'ratio', true);
  };

  var handleLockedChange = function(element) {
    updateSetDemandTagPriorityFromElement(element, 'locked', true);
  };

  var handleBulkChange = function(demandTagIds, attribute, value) {
    _.each($scope.setDemandTagPriorities, function(sdtp) {
      if (_.contains(demandTagIds, parseInt(sdtp.demand_tag_id)) && $scope.inCurrentSlot(sdtp)) {
        sdtp[attribute] = value;
      }
    });

    $timeout(function() {
      $scope.$apply();
    });

    waterfallHelper.drawServerSideTable(serverSideDataTable, false);
  };

  var tagHasAnyOpenMarketDemand = function() {
    return !!_.find($scope.setDemandTagPriorities, function(sdtp) {
      return isOpenMarketDemandTag(sdtp);
    });
  };

  var addOpenMarketDemand = function() {
    if ( !$scope.openMarketDemandTagId || $scope.openMarketDemandTagId === -1 || !$scope.openMarketEnabled ) {
      return;
    }

    if (!tagHasAnyOpenMarketDemand()) {
      setParentScopeVar('autoAddExternalBidders', true);
    }

    _.each($scope.slotLists(), function(list) {
      if (priorityWithDemandTagIdAndSlot($scope.setDemandTagPriorities, $scope.openMarketDemandTagId, list[0], list[1])) {
        return;
      }

      $scope.setDemandTagPriorities.push({
        demand_tag_id: $scope.openMarketDemandTagId,
        tier: $scope.defaultOpenMarketTier || 0,
        priority: 1,
        locked: false,
        slot_order: list[0],
        slot_number: list[1]
      });
    });

    waterfallHelper.drawServerSideTable(serverSideDataTable, false);
  };

  var removeOpenMarketDemand = function() {
    if (!tagHasAnyOpenMarketDemand()) {
      return;
    }
    removeSetDemandTagPriorities([$scope.openMarketDemandTagId], false, false, {allSlots: true});
  };

  var currentDemandTagIds = function() {
    var setDemandTagPrioritiesInCurrentSlot = _.filter($scope.setDemandTagPriorities, function(sdtp) {
      return $scope.inCurrentSlot(sdtp);
    });

    return waterfallHelper.extractIdsFromSetList(setDemandTagPrioritiesInCurrentSlot, 'demand_tag_id');
  };

  var addSetDemandTagPrioritiesSuccess = function(validNewDemandTagPriorities) {
    $scope.setDemandTagPriorities = $scope.setDemandTagPriorities.concat(validNewDemandTagPriorities);
    $scope.$apply();
    waterfallHelper.drawServerSideTable(serverSideDataTable, false);
  };

  var addSetDemandTagPriorities = function(resp, options = {}) {
    if (!resp.rows) {
      return;
    }

    var currentIds = currentDemandTagIds();

    var validNewDemandTagPriorities = _.reject(resp.rows, function(dtp) {
      return _.contains(currentIds, parseInt(dtp.demand_tag_id)) && $scope.inCurrentSlot(dtp);
    });

    var copiedOpenMarketRow = priorityWithDemandTagIdAndSlot(validNewDemandTagPriorities, $scope.openMarketDemandTagId, $scope.currentSlotOrder(), $scope.currentSlotNumber());

    if (copiedOpenMarketRow) {
      validNewDemandTagPriorities = _.without(validNewDemandTagPriorities, copiedOpenMarketRow);
    }

    addSetDemandTagPrioritiesSuccess(validNewDemandTagPriorities);

    if (copiedOpenMarketRow) {
      SwalHelper.confirmWarning(
        {
          title: 'Do you want to enable PC - Open Market Demand?',
          cancelButtonText: 'No',
          icon: 'question'
        },
        function() {
          setParentScopeVar('openMarketEnabled', true, true);

          $.get('/supply/open_market_external_bidders', {supply_tag_id: options.copyingSupplyTagId}, function (json) {
            setParentScopeVar('setOpenMarketBidders', json, true);
          });

          addOpenMarketDemand();
        }
      );
    }
  };

  var setBlackBoxOptiTooltipVisibility = function() {
    var tooltip = $('.tier-tooltip');

    if ($scope.blackBoxOpti()) {
      tooltip.removeClass('hidden');
    } else {
      tooltip.addClass('hidden');
    }
  };

  $scope.setDemandTagPriorityUniqIdFor = function(setDemandTagPriority) {
    return waterfallHelper.setDemandTagPriorityUniqIdFor(setDemandTagPriority, 'demand_tag_id');
  };

  $scope.$on('clearSelectedWaterfallBulkActionIds', function(e, args) {
    $scope.selectedDemandTagBulkActionIds = [];
  });

  $scope.$on('updatingReportData', function(e, args) {
    if (args.table === serverSideTableId) {
      platformUtilities.triggerDataTablesProcessing(serverSideTable, true);
    }
  });

  $scope.$on('updatedReportData', function(e, args) {
    if (args.table === serverSideTableId) {
      reportData = args.resp.demand_tag_report;
      $scope.demandTagCurrencyPlaceholder = args.resp.currency_placeholder;

      waterfallHelper.drawServerSideTable(serverSideDataTable, false);
    }
  });

  $scope.$on('demandTagModalSave', function(e, args) {
    var path = $('#tag_results').data('ssTableRowsPath');
    var params = {
      "object_ids": args.selectedIds,
      "slot_order": $scope.currentSlotOrder(),
      "slot_number": $scope.currentSlotNumber()
    };

    $.post(path, params, function(resp) {
      addSetDemandTagPriorities(resp);
    });
  });

  $scope.$on('supplyTagOptimizationChange', function (e, args) {
    waterfallHelper.drawServerSideTable(serverSideDataTable, false);
    setBlackBoxOptiTooltipVisibility();
  });

  $rootScope.$on('openMarketEnabledChange', function (e, args) {
    if (args.openMarketEnabled === true) {
      addOpenMarketDemand();
    }
    else if (args.openMarketEnabled === false) {
      removeOpenMarketDemand();
    }
  });

  $scope.$on('numAdSlotsChange', function(e, args) {
    addSpecialObjects(args);
  });

  $scope.$on('addNewEndpoint', function(e, args) {
    addSetDemandTagPriorities(args);
  });

  $scope.$on('drawServerSideTable', function(e, args) {
    if (args.tableId === serverSideTableId) {
      waterfallHelper.drawServerSideTable(serverSideDataTable, false);
    }
  });

  $scope.$on('wfSlotFieldChanged', function(e, args) {
    addSpecialObjects(args);
    $rootScope.$broadcast('updateReportData', {table: serverSideTableId});
    waterfallHelper.drawServerSideTable(serverSideDataTable, false);
  });

  $scope.$on('slotPositioningChange', function(e, args) {
    waterfallConverter.convert($scope, 'setDemandTagPriorities', args.slotPositioningWas, args.slotPositioning, args.maxNumAdSlots);
    addSpecialObjects(args);
    waterfallHelper.drawServerSideTable(serverSideDataTable, false);
    setSlotOrderBulkActionDisabled(args.slotPositioning);
  });

  $scope.$on('jsVpaidEnabledChange', function(e, args) {
    if(args.jsVpaidEnabled === false) {
      _.each(waterfallHelper.allTiers, function(tier) {
        $scope.setBroadfallForTier(tier, true);
      });
    }
  });

  var addSpecialObjects = function(args) {
    if (args.openMarketEnabled === true) {
      addOpenMarketDemand();
    }
  };

  var bulkRemoveDemand = function(ids) {
    removeSetDemandTagPriorities(ids, false, true, {
      slotOrder: $scope.currentSlotOrder(),
      slotNumber: $scope.currentSlotNumber()
    });
  };

  $scope.$on('bulkEditBroadcast', function(e, args) {
    if (args.tableId === serverSideTableId) {
      if (!args.selectedIds || !Array.isArray(args.selectedIds)) {
        return;
      }

      var demandTagIds = _.map(args.selectedIds, function(id) {
        return parseInt(id);
      });

      if (args.id === 'remove') {
        bulkRemoveDemand(demandTagIds);
      }
      else if (args.id === 'lock') {
        handleBulkChange(demandTagIds, 'locked', true);
      }
      else if (args.id === 'unlock') {
        handleBulkChange(demandTagIds, 'locked', false);
      }
      else if (args.id === 'priority') {
        handleBulkChange(demandTagIds, 'priority', parseInt(args.value));
      }
      else if (args.id === 'ratio') {
        handleBulkChange(demandTagIds, 'ratio', waterfallHelper.parseIntOrNull(args.value));
      }
      else if (args.id === 'tier') {
        handleBulkChange(demandTagIds, 'tier', parseInt(args.value));
      }
      else if (args.id === 'slot') {
        if (_.contains([$scope.currentSlotOrder(), $scope.currentSlotOrder()], args.value)) {
          return;
        }

        var slotAttribute = ($scope.slotPositioning === 'number') ? 'slot_number' : 'slot_order';

        var demandTagIdsAlreadyInNewSlot = _.chain($scope.setDemandTagPriorities).filter(function(sdtp){
          return _.contains(demandTagIds, parseInt(sdtp.demand_tag_id)) && sdtp[slotAttribute] == args.value;
        }).map(function(sdtp){
          return sdtp.demand_tag_id;
        }).value();

        demandTagIds = _.difference(demandTagIds, demandTagIdsAlreadyInNewSlot);

        handleBulkChange(demandTagIds, slotAttribute, args.value);
        bulkRemoveDemand(demandTagIdsAlreadyInNewSlot);
      }
    }
  });

  bodySelector.on('submit', '#copy_form', function(){
    var copyForm = $(this);
    var wfManagement = copyForm.find('#copy_waterfall_management').val();

    if (wfManagement !== 'demand_tag') {
      return;
    }

    var path = copyForm.attr('action');
    var supplyTagId = copyForm.find('#copy_supply_tag_id').val();
    var copyFromSlotOrder = copyForm.find('input[name="copy_from_slot_order"]:checked').val();
    var copyFromSlotNumber = copyForm.find('input[name="copy_from_slot_number"]:checked').val();

    var params = _.extend({}, $scope.currentSupplyTagFields(), {
      'supply_tag_id': supplyTagId,
      'demand_tag_ids': currentDemandTagIds(),
      'current_supply_tag_id': $scope.supplyTagId,
      "supply_partner_id": $scope.supplyPartnerId,
      "slot_order": $scope.currentSlotOrder(),
      "slot_number": $scope.currentSlotNumber(),
      "copy_from_slot_order": copyFromSlotOrder,
      "copy_from_slot_number": copyFromSlotNumber
    });

    $.post(path, params, function(resp) {
      addSetDemandTagPriorities(resp, {
        copyingSupplyTagId: supplyTagId
      });
    });

    $('#copy_supply_tag_id').val('');
    $('#copy_supply_tag_name').val('');
    $('#copy_waterfall_management').val('');
    $('#copy_save').attr('disabled', true);
    $('#copy_modal').modal('hide');
    return false;
  });

  var setSlotOrderBulkActionDisabled = function(slotPositioning) {
    $scope.demandTagBulkActionDisabledConditions.slotPositioningDisabled = !slotPositioning;
  };

  bodySelector.on('submit', '.tag-form', function() {
    waterfallConverter.rejectInvalidSlotOrders($scope, 'setDemandTagPriorities', $scope.slotPositioningOrder(), $scope.validSlotOrders());
    waterfallConverter.rejectInvalidSlotNumbers($scope, 'setDemandTagPriorities', $scope.slotPositioningNumber(), $scope.validSlotNumbers());
    $scope.$apply();
  });

  $scope.$watchCollection('selectedDemandTagBulkActionIds', function (new_val, old_val) {
    if (!Array.isArray(new_val) || !Array.isArray(houseAdDemandTagIds)) {
      return;
    }
    $scope.demandTagBulkActionDisabledConditions.houseAdsSelected = _.intersection(new_val, houseAdDemandTagIds).length > 0;
    $scope.demandTagBulkActionDisabledConditions.nonHouseAdsSelected = _.difference(new_val, houseAdDemandTagIds).length > 0;
    setSlotOrderBulkActionDisabled($scope.slotPositioning);
  });

  var intervalTime = 0;
  setInterval(function () {
    intervalTime += 1;
    if (intervalTime >= 60 && document.hasFocus() && $("#tabTags").is(".active") && !$('#omeb_modal').is(':visible')) {
      $rootScope.$broadcast('updateReportData', {table: serverSideTableId});
      intervalTime = 0;
    }
  }, 1000);

}]);

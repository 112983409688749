angular.module('vastdesk').
  component('shortLong',  {
    bindings: {
      short: '@',
      long: '@'
    },
    template: "<div class='d-none d-xl-inline'>{{$shortLongCtrl.long}}</div><div class='d-xl-none'>{{$shortLongCtrl.short}}</div>",
    controllerAs: '$shortLongCtrl',
    controller: function() {}
});

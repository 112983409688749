angular.module('vastdesk').
  component('dealIdFields',  {
    bindings: {
      formId: '@',
      formName: '@',
      newRecord: '<',
      generateExternalDealId: '<',
      externalDealId: '@'
    },
    template: require("../templates/dealIdFields.html"),
    controllerAs: '$dealIdFieldsCtrl',
    controller: function() {

      var self = this;

      this.dealIdButtonOptions = [
        {value: true, label: 'Generate'},
        {value: false, label: 'Input'}
      ];

      this.setGenerateExternalDealId = function(val) {
        self.generateExternalDealId = val;

        if (val) {
          self.externalDealId = '';
        }
      };

    }
});

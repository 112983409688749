import WaterfallHelper from '../../modules/waterfall_helper.js';
import MagniteModalHelper from '../../modules/magnite_modal_helper.js';

angular.module('vastdesk')
.component('magniteStreamingModal', {
  bindings: {
    objectId: '@',
    summaryPath: '@',
    reportingView: '@',
    timezone: '@',
    selectedMetric: '@',
    chartPath: '@',
    leftAxisSeries: '@',
    metrics: '<',
    magniteStreamingDealsModalTablePath: '@',
    magniteStreamingDealsModalTableOrder: '@',
    magniteStreamingDealsModalTableHeader: '@',
    currency: '@',
    currencySymbol: '@',
    currencyPlaceholder: '@', 
    dateRanges: '<'
  },
  template: require("../templates/magniteStreamingModal.html"),
  controllerAs: '$magniteStreamingModalCtrl',
  controller: ['$scope', '$element', '$rootScope', function($scope, $element, $rootScope) {

    const self = this;
    const waterfallHelper = new WaterfallHelper();
    const magniteModalHelper = new MagniteModalHelper();
    const bodyElement = $('body');
    const reportModal = $('#magnite_streaming_modal');
    const magniteStreamingDealsModalTable = $('#supply_tags_magnite_streaming_deals_modal_table');
    let magniteStreamingDealsModalDatatable;

    this.activeTab = '';
    this.selectedMagniteStreamingDealId = '';
    this.selectedMagniteStreamingDealName = '';
    this.magniteStreamingDeals = [];
    this.selectedDemandTag = '';

    waterfallHelper.dateRangeize($element.find('.date-filter select'), {
      changeCb: () => {
        updateReports();
      }
    });

    this.showMagniteStreamingDealFilters = () => {
      return _.contains(['#tabModalBidDensity', '#tabModalSummary'], self.activeTab);
    };

    this.handleMagniteStreamingDealChange = (selectedDeal) => {
      if(selectedDeal === ''){
        this.selectedMagniteStreamingDealId = '';
        this.selectedMagniteStreamingDealName = '';
      } else {
        const selectedDealArray = JSON.parse(selectedDeal);
        this.selectedMagniteStreamingDealId = selectedDealArray[0];
        this.selectedMagniteStreamingDealName = selectedDealArray[1];
      }

      updateReportsWithFilters();
    };

    const updateReportsWithFilters = () => {
      createBidDensityChart();
      updateQuickstatsSummaryReport();
    };

    magniteStreamingDealsModalTable.on('init.dt', function() {
      magniteStreamingDealsModalDatatable = $(this).DataTable();
    });

    magniteStreamingDealsModalTable.on('preXhr.dt', function(e, settings, data) {
      waterfallHelper.abortPendingAjaxRequests(magniteStreamingDealsModalTable, magniteStreamingDealsModalDatatable);

      data.date_range = modalDateRange().val();
      data.demand_tag_id = self.selectedDemandTag;
      data.supply_tag_id = self.objectId;
      data.slot_number = self.slotNumber;
      data.slot_order = self.slotOrder;
    });

    magniteStreamingDealsModalTable.on('xhr.dt', function(e, settings, json, xhr) {
      const resetFilter = magniteModalHelper.filterIds(json, self, $scope, 'foreign_deal_ids', 'magniteStreamingDeals', 'selectedMagniteStreamingDeal', '#magnite_streaming_deals_select');

      if (resetFilter) {
        self.handleMagniteStreamingDealChange('');
        $scope.$apply();
      }
    });

    const updateReports = () => {
      if (magniteStreamingDealsModalDatatable.draw) {
        magniteStreamingDealsModalDatatable.draw();
      }
    };

    const updateQuickstatsSummaryReport = () => {
      $rootScope.$broadcast('updateReportData', {
        table: 'supply_tags_magnite_streaming_deals_modal_table',
        filterParams: {
          supply_tag_id: self.objectId,
          demand_tag_id: self.selectedDemandTag,
          foreign_deal_id: self.selectedMagniteStreamingDealId,
          slot_number: self.slotNumber,
          slot_order: self.slotOrder
        }
      });
    };


    const createBidDensityChart = () => {
      $element.find('#bid_density_graph').createBidDensityChart({
        supply_tag_id: self.objectId,
        demand_tag_id: self.selectedDemandTag,
        foreign_deal_id: self.selectedMagniteStreamingDealId,
        slot_number: self.slotNumber,
        slot_order: self.slotOrder,
        date_range: 'Today'
      }, {
        chart_title: 'Bid Density for ' + (self.selectedMagniteStreamingDealName === '' ? "All Deals" : self.selectedMagniteStreamingDealName)
      });
    };

    $element.on('shown.bs.tab', 'a[data-toggle="tab"][href="#tabModalBidDensity"]', () => {
      const highchart = $element.find('#bid_density_graph').highcharts();
      if(highchart) {
        highchart.reflow();
      }
    });

    this.slotTitle = () => {
      return waterfallHelper.slotTitle(this) || '';
    };

    this.modalTitle = () => {
      return `${this.slotTitle()}${this.objectName} (${this.selectedDemandTag})`;
    };

    const parentDateRange = () => {
      return $('#supply_tag_demand_tags_table, #supply_tag_campaigns_table').parents('.dataTables_wrapper').find('#date_range');
    };

    const modalDateRange = () => {
      return $element.find('#date_range');
    };

    reportModal.on('hide.bs.modal', (e) => {
      waterfallHelper.syncDateRange(modalDateRange(), parentDateRange(), {triggerChange: true});
    });


    $element.on('shown.bs.tab', '#magniteStreamingModalTabs a[data-toggle="tab"]', function (e) {
      setActiveTab($(this));
      $scope.$apply();
    });

    const setActiveTab = (activeAnchor) => {
      this.activeTab = activeAnchor.attr('href');
    };

    const setSearchFilterPlaceholder = () => {
      const filterElement = document.querySelector('#supply_tags_magnite_streaming_deals_modal_table_filter > label > input');
      if(filterElement){
        filterElement.placeholder = "Search Deal ID, Name";
      }
    };

    const attachModalEvent = function() {
      bodyElement.on('click', '#supply_tag_demand_tags_table .modal-expand-icon.magnite-streaming-hb-tag, #supply_tag_campaigns_table .modal-expand-icon.magnite-streaming-hb-tag', function(e) {
        const row = $(this).parents('tr');

        self.objectName = $(this).attr('title');
        self.selectedDemandTag = row.data('demandTagId');
        self.slotNumber = row.data('sn');
        self.slotOrder = row.data('so');

        waterfallHelper.clearChosenSelect(self, 'selectedMagniteStreamingDealId', '#magnite_streaming_deals_select');

        setActiveTab($('#magniteStreamingModalTabs li a.active'));
        setSearchFilterPlaceholder();

        $scope.$apply();

        waterfallHelper.syncDateRange(parentDateRange(), modalDateRange());

        setTimeout(function() {
          updateReports();
          createBidDensityChart();
          updateQuickstatsSummaryReport();
          reportModal.modal();
        }, 0);

        return false;
      });
    };

    this.$onInit = function() {
      attachModalEvent();
    };

  }]
});
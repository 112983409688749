angular.module('vastdesk').
  component('responseTimesModal',  {
    bindings: {},
    template: require("../templates/responseTimesModal.html"),
    controllerAs: '$responseTimesModalCtrl',
    controller: ['$scope', function($scope) {

      var self = this;
      var bodyElement = $('body');

      bodyElement.on('click', '.chart-popover', function(e) {
        if ($(this).hasClass('bid-density-popover')) {
          return;
        }

        self.modalTitle = $(this).data('modalTitle') || 'Response Distribution';
        self.path = $(this).data('path');

        $scope.$apply();

        setTimeout(function() {
          $('#response_times_modal_chart').createResponseTimesChart({target: '#response_times_modal_chart'});
          $('#response_times_modal').modal();
        }, 0);
      });

      bodyElement.on('hide.bs.modal', '#response_times_modal', function(e) {
        var highchart = $('#response_times_modal_chart').highcharts();

        if (highchart) {
          highchart.destroy();
        }
      });

    }]
});

angular.module('vastdesk').
  component('accountPlatformType',  {
    bindings: {
      platformType: '@',
      platformTypeOptions: '<',
      dvplusId: '@'
    },
    template: require("../templates/accountPlatformType.html"),
    controllerAs: '$accountPlatformTypeCtrl',
    controller: ['$rootScope', function($rootScope) {

      this.setPlatformType = (platformType) => {
        this.platformType = platformType;

        $rootScope.$broadcast('accountPlatformTypeChange', {
          platformType: platformType
        });
      };

    }]
});

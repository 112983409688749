import platformUtilities from '../../modules/platform_utilities.js';
import WaterfallModalHelper from '../../modules/waterfall_modal_helper.js';
import DecimalEnforcer from '../../modules/decimalEnforcer.js';
import TomSelect from "tom-select";

angular.module('vastdesk').
  controller('DemandTagFormController', ['$scope', '$timeout', '$rootScope', function($scope, $timeout, $rootScope) {

  var bodySelector = $('body');
  var waterfallModalHelper = new WaterfallModalHelper();
  var decimalEnforcer = new DecimalEnforcer();
  var serverSideTable = $('#tag_results table');
  var modal = $('#tag_modal');
  var modalTable = $('#tag_modal table');
  var modalTableLoaded = $.Deferred();
  $scope.campaignId = 0 ;
  $scope.hasCampaignRate = false;
  $scope.demandPartnerId = 0;

  $scope.updateDemandPartner = function() {
    var selectedDemandPartner = $("#demand_tag_demand_partner_id").find("option:selected");
    setAccountManagers(selectedDemandPartner);
    updateScopeDefaultTier(selectedDemandPartner);
    updateDefaultTiers(selectedDemandPartner);
    unsetCampaign();

    if ($scope.demandPartnerId) {
      $scope.filterCampaignOptions($scope.demandPartnerId);
    }
  };

  $scope.setCostModelType = function(val) {
    if ($scope.usingCampaignRate()) {
      return;
    }
    $scope.costModelType = val;
  };

  $scope.handleOpenrtbGrossFloorRateChange = function() {
    $scope.$broadcast('openrtbGrossFloorRateChange');
  };

  $scope.filterCampaignOptions = function(demandPartner) {
    $("#demand_tag_campaign_id").find("option").each(function() {
        const _demandPartner = $(this).data("campaignDemandPartner");
        const isCampaignActive = $(this).data("campaignActive");

        if ($(this).val() != "" && parseInt(demandPartner) != parseInt(_demandPartner)) {
          $(this).attr('disabled', true);
        } else {
          $(this).attr('disabled', false);
        }

        if (!isCampaignActive) {
          $(this).addClass('italic');
        }
      }
    );

    $("#demand_tag_campaign_id").trigger("chosen:updated");
  };

  $scope.openrtbNetFloorRate = function() {
    var floorRate = $scope.openrtbGrossFloorRate  * (1 - ($scope.openrtbRevShare/100));
    return isNaN(floorRate) ? 0 : floorRate;
  };

  $scope.setOpenrtbRevShare = function(val) {
    $scope.openrtbRevShare = decimalEnforcer.enforce(val, 'round', 0);
  };

  $scope.changePCFee = function() {
    if ($scope.isPrivateDeal) {
      $scope.setOpenrtbRevShare($scope.pcPrivateDealRevShare);
    } else {
      $scope.setOpenrtbRevShare($scope.pcOriginatedDealRevShare);
    }
  };

  $scope.creativeCompatibleDemandClass = function() {
    return _.contains(['4', '8'], $scope.demandClass);
  };

  $scope.customVastExtensionsCompatibleDemandClass = function() {
    return _.contains(['1', '3', '4', '8', '9', '11'], $scope.demandClass);
  };

  $scope.hideRateField = function() {
    return _.contains(['3', '8', '9'], $scope.demandClass);
  };

  $scope.houseAdDemandTag = function() {
    return $scope.demandClass === '8';
  };

  var initGenerateDealId = function() {
    if (!$scope.dealId || $scope.dealId === '') {
      $scope.generateDealId = true;
    }
  };

  $scope.setOpenrtbType = function(val, disabled) {
    if (disabled === 'disabled') {
      return;
    }
    $scope.openrtbType = val;

    if (val === 'deal_id') {
      initGenerateDealId();
      $scope.changePCFee();
    } else {
      $scope.setOpenrtbRevShare(0);
    }
  };

  $scope.tileLineItem = function() {
    return $scope.demandClass === '11' && demandTagFormat() === 'tile';
  };

  $scope.showAdvertiserDomain = function() {
    return _.contains(['1', '3'], $scope.demandClass) ||
      ($scope.creativeCompatibleDemandClass() && $scope.demandPartnerId) ||
      $scope.tileLineItem();
  };

  bodySelector.on('submit', '#copy_form', function(){
    var copyForm = $(this);
    var path = copyForm.attr('action');
    var demandTagId = copyForm.find('#copy_demand_tag_id').val();
    var params = {
      demand_tag_id: demandTagId,
      demand_partner_id: $scope.demandPartnerId,
      demand_class: $scope.demandClass
    };

    $scope.$broadcast('copySupplyFromDemandSubmit', {
      path: path,
      params: params
    });

    $('#copy_demand_tag_id').val('');
    $('#copy_demand_tag_name').val('');
    $('#copy_save').attr('disabled', true);
    $('#copy_modal').modal('hide');
    return false;
  });

  $rootScope.$on('environmentChange', function(e, args) {
    $scope.environment = args.environment;

    setCreativeLandingPagePlaceholder();
  });

  $rootScope.$on('bidFloorTypeChanged', (e, args) => {
    $scope.bidFloorType = args.bidFloorType;
  });

  $scope.setDemandClass = function(demandClass) {
    if ($('#demand_tag_demand_class_' + demandClass).attr('disabled')) {
      return;
    }
    $scope.demandClass = demandClass;
    setCampaignDisabled();
    showHideHouseAdWfFields();
    setCreativeLandingPagePlaceholder();

    $scope.$broadcast('demandClassChanged', {
      demandClass: demandClass
    });
  };

  var setAccountManagers = function(selectedDemandPartner) {
    $scope.accountManagers = selectedDemandPartner.data('accountManagers');
  };

  var updateScopeDefaultTier = function(selectedDemandPartner) {
    $scope.hbUsePartnerDefaultTier = selectedDemandPartner.data('useDefaultTier');
    $scope.defaultTier = selectedDemandPartner.data('defaultTier');
    if($scope.defaultTier === 0) {
      $scope.defaultTier = '1L';
    }
  };

  var updateDefaultTiers = function(selectedDemandPartner) {
    $scope.$broadcast('demandPartnerChanged', {
       selectedDemandPartner: selectedDemandPartner,
       demandPartnerId: $scope.demandPartnerId
    });
  };

  var unsetCampaign = function() {
    $("#demand_tag_campaign_id").val('').trigger('chosen:updated');
    $scope.campaignId = "";
    broadcastCampaignChange();
    $scope.updateCampaign();
  };

  $scope.setCampaign = function(campaignId, isDc) {
    $scope.campaignId = campaignId;
    broadcastCampaignChange();
    if(isDc) {
      $scope.updateDcCampaign();
    } else {
      $scope.updateCampaign();
    }
  };

  var broadcastCampaignChange = function() {
    $scope.$broadcast('campaignChanged', {
      campaignId: $scope.campaignId
    });
  };

  var updateRateCurrencyChosen = function() {
    setTimeout(function() {
      $("select[name*='[rate_currency]']").trigger('chosen:updated');
    }, 0);
  };

  var setRate = function(rate, currency) {
    if ($scope.usingCampaignRate()) {
      $scope.rate = rate;
      $scope.rateCurrency = currency;
      updateRateCurrencyChosen();
    }
  };

  $(document).ready(function () {
    $('#programmatic_guaranteed_inventory_groups_attributes_0_inventory_values').on('change', function (...args) {
      let valueSelected = this.value;

      $('#programmatic_guaranteed_inventory_groups_attributes_0__destroy').val(valueSelected === '')
    })
  })

  $scope.updateCampaign = function() {
    var selected = $("#demand_tag_campaign_id, #programmatic_guaranteed_campaign_id").find("option:selected");
    broadcastCampaignChange();

    if (selected.length <= 0) {
      return;
    }

    // if they remove a campaign set it back to the demand tag rate.  But, if
    // it's new that would reset it to null so check that first
    if (selected[0].value == "") {
      broadcastCampaignActive(true);
      $scope.hasCampaignRate = false;
      $scope.rate = $scope.demandTagRate;
      $scope.rateCurrency = $scope.demandTagRateCurrency;
      $scope.bookedRevenue = $scope.demandTagBookedRevenue;
      updateRateCurrencyChosen();
    } else {
      $scope.hasCampaignRate = selected.data("hasCampaignRate");

      broadcastCampaignActive(selected.data("campaignActive"));
      broadcastCampaignHasRate($scope.hasCampaignRate);

      if ($scope.hasCampaignRate) {
        setRate(parseFloat(selected.data("campaignRate")), selected.data("campaignRateCurrency"));
        $scope.bookedRevenue = parseFloat(selected.data("bookedRevenue"));
      }
    }
  };

  $scope.updateDcCampaign = function() {
    var selected = $("#selling_strategy_campaign_id").find("option:selected");
    broadcastCampaignChange();

    if (selected.length <= 0) {
        return;
    } else if (selected[0].value == "") {
      broadcastCampaignActive(true);
    } else {
      broadcastCampaignActive(selected.data("campaignActive"));
    }
  };

  var broadcastCampaignActive = function (value) {
    $rootScope.$broadcast('campaignActiveChange', {
      campaignActive: value
    });
  };

  var broadcastCampaignHasRate = function (value) {
    $rootScope.$broadcast('campaignHasRateChange', {
      hasRate: value
    });
  };

  $scope.hidePriceExtensionEnabled = function() {
    return $scope.hasCampaignRate || !_.contains(['1', '11'], $scope.demandClass);
  };

  $scope.usingCampaignRate = function() {
    return $scope.hasCampaignRate;
  };

  $scope.usingCampaignRateTooltip = function() {
    if ($scope.usingCampaignRate()) {
      return 'Using Campaign rate and payment terms';
    }
  };

  $scope.doohEnvironmentSelected = function() {
    return _.contains(['dooh'], $scope.environment);
  };

  serverSideTable.on('preXhr.dt', function (e, settings, data) {
    data.campaign_id = $scope.campaignId;
    data.demand_partner_id = $scope.demandPartnerId;
    data.demand_class = $scope.demandClass;
    data.hb_use_partner_default_tier = $scope.hbUsePartnerDefaultTier;
    data.default_tier = $scope.defaultTier;
    data.openrtb_type = $scope.openrtbType;
    data.rate = $scope.openrtbNetFloorRate();
  });

  modalTable.on('preXhr.dt', function(e, settings, data) {
    data.custom_data = data.custom_data || {};
    data.custom_data.demand_tag = {
      dc_open_rtb: $scope.isDcOpenRtb,
      openrtb_type: $scope.openrtbType,
      openrtb_gross_floor_rate: $scope.openrtbNetFloorRate(),
      vast_endpoint_url: $('#demand_tag_vast_endpoint_url').val(),
      demand_class: $scope.demandClass
    };
  });

  modalTable.on('init.dt', function () {
    modalTableLoaded.resolve();
  });

  modalTable.on('draw.dt', function () {
    _styleModalRows();
  });

  waterfallModalHelper.openEventHandler(bodySelector, '.open-tag-modal', modal, modalTable, function() {
    $.when( modalTableLoaded ).done(function() {
      _setFloorRpmFilter();
    });
  });

  waterfallModalHelper.saveEventHandler(bodySelector, modal, modalTable, function() {
    var params = {
      demand_partner_id: $scope.demandPartnerId,
      demand_class: $scope.demandClass
    };

    $scope.$broadcast('addSupplyModalSave', {
      params: params
    });
  });

  var demandTagFormat = function() {
    return $('input[name="demand_tag[format]"]:checked').val() || 'video';
  };

  $("#copy_demand_tag_name").autocomplete({
    minLength: 1,
    source: function( request, response ) {
      $.ajax({
        url: "/searches/demand_tags?text_only=true&exclude_open_market=true&format=" + demandTagFormat() + "&search="+request.term,
        dataType: "json"
      }).done(function( data ) {
        response(
          _.chain(data)
           .map(function(item) {
             return {
               label: item.text,
               value: item.value
             };
           })
           .reject(function(item) {
             return item.value == $scope.demandTagId;
           })
           .value()
        );
      });
    },
    select: function(e, ui){
      var item = ui.item;
      var itemId = item.value;
      $("#copy_demand_tag_name").val($('<div></div>').html(item.label).text());
      $("#copy_demand_tag_id").val(itemId);
      $("#copy_save").removeAttr("disabled");
      return false;
    }
  }).data("ui-autocomplete")._renderItem = function (ul, item) {
     return $("<li></li>")
         .data("item.autocomplete", item)
         .append(item.label)
         .appendTo(ul);
   };

  var _styleModalRows = function (){
    var bulkIndex = modalTable.find("th.bulk-add-enabled").index();
    var statusIndex = modalTable.find("th.status").index();

    modalTable.find("tr").each(function(i, tr) {
      waterfallModalHelper.styleWithBulkAddEnabled(tr, bulkIndex);
      waterfallModalHelper.styleStatus(tr, statusIndex);
    });
  };

  $rootScope.$on('formatChanged', function(e, args) {
    if (args.format === 'tile' && $scope.costModelType == 3) {
      $scope.setCostModelType(0);
    }
  });

  var hasDynamicBidFloorRate = function() {
    return _.contains(['3', '9'], $scope.demandClass) &&
      $('input[name="demand_tag[bid_floor_type]"]:checked').val() === 'dynamic';
  };

  var hasPerformancePricing = function() {
    return _.contains([2, 3], $scope.costModelType);
  };

  var isDealId = function() {
    return $scope.isDcOpenRtb && $scope.openrtbType === 'deal_id';
  };

  var _rateFilterRate = function() {
    var rate = $("#demand_tag_rate, #selling_strategy_rate").val();
    if (isDealId()) {
      rate = String($scope.openrtbNetFloorRate());
    }
    return rate;
  };

  var _setFloorRpmFilter = function () {
    var filterVal = '';
    var rate = platformUtilities.cleanParseFloat(_rateFilterRate());
    if (rate) {
      filterVal = (hasDynamicBidFloorRate() || hasPerformancePricing()) ? '' : rate.toFixed(2);
    }
    modal.find("#dfilter_rpm_floor").val(filterVal).trigger("keyup");
    modal.find('#dfilter_min_rpm_floor').val('').trigger("keyup");
  };

  var setCreativeLandingPagePlaceholder = function() {
    // cannot use angular because the input has ng-non-bindable
    var placeholder = 'No URL entered';

    if ($scope.environment === 'ctv' || _.contains(['1', '3'], $scope.demandClass)) {
      placeholder = 'For use in advertiser domain targeting';
    }

    $('#demand_tag_creative_landing_page_url').attr('placeholder', placeholder);
  };

  var setCampaignDisabled = function() {
    $('#demand_tag_campaign_id').attr('disabled', $scope.houseAdDemandTag()).trigger('chosen:updated');
  };

  var toggleHouseAdColumns = function(cols, toggle) {
    $('#tag_results table').find(cols).toggleClass('hidden-column', toggle).css('display', 'table-cell');
  };

  var showHideHouseAdWfFields = function() {
    if ($scope.persisted) {
      return;
    }
    toggleHouseAdColumns('th.ratio, th.weight', !$scope.houseAdDemandTag());
    toggleHouseAdColumns('th.tier, th.priority', $scope.houseAdDemandTag());
  };

  setTimeout(function () {
    setCreativeLandingPagePlaceholder();
    setCampaignDisabled();
    showHideHouseAdWfFields();
  }, 0);
}]);

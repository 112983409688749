angular.module('vastdesk').
  component('accountTcPlusToggle',  {
    bindings: {
      totalConnectPlusEnabled: '<',
      toggleClass: '@'
    },
    template: require("../templates/accountTcPlusToggle.html"),
    controllerAs: '$accountTcPlusToggleCtrl',
    controller: function() {

      var self = this;

      this.clickCb = function(val) {
        $('.' + self.toggleClass).toggleClass('hidden', !val);
      };

      this.$onInit = function() {
        self.clickCb(self.totalConnectPlusEnabled);
      };
    }
});

import WaterfallHelper from '../../modules/waterfall_helper.js';
import WaterfallModalHelper from '../../modules/waterfall_modal_helper.js';

angular.module('vastdesk').
  component('externalBidderSupplyModal',  {
    bindings: {
      supplyTagIds: '<'
    },
    controllerAs: '$externalBidderSupplyModalCtrl',
    controller: function() {

      var self = this;
      var bodySelector = $('body');
      var modal = $('#eb_supply_tag_modal');
      var modalTable = modal.find('table');
      var modalDataTable;
      var selectedIds = [];
      var waterfallHelper = new WaterfallHelper();
      var waterfallModalHelper = new WaterfallModalHelper();

      bodySelector.on('click', '#open-eb-supply-tag-modal', function() {
        if (!modalDataTable) {
          return;
        }
        selectedIds = [];
        modalDataTable.rows().nodes().page.len(modalTable.data("pageLength") || 50).search('').draw();

        //hack for datatable column bug
        //https://datatables.net/forums/discussion/24424/column-header-element-is-not-sized-correctly-when-scrolly-is-set-in-the-table-setup
        setTimeout( function () {
          modalDataTable.draw();
        }, 150 );
      });

      bodySelector.on("change", "[name='tag_ids[]']", function(){
        var checkbox = $(this);
        var val = checkbox.val();
        if(checkbox.prop("checked")){
          selectedIds.push(val);
        } else {
          selectedIds.splice(selectedIds.indexOf(val),1);
        }
      });

      var getAllSelectedIds = function() {
        return angular.copy(self.supplyTagIds).concat(angular.copy(selectedIds));
      };

      modalTable.on('preXhr.dt', function(e, settings, data) {
        data.custom_data = data.custom_data || {};
        data.custom_data.selected_ids = getAllSelectedIds();
      });

      modalTable.on('init.dt', function () {
        modalDataTable = $(this).DataTable();
        $('#open-eb-supply-tag-modal').removeAttr('disabled');
      });

      modalTable.on('draw.dt', function () {
        waterfallModalHelper.setSelectedModalCheckboxes(modalTable, selectedIds);
        waterfallHelper.setModalCheckboxes(modalTable, self.supplyTagIds);
      });

      bodySelector.on('click', '#eb_supply_tag_modal #modal_save', function() {
        modal.find('form').remove();

        modal.append($("<form>", {
          action: modal.data('batchUpdatePath'),
          method: 'post',
          class: 'hidden',
          'data-turbo': false
        }));

        var form = modal.find('form');

        appendHiddenInput(form, 'form_referrer', window.location.href);
        appendHiddenInput(form, 'authenticity_token', modal.data('authToken'));

        _.each(selectedIds, function(id) {
          appendHiddenInput(form, 'batch_object_ids[]', id);
        });

        form.submit();
      });

      var appendHiddenInput = function(form, name, value) {
        form.append($("<input>", {
          type: 'hidden',
          name: name,
          value: value
        }));
      };

    }
});

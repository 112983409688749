angular.module('vastdesk').
  component('podDefaultSettings',  {
    bindings: {
      podSettingDefault: '@',
      podMaxDurationDefault: '<',
      podMaxDurationPlaceholder: '@',
      allowDuplicateCreativesDefault: '@',
      allowDuplicateAdomainsDefault: '@',
      duplicateCreativeOptions: '<',
      environmentDefault: '@'
    },
    template: require("../templates/podDefaultSettings.html"),
    controllerAs: '$podDefaultSettingsCtrl',
    controller: ['$timeout', '$rootScope', function($timeout, $rootScope) {

      var self = this;
      var bodySelector = $('body');

      this.podTypes = [
        ['none', 'None'],
        ['dynamic', 'Dynamic'],
        ['custom', 'Custom']
      ];

      this.setAllowDuplicateCreativesDefault = function(val) {
        self.allowDuplicateCreativesDefault = val;

        if (val === 'true') {
          self.allowDuplicateAdomainsDefault = 'true';
        }
      };

      $rootScope.$on('supplyEnvironmentDefaultChange', function(e, args) {
        self.environmentDefault = args.environmentDefault;
      });

      this.setPodSettingDefault = function(setting) {
        self.podSettingDefault = setting;
        $rootScope.$broadcast("podSettingDefaultChange", setting);
      };

    }]
});

import ReportMetricHelper from '../../modules/report_metric_helper.js';

angular.module('vastdesk')
.component('metricsGroup', {
  bindings: {
    metricGroupName: '@',
    metricsList: '=',
    selectedMetrics: '=',
    openrtbMetrics: '=',
    disabledByDetectedReporting: '=',
    demandReport: '=',
    pcFilterReport: '=',
    vastErrorCodeReport: '=',
    audioReport: '=',
    bidDensityReport: '=',
    advertiserDomainReport: '=',
    partnerSegmentReport: '=',
    dspCityReport: '<',
    dspDomainReport: '<',
    dspInventoryReport: '<',
    dspSourceReport: '<',
    ortbReport: '=',
    ortbBuyer: '=',
    routerReport: '=',
    savedReport: '@',
    contentReport: '=',
    magniteSspReport: '=',
    freqReachReport: '<',
    postalCodeReport: '<'
  },
  template: require("../templates/metricsGroup.html"),
  controllerAs: '$metricsGroupCtrl',
  controller: ['$scope', '$rootScope', '$timeout', function($scope, $rootScope, $timeout) {

    var self = this;
    var onInitLoad = $.Deferred();
    var reportMetricHelper = new ReportMetricHelper();

    this.hideGroup = () => {
      return (this.audioReport && vendorSelectionGroup()) ||
        _.every(this.metricsList, (metric) => {
          return this.isDisabled(metric);
        });
    };

    this.isDisabled = function(metric) {
      return reportMetricHelper.metricDisabled(self, metric);
    };

    this.addOrRemoveMetric = function(metric) {
      $timeout(function() {
        metric.selected = !metric.selected;
      }, 0);
    };

    this.bulkSetSelected = function(selected, $event) {
      _.each(self.metricsList, function(metric) {
        metric.selected = selected;
      });
      $event.preventDefault();
      $event.stopPropagation();
    };

    this.selectOpenrtbMetrics = function() {
      _.each(self.metricsList, function(metric) {
        if(_.contains(self.openrtbMetrics, metric.value[0])){
          metric.selected = true;
        }
      });
    };

    this.setSelectedMetrics = function(metrics) {
      _.each(self.metricsList, function(metric) {
        metric.selected = _.contains(metrics, metric.value[0]);
      });
    };

    var metricsConflict = function(metric) {
      if (!_.contains(['ad_requests', 'opportunities', 'opportunity_rate', 'opportunity_fill_rate'], metric.value[0])) {
        return;
      }
      else if (window.location.search.match('&metrics%5B%5D=') || self.savedReport === 'true') {
        // page is loaded via link or scheduled report is persisted or has been attempted to be saved
        return (metric.supply && self.demandReport) || (metric.demand && !self.demandReport);
      }
      else {
        // this assumes total calls is not selected by default
        return metric.supply;
      }
    };

    this.initSelected = function() {
      _.each(self.metricsList, function(metric, i) {
        if ( _.contains(self.selectedMetrics, metric.value[0]) && !metricsConflict(metric)) {
          metric.selected = true;
        }
      });
    };

    const noMetricsSelected = () => {
      return _.every(this.metricsList, (metric) => {
        return !metric.selected;
      });
    };

    const vendorSelectionGroup = () => {
      return ['pre_bid', 'ivt', 'viewability'].includes(this.metricGroupId);
    };

    const collapseOnLoad = () => {
      if (vendorSelectionGroup() && noMetricsSelected()) {
        $('#collapse-metrics-group-' + this.metricGroupId).collapse('hide');
      }
    };

    $scope.$on('setReportMetrics', (e, args) => {
      $.when( onInitLoad ).done(() => {
        this.setSelectedMetrics(args.metrics);

        if (args.applyScope) {
          $scope.$apply();
        }
      });
    });

    $scope.$on('selectOpenrtbMetrics', function() {
      $.when( onInitLoad ).done(function() {
        self.ortbReport = true;
        self.selectOpenrtbMetrics();
      });
    });

    this.$onInit = () => {
      this.metricGroupId = (this.metricGroupName || '').replace(/ /g, '_').toLowerCase();

      $timeout(function() {
        self.initSelected();
        collapseOnLoad();
        onInitLoad.resolve();
      }, 0);
    };

  }]
});

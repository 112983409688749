angular.module('vastdesk').
  component('supplyTagPodDeduplication', {
    bindings: {
      duplicateEnabledMappings: '<',
      duplicateIabTierPodEnabled: '<',
      duplicateIabTier1Categories: '=',
      duplicateIabTier2Categories: '=',
      duplicateAdomains: '=',
      duplicateCreatives: '=',
    },
    template: require('../templates/supplyTagPodDeduplication.html'),
    controllerAs: '$supplyTagPodDeduplicationCtrl',
    controller: ['$scope', function ($scope) {
      var self = this;

      const handleChange = function () {
        // when one deduplication setting is disabled then disable every deduplication setting following it
        if (!self.duplicateIabTier1Categories) {
          self.duplicateIabTier2Categories = false;
        }

        if (!self.duplicateIabTier2Categories) {
          self.duplicateAdomains = false;
        }

        if (!self.duplicateAdomains) {
          self.duplicateCreatives = false;
        }
      };

      this.setDuplicateIabTier1Categories = function (value) {
        self.duplicateIabTier1Categories = value;
        handleChange();
      };

      this.setDuplicateIabTier2Categories = function (value) {
        self.duplicateIabTier2Categories = value;
        handleChange();
      };

      this.setDuplicateAdomains = function (value) {
        self.duplicateAdomains = value;
        handleChange();
      };

      this.setDuplicateCreatives = function (value) {
        self.duplicateCreatives = value;
        handleChange();
      };

      this.duplicateIabTier2CategoriesDisabled = function () {
        return !self.duplicateIabTier1Categories;
      };

      this.duplicateAdomainsDisabled = function () {
        return !self.duplicateIabTier1Categories || !self.duplicateIabTier2Categories;
      };

      this.duplicateCreativesDisabled = function () {
        return !self.duplicateIabTier1Categories || !self.duplicateIabTier2Categories || !self.duplicateAdomains;
      };
    }],
});

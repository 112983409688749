angular.module('vastdesk').
  component('signUpsPassword', {
    bindings: {
      passwordLabel: '@',
      passwordAttr: '@',
      spacerClass: '@'
    },
    template: require("../templates/signUpsPassword"),
    controllerAs: '$signUpsPasswordCtrl',
    controller: ['$scope', function($scope) {
      var self = this;
      this.passwordInputType = 'password';
      this.userPassword = '';

      this.toggleShowPassword = function() {
        self.passwordInputType = (self.passwordInputType === 'password') ? 'text' : 'password';
      };

      this.validatePassword = function() {
        self.hasUppercase = (/[A-Z]/.test(self.userPassword));
        self.hasLowercase = (/[a-z]/.test(self.userPassword));
        self.hasSpecialChar = /[#?!@$%^&*-]/.test(self.userPassword);
        self.hasNumber = (/\d/.test(self.userPassword));
      };
    }]
  });
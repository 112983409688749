angular.module('vastdesk')
.component('listHolder', {
  bindings: {
    formName: '@',
    items: '=',
    tooLarge: '@',
    maxItems: '@'
  },
  template: require("../templates/listHolder.html"),
  controllerAs: '$listHolderCtrl',
  controller: function() {
    var self = this;

    this.removeNames = [];

    this.removeItem = function(item) {
      self.removeNames.push(item);
      self.items = _.without(self.items, item);
    };

  }
});

import platformUtilities from '../../modules/platform_utilities.js';
import WaterfallHelper from '../../modules/waterfall_helper.js';
import WaterfallModalHelper from '../../modules/waterfall_modal_helper.js';

angular.module('vastdesk')
.component('lineItemCreatives', {
  bindings: {
    setLineItemRatios: '<',
    tableId: '@',
    editable: '<',
    batchActions: '<',
    demandPartnerId: '@',
    modalPath: '@',
    format: '@',
    currencyPlaceholder: '@',
    forecast: '<',
    tagType: '@'
  },
  template: require("../templates/lineItemCreatives.html"),
  controllerAs: '$lineItemCreativesCtrl',
  controller: ['$scope', '$rootScope', function($scope, $rootScope) {

    var self = this;
    var serverSideTable = $('#creative_results table');
    var serverSideTableId = $(serverSideTable).attr('id');
    var serverSideDataTable;
    var modal = $('#line_item_creative_modal');
    var modalTable = modal.find('table');
    var selectedCreativeIds = [];
    var bodySelector = $('body');
    var filteredRecordIds = [];
    var reportData = [];
    var waterfallHelper = new WaterfallHelper();
    var waterfallModalHelper = new WaterfallModalHelper();

    var currentCreativeIds = function() {
      return waterfallHelper.extractIdsFromSetList(self.setLineItemRatios, 'creative_id');
    };

    var _styleModalRows = function (){
      var statusIndex = modalTable.find("th.status-text").index();

      modalTable.find("tr").each(function(i, tr) {
        waterfallModalHelper.styleStatus(tr, statusIndex);
      });
    };

    modalTable.on('preXhr.dt', function(e, settings, data) {
      data.custom_data = data.custom_data || {};
      data.custom_data.selected_ids = [].concat(currentCreativeIds()).concat(selectedCreativeIds);
      data.demand_partner_id = self.demandPartnerId;
      data.demand_tag_format = self.format;
    });

    modalTable.on( 'draw.dt', function () {
      _styleModalRows();
      waterfallModalHelper.setSelectedModalCheckboxes(modalTable, selectedCreativeIds);
      waterfallHelper.setModalCheckboxes(modalTable, currentCreativeIds());
    });

    waterfallModalHelper.openEventHandler(bodySelector, '.open-creative-modal', modal, modalTable, function() {
      selectedCreativeIds = [];
      modal.find('.modal-content .dataTables_filter input').attr("placeholder", "Search by creative name, or enter a comma-separated list of IDs...");
    });

    waterfallModalHelper.saveEventHandler(bodySelector, modal, modalTable, function() {
      if (!Array.isArray(selectedCreativeIds)) {
        return;
      }

      var currentIds = currentCreativeIds();

      var validNewLineItemRatios = _.chain(selectedCreativeIds)
        .reject(function(creativeId) {
          return _.contains(currentIds, parseInt(creativeId));
        })
        .map(function(creativeId) {
          return {
            creative_id: parseInt(creativeId),
            ratio: 1
          };
        })
        .value();

      self.setLineItemRatios = self.setLineItemRatios.concat(validNewLineItemRatios);
      $scope.$apply();
      waterfallHelper.drawServerSideTable(serverSideDataTable, false);
    });

    waterfallModalHelper.modalCheckEventHandler(bodySelector, 'line_item_creative_ids', function(val) {
      selectedCreativeIds.push(val);
    }, function(val) {
      selectedCreativeIds = _.without(selectedCreativeIds, val);
    });

    serverSideTable.on('init.dt', function() {
      serverSideDataTable = $(this).DataTable();
      waterfallHelper.addDateRangeSelect({
        section: '#associated_creative_section',
        forecast: self.forecast
      });

      if (Array.isArray(reportData) && reportData.length > 0) {
        setTimeout(function() {
          waterfallHelper.drawServerSideTable(serverSideDataTable, false);
        }, 0);
      }
    });

    serverSideTable.on('preXhr.dt', function (e, settings, data) {
      waterfallHelper.abortPendingAjaxRequests(serverSideTable, serverSideDataTable);

      data.set_line_item_ratios = self.setLineItemRatios;
      data.date_range = $('#date_range').val();
      data.report_data = waterfallHelper.getReportDataForDraw(serverSideTable, serverSideDataTable, reportData, 'creative_id');

      $('.slir-r').blur();
    });

    serverSideTable.on('xhr.dt', function(e, settings, json, xhr) {
      if (json && json.custom_data) {
        filteredRecordIds = json.custom_data.filtered_record_ids;
      }
      if (json && json.data) {
        attachReportData(json.data);
      }
    });

    var reportRowFor = function(tableRow) {
      return _.findWhere(reportData, {creative_id: tableRow.DT_RowAttr['data-creative-id']}) || {};
    };

    var attachReportData = function(rows) {
      _.each(rows, function(r) {
        var reportRow = reportRowFor(r);
        waterfallHelper.addReportDataToRow(r, reportRow, {
          currencyPlaceholder: self.currencyPlaceholder
        });
      });
    };

    var creativeIdForRow = function(row) {
      return $(row).data('creativeId');
    };

    var ratioWithCreativeId = function(ratios, id) {
      return _.find(ratios, function(slir) {
        return parseInt(slir.creative_id) === parseInt(id);
      });
    };

    var setLineItemRatioFor = function(row) {
      var id = creativeIdForRow(row);
      return ratioWithCreativeId(self.setLineItemRatios, id);
    };

    var updateSetLineItemRatio = function(row, attributes, draw) {
      var slir = setLineItemRatioFor(row);

      if (slir) {
        slir = _.extend(slir, attributes);
        $scope.$apply();

        if (draw) {
          waterfallHelper.drawServerSideTable(serverSideDataTable, false);
        }
      }
    };

    var updateSetLineItemRatioFromElement = function(element, attribute, draw) {
      var row = waterfallHelper.rowForTableElement(element);
      var attributes = {};
      attributes[attribute] = waterfallHelper.valueForElement(element, attribute);
      updateSetLineItemRatio(row, attributes, draw);
    };

    var handleRatioChange = function(element) {
      updateSetLineItemRatioFromElement(element, 'ratio', true);
    };

    bodySelector.on('change', 'td .slir-r', function () {
      handleRatioChange(this);
    });

    var removeSetLineItemRatios = function(creativeIds, applyScope) {
      var startingLength = self.setLineItemRatios.length;

      self.setLineItemRatios = _.reject(self.setLineItemRatios, function(slir) {
        return _.contains(creativeIds, parseInt(slir.creative_id));
      });

      var endingLength = self.setLineItemRatios.length;
      var creativesRemoved = startingLength - endingLength;

      if (creativesRemoved < 1) {
        return;
      }

      if (applyScope) {
        $scope.$apply();
      }

      waterfallHelper.drawServerSideTableAfterRemoval(serverSideDataTable, creativesRemoved);
    };

    bodySelector.on("click", "#associated_creative_section a.remove-creative", function () {
      var row = waterfallHelper.rowForTableElement(this);
      var creativeId = creativeIdForRow(row);

      removeSetLineItemRatios([creativeId], true);

      return false;
    });

    var removeAllFilteredLineItemRatios = function() {
      self.setLineItemRatios = _.filter(self.setLineItemRatios, function(slir) {
        return !_.contains(filteredRecordIds, parseInt(slir.creative_id));
      });
      $scope.$apply();
      waterfallHelper.drawServerSideTable(serverSideDataTable, true);
    };

    bodySelector.on("click", "#associated_creative_section .remove-all", function(e){
      removeAllFilteredLineItemRatios();
      return false;
    });

    $scope.$on('formatChanged', function(e, args) {
      self.setLineItemRatios = [];
      self.format = args.format;
      waterfallHelper.drawServerSideTable(serverSideDataTable, true);
    });

    $rootScope.$on('updatingReportData', function(e, args) {
      if (args.table === serverSideTableId) {
        platformUtilities.triggerDataTablesProcessing(serverSideTable, true);
      }
    });

    $rootScope.$on('updatedReportData', function(e, args) {
      if (args.table === serverSideTableId) {
        reportData = args.resp.creative_report;
        self.currencyPlaceholder = args.resp.currency_placeholder;
        waterfallHelper.drawServerSideTable(serverSideDataTable, false);
      }
    });

    $scope.$on('demandPartnerChanged', function(e, args) {
      self.demandPartnerId = args.demandPartnerId;
      var partnerPresent = args.demandPartnerId && args.demandPartnerId !== '';
      var currentIds = currentCreativeIds();

      $('.partner-hidden').toggleClass('hidden', partnerPresent);
      $('.partner-shown').toggleClass('hidden', !partnerPresent);

      if (currentIds) {
        removeSetLineItemRatios(currentIds, false);
      }
    });

    $scope.$on('demandClassChanged', function(e, args) {
      $('#demandTagTabs .creatives').parent('li').toggleClass('hidden', parseInt(args.demandClass) !== 11);
    });

    var handleBulkChange = function(creativeIds, attribute, value) {
      _.each(self.setLineItemRatios, function(slir) {
        if (_.contains(creativeIds, parseInt(slir.creative_id))) {
          slir[attribute] = value;
        }
      });

      setTimeout(function() {
        $scope.$apply();
      }, 0);

      waterfallHelper.drawServerSideTable(serverSideDataTable, false);
    };

    $rootScope.$on('bulkEditBroadcast', function(e, args) {
      if (args.tableId === serverSideTableId) {
        if (!args.selectedIds || !Array.isArray(args.selectedIds)) {
          return;
        }

        var creativeIds = _.map(args.selectedIds, function(id) {
          return parseInt(id);
        });

        if (args.id === 'remove') {
          removeSetLineItemRatios(creativeIds, false);
        }
        else if (args.id === 'ratio') {
          handleBulkChange(creativeIds, 'ratio', waterfallHelper.parseIntOrNull(args.value));
        }
      }
    });

    var intervalTime = 0;
    setInterval(function () {
      intervalTime += 1;
      if(intervalTime >= 60 && document.hasFocus() && $("#tabCreatives").is(".active")){
        $rootScope.$broadcast('updateReportData', {table: serverSideTableId});
        intervalTime = 0;
      }
    }, 1000);

  }]
});

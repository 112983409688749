angular.module('vastdesk').
  component('keyDefinitionType',  {
    bindings: {
      definitionType: '@',
      persisted: '<'
    },
    template: require("../templates/keyDefinitionType.html"),
    controllerAs: '$keyDefinitionTypeCtrl',
    controller: function() {

      var self = this;

      this.setDefinitionType = function(dt) {
        if (self.persisted) {
          return;
        }

        self.definitionType = dt;

        $('.definition-type-wrapper').addClass('hidden');
        $('.definition-type-' + dt).removeClass('hidden');
      };

    }
});

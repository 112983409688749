import HighchartsBuilder from '../../modules/highcharts_builder.js';
import { highchartsComparisonConfigHelper } from '../../modules/highcharts_comparison_config_helper.js';

angular.module('vastdesk')
  .component('historicalBlocksComparisonChart', {
    bindings: {
      chartId: '@',
      metric: '@',
      timezone: '@',
      chartData: "="
    },
    template: '<div class="graphChart comparisonChart"><div></div></div>',
    controller: ['$scope', '$rootScope', '$element', function($scope, $rootScope, $element) {

      const self = this;
      const onInitLoad = $.Deferred();
      let highchartsBuilder;
      let colors;

      this.$onInit = () => {
        highchartsBuilder = new HighchartsBuilder(this.timezone);
        colors = highchartsBuilder.chartColors;
        onInitLoad.resolve();
      };

      const chartDiv = $element.find('div');
      let chart;

      const setChart = () => {
        const todayAllHours = highchartsBuilder.getHourDataPoints('', 23);
        const currentHours = highchartsBuilder.getHourDataPoints();
        const formatType = highchartsBuilder.formatTypeForMetric(self.metric);

        const series = [{
            name: 'Today',
            type: 'areaspline',
            color: colors[0],
            lineWidth: 2,
            fillColor : highchartsBuilder.springyAreaSplineFill(colors[0]),
            data: highchartsBuilder.getDataFromHourValues(currentHours, this.chartData.today, this.metric),
            formatType: formatType,
            zIndex: 1
          },
          {
            name: 'Yesterday',
            type: 'spline',
            data: highchartsBuilder.getDataFromHourValues(todayAllHours, this.chartData.yesterday, this.metric),
            formatType: formatType,
            color: colors[1],
            shadow: highchartsBuilder.defaultShadow(),
            lineWidth: 2,
            zIndex: 3
          },
          {
            name: 'Three Days Ago',
            type: "spline",
            data: highchartsBuilder.getDataFromHourValues(todayAllHours, this.chartData.three_days_ago, this.metric),
            formatType: formatType,
            color: colors[11], //quaternary color
            shadow: highchartsBuilder.defaultShadow(),
            lineWidth: 2,
            zIndex: 2
          },
          {
            name: 'Seven Days Ago',
            type: "spline",
            data: highchartsBuilder.getDataFromHourValues(todayAllHours, this.chartData.seven_days_ago, this.metric),
            formatType: formatType,
            color: colors[2],
            shadow: highchartsBuilder.defaultShadow(),
            lineWidth: 2,
            zIndex: 2
          }
        ];

        chart = highchartsComparisonConfigHelper({
          chartDiv,
          metric: this.metric,
          colors,
          todayAllHours,
          highchartsBuilder,
          formatType,
          series,
          currencySymbol: null
        });
      };

      $scope.$on('setHighchartLoading', function(e, args) {
        highchartsBuilder.loadData(onInitLoad, chartDiv, self, args);
      });

      $scope.$on('refreshHighchart', function(e, args) {
        highchartsBuilder.refreshData(onInitLoad, chartDiv, self, args, function() {
          setChart();
        });
      });

      $scope.$on('reflowHighcharts', function(e, args) {
        highchartsBuilder.deferredReflowChart(onInitLoad, chartDiv);
      });

    }]
  });
import HighchartsBuilder from '../../modules/highcharts_builder.js';

angular.module('vastdesk')
.component('oneColumnChart', {
  bindings: {
    chartId: '@',
    chartHeight: '@',
    metric: '@',
    categories: '=?',
    currencySymbol: '@'
  },
  template: '<div class="graphChart oneColumnChart"><div></div></div>',
  controller: ['$scope', '$rootScope', '$element', function($scope, $rootScope, $element) {

    var self = this;
    var onInitLoad = $.Deferred();
    var highchartsBuilder, colors;

    this.$onInit = function() {
      highchartsBuilder = new HighchartsBuilder(self.timezone);
      colors = highchartsBuilder.chartColors;
      onInitLoad.resolve();
    };

    const isFinancialChart = () => self.chartId === 'staticFinancialDashboard';

    const getColorFor = (name) => {
      const colorMap = {
        "Revenue": colors[0], // primary color
        "Profit (Net)": colors[1], // secondary color
        "Total Cost": colors[2] //tertiary color
      };
      return colorMap[name] || '#000000';
    };

    const transformChartdata = (chartData) => {
      if (isFinancialChart) {
        return chartData.map(([data], index) => ({ data: chartData[index], name: data.name, color: getColorFor(data.name) }));  
      }
      return chartData.map(([data], index) => ({ data: chartData[index], name: data.name }));
    };

    var chartDiv = $element.find('div');
    var chart;

    var setChart = function(chartData) {
      var formatType = highchartsBuilder.formatTypeForMetric(self.metric);
      const series = Array.isArray(chartData[0]) ?
          transformChartdata(chartData) :
          [ { data: chartData } ];
      const noDataThreeColumns = Array.isArray(chartData[0]) && series.every((item)=> {
        return item.data.every((innerItem)=> {
          return innerItem.y === 0;
        });
      });
      chart = chartDiv.highcharts({
        chart: {
          type: 'column',
          animation: false,
          plotBorderWidth: null,
          backgroundColor: null,
          height: (self.chartHeight || 400) + 'px'
        },
        title: {text: ''},
        legend: Array.isArray(chartData[0]) ? highchartsBuilder.defaultLegend() : {enabled: false},
        credits: {enabled: false},
        xAxis: {
          categories: self.categories,
          labels: {enabled: Array.isArray(self.categories)},
          tickLength: 0,
          lineColor: '#ccd6eb'
        },
        yAxis: {
          title: {text: ''},
          labels: {
            reserveSpace: true,
            style: {
              fontSize: '9px',
              fontWeight: 'bold',
              color: colors[2] // tertiary color
            },
            formatter: function() {
              return highchartsBuilder.yAxisFormatter(formatType, this, self.currencySymbol);
            }
          },
          gridLineWidth: 1
        },
        tooltip: {
          useHTML: true,
          formatter: function() {
            return this.point.tooltip;
          },
          style: {
            opacity: '0.95'
          }
        },
        colors: colors,
        plotOptions: {
          column: {
            borderWidth: 0,
            maxPointWidth: 50
          }
        },
        series: noDataThreeColumns ? undefined : series
      });
    };

    $scope.$on('setHighchartLoading', function(e, args) {
      highchartsBuilder.loadData(onInitLoad, chartDiv, self, args);
    });

    $scope.$on('refreshHighchart', function(e, args) {
      highchartsBuilder.refreshData(onInitLoad, chartDiv, self, args, function() {
        setChart(args.data);
      });
    });

    $scope.$on('reflowHighcharts', function(e, args) {
      highchartsBuilder.deferredReflowChart(onInitLoad, chartDiv);
    });

    $rootScope.$on('reportCurrencySymbolChange', function (e, args) {
      self.currencySymbol = args.currencySymbol;
    });

  }]
});
import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('segmentGroupDaaAdchoices',  {
    bindings: {
      formName: '@',
      formId: '@',
      isDspSub: '<',
      canSetDaa: '<',
      daaAdChoices: '<',
      canSetEuroDaa: '<',
      euroDaaAdChoices: '<'
    },
    template: require("../templates/segmentGroupDaaAdchoices.html"),
    controllerAs: '$segmentGroupDaaAdchoicesCtrl',
    controller: ['$rootScope', '$scope', function($rootScope, $scope) {
      var self = this;

      const turnOnDaaChoices = () => {
        if (!self.isDspSub) {
          return;
        }
        if (self.canSetDaa) {
          self.daaAdChoices = true;
        }
        if (self.canSetEuroDaa) {
          self.euroDaaAdChoices = true;
        }
        setTimeout(() => $scope.$apply());
      };

      $rootScope.$on('audienceTargetingApplied', turnOnDaaChoices);

      self.attributeEnabledMappings = componentHelper.attributeEnabledMappings();

      $scope.$watch('$segmentGroupDaaAdchoicesCtrl.daaAdChoices', function(value) {
        setTimeout(() => {
          document.getElementById(`${self.formId}_daa_ad_choices_${value}`).parentElement.classList.add('active');
          document.getElementById(`${self.formId}_daa_ad_choices_${!value}`).parentElement.classList.remove('active');
        });
      });

      $scope.$watch('$segmentGroupDaaAdchoicesCtrl.euroDaaAdChoices', function(value) {
        setTimeout(() => {
          document.getElementById(`${self.formId}_euro_daa_ad_choices_${value}`).parentElement.classList.add('active');
          document.getElementById(`${self.formId}_euro_daa_ad_choices_${!value}`).parentElement.classList.remove('active');
        });
      });

    }]
});

import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('userAgentTargeting',  {
    bindings: {
      formName: '@',
      formId: '@',
      allUserAgentDevices: '<',
      allUserAgentBrands: '<',
      allUserAgentOperatingSystems: '<',
      allUserAgentBrowsers: '<',
      userAgentDevicesSelections: '<',
      userAgentBrandNamesSelections: '<',
      userAgentBrandStreamingDevicesSelections: '<',
      userAgentOperatingSystemsSelections: '<',
      userAgentBrowsersSelections: '<',
      readOnly: '<'
    },
    template: require("../templates/userAgentTargeting.html"),
    controllerAs: '$userAgentTargetingCtrl',
    controller: function() {

      var self = this;

      this.allCustomOptions = componentHelper.allCustomOptions2();

      this.replaceSpaces = function(val) {
        return val.replace(/ /g, '_');
      };

    }
});

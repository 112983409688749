angular.module('vastdesk')
.component('reportMetricsGroup', {
  bindings: {
    metricGroupName: '@',
    metricsList: '<',
    selectedMetrics: '<',
    openrtbMetrics: '<',
    detectedReport: '<',
    demandReport: '<',
    pcFilterReport: '<',
    ortbReport: '<',
    vastErrorCodeReport: '<',
    audioReport: '<',
    routerReport: '<',
    savedReport: '@',
    ortbBuyer: '<',
    magniteSspReport: '<',
    dataSource: '<'
  },
  template: require("../templates/reportMetricsGroup.html"),
  controllerAs: '$reportMetricsGroupCtrl',
  controller: ['$scope', '$rootScope', '$timeout', function($scope, $rootScope, $timeout) {

    const onInitLoad = $.Deferred();

    this.hideGroup = () => {
      return (this.audioReport && vendorSelectionGroup()) ||
        _.every(this.metricsList, (metric) => {
          return this.isDisabled(metric);
        });
    };

    this.isDisabled = (metric) => {
      return detectedDisabled(metric)
        || (this.ortbBuyer && !metric.ortb_demand)
        || demandReportDisabled(metric)
        || (this.ortbReport && !(metric.ortb_demand || metric.ortb_supply))
        || supplyReportDisabled(metric)
        || vastErrorCodeDisabled(metric)
        || audioDisabled(metric)
        || routerDisabled(metric)
        || magniteSspDisabled(metric);
    };

    const detectedDisabled = (metric) => {
      return this.detectedReport && metric.non_detected;
    }

    const demandReportDisabled = (metric) => {
      const ortbMetric = metric.ortb_demand || metric.ortb_supply;

      return (this.demandReport && !metric.demand) &&
        !(this.ortbReport && ortbMetric) &&
        !bidderPcReportAllowed(metric, ortbMetric) &&
        !bidderAdvertiserDomainReportAllowed(metric, ortbMetric);
    }

    const vastErrorCodeDisabled = (metric) => {
      return this.vastErrorCodeReport &&
        (
          !metric.vast_error_code_compatible ||
          (this.ortbMetric && !metric.bidder_vast_error_code_compatible)
        );
    }

    const dataSourceName = () => {
      if (dataSource) {
        return dataSource.name;
      }
    };

    const bidderPcReportAllowed = (metric, ortbMetric) => {
      return ortbMetric && (this.pcFilterReport || (dataSourceName && dataSourceName.startsWith('Dsp')));
    };

    const bidderAdvertiserDomainReportAllowed = (metric, ortbMetric) => {
      return ortbMetric && dataSourceName() === 'ADomain';
    }

    const audioDisabled = (metric) => {
      return this.audioReport && !metric.audio_compatible;
    }

    const routerDisabled = (metric) => {
      return !this.routerReport && metric.router_metric;
    }

    const magniteSspDisabled = (metric) => {
      return this.magniteSspReport && !metric.magnite_ssp_compatible;
    }

    const supplyReportDisabled = (metric) => {
      return !this.demandReport && !metric.supply && !this.ortbReport &&
        !bidderPcReportAllowed(metric, metric.ortb_supply) &&
        !bidderAdvertiserDomainReportAllowed(metric, metric.ortb_supply);
    }

    this.addOrRemoveMetric = (metric) => {
      $timeout(() => {
        metric.selected = !metric.selected;
      }, 0);
    };

    this.bulkSetSelected = (selected, $event) => {
      _.each(this.metricsList, (metric) => {
        metric.selected = selected;
      });
      $event.preventDefault();
      $event.stopPropagation();
    };

    this.selectOpenrtbMetrics = () => {
      _.each(this.metricsList, (metric) => {
        if(_.contains(this.openrtbMetrics, metric.value[0])){
          metric.selected = true;
        }
      });
    };

    this.setSelectedMetrics = (metrics) => {
      _.each(this.metricsList, (metric) => {
        metric.selected = _.contains(metrics, metric.value[0]);
      });
    };

    const metricsConflict = (metric) => {
      if (!_.contains(['ad_requests', 'opportunities', 'opportunity_rate', 'opportunity_fill_rate'], metric.value[0])) {
        return;
      }
      else if (window.location.search.match('&metrics%5B%5D=') || this.savedReport === 'true') {
        // page is loaded via link or scheduled report is persisted or has been attempted to be saved
        return (metric.supply && this.demandReport) || (metric.demand && !this.demandReport);
      }
      else {
        // this assumes total calls is not selected by default
        return metric.supply;
      }
    };

    this.initSelected = () => {
      _.each(this.metricsList, (metric, i) => {
        if ( _.contains(this.selectedMetrics, metric.value[0]) && !metricsConflict(metric)) {
          metric.selected = true;
        }
      });
    };

    const noMetricsSelected = () => {
      return _.every(this.metricsList, (metric) => {
        return !metric.selected;
      });
    };

    const vendorSelectionGroup = () => {
      return ['pre_bid', 'ivt', 'viewability'].includes(this.metricGroupId);
    };

    const collapseOnLoad = () => {
      if (vendorSelectionGroup() && noMetricsSelected()) {
        $('#collapse-metrics-group-' + this.metricGroupId).collapse('hide');
      }
    };

    $scope.$on('setReportMetrics', (e, args) => {
      $.when( onInitLoad ).done(() => {
        this.setSelectedMetrics(args.metrics);

        if (args.applyScope) {
          $scope.$apply();
        }
      });
    });

    $scope.$on('selectOpenrtbMetrics', () => {
      $.when( onInitLoad ).done(() => {
        this.ortbReport = true;
        this.selectOpenrtbMetrics();
      });
    });

    this.$onInit = () => {
      this.metricGroupId = (this.metricGroupName || '').replace(/ /g, '_').toLowerCase();

      $timeout(() => {
        this.initSelected();
        collapseOnLoad();
        onInitLoad.resolve();
      }, 0);
    };

  }]
});

angular.module('vastdesk').
  component('partnerSegmentsTable',  {
    bindings: {
      tableId: '@'
    },
    controllerAs: '$partnerSegmentsTableCtrl',
    controller: function() {
      var self = this;

      this.$onInit = function() {
        var partnerSegmentTable = $('#' + self.tableId);

        partnerSegmentTable.on('draw.dt', function() {
          partnerSegmentTable.find('.ps-disabled-row').parents('tr').addClass('ps-disabled');
        });
      };
    }
});

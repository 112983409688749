import HighchartsBuilder from '../../modules/highcharts_builder.js';

angular.module('vastdesk')
.component('pieChart', {
  bindings: {
    chartId: '@',
    chartData: '=',
    showSeriesName: '=?',
    metric: '@',
    noRefreshingPanel: '@'
  },
  template: '<div class="graphChart pieChart"><div></div></div>',
  controller: ['$scope', '$element', function($scope, $element) {

    var highchartsBuilder = new HighchartsBuilder();
    var chartDiv = $element.find('div');

    var self = this;
    var onInitLoad = $.Deferred();

    var seriesName = function() {
      return self.metric || 'Imps';
    };

    var setChart = function() {
      chartDiv.highcharts({
        chart: {
          backgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
        },
        title: {
          text: ''
        },
        credits: {enabled: false},
        colors: highchartsBuilder.chartColors,
        tooltip: {
          formatter: function() {
            var name = highchartsBuilder.getName(this.point.name);
            var format = '<div style="font-size: 10px;">' + name + '</div><br/>';
            if (self.showSeriesName) {
              format += this.series.name + ': ';
            }
            format += '<b>' + this.point.percentage.toFixed(1) + '%</b>';
            return format;
          },
          style: {
            opacity: '0.95'
          }
        },
        legend: _.extend({}, highchartsBuilder.defaultLegend(), {maxHeight: 44}),
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            innerSize: '40%',
            dataLabels: {
              enabled: false
            },
            showInLegend: true
          }
        },
        series: [{
          name: seriesName(),
          colorByPoint: true,
          data: self.chartData
        }]
      });
    };

    $scope.$on('setHighchartLoading', function(e, args) {
      highchartsBuilder.loadData(onInitLoad, chartDiv, self, args);
    });

    $scope.$on('refreshHighchart', function(e, args) {
      highchartsBuilder.refreshData(onInitLoad, chartDiv, self, args, function() {
        var chart = chartDiv.highcharts();

        if (chart && chart.series) {
          if (args.metric) {
            self.metric = args.metric;
          }
          if (args.data) {
            self.chartData = args.data;
          }
          chart.series[0].update({name: seriesName()}, false);
          chart.series[0].setData(self.chartData);
        }
      });
    });

    $scope.$on('reflowHighcharts', function(e, args) {
      highchartsBuilder.deferredReflowChart(onInitLoad, chartDiv);
    });

    this.$onInit = function() {
      setChart();
      onInitLoad.resolve();
    };

  }]
});

import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('accountBingewatcherFeeFields',  {
    bindings: {
      formId: '@',
      formName: '@',
      bingewatcherFeeType: '<',
      bingewatcherFlatFee: '<',
      bingewatcherFeeCommencementDate: '@',
      bingewatcherTierACpm: '<',
      bingewatcherTierACreativeCutoff: '<',
      bingewatcherTierBCpm: '<',
      bingewatcherTierBCreativeCutoff: '<',
      bingewatcherTierCCpm: '<',
      creativeReview: '<',
      creativeAuditStatusTargeting: '<'
    },
    template: require("../templates/accountBingewatcherFeeFields.html"),
    controllerAs: '$accountBingewatcherFeeFieldsCtrl',
    controller: function() {

      var self = this;

      this.feeButtonOptions = componentHelper.feeOptions();

      this.bingewatcherFeeTypeChange = function(val) {
        self.bingewatcherFeeType = val;
        if(val){
          self.bingewatcherFlatFee = null;
          self.bingewatcherFeeCommencementDate = null;
        }else{
          self.bingewatcherTierACpm = null;
          self.bingewatcherTierACreativeCutoff = null;
          self.bingewatcherTierBCpm = null;
          self.bingewatcherTierBCreativeCutoff = null;
          self.bingewatcherTierCCpm = null;
        }
      };

      this.attributeEnabledMappings = componentHelper.attributeEnabledMappings();

      this.creativeReviewChange = function(val) {
        self.creativeReview = val;
      };
      
      this.creativeAuditStatusTargetingChange = function(val) {
        self.creativeAuditStatusTargeting = val;
      };

    }
});

angular.module('vastdesk')
.component('freshworksSupport', {
  bindings: {
    email: '@',
    name: '@',
    accountId: '@',
    accountName: '@'
  },
  template: require("../templates/freshworksSupport.html"),
  controllerAs: '$freshworksSupportCtrl',
  controller: function() {
    var self = this;
    var bodyElement = $('body');
    var freshworksWidgetUrl = 'https://widget.freshworks.com/widgets/60000002499.js';

    var loadFreshworksJs = function() {
      if (bodyElement.find('script[src="' + freshworksWidgetUrl + '"]').length > 0) {
        return;
      }
      bodyElement.append('<script type="text/javascript" src="' + freshworksWidgetUrl + '" async="true" defer="true"></script>');
    };

    this.openSupportModal = function() {
      loadFreshworksJs();

      var fwTimer = setInterval(fwInterval, 500);

      function fwInterval() {
        if($('#freshworks-container').length > 0) {
          clearInterval(fwTimer);

          FreshworksWidget('prefill', 'ticketForm', {
            email: self.email,
            name: self.name,
            custom_fields: {
              cf_account_id: self.accountId,
              cf_account_name: self.accountName
            }
          });

          FreshworksWidget('hide', 'ticketForm', [
            'custom_fields.cf_account_id',
            'custom_fields.cf_account_name'
          ]);

          FreshworksWidget('open');
        }
      }

      fwInterval();
    };

  }
});

import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('segmentTargetingToggle',  {
    bindings: {
      formName: '@',
      formId: '@',
      segmentTargetingEnabled: '<',
      toggleClass: '@'
    },
    template: require("../templates/segmentTargetingToggle.html"),
    controllerAs: '$segmentTargetingToggleCtrl',
    controller: ['$rootScope', function($rootScope) {

      var self = this;

      this.allCustomOptions = componentHelper.allCustomOptions();

      this.setSegmentTargetingEnabled = function(value) {
        self.segmentTargetingEnabled = value;

        $('.' + self.toggleClass).toggleClass('hidden', !value);
      };

      const checkAudienceTargetingApplied = (e) => {
        const selectedOptions = document.querySelectorAll(`#${e.target.id} option:checked`);
        const selectedSegmentGroups = Array.from(selectedOptions).map((el) => el.value);
        if (selectedSegmentGroups.length > 0) {
          $rootScope.$broadcast('audienceTargetingApplied');
        }
      };

      const subscribeToAudienceSegmentChanges = () => {
        const segmentGroups = document.getElementsByClassName('segment-group-associations');
        if (segmentGroups && segmentGroups[0]) {
          segmentGroups[0].addEventListener('input', checkAudienceTargetingApplied);
        }
      };

      this.$onInit = function() {
        self.setSegmentTargetingEnabled(self.segmentTargetingEnabled);
        subscribeToAudienceSegmentChanges();
      };
    }]
});

angular.module('vastdesk').
  component('campaignDemandDuplicateModal',  {
    bindings: {
      duplicateDemandKey: '@',
      statusPath: '@'
    },
    template: require("../templates/campaignDemandDuplicateModal.html"),
    controllerAs: '$campaignDemandDuplicateModalCtrl',
    controller: ['$scope', function($scope) {

      const modal = $('#campaign_demand_duplicate_modal');
      let statusRetries = 0;
      let progressNoted = 0;

      this.dupeStatus = {
        progress: 0
      };

      const getStatus = () => {
        $.get(this.statusPath, {duplicate_demand_key: this.duplicateDemandKey}, (response) => {
          if (_.contains(['processing', 'enqueued'], response.status)) {
            if (statusRetries < 1800) { // 1 hour
              statusRetries += 1;

              if (progressNoted !== response.progress) {
                progressNoted = response.progress;
                statusRetries = 0;
              }

              setTimeout(() => getStatus(), 2000);
            }
          }

          this.dupeStatus = response;
          $scope.$apply();
        });
      };

      this.$onInit = function() {
        modal.modal();
        getStatus();
      };

    }]
});

export default {
  dataTableInitFilterListByBoolean: function(attr, table, listFilterElement, booleanFilterElement, filterCb) {
    if ($(table).attr(attr) === undefined) {
      return;
    }

    var wrapper = $(table).closest('.dataTables_wrapper');
    var list = wrapper.find(listFilterElement + ' option');

    wrapper.find(booleanFilterElement).change(function() {
      var booleanFilterValue = $(this).val();
      var listSelect = wrapper.find(listFilterElement);
      var selectValue = listSelect.val();
      listSelect.find('option').remove();

      var filteredList = list;

      if (booleanFilterValue !== "") {
        filteredList = _.filter(filteredList, function(option) {
          return filterCb(option, booleanFilterValue);
        });
      }

      if (!_.findWhere(filteredList, {value: selectValue})) {
        selectValue = '';
      }

      listSelect.append(filteredList)
        .val(selectValue)
        .change()
        .trigger('chosen:updated');
    });
  }
};
angular.module('vastdesk').
  component('accountUsersyncWeights',  {
    bindings: {},
    controllerAs: '$accountUsersyncWeightsCtrl',
    controller: function() {

      var bodySelector = $('body');

      bodySelector.on('cocoon:after-insert', '#account_usersync_weights', function() {
        $(this).find('.chosen-select').defaultChosen();
      });

    }
});

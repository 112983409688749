angular.module('vastdesk').
  component('tileSize',  {
    bindings: {
      formName: '@',
      formId: '@',
      width: '<',
      height: '<',
      dynamicTile: '<',
      readOnly: '<'
    },
    template: require("../templates/tileSize.html"),
    controllerAs: '$tileSizeCtrl',
    controller: ['$rootScope', function($rootScope) {

      var self = this;

      this.standardSizes = [
        '1200x1200',
        '1200x300',
        '300x300',
        '300x250',
        '640x360',
        '480x70',
        '728x90',
        '1560x390',
        '360x210',
        '378x216'
      ];

      this.standardSizeChange = function(standardSize) {
        if (standardSize === '') {
          self.width = null;
          self.height = null;
        }
        else {
          var split = standardSize.split('x');
          self.width = parseInt(split[0]);
          self.height = parseInt(split[1]);
        }
      };

      this.setSizeSetting = function(sizeSetting) {
        if (self.readOnly) {
          return;
        }

        self.sizeSetting = sizeSetting;
        self.dynamicTile = (sizeSetting === 'dynamic');

        $rootScope.$broadcast('dynamicTileChanged', {
          dynamicTile: self.dynamicTile
        });

        if (sizeSetting === 'standard') {
          setStandardSizeFromDimensions();

          setTimeout(function() {
            $('#supply_tag_size').trigger('chosen:updated');
          }, 0);
        }
      };

      this.sizeSettingOptions = [
        {value: 'standard', label: 'Standard'},
        {value: 'dynamic', label: 'Dynamic'},
        {value: 'custom', label: 'Custom'}
      ];

      $rootScope.$on('formatChanged', function(e, args) {
        if (args.format === 'tile') {
          self.setSizeSetting('dynamic');
        }
      });

      var setStandardSizeFromDimensions = function() {
        self.standardSize = calculatedStandardSize();
      };

      var calculatedStandardSize = function() {
        var sSize = '';

        if (self.height && self.width) {
          sSize = '' + self.width + 'x' + self.height;
        }

        return sSize;
      };

      var initSizeSettingValue = function() {
        var size = 'custom';

        if (self.dynamicTile) {
          size = 'dynamic';
        }
        else if (_.contains(self.standardSizes, calculatedStandardSize())) {
          size = 'standard';
        }

        return size;
      };

      this.$onInit = function() {
        self.sizeSetting = initSizeSettingValue();
        setStandardSizeFromDimensions();
      };

    }]
});

angular.module('vastdesk').
  component('importCsv',  {
    bindings: {
      uploadKey: '@',
      csvExample: '@',
      statusEndpoint: '@'
    },
    template: require("../templates/importCsv.html"),
    controllerAs: '$importCsvCtrl',
    controller: ['$scope', function($scope) {

      const bodySelector = $('body');
      let statusRetries = 0;
      let progressNoted = 0;

      this.showProgressBar = false;

      const uploadKeyPresent = () => this.uploadKey && this.uploadKey !== '';
      const getUploadParams = () => uploadKeyPresent() ? {upload_key: this.uploadKey} : {};

      bodySelector.on('change', 'input[name="csv_file"]', (event) => {
        this.showSubmitButton = $(event.currentTarget).val() !== '';
        this.showProgressBar = false;
        $scope.$apply();
      });

      bodySelector.on('submit', '#upload_csv_form', () => {
        this.submitButtonDisabled = true;
        $scope.$apply();
      });

      const getUploadStatus = () => {
        $.get(this.statusEndpoint, getUploadParams(), (response) => {
          if (_.contains(['processing', 'enqueued'], response.status)) {
            if (statusRetries < 600) { // 5 minutes
              statusRetries += 1;

              if (progressNoted !== response.progress) {
                progressNoted = response.progress;
                statusRetries = 0;
              }

              setTimeout(() => getUploadStatus(), 500);
            }
          }

          if (_.contains(['success', 'error'], response.status)) {
            this.uploadingTags = false;
          }
          this.uploadStatus = response;
          $scope.$apply();
        });
      };

      this.$onInit = () => {
        if (uploadKeyPresent()) {
          this.showProgressBar = true;
          this.uploadingTags = true;
          getUploadStatus();
        }
      };

    }]
});

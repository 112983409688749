(function(H) {
  H.wrap(H.Legend.prototype, 'colorizeItem', function(proceed, item, visible) {
    var color = item.color;

    if (item.options.legendColor) {
      item.color = item.options.legendColor;
    }

    proceed.apply(this, Array.prototype.slice.call(arguments, 1));
    item.color = color;
  });
}(Highcharts));

Highcharts.setOptions({
  chart: {
    style: {
      fontFamily: '"Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif'
    },
    zooming: {
      mouseWheel: false
    }
  }
});
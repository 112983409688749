angular.module('vastdesk').
  component('demandTagForwardDealIds',  {
    bindings: {
      formName: '@',
      formId: '@',
      forwardDealIdsEnabled: '<',
      readOnly: '<',
      demandClass: '@'
    },
    template: require("../templates/demandTagForwardDealIds.html"),
    controllerAs: '$demandTagForwardDealIdsCtrl',
    controller: ['$rootScope', '$scope', function($rootScope, $scope) {

      this.forwardDealIdsDisabled = function() {
        return disabledByDemandClass();
      };

      const disabledByDemandClass = () => {
        // Enabled for Direct Connect (2), Header Bidding (3), and DC Deals/OpenRTB (6)
        return !["2","3","6"].includes(this.demandClass);
      };

      $scope.$on('demandClassChanged', (e, args) => {
        this.demandClass = args.demandClass;
      });

    }]
});

import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('coppaTargeting',  {
    bindings: {
      formName: '@',
      formId: '@',
      allValues: '<',
      valueSelections: '<',
      paramRequired: '<',
      readOnly: '<',
      valueMappings: '<',
      default: '<'
    },
    template: require("../templates/coppaTargeting.html"),
    controllerAs: '$coppaTargetingCtrl',
    controller: function() {
      var self = this;

      this.allCustomOptions2 = componentHelper.allCustomOptions2();

      this.fieldLabel = function(label) {
        if (self.default) {
          return label + '_default';
        }
        else {
          return label;
        }
      };
    }
});

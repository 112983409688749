import platformUtilities from '../../modules/platform_utilities.js';

angular.module('vastdesk')
.component('directConnectVisibilityForm', {
  bindings: {
    tablePath: '@'
  },
  controller: function() {
    var self = this;
    var modal = $('#dc_accounts_modal');
    var modalTable = $('#dc_accounts_modal .datatable');
    var modalDataTable;

    modalTable.on('init.dt', function() {
      modalDataTable = $(this).DataTable();
    });

    var resetRows = function() {
      modalDataTable.rows().remove().draw();
      modal.find('.column-filters select').val('').trigger('chosen:updated').trigger('change');
    };

    var setAccount = function(button) {
      var accountTitle = button.data('accountName') + ' (' + button.data('accountId') + ')';
      modal.find('.dc_account_name').text(accountTitle);
    };

    var setPath = function(button) {
      modal.find('form').attr('action', button.data('action'));
    };

    var loadOtherAccounts = function(button) {
      var accountId = button.data('accountId');
      platformUtilities.triggerDataTablesProcessing(modalTable, true);

      $.post(self.tablePath, {account_id: accountId}, function(resp) {
        modalDataTable.rows.add(resp.data).order([[ 0, 'desc' ], [ 1, 'desc' ]]).draw();
        platformUtilities.triggerDataTablesProcessing(modalTable, false);
        modal.find('button:submit').removeAttr('disabled');
      });
    };

    modal.on('show.bs.modal', function(event) {
      var button = $(event.relatedTarget);
      setAccount(button);
      setPath(button);

      setTimeout(function () {
        resetRows();
        loadOtherAccounts(button);
      }, 200);      
    });

    modal.on('hidden.bs.modal', function(event) {
      modal.find('button:submit').attr('disabled', true);
    });
  }
});

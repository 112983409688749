import SwalHelper from '../../modules/swal_helper.js';

angular.module('vastdesk').
  component('segmentSettings',  {
    bindings: {
      persisted: '<',
      segmentType: '@',
      segmentPixelType: '@',
      segmentListType: '@',
      readOnly: '<',
      segmentTypes: '<',
      segmentPixelTypes: '<',
      userExpirationValue: '<',
      segmentListTypes: '<'
    },
    template: require("../templates/segmentSettings.html"),
    controllerAs: '$segmentSettingsCtrl',
    controller: function() {
      var self = this;
      var bodySelector = $('body');

      bodySelector.on('change', '#segment_csv_file', function(e) {
        var file = this.files[0];
        var errors = [];

        if (file.size > 524288000) {
          errors.push('File must be under 500 MB');
        }

        if (!_.contains(['text/plain', 'text/csv'], file.type)  ) {
          errors.push('Invalid file type');
        }

        if (errors.length > 0) {
          $(this).val('');
          SwalHelper.alertWarning(errors.join(', '));
        }
      });

      var setSegmentListActionsVisibility = function() {
        $('.segment-list-actions').toggleClass('hidden', self.segmentType !== 'list');
      };

      this.setValueIfNotPersisted = function(attr, val) {
        if (self.persisted || !attr) {
          return;
        }
        self[attr] = val;
        setSegmentListActionsVisibility();
      };
    }
});

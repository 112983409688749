import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('mediaFileBlockingCompanionAdsTargeting',  {
    bindings: {
      formName: '@',
      tooltipText: '@',
      readOnly: '<',
      blockingVastContainingCompanionAds: '<',
      removingCompanionAdsFromVast: '<'
    },
    template: require("../templates/mediaFileBlockingCompanionAdsTargeting.html"),
    controllerAs: '$mediaFileBlockingCompanionAdsCtrl',
    controller: function() {
      var self = this;
      var bodySelector = $('body');

      this.blockingVastContainingCompanionAdsButtonOptions = componentHelper.attributeEnabledMappings();

      this.customTooltip = function(condition) {
        return componentHelper.valueIfCondition(condition, self.tooltipText);
      };

      this.setBlockingVastContainingCompanionAds = function(val) {
        self.blockingVastContainingCompanionAds = val;
      
        if (val) {
          self.removingCompanionAdsFromVast = false;
        }
      };
    }
});


angular.module('vastdesk').
  component('campaignDemandPartnerFields',  {
    bindings: {
      demandPartnerId: '@',
      demandPartnerName: '@',
      accountManagers: '@',
      newRecord: '<',
      staticDemandPartner: '<',
      staticDemandPartnerReason: '@',
      formErrorClass: '@',
      demandPartnerOptions: '<'
    },
    template: require("../templates/campaignDemandPartnerFields.html"),
    controllerAs: '$campaignDemandPartnerFieldsCtrl',
    controller: ['$rootScope', function($rootScope) {

      var self = this;

      var toggleDemandPartnerContentVisibility = function() {
        $('.demand-partner-hidden').toggleClass('hidden', !!self.demandPartnerId);
        $('.demand-partner-shown').toggleClass('hidden', !self.demandPartnerId);
      };

      this.changeDemandPartner = function (demandPartnerId) {
        var selectedDemandPartner = $('#campaign_demand_partner_id').find('option:selected');
        self.accountManagers = selectedDemandPartner.data('accountManagers');

        toggleDemandPartnerContentVisibility();

        $rootScope.$broadcast('demandPartnerIdChange', {
          demandPartnerId: demandPartnerId
        });

        if (self.newRecord) {
          $rootScope.$broadcast('clearCampaignDemandTags');
        }
      };

      this.$onInit = function() {
        toggleDemandPartnerContentVisibility();
      };

    }]
});

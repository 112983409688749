import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('mediaFileMediaContainerTargeting',  {
    bindings: {
      formName: '@',
      tooltipText: '@',
      readOnly: '<',
      mediaContainerTargeting: '<',
      mediaContainerList: '<',
      mediaContainerSelections: '<',
      blockingUnknownMediaContainers: '<'
    },
    template: require("../templates/mediaFileMediaContainerTargeting.html"),
    controllerAs: '$mediaFileMediaContainerCtrl',
    controller: function() {
      var self = this;

      this.mediaContainerButtonOptions = componentHelper.allCustomOptions();

      this.customTooltip = function(condition) {
        return componentHelper.valueIfCondition(condition, self.tooltipText);
      };

    }
});

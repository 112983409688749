import FlightDatesHelper from '../../modules/flight_dates_helper.js';

angular.module('vastdesk').
  component('flightDates',  {
    bindings: {
      formName: '@',
      flightDatesEnabled: '<',
      flightDates: '@',
      flightDatesTimezone: '@',
      flightDatesTimezoneOptions: '<'
    },
    template: require('../templates/flightDates.html'),
    controllerAs: '$flightDatesCtrl',
    controller: ['$rootScope', '$scope', function($rootScope, $scope) {
      this.budgetFlightDatesInactive = false;

      const flightDatesHelper = new FlightDatesHelper();

      const pastEndDate = () => {
        if (!this.flightDatesEnabled) return false;

        const endDate = this.flightDates
          && this.flightDates.split(' - ')
          && this.flightDates.split(' - ')[1];
        if (!endDate) return false;

        const timezone = this.flightDatesTimezone || 'Etc/UTC';

        return flightDatesHelper.pastEndDate(endDate, timezone);
      };

      this.inactiveFlight = () => {
        return this.budgetFlightDatesInactive || pastEndDate();
      };

      $rootScope.$on('budgetFlightDatesInactiveChange', (e, args) => {
        this.budgetFlightDatesInactive = args.budgetFlightDatesInactive;

        if (args.applyScope) {
          $scope.$apply();
        }
      });

    }]
});

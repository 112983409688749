import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('advertiserDomainListTargeting',  {
    bindings: {
      formName: '@',
      formId: '@',
      allowList: '@',
      selectedOptions: '<',
      noAutoBlock: '<',
      blockingUnknownAdvertiserDomains: '<'
    },
    template: require("../templates/advertiserDomainListTargeting.html"),
    controllerAs: '$advertiserDomainListTargetingCtrl',
    controller: function() {
      var self = this;

      this.allowlistButtonOptions = componentHelper.allowlistOptions();

      this.setAllowlist = function(val) {
        if (val !== '' && self.allowList === '' && !self.noAutoBlock) {
          self.blockingUnknownAdvertiserDomains = true;
        }

        self.allowList = val;
      };

      this.shareButtonTooltip = function(val) {
        if (val !== '') {
          return 'Applies to detected advertiser domain when available, otherwise declared.';
        }
      };
      this.blockUnknownAdvertiserDomainsTooltip = function() {
        return 'Blocks all demand without a declared, detected, or overridden advertiser domain';
      };
    }
});

import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('newEndpointModal',  {
    bindings: {
      path: '@',
      supplyTagId: '@',
      supplyPartnerId: '@',
      endpointName: '@',
      endpointPartnerId: '@',
      demandPartnerOptions: '<',
      campaignOptions: '<'
    },
    template: require("../templates/newEndpointModal.html"),
    controllerAs: '$newEndpointModalCtrl',
    controller: ['$rootScope', function($rootScope) {

      var self = this;

      this.updateEndpointDemandPartner = function() {
        $("#campaign_id").val('').trigger('chosen:updated');
      };

      $('#new_endpoint_modal form').on('submit', function() {
        var path = $(this).attr('action');
        var params = _.reject($(this).serializeArray(), function(p) {
          return p.name === 'authenticity_token';
        });
        $.post(path, params, function(json) {
          $rootScope.$broadcast('addNewEndpoint', json);
        });
        $('#new_endpoint_modal').modal('hide');
        return false;
      });

    }]
});

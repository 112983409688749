import DashboardHelper from '../../modules/dashboard_helper.js';

angular.module('vastdesk').
  component('dashboardIntervalToggles',  {
    bindings: {
      intervalType: '@',
      dateRanges: '<'
    },
    template: require("../templates/dashboardIntervalToggles.html"),
    controllerAs: '$dashboardIntervalTogglesCtrl',
    controller: ['$rootScope', function($rootScope) {

      var self = this;
      var dashboardHelper = new DashboardHelper();

      this.selectedInterval = 'minute';
      this.selectedDateRange = 'Today';
      this.intervals = dashboardHelper.intervalOptions();

      this.setInterval = function(interval) {
        if (self.intervalDisabled(interval)) {
          return;
        }
        self.selectedInterval = interval;

        dashboardHelper.reflowDashboard(
          self.intervalType.toLowerCase() + 'Wrapper',
          interval + self.intervalType + 'Wrapper'
        );
      };

      var intervalDisabledByDateRange = function(interval) {
        return dashboardHelper.intervalDisabled(self.dateRanges, self.selectedDateRange, interval, 'disabled') &&
          !dashboardHelper.intervalDisabledException(interval, self.dateRanges, self.selectedDateRange, self.selectedForecastDateRange);
      };

      this.intervalDisabled = function(interval) {
        return dashboardHelper.intervalDisabledByForecastDateRange(interval, self.selectedForecastDateRange) ||
          intervalDisabledByDateRange(interval);
      };

      $rootScope.$on('dashboardDateRangeChange', function(e, args) {
        self.selectedDateRange = args.dateRange;
      });

      $rootScope.$on('forecastDashboardDateRangeChange', function(e, args) {
        self.selectedForecastDateRange = args.forecastDateRange;
      });

      $rootScope.$on('setDefaultInterval', function (e, args) {
        self.selectedDateRange = args.dateRange;

        if (self.intervalDisabled(self.selectedInterval)) {
          const defaultInterval = dashboardHelper.getDefaultInterval(self.dateRanges, self.selectedDateRange, self.selectedForecastDateRange);

          if (defaultInterval) {
            self.setInterval(defaultInterval);
          }
        }
      });

    }]
});

angular.module('vastdesk').
  component('routerThirdPartyOverride',  {
    bindings: {
      thirdPartyOverride: '<',
      hasVastOnlySupplyTag: '<'
    },
    template: require("../templates/routerThirdPartyOverride.html"),
    controllerAs: '$routerThirdPartyOverrideCtrl',
    controller: ['$rootScope', '$timeout', function($rootScope, $timeout) {

      var self = this;

      this.overrideOptions = [
        {value: true, label: 'Router'},
        {value: false, label: 'Tag'}
      ];

      this.overrideTooltip = function(override) {
        if (override) {
          return 'Will override tag setttings when request is made through router';
        }
      };

      this.setThirdPartyOverride = function(value) {
        self.thirdPartyOverride = value;
        toggleOverrideShown();
        broadcastApplyVastOnlyVendorsFieldChange();
      };

      var broadcastApplyVastOnlyVendorsFieldChange = function() {
        $rootScope.$broadcast('applyVastOnlyVendorsFieldChange', {
          routerVastOnly: self.thirdPartyOverride && self.hasVastOnlySupplyTag
        });
      };

      $rootScope.$on('hasVastOnlySupplyTagChange', function(e, args) {
        self.hasVastOnlySupplyTag = args.hasVastOnlySupplyTag;

        $timeout(function() {
          broadcastApplyVastOnlyVendorsFieldChange();
        }, 0);
      });

      var toggleOverrideShown = function() {
        $('.third-party-override-shown').toggleClass('hidden', !self.thirdPartyOverride);
      };

      this.$onInit = function() {
        toggleOverrideShown();
      };

    }]
});

import DashboardHelper from '../../modules/dashboard_helper.js';

angular.module('vastdesk')
.component('objectStatsCharts', {
  bindings: {
    path: '@',
    objectId: '@',
    objectTable: '@',
    selectedMetric: '@',
    timezone: '@',
    reportingView: '@',
    externalBidder: '@',
    openrtbSeller: '@',
    pod: '@',
    leftAxisSeries: '@',
    metrics: '=',
    bidDensity: '=?',
    bidDensityToggles: '=?',
    bidDensityOptions: '=?',
    slotOrder: '@',
    slotNumber: '@',
    magniteStreamingModal: '@',
    tile: '<',
    currency: '@',
    currencySymbol: '@',
    dateRanges: '<',
    environment: '@'
  },
  template: require("../templates/objectStatsCharts.html"),
  controllerAs: '$objectStatsChartsCtrl',
  controller: ['$scope', '$element', '$rootScope', function($scope, $element, $rootScope) {

    var self = this;
    var dashboardHelper = new DashboardHelper();
    var onInitLoad = $.Deferred();
    var reportOptions = {};

    this.dateRange = 'Today';
    this.chartType = 'performance';

    this.secondaryType = 'minute';
    this.intervals = dashboardHelper.intervalOptions();

    this.bidDensityToggleIndex = 0;

    this.setChartType = function(chartType) {
      self.chartType = chartType;

      if (chartType === 'bid_density' && !$('#object_stats_bid_density_graph').highcharts()) {
        createBidDensityChart();
      }

      reflowCharts();
    };

    this.financialChart = function() {
      var financialView = 'financial';

      if (self.externalBidder === 'true') {
        financialView = (self.openrtbSeller === 'true') ? 'revenueCombo' : 'costCombo';
      }
      else if (_.contains(['publisher', 'ctv_publisher'], self.reportingView)) {
        financialView = 'revenueCombo';
      }

      return financialView;
    };

    this.setSecondaryType = function(secondaryType) {
      if (self.intervalDisabled(secondaryType)) {
        return;
      }

      self.secondaryType = secondaryType;
      reflowCharts();
    };

    const intervalDisabledByDateRange = (interval) => {
      return dashboardHelper.intervalDisabled(self.dateRanges, self.dateRange, interval, 'object_disabled') &&
        !dashboardHelper.intervalDisabledException(interval, self.dateRanges, self.dateRange, self.forecastDateRange);
    };

    this.intervalDisabled = (interval) => {
      return dashboardHelper.intervalDisabledByForecastDateRange(interval, self.forecastDateRange) ||
        intervalDisabledByDateRange(interval);
    };

    const allIntervalsDisabled = () => {
      return _.every(self.intervals, function(intervalData, interval) {
        return self.intervalDisabled(interval);
      });
    };

    var chartIdFor = function(label) {
      return self.objectTable + self.objectId + label;
    };

    var refreshHighchart = function(chart) {
      $rootScope.$broadcast('refreshHighchart', chart);
    };

    var setChartPanelLoading = function(chart) {
      $rootScope.$broadcast('setHighchartLoading', chart);
    };

    var setDataAndRefresh = function(chart, results, forecastResults) {
      chart.data = results;
      chart.forecastData = forecastResults;
      refreshHighchart(chart);
    };

    this.updateTagCharts = function(){
      if(!!self.objectId && !!self.path) {
        setChartPanelLoading(self.charts.performanceHour);

        var params = _.extend({}, reportOptions, {
          id: self.objectId,
          date_range: self.dateRange,
          forecast_date_range: self.forecastDateRange,
          pod: self.pod,
          slot_order: self.slotOrder,
          slot_number: self.slotNumber,
          currency: self.currency
        });

        $.get(self.path, params, function(results) {
          self.charts.performanceMinute.changelogs = results.changelogs;
          self.charts.financialMinute.changelogs = results.changelogs;

          setDataAndRefresh(self.charts.performanceDay, results.day, results.forecast_day);
          setDataAndRefresh(self.charts.performanceHour, results.hour, results.forecast_hour);
          setDataAndRefresh(self.charts.performanceMinute, results.minute);
          setDataAndRefresh(self.charts.performanceFiveMinute, results.five_minute);
          setDataAndRefresh(self.charts.financialDay, results.day, results.forecast_day);
          setDataAndRefresh(self.charts.financialHour, results.hour, results.forecast_hour);
          setDataAndRefresh(self.charts.dspFinancialMinute, results.minute);
          setDataAndRefresh(self.charts.dspFinancialFiveMinute, results.five_minute);
          setDataAndRefresh(self.charts.dspFinancialHour, results.hour, results.forecast_hour);
          setDataAndRefresh(self.charts.dspFinancialDay, results.day, results.forecast_day);
          setDataAndRefresh(self.charts.financialMinute, results.minute);
          setDataAndRefresh(self.charts.financialFiveMinute, results.five_minute);
          setDataAndRefresh(self.charts.comparison, results.comparison_data);
          setDataAndRefresh(self.charts.comboFinancialDay, results.day, results.forecast_day);
          setDataAndRefresh(self.charts.comboFinancialMinute, results.minute);
          setDataAndRefresh(self.charts.comboFinancialFiveMinute, results.five_minute);
          setDataAndRefresh(self.charts.comboFinancialHour, results.hour, results.forecast_hour);
          setDataAndRefresh(self.charts.comboFinancialBidderDay, results.day, results.forecast_day);
          setDataAndRefresh(self.charts.comboFinancialBidderMinute, results.minute);
          setDataAndRefresh(self.charts.comboFinancialBidderFiveMinute, results.five_minute);
          setDataAndRefresh(self.charts.comboFinancialBidderHour, results.hour, results.forecast_hour);
        });
      }
    };

    $rootScope.$on('openMarketEnabledChange', function (e, args) {
      $.when( onInitLoad ).done(function() {
        if (args.openMarketEnabled === false && self.chartType === 'bid_density') {
          self.chartType = 'performance';
        }
      });
    });

    $rootScope.$on('reportCurrencyChange', function(e, args) {
      self.currency = args.currency;
    });

    $scope.$on('refreshTagCharts', function(e, args) {
      $.when( onInitLoad ).done(function() {
        if (args.table === self.objectTable) {
          self.dateRange = args.date_range;
          self.forecastDateRange = args.forecast_date_range;
          reportOptions = args.filterParams || {};

          if (allIntervalsDisabled()) {
            self.setSecondaryType('comparison');
          }
          else if(self.intervalDisabled(self.secondaryType))  {
            const defaultInterval = dashboardHelper.getDefaultInterval(self.dateRanges, self.dateRange, self.forecastDateRange);

            if (defaultInterval) {
              self.setSecondaryType(defaultInterval);
            }
          }
          self.updateTagCharts();
          createBidDensityChart();
        }
      });
    });

    this.selectedBidDensityToggle = function() {
      if (Array.isArray(self.bidDensityToggles)) {
        return self.bidDensityToggles[self.bidDensityToggleIndex];
      }
    };

    this.showBidDensityToggles = function() {
      return Array.isArray(self.bidDensityToggles) && self.bidDensityToggles.length > 1;
    };

    this.setBidDensityToggleIndex = function(val) {
      self.bidDensityToggleIndex = val;
      createBidDensityChart();
    };

    var createBidDensityChart = function() {
      if (!self.bidDensity) {
        return;
      }

      var selected = self.selectedBidDensityToggle();

      if (!selected) {
        return;
      }

      $element.find('#object_stats_bid_density_graph').createBidDensityChart(
        _.extend({}, selected.params, {
          slot_number: self.slotNumber,
          slot_order: self.slotOrder
        }),
        self.bidDensityOptions || {}
      );
    };

    var reflowCharts = function() {
      $element.find('.tagWrapper').css('display', 'none');

      if (self.secondaryType === 'comparison') {
        $element.find('.comparisonWrapper').css('display', 'block');
      }
      else {
        $element.find('.' + self.chartType + '_' + self.secondaryType + 'Wrapper').css('display', 'block');
      }
      $element.find('.graphChart').each(function() {
        var highchart = $(this).highcharts();
        if(highchart) highchart.reflow();
      });
    };

    $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
      reflowCharts();
    });

    this.metricChange = function() {
      refreshHighchart(self.charts.comparison);
    };

    this.$onInit = function() {
      self.charts = {
        performanceDay: {
          data: [],
          id: chartIdFor('objectPerformanceDay'),
          parent: '.ss-card'
        },
        performanceHour: {
          data: [],
          id: chartIdFor('objectPerformanceHour'),
          parent: '.ss-card'
        },
        performanceMinute: {
          data: [],
          changelogs: [],
          id: chartIdFor('objectPerformanceMinute'),
          parent: '.ss-card'
        },
        performanceFiveMinute: {
          data: [],
          changelogs: [],
          id: chartIdFor('objectPerformanceFiveMinute'),
          parent: '.ss-card'
        },
        financialDay: {
          data: [],
          id: chartIdFor('objectFinancialDay'),
          parent: '.ss-card'
        },
        financialHour: {
          data: [],
          id: chartIdFor('objectFinancialHour'),
          parent: '.ss-card'
        },
        financialMinute: {
          data: [],
          changelogs: [],
          id: chartIdFor('objectFinancialMinute'),
          parent: '.ss-card'
        },
        financialFiveMinute: {
          data: [],
          changelogs: [],
          id: chartIdFor('objectFinancialFiveMinute'),
          parent: '.ss-card'
        },
        comparison: {
          data: [],
          id: chartIdFor('objectComparison'),
          parent: '.ss-card'
        },
        dspFinancialMinute: {
          data: [],
          metrics: [
            ['revenue', 'Revenue'],
            ['total_cost', 'Total Cost'],
            ['net_margin', 'Margin (Net)']
          ],
          id: chartIdFor('objectDspFinancialMinute'),
          parent: '.ss-card'
        },
        dspFinancialFiveMinute: {
          data: [],
          metrics: [
            ['revenue', 'Revenue'],
            ['total_cost', 'Total Cost'],
            ['net_margin', 'Margin (Net)']
          ],
          id: chartIdFor('objectDspFinancialFiveMinute'),
          parent: '.ss-card'
        },
        dspFinancialHour: {
          data: [],
          metrics: [
            ['revenue', 'Revenue'],
            ['total_cost', 'Total Cost'],
            ['net_margin', 'Margin (Net)']
          ],
          id: chartIdFor('objectDspFinancialHour'),
          parent: '.ss-card'
        },
        dspFinancialDay: {
          data: [],
          metrics: [
            ['revenue', 'Revenue'],
            ['total_cost', 'Total Cost'],
            ['net_margin', 'Margin (Net)']
          ],
          id: chartIdFor('objectDspFinancialDay'),
          parent: '.ss-card'
        },
        comboFinancialDay: {
          data: [],
          metrics: [
            ['revenue', 'Revenue'],
            ['rpm', 'RPM']
          ],
          id: chartIdFor('objectComboFinancialDay'),
          parent: '.ss-card'
        },
        comboFinancialMinute: {
          data: [],
          metrics: [
            ['revenue', 'Revenue'],
            ['rpm', 'RPM']
          ],
          id: chartIdFor('objectComboFinancialMinute'),
          parent: '.ss-card'
        },
        comboFinancialFiveMinute: {
          data: [],
          metrics: [
            ['revenue', 'Revenue'],
            ['rpm', 'RPM']
          ],
          id: chartIdFor('objectComboFinancialFiveMinute'),
          parent: '.ss-card'
        },
        comboFinancialHour: {
          data: [],
          metrics: [
            ['revenue', 'Revenue'],
            ['rpm', 'RPM']
          ],
          id: chartIdFor('objectComboFinancialHour'),
          parent: '.ss-card'
        },
        comboFinancialBidderDay: {
          data: [],
          metrics: [
            ['cost', 'Cost'],
            ['cpm', 'CPM']
          ],
          id: chartIdFor('objectComboFinancialBidderDay'),
          parent: '.ss-card'
        },
        comboFinancialBidderMinute: {
          data: [],
          metrics: [
            ['cost', 'Cost'],
            ['cpm', 'CPM']
          ],
          id: chartIdFor('objectComboFinancialBidderMinute'),
          parent: '.ss-card'
        },
        comboFinancialBidderFiveMinute: {
          data: [],
          metrics: [
            ['cost', 'Cost'],
            ['cpm', 'CPM']
          ],
          id: chartIdFor('objectComboFinancialBidderFiveMinute'),
          parent: '.ss-card'
        },
        comboFinancialBidderHour: {
          data: [],
          metrics: [
            ['cost', 'Cost'],
            ['cpm', 'CPM']
          ],
          id: chartIdFor('objectComboFinancialBidderHour'),
          parent: '.ss-card'
        }
      };

      onInitLoad.resolve();
    };
  }]
});

angular.module('vastdesk').
  component('screenSetting',  {
    bindings: {
      formName: '@',
      formId: '@',
      screenSetting: '@',
      screenSettingOptions: '<',
      readOnly: '<'
    },
    template: require("../templates/screenSetting.html"),
    controllerAs: '$screenSettingCtrl',
    controller: ['$rootScope', function($rootScope) {

      var self = this;

      this.setScreenSetting = function(val) {
        if (self.readOnly) {
          return;
        }

        self.screenSetting = val;

        $rootScope.$broadcast('screenSettingChange', {
          screenSetting: self.screenSetting
        });
      };

    }]
});

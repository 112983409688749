import platformUtilities from '../modules/platform_utilities.js';

$(document).on('turbo:load', function() {
  var bodySelector = $('body');

  $(".daterange-future-right").daterangepicker({
    parentEl: ".daterange-place",
    locale: {
      format: 'MM/DD/YYYY'
    }
  });

  $(".daterange-single-empty").daterangepicker({
    singleDatePicker: true,
    parentEl: ".daterange-place",
    autoApply: true,
    autoUpdateInput: false,
    minDate: moment().endOf('day'),
    opens: "left",
    locale: {
      format: 'YYYY-MM-DD',
      cancelLabel: 'Clear'
    }
  });

  $(".daterange-single-empty").on('apply.daterangepicker', function(ev, picker) {
    $(this).val(picker.startDate.format('YYYY-MM-DD'));
  });

  $(".daterange-single-nullable").daterangepicker({
    singleDatePicker: true,
    parentEl: ".daterange-nested",
    autoApply: true,
    opens: "left",
    locale: {
      format: 'YYYY-MM-DD',
      cancelLabel: 'Clear'
    }
  });

  $(".daterange-single-nullable").on('apply.daterangepicker', function(ev, picker) {
    $(this).val(picker.startDate.format('YYYY-MM-DD'));
  });

  $(".daterange-single-nullable").on('cancel.daterangepicker', function(ev, picker) {
    $(this).val('');
  });

  $(".datehourrange-future").daterangepicker(platformUtilities.dateRangeFutureOptions);

  bodySelector.on('showCalendar.daterangepicker', '.datehourrange-future, #custom_date_range', function(ev, picker) {
    $('.datehourrange-future-wrapper .minuteselect').attr('disabled', true);
  });
});

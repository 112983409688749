angular.module('vastdesk').
  component('respectExternalTiers',  {
    bindings: {
      respectAllExternalTiers: '@',
      bidPlatform: '@',
      mappings: '<'
    },
    template: require("../templates/respectExternalTiers.html"),
    controllerAs: '$respectExternalTiersCtrl',
    controller: ['$rootScope', function($rootScope) {

      const self = this;

      const toggleCustomTierVisibility  = function() {
        $('.respect-external-tier-custom-shown').toggleClass('hidden', self.respectAllExternalTiers !== '');
      };

      const toggleExternalTiersVisibility = function () {
        $('.external-tiers').toggleClass('hidden', self.bidPlatform !== 'magnite');
      };

      this.labelTooltip = (label) => (label==='All' || label==='Custom') ? 'Magnite Streaming priority above 10 will be mapped to SpringServe tier 10' : '';

      this.setRespectAllExternalTiers = function(val) {
        self.respectAllExternalTiers = val;
        toggleCustomTierVisibility();
      };

      this.$onInit = function() {
        toggleExternalTiersVisibility();
        toggleCustomTierVisibility();
      };

      $rootScope.$on('bidPlatformChange', function(e, args) {
        self.bidPlatform = args.bidPlatform;
        toggleExternalTiersVisibility();
      });

    }]
});
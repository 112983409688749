import platformUtilities from '../../modules/platform_utilities.js';
import WaterfallHelper from '../../modules/waterfall_helper.js';
import WaterfallModalHelper from '../../modules/waterfall_modal_helper.js';

angular.module('vastdesk')
.component('creativeLineItems', {
  bindings: {
    demandTagIds: '<',
    tableId: '@',
    editable: '<',
    batchActions: '<',
    demandPartnerId: '@',
    creativeFormat: '@',
    currencyPlaceholder: '@',
    forecast: '<'
  },
  template: require("../templates/creativeLineItems.html"),
  controllerAs: '$creativeLineItemsCtrl',
  controller: ['$scope', '$rootScope', function($scope, $rootScope) {

    var self = this;
    var serverSideTable = $('#tag_results table');
    var serverSideTableId = $(serverSideTable).attr('id');
    var serverSideDataTable;
    var modal = $('#tag_modal');
    var modalTable = modal.find('table');
    var modalTableLoaded = $.Deferred();
    var selectedDemandTagIds = [];
    var bodySelector = $('body');
    var filteredRecordIds = [];
    var reportData = [];
    var waterfallHelper = new WaterfallHelper();
    var waterfallModalHelper = new WaterfallModalHelper();

    var _styleModalRows = function (){
      var statusIndex = modalTable.find("th.status").index();

      modalTable.find("tr").each(function(i, tr) {
        waterfallModalHelper.styleStatus(tr, statusIndex);
      });
    };

    modalTable.on('preXhr.dt', function(e, settings, data) {
      data.custom_data = data.custom_data || {};
      data.custom_data.selected_ids = [].concat(self.demandTagIds).concat(selectedDemandTagIds);
      data.demand_partner_id = self.demandPartnerId;
      if (self.creativeFormat) {
        data.creative_format = self.creativeFormat;
      }
    });

    modalTable.on('init.dt', function () {
      modalTableLoaded.resolve();
    });

    modalTable.on('draw.dt', function () {
      _styleModalRows();
      waterfallModalHelper.setSelectedModalCheckboxes(modalTable, selectedDemandTagIds);
      waterfallHelper.setModalCheckboxes(modalTable, self.demandTagIds);
    });

    waterfallModalHelper.openEventHandler(bodySelector, '.open-tag-modal', modal, modalTable, function() {
      selectedDemandTagIds = [];
      $('.modal-content .dataTables_filter input').attr("placeholder", "Search by tag name, or enter a comma-separated list of IDs...");
    });

    waterfallModalHelper.saveEventHandler(bodySelector, modal, modalTable, function() {
      waterfallHelper.addIds(self, 'demandTagIds', selectedDemandTagIds, serverSideDataTable);
      $scope.$apply();
      broadcastLineItemDemandTagsCountChange();
    });

    waterfallModalHelper.modalCheckEventHandler(bodySelector, 'line_item_demand_tag_ids', function(val) {
      selectedDemandTagIds.push(val);
    }, function(val) {
      selectedDemandTagIds = _.without(selectedDemandTagIds, val);
    });

    serverSideTable.on('init.dt', function() {
      serverSideDataTable = $(this).DataTable();
      waterfallHelper.addDateRangeSelect({forecast: self.forecast});

      if (Array.isArray(reportData) && reportData.length > 0) {
        setTimeout(function() {
          waterfallHelper.drawServerSideTable(serverSideDataTable, false);
        }, 0);
      }
    });

    serverSideTable.on('preXhr.dt', function (e, settings, data) {
      waterfallHelper.abortPendingAjaxRequests(serverSideTable, serverSideDataTable);

      data.demand_tag_ids = self.demandTagIds;
      data.date_range = $('#date_range').val();
      data.report_data = waterfallHelper.getDemandTagReportDataForDraw(serverSideTable, serverSideDataTable, reportData);
    });

    serverSideTable.on('xhr.dt', function(e, settings, json, xhr) {
      if (json && json.custom_data) {
        filteredRecordIds = json.custom_data.filtered_record_ids;
      }
      if (json && json.data) {
        attachReportData(json.data);
      }
    });

    var reportRowFor = function(tableRow) {
      return _.findWhere(reportData, {demand_tag_id: tableRow.DT_RowAttr['data-demand-tag-id']}) || {};
    };

    var attachReportData = function(rows) {
      _.each(rows, function(r) {
        var reportRow = reportRowFor(r);
        waterfallHelper.addReportDataToRow(r, reportRow, {currencyPlaceholder: self.currencyPlaceholder});
      });
    };

    bodySelector.on('change', '#creative_demand_partner_id', function() {
      self.demandPartnerId = $(this).val();

      var partnerPresent = self.demandPartnerId && self.demandPartnerId !== '';

      $('.partner-hidden').toggleClass('hidden', partnerPresent);
      $('.partner-shown').toggleClass('hidden', !partnerPresent);


      $.when( modalTableLoaded ).done(function() {
        $('#tag_modal #dfilter_name option:not([value=""])').hide();
        $('#tag_modal #dfilter_name option[data-dp="' + self.demandPartnerId + '"]').show();
        $('#tag_modal #dfilter_name').val('').trigger('chosen:updated');
      });

      if (self.demandTagIds.length > 0) {
        waterfallHelper.removeIds(self, 'demandTagIds', self.demandTagIds, serverSideDataTable, function() {
          $scope.$apply();
          broadcastLineItemDemandTagsCountChange();
        });
      }
      else {
        $scope.$apply();
      }
    });

    bodySelector.on('change', '#creative_creative_format_video', function() {
      self.creativeFormat = 'video';
    });

    bodySelector.on('change', '#creative_creative_format_tile', function() {
      self.creativeFormat = 'tile';
    });

    bodySelector.on("click", "#associated_tag_section a.remove-tag", function () {
      var row = waterfallHelper.rowForTableElement(this);
      var demandTagId = $(row).data('demandTagId');

      waterfallHelper.removeIds(self, 'demandTagIds', [demandTagId], serverSideDataTable, function() {
        $scope.$apply();
        broadcastLineItemDemandTagsCountChange();
      });

      return false;
    });

    var removeAllFilteredDemandTags = function() {
      self.demandTagIds = _.filter(self.demandTagIds, function(demand_tag_id) {
        return !_.contains(filteredRecordIds, parseInt(demand_tag_id));
      });
      $scope.$apply();
      waterfallHelper.drawServerSideTable(serverSideDataTable, true);
      broadcastLineItemDemandTagsCountChange();
    };

    var broadcastLineItemDemandTagsCountChange = function() {
      $rootScope.$broadcast('lineItemDemandTagsCountChange', {
        lineItemDemandTagsCount: self.demandTagIds.length
      });
    };

    bodySelector.on("click", "#associated_tag_section .remove-all", function(e){
      removeAllFilteredDemandTags();
      return false;
    });

    $rootScope.$on('updatingReportData', function(e, args) {
      if (args.table === serverSideTableId) {
        platformUtilities.triggerDataTablesProcessing(serverSideTable, true);
      }
    });

    $rootScope.$on('updatedReportData', function(e, args) {
      if (args.table === serverSideTableId) {
        reportData = args.resp.demand_tag_report;
        self.currencyPlaceholder = args.resp.currency_placeholder;
        waterfallHelper.drawServerSideTable(serverSideDataTable, false);
      }
    });

    $rootScope.$on('bulkEditBroadcast', function(e, args) {
      if (args.tableId === serverSideTableId) {
        if (!args.selectedIds || !Array.isArray(args.selectedIds)) {
          return;
        }

        var demandTagIds = _.map(args.selectedIds, function(id) {
          return parseInt(id);
        });

        if (args.id === 'remove') {
          waterfallHelper.removeIds(self, 'demandTagIds', demandTagIds, serverSideDataTable);
          broadcastLineItemDemandTagsCountChange();
        }
      }
    });

    var intervalTime = 0;
    setInterval(function () {
      intervalTime += 1;
      if(intervalTime >= 60 && document.hasFocus() && $("#tabLine_items").is(".active")){
        $rootScope.$broadcast('updateReportData', {table: serverSideTableId});
        intervalTime = 0;
      }
    }, 1000);

  }]
});

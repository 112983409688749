import SelectOptionDisabler from '../../modules/select_option_disabler.js';

angular.module('vastdesk').
  component('macroOverrides', {
    bindings: {},
    controller: function() {
      var bodySelector = $('body');

      var macroSelectOptionDisabler = new SelectOptionDisabler(
        '#macro_overrides .nested-fields:not([style*="display: none"]) .macro-param select'
      );

      var keySelectOptionDisabler = new SelectOptionDisabler(
        '#macro_overrides .nested-fields:not([style*="display: none"]) .key-id select'
      );

      var updateMacroOverrideFields = function() {
        macroSelectOptionDisabler.disableParamOptions();
        keySelectOptionDisabler.disableParamOptions();
      };

      var toggleOverrideTypeFields = function(field) {
        var parent = $(field).closest('.macro-overrides');

        setTimeout(function() {
          var overrideType = $(parent).find('.override-type-label input:checked').val();
          var keyOverrideType = (overrideType === 'custom_key');
          var keyDefinitionType = $(parent).find('.key-id select option:selected').data('definitionType');

          $(parent).find('.macro-param').toggleClass('hidden', keyOverrideType);
          $(parent).find('.key-id').toggleClass('hidden', !keyOverrideType);
          $(parent).find('.override-value').toggleClass('hidden', keyOverrideType && keyDefinitionType === 'predefined');
        }, 0);
      };

      bodySelector.on('cocoon:after-insert', '#macro_overrides', function() {
        $.each($(this).find("select[data-ajax]"), function(index, select){
          $(select).defaultTokenize();
        });

        $.each($(this).find("select:not([data-ajax]):not([data-dynamic-chosen])"), function(index, select){
          $(select).defaultChosen();
        });

        $.each($(this).find("select[data-dynamic-chosen]"), function(index, select){
          $(select).dynamicChosen();
        });

        updateMacroOverrideFields();
        toggleCoonditionIfAndVisibility();
      });

      bodySelector.on('cocoon:after-remove', '#macro_overrides', function() {
        updateMacroOverrideFields();
        toggleCoonditionIfAndVisibility();
      });

      bodySelector.on('change', '#macro_overrides .macro-param select', function(e) {
        updateMacroOverrideFields();

        var overrideValueField = $(this).closest('.macro-overrides').find('.override-value input');

        if ($(this).val() === '{{DNT}}') {
          overrideValueField.val('1').attr('readonly', true);
        }
        else {
          overrideValueField.attr('readonly', false);
        }
      });

      bodySelector.on('click', '#macro_overrides .override-type-label', function(e) {
        toggleOverrideTypeFields(this);
      });

      bodySelector.on('change', '#macro_overrides .key-id select', function(e) {
        updateMacroOverrideFields();
        toggleOverrideTypeFields(this);
      });

      var toggleCoonditionIfAndVisibility = function() {
        $.each($('.macro-overrides'), function(index, mo){
          var visibleConditions = $(mo).find('.macro-override-conditions:not([style*="display: none"])').not('.hidden');

          if (visibleConditions.length < 1) {
            return;
          }

          $(mo).find('.macro-override-conditions .if').addClass('hidden');
          $(mo).find('.macro-override-conditions .and').removeClass('hidden');

          $(visibleConditions[0]).find('.if').removeClass('hidden');
          $(visibleConditions[ visibleConditions.length - 1 ]).find('.and').addClass('hidden');
        });
      };

      var toggleDetectedFields = function(field) {
        var parent = $(field).closest('.macro-override-conditions');

        setTimeout(function() {
          var conditionType = $(parent).find('.condition-type-label input:checked').val();
          var detectedType = $(parent).find('.condition-detected-types select[name*="[detected_type]"]').val();

          $(parent).find('.condition-type-detected-sub-type').addClass('hidden', true);

          if (conditionType === 'detected') {
            $(parent).find('.condition-type-detected-sub-type.condition-type-detected_' + detectedType).removeClass('hidden', true);
          }
        }, 0);
      };

      bodySelector.on('change', '#macro_overrides .condition-detected-types select', function(e) {
        toggleDetectedFields(this);
      });

      var toggleConditionFields = function(field) {
        var parent = $(field).closest('.macro-override-conditions');

        setTimeout(function() {
          var conditionType = $(parent).find('.condition-type-label input:checked').val();

          $(parent).find('.condition-type-field').addClass('hidden', true);
          $(parent).find('.condition-type-field.condition-type-' + conditionType).removeClass('hidden', true);
        }, 0);
      };

      bodySelector.on('click', '#macro_overrides .condition-type-label', function() {
        toggleConditionFields(this);
        toggleDetectedFields(this);
      });

      setTimeout(function() {
        updateMacroOverrideFields();
        toggleCoonditionIfAndVisibility();
      }, 0);
    }
});

angular.module('vastdesk').
  component('overrideOriginalBudgets',  {
    bindings: {
      spendId: '@',
      overrideField: '@',
      override: '<',
      overrideLabel: '@',
      hideOverride: '<'
    },
    template: require("../templates/overrideOriginalBudgets.html"),
    controllerAs: '$overrideOriginalBudgetsCtrl',
    controller: ['$rootScope', '$scope', function($rootScope, $scope) {

      var self = this;

      var updateHideOverride = function() {
        self.hideOverride = $('#' + self.spendId + ' .add_form:not([style*="display: none"])').length < 1;
        $scope.$apply();
      };

      $rootScope.$on('budgetCountChange', function(e, args) {
        if (args.spendId === self.spendId) {
          updateHideOverride();
        }
      });

    }]
});

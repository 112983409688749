import WaterfallHelper from '../../modules/waterfall_helper.js';
import MagniteModalHelper from '../../modules/magnite_modal_helper.js';

angular.module('vastdesk')
.component('magniteStreamingSupplyModal', {
  bindings: {
    objectId: '@',
    summaryPath: '@',
    reportingView: '@',
    timezone: '@',
    selectedMetric: '@',
    chartPath: '@',
    leftAxisSeries: '@',
    metrics: '<',
    tierTablePath: '@',
    tierTableOrder: '@',
    tierTableHeader: '@',
    magniteStreamingDealsModalTablePath: '@',
    magniteStreamingDealsModalTableOrder: '@',
    magniteStreamingDealsModalTableHeader: '@',
    currency: '@',
    currencySymbol: '@',
    currencyPlaceholder: '@', 
    dateRanges: '<'
  },
  template: require("../templates/magniteStreamingSupplyModal.html"),
  controllerAs: '$magniteStreamingSupplyModalCtrl',
  controller: ['$scope', '$element', '$rootScope', function($scope, $element, $rootScope) {

    const self = this;
    const waterfallHelper = new WaterfallHelper();
    const magniteModalHelper = new MagniteModalHelper();
    const bodyElement = $('body');
    const reportModal = $('#magnite_streaming_supply_modal');
    const tiersTable = $('#demand_tags_magnite_streaming_tiers_modal_table');
    const magniteStreamingDealsModalTable = $('#demand_tags_magnite_streaming_deals_modal_table');
    let magniteStreamingDealsModalDatatable;
    let tiersDatatable;

    this.activeTab = '';
    this.selectedMagniteStreamingDealId = '';
    this.selectedMagniteStreamingDealName = '';
    this.magniteStreamingDeals = [];
    this.selectedSupplyTag = '';

    waterfallHelper.dateRangeize($element.find('.date-filter select'), {
      changeCb: () => {
        updateReports();
      }
    });

    this.showMagniteStreamingDealFilters = () => {
      return _.contains(['#tabModalBidDensity', '#tabModalSummary'], this.activeTab);
    };

    this.handleMagniteStreamingDealChange = (selectedDeal) => {
      if(selectedDeal === ''){
        this.selectedMagniteStreamingDealId = '';
        this.selectedMagniteStreamingDealName = '';
      } else {
        const selectedDealArray = JSON.parse(selectedDeal);
        this.selectedMagniteStreamingDealId = selectedDealArray[0];
        this.selectedMagniteStreamingDealName = selectedDealArray[1];
      }

      updateReportsWithFilters();
    };

    const updateReportsWithFilters = () => {
      createBidDensityChart();
      updateQuickstatsSummaryReport();
    };

    tiersTable.on('init.dt', function() {
      tiersDatatable = $(this).DataTable();
    });

    tiersTable.on('preXhr.dt', function (e, settings, data) {
      waterfallHelper.abortPendingAjaxRequests(tiersTable, tiersDatatable);

      data.date_range = modalDateRange().val();
      data.supply_tag_id = self.selectedSupplyTag;
      data.demand_tag_id = self.objectId;
    });

    magniteStreamingDealsModalTable.on('init.dt', function() {
      magniteStreamingDealsModalDatatable = $(this).DataTable();
    });

    magniteStreamingDealsModalTable.on('preXhr.dt', function(e, settings, data) {
      waterfallHelper.abortPendingAjaxRequests(magniteStreamingDealsModalTable, magniteStreamingDealsModalDatatable);

      data.date_range = modalDateRange().val();
      data.demand_tag_id = self.objectId;
      data.supply_tag_id = self.selectedSupplyTag;
      data.slot_number = self.slotNumber;
      data.slot_order = self.slotOrder;
    });

    magniteStreamingDealsModalTable.on('xhr.dt', function(e, settings, json, xhr) {
      const resetFilter = magniteModalHelper.filterIds(json, self, $scope, 'foreign_deal_ids', 'magniteStreamingDeals', 'selectedMagniteStreamingDeal', '#magnite_streaming_deals_select');
      if (resetFilter) {
        self.handleMagniteStreamingDealChange('');
        $scope.$apply();
      }
    });

    const updateReports = () => {
      if (magniteStreamingDealsModalDatatable.draw) {
        magniteStreamingDealsModalDatatable.draw();
      }

      if (tiersDatatable.draw) {
        tiersDatatable.draw();
      }
    };

    const updateQuickstatsSummaryReport = () => {
      $rootScope.$broadcast('updateReportData', {
        table: 'demand_tags_magnite_streaming_deals_modal_table',
        filterParams: {
          supply_tag_id: self.selectedSupplyTag,
          demand_tag_id: self.objectId,
          foreign_deal_id: self.selectedMagniteStreamingDealId,
          slot_number: self.slotNumber,
          slot_order: self.slotOrder
        }
      });
    };


    const createBidDensityChart = () => {
      $element.find('#bid_density_graph').createBidDensityChart({
        supply_tag_id: self.selectedSupplyTag,
        demand_tag_id: self.objectId,
        foreign_deal_id: self.selectedMagniteStreamingDealId,
        slot_number: self.slotNumber,
        slot_order: self.slotOrder,
        date_range: 'Today'
      }, {
        chart_title: 'Bid Density for ' + (self.selectedMagniteStreamingDealName === '' ? "All Deals" : self.selectedMagniteStreamingDealName)
      });
    };

    $element.on('shown.bs.tab', 'a[data-toggle="tab"][href="#tabModalBidDensity"]', () => {
      const highchart = $element.find('#bid_density_graph').highcharts();
      if(highchart) {
        highchart.reflow();
      }
    });

    this.slotTitle = () => {
      return waterfallHelper.slotTitle(this) || '';
    };

    this.modalTitle = () => {
      return `${this.slotTitle()}${this.objectName} (${this.selectedSupplyTag})`;
    };

    const parentDateRange = () => {
      return $('#demand_tag_supply_tags_table').parents('.dataTables_wrapper').find('#date_range');
    };

    const modalDateRange = () => {
      return $element.find('#date_range');
    };

    reportModal.on('hide.bs.modal', (e) => {
      waterfallHelper.syncDateRange(modalDateRange(), parentDateRange(), {triggerChange: true});
    });


    $element.on('shown.bs.tab', '#magniteStreamingSupplyModalTabs a[data-toggle="tab"]', function (e) {
      setActiveTab($(this));
      $scope.$apply();
    });

    const setActiveTab = (activeAnchor) => {
      this.activeTab = activeAnchor.attr('href');
    };


    const attachModalEvent = function() {
      bodyElement.on('click', '#demand_tag_supply_tags_table .modal-expand-icon.magnite-streaming-supply-hb-tag', function(e) {
        const row = $(this).parents('tr');

        self.objectName = $(this).attr('title');
        self.selectedSupplyTag = row.data('supplyTagId');
        self.slotNumber = row.data('sn');
        self.slotOrder = row.data('so');

        waterfallHelper.clearChosenSelect(self, 'selectedMagniteStreamingDealId', '#magnite_streaming_deals_select');

        setActiveTab($('#magniteStreamingSupplyModalTabs li a.active'));

        $scope.$apply();

        waterfallHelper.syncDateRange(parentDateRange(), modalDateRange());

        setTimeout(function() {
          updateReports();
          createBidDensityChart();
          updateQuickstatsSummaryReport();
          reportModal.modal();
        }, 0);

        return false;
      });
    };

    this.$onInit = function() {
      attachModalEvent();
    };

  }]
});
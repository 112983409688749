angular.module('vastdesk').
  component('userSecurityPanel',  {
    bindings: {
      omniauthProvider: '@',
      otpRequiredForLogin: '<',
      twoFactorDisableDisabled: '<',
      reset2fa: '<',
      includeReset: '<',
      userOtpRequiredForLogin: '<',
      showBackupCodes: '<',
      otpSecret: '@',
      png: '@',
      userTooltip: '@',
      email: '@',
      editable: '<'
    },
    template: require("../templates/userSecurityPanel.html"),
    controllerAs: '$userSecurityPanelCtrl',
    controller: function() {

      this.passwordInputType = 'password';
      this.disabledButtonTooltip = 'Click the pencil icon above to edit';

      this.setUpdatePassword = () => {
        if (!this.editable) {
          return;
        }

        this.updatePassword = true;
      };

      this.toggleShowPassword = () => {
        this.passwordInputType = (this.passwordInputType === 'password') ? 'text' : 'password';
      };

      this.setOtpRequiredForLogin = (value) => {
        if (!this.editable) {
          return;
        }

        if (!this.twoFactorDisableDisabled || value) {
          this.otpRequiredForLogin = value;
          this.reset2fa = false;
        }
      };

      this.reset = () => {
        if (!this.editable) {
          return;
        }

        this.reset2fa = true;
        this.otpRequiredForLogin = true;
      };

      this.showSimpleEnableButton = () => {
        return this.twoFactorDisableDisabled && !this.userOtpRequiredForLogin;
      };

      // check if user is logged in with auth0 Magnite-SSO which manages MFA
      this.loginWithMagniteSSO = function() {
        return ['auth0_db', 'auth0_google',
                'auth0_vizio_db', "auth0_vizio_google",
                'auth0_frndlytv_db', 'auth0_frndlytv_google',
                'auth0_amagi_db', 'auth0_amagi_google',
                'auth0_kidoodle_db', 'auth0_kidoodle_google',
                'auth0_goldbach_db', 'auth0_goldbach_google',
                'auth0_yuppads_db', 'auth0_yuppads_google'].includes(this.omniauthProvider);
      };

      this.pencilToolTipMessage = function() {
        if (this.loginWithMagniteSSO()) {
          return 'Click to edit 2FA.  Current password is required.';
        } else {
          return 'Click to edit password, email or 2FA.  Current password is required.'
        }
      }
    }
});

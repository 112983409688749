import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('mediaFileMimeTypeTargeting',  {
    bindings: {
      formName: '@',
      tooltipText: '@',
      readOnly: '<',
      mimeTypeTargeting: '<',
      mimeTypeList: '<',
      mimeTypeSelections: '<',
      blockingUnknownMimeTypes: '<'
    },
    template: require("../templates/mediaFileMimeTypeTargeting.html"),
    controllerAs: '$mediaFileMimeTypeCtrl',
    controller: function() {
      var self = this;

      this.mimeTypeButtonOptions = componentHelper.allCustomOptions();

      this.customTooltip = function(condition) {
        return componentHelper.valueIfCondition(condition, self.tooltipText);
      };

    }
});

$.widget( "custom.groupAutocomplete", $.ui.autocomplete, {
  _create: function() {
    this._super();
    this.widget().menu( "option", "items", "> :not(.ui-autocomplete-group)" );
  },
  _renderMenu: function( ul, items ) {
    var that = this,
    currentGroup = "";
    $.each( items, function( index, item ) {
      var li;
      if ( item.group != currentGroup ) {
        ul.append( "<li class='ui-autocomplete-group'>" + item.group + "</li>" );
        currentGroup = item.group;
      }
      li = that._renderItemData( ul, item );
      if ( item.group ) {
        li.attr( "aria-label", item.group + " : " + item.label );
      }
      if ( item.class ) {
        $(li).addClass( item.class );
      }
    });
  }
});
$(document).on('turbo:load', function() {
  $('#universal_search_input').groupAutocomplete({
    minLength: 1,
    source: function( request, response ) {
      $.ajax({
        url: $('#universal_search_input').data('url') || '/universal_search',
        data: {
          q: request.term
        },
        dataType: 'json',
        xhrFields: {
          withCredentials: true
       }
      }).done(function( data ) {
        if (data.length === 0) {
          data.push({
            group: '',
            label: 'No results match ' + request.term,
            value:  request.term,
            class: 'no-result'
          });
        }

        response($.map( data, function( item ) {
          return {
            group: item.group,
            label: item.label,
            value: item.value,
            class: item.class
          };
        }));
      });
    },
    select: function(e, ui){
      var item = ui.item;
      var itemType = item.group.toLowerCase();
      var itemId = item.value;
      var label = item.label;
      var path;
      if (label.toLowerCase().indexOf("no results") >= 0) {
        event.preventDefault();
        return false;
      }
      if(itemType == "supply" || itemType == "demand"){
        path = "/" + itemType + "/" + itemId;
      } else if(itemType.match("advertiser domain")){
        path = "/settings/advertiser_domain_lists/" + itemId;
      } else if(itemType.match("domain")){
        path = "/settings/lists/" + itemId;
      } else if(itemType.match("app name")){
        path = "/settings/app_name_lists/" + itemId;
      } else if(itemType.match("app bundle")){
        path = "/settings/app_bundle_lists/" + itemId;
      } else if (itemType === "campaign") {
        path = "/demand/campaigns/" + itemId;
      } else if (itemType === "pod schedule") {
        path = "/supply/pod_schedules/" + itemId;
      } else if (itemType === "supply partner") {
        path = "/supply/partners/" + itemId;
      } else if (itemType === "supply router") {
        path = "/supply/routers/" + itemId;
      } else if (itemType === "demand partner") {
        path = "/demand/partners/" + itemId;
      } else if (itemType == 'segments') {
        path = "/settings/segments/" + itemId;
      } else if (itemType == 'partner segments') {
        path = "/settings/partner_segments/" + itemId;
      } else if (itemType == 'global fcap pixels') {
        path = "/settings/global_fcap_pixels/" + itemId;
      } else if (itemType == 'deal id lists') {
        path = "/settings/deal_id_lists/" + itemId;
      } else if (itemType == 'publisher id lists') {
        path = "/settings/publisher_id_lists/" + itemId;
      } else if (itemType == 'creative') {
        path = "/creatives/" + itemId;
      } else if (itemType == 'bid modifiers') {
        path = "/bid_modifiers/" + itemId;
      } else if (itemType == 'delivery modifiers') {
        path = "/delivery_modifiers/" + itemId;
      } else if (itemType == 'hb bid maps') {
        path = "/settings/bid_maps/" + itemId;
      } else if (itemType == 'country lists') {
        path = "/settings/country_lists/" + itemId;
      } else if (itemType == 'region lists') {
        path = "/settings/region_lists/" + itemId;
      } else if (itemType == 'city lists') {
        path = "/settings/city_lists/" + itemId;
      } else if (itemType == 'dma lists') {
        path = "/settings/dma_lists/" + itemId;
      } else if (itemType == 'postal code lists') {
        path = "/settings/postal_code_lists/" + itemId;
      }
      if (path) {
        if ( $('body').data('turbo') === false ) {
          window.location.href = path;
        }
        else {
          Turbo.visit(path);
        }
      }
      $(this).val(item.label);
      return false;
    }
  });
});

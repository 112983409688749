angular.module('vastdesk').
  component('bridVideoPlayer',  {
    bindings: {
      playerId: '@',
      videoUrl: '@',
      imageUrl: '@'
    },
    template: '<div class="video-player" id="{{$bridVideoPlayerCtrl.playerId}}"></div>',
    controllerAs: '$bridVideoPlayerCtrl',
    controller: ['$rootScope', '$element', function($rootScope, $element) {

      var self = this;

      var initPlayer = function() {
        var playerConfig = {
          "id": 8272,
          "playerId": 20206,
          "Skin": {
            "id": 1
          },
          "video": {
            "image": self.imageUrl,
            "source": {
              "src": self.videoUrl
            }
          }
        };

        Brid.forceConfigLoad = true;

        $bp(self.playerId, playerConfig);
      };

      $rootScope.$on('bridVideoChange', function(e, args) {
        if (args.playerId === self.playerId) {
          self.videoUrl = args.videoUrl;
          self.imageUrl = args.imageUrl;

          $bp(self.playerId).destroy();

          $element.append('<div class="video-player" id="' + self.playerId + '"></div>');

          setTimeout(function() {
            initPlayer();
          }, 0);
        }
      });

      this.$onInit = function() {
        setTimeout(function() {
          initPlayer();

          $('a[data-toggle="tab"][href="#tabSettings"]').on('shown.bs.tab', function (e) {
            $bp(self.playerId).onResizeFunc();
          });
        }, 0);
      };

    }]
});

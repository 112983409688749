import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('updateNamePersisted', {
    bindings: {
      persisted: '@',
      formName: '@',
      name: '@',
      disabled: '@',
      errors: '<',
      objectName: '@'
    },
    template: require("../templates/updateNamePersisted.html"),
    controllerAs: '$updateNamePersistedCtrl',
    controller: function() {

      var self = this;

      var bodyElement = $('body');

      var stripClassFromName = function() {
        if(self.formName === null) {
          return "";
        }
        var class_name = (self.objectName || self.formName).replace(/_/g, ' ');

        if (class_name === 'buying strategy'){
          class_name = 'connected supply';
        }
        else if (class_name === 'spring serve deal') {
          return 'SpringServe Deal';
        }
        else if (class_name === 'selling strategy') {
          return 'Deal ID';
        }
        else if (class_name === 'dma list') {
          return 'DMA List';
        }
        else if (class_name === 'hb bid map') {
          return 'Bid Map';
        }
        else if (class_name === 'white label') {
          return 'Private Label';
        }
        return class_name.replace(/(^| )(\w)/g, function(x) {
          return x.toUpperCase();
        });
      };

      this.updateName = function() {
        var defaultName = (self.persisted === 'true') ? "" : "New " + stripClassFromName();
        $(".js-update-name").text(self.name || defaultName);
      };

      var updateNameStyle = function(active_value) {
        if (active_value === "false") {
          $(".js-update-name").addClass('italic');
        }
        else if (active_value === "true") {
          $(".js-update-name").removeClass('italic');
        }
      };

      this.$onInit = function() {
        bodyElement.on('change', 'input[name="' + self.formName + '[is_active]"], input[name="' + self.formName + '[active]"]', function() {
          updateNameStyle($(this).val());
        });
        self.updateName();
        updateNameStyle($('input[name="' + self.formName + '[is_active]"]:checked, input[name="' + self.formName + '[active]"]:checked').val());
      };

      this.formHasError = componentHelper.formHasError;

    }
  });

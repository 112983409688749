import platformUtilities from '../../modules/platform_utilities.js';
import WaterfallHelper from '../../modules/waterfall_helper.js';
import WaterfallConverter from '../../modules/waterfall_converter.js';
import SwalHelper from '../../modules/swal_helper.js';

angular.module('vastdesk').controller('SupplyTagServerSideCampaignTableController', ['$scope', '$timeout', '$rootScope', function ($scope, $timeout, $rootScope) {

  $scope.selectedCampaignBulkActionIds = [];
  $scope.campaignBulkActionDisabledConditions = {
    slotPositioningDisabled: true
  };
  var serverSideTable = $('#campaign_results table');
  var serverSideTableId = $(serverSideTable).attr('id');
  var serverSideDataTable;
  var modalTable = $('#campaign_modal').find('table');
  var bodySelector = $('body');
  var filteredRecordIds = [];
  var reportData = [];
  var demandPartnerIdsInWaterfall = [];
  var serverSideTableLoaded = false;
  var waterfallHelper = new WaterfallHelper();
  var waterfallConverter = new WaterfallConverter();

  var setCampaignTiersParams = function() {
    return _.chain($scope.setCampaignTiers)
            .map(function(t) {
              return _.pick(t, 'campaign_id', 'tier', 'slot_order', 'slot_number');
            })
            .value();
  };

  var getReportDataForDraw = function() {
    var sortMetric = waterfallHelper.getReportMetricFromHeaders(serverSideTable, serverSideDataTable);
    var campaignIds = currentCampaignIds();

    return _.chain(reportData)
            .filter(function(reportRow) {
              return _.contains(campaignIds, reportRow.campaign_id);
            })
            .map(function(reportRow) {
              var result = {
                campaign_id: reportRow.campaign_id,
                rpm: waterfallHelper.extractMetricFromReportString(reportRow, 'rpm')
              };

              if (sortMetric && !_.contains(['', 'rpm'], sortMetric)) {
                result.sort_metric = waterfallHelper.extractMetricFromReportString(reportRow, sortMetric);
              }

              return result;
            })
            .value();
  };

  serverSideTable.on('init.dt', function() {
    serverSideDataTable = $(this).DataTable();
    waterfallHelper.addDateRangeSelect({
      section: '#associated_campaign_section',
      forecast: $scope.forecast
    });

    if (Array.isArray(reportData) && reportData.length > 0) {
      setTimeout(function() {
        waterfallHelper.drawServerSideTable(serverSideDataTable, false);
      }, 0);
    }

    if ($scope.isNewRecord) {
      if ($scope.openMarketEnabled) {
        $scope.setOpenMarketEnabled(true);
        setTimeout(function() {
          addOpenMarketDemand();
        }, 0);
      }
    }
  });

  serverSideTable.on('preXhr.dt', function (e, settings, data) {
    waterfallHelper.abortPendingAjaxRequests(serverSideTable, serverSideDataTable);

    data.set_campaign_tiers = setCampaignTiersParams();
    data.rate = $scope.rate;
    data.openrtb_floor = $scope.openrtbFloor;
    data.supply_partner_id = $scope.supplyPartnerId;
    data.optimization_active = $scope.optimizationActive;
    data.optimization_mode = $scope.optimizationMode;
    data.date_range = $('#date_range').val();
    data.report_data = getReportDataForDraw();
    data.revenue_model_type = $scope.revenueModelType;
    data.object_format = waterfallHelper.objectFormat();
    data.custom_data = data.custom_data || {};
    data.custom_data.selected_ids = $scope.selectedCampaignBulkActionIds;
    data.custom_data.all_slots = $scope.allSlots();
    data.custom_data.current_slot_order = $scope.currentSlotOrder();
    data.custom_data.current_slot_number = $scope.currentSlotNumber();
    data.custom_data.valid_slot_orders = $scope.validSlotOrders();
    data.custom_data.valid_slot_numbers = $scope.validSlotNumbers();
    data.custom_data.supply_tag = $scope.currentSupplyTagFields();
  });

  serverSideTable.on('xhr.dt', function(e, settings, json, xhr) {
    if (json && json.custom_data && json.custom_data) {
      filteredRecordIds = json.custom_data.filtered_record_ids;
      serverSideTableLoaded = true;
      demandPartnerIdsInWaterfall = json.custom_data.demand_partner_ids;
      setParentScopeVar('hasHbDemand', json.custom_data.has_hb_demand, true);
      waterfallHelper.filterPartners(serverSideTable, serverSideTableLoaded, demandPartnerIdsInWaterfall);
    }
    if (json && json.data) {
      attachReportData(json.data);
    }
  });

  serverSideTable.on('init.filter.ss.dt', function(e, filterId) {
    if (filterId === 'dfilter_partner') {
      waterfallHelper.filterPartners(serverSideTable, serverSideTableLoaded, demandPartnerIdsInWaterfall);
    }
  });

  serverSideTable.on('draw.dt', function() {
    waterfallHelper.disableAllSlotsFields(this, $scope.allSlots());
    waterfallHelper.setRowSpans(this);
  });

  var reportRowFor = function(tableRow) {
    var matchConditions = waterfallHelper.reportRowMatchConditions({
        campaign_id: tableRow.DT_RowAttr['data-campaign-id']
      },
      $scope.slotPositioningOrder(),
      $scope.slotPositioningNumber(),
      tableRow
    );

    return _.findWhere(reportData, matchConditions) || {};
  };

  var attachReportData = function(rows) {
    _.each(rows, function(r) {
      var reportRow = reportRowFor(r);

      waterfallHelper.addReportDataToRow(r, reportRow, {
        currencyPlaceholder: $scope.campaignCurrencyPlaceholder
      });
    });
  };

  $('#campaign_modal').on('show.bs.modal', function () {
    $('#campaign_modal .modal-title').text('Add Campaigns' + $scope.modalTitleSuffix());
  });

  modalTable.on('preXhr.dt', function (e, settings, data) {
    data.custom_data = data.custom_data || {};
    data.custom_data.supply_tag = $scope.currentSupplyTagFields();
    data.selected_ids = [].concat(currentCampaignIds()).concat($scope.selectedCampaignIds);
  });

  modalTable.on('draw.dt', function () {
    waterfallHelper.setModalCheckboxes(modalTable, currentCampaignIds());
  });

  bodySelector.on("change", "[name*='[rate]'], [name*='[rate_currency]']", function () {
    waterfallHelper.drawServerSideTable(serverSideDataTable, false);
  });

  bodySelector.on('mousedown', 'td select.ct-t', function(e) {
    if ( $(this).attr('readonly') === 'readonly' ) {
      e.preventDefault();
      this.blur();
      window.focus();
    }
  });

  bodySelector.on('change', 'td .ct-t', function () {
    handleTierChange(this);
  });

  bodySelector.on("click", "form.tag-form a.remove-campaign", function () {
    var row = waterfallHelper.rowForTableElement(this);
    var campaignId = dataAttributeForRow(row, 'campaignId');
    var allSlots = parseInt(campaignId) === $scope.openMarketCampaignId;

    removeSetCampaignTiers([campaignId], true, false, {
      allSlots: allSlots,
      slotOrder: dataAttributeForRow(row, 'so'),
      slotNumber: dataAttributeForRow(row, 'sn')
    });

    return false;
  });

  bodySelector.on("click", "#associated_campaign_section .remove-all", function(e){
    removeAllFilteredSetCampaignTiers();
    return false;
  });

  var setParentScopeVar = function(variable, val, applyScope) {
    $rootScope.$broadcast('updateSupplyTagFormControllerVar', {
      variable: variable,
      val: val,
      applyScope: applyScope
    });
  };

  var removeSetCampaignTiers = function(campaignIds, applyScope, excludeOm, slotOptions) {
    var startingLength = $scope.setCampaignTiers.length;

    if (_.contains(campaignIds, $scope.openMarketCampaignId)) {
      if (excludeOm) {
        campaignIds = _.without(campaignIds, $scope.openMarketCampaignId);
      }
      else {
        setParentScopeVar('openMarketEnabled', false, applyScope);
      }
    }

    $scope.setCampaignTiers = _.reject($scope.setCampaignTiers, function(sct) {
      return _.contains(campaignIds, parseInt(sct.campaign_id)) && (slotOptions.allSlots || waterfallHelper.inSlot(sct, slotOptions.slotOrder, slotOptions.slotNumber));
    });

    var endingLength = $scope.setCampaignTiers.length;
    var tagsRemoved = startingLength - endingLength;

    if (tagsRemoved < 1) {
      return;
    }

    if (applyScope) {
      $scope.$apply();
    }

    waterfallHelper.drawServerSideTableAfterRemoval(serverSideDataTable, tagsRemoved);
  };

  var isOpenMarketCampaign = function(sct) {
    return parseInt(sct.campaign_id) === parseInt($scope.openMarketCampaignId);
  };

  var removeAllFilteredSetCampaignTiers = function() {
    $scope.setCampaignTiers = _.filter($scope.setCampaignTiers, function(sct) {
      return isOpenMarketCampaign(sct) || !$scope.inCurrentSlot(sct) || !_.contains(filteredRecordIds, parseInt(sct.campaign_id));
    });
    $scope.$apply();
    waterfallHelper.drawServerSideTable(serverSideDataTable, true);
  };

  var dataAttributeForRow = function(row, attribute) {
    return $(row).data(attribute);
  };

  var campaignTierWithCampaignIdAndSlot = function(campaignTiers, id, slotOrder, slotNumber) {
    return _.find(campaignTiers, function(sct) {
      return parseInt(sct.campaign_id) === parseInt(id) && waterfallHelper.inSlot(sct, slotOrder, slotNumber);
    });
  };

  var setCampaignTierFor = function(row) {
    var id = dataAttributeForRow(row, 'campaignId');
    var slotOrder = dataAttributeForRow(row, 'so');
    var slotNumber = dataAttributeForRow(row, 'sn');
    return campaignTierWithCampaignIdAndSlot($scope.setCampaignTiers, id, slotOrder, slotNumber);
  };

  var updateSetCampaignTier = function(row, attributes, draw) {
    var sct = setCampaignTierFor(row);

    if (sct) {
      sct = _.extend(sct, attributes);
      $scope.$apply();

      if (draw) {
        waterfallHelper.drawServerSideTable(serverSideDataTable, false);
      }
    }
  };

  var updateSetCampaignTierFromElement = function(element, attribute, draw) {
    var row = waterfallHelper.rowForTableElement(element);
    var attributes = {};
    attributes[attribute] = waterfallHelper.valueForElement(element, attribute);
    updateSetCampaignTier(row, attributes, draw);
  };

  var handleTierChange = function(element) {
    updateSetCampaignTierFromElement(element, 'tier', true);
  };

  var handleBulkChange = function(campaignIds, attribute, value) {
    _.each($scope.setCampaignTiers, function(sct) {
      if (_.contains(campaignIds, parseInt(sct.campaign_id)) && $scope.inCurrentSlot(sct)) {
        sct[attribute] = value;
      }
    });

    $timeout(function() {
      $scope.$apply();
    });

    waterfallHelper.drawServerSideTable(serverSideDataTable, false);
  };

  var tagHasAnyOpenMarketDemand = function() {
    return !!_.find($scope.setCampaignTiers, function(sct) {
      return isOpenMarketCampaign(sct);
    });
  };

  var addOpenMarketDemand = function() {
    if ( !$scope.openMarketCampaignId || $scope.openMarketCampaignId === -1  || !$scope.openMarketEnabled ) {
      return;
    }

    if (!tagHasAnyOpenMarketDemand()) {
      setParentScopeVar('autoAddExternalBidders', true);
    }

    _.each($scope.slotLists(), function(list) {
      if (campaignTierWithCampaignIdAndSlot($scope.setCampaignTiers, $scope.openMarketCampaignId, list[0], list[1])) {
        return;
      }

      $scope.setCampaignTiers.push({
        campaign_id: $scope.openMarketCampaignId,
        tier: $scope.defaultOpenMarketTier || 0,
        slot_order: list[0],
        slot_number: list[1]
      });
    });

    waterfallHelper.drawServerSideTable(serverSideDataTable, false);
  };

  var removeOpenMarketDemand = function() {
    if (!tagHasAnyOpenMarketDemand()) {
      return;
    }
    removeSetCampaignTiers([$scope.openMarketCampaignId], false, false, {allSlots: true});
  };

  var currentCampaignIds = function() {
    var setCampaignTiersInCurrentSlot = _.filter($scope.setCampaignTiers, function(sct) {
      return $scope.inCurrentSlot(sct);
    });

    return waterfallHelper.extractIdsFromSetList(setCampaignTiersInCurrentSlot, 'campaign_id');
  };

  var addSetCampaignTiers = function(resp, options = {}) {
    if (!resp.rows) {
      return;
    }

    var currentIds = currentCampaignIds();

    var validNewCampaignTiers = _.reject(resp.rows, function(ct) {
      return _.contains(currentIds, parseInt(ct.campaign_id)) && $scope.inCurrentSlot(ct);
    });

    var copiedOpenMarketRow = campaignTierWithCampaignIdAndSlot(validNewCampaignTiers, $scope.openMarketCampaignId, $scope.currentSlotOrder(), $scope.currentSlotNumber());

    if (copiedOpenMarketRow) {
      validNewCampaignTiers = _.without(validNewCampaignTiers, copiedOpenMarketRow);
    }

    $scope.setCampaignTiers = $scope.setCampaignTiers.concat(validNewCampaignTiers);
    $scope.$apply();
    waterfallHelper.drawServerSideTable(serverSideDataTable, false);

    if (copiedOpenMarketRow) {
      SwalHelper.confirmWarning(
        {
          title: 'Do you want to enable PC - Open Market Demand?',
          cancelButtonText: 'No',
          icon: 'question'
        },
        function() {
          setParentScopeVar('openMarketEnabled', true, true);

          $.get('/supply/open_market_external_bidders', {supply_tag_id: options.copyingSupplyTagId}, function (json) {
            setParentScopeVar('setOpenMarketBidders', json, true);
          });

          addOpenMarketDemand();
        }
      );
    }
  };

  $scope.setCampaignIdUniqIdFor = function(setCampaignTier) {
     return setCampaignTier.campaign_id + '-' + setCampaignTier.slot_order + '-' + setCampaignTier.slot_number;
  };

  $scope.$on('clearSelectedWaterfallBulkActionIds', function(e, args) {
    $scope.selectedCampaignBulkActionIds = [];
  });

  $scope.$on('updatingReportData', function(e, args) {
    if (args.table === serverSideTableId) {
      platformUtilities.triggerDataTablesProcessing(serverSideTable, true);
    }
  });

  $scope.$on('updatedReportData', function(e, args) {
    if (args.table === serverSideTableId) {
      reportData = args.resp.campaign_report;
      $scope.campaignCurrencyPlaceholder = args.resp.currency_placeholder;

      waterfallHelper.drawServerSideTable(serverSideDataTable, false);
    }
  });

  $scope.$on('campaignModalSave', function(e, args) {
    var path = $('#campaign_results').data('ssTableRowsPath');
    var params = {
      "object_ids": args.selectedIds,
      "slot_order": $scope.currentSlotOrder(),
      "slot_number": $scope.currentSlotNumber()
    };

    $.post(path, params, function(resp) {
      addSetCampaignTiers(resp);
    });
  });

  $rootScope.$on('openMarketEnabledChange', function (e, args) {
    if (args.openMarketEnabled === true) {
      addOpenMarketDemand();
    }
    else if (args.openMarketEnabled === false) {
      removeOpenMarketDemand();
    }
  });

  $rootScope.$on('formatChanged', function(e, args) {
    setTimeout(function() {
      waterfallHelper.drawServerSideTable(serverSideDataTable, false);
    }, 0);
  });

  $scope.$on('numAdSlotsChange', function(e, args) {
    addSpecialObjects(args);
  });

  $scope.$on('drawServerSideTable', function(e, args) {
    if (args.tableId === serverSideTableId) {
      waterfallHelper.drawServerSideTable(serverSideDataTable, false);
    }
  });

  $scope.$on('wfSlotFieldChanged', function(e, args) {
    addSpecialObjects(args);
    $rootScope.$broadcast('updateReportData', {table: serverSideTableId});
    waterfallHelper.drawServerSideTable(serverSideDataTable, false);
  });

  $scope.$on('slotPositioningChange', function(e, args) {
    waterfallConverter.convert($scope, 'setCampaignTiers', args.slotPositioningWas, args.slotPositioning, args.maxNumAdSlots);
    addSpecialObjects(args);
    waterfallHelper.drawServerSideTable(serverSideDataTable, false);
    setSlotOrderBulkActionDisabled(args.slotPositioning);
  });

  var addSpecialObjects = function(args) {
    if (args.openMarketEnabled === true) {
      addOpenMarketDemand();
    }
  };

  var bulkRemoveDemand = function(ids) {
    removeSetCampaignTiers(ids, false, true, {
      slotOrder: $scope.currentSlotOrder(),
      slotNumber: $scope.currentSlotNumber()
    });
  };

  $scope.$on('bulkEditBroadcast', function(e, args) {
    if (args.tableId === serverSideTableId) {
      if (!args.selectedIds || !Array.isArray(args.selectedIds)) {
        return;
      }

      var campaignIds = _.map(args.selectedIds, function(id) {
        return parseInt(id);
      });

      if (args.id === 'remove') {
        bulkRemoveDemand(campaignIds);
      }
      else if (args.id === 'tier') {
        handleBulkChange(campaignIds, 'tier', parseInt(args.value));
      }
      else if (args.id === 'slot') {
        if (_.contains([$scope.currentSlotOrder(), $scope.currentSlotOrder()], args.value)) {
          return;
        }

        var slotAttribute = ($scope.slotPositioning === 'number') ? 'slot_number' : 'slot_order';

        var campaignIdsAlreadyInNewSlot = _.chain($scope.setCampaignTiers).filter(function(sct){
          return _.contains(campaignIds, parseInt(sct.campaign_id)) && sct[slotAttribute] == args.value;
        }).map(function(sct){
          return sct.campaign_id;
        }).value();

        campaignIds = _.difference(campaignIds, campaignIdsAlreadyInNewSlot);

        handleBulkChange(campaignIds, slotAttribute, args.value);
        bulkRemoveDemand(campaignIdsAlreadyInNewSlot);
      }
    }
  });

  bodySelector.on('submit', '#copy_form', function(){
    var copyForm = $(this);
    var wfManagement = copyForm.find('#copy_waterfall_management').val();

    if (wfManagement !== 'campaign') {
      return;
    }

    var path = copyForm.data('campaignCopyAction');
    var supplyTagId = copyForm.find('#copy_supply_tag_id').val();
    var copyFromSlotOrder = copyForm.find('input[name="copy_from_slot_order"]:checked').val();
    var copyFromSlotNumber = copyForm.find('input[name="copy_from_slot_number"]:checked').val();

    var params = _.extend({}, $scope.currentSupplyTagFields(), {
      'supply_tag_id': supplyTagId,
      'campaign_ids': currentCampaignIds(),
      'current_supply_tag_id': $scope.supplyTagId,
      "supply_partner_id": $scope.supplyPartnerId,
      "slot_order": $scope.currentSlotOrder(),
      "slot_number": $scope.currentSlotNumber(),
      "copy_from_slot_order": copyFromSlotOrder,
      "copy_from_slot_number": copyFromSlotNumber
    });

    $.post(path, params, function(resp) {
      addSetCampaignTiers(resp, {
        copyingSupplyTagId: supplyTagId
      });
    });

    $('#copy_supply_tag_id').val('');
    $('#copy_supply_tag_name').val('');
    $('#copy_waterfall_management').val('');
    $('#copy_save').attr('disabled', true);
    $('#copy_modal').modal('hide');
    return false;
  });

  var setSlotOrderBulkActionDisabled = function(slotPositioning) {
    $scope.campaignBulkActionDisabledConditions.slotPositioningDisabled = !slotPositioning;
  };

  bodySelector.on('submit', '.tag-form', function() {
    waterfallConverter.rejectInvalidSlotOrders($scope, 'setCampaignTiers', $scope.slotPositioningOrder(), $scope.validSlotOrders());
    waterfallConverter.rejectInvalidSlotNumbers($scope, 'setCampaignTiers', $scope.slotPositioningNumber(), $scope.validSlotNumbers());
    $scope.$apply();
  });

  $scope.$watchCollection('selectedCampaignBulkActionIds', function (new_val, old_val) {
    if (!Array.isArray(new_val)) {
      return;
    }
    setSlotOrderBulkActionDisabled($scope.slotPositioning);
  });

  var intervalTime = 0;
  setInterval(function () {
    intervalTime += 1;
    if (intervalTime >= 60 && document.hasFocus() && $("#tabTags").is(".active") && !$('#omeb_modal').is(':visible')) {
      $rootScope.$broadcast('updateReportData', {table: serverSideTableId});
      intervalTime = 0;
    }
  }, 1000);

}]);

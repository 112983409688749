import SelectOptionDisabler from '../../modules/select_option_disabler.js';

angular.module('vastdesk')
.component('groupedAssociations', {
  bindings: {
    keyValues: '<',
    inventoryGroups: '<',
    dspSubAccount: '<',
    addGroupOnInit: '<'
  },
  controllerAs: '$groupedAssociationsCtrl',
  controller: ['$rootScope', '$scope', '$element', function($rootScope, $scope, $element) {

    var self = this;
    var bodySelector = $('body');
    var inventoryObjectOptionDisabler;

    $element.on('cocoon:after-insert', function(e) {
      var group = $(e.target).closest('.associationGroup');
      initChosenSelects(group);
      setGroups();
      $scope.$broadcast('filterKpiMetricOptions');
    });

    $element.on('cocoon:after-remove', function(e) {
      if ($(e.target).closest('.associationGroup').find('div:not(.newgroup) .add_form:not([style*="display: none"])').not('.hidden').length === 0) {
        $(e.target).closest('.associationGroup').addClass('hidden');
      }
      setGroups();
      setEnabled();
    });

    $element.on('click', '.addgroup', function(e) {
      var groupName = visibleGroupDivs().length + 1;
      var newgroup = cloneGroup(e.target, groupName);
      newgroup.first('a.remove_fields').trigger('click');
      newgroup.find('a.add_fields').trigger('click');
      initChosenSelects(newgroup);
      setGroups();

      if (self.inventoryGroups && self.dspSubAccount) {
        setTimeout(() => {
          newgroup.find('select[name*="[inventory_object]"]').val('DealIdList').trigger('chosen:updated').trigger('change');
        }, 0);
      }
    });

    $element.on('click', '.delgroup', function(e) {
      $(e.target).closest('.associationGroup').find('.remove_fields').trigger('click');
      $(e.target).closest('.associationGroup').addClass('hidden');
    });

    $element.on('change', '.group select[name*="[metric]"]', function() {
      var operatorSpan = $(this).closest('.add_form').find('.group-operator');

      if (operatorSpan) {
        var operator = $(this).find('option:selected').data('operator') || '$ge;';
        operatorSpan.html(operator);
      }
    });

    $element.find('.group select[name*="[metric]"]').trigger('change');

    $element.on('click', '.groupedAssociationButtons .btn-true', function() {
      setTimeout(function() {
        if (visibleGroupDivs().length === 0) {
          $element.find('.addgroup').trigger('click');
        }
      }, 100);
    });

    var cloneGroup = function(target, groupName) {
      return $element.find('.newgroup.hidden').clone()
                                  .removeClass('hidden newgroup')
                                  .addClass('group' + groupName)
                                  .insertBefore($(target).closest('.addgroup'));
    };

    var initChosenSelects = function(selector) {
      $.each($(selector).find('select[data-ajax]:not([data-tom-select])'), function(index, select){
        var selector = $(select);
        if (selector.data('ajax') === 'select' || selector.parent().find('.tokenize').length < 1) {
          selector.defaultTokenize();
        }
      });
      $.each($(selector).find('select[data-tom-select]'), function(index, select){
        $(select).defaultTomSelect();
      });
      $.each($(selector).find('select[data-dynamic-chosen]'), function(index, select){
        $(select).dynamicChosen();
      });
      $.each($(selector).find('select:not([data-ajax]):not([data-tom-select]):not([data-dynamic-chosen])'), function(index, select){
        $(select).defaultChosen();
      });
    };

    var visibleGroupDivs = function() {
      return $element.find('div:not(.newgroup) > .associationGroup:not([style*="display: none"])').not('.hidden');
    };

    var setGroups = function(showHidden) {
      var groups = showHidden ? $element.find('div:not(.newgroup) > .associationGroup') : visibleGroupDivs();
      $.each(groups, function(index, group) {
        var groupName = index + 1;
        $(group).find('.group-name').text(groupName);
        $(group).find('.group-input').val(groupName);
      });
      setAndVisibility(groups);
      setOrVisibility(groups);
    };

    var setEnabled = function() {
      var isEnabled = (visibleGroupDivs().length > 0) ? 'true' : 'false';
      $element.find('.groupedAssociationButtons label[for$=' + isEnabled  + ']').click();
    };

    var toggleSegmentInputs = function(group) {
      setTimeout(function() {
        var segmentGroupType = $(group).find('.segment_group_type_input input:checked').val();

        if (segmentGroupType == 'partner_segments') {
          $(group).find('.segs').addClass('hidden');
          $(group).find('.partner-segs').removeClass('hidden');
        }
        else {
          $(group).find('.segs').removeClass('hidden');
          $(group).find('.partner-segs').addClass('hidden');
        }
      }, 0);
    };

    $element.on('click', '.sgt-label', function(e) {
      var add_form = $(this).closest('.add_form');
      toggleSegmentInputs(add_form);
    });

    var setAndVisibility = function(visibleGroups) {
      $.each(visibleGroups, function(index, group) {
        var visibleAddForms = $(group).find('div:not(.newgroup) .add_form:not([style*="display: none"])').not('.hidden');

        if (visibleAddForms.length < 2) {
          $(group).find('.and').addClass('hidden');
        }
        else {
          $(group).find('.and').removeClass('hidden');
          var lastVisibleAddForm = _.last(visibleAddForms);
          $(lastVisibleAddForm).find('.and').addClass('hidden');
        }
      });
    };

    var setOrVisibility = function(visibleGroups) {
      if (visibleGroups.length > 0) {
        $element.find('.or').removeClass('hidden');
        var lastGroup = _.last(visibleGroups);
        $(lastGroup).find('.or').addClass('hidden');
      }
    };

    var resetChosenOptions = function(selector) {
      $.each($(selector), function(index, select){
        $(select).find('option').remove();
        $(select).trigger("chosen:updated");
      });
    };

    /* START KEY/VALUES */
    var togglePartialMatchVisibility = function(add_form, option) {
      setTimeout(function() {
        var demandTagKeyType = add_form.find('.demand_tag_key_type_input input:checked').val();
        add_form.find(".list_type select option[value*='white_list_partial_match']").prop('disabled', option);
        add_form.find(".list_type select option[value*='black_list_partial_match']").prop('disabled', option);
        add_form.find(".list_type select").trigger('chosen:updated');
      }, 0);
    };

    var adjustDemandTagKeyInputsVisibility = function(add_form) {
      setTimeout(function() {
        var demandTagKeyType = add_form.find('.demand_tag_key_type_input input:checked').val();
        var keyDefinitionType = add_form.find('.key_selector option:selected').data('definitionType');

        if (demandTagKeyType === 'value_lists') {
          togglePartialMatchVisibility(add_form, false);
          add_form.find('#value_lists').removeClass('hidden');
          add_form.find('#predefined_values, #free_values').addClass('hidden');
        }
        else if (demandTagKeyType === 'empty') {
          togglePartialMatchVisibility(add_form, true);
          add_form.find('#free_values, #predefined_values, #value_lists').addClass('hidden');
        }
        else {
          togglePartialMatchVisibility(add_form, false);
          add_form.find('#value_lists').addClass('hidden');
          if ( keyDefinitionType  === 'free' ) {
            add_form.find('#free_values').removeClass('hidden');
            add_form.find('#predefined_values').addClass('hidden');
          }
          else {
            add_form.find('#predefined_values').removeClass('hidden');
            add_form.find('#free_values').addClass('hidden');
          }
        }
      }, 0);
    };

    var toggleEmptyButton = function(add_form) {
      setTimeout(function() {
      var listType = add_form.find('.list_type option:selected').val();
        if (listType === 'white_list_partial_match' || listType === 'black_list_partial_match') {
          add_form.find('.demand_tag_key_type_input .btn-group label[for*=demand_tag_key_type_empty]').addClass('disabled');
        }
        else {
          add_form.find('.demand_tag_key_type_input .btn-group label[for*=demand_tag_key_type_empty]').removeClass('disabled');
        }
      }, 0);
    };

    var setKeyValuePlaceholders = function(add_form, allow_list) {
      $.each(add_form.find('#predefined_values .chosen-select, #free_values .chosen-select, #value_lists .chosen-select'), function(i, select) {
        var chosen = $(select);
        var placeholderSource = allow_list ? chosen.attr('dataAllowListPlaceholder') : chosen.attr('dataBlockListPlaceholder');
        if (placeholderSource) {
          chosen.attr('data-placeholder', placeholderSource).trigger('chosen:updated');
          chosen.parent().find('.TokensContainer .Placeholder').text(placeholderSource);
        }
      });
    };

    var isKeyValueAllowList = function(listType) {
      return _.contains(['white_list', 'white_list_partial_match'], listType);
    };

    var isKeyValueAllowListWithEmpty = function(selectedKeyType) {
      return _.contains(['empty'], selectedKeyType);
    };

    var styleKeyRequiredElements = function(add_form, isAllowList) {
     var target = add_form.find('label[for*="key_required"], input[name*="[key_required]"]');
     target.toggleClass('disabled', isAllowList);
     target.toggleClass('clickable', !isAllowList);
    };

    var autoSelectKeyRequired = function(add_form) {
      var listType = add_form.find('select[name*="[list_type]"]').val();
      var selectedKeyType = add_form.find('.key-type input:checked').val();
      var isAllowList = false;

      if (isKeyValueAllowList(listType)) {
        isAllowList = true;
      }
      else if (isKeyValueAllowListWithEmpty(selectedKeyType)) {
        if (listType === 'white_list' || listType === 'black_list') {
          isAllowList = true;
        }
      }

      styleKeyRequiredElements(add_form, isAllowList);
      setKeyValuePlaceholders(add_form, isAllowList);
      add_form.find('input[name*="[key_required]"]').prop('checked', isAllowList);
    };

    var addOptions = function(form, list, target) {
      $.each(list, function(i, item) {
        form.find(target).append($("<option>", {
          value: item.id,
          text : item.display_name || item.name
        }));
      });

      form.find(target).trigger("chosen:updated");
    };

    this.$onInit = function() {
      if (self.keyValues) {
        $element.on('click', '.dtkt-label', function(e) {
          var add_form = $(this).closest('.add_form');
          adjustDemandTagKeyInputsVisibility(add_form);
        });

        $element.on('change', '.key_selector', function(e) {
          var id = $(e.target).val();
          var add_form = $(e.target).closest('.add_form');
          var demandTagKeyType = add_form.find('.demand_tag_key_type_input input:checked').val();

          resetChosenOptions(add_form.find('#free_values select, #predefined_values select, #value_lists select'));

          var selectedDemandKeyIds = _.chain($element.find('.nested-fields:not([style*="display: none"]) .key_selector'))
                                      .map(function(e){ return $(e).val(); })
                                      .uniq()
                                      .compact()
                                      .value();

          $rootScope.$broadcast('demandKeyIdsChange', {demandKeyIds: selectedDemandKeyIds});


          if (id) {
            $.get('/settings/keys/' + id, function(data, status, xhr) {
              add_form.find('.demand_tag_key_type_input, .list_type, .key-required-wrapper').removeClass('hidden');
              addOptions(add_form, data.value_lists,'#value_lists select');

              autoSelectKeyRequired(add_form);

              if( data.definition_type === 'free' || data.key.definition_type === 'free' ) {
                if (demandTagKeyType === 'empty') {
                  add_form.find('#free_values').addClass('hidden');
                } else if (demandTagKeyType === 'value_lists') {
                  add_form.find('#value_lists').removeClass('hidden');
                  add_form.find('#predefined_values').addClass('hidden');
                } else {
                  add_form.find('#free_values').removeClass('hidden');
                  add_form.find('#predefined_values, #value_lists').addClass('hidden');
                }
              } else {
                addOptions(add_form, data.values,'#predefined_values select');
                adjustDemandTagKeyInputsVisibility(add_form);
              }
            });
          }
          else {
            add_form.find('#predefined_values, #free_values, #value_lists, .list_type, .demand_tag_key_type_input, .key-required-wrapper').addClass('hidden');
          }
        });

        $element.on('change', '.list_type select', function(e) {
          var add_form = $(e.target).closest('.add_form');
          autoSelectKeyRequired(add_form);
          toggleEmptyButton(add_form);
        });

        $element.on('click', 'input[name*="[key_required]"]', function(e) {
          var listType = $(this).parents('.add_form').find('select[name*="[list_type]"]').val();
          var selectedKeyType = $(this).parents('.add_form').find('.key-type input:checked').val();
          if ( isKeyValueAllowList(listType) || isKeyValueAllowListWithEmpty(selectedKeyType) ) {
            e.preventDefault();
            return false;
          }
        });
      }
      else if (self.inventoryGroups) {
        inventoryObjectOptionDisabler = new SelectOptionDisabler(
          '.nested-fields:not([style*="display: none"]) .inventory-object select'
        );

        var disableInventoryObjectOptions = function(group) {
          inventoryObjectOptionDisabler.disableParamOptions(group);
        };

        var toggleInventoryValueAndIdsVisibility = function(add_form, inventoryObject = undefined) {
          var sourceType = add_form.find('.inventory-source-type input:checked').val();
          var idsAttribute = add_form.find('.inventory-object select option:selected').data('associationIdsAttr');
          var idsAttributePresent = idsAttribute !== '';

          if (inventoryObject === 'DealList' && sourceType === 'values') {
            add_form.find('.inventory-values.inventory-deal-values').toggleClass('hidden', false).attr('disabled', false);
            add_form.find('.inventory-values:not(.inventory-deal-values)').toggleClass('hidden', true).attr('disabled', true);
          } else if (inventoryObject === 'DealList' && sourceType !== 'values') {
            add_form.find('.inventory-values.inventory-deal-values').toggleClass('hidden', true).attr('disabled', true);
          } else {
            add_form.find('.inventory-values:not(.inventory-deal-values)').toggleClass('hidden', sourceType !== 'values').attr('disabled', sourceType !== 'values');
            add_form.find('.inventory-values.inventory-deal-values').toggleClass('hidden', true).attr('disabled', true);
          }

          add_form.find('.inventory-list-ids').addClass('hidden');

          if (sourceType === 'list' && idsAttributePresent) {
            add_form.find('.inventory-list-ids.inventory-' + idsAttribute).removeClass('hidden');
          }
        };

        $element.on('change', '.inventory-source-type input', function(e) {
          var add_form = $(e.target).closest('.add_form');
          var inventoryObject = add_form.find('.inventory-object select').val();

          toggleInventoryValueAndIdsVisibility(add_form, inventoryObject);
        });

        $element.on('change', '.inventory-object select', function(e) {
          var inventoryObject = $(this).val();
          var isDealListInventoryObject = inventoryObject === 'DealList';
          var inventoryObjectSelected = inventoryObject !== '';
          var valuesDisabled = inventoryObject === 'PartnerSegment';
          var listDisabled = inventoryObject === 'InventorySource' || inventoryObject === "DealWseatList";
          var ronDisabled = inventoryObject !== 'DealIdList' && !isDealListInventoryObject;
          var add_form = $(e.target).closest('.add_form');
          var sourceType = add_form.find('.inventory-source-type input:checked').val();
          var group = $(e.target).closest('.associationGroup');

          disableInventoryObjectOptions(group);

          add_form.find('.inventory-values select option').remove();
          add_form.find('.inventory-values select').val([]).trigger('chosen:updated');

          add_form.find('.inventory-list-type, .inventory-source-type').toggleClass('hidden', !inventoryObjectSelected);
          add_form.find('.inventory-source-type .btn-values').toggleClass('hidden', valuesDisabled);
          add_form.find('.inventory-source-type .btn-list').toggleClass('hidden', listDisabled);
          add_form.find('.inventory-source-type .btn-ron').toggleClass('hidden', ronDisabled);

          toggleInventoryValueAndIdsVisibility(add_form, inventoryObject);

          if (inventoryObjectSelected && (ronDisabled && sourceType === 'ron') || (valuesDisabled && sourceType === 'values')) {
            add_form.find('.inventory-source-type .btn-list').click();
          }
          else if (inventoryObjectSelected && (listDisabled && sourceType === 'list')) {
            add_form.find('.inventory-source-type .btn-values').click();
          }

          if (inventoryObject === 'PartnerSegment') {
            $rootScope.$broadcast('audienceTargetingApplied');
          }
        });

        bodySelector.on('cocoon:after-insert', '#inventory_groups', function(e) {
          var group = $(e.target).closest('.associationGroup');
          disableInventoryObjectOptions(group);
        });

        bodySelector.on('cocoon:after-remove', '#inventory_groups', function(e) {
          var group = $(e.target).closest('.associationGroup');
          disableInventoryObjectOptions(group);
        });

        setTimeout(function() {
          $('#inventory_groups .associationGroup').each(function(i, group) {
            disableInventoryObjectOptions(group);
          });
        }, 0);

        if (self.addGroupOnInit) {
          setTimeout(() => {
            $element.find('.addgroup').click();
          }, 0);
        }
      }
    };

    /* END KEY/VALUES */

    setGroups(true);

  }]
});

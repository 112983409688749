angular.module('vastdesk').
  component('supplyClassDefault',  {
    bindings: {
      supplyClassDefault: '@',
      classes: '<',
      classMappings: '<'
    },
    template: require("../templates/supplyClassDefault.html"),
    controllerAs: '$supplyClassDefaultCtrl',
    controller: ['$rootScope', function($rootScope) {

      this.setSupplyClassDefault = (e) => {
        this.supplyClassDefault = e;

        $rootScope.$broadcast('supplyClassDefaultChange', {
          supplyClassDefault: this.supplyClassDefault
        });
      };
    }]
});

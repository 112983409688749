import WaterfallHelper from '../../modules/waterfall_helper.js';

angular.module('vastdesk').
  component('supplyTagAssignments',  {
    bindings: {
      formName: '@',
      tableId: '@',
      setSupplyTagAssignments: '<',
      demandPartnerIdsWithAllocationBudgets: '<',
      campaignIdsWithAllocationBudgets: '<',
      demandPartnerId: '@',
      campaignId: '@'
    },
    template: require("../templates/supplyTagAssignments.html"),
    controllerAs: '$supplyTagAssignmentsCtrl',
    controller: ['$scope', '$rootScope', function($scope, $rootScope) {

      var self = this;
      var bodySelector = $('body');
      var waterfallHelper = new WaterfallHelper();
      var serverSideTable, serverSideDataTable;

      var setSupplyTagAssignmentsParams = function() {
        return _.map(self.setSupplyTagAssignments, function(t) {
          return _.pick(t, 'supply_tag_id', 'allocation');
        });
      };

      var hasDemandTagBudgetWithoutIgnoreSupplyAllocations = function() {
        return $('#spend .add_form:not([style*="display: none"]) input[type="checkbox"][name$="[ignore_supply_allocations]"]:not(:checked)').length > 0;
      };

      var getIdFor = function(select_name) {
        var id = $('select[name="' + self.formName + '[' + select_name + ']"]').val();

        if (select_name === 'demand_partner_id') {
          if (_.contains(['selling_strategy'], self.formName)) {
            id = self.demandPartnerId;
          }
        }
        else if (select_name === 'campaign_id') {
          id = self.campaignId;
        }

        return id;
      };

      var hasParentBudgetWithoutIgnoreSupplyAllocations = function(select_name, list) {
        var id = getIdFor(select_name);
        return _.contains(list, parseInt(id));
      };

      var hasCampaignBudgetWithoutIgnoreSupplyAllocations = function() {
        return hasParentBudgetWithoutIgnoreSupplyAllocations('campaign_id', self.campaignIdsWithAllocationBudgets);
      };

      var hasDemandPartnerBudgetWithoutIgnoreSupplyAllocations = function() {
        return hasParentBudgetWithoutIgnoreSupplyAllocations('demand_partner_id', self.demandPartnerIdsWithAllocationBudgets);
      };

      var hasBudgetWithoutIgnoreSupplyAllocations = function() {
        return hasDemandTagBudgetWithoutIgnoreSupplyAllocations() ||
          hasCampaignBudgetWithoutIgnoreSupplyAllocations() ||
          hasDemandPartnerBudgetWithoutIgnoreSupplyAllocations();
      };

      var toggleAllocationColumnVisibility = function() {
        var allocationIndex = serverSideTable.find('th.allocation').index();
        if (allocationIndex < 0) {
          return;
        }

        var visible = hasBudgetWithoutIgnoreSupplyAllocations();
        $('#' + self.tableId + ' th.allocation, #' + self.tableId + ' td.allocation').toggleClass('hidden', !visible);
      };

      var assignmentWithSupplyTagId = function(assignments, id) {
        return _.find(assignments, function(ssta) {
          return parseInt(ssta.supply_tag_id) === parseInt(id);
        });
      };

      var newAssignment = function(id, allocation) {
        return {supply_tag_id: id, allocation: allocation};
      };

      var setSupplyTagAssignmentFor = function(row) {
        var id = $(row).data('supplyTagId');
        var assignment = assignmentWithSupplyTagId(self.setSupplyTagAssignments, id);

        if (!assignment) {
          self.setSupplyTagAssignments.push(newAssignment(id, null));
          assignment = assignmentWithSupplyTagId(self.setSupplyTagAssignments, id);
        }

        return assignment;
      };

      var updateSetSupplyAssignment = function(row, attributes, draw) {
        var ssta = setSupplyTagAssignmentFor(row);

        if (ssta) {
          ssta = _.extend(ssta, attributes);
          $scope.$apply();

          if (draw) {
            waterfallHelper.drawServerSideTable(serverSideDataTable, false);
          }
        }
      };

      var updateSetSupplyTagAssignmentFromElement = function(element, attribute, draw) {
        var row = waterfallHelper.rowForTableElement(element);
        var attributes = {};
        attributes[attribute] = waterfallHelper.valueForElement(element, attribute);
        updateSetSupplyAssignment(row, attributes, draw);
      };

      var handleBulkChange = function(supplyTagIds, attribute, value) {
        var supplyTagIdsModified = [];

        _.each(self.setSupplyTagAssignments, function(ssta) {
          if (_.contains(supplyTagIds, parseInt(ssta.supply_tag_id))) {
            ssta[attribute] = value;
            supplyTagIdsModified.push(parseInt(ssta.supply_tag_id));
          }
        });

        var assignmentsToAdd = _.difference(supplyTagIds, supplyTagIdsModified);

        _.each(assignmentsToAdd, function(id) {
          self.setSupplyTagAssignments.push(newAssignment(id, parseInt(value)));
        });

        setTimeout(function() {
          $scope.$apply();
        }, 0);

        waterfallHelper.drawServerSideTable(serverSideDataTable, false);
      };

      bodySelector.on('change', 'td .sta-a', function () {
        updateSetSupplyTagAssignmentFromElement(this, 'allocation', true);
      });

      $rootScope.$on('budgetSupplyAllocationChange', function(e, args) {
        toggleAllocationColumnVisibility();
        waterfallHelper.drawServerSideTable(serverSideDataTable, false);
      });

      $scope.$on('bulkEditBroadcast', function(e, args) {
        if (args.tableId === $(serverSideTable).attr('id')) {
          if (!args.selectedIds || !Array.isArray(args.selectedIds)) {
            return;
          }

          var supplyTagIds = _.map(args.selectedIds, function(id) {
            return parseInt(id);
          });

          if (args.id === 'allocation') {
            handleBulkChange(supplyTagIds, 'allocation', parseInt(args.value));
          }
        }
      });

      this.$onInit = function() {
        serverSideTable = $('#' + self.tableId);

        serverSideTable.on('init.dt', function() {
          serverSideDataTable = $(this).DataTable();
        });

        serverSideTable.on('preXhr.dt', function (e, settings, data) {
          data.set_supply_tag_assignments = setSupplyTagAssignmentsParams();
          data.custom_data = data.custom_data || {};
          data.custom_data.budget_with_allocation = hasBudgetWithoutIgnoreSupplyAllocations();
        });

        serverSideTable.on('draw.dt', function() {
          toggleAllocationColumnVisibility();
        });
      };

    }]
});

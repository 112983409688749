import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('postalCodeTargeting',  {
    bindings: {
      formName: '@',
      formId: '@',
      allowList: '@',
      geoSource: '@',
      selectedLists: '<',
      selectedOptions: '<'
    },
    template: require("../templates/postalCodeTargeting.html"),
    controllerAs: '$postalCodeTargetingCtrl',
    controller: function() {
      this.allowlistButtonOptions = componentHelper.targetingOptions();
      this.targetingSourceOptions = componentHelper.targetingSourceOptions();
    }
});

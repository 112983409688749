angular.module('vastdesk').
  component('forecastSupply', {
    bindings: {
      formName: '@',
      formId: '@',
      supplyType: '@',
      supplyTargetingSource: '@',
      selectedSupply: '<',
      supplyTargetingSourceOptions: '<'
    },
    template: require('../templates/forecastSupply.html'),
    controllerAs: '$forecastSupplyCtrl',
    controller: ['$scope', function($scope) {
      var self = this;

      this.showSupplyTargeting = function () {
        return ['include', 'exclude'].includes(this.supplyType);
      };

      this.setSupplyType = function(value) {
        self.supplyType = value;
        targetingSource = !self.showSupplyTargeting() ? "partners" : self.supplyTargetingSource;
        self.setSupplyTargetingSource(targetingSource)
      };

      this.setSupplyTargetingSource = function(value, clearAll = true) {
        let fieldsToClear = this.supplyTargetingSourceOptions

        if (!clearAll) {
          fieldsToClear = fieldsToClear.filter(option => option !== value);
        }

        // Clear the fields that are not the selected source
        fieldsToClear.forEach(field => {
          $(`.selected_supply_${field}`).trigger('tokenize:clear');
        })

        self.supplyTargetingSource = value;
      };

      this.$onInit = function() {
        setTimeout(() => {
          self.setSupplyTargetingSource(self.supplyTargetingSource, false)
        }, 0)
      };
    }],
});

import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('competitiveExclusionIabTier2Categories',  {
    bindings: {
      formName: '@',
      formId: '@',
      allCategoryCodes: '<',
      selectedCategoryCodes: '<',
      categoryOptions: '<',
      hasCodesOrList: "@",
      competitiveExclusionsSource: '@',
      selectedLists: '<',
    },
    template: require("../templates/competitiveExclusionIabTier2Categories.html"),
    controllerAs: '$competitiveExclusionIabTier2CategoriesCtrl',
    controller: function() {
      this.allCategoriesOptions = componentHelper.attributeEnabledMappings2();
      this.targetingSourceOptions = componentHelper.targetingSourceOptions();
    }
});

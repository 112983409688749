import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('mediaFileAudioVolumeTargeting',  {
    bindings: {
      formName: '@',
      tooltipText: '@',
      readOnly: '<',
      audioVolumeTargeting: '<',
      audioVolumeMean: '<',
      blockingUnknownAudioVolume: '<'
    },
    template: require("../templates/mediaFileAudioVolumeTargeting.html"),
    controllerAs: '$mediaFileAudioVolumeCtrl',
    controller: function() {
      var self = this;

      this.audioVolumeButtonOptions = componentHelper.allCustomOptions();

      this.customTooltip = function(condition) {
        return componentHelper.valueIfCondition(condition, self.tooltipText);
      };

      this.setAudioVolumeMean = function(val) {
        self.audioVolumeMean = val;
      };
    }
});

angular.module('vastdesk')
.component('selectAllFormItems', {
  bindings: {
    model: '=',
  },
  template: require("../templates/selectAllFormItems.html"),
  controllerAs: '$selectAllFormItemsTagCtrl',
  controller: function() {

    var self = this;

    this.addAll = function($event) {
      $event.preventDefault();
      angular.forEach(self.model, function(v, k) {
        self.model[k] = true;
      });
    };

    this.removeAll = function($event) {
      $event.preventDefault();
      angular.forEach(self.model, function(v, k) {
        self.model[k] = false;
      });
    };
  }
});

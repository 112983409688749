angular.module('vastdesk')
.component('supplyAllocation', {
  bindings: {
    formName: '@',
    formId: '@',
    customSupplyAllocation: '<',
    supplyAllocation: '@',
    supplyAllocationOptions: '<',
    readOnly: '<'
  },
  template: require("../templates/supplyAllocation.html"),
  controllerAs: '$supplyAllocationCtrl',
  controller: ["$scope", "$element", function($scope, $element) {

    var self = this;

    $scope.$on('supplyAllocationDefaultChange', function(e, args) {
      if (args.supply_allocation_default) {
        self.customSupplyAllocation = true;
        self.supplyAllocation = '' + args.supply_allocation_default;
        setTimeout(function() {
          $("select[name*='[supply_allocation]']").trigger('chosen:updated');
        }, 0);
      }
    });

  }]
});
import HighchartsBuilder from '../../modules/highcharts_builder.js';

angular.module('vastdesk')
.component('stackedColumnChart', {
  bindings: {
    chartId: '@',
    chartHeight: '@',
    categories: '=?',
    yAxisFormat: '@',
    currencySymbol: '@'
  },
  template: '<div class="graphChart stackedColumnChart"><div></div></div>',
  controller: ['$scope', '$rootScope', '$element', function($scope, $rootScope, $element) {

    var self = this;
    var onInitLoad = $.Deferred();
    var highchartsBuilder = new HighchartsBuilder();
    var colors = highchartsBuilder.chartColors;
    var chartDiv = $element.find('div');
    var chart;

    this.$onInit = function() {
      onInitLoad.resolve();
    };

    var formattedStacks = function(stacks) {
      return _.map(stacks, function(stack, i) {
        return {
          data: stack,
          zIndex: i,
          maxPointWidth: 50 - (i*15)
        };
      });
    };

    var getSeries = function(stacks) {
      if ( !Array.isArray(stacks) ) {
        return [];
      }

      if (Array.isArray(stacks[0]) && Array.isArray(stacks[0][0]) ) {
        return _.chain(stacks).map(function(subStack) { return formattedStacks(subStack); }).flatten().value();
      }
      else {
        return formattedStacks(stacks);
      }
    };

    var setChart = function(stacks) {
      chart = chartDiv.highcharts({
        chart: {
          type: 'column',
          animation: false,
          plotBorderWidth: null,
          backgroundColor: null,
          height: (self.chartHeight || 400) + 'px'
        },
        title: {text: ''},
        legend: {enabled: false},
        credits: {enabled: false},
        xAxis: {
          categories: self.categories,
          labels: {enabled: Array.isArray(self.categories)},
          tickLength: 0,
          lineColor: '#ccd6eb'
        },
        yAxis: {
          title: {text: ''},
          labels: {
            reserveSpace: true,
            style: {
              fontSize: '9px',
              fontWeight: 'bold'
            },
            formatter: function() {
              return highchartsBuilder.yAxisFormatter(self.yAxisFormat, this, self.currencySymbol);
            }
          },
          gridLineWidth: 0
        },
        tooltip: {
          useHTML: true,
          formatter: function() {
            return this.point.tooltip;
          },
          style: {
            opacity: '0.95'
          }
        },
        colors: colors,
        plotOptions: {
          column: {
            borderWidth: 0,
            maxPointWidth: 50,
            grouping: false,
            shadow: false
          }
        },
        series: getSeries(stacks)
      });
    };

    $scope.$on('setHighchartLoading', function(e, args) {
      highchartsBuilder.loadData(onInitLoad, chartDiv, self, args);
    });


    $scope.$on('refreshHighchart', function(e, args) {
      highchartsBuilder.refreshData(onInitLoad, chartDiv, self, args, function() {
        setChart(args.stacks);
      });
    });

    $scope.$on('reflowHighcharts', function(e, args) {
      highchartsBuilder.deferredReflowChart(onInitLoad, chartDiv);
    });

    $rootScope.$on('reportCurrencySymbolChange', function (e, args) {
      self.currencySymbol = args.currencySymbol;
    });

  }]
});

angular.module('vastdesk').
  component('reportCurrency',  {
    bindings: {
      currency: '@',
      currencyOptions: '<',
      tableIds: '<',
      noDrawTableIds: '<'
    },
    template: require("../templates/reportCurrency.html"),
    controllerAs: '$reportCurrencyCtrl',
    controller: ['$rootScope', '$timeout', function($rootScope, $timeout) {

      var self = this;
      var dataTables = [];

      var getCurrencySymbol = function() {
        var selectedCurrency = _.find(self.currencyOptions, function(n){
          return n[0] === self.currency;
        });

        return selectedCurrency ? selectedCurrency[1] : '$';
      };

      this.broadcastReportCurrencyChange = function() {
        $rootScope.$broadcast('reportCurrencyChange', {
          currency: self.currency
        });

        $rootScope.$broadcast('reportCurrencySymbolChange', {
          currencySymbol: getCurrencySymbol()
        });

        triggerDataTableDraws();
      };

      var triggerDataTableDraws = function() {
        _.each(dataTables, function(dataTable) {
          dataTable.draw();
        });
      };

      var attachCurrencyToTables = function(ids, draw) {
        if (Array.isArray(ids)) {
          _.each(ids, function(tableId) {

            if (draw) {
              $(tableId).on('init.dt', function() {
                dataTables.push($(tableId).DataTable());
              });
            }

            $(tableId).on('preXhr.dt', function (e, settings, data) {
              data.custom_data = data.custom_data || {};
              data.custom_data.currency = self.currency;
            });
          });
        }
      };

      this.$onInit = function() {
        attachCurrencyToTables(self.tableIds, true);
        attachCurrencyToTables(self.noDrawTableIds, false);
      };

      $rootScope.$on('setReportCurrency', function(e, args) {
        self.currency = args.currency;
        $('.report-currency-wrapper select').val(self.currency).trigger('chosen:updated');
        self.broadcastReportCurrencyChange();
      });

    }]
});

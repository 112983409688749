angular.module('vastdesk').
  component('billItemCost',  {
    bindings: {
      calcTotalCost: '<',
      isClearingFee: '<',
      unitCost: '<',
      units: '<',
      totalCost: '<',
      isUnitCostCpm: '<',
      currency: '@'
    },
    template: require("../templates/billItemCost.html"),
    controllerAs: '$billItemCostCtrl',
    controller: function() {
 
      this.calculateTotalCost = () => {
        if (isNaN(this.units) || isNaN(this.unitCost)) {
          return 0;
        }

        return (this.units * this.unitCost).toFixed(2);
      };

    }
});

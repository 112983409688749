angular.module('vastdesk')
.component('shareLinkModal', {
  bindings: {},
  controller: function() {
    var bodyElement = $('body');

    var copyLink = function() {
      $('#link_to_copy').select();
      return document.execCommand('copy');
    };

    bodyElement.on('click', '#copy_report_link', function() {
      var copySuccess = copyLink();

      if (copySuccess) {
        $('#copy_report_link').popover({
          animation: true,
          placement: 'top',
          content: 'Report link copied',
          sanitize: false,
          trigger: 'manual',
          html: true,
          delay: {
            hide: 100
          }
        }).popover('show');

        setTimeout(function () {
          $('#copy_report_link').popover('hide');
        }, 500);
      }
    });

    bodyElement.on('click', '#copy_and_exit', function() {
      copyLink();
    });
  }
});

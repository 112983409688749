class ReportMetricHelper {
  metricDisabled(self, metric) {
    return this.detectedDisabled(self, metric)
      || (self.ortbBuyer && !metric.ortb_demand)
      || this.demandReportDisabled(self, metric)
      || (self.ortbReport && !(metric.ortb_demand || metric.ortb_supply))
      || this.supplyReportDisabled(self, metric)
      || this.vastErrorCodeDisabled(self, metric)
      || this.audioDisabled(self, metric)
      || this.bidDensityDisabled(self, metric)
      || this.advertiserDomainDisabled(self, metric)
      || this.partnerSegmentDisabled(self, metric)
      || this.routerDisabled(self, metric)
      || this.contentDisabled(self, metric)
      || this.dspCityDisabled(self, metric)
      || this.dspDomainDisabled(self, metric)
      || this.dspInventoryDisabled(self, metric)
      || this.magniteSspDisabled(self, metric)
      || this.freqReachDisabled(self, metric)
      || this.postalCodeDisabled(self, metric);
  }

  detectedDisabled(self, metric) {
    return metric.non_detected && self.disabledByDetectedReporting;
  }

  vastErrorCodeDisabled(self, metric) {
    return self.vastErrorCodeReport && !metric.vast_error_code_compatible;
  }

  bidderVastErrorCodeAllowed(self, metric, ortbMetric) {
    return ortbMetric && self.vastErrorCodeReport && metric.vast_error_code_compatible && metric.bidder_vast_error_code_compatible;
  }

  bidderPcReportAllowed(self, metric, ortbMetric) {
    return ortbMetric && (self.pcFilterReport || self.dspCityReport || self.dspDomainReport || self.dspInventoryReport || self.dspSourceReport);
  }

  bidderAdvertiserDomainReportAllowed(self, metric, ortbMetric) {
    return ortbMetric && self.advertiserDomainReport;
  }

  audioDisabled(self, metric) {
    return self.audioReport && !metric.audio_compatible;
  }

  bidDensityDisabled(self, metric) {
    return self.bidDensityReport && Array.isArray(metric.aggs_tables) && !_.contains(metric.aggs_tables, 'Bidder');
  }

  advertiserDomainDisabled(self, metric) {
    return self.advertiserDomainReport && Array.isArray(metric.aggs_tables) && !_.contains(metric.aggs_tables, 'ADomain');
  }

  partnerSegmentDisabled(self, metric) {
    return self.partnerSegmentReport && Array.isArray(metric.aggs_tables) && !_.contains(metric.aggs_tables, 'PartnerSegments');
  }

  contentDisabled(self, metric) {
    return self.contentReport &&
      Array.isArray(metric.aggs_tables) &&
      (
        (self.demandReport && !_.contains(metric.aggs_tables, 'ContentParam')) ||
          (!self.demandReport && !_.contains(metric.aggs_tables, 'SupplyContent'))
      );
  }

  dspCityDisabled(self, metric) {
    return self.dspCityReport && Array.isArray(metric.aggs_tables) && !_.contains(metric.aggs_tables, 'DspCity');
  }

  dspDomainDisabled(self, metric) {
    return self.dspDomainReport && Array.isArray(metric.aggs_tables) && !_.contains(metric.aggs_tables, 'DspDomain');
  }

  dspInventoryDisabled(self, metric) {
    return (self.dspInventoryReport || self.dspSourceReport) && Array.isArray(metric.aggs_tables) && !_.contains(metric.aggs_tables, 'DspInventory');
  }

  routerDisabled(self, metric) {
    return !self.routerReport && metric.router_metric;
  }
  
  magniteSspDisabled(self, metric) {
    return self.magniteSspReport && !metric.magnite_ssp_compatible;
  }

  freqReachDisabled(self, metric) {
    if (self.freqReachReport) {
      return Array.isArray(metric.aggs_tables) &&
        !_.contains(metric.aggs_tables, 'FreqReach');
    }
    else {
      return Array.isArray(metric.aggs_tables) &&
        metric.aggs_tables.length === 1 &&
        metric.aggs_tables[0] === 'FreqReach';
    }
  }

  postalCodeDisabled(self, metric) {
    return self.postalCodeReport && Array.isArray(metric.aggs_tables) && !_.contains(metric.aggs_tables, 'DspCity') && !_.contains(metric.aggs_tables, 'SupplyPostalCode') && !_.contains(metric.aggs_tables, 'DemandPostalCode')
  }

  demandReportDisabled(self, metric) {
    var ortbMetric = metric.ortb_demand || metric.ortb_supply;

    return (self.demandReport && !metric.demand) &&
      !(self.ortbReport && ortbMetric) &&
      !this.bidderVastErrorCodeAllowed(self, metric, ortbMetric) &&
      !this.bidderPcReportAllowed(self, metric, ortbMetric) &&
      !this.bidderAdvertiserDomainReportAllowed(self, metric, ortbMetric);
  }

  supplyReportDisabled(self, metric) {
    return !self.demandReport && !metric.supply && !self.ortbReport &&
      !this.bidderVastErrorCodeAllowed(self, metric, metric.ortb_supply) &&
      !this.bidderPcReportAllowed(self, metric, metric.ortb_supply) &&
      !this.bidderAdvertiserDomainReportAllowed(self, metric, metric.ortb_supply);
  }
}

export default ReportMetricHelper;

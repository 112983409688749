angular.module('vastdesk').
  component('bidPlatform',  {
    bindings: {
      formName: '@',
      formId: '@',
      bidPlatform: '@',
      environment: '@',
      bidPlatformOptions: '<',
      bidParamsPath: '@',
      bidderType: '@',
      bidderTypeOptions: '<',
      openPathEnabled: '<',
      format: '@'
    },
    template: require("../templates/bidPlatform.html"),
    controllerAs: '$bidPlatformCtrl',
    controller: ['$rootScope', function($rootScope) {

      const self = this;
      const bodySelector = $('body');

      this.loadBidParameters = (platform) => {
        $rootScope.$broadcast('bidPlatformChange', {
          bidPlatform: platform
        });

        $.post(self.bidParamsPath, {"platform": platform}, (resp) => {
          $('#bid_params_wrapper').replaceWith(resp);
          $('#bid_params_wrapper select').defaultChosen();
        });
      };

      this.filterPlatformOptions = () => {
        $('#demand_tag_bid_platform').find('option').each(function() {
          $(this).removeClass('hidden');
          const bidderType = $(this).data('bidderType');
          const excludeCtv = $(this).data('excludeCtv');
          const val = $(this).val();

          // Only show 'Magnite DV+' for Tiles
          if ('tile' === self.format && val !== '' && val != 'rubicon') {
            $(this).addClass('hidden');
          }

          if (self.environment === 'ctv' && excludeCtv) {
            $(this).addClass('hidden');
          }

          if (self.bidderType === 'ssp' && !_.contains(['', 'ssp'], bidderType)) {
            $(this).addClass('hidden');
          }

          if (self.bidderType === 'dsp' && bidderType != 'dsp') {
            $(this).addClass('hidden');
          }

          if (self.bidderType === 'oem' && bidderType != 'oem') {
            $(this).addClass('hidden');
          }

          if (self.environment === 'ctv') {
            if ($('#demand_tag_bid_platform option:selected').data('excludeCtv')) {
              self.resetBidPlatform();
            }
          }
        });

        $('#demand_tag_bid_platform').trigger('chosen:updated');
      };

      this.setBidderType = (bidderType) => {
        this.bidderType = bidderType;
        this.resetBidPlatform();
        this.filterPlatformOptions();
      };

      this.resetBidPlatform = () => {
        this.bidPlatform = '';
        $('#demand_tag_bid_platform').val('').trigger('chosen:updated');
        this.loadBidParameters('');
      };

      bodySelector.on('click', '#add_bid_optional_param:not(:disabled)', function() {
        const path = $('#add_bid_optional_param').data('path');
        if (!path) {
          return;
        }

        $.post(path, {platform: self.bidPlatform}, function(resp) {
          $('#bid_optional_params_wrapper').append(resp);
          $('#bid_optional_params_wrapper select').defaultChosen();
          $rootScope.$broadcast('updateBidOptionalParamFields');
        });
      });

      bodySelector.on('change', '#demand_tag_bid_params_hb_bid_map_id', function() {
        const inventorySplitSelected = $(this).find('option:selected').data('hbbmt') === 'inventory_split';
        $('label[for="demand_tag_bid_params_inv"]').toggleClass('required', inventorySplitSelected);
      });

      $rootScope.$on('environmentChange', (e, args) => {
        this.environment = args.environment;
        this.filterPlatformOptions();
      });

      $rootScope.$on('formatChanged', (e, args) => {
        this.format = args.format;
        this.resetBidPlatform();
        this.filterPlatformOptions();
      });

      this.$onInit = () => {
        setTimeout(this.filterPlatformOptions, 0);
      };

    }]
});

angular.module('vastdesk').
  component('demandTagMultiCall',  {
    bindings: {
      formName: '@',
      formId: '@',
      multiCallEnabled: '<',
      multiCallMaximum: '<',
      readOnly: '<',
      accountMultiCallMax: '@',
      multiCallDisabledBidPlatforms: '<',
      bidPlatform: '@',
      demandClass: '@'
    },
    template: require("../templates/demandTagMultiCall.html"),
    controllerAs: '$demandTagMultiCallCtrl',
    controller: ['$rootScope', '$scope', function($rootScope, $scope) {

      var self = this;

      this.multiCallDisabled = function() {
        return disabledByDemandClass() ||
          disabledByBidPlatform();
      };

      var disabledByDemandClass = function() {
         return _.contains(['4', '8', '11'], self.demandClass);
      };

      var disabledByBidPlatform = function() {
        return self.demandClass === '3' && _.contains(self.multiCallDisabledBidPlatforms, self.bidPlatform);
      };

      var disableMultiCall = function() {
        if (self.multiCallDisabled()) {
          self.multiCallEnabled = false;
        }
      };

      $rootScope.$on('bidPlatformChange', function(e, args) {
        self.bidPlatform = args.bidPlatform;
        disableMultiCall();
      });

      $scope.$on('demandClassChanged', function(e, args) {
        self.demandClass = args.demandClass;
        disableMultiCall();
      });

      this.$onInit = function() {
        disableMultiCall();
      };

    }]
});

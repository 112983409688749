angular.module('vastdesk').
  component('bulkAddTags', {
    bindings: {
      modalId: '@',
      targetTableId: '@',
      types: '@'
    },
    controller: ['$scope', '$element', function($scope, $element) {
      var selectedTags = {};
      var self = this;
      var bodyElement = $('body');
      var modalElement, modal, modalTable, modalDataTable;
      var targetTable, targetDataTable;
      var wrapperElement = $element.parents('.bulk-add-tags-wrapper');

      this.$onInit = function() {
        modalElement = '#' + self.modalId;
        modal = $(modalElement);
        modalTable = modal.find('.datatable');

        targetTable = $('#' + self.targetTableId);

        bodyElement.on('change', modalElement + ' .bulk-add-tags-checkbox', function() {
          var checkbox = $(this);
          var val = checkbox.data('tagId');
          if(checkbox.prop('checked')) {
            selectedTags[val] = checkbox.closest('tr').get(0);
          } else {
            delete selectedTags[val];
          }
        });

        bodyElement.on('click', modalElement + ' #modal_save', function() {
          modalDataTable = modalTable.DataTable();
          modalTable.hide();
          targetDataTable.rows.add(_.values(selectedTags)).draw();
          modal.modal('hide');
          return false;
        });

        modalTable.on('init.dt', function() {
          modalDataTable = $(this).DataTable();
          setButtonDisabled(false);
        });

        modalTable.on('draw.dt', function () {
          setModalHiddenColumnCellVisibility(modalTable, true);
          setCheckboxes();
        });

        targetTable.on('init.dt', function() {
          targetDataTable = $(this).DataTable();
        });

        targetTable.on('draw.dt', function() {
          setModalHiddenColumnCellVisibility(targetTable, false);
        });
      };

      var setButtonDisabled = function(disabled) {
        wrapperElement.find('.open-modal').prop('disabled', disabled);
      };

      setButtonDisabled(true);

      var modalDataTableAjaxUrl = function(allowlist){
        var url = modalDataTable.ajax.url()
                   .replace("&allowlist=true", "")
                   .replace("&allowlist=false", "")
                   .replace("?allowlist=true", "")
                   .replace("?allowlist=false", "");

        var sym = (url.includes('?')) ? '&' : '?';

        return url + sym + "allowlist=" + allowlist;
      };

      var setAllowlistModalTitle = function(allowListValue) {
        var allowListValueDisplay = allowListValue ? 'Allowlist' : 'Blocklist';
        var newModalTitle = modal.find('.modal-title').text().split(' as')[0] + ' as ' + allowListValueDisplay;
        modal.find('.modal-title').text(newModalTitle);
      };

      wrapperElement.on('click', '.open-modal', function(e) {
        var target = $(e.target);
        var allowListValue =  target.data('allowlist');

        if (_.contains([true, false], allowListValue)) {
          modalDataTable.ajax.url(modalDataTableAjaxUrl(allowListValue));
          setAllowlistModalTitle(allowListValue);
        }

        selectedTags = {};
        modalTable.show();
        modal.modal();

        setTimeout( function () {
          modalDataTable.draw();
        }, 150 );

        return false;
      });

      var setModalHiddenColumnCellVisibility = function(table, hidden) {
        table.find('thead:first th.bulk-modal-hidden').each(function(i, th) {
          var index = $(th).index();

          if (index) {
            table.find('tr').find('td:eq(' + index + ')').toggleClass('hidden', hidden);
          }
        });
      };

      var setCheckboxes = function() {
        $(modalElement + ' input[type="checkbox"]').attr('disabled', false).prop('checked', false);

        $(targetTable).find('tbody tr').each(function() {
          var id = $(this).data('tagId');
          var checkbox = modalTable.find('.bulk-add-tags-checkbox[data-tag-id="' + id + '"]');
          checkbox.parents().eq(1).addClass('hidden');
        });

        var selectedIds = _.keys(selectedTags);

        _.each(selectedIds, function (id) {
          modalTable.find('.bulk-add-tags-checkbox[data-tag-id="' + id + '"]').prop('checked', true);
        });
      };

    }]
});

class FlightDatesHelper {
  now(timezone) {
    return moment().tz(timezone);
  }

  parsedDate(date, timezone) {
    return moment.utc(date).tz(timezone, true);
  }

  pastEndDate(date, timezone) {
    if (!date) return false;

    return this.parsedDate(date, timezone).isBefore(this.now(timezone));
  }

  inactiveByBudgetFlightDates(budgetData) {
    if(Array.isArray(budgetData) && budgetData.length > 0) {
      var self = this;

      return _.reject(budgetData, function(bd) {
        const timezone = bd[1] || 'Etc/UTC';

        return Array.isArray(bd[0]) && bd[0].length > 0 && _.every(bd[0], function(budgetFlightDates) {
          return !self.now(timezone).isBetween(self.parsedDate(budgetFlightDates[0], timezone), self.parsedDate(budgetFlightDates[1], timezone));
        });
      }).length < 1;
    }
    else {
      return false;
    }
  }
}

export default FlightDatesHelper;

import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('multiplierInteraction',  {
    bindings: {
      formName: '@',
      formId: '@',
      multiplierInteraction: '@'
    },
    template: require("../templates/multiplierInteraction.html"),
    controllerAs: '$multiplierInteractionCtrl',
    controller: function() {
      this.stackedModifiers = [ 
        { label: 'Stacked', value: 'stacked' },
        { label: 'Min', value: 'min' },
        { label: 'Max', value: 'max' },
        { label: 'Mean', value: 'mean' },
      ];
      this.attributeEnabledMappings = componentHelper.attributeEnabledMappings();
    }
});

class WaterfallModalHelper {

  openEventHandler(bodySelector, modalSelector, modal, modalTable, cb) {
    bodySelector.on('click', modalSelector, function (e) {
      if ($(this).attr('disabled')) {
        e.preventDefault();
        return false;
      }
      cb();
      modalTable.show();
      var datatable = modalTable.defaultDataTable({'retrieve': true});
      datatable.rows().nodes().page.len(modalTable.data('pageLength') || 50).search('').draw();

      modal.modal();

      //hack for datatable column bug
      //https://datatables.net/forums/discussion/24424/column-header-element-is-not-sized-correctly-when-scrolly-is-set-in-the-table-setup
      setTimeout(function () {
        datatable.columns.adjust().draw();
      }, 150);
      return false;
    });
  }

  saveEventHandler(bodySelector, modal, modalTable, cb) {
    bodySelector.on('click', '#' + modal.attr('id') + ' #modal_save', function () {
      modalTable.hide();

      cb();

      modal.modal('hide');
      return false;
    });
  }

  modalCheckEventHandler(bodySelector, idsLabel, checkedCb, uncheckedCb) {
    bodySelector.on("change", "[name='" + idsLabel +"[]']", function () {
      var checkbox = $(this);
      var val = checkbox.val();
      if (checkbox.prop("checked")) {
        checkedCb(val);
      } else {
        uncheckedCb(val);
      }
    });
  }

  setEnvironmentFilter(modal, environment) {
    var envFilter = (_.contains(['dc', 'nil'], environment)) ? '' : environment;
    modal.find("#dfilter_environment").val(envFilter).trigger("chosen:updated").change();
  }

  hideShowFormatFilters(modal, format) {
    modal.find('#dfilter_post-imp_detection').parent().toggleClass('hidden', format === 'audio');
    modal.find('#dfilter_pre-bid_filtering').parent().toggleClass('hidden', format === 'audio');
    modal.find('#dfilter_response_type').parent().toggleClass('hidden', format === 'tile');
  }

  setFormatFilter(modal, format) {
    modal.find("#dfilter_format").val(format || 'video').trigger("chosen:updated").change();
  }

  styleStatus(tr, index) {
    if (index >= 0) {
      var statusCol = $(tr).find("td").eq(index);
      if (statusCol.find(".status-false").length > 0) {
        $(tr).addClass("inactive-row");
      }
    }
  }

  styleWithBulkAddEnabled(tr, index) {
    if (index >= 0) {
      var bulkCol = $(tr).find("td").eq(index);
      if (bulkCol.find(".bulk-add-enabled-false").length > 0) {
        $(tr).addClass("disabled-row");
      }
    }
  }

  setSelectedModalCheckboxes(modalTable, selectedIds) {
    modalTable.find("input[type='checkbox']:not([data-disabled=true])").attr('disabled', false).prop('checked', false);

    $.each(selectedIds, function( index, id ) {
      var checkbox = modalTable.find('#checkbox_' + id);
      checkbox.prop('checked', true);
    });

    modalTable.find("tr.disabled-row input[type='checkbox']").each(function(index, cb) {
      $(cb).attr('disabled', true);
    });
  }

}

export default WaterfallModalHelper;
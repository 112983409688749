export default {
  allCustomOptions() {
    return [
      {value: false, label: 'All'},
      {value: true, label: 'Custom'}
    ];
  },
  allCustomOptions2() {
    return [
      {value: true, label: 'All'},
      {value: false, label: 'Custom'}
    ];
  },
  allowlistOptions() {
    return [
      {value: '', label: 'All'},
      {value: 'true', label: 'Allowlist'},
      {value: 'false', label: 'Blocklist'}
    ];
  },
  targetingOptions() {
    return [
      {value: '', label: 'All'},
      {value: 'true', label: 'Include'},
      {value: 'false', label: 'Exclude'}
    ];
  },
  attributeEnabledMappings() {
    return [
      {value: true, label: 'Enabled'},
      {value: false, label: 'Disabled'}
    ];
  },
  attributeEnabledMappings2() {
    return [
      {value: false, label: 'Enabled'},
      {value: true, label: 'Disabled'}
    ];
  },
  feeOptions() {
    return [
      {value: false, label: 'Flat'},
      {value: true, label: 'CPM'}
    ];
  },
  customNoneOptions() {
    return [
      {value: false, label: 'None'},
      {value: true, label: 'Custom'}
    ];
  },
  customNoneOptions2() {
    return [
      {value: true, label: 'None'},
      {value: false, label: 'Custom'}
    ];
  },
  customDisabledOptions() {
    return [
      {value: false, label: 'Disabled'},
      {value: true, label: 'Custom'}
    ];
  },
  valueIfCondition(condition, value) {
    if (condition) {
      return value;
    }
  },
  formHasError(error, errors) {
    var formErrors = Array.isArray(errors) ? errors : [];
    return _.contains(formErrors, error);
  },
  optimizationModeOptions() {
    return [
      {value: 'black_box', label: 'Platform'},
      {value: 'white_box', label: 'Custom'}
    ];
  },
  statusOptions() {
    return [
      {value: true, label: 'Active'},
      {value: false, label: 'Inactive'}
    ];
  },
  targetingSourceOptions() {
    return [
      {value: 'values', label: 'Values'},
      {value: 'list', label: 'List'}
    ];
  }
};

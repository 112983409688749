import componentHelper from '../../modules/component_helper.js';

angular.module('vastdesk').
  component('goalTargetingToggle',  {
    bindings: {
      formName: '@',
      formId: '@',
      goalTargetingEnabled: '<',
      toggleClass: '@',
      environment: '@',
      format: '@'
    },
    template: require("../templates/goalTargetingToggle.html"),
    controllerAs: '$goalTargetingToggleCtrl',
    controller: ['$scope', '$rootScope', function($scope, $rootScope) {

      var self = this;
      var bodySelector = $('body');

      this.customDisabledOptions = componentHelper.customDisabledOptions();

      this.setGoalTargetingEnabled = function(value) {
        self.goalTargetingEnabled = value;

        $('.' + self.toggleClass).toggleClass('hidden', !value);
      };

      var removeInvalidKpiMetrics = function() {
        if (self.environment === 'ctv' || self.format === 'tile' || self.environment === 'dooh') {
          $('[id^=' + self.formId + '_goal_targetings_attributes_][id$=metric]').each(function() {
            var removeMetric = (self.environment === 'ctv' && $(this).find('option:selected').data('excludeCtv')) ||
              (self.format === 'tile' && $(this).find('option:selected').data('excludeTile')) ||
              (self.environment === 'dooh' && $(this).find('option:selected').data('excludeDooh'));

            if (removeMetric) {
              $(this).parent().nextAll('.remove_association').find('.fa-circle-x').click();
            }
          });
        }
      };

      var filterKpiMetricOptions = function() {
        $('[id^=' + self.formId + '_goal_targetings_attributes_][id$=metric]').find('option').each(function() {
          if (($(this).data('excludeCtv') && self.environment === 'ctv') ||
            (self.format === 'tile' && $(this).data('excludeTile')) ||
            (self.environment === 'dooh' && $(this).data('excludeDooh'))) {
            $(this).attr('disabled', true);
          } else {
            $(this).attr('disabled', false);
          }
        });

        $('[id^=' + self.formId + '_goal_targetings_attributes_][id$=metric]').trigger('chosen:updated');
      };

      bodySelector.on('cocoon:after-insert', '.goal-targeting-toggle', function(e) {
        filterKpiMetricOptions();
      });

      $rootScope.$on('environmentChange', function(e, args) {
        self.environment = args.environment;
        removeInvalidKpiMetrics();
        filterKpiMetricOptions();
      });

      $rootScope.$on('formatChanged', function(e, args) {
        self.format = args.format;
        removeInvalidKpiMetrics();
        filterKpiMetricOptions();
      });

      this.$onInit = function() {
        self.setGoalTargetingEnabled(self.goalTargetingEnabled);
        filterKpiMetricOptions();
      };

    }]
  });

angular.module('vastdesk').
  component('userSessionForm',  {
    bindings: {
      isProduction: '@',
      otpPath: '@',
      preOtpPath: '@',
      sessionPath: '@',
      email: '@',
      resetTwoFactorToken: '@',
      preOtpAuthToken: '@',
      sessionAuthToken: '@'
    },
    template: require("../templates/userSessionForm.html"),
    controllerAs: '$userSessionFormCtrl',
    controller: ['$scope', function($scope) {
      var self = this;
      this.passwordInputType = 'password';

      var stepOneForm = $('#step_1');
      var bodySelector = $('body');

      this.toggleShowPassword = function() {
        self.passwordInputType = (self.passwordInputType === 'password') ? 'text' : 'password';
      };

      this.showTwoFactorFields = function() {
        return self.otpEnabled && (!self.resetTwoFactorToken || self.resetTwoFactorToken === '');
      };

      this.logInWithGoogleUrl = function() {
        var googUrl = '/users/auth/google_oauth2';

        if (self.resetTwoFactorToken && self.resetTwoFactorToken !== '') {
          googUrl += '?reset_two_factor_token=' + self.resetTwoFactorToken;
        }

        return googUrl;
      };
      
      this.magniteSSOLayout = () => {
        auth0_sso_supported_private_label_domains = ['magnite.com', 'springserve.com', 'ads.vizio.com', 'ads.frndlytv.com', 'adserve.amagi.com', 'ads.kidoodle.tv', 'ads.goldbach-germany.de', 'ads.yuppads.com'];
        return auth0_sso_supported_private_label_domains.some(domain => window.location.hostname.includes(domain));
      };

      this.magniteSSOButtons = () => {
        auth0_sso_supported_domains = ['magnite.com', 'springserve.com'];
        return auth0_sso_supported_domains.some(domain => window.location.hostname.includes(domain));
      };

      this.vizioSSOButtons = () => {
        return window.location.hostname.includes("ads.vizio.com")
      };

      this.frndlytvSSOButtons = () => {
        return window.location.hostname.includes("ads.frndlytv.com")
      };
      this.yuppadsSSOButtons = () => {
        return window.location.hostname.includes("ads.yuppads.com")
      };

      this.amagiSSOButtons = () => {
          return window.location.hostname.includes("adserve.amagi.com")
      }

      this.kidoodleSSOButtons = () => {
        return window.location.hostname.includes("ads.kidoodle.tv")
      };

      this.goldbachSSOButtons = () => {
        return window.location.hostname.includes("ads.goldbach-germany.de")
      };

      stepOneForm.on('submit', function (e) {
        e.preventDefault();

        $.post(self.otpPath, {"user[email]": $('#user_email').val()}, function (json) {
          if (Object.prototype.hasOwnProperty.call(json, 'two_factor_required_expiration') && json.two_factor_required_expiration) {
            self.twoFactorExpired = true;
          } else {
            configureForm(json.otp_enabled);
          }
          $scope.$apply();
        });
      });

      var configureForm = function(otp_enabled) {
        self.step2 = true;

        var stepTwoForm = $('#step_2');
        stepTwoForm.find('#user_email_2').val(stepOneForm.find('#user_email').val());
        stepTwoForm.find('#user_password').focus();

        if (otp_enabled) {
          self.otpEnabled = true;
          $('#step_2_otp').attr('required', 'required');
        }
      };

      bodySelector.on('click', '#subscribe_modal .btn-ss-submit', function() {
        $('#subscribe_modal').modal('hide');
      });
    }]
});

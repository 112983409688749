angular.module('vastdesk')
.component('copyButton', {
  bindings: {
    copyTarget: '@',
    copyButtonClass: '@',
    copyButtonText: '@'
  },
  template: '<button class="btn {{$copyCtrl.copyButtonClass}}" type="button" ng-bind-html="$copyCtrl.copyButtonContent"></button>',
  controllerAs: '$copyCtrl',
  controller: ['$element', function($element) {

    var self = this;

    this.$onInit = function() {
      self.copyButtonClass = self.copyButtonClass || 'btn-ss-submit';
      self.copyButtonText = self.copyButtonText || 'Copy';
      self.copyButtonContent = '<i class="far fa-clipboard"></i>' + self.copyButtonText;
    };

    $element.on('click', function() {
      if ( !self.copyTarget || self.copyTarget == '' ) {
        return;
      }
      $(self.copyTarget).select();
      var copySuccess = document.execCommand('copy');

      if (copySuccess) {
        $element.popover({
          animation: true,
          placement: 'top',
          content: '<i class="fa fa-check-square text-success"></i>',
          sanitize: false,
          trigger: 'manual',
          html: true,
          delay: {
            hide: 100
          }
        }).popover('show');

        setTimeout(function () {
          $element.popover('hide');
        }, 500);
      }
    });

  }]
});

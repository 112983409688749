angular.module('vastdesk')
.component('deselectAllChosenOptions', {
  bindings: {
    target: '@'
  },
  template: require("../templates/deselectAllChosenOptions.html"),
  controllerAs: '$deselectAllCtrl',
  controller: ['$element', function( $element) {

    var self = this;

    this.remove = function() {
      if ( self.target && $(self.target).length) {
        $(self.target).val([]).trigger('chosen:updated').trigger('tokenize:clear');
      }
    };

  }]
});